import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { AttendanceService } from 'src/app/employee/services/attendance.service';
import { CompanyGroup } from '../../../../company-group/state/company-group.model';
import { CompanyBranch } from '../../../../setting/models/CompanyBranch';
import { FlatpickrLocaleService } from '../../../../shared/services/flatpickr-locale.service';
import { Tag } from '../../../../tag/state/tag.model';
import { TagService } from '../../../../tag/state/tag.service';
import { Employee } from '../../../models/Employee';
import { EmployeeService } from '../../../services/employee.service';

interface DocumentStatus {
    step1: boolean;
    step2: boolean;
    step3: boolean;
    step4: boolean;
    step5: boolean;
}

interface EmployeeWithStats extends Employee {
    surname_for_order: string;
    name_for_order: string;
    properly_work_hours: number;
    real_work_hours: number;
    normalized_work_hours: number;
    holiday_hours: number;
    sickness_hours: number;
    sick_day_hours: number;
}

@Component({
    selector: 'app-employee-attendance-normalize-check',
    templateUrl: './employee-attendance-normalize-check.component.html',
    styleUrls: ['./employee-attendance-normalize-check.component.css']
})
export class EmployeeAttendanceNormalizeCheckComponent implements OnInit {
    @ViewChild('filterFormOptions', {static: false})
    public filterFormOptions: TemplateRef<ElementRef>;

    public employees$: Observable<EmployeeWithStats[]>;
    public month: number;
    public year: number;
    public datetimeDocumentStatus$: Observable<DocumentStatus>;
    public companyGroups$: Observable<CompanyGroup[]>;
    public employeePositionTypes$: Observable<any>;
    public companyBranches$: Observable<CompanyBranch[]>;
    public tags$: Observable<Tag[]>;
    public hardSkillsTags$: Observable<Tag[]>;
    public softSkillsTags$: Observable<Tag[]>;
    public data$: Observable<{ employees: EmployeeWithStats[], status: DocumentStatus }>;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public filterForm: UntypedFormGroup;

    private _fetchEmployees$ = new ReplaySubject<void>(1);
    private _filterFormModalRef: NgbModalRef;

    constructor(
        private _attendanceService: AttendanceService,
        private _modalService: NgbModal,
        private _route: ActivatedRoute,
        private _fb: UntypedFormBuilder,
        private _employeeService: EmployeeService,
        private _tagService: TagService,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _router: Router,
    ) { }

    public getCount(field: string) {
        return this.filterForm.get(field).value?.length;
    }

    public ngOnInit(): void {
        this.filterForm = this._fb.group({
            companyGroups: [null],
            employeePositionTypes: [null],
            positionCompanyBranches: [null],
            mainCompanyBranches: [null],
            tags: [null],
            hardSkillsTags: [null],
            softSkillsTags: [null],
        });

        this.year = parseInt(this._route.snapshot.queryParamMap.get('year'), 10);
        this.month = parseInt(this._route.snapshot.queryParamMap.get('month'), 10);

        this.companyGroups$ = this._employeeService.getAllCompanyGroups();
        this.employeePositionTypes$ = this._employeeService.getAllEmployeePositionTypes();
        this.companyBranches$ = this._employeeService.getAllCompanyBranches('minimal').pipe(shareReplay());

        this.tags$ = this._tagService.getAllTags('employee');
        this.hardSkillsTags$ = this._tagService.getAllTags('employee', 'hard_skill');
        this.softSkillsTags$ = this._tagService.getAllTags('employee', 'soft_skill');

        this.datetimeDocumentStatus$ = this._attendanceService.getDatetimesSummaryStatus(this.month, this.year);

        this.data$ = combineLatest([
            this._fetchEmployees$
                .pipe(
                    switchMap(() => this._attendanceService.getNormalizedDatetimesDataForCheck(this.month, this.year, this.filterForm.value))
                ),
            this.datetimeDocumentStatus$
        ]).pipe(
            map(([employees, status]) => ({employees, status})));

        this._fetchEmployees$.next();
    }

    public openFilterFormOptions(): void {
        this._filterFormModalRef = this._modalService.open(this.filterFormOptions);
    }

    public onSubmit(value: any): void {
        this._attendanceService.saveNormalizedDatetimesAfterCheck(value, this.month, this.year)
            .then(result => {
                this._router.navigate(['/employee', 'attendance', 'documents', 'detail'], {queryParams: {month: this.month, year: this.year}});
            });
    }

    public onSubmitFilter(): void {
        this._filterFormModalRef?.close();

        this._fetchEmployees$.next();
    }

    public disableScroll(event: WheelEvent) {
        event.preventDefault();
    }
}
