<div
  class="modal-content"
>
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">
      <span>{{ 'notes.note' | translate }}</span>
    </p>

    <button
      aria-label="Close"
      class="close"
      type="button"
      (click)="activeModal.dismiss()"
    >
      <span
        aria-hidden="true"
        class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <div *ngIf="canEdit$ | async">

              <form
                [formGroup]="editNoteForm"
                (ngSubmit)="onSubmit()"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'notes.text' | translate }}</span>
                      </label>

                      <input
                        formControlName="text"
                        name="text"
                        type="text"
                        [ngxSummernote]="summernoteHelper.getSummernoteConfig()"
                      >

                      <div *ngIf="submitted && f.text.errors">
                        <p
                          *ngIf="f.text.errors.required"
                          class="text-danger validation-text"
                        >
                          <span>{{ 'angular_validation.field' | translate }} </span>
                          <span>{{ 'notes.text' | translate }} </span>
                          <span>{{ 'angular_validation.required' | translate }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col d-flex justify-content-end">
                    <button class="btn btn-success">
                      {{ 'notes.action_save' | translate }}
                    </button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
