<div class="modal-content">
	<div class="modal-header">
			<p *ngIf="customField" class="modal-title font-16 mb-0 text-dark">{{'settings.appearance.custom_fields.page_title_edit' | translate}}</p>
			<p *ngIf="!customField" class="modal-title font-16 mb-0 text-dark">{{'settings.appearance.custom_fields.page_title_create' | translate}}</p>
		<button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
			<span aria-hidden="true" class="font-24 text-muted"><i class="mdi mdi-close"></i></span>
		  </button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-0 shadow-none">
					<div class="card-body p-0">
						<form (ngSubmit)="onSubmit()" [formGroup]="customFieldForm" autocomplete="off">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label class="control-label">{{'settings.appearance.custom_fields.label' | translate}}</label>
										<input class="form-control"
                                               formControlName="label"
                                               name="label"
                                               type="text">
										<div *ngIf="submitted && f.label.errors">
											<p *ngIf="f.label.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'settings.appearance.custom_fields.label' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="control-label">{{'settings.appearance.custom_fields.widget' | translate}}</label>
                                        <ng-select [clearable]="false"
                                                   closeOnSelect="true"
                                                   class="custom"
                                                   formControlName="widget"
                                                   [items]="customFieldWidgets"
                                                   [bindLabel]="'label'"
                                                   [bindValue]="'value'"
                                                   name="widget">
                                        </ng-select>
                                        <div *ngIf="submitted && f.widget.errors">
                                            <p *ngIf="f.widget.errors.required" class="text-danger validation-text">
                                                {{ 'angular_validation.field' | translate }}
                                                {{'settings.appearance.custom_fields.widget' | translate }}
                                                {{ 'angular_validation.required' | translate }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
							<div class="row">
								<div class="col-12">
									<div class="row">
                                        <div class="col-9">
                                            <label class="control-label">{{ 'settings.appearance.custom_fields.required' | translate }}</label>
                                        </div>
                                        <div class="col-3 text-right align-self-center">
                                            <div>
                                                <input data-switch="success" formControlName="is_required" id="is_required" name="is_required" type="checkbox">
                                                <label for="is_required">&nbsp;</label>
                                            </div>
                                        </div>
									</div>
								</div>
							</div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-9">
                                            <label class="control-label">{{ 'settings.appearance.custom_fields.visible' | translate }}</label>
                                        </div>
                                        <div class="col-3 text-right align-self-center">
                                            <div>
                                                <input data-switch="success" formControlName="is_visible" id="is_visible" name="is_visible" type="checkbox">
                                                <label for="is_visible">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="control-label">{{'settings.appearance.custom_fields.type' | translate}}</label>
                                        <ng-select [clearable]="false"
                                                   closeOnSelect="true"
                                                   class="custom"
                                                   formControlName="type"
                                                   [items]="customFieldTypes"
                                                   [bindLabel]="'label'"
                                                   [bindValue]="'value'"
                                                   (change)="onTypechange($event)"
                                                   name="type">
                                        </ng-select>
                                        <div *ngIf="submitted && f.type.errors">
                                            <p *ngIf="f.type.errors.required" class="text-danger validation-text">
                                                {{ 'angular_validation.field' | translate }}
                                                {{'settings.appearance.custom_fields.type' | translate }}
                                                {{ 'angular_validation.required' | translate }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <fieldset class="fieldset-bordered" *ngIf="f.type.value === 'SELECT' || f.type.value === 'MULTISELECT'" formArrayName="options">
                                <legend>{{ 'settings.appearance.custom_fields.options' | translate }}</legend>
                                <div class="row">
                                    <div class="col-7">
                                        <label class="control-label">
                                            <span>{{ 'settings.appearance.custom_fields.label' | translate }}</span>
                                        </label>
                                    </div>
                                    <div class="col">
                                        <label class="control-label">
                                            <span>{{ 'settings.appearance.custom_fields.is_default' | translate }}</span>
                                        </label>
                                    </div>
                                </div>
                                <ng-container *ngFor="let option of options?.controls; let i = index">
                                    <div [formGroupName]="i" class="row mb-2 align-items-center">
                                        <div class="col-7">
                                            <input class="form-control"
                                                   formControlName="value"
                                                   type="text">
                                        </div>

                                        <div class="col">
                                            <div>
                                                <input
                                                    data-switch="success"
                                                    formControlName="is_default"
                                                    id="is_default-{{i}}"
                                                    name="is_default"
                                                    (change)="onDefaultChange()"
                                                    type="checkbox"
                                                >

                                                <label class="m-0" for="is_default-{{i}}">&nbsp;</label>
                                            </div>
                                        </div>

                                        <div class="col-auto">
                                            <label class="control-label"> </label>
                                            <button (click)="removeOption(i)" class="btn btn-icon btn-primary" type="button">
                                                <i class="mdi mdi-trash-can-outline mdi-24px"></i>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="row mb-3">
                                    <div class="col-12 text-right">
                                        <button (click)="addOption()" [createButton]="'add_option'" type="button"></button>
                                    </div>
                                </div>
                                <div *ngIf="submitted && showExceedDefaultAlert" class=" col-12 mt-1">
                                    <p class="text-danger">
                                        <span> {{ 'settings.appearance.custom_fields.exceeded_default_count' | translate }} </span>
                                    </p>
                                </div>
                            </fieldset>
							<div class="row justify-content-end mt-2">
                                <div class="col-6 text-right my-auto">
                                    <u (click)="deleteCustomField()" *ngIf="customField && !customField.is_used" class="text-muted">{{'numbers_series.action_delete' | translate}}</u>
                                </div>
								<div class="col-6 text-right">
									<button class="btn btn-success"
										    type="submit">{{'numbers_series.action_save' | translate}}</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>









