import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { DisableDemoModeModalComponent } from './components/disable-demo-mode-modal/disable-demo-mode-modal.component';


@NgModule({
    declarations: [
        DisableDemoModeModalComponent
    ],
    exports: [
        DisableDemoModeModalComponent
    ],
    imports: [
        TranslateModule,
        CommonModule
    ]
})
export class DisableDemoModeModule { }
