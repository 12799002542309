<div class="row">
    <div class="col-12">
        <div *ngIf="menuSettings$ | async as menu; else loadingTemplate" class="card shadow">
            <div class="card-header py-3">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-title font-16 text-dark pt-2 pb-2">
                            {{ 'settings.appearance.menu.title' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <form #menuForm="ngForm" (ngSubmit)="onSubmit(menuForm)">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-centered table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <td></td>
                                    <td *ngFor="let role of menu.roles" class="text-muted">{{role.name}}</td>
                                </tr>
                            </thead>

                            <tbody>
                                <ng-container *ngFor="let menuItem of menu.menuItems">
                                    <!-- menu items -->
                                    <tr [ngModelGroup]="menuItem.name">
                                        <td class="font-weight-bold py-2">{{ menuItem.name | translate }}</td>
                                        <td class="py-2" *ngFor="let role of menu.roles">
                                            <ng-container *ngIf="menuItem.available_for_role_IDs?.includes(role.role_ID); else notAvailableTemplate">
                                                <input (ngModelChange)="onChange($event, menuItem.name, role.role_ID,  menuForm)"
                                                       [ngModel]="!menuItem.disabled_visibility_for_role_IDs?.includes(role.role_ID)"
                                                       data-switch="success"
                                                       id="{{menuItem.name + '-' + role.role_ID}}"
                                                       [name]="role.role_ID"
                                                       type="checkbox"
                                                />

                                                <label class="mb-0" for="{{menuItem.name + '-' + role.role_ID}}">&nbsp;</label>
                                            </ng-container>
                                        </td>
                                    </tr>
                                    <!-- submenu items -->
                                    <tr [ngModelGroup]="subItem.name" *ngFor="let subItem of menuItem.submenu">
                                        <td class="pl-4 py-2">{{ subItem.name | translate }}</td>
                                        <td class="py-2" *ngFor="let role of menu.roles">
                                            <ng-container *ngIf="subItem.available_for_role_IDs?.includes(role.role_ID); else notAvailableTemplate">
                                                <input (ngModelChange)="onChange($event, subItem.name, role.role_ID, menuForm)"
                                                       [ngModel]="!subItem.disabled_visibility_for_role_IDs?.includes(role.role_ID)"
                                                       data-switch="success"
                                                       id="{{subItem.name + '-' + role.role_ID}}"
                                                       [name]="role.role_ID"
                                                       type="checkbox"
                                                />

                                                <label class="mb-0" for="{{subItem.name + '-' + role.role_ID}}">&nbsp;</label>
                                            </ng-container>
                                        </td>
                                    </tr>
                                    <ng-template #notAvailableTemplate>
                                        <input data-switch="success"
                                               id="not-available-toggle"
                                               type="checkbox"
                                               [checked]="false"
                                               disabled
                                               readonly
                                        />

                                            <label [style.opacity]="0.3"
                                                   [ngbTooltip]="'settings.appearance.menu.setting_not_available' | translate"
                                                   container="body"
                                                   placement="bottom"
                                                   class="mb-0"
                                                   for="not-available-toggle">&nbsp;</label>
                                    </ng-template>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div *ngIf="formChanged"
                     class="submit-button shadow px-1 px-sm-3 px-md-3 pr-lg-4 pl-lg-4"
                >
                    <p class="text-dark m-0 d-flex align-items-center text-left mr-2 mr-md-0">
                        <span> {{ 'settings.appearance.menu.changes_were_applied' | translate }} </span>
                    </p>
                    <button (click)="onSubmit(menuForm)"
                            submitButton
                            class="btn btn-primary">
                        {{ 'settings.appearance.menu.action_save' | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #loadingTemplate>
  <div class="card shadow p-3 mb-3">
    <div class="item">
      <ngx-skeleton-loader [theme]="{
      'margin': '15px 0'
    }" appearance="line" count="8"></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>
