import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { CompanyService } from '../../../../setting/services/company.service';
import { Employee } from '../../../models/Employee';
import { EmployeeStats } from '../../../models/EmployeeStats';
import { EmployeeService } from '../../../services/employee.service';
import { ChangeEmployeeInCompanyFromModalComponent } from '../../partials/change-employee-in-company-from-modal/change-employee-in-company-from-modal.component';

@Component({
    selector: 'app-employee-attendance-stats',
    templateUrl: './employee-attendance-stats.component.html',
    styleUrls: ['./employee-attendance-stats.component.css']
})
export class EmployeeAttendanceStatsComponent implements OnInit, OnChanges {
    @Input() public employeeID: number;
    @Input() public statsToCheck = 'dashboard';
    @Input() public employee$: Observable<Employee>;

    public stats$: Observable<EmployeeStats>;
    public availableWidgets$: Observable<AvailableWidgets>;
    public attendanceCols = 0;
    public isAttendanceCardAvailable = false;
    public days = [
        this._translateService.instant('dates.Monday'),
        this._translateService.instant('dates.Tuesday'),
        this._translateService.instant('dates.Wednesday'),
        this._translateService.instant('dates.Thursday'),
        this._translateService.instant('dates.Friday'),
        this._translateService.instant('dates.Saturday'),
        this._translateService.instant('dates.Sunday'),
    ];

    public constructor(
        private _companyService: CompanyService,
        private _employeeService: EmployeeService,
        private _modalService: NgbModal,
        private _translateService: TranslateService
    ) { }

    public ngOnInit(): void {
        this.availableWidgets$ = this._companyService.availableWidgets$;
        this._getEmployeeStats();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.employeeID?.isFirstChange()) {
            this._getEmployeeStats();
        }
    }

    public openEmployeeInCompanyFromModal(inCompanyFrom: string): void {
        const modalRef = this._modalService.open(ChangeEmployeeInCompanyFromModalComponent, {centered: true});

        modalRef.componentInstance.inCompanyFrom = inCompanyFrom;
        modalRef.componentInstance.employeeID = this.employeeID;

        modalRef.result
            .then(
                () => this._getEmployeeStats(),
                () => {}
            );
    }

    private _getEmployeeStats(): void {
        this.stats$ = combineLatest([
            this._employeeService.getEmployeeStatsByEmployeeID(this.employeeID),
            this.availableWidgets$
        ]).pipe(
            tap(([stats, availableWidgets]) => this._checkForCols(availableWidgets, stats)),
            map(([stats]) => stats),
            shareReplay()
        );
    }

    private _checkForCols(availableWidgets: AvailableWidgets, stats: EmployeeStats): void {
        this.isAttendanceCardAvailable = false;
        this.attendanceCols = 0;

        if (
            availableWidgets[this.statsToCheck].attendance_cards.days_in_company ||
            availableWidgets[this.statsToCheck].attendance_cards.holidays_left ||
            availableWidgets[this.statsToCheck].attendance_cards.sick_days_left ||
            availableWidgets[this.statsToCheck].attendance_cards.work_chart
        ) {
            this.isAttendanceCardAvailable = true;
        }

        if (availableWidgets[this.statsToCheck].attendance_cards.sick_days_left && stats.sick_days.total > 0) {
            this.attendanceCols++;
        }
        if (availableWidgets[this.statsToCheck].attendance_cards.days_in_company) {
            this.attendanceCols++;
        }
        if (availableWidgets[this.statsToCheck].attendance_cards.holidays_left) {
            this.attendanceCols++;
        }
        if (availableWidgets[this.statsToCheck].attendance_cards.work_chart) {
            this.attendanceCols++;
        }
    }
}
