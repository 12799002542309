import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Growth } from './growth.model';

export interface GrowthState extends EntityState<Growth> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'growths', idKey: 'growth_ID' })
export class GrowthStore extends EntityStore<GrowthState> {
  constructor() {
    super();
  }
}
