import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { FormatHelper } from 'src/app/shared/common/FormatHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { CompanyBranch } from '../models/CompanyBranch';
import { BranchService } from './branch.service';

@Injectable({
    providedIn: 'root'
})
export class BranchStoreService {

    constructor(
        private branchService: BranchService,
        private formatHelper: FormatHelper,
        private apiHelper: ApiHelper,
        private swalHelper: SwalHelper
    ) {
        this.fetchAll('minimal');
    }

    private readonly branchesSubject = new BehaviorSubject<CompanyBranch[]>([]);
    public readonly branches$ = this.branchesSubject.asObservable();

    get branches(): CompanyBranch[] {
        return this.branchesSubject.getValue();
    }

    set branches(val: CompanyBranch[]) {
        this.branchesSubject.next(val);
    }

    public async addBranch(form: FormData, companyBranch: CompanyBranch) {

        const tmpId = this.formatHelper.uuid();
        const tmpBranch: CompanyBranch = {
            company_branch_ID: tmpId,
            name: companyBranch.name,
            addr_line_1: companyBranch.addr_line_1,
            addr_line_2: companyBranch.addr_line_2,
            city: companyBranch.city,
            postal_code: companyBranch.postal_code,
            country_code: companyBranch.country_code,
            country_name: companyBranch.country_name,
            company_branch_formatted: companyBranch.company_branch_formatted,
            employees: []
        };
        this.branches = [
            ...this.branches,
            tmpBranch
        ];

        try {
            const branch: any = await this.branchService
                .saveBranch(form).pipe(map((response: any) => response.branch))
                .toPromise();

            const index = this.branches.indexOf(this.branches.find(t => t.company_branch_ID === tmpId));
            this.branches[index] = {
                ...branch
            };

            this.branches = [...this.branches];

            return true;
        } catch (e) {
            this.removeBranch(tmpId, false);
            return false;
        }

    }

    public async updateBranch(branchID: number | string, form: FormData, companyBranch: CompanyBranch) {
        const branch = this.branches.find(br => br.company_branch_ID === branchID);
        if (branch) {
            // optimistic update
            const index = this.branches.indexOf(branch);

            this.branches[index] = {
                company_branch_ID: branchID,
                ...companyBranch,
            };

            this.branches = [...this.branches];

            try {
                await this.branchService
                    .updateBranch(branchID, form)
                    .toPromise().then((response: any) => {
                        this.apiHelper.handleSuccessResponse(response);
                    });

            } catch (e) {
                this.apiHelper.handleErrorResponse(e);
                this.branches[index] = {
                    ...branch,
                };
            }
        }
    }

    public async removeBranch(branchID: string | number, serverRemove = true) {

        let deleteConfirmed = false;

        if (serverRemove) {
            deleteConfirmed = await this.swalHelper.showConfirmDeleteDialog();
        }

        if (deleteConfirmed || !serverRemove) {
            // optimistic update
            const branch = this.branches.find(t => t.company_branch_ID === branchID);
            this.branches = this.branches.filter(todo => todo.company_branch_ID !== branchID);
            if (serverRemove) {
                try {
                    await this.branchService.deleteBranch(branchID).toPromise().then((response: any) => {
                        this.apiHelper.handleSuccessResponse(response);
                    });
                } catch (e) {
                    this.apiHelper.handleErrorResponse(e);
                    this.branches = [...this.branches, branch];
                }

            }
        }

    }

    public async fetchAll(scope: string) {
        this.branches = await this.branchService.getAllBranches(scope).pipe(shareReplay()).toPromise();
    }
}
