<ng-container *ngIf="(canViewCompanySettings$ | async)?.can && (canViewCRM$ | async)?.can">
  <section id="facturation">
    <app-company-settings-facturation></app-company-settings-facturation>
  </section>
</ng-container>

<ng-container *ngIf="(canViewNumberSeries$ | async)?.can">
  <section id="numberSeries">
    <app-number-series-list></app-number-series-list>
  </section>
</ng-container>
