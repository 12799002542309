import { Injectable } from '@angular/core';

import { FlatPickrOutputOptions, } from 'angularx-flatpickr/lib/flatpickr.directive';

@Injectable()
export class FlatpickrHelper {
  holidays = [
    '2019-01-01',
    '2019-01-01',
    '2019-04-19',
    '2019-04-22',
    '2019-05-01',
    '2019-05-08',
    '2019-07-05',
    '2019-07-06',
    '2019-09-28',
    '2019-10-28',
    '2019-11-17',
    '2019-12-24',
    '2019-12-25',
    '2019-12-26',
    '2020-01-01',
    '2020-01-01',
    '2020-04-10',
    '2020-04-13',
    '2020-05-01',
    '2020-05-08',
    '2020-07-05',
    '2020-07-06',
    '2020-09-28',
    '2020-10-28',
    '2020-11-17',
    '2020-12-24',
    '2020-12-25',
    '2020-12-26',
    '2021-01-01',
    '2021-01-01',
    '2021-04-02',
    '2021-04-05',
    '2021-05-01',
    '2021-05-08',
    '2021-07-05',
    '2021-07-06',
    '2021-09-28',
    '2021-10-28',
    '2021-11-17',
    '2021-12-24',
    '2021-12-25',
    '2021-12-26'
  ];

  public applyAltInputValue(flatpickr: FlatPickrOutputOptions, isDateTime: boolean): void {
    let euroDate = flatpickr.instance.altInput.value;
    let euroTime: string;

    if (isDateTime) {
      euroTime = euroDate.substring(11, 16);
      euroDate = euroDate.substring(0, 10);
    }

    const parts = euroDate.split('.');
    let isoDate = parts.reverse().join('-');

    if (isDateTime) {
      if (
        !isoDate &&
        !euroTime
      ) {
        isoDate = null;
      } else {
        isoDate = isoDate + ' ' + euroTime;
      }
    }

    flatpickr.instance.setDate(isoDate, true);
  }

  public changeSeparator(flatpickr: FlatPickrOutputOptions): void {
    if (flatpickr.instance.altInput.value.includes('do')) {
      flatpickr.instance.altInput.value = flatpickr.instance.altInput.value.replace('do', '→');
    }
  }

  public disableWeekends(date: Date): boolean {
    return (date.getDay() === 0 || date.getDay() === 6);
  }
}

