import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ID } from '@datorama/akita';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';
import { FatherOfListComponent, Sort, SORT_DESC } from 'src/app/ui';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { FlatpickrHelper } from '../../../../shared/common/FlatpickrHelper';
import { EmployeeDateTime } from '../../../models/EmployeeDateTime';
import { AttendanceService } from '../../../services/attendance.service';

@Component({
    selector: 'app-employee-absence-list',
    templateUrl: './employee-absence-list.component.html',
    styleUrls: ['./employee-absence-list.component.css']
})
export class EmployeeAbsenceListComponent extends FatherOfListComponent<EmployeeDateTime> implements OnInit {
    @ViewChild('filterFormOptions', {static: false})
    public filterFormOptions: TemplateRef<ElementRef>;

    @Input() isDashboard = false;

    public loggedUser = this._authService.loggedUser;
    public filterForm: UntypedFormGroup;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public defaultSelectedFilters = null;
    public sort: Sort<EmployeeDateTime> = {
        column: 'from',
        direction: SORT_DESC
    };

    private _fetchAbsence$ = new ReplaySubject<void>(1);
    private _filterFormModalRef: NgbModalRef;

    public constructor(
        public fpHelper: FlatpickrHelper,
        private _authService: AuthenticationService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _modalService: NgbModal,
        private _attendanceService: AttendanceService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
    ) {
        super(_ngZone, _changeDetectorRef);
    }

    public ngOnInit(): void {
        const defaultRangeDays = this.loggedUser?.default_filters?.attendance?.absence_range;
        const from = moment().format('YYYY-MM-DD');
        let to = moment().add(defaultRangeDays, 'days').format('YYYY-MM-DD');

        if (this.isDashboard) {
            to = moment().format('YYYY-MM-DD');
        }

        this.filterForm = this._fb.group({
            from: [from],
            to: [to],
        });

        this.defaultSelectedFilters = this.filterForm.value;

        this._rows$ = this._fetchAbsence$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                switchMap(params => this._attendanceService.getAbsenceList(params, this.filterForm.value)),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._fetchListData();

        this._init();
    }

    public onSubmit(): void {
        this._filterFormModalRef?.close();

        this._fetchListData();
    }

    public openFilterFormOptions(): void {
        this._filterFormModalRef = this._modalService.open(this.filterFormOptions);
    }

    public trackByFn(index: number, datetime: EmployeeDateTime): ID {
        return datetime.employee_datetime_ID;
    }

    protected _fetchListData(): void {
        this._fetchAbsence$.next();
    }
}
