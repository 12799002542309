import { ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export function passwordMatchValidator(passwordControlName: string = 'password', passwordConfirmationControlName: string = 'passwordConfirm'): ValidatorFn {
  return (form: UntypedFormGroup): ValidationErrors | null => {

    const passwordControl = form.get(passwordControlName);
    const passwordConfirmControl = form.get(passwordConfirmationControlName);

    return passwordControl && passwordConfirmControl && passwordControl.value === passwordConfirmControl.value ? null : { mismatch: true };
  };
}
