<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">
            <span *ngIf="employee">{{ 'employees.personal_data.page_title_edit' | translate }}</span>
        </p>

        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
        >
      <span
          aria-hidden="true"
          class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <app-personal-data-form #childRef
                                                [submitted]="submitted"
                                                [employeePersonalData]="employeePersonalData"
                                                [employee]="employee">
                        </app-personal-data-form>

                        <div *ngIf="hasEditEmployeePermission && !(canEdit$ | async)" class="row">
                            <div class="col-12 d-flex justify-content-end">
                                <p class="text-muted mb-1"><small> {{'employees_addresses.request_will_be_sent' | translate}} </small></p>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col d-flex justify-content-end">
                                <button (click)="onSubmit()" [disabled]="(updateRequested && !(canEdit$ | async)) || hiddenInputs" class="btn btn-success">
                                    <span *ngIf="employee">{{ 'employees.action_save' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
