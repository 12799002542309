<div class="modal-content">
    <div class="modal-header">
        <p
            *ngIf="!position"
            class="modal-title font-16 mb-0 text-dark"
        >{{ 'company_positions.page_title_create' | translate }}</p>

        <p
            *ngIf="position"
            class="modal-title font-16 mb-0 text-dark"
        >{{ 'company_positions.page_title_edit' | translate }}</p>

        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
        >
      <span
          aria-hidden="true"
          class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <form
                            (ngSubmit)="onSubmit()"
                            [formGroup]="positionForm"
                            autocomplete="off"
                        >
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'company_positions.name' | translate }}</span>
                                        </label>

                                        <input
                                            class="form-control"
                                            formControlName="name"
                                            name="name"
                                            type="text"
                                        >

                                        <div *ngIf="submitted && f.name.errors">
                                            <p
                                                *ngIf="f.name.errors.required"
                                                class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'company_positions.name' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'company_positions.type' | translate }}</span>
                                        </label>

                                        <ng-select
                                            [clearable]="false"
                                            class="custom"
                                            formControlName="type"
                                            name="scope"
                                            [items]="positionsTypes"
                                            bindValue="value"
                                            bindLabel="label"
                                        >
                                        </ng-select>

                                        <div *ngIf="submitted && f.type.errors">
                                            <p
                                                *ngIf="f.type.errors.required"
                                                class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'company_positions.type' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'company_positions.description' | translate }}</span>
                                        </label>

                                        <textarea
                                            class="form-control"
                                            formControlName="description"
                                            name="description"
                                            type="text">
                                        </textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'company_positions.job_description' | translate }}</span>
                                        </label>

                                        <textarea
                                            class="form-control"
                                            formControlName="job_description"
                                            name="description"
                                            type="text">
                                        </textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'company_positions.salary_from' | translate }}</label>
                                        <div>
                                            <input formControlName="salary_from" currencyMask type="text" class="form-control" id="salary_from" name="salary_from">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'company_positions.salary_to' | translate }}</label>
                                        <div>
                                            <input formControlName="salary_to" currencyMask type="text" class="form-control" id="salary_to" name="salary_to">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'company_positions.salary_default' | translate }}</label>
                                        <div>
                                            <input formControlName="salary_default" currencyMask type="text" class="form-control" id="salary_default" name="salary_default">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="control-label"> {{ 'company_positions.color' | translate }} </label>
                                        <input class="form-control" [(colorPicker)]="color" [style.background]="color" [cpPosition]="'top-right'" [cpOutputFormat]="'hex'" [cpAlphaChannel]="'disabled'"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-2">
                                <div class="col d-flex justify-content-end">
                                    <button (click)="deleteBranch()" *ngIf="position && position.employees_count === 0 && canDelete" class="btn btn-link text-muted pr-0 mr-4" type="button">
                                        <u>{{ 'company_positions.action_delete' | translate }}</u>
                                    </button>
                                    <button class="btn btn-success" type="submit">
                                        {{ 'company_positions.action_save' | translate }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
