<ng-container *ngIf="onboarding$ | async as onboarding; else loadingTemplate">
  <ng-template
    #content
    let-modal
  >
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">
          <span>{{ 'onboardings.assign_employee' | translate }}</span>
        </p>

        <button
          (click)="modal.dismiss()"
          aria-label="Close"
          class="close"
          type="button"
        >
          <span
            aria-hidden="true"
            class="font-24 text-muted"
          >
            <i class="mdi mdi-close"></i>
          </span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card mb-0 shadow-none">
              <div class="card-body p-0">
                <form
                  #f="ngForm"
                  (ngSubmit)="onSubmit(f.form, onboarding)"
                >
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label class="control-label">
                          <span>{{ 'onboardings.start_date' | translate }}</span>
                        </label>

                        <input [locale]="locale$ | async"
                               allowInput="true"
                               altFormat="d.m.Y"
                               altInput="true"
                               class="form-control"
                               dateFormat="Y-m-d"
                               mwlFlatpickr
                               name="start_date"
                               ngModel
                               type="text"
                        >
                      </div>
                    </div>
                    <div class="col-12 text-right">
                      <small class="text-muted">
                        {{ 'onboardings.start_date_description' | translate }}
                      </small>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label class="control-label">
                          <span>{{ 'onboardings.employees' | translate }}</span>
                        </label>

                        <ng-select
                          [closeOnSelect]="false"
                          [items]="employees$ | async"
                          [multiple]="true"
                          bindLabel="fullname"
                          bindValue="employee_ID"
                          class="custom"
                          name="employeesIDs"
                          ngModel
                          required
                        ></ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-12 text-right">
                      <button class="btn btn-primary">
                        <span>{{ 'onboardings.action_save' | translate }}</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="row">
    <div class="col-lg-6 mb-3">
      <div class="card h-100 shadow">
        <app-help name="onboarding.detail.info"></app-help>
        <div class="card-header py-3">
          <div class="row">
            <div class="col-6 col-sm-6 col-md-7">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'global.basic_info' | translate }}</span>
              </p>
            </div>

            <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
              <button
                (click)="openEditModal(onboarding)"
                [updateButton]="'onboardings.action_edit' | translate"
              ></button>
            </div>
          </div>
        </div>

        <div class="card-body px-4">
          <div class="row border-bottom text-nowrap">
            <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
              <span class="text-dark">{{ 'onboardings.name' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span>{{ onboarding.name }}</span>
            </div>
          </div>

          <div class="row border-bottom text-nowrap">
            <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
              <span class="text-dark">{{ 'onboardings.type' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span>{{ onboarding.type_formatted }}</span>
            </div>
          </div>

          <div class="row border-bottom text-nowrap">
            <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
              <span class="text-dark">{{ 'onboardings.description' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span>{{ onboarding.description }}</span>
            </div>
          </div>

          <div class="row border-bottom text-nowrap">
            <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
              <span class="text-dark">{{ 'onboardings.created_at' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span>{{ onboarding.created_at | date: 'dd.MM.yyyy' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card shadow">
        <app-help name="onboarding.detail.tasks"></app-help>
        <div class="card-header py-3">
          <div class="row">
            <div class="col-6">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'onboardings_tasks.tasks' | translate }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-hover table-centered mb-0">
              <thead>
                <tr>
                  <th>{{ 'onboardings.name' | translate }}</th>

                  <th>{{ 'onboardings_tasks.deadline' | translate }}</th>

                  <th>{{ 'onboardings_tasks.assignee' | translate }}</th>

                  <th class="text-right">{{ 'onboardings.table_action' | translate }}</th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngIf="onboarding.tasks?.length else noTasksTemplate">
                  <tr *ngFor="let t of onboarding.tasks">
                    <td class="text-nowrap">
                      <span
                        class="d-inline-block text-truncate"
                        style="max-width: 250px;"
                      >{{ t.name }}</span>
                    </td>

                    <td class="text-nowrap">
                        <span *ngIf="t.deadline !== 'CUSTOM'">
                            {{ ('onboardings_tasks.deadline_' + t.deadline.toLowerCase()) | translate }}
                        </span>
                        <span *ngIf="t.deadline === 'CUSTOM'">
                            {{ t.interval }} x {{ (('onboardings_tasks.period_' + t.period) | translate).toLowerCase() }} {{ (('onboardings_tasks.' + t.period_type) | translate).toLowerCase() }}
                        </span>
                    </td>

                    <td class="text-nowrap">
                        <span *ngIf="t.assignee_label"> {{ t.assignee_label }} </span>
                        <span *ngIf="!t.assignee_label"> {{ 'onboardings_tasks.assignee_none' | translate }} </span>
                    </td>

                    <td class="text-nowrap text-right">
                      <button
                        (click)="openOnboardingTaskEditModal(t, onboarding)"
                        class="btn btn-white btn-icon btn-sm mr-0"
                      >
                        <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                      </button>
                    </td>
                  </tr>
                </ng-container>

                <ng-template #noTasksTemplate>
                  <tr class="text-center">
                    <td colspan="4">{{ 'global.empty_widget_error' | translate }}</td>
                  </tr>
                </ng-template>
              </tbody>

              <tfoot>
                <tr>
                  <td
                    class="px-0"
                    colspan="4"
                  >
                    <form
                      (ngSubmit)="onSubmitTask()"
                      [formGroup]="taskForm"
                    >
                      <div class="input-group border">
                        <input
                          class="form-control border-0"
                          formControlName="name"
                          name="name"
                          placeholder="{{ 'onboardings_tasks.action_create_task' | translate }}"
                          type="text"
                        >

                        <app-form-max-length-hint
                          [maxLength]="maxTaskNameLength"
                          [value]="taskForm.controls.name.value"
                        ></app-form-max-length-hint>

                        <button
                          [createButton]="'onboardings_tasks.action_create_task' | translate"
                          [shouldSubmit]="true"
                        ></button>
                      </div>

                      <div class="d-flex align-items-center validation-text">
                        <div *ngIf="taskFormSubmitted && taskForm.controls.name.errors">
                          <p
                            *ngIf="taskForm.controls.name.errors.required"
                            class="ml-1 text-danger"
                          >
                            <span>{{ 'angular_validation.field' | translate }} </span>
                            <span>{{ 'angular_validation.required' | translate }}</span>
                          </p>

                          <p
                            *ngIf="taskForm.controls.name.errors.maxlength"
                            class="ml-1 text-danger"
                          >
                            <span>{{ 'angular_validation.max_length_prefix' | translate }} </span>
                            <span>{{ taskForm.controls.name.errors.maxlength.requiredLength }} </span>
                            <span>{{ 'angular_validation.max_length_suffix' | translate }}</span>
                          </p>
                        </div>
                      </div>
                    </form>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card shadow">
        <app-help name="onboarding.detail.employees"></app-help>
        <div class="card-header py-3">
          <div class="row">
            <div class="col-6">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'onboardings.employees' | translate }}</span>
              </p>
            </div>

            <div class="col-6 text-right">
              <button
                (click)="openAssignModal(content)"
                [createButton]="'onboardings.assign_employee' | translate"
              ></button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-hover table-centered mb-0">
              <thead>
                <tr>
                  <th>{{ 'employees.full_name' | translate }}</th>
                  <th>{{ 'onboardings.completed_tasks' | translate }}</th>
                  <th class="text-right pr-3">{{ 'onboardings.table_action' | translate }}</th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngIf="onboarding.employees?.length else noEmployeesTemplate">
                  <tr *ngFor="let employee of onboarding.employees">
                    <td class="text-nowrap"> <app-employee-box-avatar [avatar]="employee.avatar" [canView]="employee.permissions.view" [employeeID]="employee.employee_ID" [fullname]="employee.fullname" [position]="employee.position"></app-employee-box-avatar></td>

                      <td>{{employee.employee_onboarding?.completed_tasks_count}} / {{employee.employee_onboarding?.total_tasks_count}}</td>

                    <td class="text-nowrap text-right">
                      <a
                      (click)="openEmployeeOnboardingOverviewModal(employee.employee_ID, employee.employee_onboarding)"
                      class="btn btn-white btn-icon btn-sm mr-1"
                      title="{{ 'employees.action_view' | translate }}"
                    >
                      <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                    </a>
                    </td>
                  </tr>
                </ng-container>

                <ng-template #noEmployeesTemplate>
                  <tr class="text-center">
                    <td colspan="2">{{ 'global.empty_widget_error' | translate }}</td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loadingTemplate>
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="card shadow p-3">
                <ngx-skeleton-loader [theme]="{ width: '100%', 'margin': '25px 0', 'display': 'block' }"
                                     appearance="line"
                                     count="6">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card shadow p-3">
                <ngx-skeleton-loader [theme]="{ width: '100%', 'margin': '25px 0', 'display': 'block' }"
                                     appearance="line"
                                     count="7">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card shadow p-3">
                <ngx-skeleton-loader [theme]="{ width: '100%', 'margin': '25px 0', 'display': 'block' }"
                                     appearance="line"
                                     count="7">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
</ng-template>
