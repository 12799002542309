import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CompanyService } from 'src/app/setting/services/company.service';
import { FileService } from 'src/app/shared/services/file.service';
import { AppService } from '../../../../shared/services/app.service';

@Component({
    selector: 'app-setting-general',
    templateUrl: './setting-general.component.html',
    styleUrls: ['./setting-general.component.css']
})
export class SettingGeneralComponent implements OnInit {
    @ViewChild('logoModal', {static: false})
    public logoModal: TemplateRef<ElementRef>;

    public availableLocales$: Observable<Array<string>>;
    public canViewCompanyBranches$: Observable<{ can: boolean }>;
    public canViewCompanySettings$: Observable<{ can: boolean }>;
    public canViewCompanyCurrencies$: Observable<{ can: boolean }>;
    public imagePath: any;
    public baseImage: any;
    public customLogo: boolean;
    public deleteRequested: string;
    public localeForm: UntypedFormGroup;
    public companySettings$: Observable<any>;
    public logoDownloadUrl?: string;

    private _logoModalRef: NgbModalRef;

    public constructor(
        private _appService: AppService,
        private _authService: AuthenticationService,
        private _companyService: CompanyService,
        private sanitizer: DomSanitizer,
        private _fileService: FileService,
        private _modalService: NgbModal,
        private _fb: UntypedFormBuilder,
    ) { }

    public ngOnInit(): void {
        this.availableLocales$ = this._appService.getAvailableLanguages();

        this.canViewCompanyBranches$ = this._authService.hasPermissionTo('companyBranch.view');
        this.canViewCompanySettings$ = this._authService.hasPermissionTo('companySetting.view');
        this.canViewCompanyCurrencies$ = this._authService.hasPermissionTo('currency.view');

        this.localeForm = this._fb.group({
            'app.locale': [''],
            'app.locale_update_self': [true],
            'app.locale_update_all': [false],
            'app.locale_update_enforce': [false],
        });

        this._getCompanySettings();
    }

    public refresh(): void {
        this._getCompanySettings();
    }

    public removeLogo(): void {
        this._companyService.deleteLogo().then(() => this.refetchSettings());
    }

    public openLogoModal(): void {
        this._logoModalRef = this._modalService.open(this.logoModal);
    }

    public refetchSettings() {
        window.location.reload();
    }

    public onSubmitLocale(): void {
        const value = this.localeForm.value;

        value.settings_type = 'app';

        this._companyService.saveCompanySetting(value)
            .then(() => this.refetchSettings());
    }

    public deleteTenant(cancelDeletion: boolean): void {
        this._companyService.deleteTenant(cancelDeletion).then(() => this._getCompanySettings());
    }

    private _getCompanySettings() {
        this.companySettings$ = this._companyService.getCompanySetting()
            .pipe(tap(settings => {
                    this.localeForm.patchValue({'app.locale': settings.company_settings.app?.locale});
                    this.deleteRequested = settings.delete_requested;
                    this.logoDownloadUrl = settings.company_logo_file?.download_url;
                }),
                shareReplay());

        this._companyService.getLogo('400', '150').subscribe((baseImage: any) => {
            this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(baseImage.logoBase64);
            this.customLogo = baseImage.customLogo;
            this.baseImage = baseImage.logoBase64;
        });
    }
}
