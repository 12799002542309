<ng-container>
  <div class="card mb-0 shadow">
    <div class="card-body p-0">
      <div class="card mb-0">
          <div class="card-header">{{ 'imports.template_file' | translate }}</div>
          <div class="card-body">
              <div class="row">
                  <div class="col-12">
                      <span> {{ 'imports.download_template_file_1' | translate }} </span>
                      <span *ngFor="let file of importFiles; let isLast = last">
                          <a *ngIf="translateService.currentLang === file.locale" download="{{file.download_name}}" target="_blank" href="assets/files/{{file.name}}">
                              <i class="mdi mdi-download"> </i> {{file.download_name}}</a>
                      </span>
                      <span>. {{ 'imports.download_template_file_2' | translate }} </span>
                  </div>
              </div>
          </div>
          <div class="card-header">{{ 'imports.file' | translate }}</div>
          <div class="card-body">
              <div class="row">
                  <div class="col-12">
                      <app-upload
                          (addedFile)="addedFile($event)"
                          [uploadMultiple]="false"
                          [allowTakingPicture]="false"
                          [allowedExtensions]="'.xlsx, .xls'"
                          [clear$]="clearFileQueue$"
                          [process$]="processFileQueue$"
                          [showPreviewContainer]="true"
                      ></app-upload>
                  </div>
              </div>
              <div class="row mt-1">
                  <div class="col-12 text-right">
                      <small class="text-muted">
                          {{ 'imports.file_hint' | translate }}
                      </small>
                  </div>
              </div>
          </div>
      <ng-container *ngIf="preview$ | async as preview; else loadingTemplate">
          <div class="card-header">{{ 'imports.preview_template' | translate }}</div>
          <div class="card-body">
              <label class="control-label">{{ 'imports.from_template' | translate }}</label>
                  <ng-select
                      bindLabel="name"
                      class="custom w-100"
                      name="importTemplate"
                      [clearable]="false"
                      [items]="importTemplates$ | async"
                      [ngModel]="selectedImportTemplate"
                      (change)="onSelectImportTemplate($event)"
                  ></ng-select>
              <div class="row mt-1">
                  <div class="col-12 text-right">
                      <small class="text-muted">
                          {{ 'imports.from_template_hint' | translate }}
                      </small>
                  </div>
              </div>
          </div>
        <div class="card-header">{{ 'imports.preview_header' | translate }}</div>

        <div class="card-body">
              <ng-container>
                <label class="control-label">{{ 'imports.header_rows_count' | translate }}</label>

                <input
                  class="form-control"
                  type="number"
                  (wheel)="$event.preventDefault()"
                  [ngModel]="headerRowsCount"
                  (ngModelChange)="headerRowsCount = $event"
                >

                <div *ngIf="headerRowsCountMissing">
                  <p class="text-danger validation-text">
                    {{ 'angular_validation.field' | translate }} {{ 'imports.header_rows_count' | translate }}
                    {{ 'angular_validation.required' | translate }}
                  </p>
                </div>
              </ng-container>
            <div class="row mt-1">
                <div class="col-12 text-right">
                    <small class="text-muted">
                        {{ 'imports.header_rows_hint' | translate }}
                    </small>
                </div>
            </div>
        </div>

      <div class="card-header">{{ 'imports.preview_data' | translate }}</div>
          <div class="card-body">
            <div class="">
              <table class="table table-hover table-responsive overflow-auto table-centered mb-0">
                <tr>
                  <th></th>
                  <th *ngFor="let columnIndex of columnIndices$ | async; let i = index">
                      <div class="form-group">
                    <ng-select
                      bindLabel="name"
                      class="custom"
                      [clearable]="false"
                      [items]="importableColumns$ | async"
                      groupBy="entity"
                      [ngModel]="selectedColumns[i]"
                      (change)="onSelect(i, $event)"
                    ></ng-select>

                      </div>
                  </th>
                </tr>

                <tr
                  *ngFor="let row of preview.rows; let i = index"
                  [class.header-row]="headerRowsCount > i"
                >
                  <td>{{ i + 1 }}</td>
                  <td
                    *ngFor="let column of row; let o = index"
                    [class.do-not-import-column]="selectedColumns[o].value === emptyImportableColumn.value"
                  >{{ column }}</td>
                </tr>
              </table>
            </div>
          </div>

          <ng-container *ngIf="!preview.template">
            <div class="card-header">{{ 'imports.preview_template' | translate }}</div>

            <div class="card-body">
              <div class="row">
                <div class="col-3">
                  <label class="control-label">{{ 'imports.save_as_template' | translate }}</label>

                  <div>
                    <input
                      data-switch="success"
                      id="saveAsTemplate"
                      name="saveAsTemplate"
                      type="checkbox"
                      [checked]="saveAsTemplate"
                      (change)="saveAsTemplate = !saveAsTemplate"
                    >
                    <label for="saveAsTemplate">&nbsp;</label>
                  </div>
                </div>

                <div class="col-3">
                  <ng-container *ngIf="saveAsTemplate">
                    <label class="control-label">{{ 'imports.template_name' | translate }}</label>

                    <input
                      class="form-control"
                      type="text"
                      [ngModel]="templateName"
                      (ngModelChange)="templateName = $event"
                    >

                    <div *ngIf="templateNameMissing">
                      <p class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{ 'imports.template_name' | translate }}
                        {{ 'angular_validation.required' | translate }}
                      </p>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="card-body">
            <div
              *ngIf="preview.total_rows_count > preview.rows.length"
              class="row mt-4"
            >
              <div class="col-sm-6 text-left">
                <div class="row">
                  <span>{{ 'imports.rows_shown' | translate }}</span>
                  <span>: </span>
                  <span>{{ preview.rows.length }}</span>
                </div>

                <div class="row">
                  <span>{{ 'imports.rows_total' | translate }}</span>
                  <span>: </span>
                  <span>{{ preview.total_rows_count }}</span>
                </div>
              </div>
            </div>

              <div *ngIf="showErrorMessage && submitted" class="row">
                  <div class="col-12 text-danger">
                      Email
                      {{ 'angular_validation.must_be_selected' | translate }}
                  </div>
              </div>

            <div class="row mt-2">
              <div class="col-12 text-right">
                <button
                  class="btn btn-success"
                  (click)="onImport()"
                >{{ 'imports.import' | translate }}</button>
              </div>
            </div>
          </div>
        </ng-container>
    </div>
  </div>
  </div>
</ng-container>

<ng-template #loadingTemplate>
    <div *ngIf="loading" class="row">
        <div class="col-12">
            <div class="px-3">
                <div class="item">
                    <ngx-skeleton-loader [theme]="{
        'margin': '15px 0'
      }" appearance="line" count="10"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</ng-template>
