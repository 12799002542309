<form (ngSubmit)="onSubmit()"
      [formGroup]="companyAddressSettingEditForm"
      autocomplete="off"
>
  <div class="row">
    <div class="col-md-12">
      <div class="card shadow">
        <app-help name="settings.company.address"></app-help>

        <div class="card-header py-3">
          <div class="row">
            <div class="col-sm-6 text-left my-auto">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'employees_addresses.address' | translate }}</span>
              </p>
            </div>
          </div>
        </div>

        <div *ngIf="companySettings$ | async; else loadingtemplate" class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label">
                  <span>{{ 'settings.company.address.addr_line_1' | translate }}</span>
                </label>

                <input
                  class="form-control"
                  formControlName="company.address.addr_line_1"
                  name="company.address.addr_line_1"
                  type="text"
                />
              </div>

              <div class="form-group">
                <label class="control-label">
                  <span>{{ 'settings.company.address.addr_line_2' | translate }}</span>
                </label>

                <input
                  class="form-control"
                  formControlName="company.address.addr_line_2"
                  name="company.address.addr_line_2"
                  type="text"
                />
              </div>

              <div class="form-group">
                <label class="control-label">
                  <span>{{ 'settings.company.address.city' | translate }}</span>
                </label>

                <input
                  class="form-control"
                  formControlName="company.address.city"
                  name="company.address.city"
                  type="text"
                />
              </div>

              <div class="form-group">
                <label class="control-label">
                  <span>{{ 'settings.company.address.postal_code' | translate }}</span>
                </label>

                <input
                  class="form-control"
                  formControlName="company.address.postal_code"
                  name="company.address.postal_code"
                  type="text"
                />
              </div>

              <div class="form-group">
                <label class="control-label">
                  <span>{{ 'settings.company.address.country_code' | translate }}</span>
                </label>

                <ng-select
                  [clearable]="false"
                  [items]="countryStoreService.countries$ | async"
                  bindLabel="name"
                  bindValue="country_code"
                  class="custom"
                  formControlName="company.address.country_code"
                  name="company.address.country_code"
                ></ng-select>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col d-flex justify-content-end">
              <button class="btn btn-success">
                <span>{{ 'global.action_save' | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<ng-template #loadingtemplate>
  <div class="row">
    <div class="col-12">
      <div class="p-3">
        <div class="item">
          <ngx-skeleton-loader [theme]="{
        'margin': '15px 0'
      }" appearance="line" count="9"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</ng-template>
