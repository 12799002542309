<div class="modal-content">
	<div class="modal-header">
			<p *ngIf="growthTask" class="modal-title font-16 mb-0 text-dark">{{'growths_tasks.action_edit' | translate}}</p>
			<p *ngIf="!growthTask" class="modal-title font-16 mb-0 text-dark">{{'growths_tasks.action_create' | translate}}</p>
		<button (click)="activeModal.dismiss()" type="button" class="close" aria-label="Close">
			<span class="font-24 text-muted" aria-hidden="true"><i class="mdi mdi-close"></i></span>
		  </button>
	</div>
	<div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <form [formGroup]="growthTaskEditForm" (ngSubmit)="onSubmit()" autocomplete="off">
                            <div class="row">
								<div class="col-lg-12">
									<div class="form-group">
                                        <label class="control-label">{{'growths_tasks.name' | translate}}</label>
										<input formControlName="name" class="form-control" type="text" name="name">
										<div *ngIf="submitted && f.name.errors">
											<p *ngIf="f.name.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'growths_tasks.name' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>
                            <div class="row mt-2">
                                <div class="col-6 text-left">
                                    <button class="btn btn-success" type="submit">{{'growths_tasks.action_save' | translate}}</button>
                                </div>
                                <div class="col-6 text-right my-auto">
									<u *ngIf="growthTask" (click)="deleteGrowthTask()" class="text-muted">{{'growths_tasks.action_delete' | translate}}</u>
								</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
	</div>
</div>
