<div class="modal-content">
    <div class="modal-header">
        <p
            *ngIf="userID"
            class="modal-title font-16 mb-0 text-dark"
        >{{ 'users.page_title_edit' | translate }}</p>

        <p
            *ngIf="!userID"
            class="modal-title font-16 mb-0 text-dark"
        >{{ 'users.page_title_create' | translate }}</p>

        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
        >
      <span
          aria-hidden="true"
          class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <form (ngSubmit)="onSubmit()" [formGroup]="userEditForm" autocomplete="off">
                            <div *ngIf="message !== '' && !(guard === 'all' && employeeRoles)" class="row">
                                <div class="col-12">
                                    <p class="pt-1 text-danger">
                                        <span> {{ message | translate }} </span>
                                        <a (click)="activeModal.dismiss()" routerLink="/setting/subscription-settings">
                                            <u>{{ 'subscription.message_button' | translate }}</u>
                                        </a>.
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'employees.name' | translate }}</span>
                                        </label>
                                        <input autocomplete="none"
                                               class="form-control"
                                               formControlName="name"
                                               name="name"
                                               type="text">
                                        <div *ngIf="submitted && f.name.errors">
                                            <p *ngIf="f.name.errors.required" class="text-danger validation-text">
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees.name' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'employees.surname' | translate }}</span>
                                        </label>
                                        <input autocomplete="none"
                                               class="form-control"
                                               formControlName="surname"
                                               name="surname"
                                               type="text">
                                        <div *ngIf="submitted && f.surname.errors">
                                            <p *ngIf="f.surname.errors.required" class="text-danger validation-text">
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees.surname' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'employees.email' | translate }}</span>
                                        </label>
                                        <input (keyup)="inputChanged($event)"
                                               autocomplete="none"
                                               class="form-control"
                                               formControlName="email"
                                               name="email"
                                               type="text">
                                        <div *ngIf="emailTaken === true">
                                            <p class="text-danger">
                                                <span>{{ 'users.email_taken' | translate }}</span>
                                            </p>
                                        </div>
                                        <div *ngIf="submitted && f.email.errors">
                                            <p *ngIf="f.email.errors.required" class="text-danger validation-text">
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees.email' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                            <p *ngIf="f.email.errors.email" class="text-danger">{{ 'angular_validation.valid_email' | translate }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'employees.phone' | translate }}</span>
                                        </label>
                                        <input autocomplete="none"
                                               class="form-control"
                                               formControlName="phone"
                                               name="phone"
                                               type="text">
                                        <div *ngIf="submitted && f.phone.errors">
                                            <p *ngIf="f.phone.errors.phone" class="text-danger">{{ 'angular_validation.valid_phone' | translate }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="guard === 'all' && employeeRoles && (employeesUsersCounts$ | async); let counts" class="row">
                                <div class="col-6">
                                    <label class="control-label mb-0">{{ 'subscription.is_type_employee' | translate }}</label>
                                </div>
                                <div class="col-6 text-right align-self-center">
                                    <div>
                                        <input (change)="guardTypeChanged(counts)" data-switch="success" formControlName="is_employee_guard_type" id="is_employee_guard_type" name="is_employee_guard_type" type="checkbox">
                                        <label class="mb-0" for="is_employee_guard_type">&nbsp;</label>
                                    </div>
                                </div>
                                <div class="col-12 mb-3 text-right">
                                    <small *ngIf="userEditForm.controls.is_employee_guard_type.value === true" class="text-muted">
                                        {{ 'subscription.is_employee_guard_type_text' | translate }}
                                    </small>
                                    <small *ngIf="userEditForm.controls.is_employee_guard_type.value === false" class="text-muted">
                                        {{ 'subscription.is_user_guard_type_text' | translate }}
                                    </small>
                                    <p *ngIf="message !== ''" class="pt-1 text-danger">
                                        <span> {{ message | translate }} </span>
                                        <a (click)="activeModal.dismiss()" routerLink="/setting/subscription-settings">
                                            <u>{{ 'subscription.message_button' | translate }}</u>
                                        </a>.
                                    </p>
                                </div>
                            </div>
                            <div *ngIf="employeeRoles && guardType === 'employee'" class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'employees.role' | translate }}</span>
                                        </label>
                                        <ng-select [items]="employeeRoles" bindLabel="name" bindValue="role_ID" class="custom" formControlName="role" placeholder="Vyberte roli"></ng-select>
                                        <div *ngIf="submitted && f.role.errors">
                                            <p *ngIf="f.role.errors.required" class="text-danger validation-text">
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees.role' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="userRoles && guardType === 'user'" class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'employees.role' | translate }}</span>
                                        </label>
                                        <ng-select [items]="userRoles" bindLabel="name" bindValue="role_ID" class="custom" formControlName="role" placeholder="Vyberte roli"></ng-select>
                                        <div *ngIf="submitted && f.role.errors">
                                            <p *ngIf="f.role.errors.required" class="text-danger validation-text">
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'users.role' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr class="mt-2 mb-3"/>

                            <div *ngIf="showActive && !userID" class="row">
                                <div class="col-6">
                                    <label class="control-label">{{ 'employees.is_active' | translate }}</label>
                                </div>
                                <div class="col-6 text-right align-self-center">
                                    <div>
                                        <input data-switch="success" formControlName="is_active" id="is_active" name="is_active" type="checkbox">
                                        <label for="is_active">&nbsp;</label>
                                    </div>
                                </div>
                                <div *ngIf="userEditForm.controls.is_active.value" class="col-12 text-right">
                                    <small class="text-muted">
                                        {{ 'users.is_active_text' | translate }}
                                    </small>
                                    <small *ngIf="(subscription$ | async)?.subscription.per_seats_pricing" class="text-muted">
                                        {{ 'subscription.subscription_price_may_change' | translate }}
                                    </small>
                                </div>
                            </div>

                            <div *ngIf="emailTaken" class="row">
                                <div class="col">
                                    <p class="text-danger">
                                        <span>{{ 'users.email_taken' | translate }}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <p *ngIf="message !== ''" class="pt-1 text-danger">
                                        <span> {{ message | translate }} </span>
                                        <a (click)="activeModal.dismiss()" routerLink="/setting/subscription-settings">
                                            <u>{{ 'subscription.message_button' | translate }}</u>
                                        </a>.
                                    </p>
                                </div>
                                <div class="col-12 d-flex justify-content-end">
                                    <button (click)="deleteUser()" *ngIf="userID && !isLoggedInUser && user && !user.is_admin" class="btn btn-link text-muted pr-0 mr-4" type="button">
                                        <u>{{ 'users.action_delete' | translate }}</u>
                                    </button>
                                    <button [disabled]="emailTaken || !showButton" class="btn btn-success" type="submit">
                                        {{ 'numbers_series.action_save' | translate }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
