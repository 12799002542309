import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CompanyGroupState, CompanyGroupStore } from './company-group.store';

@Injectable({ providedIn: 'root' })
export class CompanyGroupQuery extends QueryEntity<CompanyGroupState> {
    constructor(protected store: CompanyGroupStore) {
        super(store);
    }
}
