<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">{{'properties.page_title_delete' | translate}}</p>
    <button (click)="activeModal.dismiss()" type="button" class="close" aria-label="Close">
      <span class="font-24 text-muted" aria-hidden="true"><i class="mdi mdi-close"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <form [formGroup]="propertyDeleteForm" autocomplete="off" (ngSubmit)="onSubmitPropertyDelete()">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">{{'properties.delete_reason' | translate}}</label>
                    <ng-select formControlName="delete_reason" class="custom" name="delete_reason">
                      <ng-option value="SELL">{{'properties.reason_SELL' | translate}}</ng-option>
                      <ng-option value="DISPOSAL">{{'properties.reason_DISPOSAL' | translate}}</ng-option>
                      <ng-option value="GIFT">{{'properties.reason_GIFT' | translate}}</ng-option>
                      <ng-option value="OTHER">{{'properties.reason_OTHER' | translate}}</ng-option>
                    </ng-select>
                    <div *ngIf="deleteSubmitted && propertyDeleteForm.controls.delete_reason.errors">
                      <p *ngIf="propertyDeleteForm.controls.delete_reason.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'properties.delete_reason' | translate}}
                        {{'angular_validation.required' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">{{'properties.delete_note' | translate}}</label>
                    <textarea formControlName="delete_note" class="form-control" name="delete_note" rows="5">
                    </textarea>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col d-flex justify-content-end">
                  <button class="btn btn-primary" type="submit">
                    {{'properties.action_delete' | translate}}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
