import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { File } from '../../../../employee/models/File';

@Component({
    selector: 'app-multi-files-preview-modal',
    templateUrl: './multi-files-preview-modal.component.html',
    styleUrls: ['./multi-files-preview-modal.component.css']
})
export class MultiFilesPreviewModalComponent implements OnInit {
    @Input() public files$: Observable<File[]>;

    constructor(
        public activeModal: NgbActiveModal,
    ) { }

    public ngOnInit(): void {

    }
}
