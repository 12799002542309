import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { OnboardingState, OnboardingStore } from './onboarding.store';

@Injectable({ providedIn: 'root' })
export class OnboardingQuery extends QueryEntity<OnboardingState> {
    constructor(protected store: OnboardingStore) {
        super(store);
    }
}
