import { ChangeDetectorRef, Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { AvailableFeatures } from '../../../../employee/models/AvailableFeatures';
import { CreateEditDocumentTemplateComponent } from '../../../../setting/components/partials/create-edit-document-template/create-edit-document-template.component';
import { DocumentTemplate } from '../../../../setting/models/DocumentTemplate';
import { DocumentTemplateService } from '../../../../setting/services/document-template.service';
import { SubscriptionService } from '../../../../setting/services/subscription.service';
import { FatherOfListComponent, Sort, SORT_ASC } from '../../../../ui';

@Component({
    selector: 'app-document-templates-list',
    templateUrl: './document-templates-list.component.html',
    styleUrls: ['./document-templates-list.component.css']
})
export class DocumentTemplatesListComponent extends FatherOfListComponent<DocumentTemplate> implements OnInit {
    @ViewChild('filterFormOptions') public filterModal: TemplateRef<any>;

    public availableFeatures$: Observable<AvailableFeatures>;
    public canCreateTemplate$: Observable<boolean>;
    public documentTemplates$: Observable<Array<DocumentTemplate>>;
    public filterForm: UntypedFormGroup;
    public sort: Sort<DocumentTemplate> = {
        column: 'name',
        direction: SORT_ASC
    };
    public selectOptions = [
        {value: 'yes', label: this._translateService.instant('global.YES')},
        {value: 'no', label: this._translateService.instant('global.NO')},
    ];

    private _fetchTemplates$ = new ReplaySubject<void>(1);
    private _filterFormModalRef: NgbModalRef;

    public constructor(
        private _documentTeplateService: DocumentTemplateService,
        private _modalService: NgbModal,
        private _subscriptionService: SubscriptionService,
        private _authService: AuthenticationService,
        private _fb: FormBuilder,
        private _translateService: TranslateService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
    ) {
        super(_ngZone, _changeDetectorRef);
    }

    public ngOnInit(): void {
        this.filterForm = this._fb.group({
            active: [true],
            signable: [null], // yes, no, null - all
            bulk_generate: [null], // yes, no, null - all
        });

        this.availableFeatures$ = this._subscriptionService.availableFeatures$;

        this.canCreateTemplate$ = this._authService.hasPermissionTo('documentTemplate.create')
            .pipe(map(perm => perm.can));

        this._rows$ = this._fetchTemplates$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                switchMap(params => this._documentTeplateService.getTemplatesList(params, this.filterForm.value)),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();
        this._fetchListData();
    }

    public openCreateModal(): void {
        const modalRef = this._modalService.open(CreateEditDocumentTemplateComponent, {centered: true});

        modalRef.result
            .then(
                () => this._fetchListData(),
                () => { }
            );
    }

    public openFilterFormOptions(): void {
        this._filterFormModalRef = this._modalService.open(this.filterModal, {centered: true});
    }

    public onSubmittedFilterForm(): void {
        this._filterFormModalRef.close();
        this._fetchListData();
    }

    public trackByFn(index: number, documentTemplate: DocumentTemplate): number {
        return documentTemplate.document_template_ID;
    }

    protected _fetchListData(): void {
        this._fetchTemplates$.next();
    }
}
