<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">
      <span>{{ 'files.take_a_picture' | translate }}</span>
    </p>

    <button
      aria-label="Close"
      class="close"
      (click)="activeModal.dismiss()"
    >
      <span
        aria-hidden="true"
        class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <div
              *ngIf="!webcamImage"
              class="row"
            >
              <div class="col-12 d-flex flex-column align-items-center justify-content-center">
                <div class="wrapper__camera">
                  <webcam
                    [allowCameraSwitch]="false"
                    [imageQuality]="1"
                    [trigger]="trigger"
                    (imageCapture)="handleImage($event)"
                    (initError)="handleInitError($event)"
                  ></webcam>
                </div>

                <div class="d-flex w-100 justify-content-end">
                  <button class="btn btn-primary" (click)="triggerSnapshot();">
                    <span>{{ 'files.take_a_picture' | translate }}</span>
                  </button>
                </div>
              </div>
            </div>

            <ng-container *ngIf="webcamImage">
              <div class="row">
                <div class="col-12 d-flex align-items-center justify-content-center">
                  <img
                    class="img-fluid"
                    [src]="webcamImage.imageAsDataUrl"
                  >
                </div>
              </div>

              <div class="row mt-2">
                <div class="col d-flex justify-content-end">
                  <button class="btn btn-link text-muted pr-0 mr-4" (click)="webcamImage = null">
                    <u>{{ 'files.remove' | translate }}</u>
                  </button>
                  <button class="btn btn-primary" (click)="activeModal.close(webcamImage.imageAsDataUrl)">
                    <span>{{ 'files.action_upload' | translate }}</span>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
