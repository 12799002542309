import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { Benefit } from 'src/app/benefit-bonus/benefit/state/benefit.model';
import { BenefitService } from 'src/app/benefit-bonus/benefit/state/benefit.service';
import { FlatpickrHelper } from '../../../../shared/common/FlatpickrHelper';
import { InputVisibilityService } from '../../../../shared/services/InputVisibilityService';
import { CreateEditBenefitModalComponent } from '../../partials/create-edit-benefit-modal/create-edit-benefit-modal.component';

@Component({
    selector: 'app-benefit-overview',
    templateUrl: './benefit-overview.component.html',
    styleUrls: ['./benefit-overview.component.css']
})
export class BenefitOverviewComponent implements OnInit {
    public benefit$: Observable<Benefit>;

    private _benefitId$: Observable<number>;
    private _fetchBenefit$ = new ReplaySubject<void>(1);

    public constructor(
        public fpHelper: FlatpickrHelper,
        private _benefitService: BenefitService,
        private _modalService: NgbModal,
        private _route: ActivatedRoute,
        private _inputVisibilityService: InputVisibilityService
    ) { }

    public get hiddenInputs(): boolean {
        return this._inputVisibilityService.hiddenInputs;
    }

    public ngOnInit(): void {
        this._benefitId$ = this._route.params
            .pipe(
                map(params => parseInt(params.benefitID, 10)),
                shareReplay()
            );

        this.benefit$ = combineLatest([
            this._fetchBenefit$,
            this._benefitId$
        ])
            .pipe(
                switchMap(([noop, benefitId]) => this._benefitService.getBenefitByID(benefitId)),
                shareReplay()
            );

        this._fetchBenefit$.next();
    }

    public openEditModal(benefit: Benefit): void {
        const modalRef = this._modalService.open(CreateEditBenefitModalComponent, {centered: true});

        modalRef.componentInstance.benefit = benefit;

        modalRef.result
            .then(
                () => this._fetchBenefit$.next(),
                () => { }
            );
    }
}
