import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { EmployeeDatetimeType } from 'src/app/setting/models/EmployeeDatetimeType';
import { AttendanceService } from '../../../../employee/services/attendance.service';

@Component({
    selector: 'app-create-multiple-attendance',
    templateUrl: './create-multiple-attendance.component.html',
    styleUrls: ['./create-multiple-attendance.component.css']
})
export class CreateMultipleAttendanceComponent implements OnInit {
    public datetimeType: EmployeeDatetimeType;
    public form: UntypedFormGroup;
    public submitted = false;
    public datesItems: { value: string, label: string, group: string }[] = [];

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.form.controls;
    }

    public constructor(
        public activeModal: NgbActiveModal,
        public translateService: TranslateService,
        private _attendanceService: AttendanceService,
        private _fb: UntypedFormBuilder,
    ) { }

    public ngOnInit(): void {
        this.form = this._fb.group({
            employee_datetime_type_ID: [this.datetimeType.employee_datetime_type_ID],
            dates: [null, Validators.required],
            note: [null],
        });

        const from = moment().subtract(1, 'year').startOf('year');
        const to = moment().add(1, 'year').endOf('year');

        while (from.isBefore(to)) {
            this.datesItems.push({
                value: from.format('YYYY-MM-DD'),
                label: from.format('DD.MM.YYYY') + ' (' + this.translateService.instant('dates.' + from.format('ddd')) + ')',
                group: this.translateService.instant('global.month_' + (from.month() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})) + ' ' + from.year()
            });
            from.add(1, 'day');
        }
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.form.valid) {
            return;
        }

        this._attendanceService.createMultipleAttendance(this.form.value)
            .then(() => this.activeModal.close('close'));
    }
}
