import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, from, Observable, ReplaySubject } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { GrowthTask } from 'src/app/growth/growth/state/growth-task.model';
import { Growth } from 'src/app/growth/growth/state/growth.model';
import { GrowthService } from 'src/app/growth/growth/state/growth.service';
import { TEXT_MAX_LENGTH } from 'src/app/ui';
import { CreateEditGrowthModalComponent } from '../../partials/create-edit-growth-modal/create-edit-growth-modal.component';
import { CreateEditGrowthTaskModalComponent } from '../../partials/create-edit-growth-task-modal/create-edit-growth-task-modal.component';

@Component({
    selector: 'app-growth-overview',
    templateUrl: './growth-overview.component.html',
    styleUrls: ['./growth-overview.component.css']
})
export class GrowthOverviewComponent implements OnInit {
    public employees$: Observable<Array<Employee>>;
    public growth$: Observable<Growth>;
    public maxTaskNameLength = TEXT_MAX_LENGTH;
    public taskForm: UntypedFormGroup;
    public taskFormSubmitted = false;

    private _fetchGrowth$ = new ReplaySubject<void>(1);
    private _growthId$: Observable<number>;

    public constructor(
        private _employeeService: EmployeeService,
        private _formBuilder: UntypedFormBuilder,
        private _growthService: GrowthService,
        private _modalService: NgbModal,
        private _route: ActivatedRoute
    ) { }

    public ngOnInit(): void {
        this._growthId$ = this._route.params
            .pipe(map(params => parseInt(params.growthID, 10)));

        this.growth$ = combineLatest([
            this._fetchGrowth$,
            this._growthId$
        ])
            .pipe(
                switchMap(([noop, growthId]) => combineLatest([
                    this._growthService.getGrowthByID(growthId),
                    this._growthService.getEmployeesForGrowth(growthId)
                ])),
                map(([growth, employees]) => ({
                    ...growth,
                    employees
                }))
            );

        this.employees$ = this._employeeService.getAllEmployees(false, 'select');

        this._fetchGrowth$.next();

        this.taskForm = this._formBuilder.group({
            name: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(this.maxTaskNameLength)
                ]
            ]
        });
    }

    public onSubmit(f: UntypedFormGroup, growth: Growth): void {
        if (!f.valid) {
            return;
        }

        const value = f.value;

        this._growthService.assignEmployeesToGrowth(growth, value.employeesIDs)
            .then(() => this._fetchGrowth$.next());
    }

    public onSubmitTask(): void {
        this.taskFormSubmitted = true;

        if (!this.taskForm.valid) {
            return;
        }

        this.growth$
            .pipe(
                first(),
                switchMap(growth => from(this._growthService.saveGrowthTask(this.taskForm.value, growth.growth_ID, null, null)))
            )
            .subscribe(() => {
                this.taskForm.reset();

                this.taskFormSubmitted = false;

                this._fetchGrowth$.next();
            });
    }

    public openAssignModal(content: any): void {
        const modalRef = this._modalService.open(content, {centered: true});

        modalRef.result
            .then(
                () => this._fetchGrowth$.next(),
                () => { }
            );
    }

    public openEditModal(growth: Growth): void {
        const modalRef = this._modalService.open(CreateEditGrowthModalComponent, {centered: true});

        modalRef.componentInstance.growth = growth;

        modalRef.result
            .then(
                () => this._fetchGrowth$.next(),
                () => { }
            );
    }

    public openGrowthTaskEditModal(growthTask: GrowthTask, growth: Growth): void {
        const modalRef = this._modalService.open(CreateEditGrowthTaskModalComponent, {centered: true});

        modalRef.componentInstance.growth = growth;

        modalRef.componentInstance.growthTask = growthTask;

        modalRef.result
            .then(
                () => this._fetchGrowth$.next(),
                () => { }
            );
    }

    public unassignEmployeeFromGrowth(employeeID: number): void {
        this._growthId$
            .pipe(
                first(),
                switchMap(growthId => from(this._growthService.unAssignEmployeeFromGrowth(growthId, employeeID)))
            )
            .subscribe(() => this._fetchGrowth$.next());
    }
}
