import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContentLoaderModule } from '@ngneat/content-loader';

import { RowLoaderComponent } from './components/row-loader/row-loader.component';

@NgModule({
  imports: [
    CommonModule,

    ContentLoaderModule,
  ],
  declarations: [
    RowLoaderComponent,
  ],
  exports: [
    RowLoaderComponent,
  ]
})
export class UIRowLoaderModule { }
