import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { LoginResponse } from '../../core/models/LoginResponse';
import { Workspace } from '../models/Workspace';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceService {
    public available$: Observable<Array<Workspace>>;

    public constructor(
        private _http: HttpClient
    ) {
        this._init();
    }

    public switch(workspace: Workspace): Observable<LoginResponse> {
        return this._http.post<LoginResponse>('/api/workspace/switch', {tenant_name: workspace.value});
    }

    public processMagicLink(url: string): Observable<LoginResponse> {
        return this._http.get<LoginResponse>(url);
    }

    private _fetchAvailable(): Observable<Array<Workspace>> {
        return this._http.get<Array<Workspace>>('/api/workspace/available');
    }

    private _init(): void {
        this.available$ = this._fetchAvailable()
            .pipe(shareReplay());
    }
}
