import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { ApiHelper } from '../../shared/common/ApiHelper';
import { SwalHelper } from '../../shared/common/SwalHelper';
import { ApiResponse } from '../../shared/models/ApiResponse';
import { CustomField } from '../../shared/models/CustomField';

@Injectable({
    providedIn: 'root',
})
export class CustomFieldService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _swalHelper: SwalHelper
    ) { }

    public getCustomFields(widget?: string): Observable<CustomField[]> {
        let url = '/api/custom-fields/definitions';
        if (widget) {
            url += '?widget=' + widget;
        }
        return this._http.get<CustomField[]>(url);
    }

    public saveCustomField(form: CustomField, customFieldID?: number): Promise<'done'> {
        return new Promise(resolve => {
            this._loaderService.start();

            if (customFieldID) {
                this._http.post<ApiResponse>('/api/custom-fields/definitions/' + customFieldID, form)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');

                            this._loaderService.stop();
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);

                            this._loaderService.stop();
                        }
                    );
            } else {
                this._http.post<ApiResponse>('/api/custom-fields/definitions', form)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');

                            this._loaderService.stop();
                        },
                        error => {

                            this._apiHelper.handleErrorResponse(error);

                            this._loaderService.stop();
                        }
                    );
            }
        });
    }

    public deleteCustomField(customFieldID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete('/api/custom-fields/definitions/' + customFieldID)
                    .subscribe(
                        (response: any) => {
                            this._loaderService.stop();

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }
}
