import { ListParameters } from './interface/list-parameters.interface';

function transformParameters<T>(params: ListParameters<T>): { [key: string]: any } {
  return {
    length: params.itemsPerPage,
    order: [{
      column: params?.sort?.name || params?.sort?.column,
      dir: params?.sort?.direction
    }],
    page: params.page,
    search: params.search || ''
  };
}

export {
  transformParameters
};
