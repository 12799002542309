import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AttendanceService } from 'src/app/employee/services/attendance.service';

@Component({
  selector: 'app-employee-attendance-gross-salary-count',
  templateUrl: './employee-attendance-gross-salary-count.component.html',
  styleUrls: ['./employee-attendance-gross-salary-count.component.css']
})
export class EmployeeAttendanceGrossSalaryCountComponent implements OnInit {

  employees$;
  month: number;
  year: number;
  datetimeDocumentStatus$: Observable<{step1: boolean, step2: boolean, step3: boolean, step4: boolean, step5: boolean}>;

  constructor(
    private attendanceService: AttendanceService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.year = parseInt(this.route.snapshot.queryParamMap.get('year'), 10);
    this.month = parseInt(this.route.snapshot.queryParamMap.get('month'), 10);
    this.employees$ = this.attendanceService.getNormalizedDatetimesDataForCheck(this.month, this.year);
    this.datetimeDocumentStatus$ = this.attendanceService.getDatetimesSummaryStatus(this.month, this.year);
  }

  onSubmit(value: any) {
    const promise = this.attendanceService.saveNormalizedDatetimesAfterGrossSalaryCheck(value, this.month, this.year);
    promise.then(result => {
      this.router.navigate(['/employee', 'attendance', 'documents', 'detail'], {queryParams: {month: this.month, year: this.year}});
    });
  }

}
