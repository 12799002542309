import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { EmployeeReview } from 'src/app/kpi/employee-review/employee-review.model';
import { EmployeeReviewService } from 'src/app/kpi/employee-review/employee-review.service';
import { KPI } from 'src/app/kpi/kpi/state/kpi.model';
import { SummernoteHelper } from 'src/app/shared/common/SummernoteHelper';
import { FlatpickrHelper } from '../../../../shared/common/FlatpickrHelper';

const SLIDER_CHANGE_END_BACKDROP_CLICK_DIFF_IN_MS = 200;

@Component({
    selector: 'app-create-edit-employee-review-modal',
    templateUrl: './create-edit-employee-review-modal.component.html',
    styleUrls: ['./create-edit-employee-review-modal.component.css']
})
export class CreateEditEmployeeReviewModalComponent implements OnInit {
    public canDelete$: Observable<boolean>;
    public employeeID: number;
    public kpis: Array<KPI>;
    public openedKpis = [];
    public kpiRadarCharts$: Observable<any>;
    public review: EmployeeReview;
    public submitted = false;
    public loading = false;
    public availableRatings = ['1', '2', '3', '4', '5'];
    public showAllRatings = true;
    public workspaceTenantID = this._authService.loggedUser.workspace.tenant_ID;
    private _sliderChangeEnd$ = new BehaviorSubject<number>(Date.now());

    public constructor(
        public activeModal: NgbActiveModal,
        private _authService: AuthenticationService,
        private _employeeReviewService: EmployeeReviewService,
        public summernoteHelper: SummernoteHelper,
        public fpHelper: FlatpickrHelper,
    ) { }

    public ngOnInit(): void {

        // check if it is specific tenant, if yes, set
        if (this.workspaceTenantID === '9478de01-01f1-4bce-98b5-b926d94a6274') {
            this.availableRatings = ['1', '2', '3', '4'];
            this.showAllRatings = false;
        }

        this.canDelete$ = combineLatest([
            this._authService.hasPermissionTo('employee.edit').pipe(map(permission => permission.can)),
            this._authService.hasPermissionTo('employee.editStructure').pipe(map(permission => permission.can))
        ])
            .pipe(map(([canEdit, canEditStructure]) => canEdit || canEditStructure));

        const reviewKPIsIDs = [];

        if (this.review) {
            for (const i of this.review.kpis) {
                reviewKPIsIDs.push(i.kpi_ID);
            }

            this.kpiRadarCharts$ = this._employeeReviewService.getRadarForEmployeeKPI([this.employeeID], reviewKPIsIDs, [this.review.employee_review_ID]).pipe(shareReplay());
        }
    }

    public deleteReview(): void {
        this._employeeReviewService.deleteReview(
            this.employeeID,
            this.review.employee_review_ID,
            this.activeModal
        );
    }

    public onSubmit(f: NgForm): void {
        this.submitted = true;
        if (!f.valid) {
            return;
        }

        this.loading = true;

        for (const kpi of this.kpis) {
            if (!this.openedKpis[kpi.kpi_ID]) {
                const kpiFormGroup = f.form.get('kpis').get(kpi.kpi_ID.toString());
                kpiFormGroup.disable();
            }
        }

        this._employeeReviewService.saveReview(f.value, this.employeeID, this.activeModal);
    }

    public sliderChangeEnd(): void {
        this._sliderChangeEnd$.next(Date.now());
    }

    public collapseKpi(event, kpiID): void {
        this.openedKpis[kpiID] = event.target.checked;
    }
}
