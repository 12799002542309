import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { RolesService } from 'src/app/setting/services/roles.service';
import { Role } from '../../../models/Role';

type ScopeType = 'all' | 'none' | 'structure';

interface PermissionFromServer {
    scope: ScopeType;
    scope_translate: string;
}

interface ActionFromServer {
    action: string;
    action_translate: string;
    permissions: Array<PermissionFromServer>;
    available_actions: Array<string>;
}

interface ModuleFromServer {
    module_actions: Array<ActionFromServer>;
    module_name: string;
    module_name_translate: string;
}

interface SectionFromServer {
    permissions: Array<ModuleFromServer>;
    section_name: string;
    section_name_translate: string;
}

export interface RolesReviewFromServer {
    headings: Array<string>;
    permissions: Array<SectionFromServer>;
    roles: Array<RoleWithPermissions>;
}

interface Permission {
    scope: ScopeType;
    scope_translate: string;
}

interface Action {
    action_name: string;
    action_name_translate: string;
    permissions: Array<Permission>;
    available_actions: Array<string>;
}

interface Module {
    actions: Array<Action>;
    module_name: string;
    module_name_translate: string;
}

interface Section {
    modules: Array<Module>;
    section_name: string;
    section_name_translate: string;
}

interface RolesReview {
    sections: Array<Section>;
    roles: Array<RoleWithPermissions>;
}

type RoleWithPermissions = Role & { permissions: { delete: boolean, edit: boolean } };

@Component({
    selector: 'app-roles-review',
    templateUrl: './roles-review.component.html',
    styleUrls: ['./roles-review.component.css']
})
export class RolesReviewComponent implements OnInit {
    public roles$: Observable<RolesReview>;
    public processedRole: RoleWithPermissions;
    public editEnabled = false;
    public roleNameInputValue = null;
    public submitted = false;

    private _fetchRoles$ = new ReplaySubject<void>(1);

    public constructor(
        private _roleService: RolesService
    ) { }

    public ngOnInit(): void {
        this.roles$ = this._fetchRoles$
            .pipe(
                switchMap(() => this._roleService.getRolesReview()),
                catchError(() => of([])),
                map((roles: RolesReviewFromServer) => this._renameKeysAndTrimModuleNames(roles)),
                shareReplay()
            );

        this._fetchRoles$.next();
    }

    public trackModules(index: number, module: Module): string {
        return module.module_name;
    }

    public trackSections(index: number, section: Section): string {
        return section.section_name;
    }

    public trackActions(index: number, action: Action): string {
        return action.action_name;
    }

    public trackPermissions(index: number, permission: Permission): string {
        return permission.scope;
    }

    private _renameKeysAndTrimModuleNames(rolesReview: RolesReviewFromServer): RolesReview {
        if (
            !rolesReview ||
            !rolesReview.headings ||
            !rolesReview.permissions
        ) {
            return null;
        }

        const obj = {
            sections: [...rolesReview.permissions].map(section => ({
                section_name: section.section_name,
                section_name_translate: section.section_name_translate,
                modules: [...section.permissions].map(module => ({
                    module_name: module.module_name,
                    module_name_translate: module.module_name_translate,
                    actions: [...module.module_actions].map(action => ({
                        action_name: action.action,
                        action_name_translate: action.action_translate
                            .replace(/^.*\((.*?)\)$/, '$1')
                            .replace('vše', ''),
                        available_actions: action.available_actions,
                        permissions: this.getPermissionsForAction(action)
                    }))
                }))
            })),
            roles: [...rolesReview.roles]
        };

        return obj;
    }

    public onSubmit(form: NgForm): void {
        this.submitted = true;

        if (this.processedRole.role_ID === 0 && !this.roleNameInputValue) {
            return;
        }

        const body = {
            name: this.processedRole.name ?? this.roleNameInputValue,
            level: this.processedRole.level ?? 5,
            permissions: form.value
        };

        const roleID = this.processedRole.role_ID === 0 ? null : this.processedRole.role_ID;

        this._roleService.saveRoleWithPermissions(body, roleID)
            .then(() => {
                this._fetchRoles$.next();
                this.toggleEditEnabled();
            });
    }

    public setProcessedRole(role: RoleWithPermissions): void {
        if (role.permissions.edit) {
            this.processedRole = role;
        }
    }

    public toggleEditEnabled(review: RolesReview = null): void {
        this.editEnabled = !this.editEnabled;
        this.processedRole = null;
        this.roleNameInputValue = null;
        this.submitted = false;

        if (review) {
            if (this.editEnabled) {
                review.roles.push({
                    role_ID: 0,
                    name: null,
                    permissions: {edit: true, delete: false},
                } as RoleWithPermissions);
            } else {
                review.roles.pop();
            }
        }
    }

    private getPermissionsForAction(action: ActionFromServer): PermissionFromServer[] {
        const permissions = [...action.permissions];
        permissions.push({scope: 'none'} as PermissionFromServer);
        return permissions;
    }

    public setRoleNameInputValue($event: Event): void {
        this.roleNameInputValue = ($event.target as HTMLInputElement).value;
    }

    public deleteRole(roleID: number): void {
        this._roleService.deleteRole(roleID)
            .then(() => {
                this._fetchRoles$.next();
                this.toggleEditEnabled();
            }, () => {});
    }
}
