import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Routes } from '../shared/models/Route';

import { BenefitListComponent } from './components/sections/benefit-list/benefit-list.component';
import { BenefitOverviewComponent } from './components/sections/benefit-overview/benefit-overview.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'benefit/list',
    pathMatch: 'full'
  },

  {
    path: 'benefit',
    redirectTo: 'benefit/list',
    pathMatch: 'full'
  },

  {
    path: 'benefit/list',
    component: BenefitListComponent,
    data: {
      help: 'employees.benefit.list',
      title: 'benefits.page_title_index'
    }
  },

  {
    path: 'benefit/overview/:benefitID',
    component: BenefitOverviewComponent,
    data: {
      help: 'employees.benefit.overview',
      title: 'benefits.page_title_view'
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ]
})
export class BenefitBonusRoutingModule { }
