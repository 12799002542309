<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">
            <span>{{ 'employees.page_title_edit_supervisor' | translate }}</span>
        </p>

        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
        >
      <span
          aria-hidden="true"
          class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row mb-2">
            <div class="col-12">
                <label class="control-label required">{{ 'employees.supervisor' | translate }}</label>
                <ng-select
                    class="custom"
                    [(ngModel)]="selectedSupervisor"
                    name="selectedSupervisor"
                    [clearable]="false"
                    [items]="employees$ | async"
                    [bindLabel]="'fullname'"
                >
                    <ng-template ng-label-tmp let-item="item">
                        <span>{{ item.fullname }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                        <span>{{ item.fullname }}</span>
                    </ng-template>

                </ng-select>
            </div>
        </div>
        <div *ngIf="employee.is_supervisor" class="row mb-2">
            <div class="col-12">
                <label class="control-label required mb-2">{{ 'employees.descendants_action' | translate }}</label>
                <div class="custom-control custom-radio mb-3">
                    <input
                        [(ngModel)]="descendantsAction"
                        class="custom-control-input"
                        id="descendantsActionWithParent"
                        name="descendants_action"
                        ngModel
                        type="radio"
                        value="with_parent"
                    >

                    <label
                        class="custom-control-label font-weight-normal"
                        for="descendantsActionWithParent"
                    >{{ 'employees.move_structure_with_parent' | translate }}</label>
                </div>
                <div class="custom-control custom-radio mb-1">
                    <input
                        [(ngModel)]="descendantsAction"
                        class="custom-control-input"
                        id="descendantsActionMoveTo"
                        name="descendants_action"
                        ngModel
                        type="radio"
                        value="move_to"
                    >

                    <label
                        class="custom-control-label font-weight-normal"
                        for="descendantsActionMoveTo"
                    >{{ 'employees.move_structure_to' | translate }}:</label>
                </div>
                <div class="mb-3 pl-3">
                    <ng-select
                        class="custom"
                        [(ngModel)]="selectedSupervisorForDescendants"
                        name="selectedSupervisorForDescendants"
                        [clearable]="false"
                        [items]="employees$ | async"
                        [bindLabel]="'fullname'"
                    >
                        <ng-template ng-label-tmp let-item="item">
                            <span>{{ item.fullname }}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                            <span>{{ item.fullname }}</span>
                        </ng-template>

                    </ng-select>
                </div>
                <div *ngIf="currentSupervisor" class="custom-control custom-radio mb-3">
                    <input
                        [(ngModel)]="descendantsAction"
                        class="custom-control-input"
                        id="descendantsActionMoveToParent"
                        name="descendants_action"
                        type="radio"
                        value="move_to_parent"
                    >

                    <label
                        class="custom-control-label font-weight-normal"
                        for="descendantsActionMoveToParent"
                    >{{ 'employees.move_structure_to_parent' | translate }}: {{ currentSupervisor.fullname }}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col text-right">
                <button class="btn btn-success" type="submit" (click)="saveSupervisor()">{{ 'employees.action_save' | translate }}</button>
            </div>
        </div>
    </div>
</div>
