<div *ngIf="checkPermission('employeeProperty') && (availableWidgets$ | async)?.employee?.properties?.properties && (availableFeatures$ | async)?.property" class="row">
  <div class="col-md-12">
    <div class="card shadow">
      <app-help name="employee.detail.properties"></app-help>

      <div class="card-header py-3">
        <div class="row">
          <div class="col-sm-6 text-left my-auto">
            <p class="modal-title font-16 pt-2 pb-2 text-dark">
              <span>{{ 'employees.properties' | translate }}</span>
            </p>
          </div>
          <div *ngIf="canEdit" class="col-sm-6 text-right align-self-center">
            <button
              [createButton]="'employees_properties.action_create' | translate"
              class="ml-sm-2"
              routerLink="/property/list"
            ></button>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <app-data-table
                (searchChanged)="searchChanged($event)"
                (sortChanged)="sortChanged($event)"
                [columnsToFilterIn]="['files.length', 'handover_date_formatted', 'name', 'removal_date_formatted', 'serial_number']"
                [colsConfig]="{width: ['50px', '17%', '17%', '17%', '17%', '17%', '10%']}"
                [disableInPlaceFiltering]="false"
                [disableInPlaceSorting]="false"
                [loading]="loading"
                [rows]="rows"
                [search]="search"
                [sort]="sort"
                [trackByFn]="trackByFn"
              >
                <ng-template #headerRow>
                  <th>
                    <div class="form-check">
                        <input (click)="checkUncheckAll($event)" [(ngModel)]="checkedWholePage" aria-label="..." class="form-check-input position-static"
                               id="blankCheckbox" type="checkbox" value="option1">
                    </div>
                  </th>
                  <th appDataTableHeaderCell="name">
                    <span>{{ 'employees_properties.name' | translate }}</span>
                  </th>

                  <th appDataTableHeaderCell="serial_number">
                    <span>{{ 'employees_properties.serial_number' | translate }}</span>
                  </th>

                  <th appDataTableHeaderCell="handover_date_formatted">
                    <span>{{ 'employees_properties.date_of_registration' | translate }}</span>
                  </th>

                  <th appDataTableHeaderCell="removal_date_formatted">
                    <span>{{ 'employees_properties.date_of_decommissioning' | translate }}</span>
                  </th>

                  <th appDataTableHeaderCell="files.length">
                    <span>{{ 'employees_properties.file' | translate }}</span>
                  </th>

                  <th
                    [disableSort]="true"
                    appDataTableHeaderCell
                    class="text-right"
                  >{{ 'employees_properties.table_action' | translate }}</th>
                </ng-template>

                <ng-template
                  #dataRow
                  let-row
                >
                  <td>
                    <div class="form-check">
                        <input (click)="rowCheckBoxChecked($event, row.property_ID)" [checked]="checkedEmployeePropertyIds.includes(row.property_ID)" aria-label="..."
                               class="form-check-input position-static" type="checkbox" value="1">
                    </div>
                  </td>

                  <td appDataTableDataCell>
                    <div>{{ row.name }}</div>
                    <small class="text-muted">{{ row.category_name }}</small>
                  </td>

                  <td appDataTableDataCell>
                    <app-value-or-dash>{{ row.serial_number }}</app-value-or-dash>
                  </td>

                  <td appDataTableDataCell>{{ row.handover_date_formatted }}</td>

                  <td appDataTableDataCell>
                    <app-value-or-dash>{{ row.removal_date_formatted }}</app-value-or-dash>
                  </td>

                  <td appDataTableDataCell>
                      <a
                        (click)="documentService.openDocumentDetailModal(row.transfer_protocol.hash)"
                        *ngIf="row.transfer_protocol"
                        class="btn btn-white btn-icon btn-sm mr-1"
                      >
                        <i class="mdi mdi-file-document-outline mdi-24px"></i>
                      </a>
                      <button
                        (click)="openPropertyFilesModal(row)"
                        *ngIf="row?.files?.length"
                        class="btn btn-white btn-icon btn-sm"
                      >
                        <i class="mdi mdi-folder-outline mdi-24px"></i>
                      </button>
                  </td>

                  <td
                    appDataTableDataCell
                    class="text-right"
                  >
                    <div>
                      <a
                        *ngIf="row.permissions.view"
                        [routerLink]="['/property', 'detail', row.property_ID]"
                        class="btn btn-white btn-icon btn-sm mr-1"
                        title="{{ 'employees_properties.action_view' | translate }}"
                      >
                        <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                      </a>
                    </div>
                  </td>
                </ng-template>
              </app-data-table>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-10">
            <app-pagination
              (pageChanged)="pageChanged($event)"
              [itemsPerPage]="itemsPerPage"
              [page]="page"
              [total]="itemsTotal"
            ></app-pagination>
          </div>
        </div>

        <div *ngIf="checkedEmployeePropertyIds.length" class="row mt-3 d-flex justify-content-between">
          <span class="ml-1">{{ 'employees_properties.selected_count' | translate }}: {{ checkedEmployeePropertyIds.length }}</span>
          <div>
              <button (click)="openMultiUnassignModal()" class="btn btn-danger btn-sm mr-1"
                      title="{{ 'properties.action_detach' | translate }}">
                  {{ 'properties.action_detach' | translate }}
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-employee-benefit-bonus *ngIf="checkPermission('benefit') && (availableWidgets$ | async)?.employee?.properties?.benefits">

</app-employee-benefit-bonus>
