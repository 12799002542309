import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { RolesService } from '../../../../setting/services/roles.service';
import { Employee } from '../../../models/Employee';
import { EmployeeService } from '../../../services/employee.service';

@Component({
    selector: 'app-edit-employee-supervisor',
    templateUrl: './edit-employee-supervisor.component.html',
    styleUrls: ['./edit-employee-supervisor.component.css']
})
export class EditEmployeeSupervisorComponent implements OnInit {
    public employee: Employee;
    public employees$: Observable<Array<Employee>>;
    public roles$: Observable<Array<string>>;
    public selectedSupervisor: Employee;
    public selectedSupervisorForDescendants: Employee;
    public descendantsAction = 'with_parent';
    public currentSupervisor: Employee;

    public constructor(
        public activeModal: NgbActiveModal,
        private _employeeService: EmployeeService,
        private _roleService: RolesService,
        private _tr: TranslateService
    ) { }

    ngOnInit(): void {
        const noSupervisor: Employee = {
            at_work_this_month: 0,
            avatar: '',
            avg_score: 0,
            bank_account: '',
            bank_account_bank_code: '',
            bank_account_iban: '',
            bank_account_number: '',
            benefits: undefined,
            born_date: '',
            born_surname: '',
            can_login: undefined,
            company_branch_ID: 0,
            company_branch_name: '',
            company_group: undefined,
            company_name: '',
            company_phone: '',
            created_at: '',
            days_in_company: 0,
            degree_after: '',
            degree_before: '',
            emergency_contact: '',
            employee_benefit_ID: 0,
            employee_property_ID: 0,
            employee_type: '',
            facebook: '',
            fullname: this._tr.instant('employees.no_supervisor'),
            gender: '',
            help_mode: false,
            holiday_work: 0,
            hour_cost: 0,
            in: '',
            is_active: undefined,
            is_externist: false,
            kpis: undefined,
            last_score: 0,
            linkedin: '',
            locale: '',
            owned_company_groups: undefined,
            password: '',
            permissions: {
                createAddress: false,
                createAdjustement: false,
                createDatetime: false,
                createFile: false,
                createPayroll: false,
                createPolicy: false,
                createPosition: false,
                createSalary: false,
                createTermination: false,
                delete: false,
                deleteAddress: false,
                deleteFile: false,
                deletePayroll: false,
                deletePosition: false,
                deleteSalary: false,
                edit: false,
                editAddress: false,
                editPersonalData: false,
                editPosition: false,
                editSalary: false,
                requestCreateAddress: false,
                requestCreateContactPerson: false,
                requestCreateEmployee: false,
                requestDeleteAddress: false,
                requestDeleteContactPerson: false,
                requestDeleteEmployee: false,
                requestEditAddress: false,
                requestEditContactPerson: false,
                requestEditEmployee: false,
                requestEditPersonalData: false,
                view: false,
                viewPersonalData: false
            },
            personal_code: '',
            abbreviation: '',
            personal_email: '',
            personal_in: '',
            position_type: '',
            profile_type: '',
            pw_not_set: '',
            remaining_holiday_hours: 0,
            remaining_holiday_hours_detail: '',
            remaining_sick_day_hours: 0,
            remaining_sick_day_hours_detail: '',
            remaining_test_time: 0,
            role: '',
            role_name: '',
            signature_created_at: '',
            signature_saved: false,
            tin: '',
            total_sick_days: 0,
            weekend_work: 0,
            employee_ID: null,
            name: '',
            surname: '',
            email: '',
            phone: '',
            supervisor: null,
            position: null
        };
        this.employees$ = this._employeeService.getAllEmployees(false, 'minimal').pipe(
            map(employees => {
                employees.unshift(noSupervisor); // Add the new employee at the beginning of the array
                return employees.filter(employee => employee.employee_ID !== this.employee.employee_ID);
            }),
            shareReplay()
        );
        this.selectedSupervisor = this.employee.supervisor ?? noSupervisor;
        this.selectedSupervisorForDescendants = noSupervisor;
        this.currentSupervisor = this.employee.supervisor ?? null;
        this.roles$ = this._roleService.getRolesByPermissions(['employee.view', 'employee.viewStructure']).pipe(shareReplay());
    }

    public saveSupervisor(): void {
        let descendantsAction = {type: 'nothing', new_supervisor: null};
        if (this.employee.is_supervisor) {
            descendantsAction = {
                type: this.descendantsAction,
                new_supervisor: this.descendantsAction === 'move_to_parent' ? this.currentSupervisor.employee_ID : this.selectedSupervisorForDescendants.employee_ID
            };
        }
        this._employeeService.saveSupervisor(this.employee.employee_ID, this.selectedSupervisor.employee_ID, descendantsAction).then(
            () => this.activeModal.close(),
            () => {}
        );
    }
}
