import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EntityUpdateRequestComponent } from './components/sections/list/entity_update_request-list.component';

const routes: Routes = [
    {path: '', redirectTo: 'list', pathMatch: 'full'},
    {path: 'list', component: EntityUpdateRequestComponent, data: {title: 'entity_update_request.title'}},
    {path: '**', redirectTo: '404'},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EntityUpdateRequestRoutingModule {}
