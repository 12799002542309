<div *ngIf="employeeGrowth$ | async as employeeGrowth; else loadingTemplate" class="modal-content">
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">
      <span>{{ employeeGrowth.name }}</span>
    </p>

    <button aria-label="Close" class="close" (click)="activeModal.dismiss()">
      <span aria-hidden="true" class="font-24 text-muted">
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <div class="mb-4" *ngIf="canEdit$ | async">
              <form #fCreate="ngForm" (keydown.enter)="$event.preventDefault()">
                <div class="d-inline-flex w-100">
                  <input ngModel
                         required
                         type="text"
                         class="form-control mr-2"
                         name="name"
                         placeholder="{{ 'onboardings_tasks.action_create_task' | translate }}">
                  <button
                    [createButton]="'onboarding.action_add_task' | translate"
                    (click)="onSubmit(fCreate.form, employeeGrowth, null)"
                  ></button>
                </div>
                  <div *ngIf="submitted && fCreate.controls?.name?.errors">
                      <p *ngIf="fCreate.controls?.name?.errors.required" class="text-danger validation-text mb-0">
                          {{ 'angular_validation.field' | translate }}
                          {{'angular_validation.required' | translate }}
                      </p>
                  </div>
              </form>
            </div>

            <ng-container *ngFor="let task of employeeGrowth.tasks; let i = index;">
              <hr class="mt-2 mb-2"/>

              <div class="row align-items-center">
                <div class="col-6">
                  <div class="d-flex align-items-center justify-content-between">
                    <div>
                      <button *ngIf="!task.completed_at" class="btn btn-link btn-icon text-muted pr-1 pl-1" (click)="completeTask(task, employeeGrowth)">
                        <i class="mdi mdi-checkbox-blank-circle-outline"></i>
                      </button>

                      <button *ngIf="task.completed_at" class="btn btn-link btn-icon text-green pr-1 pl-1" (click)="unCompleteTask(task, employeeGrowth)">
                        <i class="mdi mdi-check-circle-outline"></i>
                      </button>
                    </div>
                    <div class="flex-1">
                        <span [style.text-decoration]="task.completed_at  ? 'line-through' : null">{{ task.name }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-6 pl-0 text-right">
                    <button
                      *ngIf="canEdit$ | async"
                      type="submit"
                      [updateButton]="'onboarding.action_edit_task' | translate"
                      aria-controls="collapse"
                      [attr.aria-expanded]="!isCollapsed[i]"
                      (click)="isCollapsed[i] = !isCollapsed[i]"
                      >
                    </button>
                </div>
              </div>



              <div
                #collapse="ngbCollapse"
                [(ngbCollapse)]="!isCollapsed[i]"
              >
                <form #fEdit="ngForm" (keydown.enter)="$event.preventDefault()">
                  <div class="row my-2">
                    <div class="col-12">
                      <label class="control-label" for="onboarding_task_name">
                        <span>{{ 'onboardings_tasks.name' | translate }}</span>
                      </label>
                      <input class="form-control x-1"
                             id="onboarding_task_name"
                             name="name"
                             type="text"
                             required
                             [ngModel]="task.name" />
                        <div *ngIf="submittedTask && fEdit.controls?.name?.errors">
                            <p *ngIf="fEdit.controls?.name?.errors.required" class="text-danger validation-text mb-0">
                                {{ 'angular_validation.field' | translate }}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-right">
                      <u (click)="deleteTask(employeeGrowth, task)" class="text-muted mr-2" *ngIf="canEdit$ | async">
                        {{ 'onboardings_tasks.action_delete' | translate }}
                      </u>
                      <button (click)="saveTask(employeeGrowth, task, fEdit.form)" *ngIf="canEdit$ | async" class="btn btn-success"><span>{{ 'onboardings_tasks.action_edit' | translate }}</span></button>
                    </div>
                  </div>
                </form>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-6 text-left"></div>
      <div class="col-6 my-auto text-right">
        <u class="text-muted" *ngIf="canEdit$ | async" (click)="unAssignEmployeeFromGrowth(employeeGrowth.growth_ID, employeeGrowth.employee_ID)">
          {{ 'employees_onboardings.action_delete' | translate }}
        </u>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
    <app-modal-loader></app-modal-loader>
</ng-template>
