<form
    (addedFile)="onAddedFile($event)"
    (addedFiles)="onAddedFiles($event)"
    (error)="onFailure($event)"
    (reset)="onReset($event)"
    (sending)="onSend($event)"
    (sendingMultiple)="onSendMultiple($event)"
    (success)="onSuccess()"
    (successMultiple)="onSuccessMultiple()"
    (thumbnail)="onThumbnail($event)"
    [dropzone]="dropZoneConfig"
    [ngClass]="{'avatar-select': style === 'avatar'}"
    class="dropzone"
  >
    <div class="upload-inner">
      <div class="part">
        <input
          [attr.name]="modelName"
          [attr.value]="modelValue"
          type="hidden"
        >

        <input
          *ngIf="secondaryModelName"
          [attr.name]="secondaryModelName"
          [attr.value]="secondaryModelValue"
          type="hidden"
        >

        <div class="dz-message needsclick">
          <ng-container *ngIf="style === ''">
            <i class="font-24 text-muted mdi mdi-cloud-upload-outline"></i>
            <p class="text-muted font-16">{{ 'files.dropzone_title' | translate }}</p>
          </ng-container>
          <ng-container *ngIf="style === 'avatar'">
            <div *ngIf="isLoading" class="spinner-border spinner-border-sm avatar custom-avatar-loader" role="status">
            </div>
            <ng-container *ngIf="model?.avatar.includes('assets/images/avatars/'); else customAvatarTemplate">
              <i class="font-24 text-muted mdi mdi-plus"></i>
            </ng-container>
            <ng-template #customAvatarTemplate>
              <img [alt]="'custom avatar'" [src]="model.avatar" class="custom-avatar-max-size">
            </ng-template>
          </ng-container>
        </div>
      </div>

      <div
        *ngIf="allowTakingPicture"
        class="part"
      >
        <div
          (click)="openWebcamModal()"
          class="webcam"
        >
          <i class="font-24 text-muted mdi mdi-camera-outline"></i>

          <p class="text-muted font-16">{{ 'files.take_a_picture' | translate }}</p>
        </div>
      </div>
    </div>

    <p *ngIf="style === ''"
       class="allowed-file-extensions font-12 cursor-pointer"
       ngbTooltip="{{ 'files.allowed_file_size' | translate }} {{ allowedFileSize + ' MB' }}. {{ 'files.allowed_extensions' | translate }} {{ allowedExtensions }}">
          {{ 'files.allowed_file_size' | translate }} {{ allowedFileSize + ' MB' }}.
          {{ 'files.allowed_extensions' | translate }} {{ allowedFileExtensionsShort }}
    </p>

</form>
