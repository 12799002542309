<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">
            <span>{{ 'employees.change_in_company_from' | translate }}</span>
        </p>

        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
        >
                    <span
                        aria-hidden="true"
                        class="font-24 text-muted"
                    >
                      <i class="mdi mdi-close"></i>
                    </span>
        </button>
    </div>
    <div class="modal-body">
        <form
            (ngSubmit)="onSubmit()"
            [formGroup]="form"
        >
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="control-label">
                            <span>{{ 'employees.days_in_company' | translate }}</span>
                        </label>
                        <input
                            (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                            [locale]="locale$ | async"
                            allowInput="true"
                            altFormat="d.m.Y"
                            altInput="true"
                            class="form-control"
                            dateFormat="Y-m-d"
                            formControlName="in_company_from"
                            mwlFlatpickr
                            name="in_company_from"
                            type="text"
                        >

                        <div *ngIf="submitted && form.controls.in_company_from.errors">
                            <p *ngIf="form.controls.in_company_from.errors.required" class="text-danger validation-text">
                                <span>{{ 'angular_validation.field' | translate }} </span>
                                <span>{{ 'employees.days_in_company' | translate }} </span>
                                <span>{{ 'angular_validation.required' | translate }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-12 text-right">
                    <button class="btn btn-primary">
                        <span>{{ 'employees.action_save' | translate }}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
