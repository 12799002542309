import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Routes } from '../shared/models/Route';

import { PropertyListComponent } from './components/sections/property-list/property-list.component';
import { CreateEditPropertyComponent } from './components/sections/create-edit-property/create-edit-property.component';
import { CategoryListComponent } from './components/sections/category-list/category-list.component';
import { CreateEditCategoryComponent } from './components/sections/create-edit-category/create-edit-category.component';
import { PropertyOverviewComponent } from './components/sections/property-overview/property-overview.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full'
  },

  {
    path: 'list',
    component: PropertyListComponent,
    data: {
      title: 'properties.page_title_index'
    }
  },

  {
    path: 'create',
    component: CreateEditPropertyComponent,
    data: {
      help: 'property.create',
      title: 'properties.page_title_create'
    }
  },

  {
    path: 'edit/:propertyID',
    component: CreateEditPropertyComponent,
    data: {
      help: 'property.edit',
      title: 'properties.page_title_edit'
    }
  },

  {
    path: 'detail/:propertyID',
    component: PropertyOverviewComponent,
    data: {
      help: 'property.detail',
      title: 'properties.page_title_view'
    }
  },

  {
    path: 'category/list',
    component: CategoryListComponent,
    data: {
      help: 'property.category.list',
      title: 'properties_categories.page_title_index'
    }
  },

  {
    path: 'category/create',
    component: CreateEditCategoryComponent,
    data: {
      help: 'property.category.create',
      title: 'properties_categories.page_title_create'
    }
  },

  {
    path: 'category/edit/:categoryID',
    component: CreateEditCategoryComponent,
    data: {
      help: 'property.category.edit',
      title: 'properties_categories.page_title_edit'
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class PropertyRoutingModule { }
