<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">
      <span *ngIf="growth">{{ 'growths.page_title_edit' | translate }}</span>
      <span *ngIf="!growth">{{ 'growths.page_title_create' | translate }}</span>
    </p>

    <button
      aria-label="Close"
      class="close"
      type="button"
      (click)="activeModal.dismiss()"
    >
      <span
        class="font-24 text-muted"
        aria-hidden="true"
      >
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <form
              autocomplete="off"
              [formGroup]="growthEditForm"
              (ngSubmit)="onSubmit()"
            >
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'growths.name' | translate }}</span>
                    </label>

                    <input
                      class="form-control"
                      formControlName="name"
                      name="name"
                      type="text"
                    >

                    <div *ngIf="submitted && f.name.errors">
                      <p
                        *ngIf="f.name.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'growths.name' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'growths.description' | translate }}</span>
                    </label>

                    <input
                      class="form-control"
                      formControlName="description"
                      name="description"
                      type="text"
                    >

                    <div *ngIf="submitted && f.description.errors">
                      <p
                        *ngIf="f.description.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'growths.description' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'growths.costs' | translate }}</span>
                    </label>

                    <input class="form-control" formControlName="costs" name="costs" type="text" currencyMask>

                    <div *ngIf="submitted && f.name.errors">
                      <p
                        *ngIf="f.name.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'growths.costs' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col d-flex justify-content-end">
                  <button *ngIf="growth" class="btn btn-link text-muted pr-0 mr-4" type="button" (click)="deleteGrowth()">
                    <u>{{ 'growths.action_delete' | translate }}</u>
                  </button>
                  <button class="btn btn-success">
                    {{ 'growths.action_save' | translate }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
