<form (ngSubmit)="onSubmit()" [formGroup]="companyCurrencySettingEditForm" autocomplete="off" class="form">
  <div class="card shadow">
    <app-help name="settings.company.currency"></app-help>
    <div class="card-header py-3">
      <div class="row">
        <div class="col-sm-6 text-left my-auto">
          <p class="modal-title font-16 text-dark pt-2 pb-2">
            <span>{{ 'settings.currency_title' | translate }}</span>
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="companyCurrencySettings$ | async; else loadingtemplate" class="card-body">
      <div class="row mt-2">
        <div class="col-4">
          <div class="form-group">
            <label class="control-label" for="currency_label">
              <span>{{ 'settings.currency_label' | translate }}</span>
            </label>
            <input (keyup)="updatePreview()" class="form-control" formControlName="company.currency.label" id="currency_label" name="company.currency.label" type="text"/>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="control-label" for="currency_prefix">
              <span>{{ 'settings.currency_prefix' | translate }}</span>
            </label>
            <div>
              <input (change)="updatePreview()" data-switch="success" formControlName="company.currency.prefix" id="currency_prefix" name="currency_prefix" type="checkbox">
              <label class="mb-0 mt-1" for="currency_prefix">&nbsp;</label>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="control-label" for="currency_label">
              <span>{{ 'settings.currency_preview' | translate }}</span>
            </label>
            <div>{{ preview }}</div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <div class="form-group">
            <label class="control-label" for="currency_thousands_delimiter">
              <span>{{ 'settings.currency_thousands_delimiter' | translate }}</span>
            </label>
            <ng-select (change)="updatePreview()" [clearable]="false" [items]="thousandsDelimiters" bindLabel="label" bindValue="value" class="custom"
                       formControlName="company.currency.thousands_delimiter" id="currency_thousands_delimiter"></ng-select>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="control-label" for="currency_decimal_delimiter">
              <span>{{ 'settings.currency_decimal_delimiter' | translate }}</span>
            </label>
            <ng-select (change)="updatePreview()" [clearable]="false" [items]="decimalDelimiters" bindLabel="label" bindValue="value" class="custom"
                       formControlName="company.currency.decimal_delimiter" id="currency_decimal_delimiter"></ng-select>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="control-label" for="currency_round">
              <span>{{ 'settings.currency_round' | translate }}</span>
            </label>
            <input (change)="updatePreview()" class="form-control" formControlName="company.currency.round" id="currency_round" max="4" min="0" name="company.currency.label" step="1" type="number"/>
            <div *ngIf="submitted && f['company.currency.round'].errors">
              <p *ngIf="f['company.currency.round'].errors.min" class="text-danger validation-text">
                {{ 'angular_validation.field' | translate }} {{ 'settings.currency_round' | translate }}
                {{ 'angular_validation.cannot_be_lower' | translate }} 0
              </p>
              <p *ngIf="f['company.currency.round'].errors.max" class="text-danger validation-text">
                {{ 'angular_validation.field' | translate }} {{ 'settings.currency_round' | translate }}
                {{ 'angular_validation.cannot_be_greater' | translate }} 4
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col d-flex justify-content-end">
          <button class="btn btn-success">
            <span>{{ 'global.action_save' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
<ng-template #loadingtemplate>
  <div class="row">
    <div class="col-12">
      <div class="p-3">
        <div class="item">
          <ngx-skeleton-loader [theme]="{
        'margin': '15px 0'
      }" appearance="line" count="5"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</ng-template>
