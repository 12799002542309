import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { PUBLIC_ROUTES } from './auth/public-routes';
import { AuthenticationService } from './core/services/authentication.service';
import { DomainService } from './core/services/domain.service';
import { MenuItemStore } from './menu/state/menu-item.store';
import { ApiHelper } from './shared/common/ApiHelper';
import { AppService } from './shared/services/app.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
    private _initialized = false;
    private _subscriptions: Subscription;

    public constructor(
        public appService: AppService,
        private _authService: AuthenticationService,
        private _domainService: DomainService,
        private _menuStore: MenuItemStore,
        private _modalService: NgbModal,
        private _ngSelectConfig: NgSelectConfig,
        private _ngbModalConfig: NgbModalConfig,
        private _router: Router,
        private _translateService: TranslateService,
        private _route: ActivatedRoute,
        private _apiHelper: ApiHelper,
    ) {}

    public ngOnDestroy(): void {
        this._subscriptions?.unsubscribe();
    }

    public ngOnInit(): void {
        this._subscriptions = this._router.events
            .subscribe(event => {
                // Close all active modals when user change route
                if (event instanceof NavigationEnd) {
                    this._modalService.dismissAll();

                    $('.navbar-toggle').removeClass('open');

                    this._menuStore.update({isCollapsed: true});

                    this.appService.setCurrentRoute(event);

                    const params = {...this._route.snapshot.queryParams};
                    if (params.error) {
                        this._apiHelper.showErrorMessage(params.error);
                        delete params.error;
                        void this._router.navigate([], {queryParams: params});
                    }
                }
            });

        this._startup();
    }

    private _initializeRouter(): void {
        this._ngSelectConfig.notFoundText = this._translateService.instant('select.no_items_found');
        if (environment.production) {
            this._ngbModalConfig.backdrop = 'static';
        }

        if (this._initialized === false) {
            this._initialized = true;
            this._router.initialNavigation();
        }
    }

    private _startup(): void {
        this._subscriptions.add(this._authService.getAuthUser()
            .subscribe(
                user => {
                    if (user?.tenant_name) {
                        this._domainService.setTenant(user.tenant_name);
                    }
                    this._initializeRouter();
                },
                () => {
                    this._initializeRouter();
                    const navigation = this._router.getCurrentNavigation();
                    const url = this._router.serializeUrl(navigation.extractedUrl);
                    const isPublic = PUBLIC_ROUTES.find(route => url.startsWith(route)) && true;

                    if (!isPublic) {
                        void this._router.navigateByUrl('/auth/login');
                    }
                }
            ));
    }
}
