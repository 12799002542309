import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Help } from './help.model';

export interface HelpState extends EntityState<Help> {
    help_mode: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'helps', idKey: 'external_ID' })
export class HelpStore extends EntityStore<HelpState> {
  constructor() {
    super({help_mode: false});
  }
}
