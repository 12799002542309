import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Benefit } from './benefit.model';

export interface BenefitState extends EntityState<Benefit> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'benefits', idKey: 'benefit_ID' })
export class BenefitStore extends EntityStore<BenefitState> {
  constructor() {
    super();
  }
}
