import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-setting-user-accounts',
  templateUrl: './setting-user-accounts.component.html',
  styleUrls: ['./setting-user-accounts.component.css']
})
export class SettingUserAccountsComponent implements OnInit {
  public canViewRoles$: Observable<{ can: boolean }>;

  public canViewUsers$: Observable<{ can: boolean }>;

  public constructor(private _authService: AuthenticationService) { }

  public ngOnInit(): void {
    this.canViewRoles$ = this._authService.hasPermissionTo('role.view');

    this.canViewUsers$ = this._authService.hasPermissionTo('user.view');
  }
}
