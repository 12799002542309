import { Component } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-signature-esign-modal',
  templateUrl: './signature-esign-modal.component.html',
  styleUrls: ['./signature-esign-modal.component.css']
})
export class SignatureEsignModalComponent {
  public saveSignature = false;

  public constructor(public activeModal: NgbActiveModal) {}

  public closeModal(useSavedSignature: boolean): void {
    this.activeModal.close({
      save_signature: this.saveSignature,
      use_saved_signature: useSavedSignature,
    });
  }
}
