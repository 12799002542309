<ng-template
  #content
  let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'employees.payroll_action_create_modal' | translate }}</span>
      </p>

      <button
        (click)="modal.dismiss()"
        aria-label="Close"
        class="close"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <form
                (ngSubmit)="onDateSubmit()"
                [formGroup]="dateForm"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-6">
                          <label class="control-label">
                            <span>{{ 'employees.payroll_month' | translate }}</span>
                          </label>
                          <ng-select
                          [clearable]="false"
                          class="custom"
                          formControlName="month"
                          >
                            <ng-container *ngFor="let i of selectRange.months">
                              <ng-option value="{{ i }}"> {{ 'global.month_' + i | translate }}</ng-option>
                            </ng-container>
                          </ng-select>
                        </div>
                        <div class="col-6">
                          <label class="control-label">
                            <span>{{ 'employees.payroll_year' | translate }}</span>
                          </label>
                          <ng-select
                          [clearable]="false"
                          class="custom"
                          formControlName="year"
                          >
                            <ng-container *ngFor="let i of selectRange.years">
                              <ng-option value="{{ i }}"> {{ i }}</ng-option>
                            </ng-container>
                          </ng-select>
                        </div>
                      </div>



                      <div *ngIf="dateFormSubmitted && dateForm.controls?.date?.errors">
                        <p
                          *ngIf="dateForm.controls?.date?.errors.required"
                          class="text-danger validation-text"
                        >
                          <span>{{ 'angular_validation.field' | translate }} </span>
                          <span>{{ 'employees_datetimes.date' | translate }} </span>
                          <span>{{ 'angular_validation.required' | translate }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12 text-right">
                    <button class="btn btn-success">
                      <span>{{ 'employees_datetimes.action_save' | translate }}</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="card shadow">
  <app-help name="employee.detail.payrolls"></app-help>

  <div class="card-header py-3">
    <p class="modal-title font-16 pt-2 pb-2 text-dark">
      <span>{{ 'employees.files_payrolls' | translate }}</span>
    </p>
  </div>

  <div class="card-body">
    <ng-container *ngIf="employee?.permissions.createPayroll">
      <app-upload
        (addedFile)="showDateModal(content)"
        (success)="refetch()"
        [additionalParams]="payrollFileAdditionalData"
        [clear$]="clearFileQueue$"
        [fileName]="employee.fullname + '.jpeg'"
        [modelName]="'employee_ID'"
        [modelValue]="employeeID"
        [process$]="processFileQueue$"
        [secondaryModelName]="'file_type'"
        [secondaryModelValue]="'payroll'"
      ></app-upload>
    </ng-container>

    <app-files-table
      (refetch)="refetch()"
      *ngIf="employeePayrolls$"
      [allowDelete]="allowDelete$ | async"
      [entityID]="employeeID"
      [entityType]="'employee'"
      [files$]="employeePayrolls$"
      [groupBy]="'year'"
      [objectType]="'EMPLOYEE'"
      [object]="employee"
      [showFileOnNewPage]="true"
    ></app-files-table>
  </div>
</div>
