<div class="row">
    <div class="col-12">
        <div class="card shadow">
            <app-help name="{{'settings.appearance.custom_fields'}}"></app-help>
            <div class="card-header py-3">
                <div class="row">
                    <div class="col text-left my-auto">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">
                            <span>{{ ('settings.appearance.custom_fields.title') | translate }}</span>
                        </p>
                    </div>
                    <div class="col-auto text-right">
                        <button *ngIf="canCreate"
                                (click)="openEditModal(null)"
                                [createButton]="'settings.appearance.custom_fields.action_create' | translate"
                                class="ml-sm-2"
                        ></button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive">
                            <app-data-table
                                [customNoDataMessage]="'settings.appearance.custom_fields.empty_widget_error' | translate"
                                [disableInPlaceSorting]="true"
                                [rows]="customFields$ | async"
                                [showSearch]="false"
                            >
                                <ng-template #headerRow
                                >
                                    <th [disableSort]="true"
                                        appDataTableHeaderCell="label"
                                    >{{ 'settings.appearance.custom_fields.label' | translate }}</th>

                                    <th [disableSort]="true"
                                        appDataTableHeaderCell="type"
                                    >{{ 'settings.appearance.custom_fields.type' | translate }}</th>

                                    <th [disableSort]="true"
                                        appDataTableHeaderCell="widget"
                                    >{{ 'settings.appearance.custom_fields.widget' | translate }}</th>

                                    <th [disableSort]="true"
                                        appDataTableHeaderCell="required"
                                    >{{ 'settings.appearance.custom_fields.required' | translate }}</th>

                                    <th [disableSort]="true"
                                        appDataTableHeaderCell="visible"
                                    >{{ 'settings.appearance.custom_fields.visible' | translate }}</th>

                                    <th
                                        [disableSort]="true"
                                        appDataTableHeaderCell
                                        class="text-right"
                                    >{{ 'settings.appearance.custom_fields.actions' | translate }}</th>
                                </ng-template>

                                <ng-template
                                    #dataRow
                                    let-row
                                >
                                    <td appDataTableDataCell class="w-sm-15">
                                        {{ row.label }}
                                    </td>

                                    <td appDataTableDataCell>
                                        {{ ('settings.appearance.custom_fields.type_' + row.type) | translate }}
                                    </td>

                                    <td appDataTableDataCell>
                                        {{ ('settings.appearance.custom_fields.widget_'+ row.widget) | translate }}
                                    </td>

                                    <td appDataTableDataCell>
                                        {{ (row.is_required ? 'global.YES' : 'global.NO') | translate }}
                                    </td>

                                    <td appDataTableDataCell>
                                        {{ (row.is_visible? 'global.YES' : 'global.NO') | translate }}
                                    </td>

                                    <td
                                        appDataTableDataCell
                                        class="text-right"
                                    >
                                        <a
                                            (click)="openEditModal(row)"
                                            class="btn btn-white btn-icon btn-sm mr-1"
                                            title="{{ 'settings.appearance.custom_fields.action_view' | translate }}"
                                        >
                                            <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                        </a>
                                    </td>
                                </ng-template>
                            </app-data-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
