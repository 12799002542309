import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BonusState, BonusStore } from './bonus.store';

@Injectable({ providedIn: 'root' })
export class BonusQuery extends QueryEntity<BonusState> {
    constructor(protected store: BonusStore) {
        super(store);
    }
}
