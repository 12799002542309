import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CreateEditOnboardingModalComponent } from 'src/app/onboarding/components/partials/create-edit-onboarding-modal/create-edit-onboarding-modal.component';
import { EmployeeOnboardingOverviewModalComponent } from 'src/app/onboarding/components/partials/employee-onboarding-overview-modal/employee-onboarding-overview-modal.component';
import { EmployeeOnboarding } from 'src/app/onboarding/employee-onboarding/state/employee-onboarding.model';
import { EmployeeOnboardingQuery } from 'src/app/onboarding/employee-onboarding/state/employee-onboarding.query';
import { EmployeeOnboardingService } from 'src/app/onboarding/employee-onboarding/state/employee-onboarding.service';
import { Onboarding } from 'src/app/onboarding/onboarding/state/onboarding.model';
import { OnboardingQuery } from 'src/app/onboarding/onboarding/state/onboarding.query';
import { OnboardingService } from 'src/app/onboarding/onboarding/state/onboarding.service';
import { FlatpickrLocaleService } from '../../../../shared/services/flatpickr-locale.service';

@Component({
    selector: 'app-employee-onboardings',
    templateUrl: './employee-onboardings.component.html',
    styleUrls: ['./employee-onboardings.component.css']
})
export class EmployeeOnboardingsComponent implements OnInit, OnDestroy {
    public assignOnboardingsForm: UntypedFormGroup;
    public canCreateOnboarding$: Observable<boolean>;
    public canEditOrRemove$: Observable<boolean>;
    public employeeID: number;
    public employeeOnboardings$: Observable<Array<EmployeeOnboarding>>;
    public onboardings$: Observable<Array<Onboarding>>;
    public submittedOnboardings = false;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;

    private _subscription: Subscription;

    public constructor(
        private _authService: AuthenticationService,
        private _employeeOnboardingQuery: EmployeeOnboardingQuery,
        private _employeeOnboardingService: EmployeeOnboardingService,
        private _fb: UntypedFormBuilder,
        private _modalService: NgbModal,
        private _onboardingQuery: OnboardingQuery,
        private _onboardingService: OnboardingService,
        private _route: ActivatedRoute,
        private _flatpickrLocale: FlatpickrLocaleService,
    ) { }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.assignOnboardingsForm.controls;
    }

    public ngOnInit(): void {
        this._subscription = this._route.parent.params.subscribe(params => {
            this.employeeID = parseInt(params.id, 10);

            this._employeeOnboardingService.get(this.employeeID)
                .subscribe();

            this.employeeOnboardings$ = this._employeeOnboardingQuery.selectAll();

            this.canCreateOnboarding$ = this._authService.hasPermissionTo('onboarding.create').pipe(map(permission => permission.can));

            this.canEditOrRemove$ = combineLatest([
                this._authService.hasPermissionTo('employee.edit').pipe(map(permission => permission.can)),
                this._authService.hasPermissionTo('employee.editStructure').pipe(map(permission => permission.can)),
            ])
                .pipe(
                    map(([canEdit, canEditStructure]) => canEdit || canEditStructure),
                    tap(can => this._fetchOnboardings(can)),
                    shareReplay()
                );
        });
    }

    public onboardingSelectionChanged(): void {
        if (this._isCreatingNew() === false) {
            this.assignOnboardingsForm.removeControl('description');
            this.assignOnboardingsForm.removeControl('name');
            this.assignOnboardingsForm.removeControl('type');
        } else {
            this.assignOnboardingsForm.addControl('description', new UntypedFormControl(null));
            this.assignOnboardingsForm.addControl('name', new UntypedFormControl(null, Validators.required));
            this.assignOnboardingsForm.addControl('type', new UntypedFormControl(null, Validators.required));
        }
    }

    public onSubmitOnboarding(): void {
        this.submittedOnboardings = true;

        if (!this.assignOnboardingsForm.valid) {
            return;
        }

        const value = this.assignOnboardingsForm.value;

        let promise: Promise<'done'>;

        if (this._isCreatingNew()) {
            promise = this._onboardingService.createAndAssignOnboardingToEmployee(value, this.employeeID);
        } else {
            promise = this._onboardingService.assignOnboardingsToEmployee(value, this.employeeID);
        }

        promise
            .then(() => this._modalService.dismissAll());
    }

    public openEmployeeOnboardingOverviewModal(employeeOnboarding: EmployeeOnboarding): void {
        const modalRef = this._modalService.open(EmployeeOnboardingOverviewModalComponent, {size: 'lg', centered: true});

        modalRef.componentInstance.employeeOnboardingID = employeeOnboarding.employee_onboarding_ID;
    }

    public openOnboardingAssignModal(content: any): void {
        this.submittedOnboardings = false;

        this.assignOnboardingsForm = this._fb.group({
            description: [null],
            name: [null, Validators.required],
            type: [null, Validators.required],
            onboardingIDs: [[]],
            start_date: [null]
        });

        this._modalService.open(content, {centered: true});
    }

    public openOnboardingCreateModal(): void {
        this._modalService.open(CreateEditOnboardingModalComponent, {centered: true});
    }

    public trackByOnboarding(index: number, growth: EmployeeOnboarding): number | string {
        return growth.employee_onboarding_ID;
    }

    private _fetchOnboardings(can: boolean): void {
        if (can) {
            this._onboardingService.get()
                .subscribe();

            this.onboardings$ = combineLatest([
                this._onboardingQuery.selectAll(),
                this.employeeOnboardings$
                    .pipe(map(onboardings => (onboardings || []).map(o => o.onboarding_ID)))
            ])
                .pipe(map(([allOnboardings, assignedOnboardingIds]) => allOnboardings.filter(o => assignedOnboardingIds.indexOf(o.onboarding_ID as number) === -1)));
        }
    }

    private _isCreatingNew(): boolean {
        return this.assignOnboardingsForm.value?.onboardingIDs?.length === 0;
    }
}
