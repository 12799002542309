import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Onboarding } from './onboarding.model';

export interface OnboardingState extends EntityState<Onboarding> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'onboardings', idKey: 'onboarding_ID' })
export class OnboardingStore extends EntityStore<OnboardingState> {
  constructor() {
    super();
  }
}
