<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">
            <span>{{ 'employees_datetimes_types.page_title_create' | translate }}</span>
        </p>

        <button aria-label="Close" class="close" (click)="activeModal.dismiss()">
            <span aria-hidden="true" class="font-24 text-muted">
                <i class="mdi mdi-close"></i>
            </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <form autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'employees_datetimes_types.dates' | translate }}</span>
                                        </label>
                                        <ng-select
                                            bindLabel="label"
                                            bindValue="value"
                                            class="custom"
                                            formControlName="dates"
                                            name="dates"
                                            groupBy="group"
                                            [multiple]="true"
                                            [clearable]="true"
                                            [closeOnSelect]="false"
                                            [items]="datesItems"
                                        ></ng-select>
                                        <div *ngIf="submitted && f.dates.errors">
                                            <p *ngIf="f.dates.errors.required" class="text-danger validation-text">
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'employees_datetimes_types.dates' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'employees_datetimes_types.note' | translate }}</span>
                                        </label>
                                        <input
                                            class="form-control"
                                            formControlName="note"
                                            name="note"
                                            type="text"
                                        >
                                    </div>
                                </div>
                            </div>

                            <app-targetable [formGroup]="form"></app-targetable>

                            <div class="row mt-2">
                                <div class="col d-flex justify-content-end">
                                    <button class="btn btn-success">
                                        <span>{{ 'employees_datetimes_types.action_save' | translate }}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
