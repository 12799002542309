import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { GrowthState, GrowthStore } from './growth.store';

@Injectable({ providedIn: 'root' })
export class GrowthQuery extends QueryEntity<GrowthState> {
    constructor(protected store: GrowthStore) {
        super(store);
    }
}
