import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Injectable()
export class DataTablesHelper {
    public constructor(
        private authService: AuthenticationService,
        private translateService: TranslateService
    ) { }

    public formatDatatablesDesign(permissionName?: string): any {
        return () => {
            if (permissionName) {
                const can = this.authService.checkPermission(permissionName);

                if (can) {
                    $('[data-action="' + permissionName + '"]').removeClass('d-none');
                }
            }

            $('.dataTables_filter label input').css('width', '100%');
            $('.dataTables_filter label input').attr('autocomplete', 'off');
            $('.dataTables_filter label input').css('margin-left', '0').attr('placeholder', 'Vyhledat...');
            $('.dataTables_filter label').each(function () {
                $(this).css({width: '100%'}).contents()[0].nodeValue = '';
            });
            $('#tasks-datatable thead').remove();
            $('#employees-datatable thead').remove();
            $('#accounts-datatable thead').remove();
            $('#projects-datatable thead').remove();
            $('#company-groups-datatable thead').remove();
            $('#properties-datatable thead').remove();
            $('#noti-datatable thead').remove();
            $('#forms-datatable thead').remove();
            $('#products-datatable thead').remove();
            $('#attendance-datatable thead').remove();
            $('#internal-documents-datatable thead').remove();
            $('#categories-datatable thead').remove();
            $('#export-datetimes-datatable thead').remove();
        };
    }

    public getDefaultDatatablesOptions(
        component,
        permissionName?: string,
        actionColumnIndex?: number,
        orderColumnIndex?: number,
        orderType?: string
    ): object {
        if (
            (
                !orderColumnIndex ||
                !orderType
            ) &&
            orderColumnIndex !== 0
        ) {
            orderColumnIndex = 0;
            orderType = 'asc';
        }

        const splitPermission = permissionName.split('.');

        let buttonName;

        switch (splitPermission[0]) {
            case 'articleCategory': {
                buttonName = 'articles_categories' + '.action_create';

                break;
            }

            case 'companyBranch': {
                buttonName = 'company_branches' + '.action_create';

                break;
            }

            case 'companyEmployeePolicy': {
                buttonName = 'company_employee_policies' + '.action_create';

                break;
            }

            case 'companyGroup': {
                buttonName = 'company_group' + 's.action_create';

                break;
            }

            case 'currency': {
                buttonName = 'currencies' + '.action_create';

                break;
            }

            case 'employeeDatetimeType': {
                buttonName = 'employees_datetimes_types' + '.action_create';

                break;
            }

            case 'import': {
                buttonName = 'imports' + '.action_create';

                break;
            }

            case 'internalDocument': {
                buttonName = 'internal_documents' + '.action_create';

                break;
            }

            case 'inventory': {
                buttonName = 'inventories' + '.action_create';

                break;
            }

            case 'inventoryItem': {
                buttonName = 'inventory_items' + '.action_create';

                break;
            }

            case 'numberSerie': {
                buttonName = 'numbers_series' + '.action_create';

                break;
            }

            case 'productCategory': {
                buttonName = 'products_categories' + '.action_create';

                break;
            }

            case 'property': {
                buttonName = 'propertie' + 's.action_create';

                break;
            }

            case 'propertyCategory': {
                buttonName = 'properties_categories' + '.action_create';

                break;
            }

            case 'training': {
                buttonName = 'forms' + '.action_create';

                break;
            }

            default: {
                buttonName = splitPermission[0] + 's.action_create';

                break;
            }
        }

        const translatedButtonName = this.translateService.instant(buttonName);

        return {
            dom:
                '<\'d-block d-sm-flex\'<\'flex-1\'f><\'d-block d-sm-inline\'B>>' +
                '<\'row\'<\'col-sm-12\'tr>>' +
                '<\'row\'<\'col-sm-12\'p>>',
            buttons: [
                {
                    text: translatedButtonName,
                    className: 'btn btn-success ml-sm-2 w-100 w-sm-auto d-none',
                    attr: {
                        'data-action': permissionName
                    },
                    action: () => {
                        component.openEditModal();
                    }
                }
            ],
            language: {
                url: '/assets/locale/datatables/Czech.json'
            },
            order: [[orderColumnIndex, orderType]],
            columnDefs: [
                {orderable: false, targets: actionColumnIndex}
            ],
            drawCallback: this.formatDatatablesDesign(permissionName)
        };
    }

    public reloadDatablesAjax(dtElement: DataTableDirective): void {
        dtElement.dtInstance
            .then((dtInstance: any) => {
                dtInstance.ajax.reload(null, false);
            });
    }

    public rerenderDataTable(dtElement: DataTableDirective, dtTrigger: Subject<void>): void {
        dtElement.dtInstance
            .then((dtInstance: any) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                dtTrigger.next();
            });
    }
}
