<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">
            <span>{{ 'employees.change_company_group' | translate }}</span>
        </p>

        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
        >
                    <span
                        aria-hidden="true"
                        class="font-24 text-muted"
                    >
                      <i class="mdi mdi-close"></i>
                    </span>
        </button>
    </div>
    <div class="modal-body">
        <form
            (ngSubmit)="onCompanyGroupSubmit()"
            [formGroup]="companyGroupForm"
        >
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="control-label">
                            <span>{{ 'employees.company_group' | translate }}</span>
                        </label>
                        <ng-select
                            bindLabel="name"
                            bindValue="company_group_ID"
                            class="custom"
                            formControlName="company_group_ID"
                            [multiple]="false"
                            [clearable]="false"
                            [items]="companyGroups$ | async"
                        ></ng-select>
                        <div *ngIf="submitted && companyGroupForm.controls.company_group_ID.errors">
                            <p *ngIf="companyGroupForm.controls.company_group_ID.errors.required" class="text-danger validation-text">
                                <span>{{ 'angular_validation.field' | translate }} </span>
                                <span>{{ 'employees.company_group' | translate }} </span>
                                <span>{{ 'angular_validation.required' | translate }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-12 text-right">
                    <button class="btn btn-primary">
                        <span>{{ 'employees.action_save' | translate }}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
