import { Injectable } from '@angular/core';

import { WindowRef } from 'src/app/ui/window';

const TENANT_KEY = 'cc_tenant';

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  private _tenant: string | null = null;

  public constructor(private _windowRef: WindowRef) { }

  public getTenant(): string | null {
    return this._getTenant();
  }

  public getTenantNameWithStringAppend(appendix: string): string | null {
    const tenantName = this.getTenant();

    if (!tenantName) {
      return '';
    }

    return tenantName ? tenantName + appendix : tenantName;
  }

  public setTenant(tenant: string): void {
    this._setTenant(tenant);
  }

  private _getTenant(): string | null {
    if (this._tenant) {
      return this._tenant || null;
    } else {
      return this._windowRef.nativeWindow?.localStorage?.getItem(TENANT_KEY) || null;
    }
  }

  private _setTenant(tenant: string): void {
    this._tenant = tenant;

    this._windowRef.nativeWindow?.localStorage?.setItem(TENANT_KEY, this._tenant);
  }
}
