import { BreakpointObserver } from '@angular/cdk/layout';
import { Directive, HostBinding, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

interface ClassesObject {
    [className: string]: boolean;
}

@Directive({
    selector: `
    [checkButton],
    [createButton],
    [denyButton],
    [downloadButton],
    [duplicateButton],
    [generateButton],
    [importButton],
    [paidButton],
    [unpaidButton],
    [updateButton],
    [uploadButton]
  `
})
export class ButtonDirective implements OnChanges, OnDestroy, OnInit {
    @HostBinding('attr.aria-label')
    public get ariaLabel(): string {
        return this.checkButton ||
            this.createButton ||
            this.denyButton ||
            this.downloadButton ||
            this.duplicateButton ||
            this.generateButton ||
            this.importButton ||
            this.paidButton ||
            this.unpaidButton ||
            this.updateButton ||
            this.uploadButton;
    }

    @HostBinding('class')
    public classes: ClassesObject;

    @HostBinding('style.padding-left')
    public get stylePaddingLeft(): string {
        return !this._forceIconOnly && this.showLabel ? '3.5rem !important' : null;
    }

    @HostBinding('style.padding-right')
    public get stylePaddingRight(): string {
        return !this._forceIconOnly && this.showLabel ? '1rem !important' : null;
    }

    @HostBinding('type')
    public get typeButton(): string {
        return this.shouldSubmit ? null : 'button';
    }

    @Input()
    public breakpointInPx = 9999;

    @Input()
    public checkButton: string;

    @Input()
    public createButton: string;

    @Input()
    public denyButton: string;

    @Input()
    public downloadButton: string;

    @Input()
    public duplicateButton: string;

    @Input()
    public generateButton: string;

    @Input()
    public importButton: string;

    @Input()
    public paidButton: string;

    @Input()
    public shouldSubmit = false;

    @Input()
    public showLabel = true;

    @Input()
    public unpaidButton: string;

    @Input()
    public updateButton: string;

    @Input()
    public uploadButton: string;

    private _breakpointObserverSubscription: Subscription;

    private _forceIconOnly = false;

    public constructor(private _breakpointObserver: BreakpointObserver) {
    }

    public ngOnDestroy(): void {
        this._breakpointObserverSubscription?.unsubscribe();
    }

    public ngOnChanges(): void {
        this._setupClassesObject();
    }

    public ngOnInit(): void {
        this._breakpointObserverSubscription = this._breakpointObserver
            .observe([`(max-width: ${this.breakpointInPx}px)`])
            .subscribe(state => {
                this._forceIconOnly = state.matches;

                this._setupClassesObject();
            });
    }

    private _setupClassesObject(): void {
        const checkButton = Boolean(this.checkButton);
        const createButton = Boolean(this.createButton);
        const denyButton = Boolean(this.denyButton);
        const downloadButton = Boolean(this.downloadButton);
        const duplicateButton = Boolean(this.duplicateButton);
        const generateButton = Boolean(this.generateButton);
        const importButton = Boolean(this.importButton);
        const paidButton = Boolean(this.paidButton);
        const unpaidButton = Boolean(this.unpaidButton);
        const updateButton = Boolean(this.updateButton);
        const uploadButton = Boolean(this.uploadButton);

        let isDanger = false;
        let isPrimary = true;
        let isSecondary = false;

        if (
            denyButton ||
            unpaidButton
        ) {
            isDanger = true;
            isPrimary = false;
            isSecondary = false;
        } else if (downloadButton) {
            isDanger = false;
            isPrimary = false;
            isSecondary = true;
        }

        this.classes = {
            btn: true,
            'btn-icon': !this._forceIconOnly && this.showLabel ? false : true,
            'btn-danger': isDanger,
            'btn-primary': isPrimary,
            'btn-secondary': isSecondary,
            showLabel: !this._forceIconOnly && this.showLabel,
            checkButton,
            createButton,
            denyButton,
            downloadButton,
            duplicateButton,
            generateButton,
            importButton,
            paidButton,
            unpaidButton,
            updateButton,
            uploadButton
        };
    }
}
