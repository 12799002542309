import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { arrayRemove, cacheable } from '@datorama/akita';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { Employee } from '../../../employee/models/Employee';
import { Benefit } from './benefit.model';
import { BenefitStore } from './benefit.store';

@Injectable({
    providedIn: 'root'
})
export class BenefitService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _benefitStore: BenefitStore,
        private _http: HttpClient,
        private _modalService: NgbModal,
        private _router: Router,
        private _swalHelper: SwalHelper
    ) { }

    public assignBenefitToEmployee(
        form: { handover_date: string, removal_date: string, note: string, employee_benefit_ID: number },
        employeeID: number,
        benefitID: number | string
    ): Observable<ApiResponse> {
        const apiUrl = `/api/employees/${employeeID}/benefits/${benefitID}`;

        return this._http.post<ApiResponse & { benefit: Benefit; }>(apiUrl, form)
            .pipe(
                tap(response => {
                    this._benefitStore.add(response.benefit);

                    this._apiHelper.handleSuccessResponse(response);
                }),
                catchError(error => {
                    this._apiHelper.handleErrorResponse(error);

                    return of({
                        message: '',
                        error
                    });
                })
            );
    }

    public async deleteBenefit(benefitID: number | string): Promise<void> {
        const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

        if (deleteConfirmed) {
            this._http.delete<ApiResponse>(`/api/benefits/${benefitID}`)
                .subscribe(
                    response => {
                        this._benefitStore.remove(benefitID);

                        this._apiHelper.handleSuccessResponse(response);

                        this._router.navigate(['/benefit-bonus', 'benefit', 'list']);
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        }
    }

    public get(): Observable<void> {
        const request = this._http.get<Array<Benefit>>('/api/benefits')
            .pipe(map(response => this._benefitStore.set(response)));

        return cacheable(this._benefitStore, request);
    }

    public getAll(): Observable<Array<Benefit>> {
        return this._http.get<Array<Benefit>>('/api/benefits');
    }

    public getBenefitByID(benefitID: number | string): Observable<Benefit> {
        return this._http.get<Benefit>(`/api/benefits/${benefitID}`);
    }

    public getBenefitsList(params: ListParameters<Benefit>): Observable<ListResponse<Benefit>> {
        return this._http.post<ListResponse<Benefit>>('/api/benefits/list', {...transformParameters(params)});
    }

    public getEmployeesListByBenefitID(benefitID: number, params: ListParameters<Employee>, filter: any): Observable<ListResponse<Employee>> {
        return this._http.post<ListResponse<Employee>>(`/api/benefits/${benefitID}/employees`,
            {
                ...transformParameters(params),
                filter
            });
    }

    public getByEmployeeID(employeeID: number): Observable<Array<Benefit>> {
        return this._http.get<Array<Benefit>>(`/api/employees/${employeeID}/benefits`);
    }

    public async removeEmployeeRelation(employeeID: number, benefitID: number | string, employeeBenefitID: number): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                const apiUrl = `/api/employees/${employeeID}/benefits`;

                const params = new HttpParams()
                    .set('employee_benefit_ID', String(employeeBenefitID));

                this._http.delete(apiUrl, {params})
                    .subscribe(
                        () => {
                            this._benefitStore.update(
                                benefitID,
                                originalBenefit => ({
                                    employees: arrayRemove(originalBenefit.employees, employeeBenefitID, 'employee_benefit_ID')
                                })
                            );

                            resolve(true);
                        },
                        () => reject('error')
                    );

                this._modalService.dismissAll();
            } else {
                reject('cancelled');
            }
        });
    }

    public saveBenefit(form: Benefit, modalRef: NgbActiveModal, benefitID?: number | string): void {
        const apiUrl = benefitID ? `/api/benefits/${benefitID}` : '/api/benefits';

        this._http.post<ApiResponse & { benefit: Benefit; }>(apiUrl, form)
            .subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);

                    if (benefitID) {
                        this._benefitStore.update(benefitID, response.benefit);
                    } else {
                        this._benefitStore.add(response.benefit);
                    }

                    modalRef.close();
                },
                error => this._apiHelper.handleErrorResponse(error)
            );
    }
}
