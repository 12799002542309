import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoggedInUser } from 'src/app/core/models/LoggedUser';
import { DomainService } from 'src/app/core/services/domain.service';
import { WindowRef } from '../../window';
import { TIDIO_KEY } from '../constants';
import { Identity } from '../interfaces/identity.interface';

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    private readonly _document: any;
    private _chatAPI: any;
    private _initialized = false;
    private _window: any;

    public constructor(
        private _domainService: DomainService,
        private _translateService: TranslateService,
        private _windowRef: WindowRef
    ) {
        this._window = this._windowRef.nativeWindow;
        this._document = this._window.document;
    }

    public close(): void {
        this._chatAPI?.close();
    }

    public initialize(user: LoggedInUser): void {
        if (
            this._initialized !== false ||
            !user
        ) {
            return;
        }

        this._initialized = true;
        this._document.tidioIdentify = this._createUserIdentity(user);
        this._document.tidioChatLang = this._translateService.currentLang;
        this._appendChatScript();
        this._addChatReadyListener();
    }

    public open(): void {
        this._chatAPI?.open();
    }

    public show(): void {
        this._chatAPI?.show();
        this._chatAPI?.open();
        this._chatAPI?.track('help-chat-opened');
        this._chatAPI?.on('close', () => this.hide());
    }

    public hide(): void {
        this._chatAPI?.hide();
    }

    private _addChatReadyListener(): void {
        if (
            this._document &&
            typeof this._document.addEventListener === 'function'
        ) {
            this._document.addEventListener('tidioChat-ready', () => this._onChatReady());
        }
    }

    private _appendChatScript(): void {
        if (
            this._document &&
            typeof this._document.createElement === 'function' &&
            typeof this._document.body !== 'undefined' &&
            typeof this._document.body.appendChild === 'function'
        ) {
            const script = this._document.createElement('script');
            script.src = `//code.tidio.co/${TIDIO_KEY}.js`;

            this._document.body.appendChild(script);
        }
    }

    private _createUserIdentity(user: LoggedInUser): Identity | null {
        let identity: Identity | null = null;

        if (user) {
            const tenant = this._domainService.getTenant();
            const defaultId = `T:${tenant};`;
            let id = defaultId;

            if (user.user_ID) {
                id += `U:${user.user_ID}`;
            } else if (user.employee_ID) {
                id += `E:${user.employee_ID}`;
            }

            if (id !== defaultId) {
                identity = {
                    distinct_id: id,
                    email: user.email,
                    name: user.fullname
                };
            }
        }

        return identity;
    }

    private _onChatReady(): void {
        this._chatAPI = this._window.tidioChatApi;
        this.hide();
        this.close();
    }
}
