<div *ngIf="!ownContainer" class="card" [class.shadow]="shadow">
  <div
    *ngIf="title"
    class="card-header"
  >
    <h4>{{ title | translate }}</h4>
  </div>

  <div class="card-body pb-0 pt-0">
    <apx-chart
      [chart]="chart"
      [fill]="fill"
      [labels]="labels"
      [plotOptions]="plotOptions"
      [series]="series"
    ></apx-chart>
  </div>
</div>

<div *ngIf="ownContainer" class="p-0 m-0">
  <apx-chart
    [chart]="chart"
    [fill]="fill"
    [labels]="labels"
    [plotOptions]="plotOptions"
    [series]="series"
    [tooltip]="tooltip"
  ></apx-chart>
</div>
