import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { CompanyPosition } from '../../../models/CompanyPosition';
import { CompanyPositionService } from '../../../services/company-position.service';

@Component({
    selector: 'app-create-edit-company-position',
    templateUrl: './create-edit-company-position.component.html',
    styleUrls: ['./create-edit-company-position.component.css']
})
export class CreateEditCompanyPositionComponent implements OnInit {
    public position: CompanyPosition;
    public positionForm: UntypedFormGroup;
    public submitted = false;
    public canDelete: boolean;
    public color = '#393B4A';

    public positionsTypes = [
        {value: 'HPP', label: this._translateService.instant('employees_positions.HPP')},
        {value: 'DPC', label: this._translateService.instant('employees_positions.DPC')},
        {value: 'DPP', label: this._translateService.instant('employees_positions.DPP')},
        {value: 'ICO', label: this._translateService.instant('employees_positions.ICO')},
        {value: 'EXTERNIST', label: this._translateService.instant('employees_positions.EXTERNIST')},
    ];

    constructor(
        public activeModal: NgbActiveModal,
        private _fb: UntypedFormBuilder,
        private _companyPositionService: CompanyPositionService,
        private _translateService: TranslateService,
        private _authService: AuthenticationService,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.positionForm.controls;
    }

    public ngOnInit(): void {
        this.canDelete = this._authService.checkPermission('companyPosition.delete');

        this.positionForm = this._fb.group({
            name: ['', Validators.required],
            job_description: [''],
            description: [''],
            type: ['', Validators.required],
            salary_from: [''],
            salary_to: [''],
            salary_default: [''],
            color: [''],
        });

        if (this.position) {
            this.positionForm.patchValue(this.position);
            this.color = this.position.color;
        }
    }

    public onSubmit(): void {
        this.submitted = true;
        if (!this.positionForm.valid) {
            return;
        }

        this.positionForm.value.color = this.color;

        this._companyPositionService.saveCompanyPosition(this.position?.company_position_ID, this.positionForm.value)
            .then(() => this.activeModal.close('close'));
    }

    public deleteBranch(): void {
        this._companyPositionService.deleteCompanyPosition(this.position.company_position_ID)
            .then(() => this.activeModal.close('close'));
    }
}
