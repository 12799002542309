<ng-container *ngIf="employeeAddresses$ | async; let addresses; else loadingTemplate">
  <div
    [ngClass]="requiredUpdates && requiredUpdates.address === 'REQUESTED' ? 'notify-dot' : ''"
    class="card shadow h-100">
    <app-help name="employee.detail.addresses"></app-help>

    <div class="card-header py-3">
      <div class="row">
        <div class="col-6 text-left my-auto">
          <p class="modal-title font-16 text-dark pt-2 pb-2">
            <span>{{ 'employees_addresses.addresses' | translate }}</span>
          </p>
        </div>

        <div class="col-6 text-right my-auto">
          <button *ngIf="widgetTemplatesService.canSeeButton('Address', 'Create')"
                  (click)="openEditModal(null, addresses)"
                  [createButton]="'employees_addresses.action_create' | translate"
          ></button>
        </div>
      </div>
    </div>

    <div class="card-body">
      <ng-container *ngIf="entityRequests">
        <ng-container *ngFor="let request of entityRequests">
          <div *ngIf="request?.entity_type === 'App\\Models\\EmployeeAddress'" class="alert alert-warning mb-3 w-100 shadow">
            <span>{{ 'employees_addresses.waiting_to_be_processed' | translate }} </span>
            <span><u (click)="openEntityUpdateRequestModal(request)">{{ 'employees_addresses.waiting_to_be_processed_part_2' | translate }}</u></span>
            <span>{{ 'employees_addresses.waiting_to_be_processed_'+(request?.action) | translate }} </span>
            <span class="font-weight-bold">{{ request?.entity_label }}</span>.
          </div>
        </ng-container>
      </ng-container>
      <div *ngIf="requiredUpdates && requiredUpdates.address === 'REQUESTED'" class="alert alert-warning mb-3 w-100 shadow">
        <span> {{ 'employees_addresses.require_update_description' | translate }} </span>
        <span><u (click)="openEditModal(null, addresses)" > {{ 'employees_addresses.require_update_create_action'| translate }}</u> </span>
        <span *ngIf="addresses?.length > 0"> {{ 'employees_addresses.require_update_edit_action' | translate }}</span>.
        <u (click)="refuseUpdateData()"> {{ 'employees_addresses.require_update_cancel_action' | translate }}</u>.
      </div>
      <ng-container *ngIf="addresses?.length; else noDataTemplate">
        <div
          *ngFor="let address of addresses; let i = index;"
          class="row"
        >
          <div class="col-12">
            <div class="form-group">
              <label class="control-label">
                <span>{{ 'employees_addresses.address' | translate }} </span>

                <span>{{ i + 1 }} </span>

                <span *ngIf="['DOMICILE', 'RESIDENCE'].includes(address.type)" class="text-muted">
                  {{ 'employees_addresses.address_type.'+address.type | translate }}
                </span>

                <span
                  *ngIf="address.description"
                  class="text-muted"
                >({{ address.description }})</span>
              </label>

              <div class="input-group">
                <input
                  class="form-control style-default address-overflow"
                  readonly
                  type="text"
                  value="{{ address.full_address }}"
                />

                <div class="input-group-append input-group-append-over">
                  <button *ngIf="widgetTemplatesService.canSeeButton('Address', 'Edit')"
                          (click)="openEditModal(address)"
                          class="btn btn-link btn-icon text-muted pl-0 pr-0"
                  >
                    <i
                      aria-hidden="true"
                      class="mdi mdi-eye-outline mdi-24px"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #noDataTemplate>
        <div class="row">
          <div class="col-12 text-center">
            <span>{{ 'global.empty_widget_error' | translate }}</span>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>

<ng-template #loadingTemplate>
    <div class="card shadow p-3">
      <div class="item">
        <ngx-skeleton-loader [theme]="{
        'margin': '15px 0'
      }" appearance="line" count="5"></ngx-skeleton-loader>
      </div>
    </div>
</ng-template>
