import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Property } from 'src/app/property/models/Property';
import { PropertyCategory } from 'src/app/property/models/PropertyCategory';
import { PropertyService } from 'src/app/property/services/property.service';
import { FlatpickrHelper } from 'src/app/shared/common/FlatpickrHelper';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';
import { SummernoteHelper } from '../../../../shared/common/SummernoteHelper';
import { SearchService } from '../../../../shared/services/search.service';
import { DeletePropertyModalComponent } from '../../partials/delete-property-modal/delete-property-modal.component';

@Component({
    selector: 'app-create-edit-property',
    templateUrl: './create-edit-property.component.html',
    styleUrls: ['./create-edit-property.component.css']
})
export class CreateEditPropertyComponent implements OnInit, OnDestroy {
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public property: Property;
    public propertyCategories$: Observable<Array<PropertyCategory>>;
    public propertyEditForm: UntypedFormGroup;
    public renderedSerialNumberControls: Array<number> = [];
    public submitted = false;
    public properties: Property[];
    public propertiesAutoComplete: string[];
    public selectFilterValues = [
        {
            name: this._translateService.instant('properties.states.IN_USE'),
            value: 'IN_USE',
        }, {
            name: this._translateService.instant('properties.states.IN_STOCK'),
            value: 'IN_STOCK',
        }, {
            name: this._translateService.instant('properties.states.ARCHIVED'),
            value: 'ARCHIVED',
        }, {
            name: this._translateService.instant('properties.states.LOANED'),
            value: 'LOANED',
        }, {
            name: this._translateService.instant('properties.states.IN_SERVICE'),
            value: 'IN_SERVICE',
        }, {
            name: this._translateService.instant('properties.states.OTHER'),
            value: 'OTHER',
        },
    ];

    private _quantityControlSubscription: Subscription;
    private _searchSubscription: Subscription;

    public constructor(
        public activeModal: NgbActiveModal,
        public fpHelper: FlatpickrHelper,
        public summernoteHelper: SummernoteHelper,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _modalService: NgbModal,
        private _propertyService: PropertyService,
        private _searchService: SearchService,
        private _translateService: TranslateService,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.propertyEditForm.controls;
    }

    public ngOnDestroy(): void {
        this._quantityControlSubscription?.unsubscribe();
        this._searchSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.propertyEditForm = this._fb.group({
            name: ['', Validators.required],
            code: [''],
            serial_number: [''],
            state: ['IN_STOCK'],
            lost_fee: [0],
            property_category_ID: [null, Validators.required],
            date_of_registration: [''],
            date_of_decommissioning: [''],
            end_of_warranty: [null],
            description: [null],
            multiple_items: [false, Validators.required],
            quantity: [1, [Validators.required, Validators.min(1)]]
        });

        this.propertyCategories$ = this._propertyService.getAllPropertyCategories();

        if (this.property) {
            this.propertyEditForm.patchValue({
                name: this.property.name,
                code: this.property.code,
                serial_number: this.property.serial_number,
                state: this.property.state,
                lost_fee: this.property.lost_fee,
                property_category_ID: this.property.property_category_ID,
                date_of_registration: this.property.date_of_registration,
                date_of_decommissioning: this.property.date_of_decommissioning,
                end_of_warranty: this.property.end_of_warranty,
                description: this.property.description,
            });
        } else {
            this.propertyEditForm.addControl('note', this._fb.control(''));
            this._quantityControlSubscription = this.propertyEditForm.get('quantity')
                .valueChanges
                .subscribe((value: number) => {
                    for (let i = 1; i <= this.renderedSerialNumberControls.length; i++) {
                        this.propertyEditForm.removeControl(`serial_number-${i}`);
                    }

                    if (value && value <= 30) {
                        for (let i = 1; i <= value; i++) {
                            this.propertyEditForm.addControl(`serial_number-${i}`, this._fb.control('', Validators.required));
                        }

                        this.renderedSerialNumberControls = new Array(value);
                    }
                });
        }
    }

    public getNewCode(event: PropertyCategory): void {
        if (
            !this.property &&
            event
        ) {
            this._propertyService.getNewCodeByPropertyCategoryID(event.property_category_ID)
                .subscribe((newCode: { code: string }) => {
                    this.propertyEditForm.patchValue({
                        code: newCode.code
                    });
                });
        } else if (!event) {
            this.propertyEditForm.get('code').reset();
        }
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.propertyEditForm.valid) {
            const controls = this.propertyEditForm.controls;
            for (const name in controls) {
                if (controls[name].invalid) {
                    console.log('Invalid control: ' + name, controls[name].errors);
                }
            }
            console.log();
            return;
        }

        const value = this.propertyEditForm.value;

        const serialNumberEntries = Object.entries(this.propertyEditForm.value).filter(entry => entry[0].includes('serial_number-'));
        value.serial_numbers = serialNumberEntries.map(entry => entry[1]);

        let promise = null;

        if (this.property) {
            promise = this._propertyService.saveProperty(this.propertyEditForm.value, this.property.property_ID);
        } else {
            promise = this._propertyService.saveProperty(this.propertyEditForm.value);
        }

        promise
            .then(() => this.activeModal.close('close'));
    }

    public openPropertyDeleteModal(): void {
        const modalRef = this._modalService.open(DeletePropertyModalComponent, {centered: true});

        modalRef.componentInstance.propertyID = this.property.property_ID;

        modalRef.result
            .then(
                () => this.activeModal.close(),
                () => { }
            );
    }

    public onChangeSearch(search: string) {
        if (search.length > 2) {
            this._searchSubscription = this._searchService.search(search, 'Property', 'name')
                .subscribe((result: any[]) => {
                    this.propertiesAutoComplete = result.map(r => r.label);
                });
        }
    }
}
