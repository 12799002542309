import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CompanyPolicyListComponent } from './components/sections/company-policy-list/company-policy-list.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full'
  },

  {
    path: 'list',
    component: CompanyPolicyListComponent,
    data: {
      title: 'company_employee_policies.page_title_index'
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ]
})
export class PolicyRoutingModule { }
