import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { CreateEditGrowthModalComponent } from 'src/app/growth/components/partials/create-edit-growth-modal/create-edit-growth-modal.component';
import { EmployeeGrowthOverviewModalComponent } from 'src/app/growth/components/partials/employee-growth-overview-modal/employee-growth-overview-modal.component';
import { EmployeeGrowth } from 'src/app/growth/employee-growth/employee-growth.model';
import { EmployeeGrowthQuery } from 'src/app/growth/employee-growth/employee-growth.query';
import { EmployeeGrowthService } from 'src/app/growth/employee-growth/employee-growth.service';
import { Growth } from 'src/app/growth/growth/state/growth.model';
import { GrowthQuery } from 'src/app/growth/growth/state/growth.query';
import { GrowthService } from 'src/app/growth/growth/state/growth.service';
import { CreateEditEmployeeReviewModalComponent } from 'src/app/kpi/components/partials/create-edit-employee-review-modal/create-edit-employee-review-modal.component';
import { CreateEditKpiModalComponent } from 'src/app/kpi/components/partials/create-edit-kpi-modal/create-edit-kpi-modal.component';
import { EmployeeReview } from 'src/app/kpi/employee-review/employee-review.model';
import { EmployeeReviewQuery } from 'src/app/kpi/employee-review/employee-review.query';
import { EmployeeReviewService } from 'src/app/kpi/employee-review/employee-review.service';
import { KPI } from 'src/app/kpi/kpi/state/kpi.model';
import { KPIQuery } from 'src/app/kpi/kpi/state/kpi.query';
import { KPIService } from 'src/app/kpi/kpi/state/kpi.service';
import { SubscriptionService } from 'src/app/setting/services/subscription.service';
import { CreateEditKPIMetricModificationModalComponent } from '../../../../kpi/components/partials/create-edit-kpimetric-modification-modal/create-edit-kpimetric-modification-modal.component';
import { KpiRadarChartModalComponent } from '../../../../kpi/components/partials/kpi-radar-chart-modal-component/kpi-radar-chart-modal-component';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { CompanyService } from '../../../../setting/services/company.service';
import { AvailableFeatures } from '../../../models/AvailableFeatures';
import { Employee } from '../../../models/Employee';

@Component({
    selector: 'app-employee-kpis',
    templateUrl: './employee-kpis.component.html',
    styleUrls: ['./employee-kpis.component.css']
})
export class EmployeeKpisComponent implements OnInit, OnDestroy {
    public assignGrowthsForm: UntypedFormGroup;
    public assignKPIsForm: UntypedFormGroup;
    public assignedKpis$: Observable<Array<KPI>>;
    public canCreateGrowth$: Observable<boolean>;
    public canEditGrowth$: Observable<boolean>;
    public canCreateKPI$: Observable<boolean>;
    public canEditKPI$: Observable<boolean>;
    public availableFeatures$: Observable<AvailableFeatures>;
    public availableWidgets$: Observable<AvailableWidgets>;
    public employeeGrowthIDs: Array<number> = [];
    public employeeID: number;
    public assignedGrowths$ = this._employeeGrowthQuery.selectAll({filterBy: ({employee_ID}) => employee_ID === this.employeeID});
    public employee: Employee;
    public employeeKpiIDs: Array<number> = [];
    public permissions: Array<string>;
    public watchingMyself = false;
    public availableRatings = ['😀', '😊', '😐', '😞', '😥'];
    public growths$: Observable<Array<Growth>>;
    public kpi: KPI;
    public kpiReviews$ = this._employeeReviewQuery.selectAll({filterBy: ({employee_ID}) => employee_ID === this.employeeID});
    public kpis$: Observable<Array<KPI>>;
    public submittedGrowths = false;

    private _assignedKpiIds$ = new ReplaySubject<Array<number>>(1);
    private _subscription: Subscription;

    public constructor(
        private _authService: AuthenticationService,
        private _employeeGrowthQuery: EmployeeGrowthQuery,
        private _employeeGrowthService: EmployeeGrowthService,
        private _employeeReviewQuery: EmployeeReviewQuery,
        private _employeeReviewService: EmployeeReviewService,
        private _employeeService: EmployeeService,
        private _companyService: CompanyService,
        private _fb: UntypedFormBuilder,
        private _growthQuery: GrowthQuery,
        private _growthService: GrowthService,
        private _kpiQuery: KPIQuery,
        private _kpiService: KPIService,
        private _modalService: NgbModal,
        private _route: ActivatedRoute,
        private _subscriptionService: SubscriptionService,
        private _cdr: ChangeDetectorRef
    ) { }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public get fGrowths(): { [formControlName: string]: AbstractControl; } {
        return this.assignGrowthsForm.controls;
    }

    public ngOnInit(): void {
        this.availableWidgets$ = this._companyService.availableWidgets$;
        this.availableFeatures$ = this._subscriptionService.availableFeatures$;

        this.permissions = this._authService?.permissions?.map(perm => perm.name);
        this.canCreateGrowth$ = this._authService.hasPermissionTo('growth.create').pipe(map(permission => permission.can));
        this.canCreateKPI$ = this._authService.hasPermissionTo('kpi.create').pipe(map(permission => permission.can));

        this.canEditGrowth$ = combineLatest([
            this._authService.hasPermissionTo('employee.edit').pipe(map(permission => permission.can)),
            this._authService.hasPermissionTo('employee.editStructure').pipe(map(permission => permission.can)),
        ])
            .pipe(
                map(([canEdit, canEditStructure]) => canEdit || canEditStructure),
                shareReplay()
            );

        this.canEditKPI$ = combineLatest([
            this._authService.hasPermissionTo('kpi.edit').pipe(map(permission => permission.can)),
            this._authService.hasPermissionTo('employee.edit').pipe(map(permission => permission.can)),
            this._authService.hasPermissionTo('employee.editStructure').pipe(map(permission => permission.can)),
        ])
            .pipe(
                map(([canEdit, canEditEmployee, canEditEmployeeStructure]) => canEdit || canEditEmployee || canEditEmployeeStructure),
                shareReplay()
            );

        this._subscription = this._route.parent.params.subscribe(params => {
            this.employeeID = parseInt(params.id, 10);
            const loggedEmployeeID = this._authService?.employee?.employee_ID;
            this.watchingMyself = loggedEmployeeID === this.employeeID;
            this._cdr.detectChanges();

            this.assignedKpis$ = this._kpiService.getKpisByEmployee(this.employeeID).pipe(shareReplay());

            this._employeeGrowthService.get(this.employeeID)
                .subscribe();

            this._employeeReviewService.get(this.employeeID)
                .subscribe();

            this._growthService.get()
                .subscribe();

            this._kpiService.get()
                .subscribe();

            this._employeeService.getEmployeeInfoByEmployeeID(this.employeeID, 'edit')
                .subscribe(employee => {
                    this.employee = employee;
                    this.employeeKpiIDs = employee.kpis;

                    this._assignedKpiIds$.next(employee.kpis);
                });
        });

        this.growths$ = combineLatest([
            this._growthQuery.selectAll(),
            this.assignedGrowths$
                .pipe(map(growths => (growths || []).map(g => g.growth_ID)))
        ])
            .pipe(
                map(([allGrowths, assignedGrowthsIds]) => allGrowths.filter(g => assignedGrowthsIds.indexOf(g.growth_ID as number) === -1)),
            );

        this.kpis$ = combineLatest([
            this._kpiQuery.selectAll(),
            this.assignedKpis$
                .pipe(map(kpis => (kpis || []).map(k => k.kpi_ID)))
        ])
            .pipe(
                map(([allKpis, assignedKpiIds]) => allKpis.filter(k => assignedKpiIds.indexOf(k.kpi_ID) === -1)),
            );
    }

    public growthSelectionChanged(): void {
        if (this._isCreatingNewGrowth() === false) {
            this.assignGrowthsForm.removeControl('description');
            this.assignGrowthsForm.removeControl('name');
        } else {
            this.assignGrowthsForm.addControl('description', new UntypedFormControl(null));
            this.assignGrowthsForm.addControl('name', new UntypedFormControl(null, Validators.required));
        }
    }

    public onSubmitGrowth(): void {
        this.submittedGrowths = true;

        if (!this.assignGrowthsForm.valid) {
            return;
        }

        const value = this.assignGrowthsForm.value;

        let promise: Promise<'done'>;

        if (this._isCreatingNewGrowth()) {
            promise = this._employeeGrowthService.createAndAssignGrowthToEmployee(value.name, value.description, value.costs, this.employeeID);
        } else {
            promise = this._growthService.assignGrowthsToEmployee(value.growthIDs, this.employeeID);
        }

        promise
            .then(() => this._modalService.dismissAll());
    }

    public onSubmitKPI(): void {
        if (!this.assignKPIsForm.valid) {
            return;
        }

        const value = this.assignKPIsForm.value;

        this._kpiService.assignKPIsToEmployee(value.kpiIDs, this.employeeID)
            .then(() => {
                this._modalService.dismissAll();

                this.employeeKpiIDs = [...this.employeeKpiIDs, ...value.kpiIDs];

                this.employeeKpiIDs = [...new Set(this.employeeKpiIDs)];

                this.assignedKpis$ = this._kpiService.getKpisByEmployee(this.employeeID);

                this._assignedKpiIds$.next(this.employeeKpiIDs);
            });
    }

    public openEmployeeGrowthOverviewModal(employeeGrowth: EmployeeGrowth): void {
        const modalRef = this._modalService.open(EmployeeGrowthOverviewModalComponent, {centered: true});

        modalRef.componentInstance.employeeGrowthID = employeeGrowth.employee_growth_ID;
    }

    public openModificationModal(kpi: KPI): void {
        const modalRef = this._modalService.open(CreateEditKPIMetricModificationModalComponent, {centered: true});
        modalRef.componentInstance.employee = this.employee;
        modalRef.componentInstance.kpi = kpi;

        modalRef.result
            .then(
                () => this._assignedKpiIds$.next(this.employeeKpiIDs),
                () => { }
            );
    }

    public openRadarChart(scoreType: string, kpi: KPI): void {
        const modalRef = this._modalService.open(KpiRadarChartModalComponent, {centered: true});

        modalRef.componentInstance.employeeID = this.employeeID;
        modalRef.componentInstance.kpiID = kpi.kpi_ID;
        modalRef.componentInstance.scoreType = scoreType;

        modalRef.result
            .then(
                () => { },
                () => { }
            );
    }

    public openGrowthAssignModal(content: any): void {
        this.submittedGrowths = false;

        this.assignGrowthsForm = this._fb.group({
            description: [null],
            costs: [0],
            growthIDs: [[]],
            name: [null, Validators.required]
        });

        this._modalService.open(content, {centered: true});
    }

    public openGrowthCreateModal(): void {
        this._modalService.open(CreateEditGrowthModalComponent, {centered: true});
    }

    public openKPIAssignModal(content: any): void {
        this.assignKPIsForm = this._fb.group({
            kpiIDs: [null, Validators.required]
        });

        this._modalService.open(content, {centered: true});
    }

    public openKPICreateModal(): void {
        this._modalService.open(CreateEditKpiModalComponent, {centered: true});
    }

    public openReviewEditModal(kpis: Array<KPI>, review: EmployeeReview): void {
        const modalRef = this._modalService.open(
            CreateEditEmployeeReviewModalComponent,
            {
                centered: true,
            }
        );

        modalRef.componentInstance.kpis = kpis;
        modalRef.componentInstance.employeeID = this.employeeID;

        if (review) {
            modalRef.componentInstance.review = review;
        }
    }

    public trackByGrowth(index: number, growth: EmployeeGrowth): number | string {
        return growth.employee_growth_ID;
    }

    public trackByKPI(index: number, kpi: KPI): number | string {
        return kpi.kpi_ID;
    }

    public trackByReview(index: number, review: EmployeeReview): number | string {
        return review.employee_review_ID;
    }

    public unassignEmployeeFromGrowth(g: EmployeeGrowth): void {
        if (!g.growth_ID) {
            this._employeeGrowthService.deleteEmployeeGrowth(g.employee_growth_ID);
        } else {
            this._growthService.unassignGrowthFromEmployee(g, this.employeeID);
        }
    }

    public unassignEmployeeFromKPI(k: KPI): void {
        this._kpiService.unAssignEmployeeFromKPI(k.kpi_ID, this.employeeID)
            .then(
                isDeleted => {
                    if (isDeleted) {
                        this.assignedKpis$ = this._kpiService.getKpisByEmployee(this.employeeID);
                    }
                },
                () => { }
            );
    }

    public checkPermission(name: string): boolean {
        return (this.watchingMyself || this.permissions.includes(name + '.view') || this.permissions.includes(name + '.viewStructure'));
    }

    private _isCreatingNewGrowth(): boolean {
        return this.assignGrowthsForm.value?.growthIDs?.length === 0;
    }
}
