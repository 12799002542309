import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ActivityLogListComponent } from '../shared/components/partials/activity-log-list/activity-log-list.component';
import { Routes } from '../shared/models/Route';
import { CreateEditDocumentTemplateHtmlComponent } from './components/sections/create-edit-document-template-html/create-edit=document-template-html.component';
import { CreateEditRoleComponent } from './components/sections/create-edit-role/create-edit-role.component';
import { DocumentTemplateDetailComponent } from './components/sections/document-template-detail/document-template-detail.component';
import { NotificationCenterComponent } from './components/sections/notification-center/notification-center.component';
import { RolesListComponent } from './components/sections/roles-list/roles-list.component';
import { SettingAppearanceComponent } from './components/sections/setting-appearance/setting-appearance.component';
import { SettingAttendanceComponent } from './components/sections/setting-attendance/setting-attendance.component';
import { SettingCRMComponent } from './components/sections/setting-crm/setting-crm.component';
import { SettingDetailComponent } from './components/sections/setting-detail/setting-detail.component';
import { SettingGeneralComponent } from './components/sections/setting-general/setting-general.component';
import { SettingGuideComponent } from './components/sections/setting-guide/setting-guide.component';
import { SettingIntegrationComponent } from './components/sections/setting-integration/setting-integration.component';
import { SettingOtherComponent } from './components/sections/setting-other/setting-other.component';
import { SettingPositionsBranchesComponent } from './components/sections/setting-positions-branches/setting-positions-branches.component';
import { SettingSecurityComponent } from './components/sections/setting-security/setting-security.component';
import { SettingUserAccountsComponent } from './components/sections/setting-user-accounts/setting-user-accounts.component';
import { SubscriptionSettingsOverviewComponent } from './components/sections/subscription-settings-overview/subscription-settings-overview.component';

const routes: Routes = [
    {
        path: '',
        component: SettingDetailComponent,
        children: [
            {
                path: '',
                redirectTo: 'general',
                pathMatch: 'full'
            },

            {
                path: 'general',
                component: SettingGeneralComponent,
                data: {
                    help: 'settings.general',
                    title: 'settings.page_title_view_company_setting'
                }
            },

            {
                path: 'positions-branches',
                component: SettingPositionsBranchesComponent,
                data: {
                    help: 'settings.userAccounts',
                    title: 'settings.page_title_view_positions_branches'
                }
            },

            {
                path: 'user-accounts',
                component: SettingUserAccountsComponent,
                data: {
                    help: 'settings.userAccounts',
                    title: 'settings.page_title_view_user_accounts'
                }
            },

            {
                path: 'crm',
                component: SettingCRMComponent,
                data: {
                    help: 'settings.crm',
                    title: 'settings.page_title_view_crm'
                }
            },

            {
                path: 'other',
                component: SettingOtherComponent,
                data: {
                    help: 'settings.other',
                    title: 'settings.page_title_view_other'
                }
            },

            {
                path: 'guide',
                component: SettingGuideComponent,
                data: {
                    help: 'settings.guide',
                    title: 'settings.page_title_view_guide'
                }
            },
            {path: 'attendance', component: SettingAttendanceComponent, data: {title: 'settings.page_title_view_attendance'}},
            {path: 'document', component: SettingOtherComponent, data: {title: 'settings.page_title_view_other_documents'}},
            {path: 'document/:key/detail', component: DocumentTemplateDetailComponent, data: {title: 'settings.company.documents_templates.template_detail'}},
            {path: 'document/:key/html', component: CreateEditDocumentTemplateHtmlComponent, data: {title: 'settings.company.documents_templates.create_html_title'}},
            {path: 'notification', component: NotificationCenterComponent, data: {title: 'settings.page_title_view_notifications'}},
            {path: 'integration', component: SettingIntegrationComponent, data: {title: 'settings.page_title_view_integration'}},

            {
                path: 'security',
                component: SettingSecurityComponent,
                data: {
                    title: 'settings.page_title_view_security',
                    help: 'settings.security'
                }
            },
            {
                path: 'appearance',
                component: SettingAppearanceComponent,
                data: {
                    title: 'settings.page_title_view_appearance',
                    help: 'settings.appearance'
                }
            },
            {
                path: 'activity-log',
                component: ActivityLogListComponent,
                data: {
                    title: 'activity_log.title_header',
                    help: 'settings.activityLog'
                }
            },
        ]
    },

    // {
    //   path: 'roles/list',
    //   component: RolesListComponent,
    //   data: {
    //     title: 'roles.page_title_index'
    //   }
    // },

    {
        path: 'roles/edit/:id',
        component: CreateEditRoleComponent,
        data: {
            help: 'settings.role.edit',
            title: 'roles.page_title_edit'
        }
    },

    {
        path: 'roles/create',
        component: CreateEditRoleComponent,
        data: {
            help: 'settings.role.create',
            title: 'roles.page_title_create'
        }
    },

    {path: 'roles/list', component: RolesListComponent, data: {title: 'roles.page_title_index'}},
    // {
    //   path: 'number-series/list',
    //   component: NumberSeriesListComponent,
    //   data: {
    //     title: 'numbers_series.page_title_index'
    //   }
    // },

    // {
    //   path: 'company-settings',
    //   component: CompanySettingsComponent,
    //   data: {
    //     title: 'settings.page_title_view_company_setting'
    //   },
    //   children: [
    //     {
    //       path: '',
    //       redirectTo: 'basic-settings',
    //       pathMatch: 'full'
    //     },

    //     {
    //       path: 'basic-settings',
    //       component: CompanySettingsBasicComponent,
    //       data: {
    //         title: 'settings.page_title_view_company_setting_basic'
    //       }
    //     },

    //     {
    //       path: 'addresses',
    //       component: CompanySettingsAddressComponent,
    //       data: {
    //         title: 'settings.page_title_view_company_setting_addresses'
    //       }
    //     },

    //     {
    //       path: 'bank-account',
    //       component: CompanySettingsAccountComponent,
    //       data: {
    //         title: 'settings.page_title_view_company_setting_bank_account'
    //       }
    //     },

    //     {
    //       path: 'other-settings',
    //       component: CompanySettingsOtherComponent,
    //       data: {
    //         title: 'settings.page_title_view_company_setting_other'
    //       }
    //     },
    //   ]
    // },

    {
        path: 'subscription-settings',
        component: SubscriptionSettingsOverviewComponent,
        data: {
            help: 'settings.subscription',
            title: 'settings.page_title_view_subscription_setting'
        }
    },

    // {
    //   path: 'users/list',
    //   component: UserListComponent,
    //   data: {
    //     title: 'users.page_title_index'
    //   }
    // },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [
        RouterModule,
    ]
})
export class SettingRoutingModule {}
