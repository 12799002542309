<div class="row">
  <div class="col-md-12">
    <form
      #f="ngForm"
      autocomplete="off"
      (ngSubmit)="f.form.valid && onSubmit(f.value)"
    >
      <div
        *ngIf="role$ | async; let role"
        class="row"
      >
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-header">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'global.basic_info' | translate }}</span>
              </p>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label required-label">
                      <span>{{ 'roles.name' | translate }}</span>
                    </label>

                    <input
                      #name="ngModel"
                      required
                      class="form-control"
                      name="name"
                      type="text"
                      [ngModel]="role?.name"
                    >

                    <div *ngIf="(name.invalid && f.submitted) || (name.touched && name.invalid)">
                      <p class="text-danger validation-text">
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'roles.name' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label required-label">
                      <span>{{ 'roles.level' | translate }}</span>
                    </label>

                    <input
                      required
                      #level="ngModel"
                      class="form-control"
                      name="level"
                      type="number"
                      [ngModel]="role?.level"
                    >

                    <div *ngIf="(level.invalid && f.submitted) || (level.touched && level.invalid)">
                      <p class="text-danger validation-text">
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'roles.level' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-6 text-left">
                  <button class="btn btn-success">
                    <span>{{ 'roles.action_save' | translate }}</span>
                  </button>
                </div>

                <div class="col-6 text-right my-auto">
                  <u
                    class="text-muted"
                    (click)="deleteRole()"
                  >{{ 'roles.action_delete' | translate }}</u>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="permissions$ | async; let permissions">
        <div
          *ngFor="let p of permissions"
          class="row mb-3"
        >
          <div class="col-md-12">
            <div class="card shadow">
              <div class="card-header">
                <p class="modal-title font-16 text-dark pt-2 pb-2">
                  <span>{{ p.name }}</span>
                </p>
              </div>

              <div class="card-body pt-0">
                <div class="table-responsive">
                  <table class="table table-centered mb-0">
                    <tbody>
                      <tr
                        *ngFor="let a of p.values"
                        [ngModelGroup]="p.name"
                      >
                        <td>{{ a.label }}</td>

                        <td class="text-right">
                          <div>
                            <input
                              data-switch="success"
                              id="{{ a.name }}"
                              name="{{ a.name }}"
                              type="checkbox"
                              [ngModel]="a.active"
                            >

                            <label for="{{ a.name }}">&nbsp;</label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col d-flex justify-content-end">
            <button class="btn btn-success">
              <span>{{ 'roles.action_save' | translate }}</span>
            </button>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
</div>
