import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { localStorageSafe } from '../functions';

@Injectable({
    providedIn: 'root',
})
export class LastActiveService {
    public lastActive$: Observable<Date>;
    private _localStorageKey = 'user_last_active';
    private _events: string[] = ['keydown', 'click', 'wheel', 'mousemove'];
    private _lastActive: BehaviorSubject<Date>;

    constructor() {
        const lastActiveDate = this.getLastActiveFromLocalStorage() ?? new Date();
        this._lastActive = new BehaviorSubject<Date>(lastActiveDate);
        this.lastActive$ = this._lastActive.asObservable();
    }

    public setUp(): void {
        this._events.forEach(event =>
            fromEvent(document, event).subscribe(_ => this.recordLastActiveDate())
        );
    }

    public getLastActiveFromLocalStorage(): Date | null {
        const valueFromStorage = localStorageSafe.getItem(this._localStorageKey);
        if (!valueFromStorage) {
            return null;
        }

        return new Date(valueFromStorage);
    }

    private recordLastActiveDate(): void {
        const currentDate = new Date();
        localStorageSafe.setItem(this._localStorageKey, currentDate.toString());
        this._lastActive.next(currentDate);
    }
}
