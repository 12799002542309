import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { AccountMove } from 'src/app/account/models/AccountMove';
import { File } from 'src/app/employee/models/File';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { Customer } from '../models/Customer';
import { CustomerActivity } from '../models/CustomerActivity';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor(
        private http: HttpClient,
        private loaderService: NgxUiLoaderService,
        private apiHelper: ApiHelper,
        private swalHelper: SwalHelper
    ) { }

    public in: number;

    public getAllCustomers(type?: 'ALL' | 'CUSTOMER' | 'SUPPLIER'): Observable<Customer[]> {
        if (!type) {
            type = 'CUSTOMER';
        }
        const params = new HttpParams().set('type', type);
        return this.http.get<Customer[]>('/api/customers', {params});
    }

    public getCustomerByID(customerID: number): Observable<Customer> {
        return this.http.get<Customer>('/api/customers/' + customerID);
    }

    public getActivitiesByCustomerID(customerID: number): Observable<CustomerActivity[]> {
        return this.http.get<CustomerActivity[]>('/api/customers/' + customerID + '/activities');
    }

    public getActivityByCustomerID(customerID: number, customerActivityID: number): Observable<CustomerActivity> {
        return this.http.get<CustomerActivity>('/api/customers/' + customerID + '/activities/' + customerActivityID);
    }

    public getAllFilesByCustomerID(customerID: number): Observable<File[]> {
        return this.http.get<File[]>('/api/customers/' + customerID + '/files');
    }

    public getAllAccountMovesAssociatedWithCustomer(customerID: number): Observable<AccountMove[]> {
        return this.http.get<AccountMove[]>(`/api/customers/${customerID}/moves`);
    }

    public saveCustomerActivity(form: CustomerActivity, customerID: number, customerActivityID?: number): Promise<string> {
        this.loaderService.start();
        return new Promise(resolve => {
            if (customerActivityID) {
                this.http.post('/api/customers/' + customerID + '/activities/' + customerActivityID, form).subscribe((response: ApiResponse) => {
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                    this.loaderService.stop();
                }, error => {
                    this.apiHelper.handleErrorResponse(error);
                    this.loaderService.stop();
                });
            } else {
                this.http.post('/api/customers/' + customerID + '/activities', form).subscribe((response: ApiResponse) => {
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                    this.loaderService.stop();
                }, error => {
                    this.apiHelper.handleErrorResponse(error);
                    this.loaderService.stop();
                });
            }
        });
    }

    public deleteCustomer(customerID: number) {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this.swalHelper.showConfirmDeleteDialog();
            if (deleteConfirmed) {
                this.http.delete('/api/customers/' + customerID).subscribe((response: ApiResponse) => {
                    this.loaderService.stop();
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                }, error => {
                    this.loaderService.stop();
                    this.apiHelper.handleErrorResponse(error);
                });
            } else {
                reject('error');
            }
        });
    }

    public saveCustomer(form: Customer, customerID?: number): Promise<string> {
        this.loaderService.start();
        return new Promise(resolve => {
            if (customerID) {
                this.http.post('/api/customers/' + customerID, form).subscribe((response: ApiResponse) => {
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                    this.loaderService.stop();
                }, error => {
                    this.apiHelper.handleErrorResponse(error);
                    this.loaderService.stop();
                });
            } else {
                this.http.post('/api/customers', form).subscribe((response: any) => {
                    this.apiHelper.handleSuccessResponse(response);
                    resolve(response.customerID);
                    this.loaderService.stop();
                }, error => {
                    this.apiHelper.handleErrorResponse(error);
                    this.loaderService.stop();
                });
            }
        });
    }

    public deleteCustomerActivity(customerID: number, customerActivityID: number): Promise<string> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this.swalHelper.showConfirmDeleteDialog();
            if (deleteConfirmed) {
                this.http.delete('/api/customers/' + customerID + '/activities/' + customerActivityID).subscribe((response: ApiResponse) => {
                    this.loaderService.stop();
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                }, error => {
                    this.loaderService.stop();
                    this.apiHelper.handleErrorResponse(error);
                });
            } else {
                reject('error');
            }
        });
    }

    public findCompany(identificationNumber: string): Observable<any> {
        let params = null;
        if (identificationNumber) {
            params = new HttpParams().set('in', identificationNumber);
        }
        return this.http.get('/api/customers/info', {params});
    }

    public saveBasicOnBoarding(form: any) {
        return new Promise(resolve => {
            this.http.post('/api/guides/basic-settings', form).subscribe((response: ApiResponse) => {
                this.loaderService.stop();
                this.apiHelper.handleSuccessResponse(response);
                resolve('done');
            }, error => {
                this.loaderService.stop();
                this.apiHelper.handleErrorResponse(error);
            });
        });
    }

    public getBasicOnBoardingProgress(): Observable<{ progress: number }> {
        return this.http.get<{ progress: number }>('/api/guides/basic-settings/progress');
    }
}
