import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanyService } from 'src/app/setting/services/company.service';
import { Employee } from '../../../../employee/models/Employee';
import { EmployeeService } from '../../../../employee/services/employee.service';

@Component({
    selector: 'app-attendance-indicator-settings',
    templateUrl: './attendance-indicator-settings.component.html',
    styleUrls: ['./attendance-indicator-settings.component.css']
})
export class AttendanceIndicatorSettingsComponent implements OnInit {
    public form: UntypedFormGroup;
    public settings$: Observable<any>;
    public employees$: Observable<Employee[]>;

    public constructor(
        private _companyService: CompanyService,
        private _employeeService: EmployeeService,
        private _fb: UntypedFormBuilder,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.form.controls;
    }

    public ngOnInit(): void {
        this.form = this._fb.group({
            'company.attendance.highlight_daily_hour_work_fond': ['', Validators.required],
            'company.attendance.highlight_excluded_employees': [[]]
        });

        this.employees$ = this._employeeService.getAllEmployees(false, 'select');

        this.settings$ = this._companyService.getCompanySetting()
            .pipe(
                tap(settings => {
                    this.form.patchValue({
                        'company.attendance.highlight_daily_hour_work_fond': settings.company_settings.attendance.highlight_daily_hour_work_fond === '1',
                        'company.attendance.highlight_excluded_employees': settings.company_settings.attendance.highlight_excluded_employees
                    });
                })
            );
    }

    public onSubmit(): void {
        const value = this.form.value;

        value.settings_type = 'attendance';

        this._companyService.saveCompanySetting(this.form.value);
    }
}
