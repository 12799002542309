import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AccountMove } from 'src/app/account/models/AccountMove';
import { Customer } from 'src/app/crm/models/Customer';
import { Order } from 'src/app/crm/models/Order';
import { Document } from 'src/app/document/models/Document';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeActivity } from 'src/app/employee/models/EmployeeActivity';
import { File } from 'src/app/employee/models/File';
import { DocumentService } from 'src/app/employee/services/document.service';
import { InternalDocument } from 'src/app/internal-document/state/internal-document.model';
import { Property } from 'src/app/property/models/Property';
import { FileService } from 'src/app/shared/services/file.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { DocumentTemplate } from '../../../../setting/models/DocumentTemplate';
import { CompanyService } from '../../../../setting/services/company.service';
import { Training } from '../../../../training/state/training.model';

interface FileWithGroup extends File {
    group: string;
}

@Component({
    selector: 'app-files-table',
    templateUrl: './files-table.component.html',
    styleUrls: ['./files-table.component.css']
})

export class FilesTableComponent implements OnInit {
    @Input() additionalColumnKeys: Array<string>;
    @Input() files$: Observable<Array<FileWithGroup>>;
    @Input() object: Employee | EmployeeActivity | AccountMove | Customer | Document | Order | Property | InternalDocument | Training | DocumentTemplate;
    @Input() objectType: 'EMPLOYEE' | 'EMPLOYEE-AVATAR' | 'EMPLOYEE_ACTIVITY' | 'ACCOUNTMOVE' | 'CUSTOMER' | 'DOCUMENT' | 'ORDER' | 'PROPERTY' | 'INTERNALDOCUMENT' | 'TRAINING' | 'MEETING' | 'PROPERTY_FILE' | 'INVENTORYITEM' | 'LOGO' | 'DOCUMENTLOGO' | 'SIGNATURE' | 'PAGE' | 'ARTICLE' | 'DOCUMENT_TEMPLATE';
    @Input() allowDelete: boolean;
    @Input() showFileOnNewPage: boolean;
    @Input() entityType: string;
    @Input() groupBy: string;
    @Input() entityID: number;
    @Input() loadSettings = true;
    @Output() refetch = new EventEmitter<string>();
    public allowedDocumentSignature: boolean;
    public fileEditForm: UntypedFormGroup;
    public selectedFileID: number;
    public submitted = false;
    public groups = [];
    public groupsCollapsed = {};

    public constructor(
        public documentService: DocumentService,
        private _companyService: CompanyService,
        private _fb: UntypedFormBuilder,
        private _documentService: DocumentService,
        private _fileService: FileService,
        private _modalService: NgbModal,
        public _authservice: AuthenticationService
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.fileEditForm.controls;
    }

    public ngOnInit(): void {
        if (!this.allowDelete) {
            this.allowDelete = false;
        }

        if (this.groupBy) {
            const currentYear = new Date().getFullYear().toString();
            this.files$ = this.files$.pipe(map(files => {
                const groups = [];
                const result = [];

                files.forEach((file, index) => {
                    let groupKey = '';
                    // group by year for employee payrolls, else group by name of property of file object passed in
                    if (this.groupBy === 'year') {
                        groupKey = file?.date?.slice(0, 4);
                        this.groupsCollapsed[groupKey] = currentYear === groupKey;
                    } else {
                        groupKey = file[this.groupBy];
                        this.groupsCollapsed[groupKey] = true;
                    }

                    groups[index] = groupKey;
                    result[index] = {...file, group: groupKey};
                });
                this.groups = [...new Set(groups)];

                return result;
            }));
        }

        this.fileEditForm = this._fb.group({
            name: ['', Validators.required]
        });
        this._getCompanySettings();
    }

    public deleteFile(fileID: number, documentID: number): void {
        let promise = null;

        if (documentID) {
            promise = this._documentService.deleteDocument(documentID);
        } else {
            promise = this._fileService.deleteFileByID(fileID, this.object, this.objectType);
        }

        promise.then(
            () => this.refetch.emit('refetch'),
            () => { }
        );
    }

    public onSubmit(): void {
        this.submitted = true;
        if (!this.fileEditForm.valid) {
            return;
        }

        this._fileService.updateFileByID(this.fileEditForm.value, this.selectedFileID)
            .then(() => {
                this.refetch.emit('refetch');

                this._modalService.dismissAll();
            });
    }

    public openEditModal(content: any, file: File): void {
        this.selectedFileID = file.file_ID;
        this.fileEditForm.patchValue({name: file.name});
        this._modalService.open(content, {centered: true});
    }

    public collapseGroup(group: string): void {
        this.groupsCollapsed[group] = !this.groupsCollapsed[group];
    }

    public openDocumentModal(fileHash: string): void {
        this.documentService.openDocumentDetailModal(fileHash, this.allowedDocumentSignature);
    }

    private _getCompanySettings(): void {
        if (this.loadSettings) {
            this._companyService.getCompanySetting().pipe(shareReplay()).subscribe((response: any) => {
                this.allowedDocumentSignature = Boolean(Number(response.company_settings?.documents?.allow_signature ?? false));
            }, () => {});
        }
    }
}
