import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Bonus } from 'src/app/benefit-bonus/bonus/bonus.model';
import { BonusService } from 'src/app/benefit-bonus/bonus/bonus.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';
import { InputVisibilityService } from '../../../../shared/services/InputVisibilityService';

@Component({
    selector: 'app-create-edit-employee-bonus-modal',
    templateUrl: './create-edit-employee-bonus-modal.component.html',
    styleUrls: ['./create-edit-employee-bonus-modal.component.css']
})
export class CreateEditEmployeeBonusModalComponent implements OnInit {
    public bonus: Bonus;
    public bonusEditForm: UntypedFormGroup;
    public canEditOrDelete$: Observable<boolean>;
    public employeeID: number;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public submitted = false;

    public constructor(
        public activeModal: NgbActiveModal,
        private _authService: AuthenticationService,
        private _bonusService: BonusService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _inputVisibilityService: InputVisibilityService,
    ) { }

    public get hiddenInputs(): boolean {
        return this._inputVisibilityService.hiddenInputs;
    }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.bonusEditForm.controls;
    }

    public ngOnInit(): void {
        this.bonusEditForm = this._fb.group({
            name: ['', Validators.required],
            date: ['', Validators.required],
            amount: [0, Validators.required],
            note: ['']
        });

        if (this.bonus) {
            this.bonusEditForm.patchValue({
                name: this.bonus.name,
                date: this.bonus.date,
                amount: this.bonus.amount,
                note: this.bonus.note
            });
        }

        if (this.hiddenInputs) {
            this.bonusEditForm.disable();
        }

        this.canEditOrDelete$ = combineLatest([
            this._authService.hasPermissionTo('employee.edit').pipe(map(permission => permission.can)),
            this._authService.hasPermissionTo('employee.editStructure').pipe(map(permission => permission.can)),
        ])
            .pipe(
                map(([canEdit, canEditStructure]) => canEdit || canEditStructure),
                tap(can => can ? null : this.bonusEditForm.disable())
            );
    }

    public deleteBonus(): void {
        this._bonusService.deleteBonus(this.employeeID, this.bonus.employee_bonus_ID, this.activeModal);
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.bonusEditForm.valid) {
            return;
        }

        const benefit = this.bonusEditForm.value;

        if (this.bonus) {
            this._bonusService.saveBonus(benefit, this.employeeID, this.activeModal, this.bonus.employee_bonus_ID);
        } else {
            this._bonusService.saveBonus(benefit, this.employeeID, this.activeModal);
        }
    }
}
