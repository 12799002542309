<div *ngIf="loggedInUser$ | async; let user" class="modal-content">
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">
      <span>{{ 'workspace.switch_title' | translate }}</span>
    </p>

    <button aria-label="Close" class="close" type="button" (click)="activeModal.dismiss()">
      <span aria-hidden="true" class="font-24 text-muted">
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <ul class="available-workspaces">
      <li *ngFor="let workspace of availableWorkspaces$ | async; trackBy:trackByValue" class="workspace justify-content-start">
            <span class="workspace-logo">
              <img alt="" [attr.src]="getSafeSrc(workspace.logoBase64)">
            </span>
        <span>{{ workspace.label }}</span>
        <u *ngIf="user?.workspace?.value !== workspace.value" class="text-primary workspace-switcher" (click)="switch(workspace)">
          {{ 'workspace.action_switch' | translate }}
        </u>
      </li>
    </ul>
  </div>
</div>
