import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexPlotOptions, ApexStroke, ApexTooltip, ApexYAxis, ChartType } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { LocalizedCurrencyPipe } from '../../../pipes/localized-currency.pipe';
import { InputVisibilityService } from '../../../services/InputVisibilityService';

@Component({
    selector: 'app-bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent implements OnInit, OnDestroy {
    @Input() labels: Array<string>;
    @Input() series: ApexAxisChartSeries;
    @Input() type: ChartType;
    @Input() colors: string[];
    @Input() horizontal = false;
    @Input() height = 428;
    @Input() showAxis = true;
    @Input() yaxisFormat: 'RAW' | 'CURRENCY' | 'DAYS' | 'NON_DECIMAL';
    @Input() tooltips: Array<Array<string>>;

    public chart: ApexChart = {
        animations: {
            enabled: false
        },
        toolbar: {
            show: false
        },
        type: 'bar'
    };

    public dataLabels: ApexDataLabels = {
        enabled: false
    };

    public stroke: ApexStroke = {
        show: true,
        width: 2,
        colors: ['transparent']
    };

    public xaxis: ApexYAxis = {
        show: true
    };

    public yaxis: ApexYAxis = {
        labels: {
            formatter: (value) => {
                return this.formatYAxisValue(value, this.yaxisFormat);
            }
        }
    };

    public tooltip: ApexTooltip = {
        enabled: true
    };

    public grid = {
        show: true
    };

    public legend = {
        show: true
    };

    public plotOptions: ApexPlotOptions = {
        bar: {
            horizontal: false
        }
    };

    public get hiddenInputs(): boolean {
        return this._inputVisibilityService.hiddenInputs;
    }

    private _hiddenInputsSubscription: Subscription;

    public constructor(
        private _localizedCurrency: LocalizedCurrencyPipe,
        private _inputVisibilityService: InputVisibilityService
    ) {}

    public ngOnDestroy(): void {
        this._hiddenInputsSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this._hiddenInputsSubscription = this._inputVisibilityService.hiddenInputs$.subscribe((value) => {
            this.yaxis = {
                labels: {
                    formatter: (val) => {
                        return this.formatYAxisValue(val, this.yaxisFormat);
                    },
                }
            };
        });

        if (!this.showAxis) {
            this._hideAxis();
        }

        this.chart.height = this.height;

        if (this.horizontal) {
            this.plotOptions.bar.horizontal = this.horizontal;
            this.chart.height = 40 * (this.series[0].data.length + 1);
        }
    }

    public formatYAxisValue(value: number, format: string): string {
        if (format === 'CURRENCY') {
            if (this.hiddenInputs) {
                return '******';
            }
            return this._localizedCurrency.transform(value);
        } else if (format === 'PERCENT') {
            return value.toFixed(2) + ' %';
        } else if (format === 'DAYS') {
            return value.toFixed(0) + ' d';
        } else if (format === 'NON_DECIMAL') {
            return value.toFixed(0);
        } else {
            return typeof value === 'number' ? value?.toFixed(2) : value;
        }
    }

    private _hideAxis(): void {
        this.xaxis = {
            floating: true,
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
        };

        this.yaxis = {
            floating: true,
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
        };

        this.grid = {
            show: false
        };

        this.plotOptions.bar = {
            ...this.plotOptions.bar,
            columnWidth: '70%',
        };

        this.chart.stacked = true;
        this.chart.width = '100%';
        this.chart.sparkline = {
            enabled: true
        };

        this.tooltip = {
            fixed: {
                enabled: true,
                position: 'topRight',
                offsetY: 100
            },
            shared: true,
            intersect: false,
            y: {
                formatter: (val: number, opts?: { dataPointIndex: number, series: number[], seriesIndex: number }): string => {
                    return this.tooltips[opts.seriesIndex][opts.dataPointIndex] + 'h';
                }
            }
        };
    }
}
