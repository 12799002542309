import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { UIButtonModule, UIDataTableModule, UIItemsPerPageSelectModule, UIPaginationModule } from '../ui';
import { EntityUpdateRequestModalComponent } from './components/partials/modal/entity_update_request-modal.component';
import { EntityUpdateRequestComponent } from './components/sections/list/entity_update_request-list.component';
import { EntityUpdateRequestRoutingModule } from './entity_update_request-routing.module';

@NgModule({
    declarations: [
        EntityUpdateRequestComponent,
        EntityUpdateRequestModalComponent,
    ],
    imports: [
        EntityUpdateRequestRoutingModule,
        SharedModule,
        UIDataTableModule,
        UIItemsPerPageSelectModule,
        UIPaginationModule,
        UIButtonModule,
    ],
    exports: [
        EntityUpdateRequestComponent
    ]
})
export class EntityUpdateRequestModule {}
