import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Benefit } from 'src/app/benefit-bonus/benefit/state/benefit.model';
import { BenefitQuery } from 'src/app/benefit-bonus/benefit/state/benefit.query';
import { BenefitService } from 'src/app/benefit-bonus/benefit/state/benefit.service';
import { CreateEditBenefitModalComponent } from 'src/app/benefit-bonus/components/partials/create-edit-benefit-modal/create-edit-benefit-modal.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';
import { FlatpickrHelper } from '../../../../shared/common/FlatpickrHelper';

@Component({
    selector: 'app-employee-benefit-bonus',
    templateUrl: './employee-benefit-bonus.component.html',
    styleUrls: ['./employee-benefit-bonus.component.css']
})
export class EmployeeBenefitBonusComponent implements OnInit, OnDestroy {
    public assignBenefitsForm: UntypedFormGroup;
    public assignedBenefits$: Observable<Array<Benefit>>;
    public benefits$: Observable<Array<Benefit>>;
    public canCreateBenefit$: Observable<boolean>;
    public canEditBenefit$: Observable<boolean>;
    public employeeBenefitIDs: Array<number> = [];
    public employeeID: number;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public submittedBenefits = false;

    private _subscription: Subscription;

    public constructor(
        private _authService: AuthenticationService,
        private _benefitQuery: BenefitQuery,
        private _benefitService: BenefitService,
        private _employeeService: EmployeeService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _modalService: NgbModal,
        public fpHelper: FlatpickrHelper,
        private _route: ActivatedRoute
    ) { }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.assignBenefitsForm.controls;
    }

    public ngOnInit(): void {
        this.canCreateBenefit$ = this._authService.hasPermissionTo('benefit.create').pipe(map(permission => permission.can));
        this.canEditBenefit$ = this._authService.hasPermissionTo('benefit.edit').pipe(map(permission => permission.can));

        this.assignBenefitsForm = this._fb.group({
            benefit_ID: [null, Validators.required],
            handover_date: [null, Validators.required]
        });

        this._subscription = this._route.parent.params.subscribe(params => {
            this.employeeID = parseInt(params.id, 10);

            this.assignedBenefits$ = this._benefitService.getByEmployeeID(this.employeeID).pipe(shareReplay());

            this.benefits$ = combineLatest([
                this._benefitQuery.selectAll(),
                this.assignedBenefits$
                    .pipe(map(benefits => (benefits || []).map(b => b.benefit_ID)), shareReplay())
            ])
                .pipe(
                    map(([allBenefits, assignedBenefitIds]) => allBenefits.filter(b => assignedBenefitIds.indexOf(b.benefit_ID) === -1)),
                    shareReplay()
                );

            if (this._authService.checkPermission('benefit.view')) {
                this._benefitService.get()
                    .subscribe();
            }

            this.refetchBenefits();
        });
    }

    public refetchBenefits(): void {
        this._employeeService.getEmployeeInfoByEmployeeID(this.employeeID, 'edit')
            .subscribe(employee => {
                this.employeeBenefitIDs = employee.benefits;
            });
    }

    public onSubmitBenefit(): void {
        this.submittedBenefits = true;

        if (!this.assignBenefitsForm.valid) {
            return;
        }

        const value = this.assignBenefitsForm.value;

        this._benefitService.assignBenefitToEmployee(value, this.employeeID, value.benefit_ID)
            .subscribe(() => {
                this._modalService.dismissAll();
                this.refetchBenefits();
            });
    }

    public openBenefitAssignModal(content: any): void {
        this.submittedBenefits = false;

        this._modalService.open(content, {centered: true});
    }

    public openBenefitCreateModal(): void {
        this._modalService.open(CreateEditBenefitModalComponent, {centered: true});
    }

    public trackByBenefit(index: number, benefit: Benefit): number | string {
        return benefit.benefit_ID;
    }
}
