import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-setting-crm',
  templateUrl: './setting-crm.component.html',
  styleUrls: ['./setting-crm.component.css']
})
export class SettingCRMComponent implements OnInit {
  public canViewCompanySettings$: Observable<{ can: boolean }>;

  public canViewCRM$: Observable<{ can: boolean }>;

  public canViewNumberSeries$: Observable<{ can: boolean }>;

  public constructor(private _authService: AuthenticationService) { }

  public ngOnInit(): void {
    this.canViewCompanySettings$ = this._authService.hasPermissionTo('companySetting.view');

    this.canViewCRM$ = this._authService.hasPermissionTo(['order.view', 'customer.view']);

    this.canViewNumberSeries$ = this._authService.hasPermissionTo('numberSerie.view');
  }
}
