import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: 'form button[type="submit"], button[submitButton]'
})

export class SubmitButtonDirective implements AfterViewInit {

    constructor(
        public el: ElementRef,
    ) { }

    public event: CustomEvent;

    @HostListener('click', ['$event']) onClick(): void {
        document.dispatchEvent(this.event);
    }

    public ngAfterViewInit(): void {
        this.event = new CustomEvent('submitButtonClicked', {
            detail: {
                button: this.el.nativeElement
            },
            cancelable: true,
        });
    }
}
