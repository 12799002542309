import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import Swal from 'sweetalert2';
import { SwalHelper } from '../../../../shared/common/SwalHelper';
import { InputVisibilityService } from '../../../../shared/services/InputVisibilityService';
import { EmployeePersonalData } from '../../../models/EmployeePersonalData';
import { PersonalDataFormComponent } from '../../embedded-forms/personal-data/personal-data-form.component';

@Component({
    selector: 'app-create-edit-employee-personal-data-modal',
    templateUrl: './create-edit-employee-personal-data-modal.component.html',
    styleUrls: ['./create-edit-employee-personal-data-modal.component.css']
})
export class CreateEditEmployeePersonalDataModalComponent implements OnInit {
    @ViewChild('childRef', {static: false}) formComponent: PersonalDataFormComponent;

    public employeePersonalData: EmployeePersonalData;
    public employee: Employee;
    public hasEditEmployeePermission = false;
    public canEdit$: Observable<boolean>;
    public submitted = false;
    public updateRequested = false;

    public get hiddenInputs(): boolean {
        return this._inputVisibilityService.hiddenInputs;
    }

    public constructor(
        public activeModal: NgbActiveModal,
        private _authService: AuthenticationService,
        private _employeeService: EmployeeService,
        private _modalService: NgbModal,
        private _swalHelper: SwalHelper,
        private _translateService: TranslateService,
        private _inputVisibilityService: InputVisibilityService,
    ) { }

    public ngOnInit(): void {
        this.canEdit$ = this._authService.hasPermissionTo('employeePersonalData.edit')
            .pipe(
                first(),
                map(permission => permission.can)
            );

        if (this.employee?.permissions?.requestEditEmployee) {
            this.hasEditEmployeePermission = true;
        }
    }

    public onSubmit(): void {
        if (this.formComponent.personalInTaken || this.formComponent.inTaken) {
            Swal.fire({
                title: this._translateService.instant('swal.action_are_you_sure'),
                text: this._translateService.instant('swal.action_save_duplicates'),
                showCancelButton: true,
                confirmButtonColor: 'red',
                confirmButtonText: this._translateService.instant('swal.action_save'),
                cancelButtonText: this._translateService.instant('swal.action_cancel_change')
            }).then((result) => {
                if (result.value) {
                    this.submit();
                }
            });
        } else {
            this.submit();
        }
    }

    public submit(): void {
        this.submitted = true;
        const form = this.formComponent.form;

        if (form.invalid) {
            return;
        }

        if (!form.value.has_pension) {
            form.value.pension_type = '';
            form.value.pension_amount = 0;
        }

        if (!form.value.has_salary_deduction) {
            form.value.salary_deduction_type = '';
            form.value.salary_deduction_amount = 0;
        }
        this._employeeService.savePersonalData(form.value, this.employee.employee_ID)
            .then(() => {
                this.activeModal.close();
            });
    }
}
