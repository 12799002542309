import { ChangeDetectorRef, Component, NgZone, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ListParameters } from 'src/app/api';
import { ListResponse } from 'src/app/api/interface/list-response.interface';
import { localStorageSafe } from '../../../shared/functions';
import { Sort } from '../../data-table/interface/sort.interface';
import { WithDataTable } from '../../data-table/interface/with-data-table.interface';

@Component({
    template: ''
})
export abstract class FatherOfListComponent<T> implements WithDataTable<T>, OnDestroy {
    public itemsPerPage = 10;
    public itemsTotal: number;
    public loading: boolean;
    public page = 1;
    public rows: Array<T>;
    public search: string;
    public saveToLocalStorage = false;
    public localStorageFormName: string;

    private _localStorageConfigName = 'list_components_config';
    private _subscriptions: Subscription;

    protected _loading$ = new BehaviorSubject(true);
    protected _rows$: Observable<Array<T>>;

    public abstract sort: Sort<T>;

    public abstract trackByFn(index: number, row: T): number | string;

    protected abstract _fetchListData(): void;

    protected constructor(
        protected _ngZone: NgZone,
        protected _changeDetectorRef: ChangeDetectorRef
    ) { }

    public ngOnDestroy(): void {
        this._subscriptions?.unsubscribe();
    }

    public itemsPerPageChanged(itemsPerPage: number): void {
        this.itemsPerPage = itemsPerPage;

        this.saveFormConfigToLocalStorage(itemsPerPage, 'itemsPerPage');

        this._resetPage();

        this._fetchListData();
    }

    public pageChanged(page: number): void {
        this.page = page;

        this._fetchListData();
    }

    public searchChanged(search: string): void {
        this.search = search;

        this._resetPage();

        this._fetchListData();
    }

    public sortChanged(sort: Sort<T>): void {
        this.sort = sort;

        this.saveFormConfigToLocalStorage(sort, 'sort');

        this._resetPage();

        this._fetchListData();
    }

    public useLocalStorage(form: UntypedFormGroup, formName: string): void {
        const lsObject = JSON.parse(localStorageSafe.getItem(this._localStorageConfigName) || '{}');
        this.saveToLocalStorage = true;
        this.localStorageFormName = formName;
        if (lsObject[this.localStorageFormName]?.form) {
            form.patchValue(lsObject[this.localStorageFormName]?.form);
        }
        if (lsObject[this.localStorageFormName]?.itemsPerPage) {
            this.itemsPerPage = lsObject[this.localStorageFormName]?.itemsPerPage;
        }
        if (lsObject[this.localStorageFormName]?.sort) {
            this.sort = lsObject[this.localStorageFormName]?.sort;
        }
    }

    public saveFormConfigToLocalStorage(addedData: any, addedDataKey: string): void {
        if (this.saveToLocalStorage) {
            let lsObject = JSON.parse(localStorageSafe.getItem(this._localStorageConfigName) || '{}');
            lsObject = JSON.parse(localStorageSafe.getItem(this._localStorageConfigName) || '{}');
            const newLsObject = {
                ...lsObject,
                [this.localStorageFormName]: {
                    ...lsObject[this.localStorageFormName],
                    [addedDataKey]: addedData
                }
            };
            localStorageSafe.setItem(this._localStorageConfigName, JSON.stringify(newLsObject));
        }
    }

    protected _buildParams(): ListParameters<T> {
        return {
            itemsPerPage: this.itemsPerPage,
            page: this.page,
            search: this.search,
            sort: this.sort
        };
    }

    protected _init(): void {
        this._subscriptions = this._loading$
            .subscribe(loading => {
                this.loading = loading;

                this._ngZone.run(() => {
                    this._changeDetectorRef.detectChanges();
                });
            });

        this._subscriptions.add(this._rows$
            .subscribe(rows => {
                this.rows = rows;

                this._ngZone.run(() => {
                    this._changeDetectorRef.detectChanges();
                });
            })
        );
    }

    protected _setupList(response: ListResponse<T>): Array<T> | null {
        if (!response) {
            return null;
        }

        this.itemsTotal = response.recordsTotal;

        return response.data;
    }

    private _resetPage(): void {
        this.page = 1;
    }
}
