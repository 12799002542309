import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { FlatpickrHelper } from '../../../../shared/common/FlatpickrHelper';
import { FlatpickrLocaleService } from '../../../../shared/services/flatpickr-locale.service';

@Component({
    selector: 'change-employee-in-company-from-modal',
    templateUrl: './change-employee-in-company-from-modal.component.html',
    styleUrls: ['./change-employee-in-company-from-modal.component.css']
})

export class ChangeEmployeeInCompanyFromModalComponent implements OnInit {
    public submitted = false;
    public inCompanyFrom: string;
    public employeeID: number;
    public form: UntypedFormGroup;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;

    constructor(
        public fpHelper: FlatpickrHelper,
        public activeModal: NgbActiveModal,
        private _employeeService: EmployeeService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
    ) { }

    public ngOnInit(): void {
        this.form = this._fb.group({
            in_company_from: [null, Validators.required]
        });

        this.form.patchValue({
            in_company_from: this.inCompanyFrom
        });
    }

    public onSubmit(): void {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }

        this._employeeService.saveMeta(this.employeeID, 'in_company_from', this.form.value.in_company_from, this.activeModal);
    }
}
