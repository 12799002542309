<div id="chart">
  <apx-chart *ngIf="canOpen; else noDataTemplate"
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [xaxis]="chartOptions.xaxis"
          [yaxis]="chartOptions.yaxis"
          [stroke]="chartOptions.stroke"
          [markers]="chartOptions.markers"
          [fill]="chartOptions.fill"
          [title]="chartOptions.title"
          [plotOptions]="chartOptions.plotOptions"
  ></apx-chart>
</div>

<ng-template #noDataTemplate>
  <div class="text-center text-muted">{{ 'global.empty_widget_error' | translate }}</div>
</ng-template>
