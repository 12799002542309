<div class="media">
    <div *ngIf="states" class="entity-indicators">
        <ng-container *ngIf="states.checklist || states.change_request || states.testing_end || states.position_end || states.termination_period || states.joining; else emptyDotTemplate">
            <span *ngIf="states.checklist"
                  [ngbTooltip]="'employees.states_checklist' | translate"
                  class="entity-indicator-dot employee-state-2"
                  container="body"
                  placement="top-left"></span>
            <span *ngIf="states.change_request"
                  [ngbTooltip]="'employees.states_change_request' | translate"
                  class="entity-indicator-dot employee-state-3"
                  container="body"
                  placement="top-left"></span>
            <span *ngIf="states.testing_end"
                  [ngbTooltip]="('employees.states_test_end' | translate)"
                  class="entity-indicator-dot employee-state-4"
                  container="body"
                  placement="top-left"></span>
            <span *ngIf="states.position_end"
                  [ngbTooltip]="('employees.states_position_end' | translate)"
                  class="entity-indicator-dot employee-state-5"
                  container="body"
                  placement="top-left"></span>
            <span *ngIf="states.termination_period"
                  [ngbTooltip]="('employees.states_termination_period' | translate)"
                  class="entity-indicator-dot employee-state-6"
                  container="body"
                  placement="top-left"></span>
            <span *ngIf="states.joining"
                  [ngbTooltip]="('employees.states_joining' | translate)"
                  class="entity-indicator-dot employee-state-1"
                  container="body"
                  placement="top-left"></span>
        </ng-container>
    </div>
    <div *ngIf="avatar" [ngStyle]="{'width.px': avatarWidth ? avatarWidth : 43, 'height.px': avatarWidth ? avatarWidth : 43 }"
         class="avatar-wrapper">
        <img
            [class.cursor-pointer]="canView"
            [fragment]="employeeType !== 'COMMON' ? employeeType : null"
            [ngStyle]="{'max-width.px': avatarWidth ? avatarWidth : 43 }"
            [routerLink]="canView ? ['/employee', employeeID, 'detail', 'overview'] : null"
            [src]="avatar"
            alt="Avatar image">
    </div>
    <div class="media-body my-auto">
        <div [style.font-size]="(sizeRatio * 15) + 'px'" class="mt-0 mb-0 text-truncate white-space-nowrap font-weight-normal" title="{{ fullname }}">
            <a *ngIf="canView && employeeID" [fragment]="employeeType !== 'COMMON' ? employeeType : null" [routerLink]="['/employee', employeeID, 'detail', 'overview']" class="anchor-unstyled employee-name">
                <span *ngIf="isArchived" class="mr-1">🗃</span>
                {{ fullname }}
                <span class="text-muted" *ngIf="abbreviation"> ({{ abbreviation }})</span>
            </a>
            <div *ngIf="!canView || !employeeID">
                {{ fullname }}
                <span class="text-muted" *ngIf="abbreviation"> ({{ abbreviation }})</span>
            </div>

        </div>
        <span [style.font-size]="(sizeRatio * 13) + 'px'" class="text-truncate text-muted">{{ position }}</span>
    </div>
</div>

<ng-template #emptyDotTemplate>
    <span class="employee-box-dot"></span>
</ng-template>

<ng-template #tooltipTemplate>
    <span class="employee-box-dot"></span>
</ng-template>
