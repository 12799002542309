<div class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help name="attendance.documents.list"></app-help>

      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <app-data-table
                [columnsToFilterIn]="['name']"
                [disableInPlaceFiltering]="false"
                [disableInPlaceSorting]="false"
                [loading]="loading"
                [rows]="rows"
                [search]="search"
                [showSearch]="true"
                [sort]="sort"
                [trackByFn]="trackByFn"
                (searchChanged)="searchChanged($event)"
                (sortChanged)="sortChanged($event)"
              >
                <ng-template #headerRow>
                  <th appDataTableHeaderCell="date">{{ 'employees_datetimes_documents.date' | translate }}</th>

                  <th
                    appDataTableHeaderCell
                    class="text-right"
                    [disableSort]="true"
                  >{{ 'employees_datetimes_documents.table_action' | translate }}</th>
                </ng-template>

                <ng-template
                  #dataRow
                  let-row
                >
                  <td
                    appDataTableDataCell
                    class="text-dark"

                  >{{ row.date_formatted }}</td>

                  <td
                    appDataTableDataCell
                    class="text-right"
                  >
                    <a
                      class="btn btn-white btn-icon btn-sm mr-1"
                      routerLink="/employee/attendance/documents/detail"
                      [queryParams]="{ month: row.month, year: row.year }"
                    >
                      <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                    </a>
                  </td>
                </ng-template>
              </app-data-table>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
            <app-pagination
              [itemsPerPage]="itemsPerPage"
              [page]="page"
              [total]="itemsTotal"
              (pageChanged)="pageChanged($event)"
            ></app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
