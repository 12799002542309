<div *ngIf="benefit$ | async; let benefit">
  <div class="row">
    <div class="col-lg-6 mb-3">
      <div class="card h-100 shadow">
        <app-help name="benefit.detail.info"></app-help>
        <div class="card-header py-3">
          <div class="row">
            <div class="col-6 col-sm-6 col-md-7">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'global.basic_info' | translate }}</span>
              </p>
            </div>

            <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
              <button
                (click)="openEditModal(benefit)"
                [updateButton]="'benefits.action_edit' | translate"
              ></button>
            </div>
          </div>
        </div>

        <div class="card-body px-4">
          <div class="row border-bottom">
            <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
              <span class="text-dark">{{ 'benefits.name' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span>{{ benefit.name }}</span>
            </div>
          </div>

          <div class="row border-bottom">
            <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
              <span class="text-dark">{{ 'benefits.employee_cost' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span [ngClass]="{'hidden-sensitive-value': hiddenInputs}">{{ benefit.employee_cost | localizedCurrency }}</span>
            </div>
          </div>

          <div class="row border-bottom">
            <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
              <span class="text-dark">{{ 'benefits.employer_cost' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span [ngClass]="{'hidden-sensitive-value': hiddenInputs}">{{ benefit.employer_cost | localizedCurrency }}</span>
            </div>
          </div>

          <div class="row border-bottom">
            <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
              <span class="text-dark">{{ 'benefits.note' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span>{{ benefit.note }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <app-benefit-employee-list [benefitID]="benefit.benefit_ID"></app-benefit-employee-list>
</div>
