import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';

import { ItemsPerPageSelectComponent } from './components/items-per-page-select.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    NgSelectModule
  ],
  declarations: [
    ItemsPerPageSelectComponent
  ],
  exports: [
    ItemsPerPageSelectComponent
  ]
})
export class UIItemsPerPageSelectModule { }
