import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { EntityUpdateRequest } from './entity_update_request.model';

@Injectable({
    providedIn: 'root'
})
export class EntityUpdateRequestService {
    public constructor(
        private _http: HttpClient,
        private _apiHelper: ApiHelper,
    ) { }

    public getAllRequests(employeesIDs: any, states: string[]): Observable<Array<EntityUpdateRequest>> {
        const body = {
            employeesIDs: employeesIDs ? (Array.isArray(employeesIDs) ? employeesIDs : [employeesIDs]) : null,
            states: states ? states : null
        };
        return this._http.post<Array<EntityUpdateRequest>>('/api/entityUpdateRequest/list', body);
    }

    public setState(state: string, stateDescription: string, entityUpdateRequest: EntityUpdateRequest): Promise<'done'> {
        let body;

        if (entityUpdateRequest.entity_type === 'App\\Models\\Employee') {
            entityUpdateRequest.data.state = state;
            entityUpdateRequest.data.state_description = stateDescription;
            entityUpdateRequest.data.entity_update_request_ID = entityUpdateRequest.entity_update_request_ID;
            body = entityUpdateRequest.data;
        } else {
            body = {
                state,
                state_description: stateDescription,
                entity_update_request_ID: entityUpdateRequest.entity_update_request_ID
            };
        }

        return new Promise(resolve => {
                this._http.post<ApiResponse>('/api/entityUpdateRequest/setState', body)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            }
        );
    }

    public getStates() {
        return this._http.get('/api/entityUpdateRequest/states')
            .pipe(catchError(() => of([])));
    }
}
