  <div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">{{file?.name}}</p>
      <button (click)="download()" type="button" class="close" aria-label="Close">
        <span class="text-muted" aria-hidden="true"><i class="mdi mdi-tray-arrow-down mdi-24px"></i></span>
        </button>
      <button (click)="activeModal.dismiss()" type="button" class="close ml-0" aria-label="Close">
        <span class="font-24 text-muted" aria-hidden="true"><i class="mdi mdi-close"></i></span>
        </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0" *ngIf="file">
              <ng-container *ngIf="file.mime_type.includes('pdf'); else imageTemplate">
                <pdf-viewer [src]="encodedFile" [original-size]="true" [fit-to-page]="true"  style="display: block; min-width: 500px; min-height: 600px;"></pdf-viewer>
                <div class="d-flex justify-content-between">
                  <div>
                    <p *ngIf="file.is_signed" class="text-danger">
                      {{ 'files.signed_at' | translate }} {{ file.signed_at | date:'dd.MM.yyyy HH:mm' }}
                    </p>
                  </div>
                  <div class="d-flex justify-content-end">
                    <div *ngIf="file.is_signed && file.can_remove_signature">
                      <button (click)="deleteFileSignature()" class="btn btn-link text-muted pr-0"><u>{{ 'files.action_remove_sign' | translate }}</u></button>
                    </div>
                    <div *ngIf="file.to_be_signed && file.can_sign">
                      <div *ngIf="file.signature_type === 'IMAGE'">
                        <button (click)="openSignModal()" class="btn btn-primary">{{ 'files.action_sign' | translate }}</button>
                      </div>
                      <div *ngIf="file.signature_type === 'ESIGN' || !file.signature_type">
                        <button (click)="openEsignModal()" class="btn btn-primary">{{ 'files.action_esign' | translate }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-template #imageTemplate>
                <ng-container *ngIf="file.mime_type.includes('image'); else unknownMimeTemplate">
                  <img
                  class="image-detail"
                  src="{{ file.url || file.fileBase64 }}"
                  alt="{{ file.name }}"
                  />
                </ng-container>
              </ng-template>
              <ng-template #unknownMimeTemplate>
                <span>{{ 'files.unsupported_content' | translate }}: {{ file.mime_type }}</span>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

