import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './core/components/sections/main/main.component';
import { NotFoundComponent } from './core/sections/main/not-found/not-found.component';
import { AuthGuardService } from './core/services/auth-guard.service';
import { OneTimeComponent } from './shared/components/partials/one-time/one-time.component';

const routes: Routes = [
    {path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
    {path: 'kiosk', loadChildren: () => import('./kiosk/kiosk.module').then(m => m.KioskModule)},
    {path: 'questionnaire', loadChildren: () => import('./questionnaire/questionnaire.module').then(m => m.QuestionnaireModule)},
    {path: 'one-time', component: OneTimeComponent},
    {path: '', redirectTo: '/auth/login', pathMatch: 'full'},
    {
        path: '', component: MainComponent, canActivate: [AuthGuardService], canActivateChild: [AuthGuardService], children: [
            {path: '404', component: NotFoundComponent, data: {title: 'global.not_found'}},
            {path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule), data: {title: 'accounts.page_title_index'}},
            {path: 'analysis', loadChildren: () => import('./analysis/analysis.module').then(m => m.AnalysisModule), data: {title: 'analysis.analysis'}},
            {path: 'benefit-bonus', loadChildren: () => import('./benefit-bonus/benefit-bonus.module').then(m => m.BenefitBonusModule), data: {title: 'kpis.page_title_index'}},
            {path: 'company-group', loadChildren: () => import('./company-group/company-group.module').then(m => m.CompanyGroupModule), data: {title: 'company_groups.page_title_index'}},
            {path: 'chat', loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule), data: {title: 'chat.page_title_index'}},
            {path: 'crm', loadChildren: () => import('./crm/crm.module').then(m => m.CrmModule), data: {title: 'customers.page_title_index'}},
            {path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), data: {title: 'dashboard.page_title_index'}},
            {path: 'document', loadChildren: () => import('./document/document.module').then(m => m.DocumentModule), data: {title: 'documents.page_title_index'}},
            {path: 'employee', loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule), data: {title: 'employees.page_title_index'}},
            {path: 'entity-update-requests', loadChildren: () => import('./entity_update_request/entity_update_request.module').then(m => m.EntityUpdateRequestModule), data: {title: 'entity_update_request.page_title_index'}},
            {path: 'evaluation', loadChildren: () => import('./kpi/kpi.module').then(m => m.KpiModule), data: {title: 'kpis.page_title_index'}},
            {path: 'event', loadChildren: () => import('./event/event.module').then(m => m.EventModule), data: {title: 'employees.events_title'}},
            {path: 'file-detail/:entity', loadChildren: () => import('./file-detail/file-detail.module').then(m => m.FileDetailModule), data: {title: 'file_detail.page_title_index'}},
            {path: 'form', loadChildren: () => import('./form/form.module').then(m => m.FormModule), data: {title: 'forms.page_title_index'}},
            {path: 'growth', loadChildren: () => import('./growth/growth.module').then(m => m.GrowthModule), data: {title: 'growths.page_title_index'}},
            {path: 'getting-started', loadChildren: () => import('./help/help.module').then(m => m.HelpModule), data: {title: 'help.page_title_index'}},
            {path: 'import', loadChildren: () => import('./import/import.module').then(m => m.ImportModule), data: {title: 'imports.page_title_index'}},
            {path: 'internal-document', loadChildren: () => import('./internal-document/internal-document.module').then(m => m.InternalDocumentModule), data: {title: 'internal_documents.page_title_index'}},
            {path: 'inventory', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule), data: {title: 'inventory_items.page_title_index'}},
            {path: 'notification', loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule), data: {title: 'notifications.page_title_index'}},
            {path: 'onboarding', loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule), data: {title: 'onboardings.page_title_index'}},
            {path: 'policy', loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule), data: {title: 'company_employee_policies.page_title_index'}},
            {path: 'project', loadChildren: () => import('./project/project.module').then(m => m.ProjectModule), data: {title: 'projects.page_title_index'}},
            {path: 'property', loadChildren: () => import('./property/property.module').then(m => m.PropertyModule), data: {title: 'properties.page_title_index'}},
            {path: 'scheduled-task', loadChildren: () => import('./scheduled-task/scheduled-task.module').then(m => m.ScheduledTaskModule), data: {title: 'scheduled_task.page_title_index'}},
            {path: 'setting', loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule), data: {title: 'settings.page_title_index'}},
            {path: 'todo', loadChildren: () => import('./todo/todo.module').then(m => m.ToDoModule), data: {title: 'todo.page_title_index'}},
            {path: 'term-watch', loadChildren: () => import('./term-watch/term-watch.module').then(m => m.TermWatchModule), data: {title: 'term_watch.page_title_index'}},
            {path: 'training', loadChildren: () => import('./training/training.module').then(m => m.TrainingModule), data: {title: 'trainings.page_title_index'}},
            {path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule), data: {title: 'users.page_title_index'}},
            {path: 'wall', loadChildren: () => import('./wall/wall.module').then(m => m.WallModule), data: {title: 'wall.page_title_index'}},
            {path: 'whats-new', loadChildren: () => import('./whats-new/whats-new.module').then(m => m.WhatsNewModule), data: {title: 'whats_new.page_title_index'}},
            {path: '**', redirectTo: '404'},
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            {
                enableTracing: false,
                anchorScrolling: 'enabled',
                initialNavigation: 'disabled',
                scrollPositionRestoration: 'enabled'
            }
        )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {}
