import { Injectable } from '@angular/core';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AvailableLocaleType } from '../types';

type LocalesType = {
    [K in AvailableLocaleType]?: any;
};

@Injectable({
    providedIn: 'root'
})
export class FlatpickrLocaleService {
    public currentFlatpickrLocale$: Observable<any>;

    private _currentFlatpickrLocale$$ = new ReplaySubject<any>(1);
    private _locales: LocalesType = {};

    public constructor(private _authService: AuthenticationService) {
        this.currentFlatpickrLocale$ = this._currentFlatpickrLocale$$;
    }

    public getLocale(): Observable<any> {
        return this._authService.loggedUser$
            .pipe(
                map(user => user?.locale || null)
            )
            .pipe(
                switchMap(localeId => {
                    const locale = this._locales[localeId];

                    if (locale) {
                        return of(locale);
                    }

                    let importing$: Observable<any>;

                    if (localeId === 'cs') {
                        importing$ = from(import('flatpickr/dist/l10n/cs'))
                            .pipe(map(imported => imported.Czech));
                    } else {
                        importing$ = from(import('flatpickr/dist/l10n/default'))
                            .pipe(map(imported => imported.english));
                    }

                    return importing$
                        .pipe(
                            map(imported => {
                                this._locales[localeId] = imported;

                                return this._locales[localeId];
                            })
                        );
                }),
                tap(locale => this._currentFlatpickrLocale$$.next(locale)),
                shareReplay()
            );
    }
}
