import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { AttendanceService } from '../../../employee/services/attendance.service';

@Component({
    selector: 'app-employee-attendance-plan-chart',
    templateUrl: './employee-attendance-plan-chart.component.html',
    styleUrls: ['./employee-attendance-plan-chart.component.css']
})
export class EmployeeAttendancePlanChartComponent implements OnInit {
    public from: string;
    public to: string;

    public constructor(
        private _attendanceService: AttendanceService,
        private _route: ActivatedRoute,
        private _router: Router
    ) { }

    public ngOnInit(): void {
        this.from = moment().startOf('week').add(1, 'day').format('YYYY-MM-DD');
        this.to = moment().endOf('week').subtract(1, 'day').format('YYYY-MM-DD');
    }
}
