<div class="modal-content">
  <div class="modal-header">
    <p *ngIf="!position" class="modal-title font-16 mb-0 text-dark">{{'employees_positions.page_title_create' | translate}}</p>
    <p *ngIf="position" class="modal-title font-16 mb-0 text-dark">{{'employees_positions.page_title_edit' | translate}}</p>
    <button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true" class="font-24 text-muted"><i class="mdi mdi-close"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
              <app-position-form #childRef
                                 [submitted]="submitted"
                                 [employeePosition]="position"
                                 [employee]="employee"
                                 [employeeID]="employeeID">
              </app-position-form>
              <div class="row mt-2">
                  <div class="col-6 my-auto text-right">
                      <u (click)="deletePosition()" *ngIf="employee?.permissions.deletePosition && position" class="text-muted">{{'employees_positions.action_delete'  | translate}}</u>
                  </div>
                  <div class="col-6 text-right">
                      <button *ngIf="employee?.permissions.editPosition" (click)="onSubmit()" class="btn btn-success" type="submit" submitButton>{{'employees_positions.action_save' | translate}}</button>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
