import { Component } from '@angular/core';
import { AppService } from '../../../services/app.service';

@Component({
    selector: 'app-new-version',
    templateUrl: './new-version.component.html',
    styleUrls: ['./new-version.component.css']
})
export class NewVersionComponent {
    constructor(public appService: AppService) {}
}
