import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SubscriptionService } from 'src/app/setting/services/subscription.service';
import { AvailableFeatureExtended } from '../../../../employee/models/AvailableFeatures';

@Component({
    selector: 'app-subscription-settings-features',
    templateUrl: './subscription-settings-features.component.html',
    styleUrls: ['./subscription-settings-features.component.css']
})
export class SubscriptionSettingsFeaturesComponent implements OnDestroy, OnInit {

    @Input() public isWelcomeModal = false;

    public availableFeaturesExtended: AvailableFeatureExtended[];
    public formInitialValue: any = {};

    private _featuresSubscription: Subscription;

    public constructor(
        private _subscriptionService: SubscriptionService,
    ) { }

    public ngOnDestroy(): void {
        this._featuresSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this._featuresSubscription = this._subscriptionService.getAvailableFeaturesExtended()
            .subscribe(features => {
                this.availableFeaturesExtended = features;
                for (const feature of features) {
                    this.formInitialValue[feature.name] = feature.actual_state;
                }
            });
    }

    public saveFeatures(form: NgForm, feature: string): void {
        const changedFeature = {};
        changedFeature[feature] = form.value[feature];
        this._subscriptionService.saveFeatures(form.value, changedFeature)
            .then((result) => this.availableFeaturesExtended = result);
        this.formInitialValue = form.value;
    }

    public settingsChanged(form: NgForm): boolean {
        for (const feature of this.availableFeaturesExtended) {
            if (form.value[feature.name] !== this.formInitialValue[feature.name]) {
                return true;
            }
        }

        return false;
    }

    public setFeature(form: NgForm, feature: AvailableFeatureExtended): void {
        if (feature.is_available || (feature.is_overwritten && feature.actual_state)) {
            form.value[feature.name] = !form.value[feature.name];
            this.saveFeatures(form, feature.name);
        }
    }
}
