<div *ngIf="checkPermission('employeePayroll') && (availableWidgets$ | async)?.employee?.documents?.payrolls" class="row" id="section-payrolls">
  <div class="col-md-12">
    <app-employee-payrolls
        (refetchPayrolls)="refreshEmployeePayrolls()"
        [employeeID]="employeeID"
        [employeePayrolls$]="employeePayrolls$"
        [employee]="employee$ | async"
    ></app-employee-payrolls>
  </div>
</div>
<div *ngIf="checkPermission('employeeFile') && (availableWidgets$ | async)?.employee?.documents?.files" class="row">
  <div class="col-md-12">
    <app-employee-files
      (refetchFiles)="refreshEmployeeFiles()"
      [employeeFiles$]="employeeFiles$"
      [employeeID]="employeeID"
      [employee]="employee$ | async"
    ></app-employee-files>
  </div>
</div>
