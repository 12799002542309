import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationService } from '../../../../notification/services/notification.service';
import { User } from '../../../../user/models/User';
import { NotificationSetting } from '../../../models/NotificationSetting';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-notification-center',
    templateUrl: './notification-center.component.html',
    styleUrls: ['./notification-center.component.css']
})
export class NotificationCenterComponent implements OnInit {

    public groups: Array<string>;
    public notificationSettings: Array<NotificationSetting>;
    public services: Array<string>;
    public weeklyReportToAdmin: boolean;
    public weeklyReportUserIds: Array<number>;
    public availableUsers$: Observable<Array<User>>;

    constructor(
        private _notificationService: NotificationService,
        private _userService: UserService,
    ) { }

    public ngOnInit(): void {
        this._notificationService.settings$.subscribe((data: any) => {
            this.services = data.services;
            this.notificationSettings = data.company_settings;
            this.groups = [...(new Set(this.notificationSettings.map((notificationSetting: NotificationSetting) => notificationSetting.group)))];
        });
        this._notificationService.getWeeklyReportsSetting().subscribe((data: any) => {
            this.weeklyReportToAdmin = data.admin;
            this.weeklyReportUserIds = data.user_IDs;
        });
        this.availableUsers$ = this._userService.getAvailableUsers();
    }

    public getGroupNotifications(group: string): Array<string> {
        return [...new Set(this.notificationSettings
            .filter((notificationSetting: NotificationSetting) => notificationSetting.group === group)
            .map((notificationSetting: NotificationSetting) => notificationSetting.notification)
        )];
    }

    public checkChecked(group: string, notificationName: string, service: string): boolean {
        const notification = this.notificationSettings.filter((notificationSetting: NotificationSetting) => notificationSetting.key === group + '.' + notificationName + '.' + service).pop();
        return notification.value;
    }

    public checkAvailable(group: string, notificationName: string, service: string): boolean {
        const notification = this.notificationSettings.filter((notificationSetting: NotificationSetting) => notificationSetting.key === group + '.' + notificationName + '.' + service).pop();
        return notification.available;
    }

    public checkDisabled(group: string, notificationName: string, service: string): boolean {
        const notification = this.notificationSettings.filter((notificationSetting: NotificationSetting) => notificationSetting.key === group + '.' + notificationName + '.' + service).pop();
        return notification.disabled;
    }

    public updateNotification($event: Event, group: string, notificationName: string, service: string): void {
        const key = group + '.' + notificationName + '.' + service;
        const data = {
            key,
            value: ($event.target as HTMLInputElement).checked
        };
        this._notificationService.updateNotificationsSetting(data).then(() => {
            const updatedConfig = {
                services: this.services,
                company_settings: this.notificationSettings.map((notificationSetting: NotificationSetting) => {
                    if (notificationSetting.key === key) {
                        notificationSetting.value = data.value;
                    }
                    return notificationSetting;
                })
            };
            this._notificationService.updateNotificationConfig(updatedConfig);
        });
    }

    public saveWeeklyReportRecipients(): void {
        const data = {
            admin: this.weeklyReportToAdmin,
            user_IDs: this.weeklyReportUserIds
        };
        this._notificationService.updateWeeklyReportsSetting(data).then(() => {});
    }
}
