import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { EmployeeGrowthService } from 'src/app/growth/employee-growth/employee-growth.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { Employee } from '../../../../employee/models/Employee';
import { EmployeeService } from '../../../../employee/services/employee.service';
import { Sort, SORT_ASC } from '../../../../ui';
import { EmployeeGrowth } from '../../../employee-growth/employee-growth.model';
import { Growth } from '../../../growth/state/growth.model';
import { GrowthService } from '../../../growth/state/growth.service';
import { EmployeeGrowthOverviewModalComponent } from '../../partials/employee-growth-overview-modal/employee-growth-overview-modal.component';

@Component({
    selector: 'app-active-growth',
    templateUrl: './active-growth.component.html',
    styleUrls: ['./active-growth.component.css']
})
export class ActiveGrowths implements OnInit, OnDestroy {
    public canCreate$: Observable<boolean>;
    public activeGrowths: EmployeeGrowth[];
    public data$: Observable<{ employees: Employee[], growths: Growth[] }>;
    public sort: Sort<Growth> = {
        column: 'created_at',
        direction: SORT_ASC
    };

    private _subscription: Subscription;

    public constructor(
        private _employeeGrowthService: EmployeeGrowthService,
        private _modalService: NgbModal,
        private _authService: AuthenticationService,
        private _employeeService: EmployeeService,
        private _growthService: GrowthService,
    ) { }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.fetchGrowths();

        this.data$ = combineLatest([
            this._employeeService.getAllEmployees(false, 'select'),
            this._growthService.getGrowthsList({
                sort: this.sort
            })
                .pipe(map(response => response.data))
        ]).pipe(
            map(([employees, growths]) => ({employees, growths})),
            shareReplay()
        );

        this.canCreate$ = this._authService.hasPermissionTo('growth.create')
            .pipe(map(permission => permission.can));
    }

    public fetchGrowths(): void {
        this._subscription = this._employeeGrowthService.getActiveGrowths()
            .subscribe(growths => this.activeGrowths = growths);
    }

    public openEmployeeGrowthOverviewModal(employeeGrowthID: number, employeeID: number): void {
        const modalRef = this._modalService.open(EmployeeGrowthOverviewModalComponent, {centered: true});
        modalRef.componentInstance.employeeGrowthID = employeeGrowthID;
        modalRef.result
            .then(
                () => { },
                () => this.fetchGrowths());
        this._employeeGrowthService.get(employeeID).subscribe();
    }

    public openAssignModal(content: any): void {
        this._modalService.open(content, {centered: true});
    }

    public onSubmit(f: UntypedFormGroup): void {
        if (!f.valid) {
            return;
        }

        const value = f.value;

        this._growthService.assignEmployeesToGrowth(value.growth, value.employeesIDs)
            .then(() => this.fetchGrowths());
    }
}
