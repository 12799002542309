<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">
            <span>{{ 'employees.files' | translate }}</span>
        </p>

        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
            type="button"
        >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <app-files-table [files$]="files$"></app-files-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
