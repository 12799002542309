import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyEmployeePolicyAdjustement } from 'src/app/policy/models/CompanyEmployeePolicyAdjustement';
import { PolicyService } from 'src/app/policy/services/policy.service';
import { SummernoteHelper } from 'src/app/shared/common/SummernoteHelper';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';

@Component({
    selector: 'app-create-edit-employee-adjustement-modal',
    templateUrl: './create-edit-employee-adjustement-modal.component.html',
    styleUrls: ['./create-edit-employee-adjustement-modal.component.css']
})
export class CreateEditEmployeeAdjustementModalComponent implements OnInit {
    public adjustmentEditForm: UntypedFormGroup;
    public submitted = false;
    public companyEmployeeAdjustementID: number;
    public employeeID: number;
    public companyEmployeeAdjustement: CompanyEmployeePolicyAdjustement;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;

    constructor(
        public activeModal: NgbActiveModal,
        public summernoteHelper: SummernoteHelper,
        private _fb: UntypedFormBuilder,
        private _policyService: PolicyService,
        private _flatpickrLocale: FlatpickrLocaleService,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.adjustmentEditForm.controls;
    }

    public ngOnInit(): void {
        this.adjustmentEditForm = this._fb.group({
            holiday_hours: [0, Validators.required],
            holiday_transfer_hours: [0, Validators.required],
            sick_day_hours: [0, Validators.required],
            home_office_hours: [0, Validators.required],
            effective_from: ['', Validators.required],
            description: ['']
        });

        if (this.companyEmployeeAdjustementID) {
            this.adjustmentEditForm.patchValue({
                holiday_hours: this.companyEmployeeAdjustement.holiday_hours,
                sick_day_hours: this.companyEmployeeAdjustement.sick_day_hours,
                home_office_hours: this.companyEmployeeAdjustement.home_office_hours,
                holiday_transfer_hours: this.companyEmployeeAdjustement.holiday_transfer_hours,
                effective_from: this.companyEmployeeAdjustement.effective_from,
                description: this.companyEmployeeAdjustement.description
            });
        }
    }

    public onSubmit(): void {
        this.submitted = true;
        if (!this.adjustmentEditForm.valid) {
            return;
        }
        const promise = this._policyService.savePolicyAdjustement(this.adjustmentEditForm.value, this.employeeID, this.companyEmployeeAdjustementID);
        promise.then(result => this.activeModal.close('saved'));
    }

    public deleteAdjustement(): void {
        const promise = this._policyService.deleteAdjustement(this.employeeID, this.companyEmployeeAdjustementID);
        promise.then(result => this.activeModal.close('saved')).catch(() => {});
    }
}
