<ng-container *ngIf="(canViewCompanySettings$ | async); else loadingTemplate">
  <ng-container *ngIf="availableWidgets$ | async; let availableWidgets">
    <ng-template [ngForOf]="getEntities(availableWidgets)" let-entity ngFor>
      <div class="row">
        <div class="col-12">
          <div class="card shadow">
            <app-help name="{{'settings.appearance.' + entity}}"></app-help>
            <div class="card-header py-3">
              <div class="row">
                <div class="col-12 text-left my-auto">
                  <p class="modal-title font-16 text-dark pt-2 pb-2">
                    <span>{{ ('settings.appearance.' + entity + '.widgets_title') | translate }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="mb-3">
                  <ng-container *ngFor="let category of availableWidgets[entity] | keyvalue">
                    <div class="mb-2 py-2 heading-row">
                      <div class="row">
                        <div class="col-12">
                          <strong>{{ ('settings.appearance.' + entity + '.category_'+ category.key) | translate }}</strong>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 mt-2">
                      <ng-container *ngFor="let widget of category.value | keyvalue" class="">
                        <div class="col-12 col-lg-6 col-xl-4 mb-2 mt-2 d-flex">
                          <div class="toggler">
                            <div class="d-flex w-100">
                              <div class="py-0 pr-2 w-100">
                                <span *ngIf="category.key !== 'custom_fields'">{{ ('settings.appearance.' + entity + '.'+ widget.key) | translate }}</span>
                                <span *ngIf="category.key === 'custom_fields'">{{ widget.value.label }}</span>
                              </div>
                              <div class="flex-0">
                                <input (change)="changeWidgets(entity, category.key, (category.key === 'custom_fields' ? widget.value.custom_field_definition_ID : widget.key.toString()), $event)"
                                       [checked]="(category.key === 'custom_fields' ? widget.value.enabled : widget.value)"
                                       [disabled]="!(canEditCompanySettings$ | async)"
                                       [readOnly]="!(canEditCompanySettings$ | async)"
                                       data-switch="success"
                                       id="{{ entity + '.' + widget.key }}"
                                       name="{{ entity + '.' + widget.key }}"
                                       type="checkbox"
                                >
                                <label class="m-0" for="{{ entity + '.' + widget.key }}">&nbsp;</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                        <!--<div *ngIf="category.key === 'custom_fields'" class="col-12 mt-2 text-right">
                            <small>{{ ('settings.appearance.custom_fields.filter_description') | translate }}</small>
                        </div>-->
                    </div>
                  </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <div class="row">
        <div class="col-12">
            <div class="card shadow">
                <app-help name="{{'settings.appearance.questionnaire'}}"></app-help>
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-12 text-left my-auto">
                            <p class="modal-title font-16 text-dark pt-2 pb-2">
                                <span>{{ ('settings.appearance.questionnaire.title') | translate }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <form
                        *ngIf="settings$ | async"
                        autocomplete="off"
                        [formGroup]="questionnaireForm"
                        (ngSubmit)="onSubmitQustionnaire()"
                    >
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="control-label">
                                        <span>{{ 'settings.appearance.questionnaire.identify_company_by' | translate }}</span>
                                    </label>

                                    <ng-select
                                        [multiple]="false"
                                        [closeOnSelect]="true"
                                        class="custom"
                                        formControlName="appearance.questionnaire.identify_company_by"
                                    >
                                        <ng-option [value]="'COMPANY'">{{ 'settings.appearance.questionnaire.option_COMPANY' | translate }}</ng-option>
                                        <ng-option [value]="'COMPANY_BRANCH'">{{ 'settings.appearance.questionnaire.option_COMPANY_BRANCH' | translate }}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <label class="control-label mb-0">
                                    <span>{{ 'settings.appearance.questionnaire.introduction_text_settings' | translate }}</span>
                                </label>
                            </div>

                            <div class="col-6 text-right align-self-center">
                                <div>
                                    <input
                                        data-switch="success"
                                        id="appearance.questionnaire.show_introduction_text"
                                        name="appearance.questionnaire.show_introduction_text"
                                        formControlName="appearance.questionnaire.show_introduction_text"
                                        type="checkbox"
                                    >

                                    <label for="appearance.questionnaire.show_introduction_text">&nbsp;</label>
                                </div>
                            </div>
                        </div>

                        <div [(ngbCollapse)]="!questionnaireForm.value['appearance.questionnaire.show_introduction_text']">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <small class="text-muted">{{ 'settings.appearance.questionnaire.settings_introduction_text_explanation' | translate }}</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="control-label">{{'settings.appearance.questionnaire.introduction_text' | translate }}</label>
                                        <input [ngxSummernote]="summernoteHelper.getSummernoteConfig()"
                                               class="form-control"
                                               formControlName="appearance.questionnaire.introduction_text"
                                               name="company.appearance.questionnaire.introduction_text"
                                               type="text">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-6">
                                <label class="control-label mb-0">
                                    <span>{{ 'settings.appearance.questionnaire.ending_text_settings' | translate }}</span>
                                </label>
                            </div>

                            <div class="col-6 text-right align-self-center">
                                <div>
                                    <input
                                        data-switch="success"
                                        id="appearance.questionnaire.show_ending_text"
                                        name="appearance.questionnaire.show_ending_text"
                                        formControlName="appearance.questionnaire.show_ending_text"
                                        type="checkbox"
                                    >

                                    <label for="appearance.questionnaire.show_ending_text">&nbsp;</label>
                                </div>
                            </div>
                        </div>

                        <div [(ngbCollapse)]="!questionnaireForm.value['appearance.questionnaire.show_ending_text']">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <small class="text-muted">{{ 'settings.appearance.questionnaire.settings_ending_text_explanation' | translate }}</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="control-label">{{'settings.appearance.questionnaire.ending_text' | translate }}</label>
                                        <input [ngxSummernote]="summernoteHelper.getSummernoteConfig()"
                                               class="form-control"
                                               formControlName="appearance.questionnaire.ending_text"
                                               name="company.appearance.questionnaire.ending_text"
                                               type="text">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-success">
                                    <span>{{ 'global.action_save' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
  </div>

  <app-widget-templates-list [definitions]="customFieldDefinitions"></app-widget-templates-list>

  <app-custom-field-list (setDefinitions)="setCustomFieldDefinitions($event)"></app-custom-field-list>

  <app-setting-menu></app-setting-menu>
</ng-container>

<ng-template #loadingTemplate>
  <div class="card shadow p-3 mb-3">
    <div class="item">
      <ngx-skeleton-loader [theme]="{
      'margin': '15px 0'
    }" appearance="line" count="8"></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>
