import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { range as rangeFn } from 'src/app/utils/array.functions';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnChanges {
  @Input()
  public base = 1;

  @Input()
  public itemsPerPage = 10;

  @Input()
  public listLoading: boolean;

  @Input()
  public set page(page: number) {
    this._page = page >= this.base ? page : this.base;
  }
  public get page(): number {
    return this._page;
  }

  @Input()
  public total: number;

  @Output()
  public pageChanged = new EventEmitter<number>();

  public ELLIPSIS = 0;

  public FIRST_FIXED_COUNT = 1;

  public LAST_FIXED_COUNT = 1;

  public SIBLING_OF_CURRENT_COUNT = 1;

  public isCurrentPageFirst: boolean;

  public isCurrentPageLast: boolean;

  public pageCount: number;

  public shown: boolean;

  public steps: Array<number> = [];

  private _page: number;

  public ngOnChanges(): void {
    if (typeof this.total === 'number') {
      this._sanitize();

      this.isCurrentPageFirst = this._isFirst();
      this.isCurrentPageLast = this._isLast();
      this.pageCount = this._getPageCount();

      if (this.total > this.itemsPerPage) {
        this.shown = true;
      } else {
        this.shown = false;
      }
    }

    this._createSteps();
  }

  public pageChange(page: number): void {
    if (
      page >= this._getFirstPage() &&
      page <= this._getLastPage()
    ) {
      this.pageChanged.emit(page);

      this._createSteps();
    }
  }

  private _createSteps(): void {
    const currentPage = this.page;

    const range = rangeFn(this.pageCount, 1);
    const left = this.FIRST_FIXED_COUNT;
    const right = range[range.length - this.LAST_FIXED_COUNT];

    const steps = [];

    for (let i = 0; i < range.length; i++) {
      const element = range[i];
      const previousStep = steps[steps.length - 1];

      if (
        element === currentPage - this.SIBLING_OF_CURRENT_COUNT ||
        element === currentPage + this.SIBLING_OF_CURRENT_COUNT ||
        element === currentPage ||
        element <= left ||
        element >= right
      ) {
        steps.push(element);
      } else if (previousStep !== this.ELLIPSIS) {
        steps.push(this.ELLIPSIS);
      }
    }

    this.steps = steps;
  }

  private _getFirstPage(): number {
    return this.base;
  }

  private _getLastPage(): number {
    return this.total === null ? null : this.base + Math.max(0, this._getPageCount() - 1);
  }

  private _getPageCount(): number {
    return (!this.total || !this.itemsPerPage ? null : Math.ceil(this.total / this.itemsPerPage));
  }

  // Returns zero-based page number
  private _getPageIndex(): number {
    const index = Math.max(0, this.page - this.base);

    return (!this.total ? index : Math.min(index, Math.max(0, this._getPageCount() - 1)));
  }

  private _isFirst(): boolean {
    return this._getPageIndex() === 0;
  }

  private _isLast(): boolean {
    return (!this.total ? false : this._getPageIndex() >= this._getPageCount() - 1);
  }

  private _sanitize(): void {
    if (this.page > this._getLastPage()) {
      this.page = this._getLastPage();

      this.pageChange(this.page);
    }
  }
}
