import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { SearchResult } from 'src/app/shared/models/SearchResult';
import { SearchService } from 'src/app/shared/services/search.service';
import { getOS } from '../../../functions';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, AfterViewInit {
    @ViewChild('searchInput')
    public searchInput: NgSelectComponent;

    @Input() public isModal = true;
    @Input() public modalRef: NgbModalRef;

    public results$: Observable<SearchResult[]>;
    public resultsLoading = false;
    public resultsInput$ = new Subject<string>();
    public os: string;

    constructor(
        private _searchService: SearchService,
        private _router: Router,
    ) { }

    @HostListener('document:keydown', ['$event']) onKeyDown(e: KeyboardEvent): void {
        if (!this.isModal) {
            if ((e.metaKey && e.code === 'KeyK' && this.os === 'macos') || (e.ctrlKey && e.code === 'KeyK' && this.os === 'windows')) {
                e.preventDefault();
                if (!this.searchInput.isOpen) {
                    this.searchInput.open();
                    return;
                }
                this.searchInput.close();
                this.searchInput.blur();
            }
        }
    }

    public ngOnInit(): void {
        this.loadResults();
        this.os = getOS();
    }

    public ngAfterViewInit(): void {
        if (this.isModal) {
            this.searchInput.focus();
        }
    }

    private loadResults(): void {
        this.results$ = concat(
            of([]), // default items
            this.resultsInput$.pipe(
                distinctUntilChanged(),
                tap(() => this.resultsLoading = true),
                switchMap(term => this._searchService.search(term).pipe(
                    catchError(() => of([])), // empty list on error
                    tap(() => this.resultsLoading = false)
                ))
            )
        );
    }

    public opened(): void {
        if (!this.isModal) {
            $('body').addClass('search-open')
                .append('<ngb-modal-backdrop style="z-index: 1055;" class="modal-backdrop fade show"></ngb-modal-backdrop>');
        }
    }

    public closed(): void {
        if (!this.isModal) {
            $('body').removeClass('search-open');
            $('ngb-modal-backdrop').remove();
        }
    }

    public redirectToResult(clickedObject: SearchResult): void {
        switch (clickedObject?.object_type) {
            case 'Employee':
                this._router.navigate(['/employee', clickedObject?.id, 'detail', 'overview']);
                break;
            case 'Property':
                this._router.navigate(['/property', 'detail', clickedObject?.id]);
                break;
            case 'Document':
                this._router.navigate(['/file-detail', 'document-template-document', clickedObject?.document_template_key, 'file', clickedObject?.file_hash, 'detail']);
                break;
            case 'Training':
                this._router.navigate(['/training', 'overview', clickedObject?.id]);
                break;
            case 'TermWatch':
                this._router.navigate(['/term-watch', 'overview', clickedObject?.id]);
                break;
            case 'Form':
            case 'Test':
                this._router.navigate(['/form', 'overview', clickedObject?.id], {fragment: clickedObject?.object_type.toUpperCase()});
                break;
        }

        this.searchInput.writeValue(null);
        this.searchInput.blur();

        this.modalRef?.dismiss();
    }
}
