import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CompanyService} from '../../../services/company.service';
import {tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-company-settings-currency',
  templateUrl: './company-settings-currency.component.html',
  styleUrls: ['./company-settings-currency.component.css']
})
export class CompanySettingsCurrencyComponent implements OnInit {

  public companyCurrencySettings$: Observable<any>;
  public companyCurrencySettingEditForm: UntypedFormGroup;
  public get f(): { [formControlName: string]: AbstractControl; } {
    return this.companyCurrencySettingEditForm.controls;
  }
  public submitted = false;
  public thousandsDelimiters = [
    {label: this._ts.instant('settings.delimiter_space'), value: ' '},
    {label: this._ts.instant('settings.delimiter_period'), value: '.'},
    {label: this._ts.instant('settings.delimiter_comma'), value: ','},
  ];
  public decimalDelimiters = [
    {label: this._ts.instant('settings.delimiter_comma'), value: ','},
    {label: this._ts.instant('settings.delimiter_period'), value: '.'},
  ];
  public preview = '';

  constructor(
    private _companyService: CompanyService,
    private _fb: UntypedFormBuilder,
    private _ts: TranslateService,
  ) { }

  ngOnInit(): void {
    this.companyCurrencySettingEditForm = this._fb.group({
      'company.currency.label': ['', Validators.required],
      'company.currency.thousands_delimiter': ['', Validators.required],
      'company.currency.decimal_delimiter': ['', Validators.required],
      'company.currency.round': ['', Validators.required],
      'company.currency.prefix': ['', Validators.required],
    });
    this.loadCurrencySettings();
  }

  loadCurrencySettings() {
    this.companyCurrencySettings$ = this._companyService.getCompanyCurrencySetting()
      .pipe(
        tap(currencySettings => {
          this.companyCurrencySettingEditForm.patchValue({
            'company.currency.label': currencySettings.currency_settings?.label ?? '',
            'company.currency.thousands_delimiter': currencySettings.currency_settings?.thousands_delimiter ?? ' ',
            'company.currency.decimal_delimiter': currencySettings.currency_settings?.decimal_delimiter ?? ',',
            'company.currency.round': currencySettings.currency_settings?.round ?? 2,
            'company.currency.prefix': currencySettings.currency_settings?.prefix ?? false,
          });
          this.updatePreview();
        })
      );
  }

  public onSubmit(): void {
    this.submitted = true;
    if (!this.companyCurrencySettingEditForm.valid) {
      return;
    }
    const value = this.companyCurrencySettingEditForm.value;
    value.settings_type = 'currency';
    this._companyService.saveCompanySetting(this.companyCurrencySettingEditForm.value).then(() => window.location.reload());
  }

  updatePreview() {
    const settings = this.companyCurrencySettingEditForm.value;
    const prefix = settings['company.currency.prefix'] ? settings['company.currency.label'] + ' ' : '';
    const thousandsDelimiter = settings['company.currency.thousands_delimiter'];
    const round = settings['company.currency.round'];
    const decimalDelimiter = round > 0 ? settings['company.currency.decimal_delimiter'] : '';
    const suffix = settings['company.currency.prefix'] ? '' : ' ' + settings['company.currency.label'];
    this.preview = prefix + '12' + thousandsDelimiter + '345' + decimalDelimiter + '67890'.substr(0, round) + suffix;
  }
}
