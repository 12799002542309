import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SummernoteHelper } from 'src/app/shared/common/SummernoteHelper';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { CustomFieldService } from '../../../../setting/services/custom-field.service';
import { FlatpickrHelper } from '../../../common/FlatpickrHelper';
import { CustomField } from '../../../models/CustomField';
import { FlatpickrLocaleService } from '../../../services/flatpickr-locale.service';
import { InputVisibilityService } from '../../../services/InputVisibilityService';

@Component({
    selector: 'app-custom-fields',
    templateUrl: './custom-fields.component.html',
    styleUrls: ['./custom-fields.component.css']
})
export class CustomFieldsComponent implements OnInit {
    @Input() public customFields: CustomField[];
    @Input() public form: UntypedFormGroup;
    @Input() public formConfig: { [key: string]: string } | false = null;
    @Input() public widget: 'EMPLOYEE' | 'PERSONAL_DATA' | 'PROPERTY' | 'EMPLOYEE_POSITION' | 'EMPLOYEE_SALARY';
    @Input() public columns = 1;
    @Input() public submitted = false;
    @Input() public sensitive = false;
    @Input() public fieldset = false;
    @Input() public hr = false;

    public customFieldControlsCount = 0;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public readonly Validators = Validators;

    public get hiddenInputs(): boolean {
        return this._inputVisibilityService.hiddenInputs;
    }

    public f(control: string): AbstractControl<any, any> {
        return this.form.get('custom_fields.' + control) as AbstractControl;
    }

    public constructor(
        public fpHelper: FlatpickrHelper,
        public summernoteHelper: SummernoteHelper,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _inputVisibilityService: InputVisibilityService,
        private _customFieldService: CustomFieldService,
        private _authService: AuthenticationService,
    ) { }

    public ngOnInit(): void {
        if (this.customFields?.length > 0) {
            this._presetCustomFields();
        } else if (this.widget) {
            this._customFieldService.getCustomFields(this.widget).subscribe((customFields: CustomField[]) => {
                this.customFields = customFields;
                this._presetCustomFields();
            });
        }

        if (this.sensitive && this.hiddenInputs) {
            this.form.disable();
        }
    }

    private _presetCustomFields(): void {
        this.form.addControl('custom_fields', this._fb.group({}));
        this.customFields.forEach((customField: CustomField) => {
            const validators = customField.is_required ? [Validators.required] : [];
            let value = customField.value;
            if (!customField.value) {
                if (customField.type === 'SELECT') {
                    const defaultValue = customField.options?.find((option) => option.is_default);
                    value = defaultValue?.value ?? null;
                }
                if (customField.type === 'MULTISELECT') {
                    value = customField.options?.filter((option) => option.is_default).map((option) => option.value);
                }
            }
            (this.form.get('custom_fields') as FormGroup).addControl(customField.custom_field_definition_ID.toString(), this._fb.control(value, validators));

            // ONLY FOR EMPLOYEE PERSONAL DATA WIDGET - questionnaire, or when user does not have permission to edit
            if (this.formConfig?.[`custom_fields`] && !this._authService.checkPermission(['employeePersonalData.edit', 'employeePersonalData.editStructure'])) {
                const controlConfig = this.formConfig[`custom_fields`][customField.custom_field_definition_ID];
                if (controlConfig === 'HIDDEN') {
                    (this.form.get('custom_fields') as FormGroup).removeControl(customField.custom_field_definition_ID.toString());
                }
                if (controlConfig === 'READONLY') {
                    (this.form.get('custom_fields') as FormGroup).get(customField.custom_field_definition_ID.toString()).disable();
                }
                if (controlConfig === 'REQUIRED') {
                    (this.form.get('custom_fields') as FormGroup).get(customField.custom_field_definition_ID.toString()).setValidators([Validators.required]);
                }
                if (controlConfig === 'VISIBLE') {
                    (this.form.get('custom_fields') as FormGroup).get(customField.custom_field_definition_ID.toString()).removeValidators(Validators.required);
                }
            }
        });
        this.customFieldControlsCount = Object.keys((this.form.get('custom_fields') as FormGroup).controls).length;
    }
}
