import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { ImportDataWithMappings } from '../interface/import-data-with-mappings.interface';
import { ImportTemplate } from '../interface/import-template.interface';
import { Import } from './import.model';

@Injectable({
    providedIn: 'root'
})
export class ImportService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _swalHelper: SwalHelper
    ) { }

    public deleteImport(importID: number | string): Observable<'done' | 'error'> {
        return from(
            new Promise<'done' | 'error'>(async (resolve, reject) => {
                const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

                if (deleteConfirmed) {
                    this._http.delete<ApiResponse>(`/api/imports/${importID}`)
                        .subscribe(
                            response => {
                                this._loaderService.stop();

                                this._apiHelper.handleSuccessResponse(response);

                                resolve('done');
                            },
                            error => {
                                this._loaderService.stop();

                                this._apiHelper.handleErrorResponse(error);
                            }
                        );
                } else {
                    reject('error');
                }
            })
        );
    }

    public deleteImportItem(importItemID: number | string): Observable<'done' | 'error'> {
        return from(
            new Promise<'done' | 'error'>(async (resolve, reject) => {
                const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

                if (deleteConfirmed) {
                    this._http.delete<ApiResponse>(`/api/imports-items/${importItemID}`)
                        .subscribe(
                            response => {
                                this._loaderService.stop();

                                this._apiHelper.handleSuccessResponse(response);

                                resolve('done');
                            },
                            error => {
                                this._loaderService.stop();

                                this._apiHelper.handleErrorResponse(error);
                            }
                        );
                } else {
                    reject('error');
                }
            })
        );
    }

    public getAllImportTemplates(): Observable<Array<ImportTemplate>> {
        return this._http.get<Array<any>>('/api/imports-templates');
    }

    public getImportByID(importID: number): Observable<Import> {
        return this._http.get<Import>(`/api/imports/${importID}`);
    }

    public getImportsList(params: ListParameters<Import>): Observable<ListResponse<Import>> {
        return this._http.post<ListResponse<Import>>('/api/imports/list', {...transformParameters(params)});
    }

    public importData(importData: ImportDataWithMappings): Observable<Import> {
        const formData = new FormData();

        formData.append('mappings', JSON.stringify(importData.mappings));
        formData.append('save_template', String(importData.saveAsTemplate));

        if (importData.headerRowsCount) {
            formData.append('header_rows_count', String(importData.headerRowsCount));
        }

        if (importData.name) {
            formData.append('name', importData.name);
        }

        return of(null)
            .pipe(
                switchMap(() => {
                    return this._http.post<ApiResponse & { import: Import; }>('/api/imports', formData)
                        .pipe(
                            map(response => {
                                this._apiHelper.handleSuccessResponse(response);

                                return response.import;
                            }),
                            catchError(error => {
                                this._apiHelper.handleErrorResponse(error);

                                return of(null);
                            })
                        );
                })
            );
    }

    public importPreview(importData: any): Observable<any> {
        const formData = new FormData();

        formData.append('file', importData.file);

        if (importData.entityType) {
            formData.append('entity', importData.entityType);
        }

        if (importData.entityTargetId?.toString()) {
            formData.append('entity_target_ID', importData.entityTargetId?.toString());
        }

        if (importData.importTemplateId?.toString()) {
            formData.append('import_template_ID', importData.importTemplateId?.toString());
        }

        return of(null)
            .pipe(
                switchMap(() => {
                    return this._http.post('/api/imports/preview', formData)
                        .pipe(
                            catchError(error => {
                                this._apiHelper.handleErrorResponse(error);

                                return of(null);
                            })
                        );
                })
            );
    }
}
