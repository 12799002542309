import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm, UntypedFormBuilder } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { CompanyService } from 'src/app/setting/services/company.service';
import { MenuItem } from '../../../../menu/state/menu-item.model';
import { SummernoteHelper } from '../../../../shared/common/SummernoteHelper';
import { Role } from '../../../models/Role';
import { RolesService } from '../../../services/roles.service';

interface MenuSettings {
    roles: Role[];
    menuItems: MenuItem[];
}

@Component({
    selector: 'app-setting-menu',
    templateUrl: './setting-menu.component.html',
    styleUrls: ['./setting-menu.component.css']
})

export class SettingMenuComponent implements OnInit, OnDestroy {
    public menuSettings$: Observable<MenuSettings>;
    public formChanged = false;
    public formDefaultValue: any;

    private _availableWidgetsSubscription: Subscription;

    public constructor(
        public summernoteHelper: SummernoteHelper,
        private _authService: AuthenticationService,
        private _companyService: CompanyService,
        private _fb: UntypedFormBuilder,
        private _roleService: RolesService
    ) { }

    public ngOnInit(): void {
        this.menuSettings$ = combineLatest([
            this._roleService.getAllRoles(),
            this._companyService.getMenuVisibility()
        ]).pipe(
            map(([roles, menuItems]) => ({roles, menuItems}))
        );
    }

    public ngOnDestroy(): void {

    }

    public onSubmit(menuForm: NgForm): void {
        this._companyService.saveMenuSettings(menuForm.value)
            .then(() => {
                this.formChanged = false;
                this.formDefaultValue = null;
            }, () => { });
    }

    public onChange(event: boolean, menuItemName: string, roleID: number, menuForm: NgForm): void {
        // to get the initial value of the form we change the value of the first changed item back
        if (!this.formDefaultValue) {
            this.formDefaultValue = {
                ...menuForm.value,
                [menuItemName]: {
                    ...menuForm.value[menuItemName],
                    [roleID]: !event
                }
            };
        }

        // check if the form has changed
        this._checkFormDifference(menuForm.value);
    }

    private _checkFormDifference(formValue: any): void {
        const keys = Object.keys(formValue);

        for (const key of keys) {
            const subKeys = Object.keys(formValue[key]);
            for (const subKey of subKeys) {
                if (formValue[key][subKey] !== this.formDefaultValue[key][subKey]) {
                    this.formChanged = true;
                    return;
                }
            }
        }

        this.formChanged = false;
    }
}
