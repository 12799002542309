import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { CompanyEmployeePolicy } from 'src/app/policy/models/CompanyEmployeePolicy';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { DateTimeProject } from '../../../models/DatetimeProject';
import { CreateEditDatetimeProjectModalComponent } from '../../../partials/create-edit-datetime-project-modal/create-edit-datetime-project-modal.component';
import { CompanyService } from '../../../services/company.service';
import { DatetimeProjectService } from '../../../services/datetime-project.service';

@Component({
    selector: 'app-datetime-projects-list',
    templateUrl: './datetime-projects-list.component.html',
    styleUrls: ['./datetime-projects-list.component.css']
})
export class DatetimeProjectsListComponent extends FatherOfListComponent<DateTimeProject> implements OnInit {
    public canCreate = false;
    public allowProjects = false;
    public loggedUser = this._authService.loggedUser;
    public sort: Sort<CompanyEmployeePolicy> = {
        column: 'name',
        direction: SORT_ASC
    };

    private _fetchProjects$ = new ReplaySubject<void>(1);

    public constructor(
        private _modalService: NgbModal,
        private _authService: AuthenticationService,
        private _datetimeProjectService: DatetimeProjectService,
        private _companyService: CompanyService,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
    ) {
        super(_ngZone, _changeDetectorRef);
    }

    public ngOnInit(): void {
        this.canCreate = this._authService.checkPermission('employeeDatetimeProject.create');
        this.allowProjects = this.loggedUser.attendance_projects.active;

        this._rows$ = this._fetchProjects$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                switchMap(params => this._datetimeProjectService.getDatetimeProjectsList(params)),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();

        this._fetchListData();
    }

    public openEditModal(project?: DateTimeProject): void {
        const modalRef = this._modalService.open(CreateEditDatetimeProjectModalComponent, {centered: true});
        modalRef.componentInstance.datetimeProject = project;

        modalRef.result
            .then(
                () => this._fetchListData(),
                () => { }
            );
    }

    public trackByFn(index: number, dateTimeProject: DateTimeProject): number {
        return dateTimeProject.employee_datetime_project_ID;
    }

    public toggleProjects($event: any): void {
        const form = {
            'company.attendance.allow_projects': $event.target.checked ? '1' : '0',
            settings_type: 'attendance'
        };
        this._companyService.saveCompanySetting(form as any)
            .then(() => {
                this._authService.refreshProfile = true;
                this._authService.getAuthUser().subscribe();
            });
    }

    protected _fetchListData(): void {
        this._fetchProjects$.next();
    }

}
