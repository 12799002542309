import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-max-length-hint',
  templateUrl: './form-max-length-hint.component.html',
  styleUrls: ['./form-max-length-hint.component.css']
})
export class FormMaxLengthHintComponent {
  @Input()
  public maxLength: number;

  @Input()
  public value: string;
}
