import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { Role } from 'src/app/setting/models/Role';
import { RolesService } from 'src/app/setting/services/roles.service';
import { SubscriptionService } from 'src/app/setting/services/subscription.service';
import { UserService } from 'src/app/setting/services/user.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { EmployeeService } from '../../../../employee/services/employee.service';
import { User } from '../../../../user/models/User';

@Component({
    selector: 'app-create-edit-user',
    templateUrl: './create-edit-user-modal.component.html',
    styleUrls: ['./create-edit-user-modal.component.css']
})
export class CreateEditUserModalComponent implements OnInit {
    @Input() public guard = 'user';

    public userEditForm: UntypedFormGroup;
    public userID: number;
    public user: User;
    public userRoles = Array<Role>();
    public employeeRoles = Array<Role>();
    public guardType: 'employee' | 'user' = 'user';
    public employeeRoles$: Observable<Array<Role>>;
    public employeesUsersCounts$: Observable<Array<number>>;
    public showButton: boolean;
    public message = '';
    public isLoggedInUser: boolean;
    public showActive = true;
    public submitted = false;
    public emailTaken = false;
    public subscription$ = this._subscriptionService.subscription$;

    private _inputTimeout: any;
    private _debounceTime = 500;

    public constructor(
        public activeModal: NgbActiveModal,
        private _employeeService: EmployeeService,
        private _fb: UntypedFormBuilder,
        private _roleService: RolesService,
        private _userService: UserService,
        private _subscriptionService: SubscriptionService,
        private _authService: AuthenticationService,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.userEditForm.controls;
    }

    public ngOnInit(): void {
        this.employeesUsersCounts$ = this._subscriptionService.getEmployeesUsersCounts().pipe(shareReplay());

        this.userEditForm = this._fb.group({
            name: ['', Validators.required],
            surname: ['', Validators.required],
            email: ['', [Validators.required]],
            phone: [''],
            role: [null],
            is_employee_guard_type: [this.guardType === 'employee'],
        });

        this.employeeRoles$ = this._roleService.getAllRoles(this.guard)
            .pipe(
                tap(roles => {
                    this.userRoles = roles.filter(role => role.guard_type === 'user');
                    this.employeeRoles = roles.filter(role => role.guard_type === 'employee');

                    this._getActiveRolesByGuardType();
                })
            );
        this.employeeRoles$.subscribe();

        if (this.userID) {
            this._userService.getUserByID(this.userID)
                .subscribe(user => {
                    this.userEditForm.patchValue({
                        name: user.name,
                        surname: user.surname,
                        email: user.email,
                        phone: user.phone,
                    });

                    this.user = user;

                    this.isLoggedInUser = user.user_ID === this._authService.loggedUser.user_ID;

                    this.userEditForm.setValidators(null);

                    if (user.role_change_disabled) {
                        this.userEditForm.controls.role.disable();
                    }
                });
        } else {
            this._updateValidation();
            this.userEditForm.addControl('is_active', this._fb.control(true, Validators.required));
        }

        this.employeesUsersCounts$.subscribe(counts => this.checkCounts(counts));
    }

    public inputChanged($event): void {
        this.emailTaken = false;
        clearTimeout(this._inputTimeout);
        this._inputTimeout = setTimeout(() => {
            this.onInputChange($event.target.name, $event.target.value);
        }, this._debounceTime);
    }

    public onInputChange(name, value): void {
        if (name === 'email') {
            this._userService.checkPropertyExistence(name, value)
                .then((response) => {
                    if (name === 'email') {
                        if (response.disable_continue && (typeof this.userID === undefined || this.userID !== response.user_ID)) {
                            this.emailTaken = true;
                        } else {
                            this.emailTaken = false;
                        }
                    }
                })
                .catch(() => { });
        }
    }

    public checkCounts(counts): void {
        const value = this.userEditForm.value.is_employee_guard_type;
        if (value === false && counts.users_limit !== 'unlimited' && counts.users >= counts.users_limit && !this.userID) {
            this.showButton = false;
            this.message = 'subscription.max_users_count_reached';
        } else if (value === true && counts.employees_limit !== 'unlimited' && counts.employees >= counts.employees_limit && !this.userID) {
            this.showButton = false;
            this.message = 'subscription.max_employees_count_reached';
        } else {
            this.showButton = true;
            this.message = '';
        }
    }

    public deleteUser(): void {
        this._userService.deleteUser(this.userID)
            .then(() => this.activeModal.close('close'))
            .catch(() => { });
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.userEditForm.valid) {
            return;
        }

        const selectedRoleId = this.userEditForm.controls.role.value;
        if (this.guard === 'user' || this.userRoles.find(role => role.role_ID === selectedRoleId)) {
            this._userService.saveUser(this.userEditForm.value, this.userID)
                .then(() => this.activeModal.close())
                .catch(() => { });
        } else if (this.employeeRoles.find(role => role.role_ID === selectedRoleId)) {
            this._employeeService.saveEmployee(this.userEditForm.value)
                .then(() => this.activeModal.close())
                .catch(() => { });
        }
    }

    public guardTypeChanged(counts): void {
        this.guardType = this.userEditForm.controls.is_employee_guard_type.value === true ? 'employee' : 'user';
        this.checkCounts(counts);
        this._getActiveRolesByGuardType();
    }

    private _getActiveRolesByGuardType(): void {
        if (this.guardType === 'employee') {
            this.userEditForm.patchValue({
                role: this.user?.role_ID || this.employeeRoles.find(role => role.internal_name === 'employee')?.role_ID || null
            });
        } else {
            this.userEditForm.patchValue({
                role: this.user?.role_ID || this.userRoles.find(role => role.internal_name === 'accountant')?.role_ID || null
            });
        }
    }

    private _updateValidation(): void {
        if (!this.userEditForm) {
            return;
        }

        if (this.showActive) {
            this.userEditForm.controls.is_active?.setValidators([Validators.required]);
        } else {
            this.userEditForm.controls.is_active?.setValidators([]);
        }
    }
}
