import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexStroke, ApexYAxis, ChartType, } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { LocalizedCurrencyPipe } from '../../../pipes/localized-currency.pipe';
import { InputVisibilityService } from '../../../services/InputVisibilityService';

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.css'],
})
export class LineChartComponent implements OnInit, OnDestroy {
    @Input() public labels: Array<string>;
    @Input() public series: ApexAxisChartSeries;
    @Input() public type: ChartType;
    @Input() public yAxisConfig: any;
    @Input() public dashArray: number[];
    @Input() public yaxisFormat: 'RAW' | 'CURRENCY';
    @Input() public forecastDataPoints = {count: 0};

    public chart: ApexChart = {
        type: 'line',
        height: 428,
        toolbar: {
            show: false,
        },
        animations: {
            enabled: false,
        },
    };

    public dataLabels: ApexDataLabels = {
        enabled: false,
    };

    public stroke: ApexStroke = {
        curve: 'straight',
        width: 3,
    };

    public yaxis: ApexYAxis = {
        labels: {
            formatter: (value) => {
                return this.formatYAxisValue(value, this.yaxisFormat);
            },
        },
        showForNullSeries: true,
    };

    public get hiddenInputs(): boolean {
        return this._inputVisibilityService.hiddenInputs;
    }

    private _hiddenInputsSubscription: Subscription;

    public constructor(
        private _localizedCurrency: LocalizedCurrencyPipe,
        private _inputVisibilityService: InputVisibilityService,
    ) {}

    public ngOnDestroy(): void {
        this._hiddenInputsSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this._hiddenInputsSubscription = this._inputVisibilityService.hiddenInputs$.subscribe((value) => {
            this.yaxis = {
                labels: {
                    formatter: (val) => {
                        return this.formatYAxisValue(val, this.yaxisFormat);
                    },
                },
                showForNullSeries: true,
            };
        });

        if (this.dashArray) {
            this.stroke.dashArray = this.dashArray;
        }
        if (this.type === 'area') {
            this.chart.type = 'area';
        }
    }

    public formatYAxisValue(value: number, format: string): string {
        if (format === 'CURRENCY') {
            if (this.hiddenInputs) {
                return '******';
            }
            return this._localizedCurrency.transform(value);
        } else if (format === 'PERCENT') {
            return value.toFixed(2) + ' %';
        } else {
            return value?.toString();
        }
    }
}
