<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">
      <span *ngIf="!benefit">{{ 'benefits.page_title_create' | translate }}</span>
      <span *ngIf="benefit">{{ 'benefits.page_title_edit' | translate }}</span>
    </p>

    <button
      aria-label="Close"
      class="close"
      (click)="activeModal.dismiss()"
    >
      <span
        aria-hidden="true"
        class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <form
              autocomplete="off"
              [formGroup]="benefitEditForm"
              (ngSubmit)="onSubmit()"
            >
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'benefits.name' | translate }}</span>
                    </label>

                    <input
                      class="form-control"
                      formControlName="name"
                      name="name"
                      type="text"
                    >

                    <div *ngIf="submitted && f.name.errors">
                      <p
                        *ngIf="f.name.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'benefits.name' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'benefits.employee_cost' | translate }}</span>
                    </label>

                      <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input currencyMask class="form-control" formControlName="employee_cost" name="name" type="text">
                      </div>

                    <div *ngIf="submitted && f.employee_cost.errors">
                      <p
                        *ngIf="f.employee_cost.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'benefits.name' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'benefits.employer_cost' | translate }}</span>
                    </label>

                      <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input currencyMask formControlName="employer_cost" class="form-control" name="name" type="text">
                      </div>

                    <div *ngIf="submitted && f.employer_cost.errors">
                      <p
                        *ngIf="f.employer_cost.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'benefits.employer_cost' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'benefits.note' | translate }}</span>
                    </label>

                    <input
                      class="form-control"
                      formControlName="note"
                      name="note"
                      type="text"
                    >

                    <div *ngIf="submitted && f.note.errors">
                      <p
                        *ngIf="f.note.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'benefits.note' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col d-flex justify-content-end">
                  <button *ngIf="benefit && !hiddenInputs" class="btn btn-link text-muted pr-0 mr-2" type="button" (click)="deleteBenefit()">
                    <u>{{ 'benefits.action_delete' | translate }}</u>
                  </button>
                  <button [disabled]="hiddenInputs" submitButton class="btn btn-success">
                    <span>{{ 'benefits.action_save' | translate }}</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
