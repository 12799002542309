import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeePosition } from 'src/app/employee/models/EmployeePosition';
import { EmployeePositionService } from 'src/app/employee/services/employee-position.service';
import { FlatpickrHelper } from 'src/app/shared/common/FlatpickrHelper';
import { SummernoteHelper } from '../../../../shared/common/SummernoteHelper';
import { PositionFormComponent } from '../../embedded-forms/position/position-form.component';

@Component({
    selector: 'app-create-employee-position',
    templateUrl: './create-employee-position-modal.component.html',
    styleUrls: ['./create-employee-position-modal.component.css']
})
export class CreateEmployeePositionModalComponent implements OnInit {
    @ViewChild('childRef', {static: false}) formComponent: PositionFormComponent;

    public submitted = false;
    public employee: Employee;
    public employeeID: number;
    public position: EmployeePosition;

    constructor(
        public activeModal: NgbActiveModal,
        public fpHelper: FlatpickrHelper,
        public summernoteHelper: SummernoteHelper,
        private _positionService: EmployeePositionService,
    ) { }

    public ngOnInit(): void {

    }

    public onSubmit(): void {
        this.submitted = true;
        const form = this.formComponent.form;
        const formValue = form.value;

        if (form.invalid || !this.employee.permissions.editPosition) {
            return;
        }
        if (formValue.company_branch_ID === 0) {
            formValue.company_branch_ID = null;
        }

        const allDays = [1, 2, 3, 4, 5, 6, 7];
        const workingDays = formValue.working_days as number[];
        const daysToProcess = {};
        if (formValue.hours_per_day && formValue.working_days) {
            allDays.forEach(day => {
                if (workingDays.find(wd => wd === day)) {
                    daysToProcess[day] = parseFloat(formValue.hours_per_day);
                } else {
                    daysToProcess[day] = 0;
                }
            });
        }

        formValue.working_days = daysToProcess;

        this._positionService.savePosition(formValue as EmployeePosition, this.position?.employee_position_ID, this.employeeID)
            .then(() => this.activeModal.close('close'));
    }

    public deletePosition(): void {
        this._positionService.deletePosition(this.employeeID, this.position.employee_position_ID)
            .then(() => this.activeModal.close('close')).catch(() => { });
    }
}
