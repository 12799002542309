import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { IntegrationsComponent } from './components/integrations/integrations.component';


@NgModule({
  imports: [
    CommonModule,

    TranslateModule
  ],
  declarations: [
    IntegrationsComponent
  ],
  exports: [
    IntegrationsComponent
  ],
})
export class IntegrationsModule { }
