import { LayoutModule } from '@angular/cdk/layout';
import { NgModule } from '@angular/core';

import { ButtonDirective } from './directives/button.directive';

@NgModule({
  imports: [
    LayoutModule,
  ],
  declarations: [
    ButtonDirective,
  ],
  exports: [
    ButtonDirective,
  ]
})
export class UIButtonModule { }
