<div class="card shadow">
  <app-help name="employee.detail.files"></app-help>

  <div class="card-header py-3">
    <div class="row">
      <div class="col-6 text-left my-auto">
        <p class="modal-title font-16 pt-2 pb-2 text-dark">
          <span>{{ 'employees.files' | translate }}</span>
        </p>
      </div>
      <div class="col-6 text-right">
        <button *ngIf="employee?.permissions.createFile && (availableFeatures$ | async)?.document_generations"
          class="ml-sm-2"
          [createButton]="'document_templates.action_generate' | translate"
          (click)="openCreateDocumentByTemplateModal()"
        ></button>
      </div>
    </div>
  </div>

  <div class="card-body">

    <ng-container *ngIf="employee?.permissions.createFile">
      <app-upload
        [fileName]="employee.fullname + '.jpeg'"
        [modelName]="'employee_ID'"
        [modelValue]="employeeID"
        (success)="onFileDelete()"
      ></app-upload>
    </ng-container>

    <app-files-table
      *ngIf="employeeFiles$"
      [allowDelete]="allowDelete$ | async"
      [files$]="employeeFiles$"
      [object]="employee"
      [objectType]="'EMPLOYEE'"
      [showFileOnNewPage]="true"
      [entityType]="'employee'"
      [entityID]="employeeID"
      (refetch)="onFileDelete()"
    ></app-files-table>
  </div>
</div>
