import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

interface Permissions {
  canViewCompanySettings: boolean;
  canViewCompanyEmployeePolicy: boolean;
  canViewNumberSeries: boolean;
  canViewOrderAndCustomer: boolean;
  canViewRole: boolean;
  canViewSubscriptionSettings: boolean;
  canViewUser: boolean;
}

interface RouteDefinition {
  name: string;
  routerLink: string;
}

interface Section extends RouteDefinition {
  isCollapsed: boolean;
  subSections$: Observable<Array<{ fragment: string; name: string; }>>;
}

@Component({
  selector: 'app-setting-detail',
  templateUrl: './setting-detail.component.html',
  styleUrls: ['./setting-detail.component.css']
})
export class SettingDetailComponent implements OnInit {
  public pageTitle$: Observable<string>;
  public routes$: Observable<Array<RouteDefinition | null>>;
  public sections$: Observable<Array<Section | null>>;

  private _canViewCompanyEmployeePolicy$: Observable<boolean>;
  private _canViewCompanySettings$: Observable<boolean>;
  private _canViewNumberSeries$: Observable<boolean>;
  private _canViewOrderAndCustomer$: Observable<boolean>;
  private _canViewRole$: Observable<boolean>;
  private _canViewSubscriptionSettings$: Observable<boolean>;
  private _canViewUser$: Observable<boolean>;

  public constructor(
    private _authService: AuthenticationService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _translateService: TranslateService,
  ) {
    this._setup();
  }

  public ngOnInit(): void {
    this.pageTitle$ = merge(
      this._router.events.pipe(filter(e => e instanceof NavigationEnd)),
      this._route.firstChild.data
    )
      .pipe(
        switchMap(() => merge(
          this._route.data.pipe(map(data => data.title)),
          this._route.firstChild.data.pipe(map(data => data.title)),
          this._authService.pageTitle
        )),
        filter(title => title !== null)
      );
  }

  public setOpen(sectionToBeOpen: Section): void {
    this.sections$ = this.sections$
      .pipe(
        map(sections => sections.map(section => {
          if (!section) {
            return section;
          }

          let isCollapsed: boolean;

          if (
            section.routerLink !== sectionToBeOpen.routerLink ||
            section.routerLink === sectionToBeOpen.routerLink &&
            sectionToBeOpen.isCollapsed === false
          ) {
            isCollapsed = true;
          } else {
            isCollapsed = false;
          }

          return {
            ...section,
            isCollapsed
          };
        }))
      );
  }

  private _addSectionsToRoutes(routes: Array<RouteDefinition>): Observable<Array<Section>> {
    return this._getPermissions()
      .pipe(
        switchMap(permissions => of(routes.map(route => {
          switch (route?.routerLink) {
            case 'attendance': {
              return this._transformRouteAttendanceIntoSection(route, permissions);
            }

            case 'crm': {
              return this._transformRouteCRMIntoSection(route, permissions);
            }

            case 'general': {
              return this._transformRouteGeneralIntoSection(route, permissions);
            }

            case 'guide': {
              return this._transformRouteGuideIntoSection(route, permissions);
            }

            case 'integration': {
              return this._transformRouteIntegrationIntoSection(route, permissions);
            }

            case 'document': {
              return this._transformRouteDocumentIntoSection(route, permissions);
            }

            case 'notification': {
              return this._transformRouteIntegrationIntoSection(route, permissions);
            }

            // case 'other': {
            //   return this._transformRouteOtherIntoSection(route, permissions);
            // }

            // case 'subscription-settings': {
            //   return this._transformRouteSubscriptionIntoSection(route, permissions);
            // }

            case 'user-accounts': {
              return this._transformRouteUserAccountsIntoSection(route, permissions);
            }

            default: {
              return null;
            }
          }
        })))
      );
  }

  private _getPermissions(): Observable<Permissions> {
    return combineLatest([
      this._canViewCompanyEmployeePolicy$,
      this._canViewCompanySettings$,
      this._canViewNumberSeries$,
      this._canViewOrderAndCustomer$,
      this._canViewRole$,
      this._canViewSubscriptionSettings$,
      this._canViewUser$
    ])
      .pipe(
        map(([
          canViewCompanyEmployeePolicy,
          canViewCompanySettings,
          canViewNumberSeries,
          canViewOrderAndCustomer,
          canViewRole,
          canViewSubscriptionSettings,
          canViewUser
        ]) => ({
          canViewCompanyEmployeePolicy,
          canViewCompanySettings,
          canViewNumberSeries,
          canViewOrderAndCustomer,
          canViewRole,
          canViewSubscriptionSettings,
          canViewUser
        }))
      );
  }

  private _setup(): void {
    this._canViewCompanyEmployeePolicy$ = this._authService.hasPermissionTo('companyEmployeePolicy.view').pipe(map(this._unwrapPermission));
    this._canViewCompanySettings$ = this._authService.hasPermissionTo('companySetting.view').pipe(map(this._unwrapPermission));
    this._canViewNumberSeries$ = this._authService.hasPermissionTo('numberSerie.view').pipe(map(this._unwrapPermission));
    this._canViewOrderAndCustomer$ = this._authService.hasPermissionTo(['order.view', 'customer.view']).pipe(map(this._unwrapPermission));
    this._canViewRole$ = this._authService.hasPermissionTo('role.view').pipe(map(this._unwrapPermission));
    this._canViewSubscriptionSettings$ = this._authService.hasPermissionTo('subscription.view').pipe(map(this._unwrapPermission));
    this._canViewUser$ = this._authService.hasPermissionTo('user.view').pipe(map(this._unwrapPermission));

    this.routes$ = this._getPermissions()
      .pipe(
        map(permissions => {
          const routes: Array<RouteDefinition | null> = [];

          if (
            permissions.canViewCompanySettings ||
            permissions.canViewCompanyEmployeePolicy
          ) {
            routes.push({
              name: this._translateService.instant('settings.page_title_view_company_setting'),
              routerLink: 'general'
            });
          }

          if (
            permissions.canViewCompanyEmployeePolicy ||
            permissions.canViewCompanySettings
          ) {
            routes.push({
              name: this._translateService.instant('settings.page_title_view_attendance'),
              routerLink: 'attendance'
            });
          }

          if (
            permissions.canViewRole ||
            permissions.canViewUser
          ) {
            routes.push({
              name: this._translateService.instant('settings.page_title_view_user_accounts'),
              routerLink: 'user-accounts'
            });
          }

          if (permissions.canViewCompanySettings) {
            routes.push({
              name: this._translateService.instant('settings.page_title_view_other_documents'),
              routerLink: 'document'
            });
          }

          if (permissions.canViewCompanySettings) {
            routes.push({
              name: this._translateService.instant('settings.page_title_view_notifications'),
              routerLink: 'notification'
            });
          }

          if (permissions.canViewCompanySettings) {
            routes.push({
              name: this._translateService.instant('settings.page_title_view_integration'),
              routerLink: 'integration'
            });
          }

          if (
            permissions.canViewCompanySettings ||
            permissions.canViewNumberSeries
          ) {
            routes.push({
              name: this._translateService.instant('settings.page_title_view_crm'),
              routerLink: 'crm'
            });
          }

          if (permissions.canViewSubscriptionSettings) {
            routes.push({
              name: this._translateService.instant('settings.page_title_view_subscription_setting'),
              routerLink: 'subscription-settings'
            });
          }

          if (permissions.canViewCompanySettings) {
            routes.push({
              name: this._translateService.instant('settings.page_title_view_other'),
              routerLink: 'other'
            });
          }

          // if (permissions.canViewCompanySettings) {
          //   routes.push({
          //     name: this._translateService.instant('settings.page_title_view_guide'),
          //     routerLink: 'guide'
          //   });
          // }

          return routes;
        })
      )
      .pipe(shareReplay());

    this.sections$ = this.routes$
      .pipe(
        switchMap(routes => this._addSectionsToRoutes(routes)),
        shareReplay()
      );
  }

  private _transformRouteAttendanceIntoSection(route: RouteDefinition | null, permissions: Permissions): Section | null {
    if (
      !permissions.canViewCompanyEmployeePolicy &&
      !permissions.canViewCompanySettings
    ) {
      return null;
    }

    return {
      ...route,
      isCollapsed: true,
      subSections$: of([
        {
          fragment: 'policy',
          name: this._translateService.instant('settings.page_title_view_attendance_policy')
        },
        {
          fragment: 'datetimeType',
          name: this._translateService.instant('settings.page_title_view_attendance_types')
        },
        {
          fragment: 'settings',
          name: this._translateService.instant('settings.page_title_view_attendance_settings')
        }
      ])
        .pipe(
          map(sections => {
            if (!permissions.canViewCompanySettings) {
              sections = sections.filter(s => s.fragment === 'policy');
            }

            if (!permissions.canViewCompanyEmployeePolicy) {
              sections = sections.filter(s => s.fragment !== 'policy');
            }

            return sections;
          }),
          shareReplay()
        )
    };
  }

  private _transformDocumentIntoSection(route: RouteDefinition | null, permissions: Permissions): Section | null {
    if (
      !permissions.canViewCompanyEmployeePolicy &&
      !permissions.canViewCompanySettings
    ) {
      return null;
    }

    return {
      ...route,
      isCollapsed: true,
      subSections$: of([
        {
          fragment: 'policy',
          name: this._translateService.instant('settings.page_title_view_attendance_policy')
        },
        {
          fragment: 'datetimeType',
          name: this._translateService.instant('settings.page_title_view_attendance_types')
        },
        {
          fragment: 'settings',
          name: this._translateService.instant('settings.page_title_view_attendance_settings')
        }
      ])
        .pipe(
          map(sections => {
            if (!permissions.canViewCompanySettings) {
              sections = sections.filter(s => s.fragment === 'policy');
            }

            if (!permissions.canViewCompanyEmployeePolicy) {
              sections = sections.filter(s => s.fragment !== 'policy');
            }

            return sections;
          }),
          shareReplay()
        )
    };
  }


  private _transformRouteCRMIntoSection(route: RouteDefinition | null, permissions: Permissions): Section | null {
    if (
      !permissions.canViewNumberSeries &&
      !permissions.canViewOrderAndCustomer
    ) {
      return null;
    }

    return {
      ...route,
      isCollapsed: true,
      subSections$: of([
        {
          fragment: 'facturation',
          name: this._translateService.instant('settings.page_title_view_crm_facturation')
        },
        {
          fragment: 'numberSeries',
          name: this._translateService.instant('settings.page_title_view_crm_number_series')
        }
      ])
        .pipe(
          map(sections => {
            if (!permissions.canViewNumberSeries) {
              sections = sections.filter(s => s.fragment === 'numberSeries');
            }

            if (!permissions.canViewOrderAndCustomer) {
              sections = sections.filter(s => s.fragment !== 'numberSeries');
            }

            return sections;
          }),
          shareReplay()
        )
    };
  }

  private _transformRouteGeneralIntoSection(route: RouteDefinition, permissions: Permissions): Section | null {
    return {
      ...route,
      isCollapsed: true,
      subSections$: of([
        {
          fragment: 'basic',
          name: this._translateService.instant('settings.page_title_view_company_setting_basic')
        },
        {
          fragment: 'address',
          name: this._translateService.instant('settings.page_title_view_company_setting_addresses')
        },
        {
          fragment: 'logo',
          name: this._translateService.instant('settings.page_title_view_company_setting_logo')
        },
        // {
        //   fragment: 'currency',
        //   name: this._translateService.instant('settings.page_title_view_company_setting_currencies')
        // },
        {
          fragment: 'branch',
          name: this._translateService.instant('settings.page_title_view_company_setting_branches')
        },
        // {
        //   fragment: 'salary',
        //   name: this._translateService.instant('settings.page_title_view_company_setting_salaries')
        // },
      ])
        .pipe(
          shareReplay()
        )
    };
  }

  private _transformRouteGuideIntoSection(route: RouteDefinition, permissions: Permissions): Section | null {
    return {
      ...route,
      isCollapsed: true,
      subSections$: of([])
    };
  }

  private _transformRouteIntegrationIntoSection(route: RouteDefinition, permissions: Permissions): Section | null {
    if (!permissions.canViewCompanySettings) {
      return null;
    }

    return {
      ...route,
      isCollapsed: true,
      subSections$: of([])
        .pipe(
          map(sections => {
            if (!permissions.canViewCompanySettings) {
              sections = [];
            }

            return sections;
          }),
          shareReplay()
        )
    };
  }

  private _transformRouteDocumentIntoSection(route: RouteDefinition, permissions: Permissions): Section | null {
    if (!permissions.canViewCompanySettings) {
      return null;
    }

    return {
      ...route,
      isCollapsed: true,
      subSections$: of([
        {
          fragment: 'templates',
          name: this._translateService.instant('settings.page_title_view_company_setting_templates')
        },
        {
          fragment: 'logo',
          name: this._translateService.instant('settings.page_title_view_company_setting_logo')
        },
        {
          fragment: 'signature',
          name: this._translateService.instant('settings.page_title_view_company_setting_signature')
        },
        {
          fragment: 'responsible_person',
          name: this._translateService.instant('settings.page_title_view_company_setting_responsible_person')
        },
      ])
        .pipe(
          shareReplay()
        )
    };
  }

  private _transformRouteOtherIntoSection(route: RouteDefinition, permissions: Permissions): Section | null {
    if (!permissions.canViewCompanySettings) {
      return null;
    }

    return {
      ...route,
      isCollapsed: true,
      subSections$: of([
        {
          fragment: 'documents',
          name: this._translateService.instant('settings.page_title_view_other_documents')
          // },
          // {
          //   fragment: 'import',
          //   name: this._translateService.instant('settings.page_title_view_other_import_export')
        }
      ])
        .pipe(
          map(sections => {
            if (!permissions.canViewCompanySettings) {
              sections = [];
            }

            return sections;
          }),
          shareReplay()
        )
    };
  }

  private _transformRouteSubscriptionIntoSection(route: RouteDefinition, permissions: Permissions): Section | null {
    if (!permissions.canViewSubscriptionSettings) {
      return null;
    }

    return {
      ...route,
      isCollapsed: true,
      subSections$: of([])
    };
  }

  private _transformRouteUserAccountsIntoSection(route: RouteDefinition, permissions: Permissions): Section | null {
    if (
      !permissions.canViewRole &&
      !permissions.canViewUser
    ) {
      return null;
    }

    return {
      ...route,
      isCollapsed: true,
      subSections$: of([
        {
          fragment: 'users',
          name: this._translateService.instant('settings.page_title_view_user_account')
        },
        {
          fragment: 'roles',
          name: this._translateService.instant('settings.page_title_view_user_accounts_roles_and_permissions')
        }
      ])
        .pipe(
          map(sections => {
            if (!permissions.canViewRole) {
              sections = sections.filter(s => s.fragment === 'users');
            }

            if (!permissions.canViewUser) {
              sections = sections.filter(s => s.fragment !== 'users');
            }

            return sections;
          }),
          shareReplay()
        )
    };
  }

  private _unwrapPermission(permission: { can: boolean; }): boolean {
    return permission.can;
  }
}
