import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { Observable } from 'rxjs';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { SwalHelper } from '../../shared/common/SwalHelper';
import { Tag } from './tag.model';

@Injectable({
    providedIn: 'root'
})
export class TagService {
    public constructor(
        private _http: HttpClient,
        private _apiHelper: ApiHelper,
        private _swalHelper: SwalHelper,
    ) { }

    public getAllTags(entityType: string, category?: string | null): Observable<Array<Tag>> {
        let params = {type: entityType};
        params = {...params, ...{category}};
        return this._http.post<Array<Tag>>('/api/tags', params);
    }

    public getEntityTags(entityID: number, entityType: string, category?: string | null): Observable<Array<Tag>> {
        let params = {type: entityType, id: entityID};
        params = {...params, ...{category}};
        return this._http.post<Array<Tag>>('/api/tags', params);
    }

    public attachCreateTag(entityID: number, entityType: string, tag?: Tag, tagLabel?: string, category?: string | null): Promise<'done'> {
        let body;

        if (tag) {
            body = {
                tag_ID: tag.tag_ID,
                type: entityType,
                id: entityID,
                label: tag.label
            };
        } else {
            body = {
                type: entityType,
                id: entityID,
                label: tagLabel
            };
        }

        if (!entityID) {
            delete body.id;
        }

        body = {...body, ...{category}};

        return new Promise(resolve => {
                this._http.post<ApiResponse>('/api/tags/attach-create', body)
                    .subscribe(
                        response => {
                            resolve('done');
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            }
        );
    }

    public editTagOption(tag: Tag, label: string): Promise<'done'> {
        const body = {
            category: tag.category,
            label
        };

        return new Promise(resolve => {
                this._http.post<ApiResponse>(`/api/tags/${tag.tag_ID}`, body)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            }
        );
    }

    public detachTag(entityID: number, entityType: string, tag: Tag): Promise<'done'> {
        const body = {
            tag_ID: tag.tag_ID,
            type: entityType,
            id: entityID,
        };

        return new Promise(resolve => {
                this._http.post<ApiResponse>('/api/tags/detach', body)
                    .subscribe(
                        response => {
                            resolve('done');
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            }
        );
    }

    public async deleteTagOption(tagID: ID): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete<ApiResponse>(`/api/tags/${tagID}`, {})
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => this._apiHelper.handleErrorResponse(error)
                    );
            }
        });
    }
}
