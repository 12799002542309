import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuItemService } from '../../menu/state/menu-item.service';
import { Version } from './version.model';

@Injectable({
    providedIn: 'root'
})
export class WhatsNewService {
    public constructor(
        private _http: HttpClient,
        private _menuService: MenuItemService,
    ) { }

    public getVersions(group = 'version', limit = 5): Observable<Version[]> {
        return this._http.get<Version[]>(`/api/version?limit=${limit}&group=${group}`);
    }

    public setVersionAsViewed(): void {
        this._http.get<any>('/api/version/viewed')
            .subscribe(() => this._menuService.refetchMenuStats());
    }
}
