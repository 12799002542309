import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Employee } from 'src/app/employee/models/Employee';
import { File } from 'src/app/employee/models/File';

import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';

@Component({
    selector: 'app-employee-payrolls',
    templateUrl: './employee-payrolls.component.html',
    styleUrls: ['./employee-payrolls.component.css']
})
export class EmployeePayrollsComponent implements OnInit {
    @ViewChild('content', {static: false})
    public dialogTemplateRef: TemplateRef<any>;

    @Input()
    public employee: Employee;
    @Input()
    public employeeID: number;
    @Input()
    public employeePayrolls$: Observable<Array<File>>;
    @Output()
    public refetchPayrolls = new EventEmitter<string>();

    public allowDelete$: Observable<boolean>;
    public today = new Date();
    public selectRange = {months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'], years: []};
    public clearFileQueue$: Observable<void>;
    public dateForm: UntypedFormGroup;
    public dateFormSubmitted = false;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public payrollFileAdditionalData: string;
    public processFileQueue$: Observable<void>;
    private _clearFileQueue$ = new Subject<void>();
    private _dateModalRef: NgbModalRef;
    private _processFileQueue$ = new Subject<void>();

    public constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _authService: AuthenticationService,
        private _formBuilder: UntypedFormBuilder,
        private _modalService: NgbModal
    ) { }

    public ngOnInit(): void {
        this.today.setMonth(this.today.getMonth() - 1);
        const todayMinus5Years = this.today.getFullYear() - 5;
        for (let i = 0; i < 11; i++) {
            this.selectRange.years[i] = (todayMinus5Years + i).toString();
        }

        this.clearFileQueue$ = this._clearFileQueue$;
        this.processFileQueue$ = this._processFileQueue$;

        this.allowDelete$ = this._authService.hasPermissionTo('employee.edit')
            .pipe(
                map(permission => permission.can),
                map(canEdit => canEdit || this.employee?.permissions.deletePayroll)
            );

        this.dateForm = this._formBuilder.group({
            month: [null, Validators.required],
            year: [null, Validators.required]
        });

        this.setDefaultFormData();
    }

    public setDefaultFormData(): void {
        this.dateForm.patchValue({
            month: ('0' + (this.today.getMonth() + 1).toString()).slice(-2),
            year: this.today.getFullYear().toString()
        });
    }

    public onDateSubmit(): void {
        this.dateFormSubmitted = true;

        if (this.dateForm.valid) {
            this.dateFormSubmitted = false;

            this._dateModalRef.close({...this.dateForm.value});
        }
    }

    public refetch(): void {
        this.refetchPayrolls.emit('refetch');
    }

    public showDateModal(content: any): void {
        this._dateModalRef = this._modalService.open(content, {centered: true});

        this._dateModalRef.result
            .then(
                () => {
                    this.payrollFileAdditionalData = this._createObjectWithDateKeyValuePairAsString();
                    this._changeDetectorRef.detectChanges();
                    this._processFileQueue$.next();
                    this.setDefaultFormData();
                },
                () => {
                    this.setDefaultFormData();
                    this._clearFileQueue$.next();
                }
            );
    }

    private _createObjectWithDateKeyValuePairAsString(): string {
        const values = {
            month: this.dateForm.value.month,
            year: this.dateForm.value.year
        };

        return JSON.stringify(values);
    }
}
