import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cacheable } from '@datorama/akita';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { Bonus } from './bonus.model';
import { BonusStore } from './bonus.store';

@Injectable({
    providedIn: 'root'
})
export class BonusService {
    constructor(
        private bonusStore: BonusStore,
        private http: HttpClient,
        private apiHelper: ApiHelper,
        private swalHelper: SwalHelper,
    ) { }

    public get(employeeID: number): Observable<void> {
        const request = this.http.get<Bonus[]>(`/api/employees/${employeeID}/bonuses`).pipe(
            map(response => this.bonusStore.set(response))
        );

        return cacheable(this.bonusStore, request);
    }

    public saveBonus(form: Bonus, employeeID: number, modalRef: NgbActiveModal, bonusID?: number | string) {
        const apiUrl = bonusID ? `/api/employees/${employeeID}/bonuses/${bonusID}` : `/api/employees/${employeeID}/bonuses`;
        this.http.post(apiUrl, form).subscribe((response: any) => {
            this.apiHelper.handleSuccessResponse(response);
            if (bonusID) {
                this.bonusStore.update(bonusID, response.bonus);
            } else {
                this.bonusStore.add(response.bonus);
            }
            modalRef.close();
        }, error => {
            this.apiHelper.handleErrorResponse(error);
        });
    }

    public async deleteBonus(employeeID: number, bonusID: number | string, modalRef: NgbActiveModal) {
        const deleteConfirmed = await this.swalHelper.showConfirmDeleteDialog();
        if (deleteConfirmed) {
            this.http.delete(`/api/employees/${employeeID}/bonuses/${bonusID}`, {}).subscribe((response: any) => {
                this.bonusStore.remove(bonusID);
                this.apiHelper.handleSuccessResponse(response);
                modalRef.dismiss();
            }, error => {
                this.apiHelper.handleErrorResponse(error);
            });
        }
    }
}
