import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { AvailableFeatures } from '../../../../employee/models/AvailableFeatures';
import { DocumentTemplate } from '../../../models/DocumentTemplate';
import { DocumentTemplateService } from '../../../services/document-template.service';
import { SubscriptionService } from '../../../services/subscription.service';

@Component({
    selector: 'app-create-edit-document-template',
    templateUrl: './create-edit-document-template.component.html',
    styleUrls: ['./create-edit-document-template.component.css']
})
export class CreateEditDocumentTemplateComponent implements OnInit {
    public submitted = false;
    public collapseExternalSignature = true;
    public canShowExternalSignature = false;
    public documentTemplateForm: UntypedFormGroup;
    public documentTemplate: DocumentTemplate;
    public canDeleteTemplate$: Observable<boolean>;
    public availableFeatures$: Observable<AvailableFeatures>;
    public entityTypes = [
        {value: 'EMPLOYEE', label: this.translateService.instant('settings.company.documents_templates.entity_type_EMPLOYEE')},
        {value: 'PROPERTY', label: this.translateService.instant('settings.company.documents_templates.entity_type_PROPERTY')}
    ];
    public signatureServices = [
        'SIGNI'
    ];

    private _inputTimeout: any;
    private _featureSubscription: Subscription;

    constructor(
        private _fb: UntypedFormBuilder,
        private _documentTemplateService: DocumentTemplateService,
        private _authService: AuthenticationService,
        private _router: Router,
        private _subscriptionService: SubscriptionService,
        public activeModal: NgbActiveModal,
        public translateService: TranslateService
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.documentTemplateForm.controls;
    }

    public ngOnInit(): void {
        this.documentTemplateForm = this._fb.group({
            name: ['', Validators.required],
            code: ['', Validators.required],
            description: [''],
            active: [true],
            entity_type: ['EMPLOYEE'],
            employee_notification: this._fb.group({
                send: [true],
                frequency: [7]
            }),
            hr_notification: this._fb.group({
                send: [true],
                frequency: [7]
            }),
        });

        if (this.documentTemplate) {
            if (this.documentTemplate.require_responsible_signature) {
                this.documentTemplateForm.addControl('responsible_autosign', this._fb.control(this.documentTemplate.responsible_autosign));
                if (!this.documentTemplate.responsible_autosign_available) {
                    this.documentTemplateForm.controls.responsible_autosign.disable();
                }

                if (this.documentTemplate.external_signature) {
                    this.onCollapseExternalSignature();
                }
            }

            this._subscriptionService.availableFeatures$
                .subscribe(availableFeatures => {
                    if (availableFeatures.signatures && this.documentTemplate.require_owner_signature && this.documentTemplate.require_responsible_signature) {
                        this.canShowExternalSignature = true;
                    }
                });

            this.documentTemplateForm.patchValue(this.documentTemplate);
        }

        this.canDeleteTemplate$ = this._authService.hasPermissionTo('documentTemplate.delete')
            .pipe(map(perm => perm.can));
    }

    public onSubmit() {
        this.submitted = true;
        if (!this.documentTemplateForm.valid) {
            return;
        }

        const form = this.documentTemplateForm.value;

        let promise = null;
        if (!this.documentTemplate) {
            promise = this._documentTemplateService.createDocumentTemplate(form);
        } else {
            if (this.canShowExternalSignature && this.collapseExternalSignature) {
                form.external_signature = null;
            }
            if (!this.collapseExternalSignature) {
                form.responsible_autosign = false;
            }
            promise = this._documentTemplateService.editDocumentTemplate(form, this.documentTemplate.document_template_ID);
        }

        promise.then(() => this.activeModal.close('close'));
    }

    public onInputChanged($event): void {
        if (!this.documentTemplate) {
            clearTimeout(this._inputTimeout);
            this._inputTimeout = setTimeout(() => {
                if ($event.target.value !== '') {
                    this.generateCode($event.target.value);
                }
            }, 200);
        }
    }

    public generateCode(value: string): void {
        const nameArray = value.split(' ');
        let finalCode = '';

        if (nameArray.length > 1) {
            nameArray.forEach(element => {
                if (this.isNumeric(element)) {
                    finalCode += element;
                } else {
                    finalCode += element.charAt(0);
                }
            });
        }

        if (nameArray.length === 1) {
            finalCode = nameArray[0].substring(0, 3);
        }

        this.documentTemplateForm.patchValue({
            code: finalCode.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-z0-9]/gi, '')
        });
    }

    public isNumeric(str: string): boolean {
        return /^[0-9]*$/.test(str);
    }

    public onCollapseExternalSignature(): void {
        this.collapseExternalSignature = !this.collapseExternalSignature;

        if (!this.collapseExternalSignature) {
            this.documentTemplateForm.addControl('external_signature', this._fb.group({
                service: ['SIGNI'],
            }));
        } else {
            this.documentTemplateForm.removeControl('external_signature');
        }
    }

    public deleteDocumentTemplate(): void {
        this._documentTemplateService.deleteDocumentTemplate(this.documentTemplate.document_template_ID)
            .then(
                () => this._router.navigateByUrl('/document/templates'),
                () => { }
            );
    }
}
