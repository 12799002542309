import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { EmployeeReviewState, EmployeeReviewStore } from './employee-review.store';

@Injectable({ providedIn: 'root' })
export class EmployeeReviewQuery extends QueryEntity<EmployeeReviewState> {
    constructor(protected store: EmployeeReviewStore) {
        super(store);
    }
}
