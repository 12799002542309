import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { UIButtonModule, UIDataTableModule, UIItemsPerPageSelectModule, UIPaginationModule } from '../ui';
import { ScheduledTaskListComponent } from './container/scheduled-task-list.component';
import { ScheduledTaskCreateEditModule } from './scheduled-task-create-edit.module';

@NgModule({
    imports: [
        SharedModule,
        RouterModule,
        UIButtonModule,
        UIDataTableModule,
        UIItemsPerPageSelectModule,
        UIPaginationModule,
        ScheduledTaskCreateEditModule,
    ],
    declarations: [
        ScheduledTaskListComponent,
    ],
    exports: [
        ScheduledTaskListComponent,
    ],
})
export class ScheduledTaskListModule {}
