import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Employee } from 'src/app/employee/models/Employee';
import { DocumentService } from 'src/app/employee/services/document.service';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { DocumentTemplate } from 'src/app/setting/models/DocumentTemplate';
import { Permission } from 'src/app/setting/models/Permission';
import { CompanyService } from 'src/app/setting/services/company.service';
import { DocumentTemplateService } from 'src/app/setting/services/document-template.service';
import { RolesService } from 'src/app/setting/services/roles.service';
import { FormHelper } from 'src/app/shared/common/FormHelper';

@Component({
    selector: 'app-multi-document-template-generator',
    templateUrl: './multi-document-template-generator.component.html',
    styleUrls: ['./multi-document-template-generator.component.css']
})
export class MultiDocumentTemplateGeneratorComponent implements OnInit {
    public permissions$: Observable<Array<Permission>>;
    public allowedDocumentSignature: boolean;
    public selectedTemplate: DocumentTemplate;
    public employees$: Observable<Array<Employee>>;
    public checkedEmployees$: Observable<Array<any>>;
    public employeesErrors: any;
    public errorsCount: number;
    public warningsCount: number;
    public successCount: number;
    public employeesSuccess: any;
    public employeesWarnings: any;
    public employeeData: any;
    public submitDisabled = true;
    public fields: any;
    public message = 'message';
    public form: UntypedFormGroup;
    public isCollapsed: any = {errorsSection: false, successSection: false, warningsSection: false, errors: [], warnings: []};

    public constructor(
        public activeModal: NgbActiveModal,
        private _roleService: RolesService,
        private _employeeService: EmployeeService,
        private _documentService: DocumentService,
        private _documentTemplateService: DocumentTemplateService,
        private _companyService: CompanyService,
        private _route: ActivatedRoute,
        private _fb: UntypedFormBuilder,
        private _formHelper: FormHelper,
        private _router: Router
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.form.controls;
    }

    public employeesIDsCount(form) {
        return form.value?.employeesIDs?.length;
    }

    public ngOnInit(): void {

        this.employees$ = this._employeeService.getAllEmployees(false, 'minimal')
            .pipe(
                map(employees => employees.map(employee => ({
                    ...employee,
                    company_group_name: employee.company_group?.name
                })))
            );
    }

    public checkEmployees(form): void {
        const values = form.value;
        this._documentTemplateService.saveDocumentFromTemplate(values.employeesIDs, this.selectedTemplate.key)
            .subscribe(sub => {
                this.employeesErrors = sub.errors ? sub.errors : null;
                this.employeesSuccess = sub.success ? sub.success : null;
                this.employeesWarnings = sub.warnings ? sub.warnings : null;
                this.employeeData = sub.employeeData ? sub.employeeData : null;

                this.fields = sub.fields;

                if (this.selectedTemplate.default_values) {
                    this.fields.forEach((group, groupIndex) => {
                        group.data.forEach((field, fieldIndex) => {
                            if (field.name in this.selectedTemplate.default_values) {
                                this.fields[groupIndex].data[fieldIndex].value = this.selectedTemplate.default_values[field.name];
                            }
                        });
                    });

                }
                if (this.employeesSuccess) {
                    const keys = Object.keys(this.employeesSuccess);
                    this.successCount = keys.length;
                }

                if (this.employeesWarnings) {
                    const keys = Object.keys(this.employeesWarnings);
                    this.warningsCount = keys.length;

                    keys.forEach(key => {
                        this.isCollapsed.warnings[key] = false;
                    });
                }

                if (this.employeesErrors) {
                    this.submitDisabled = true;
                    this.message = 'errors';

                    const keys = Object.keys(this.employeesErrors);
                    this.errorsCount = keys.length;

                    keys.forEach(key => {
                        this.isCollapsed.errors[key] = false;
                    });
                } else {
                    this.submitDisabled = false;
                    this.message = '';
                }
            });
        this._getCompanySettings();
    }

    public onSubmit(form): void {
        if (form.valid) {
            const data = {
                employeeData: this.employeeData,
                ...form.value,
                documentTemplateKey: this.selectedTemplate.key
            };
            this._documentTemplateService.generateToManyEmployees(data)
                .then(() => this.activeModal.close('close'));
        }

    }

    private _getCompanySettings() {
        this._companyService.getCompanySetting().subscribe((response: any) => {
            this.allowedDocumentSignature = Boolean(Number(response.company_settings?.documents?.allow_signature ?? false));
        }, () => {});
    }

}
