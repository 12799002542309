import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { UIButtonModule, UIDataTableModule, UIItemsPerPageSelectModule, UIPaginationModule, UIValueOrDashModule } from '../ui';
import { BenefitBonusRoutingModule } from './benefit-bonus-routing.module';
import { CreateEditBenefitModalComponent } from './components/partials/create-edit-benefit-modal/create-edit-benefit-modal.component';
import { BenefitEmployeeListComponent } from './components/sections/benefit-employee-list/benefit-employee-list.component';
import { BenefitListComponent } from './components/sections/benefit-list/benefit-list.component';
import { BenefitOverviewComponent } from './components/sections/benefit-overview/benefit-overview.component';

@NgModule({
    declarations: [
        BenefitListComponent,
        BenefitOverviewComponent,
        CreateEditBenefitModalComponent,
        BenefitEmployeeListComponent
    ],
    imports: [
        BenefitBonusRoutingModule,
        SharedModule,
        UIButtonModule,
        UIDataTableModule,
        UIItemsPerPageSelectModule,
        UIPaginationModule,
        UIValueOrDashModule
    ]
})
export class BenefitBonusModule {}
