<div class="entrance__main__wrap">
    <div class="d-flex align-items-center flex-1 entrance">
        <div class="container">
            <div class="entrance__wrapper">
                <div class="entrance__navbar">
                    <div>
                        <a routerLink="/auth/login">
                            <img alt="" src="assets/images/logo_speybl-logo-black.svg">
                        </a>
                    </div>
                </div>
                <div class="entrance__form__wrap">
                    <div class="col-12 text-center">
                        <div class="row">
                            <div class="col-12 text-center">
                                <h3 class="text-dark mb-3">
                                    {{ title | translate }}
                                </h3>
                                <p *ngIf="description" class="mb-3">
                                    {{ description | translate }}
                                </p>
                                <div class="entrance__navbar__timeout">
                                    <div class="progress__label">{{ 'public_pages.auto_navigate_at' | translate }} {{ remainingSeconds }} s</div>
                                    <div class="progress my-1">
                                        <div [style.width.%]="progressBarWidth" aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" class="progress-bar" role="progressbar"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
