<form [formGroup]="form">
    <div *ngIf="f.fullname" class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">{{'employees_contact_persons.fullname' | translate}}</label>
                <input formControlName="fullname" type="text" class="form-control" name="fullname">
                <div *ngIf="submitted && f.fullname.errors">
                    <p *ngIf="f.fullname.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'employees_contact_persons.fullname' | translate}}
                        {{'angular_validation.required' | translate }}
                    </p>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="f.email" class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">{{'employees_contact_persons.email' | translate}}</label>
                <input formControlName="email" type="text" class="form-control" name="email">
                <div *ngIf="submitted && f.email.errors">
                    <p *ngIf="f.email.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'employees_contact_persons.email' | translate}}
                        {{'angular_validation.required' | translate }}
                    </p>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="f.phone" class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">{{'employees_contact_persons.phone' | translate}}</label>
                <input formControlName="phone" type="text" class="form-control" name="phone">
            </div>
        </div>
    </div>
    <div *ngIf="f.description" class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">{{'employees_contact_persons.description' | translate}}</label>
                <input formControlName="description" type="text" class="form-control" name="description">
                <div *ngIf="submitted && f.description.errors">
                    <p *ngIf="f.description.errors.required" class="text-danger validation-text">
                         {{ 'angular_validation.field' | translate }} {{'employees_contact_persons.city' | translate}}
                        {{'angular_validation.required' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</form>
