import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { EmployeeOnboardingTask } from 'src/app/onboarding/employee-onboarding/state/employee-onboarding-task.model';
import { EmployeeOnboarding } from 'src/app/onboarding/employee-onboarding/state/employee-onboarding.model';
import { EmployeeOnboardingQuery } from 'src/app/onboarding/employee-onboarding/state/employee-onboarding.query';
import { EmployeeOnboardingService } from 'src/app/onboarding/employee-onboarding/state/employee-onboarding.service';
import { UserService } from '../../../../setting/services/user.service';
import { FlatpickrLocaleService } from '../../../../shared/services/flatpickr-locale.service';
import { User } from '../../../../user/models/User';
import { OnboardingService } from '../../../onboarding/state/onboarding.service';

@Component({
    selector: 'app-employee-onboarding-overview-modal',
    templateUrl: './employee-onboarding-overview-modal.component.html',
    styleUrls: ['./employee-onboarding-overview-modal.component.css']
})
export class EmployeeOnboardingOverviewModalComponent implements OnInit {
    public availableUsers: Array<User>;
    public canEdit$: Observable<boolean>;
    public employeeID: number;
    public employeeOnboarding$: Observable<EmployeeOnboarding>;
    public employeeOnboardingID: number;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public submitted = false;
    public submittedTask = false;
    public isCollapsed: boolean[] = [];

    public constructor(
        public activeModal: NgbActiveModal,
        private _authService: AuthenticationService,
        private _employeeOnboardingQuery: EmployeeOnboardingQuery,
        private _employeeOnboardingService: EmployeeOnboardingService,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _onboardingService: OnboardingService,
        private _userService: UserService,
    ) { }

    public ngOnInit(): void {

        this.canEdit$ = combineLatest([
            this._authService.hasPermissionTo('employee.edit')
                .pipe(map(permission => permission.can)),
            this._authService.hasPermissionTo('employee.editStructure')
                .pipe(map(permission => permission.can))
        ])
            .pipe(
                map(([canEdit, canEditStructure]) => canEdit || canEditStructure),
                shareReplay()
            );

        if (this.employeeOnboardingID) {
            this.employeeOnboarding$ = this._employeeOnboardingQuery.selectEntity(this.employeeOnboardingID);
        }

        this._userService.getAvailableUsers().subscribe(availableUsers => {
            this.availableUsers = availableUsers;
        });
    }

    public completeTask(task: EmployeeOnboardingTask, onboarding: EmployeeOnboarding): void {
        this._employeeOnboardingService.completeTask(onboarding.employee_onboarding_ID, task);
    }

    public deleteTask(onboarding: EmployeeOnboarding, onboardingTask: EmployeeOnboardingTask): void {
        void this._employeeOnboardingService.deleteTask(onboarding, onboardingTask);
    }

    public onSubmit(f: UntypedFormGroup, onboarding: EmployeeOnboarding, onboardingTask: EmployeeOnboardingTask): void {
        this.submitted = true;

        if (f.invalid) {
            return;
        }

        this.submitted = false;

        this._employeeOnboardingService.saveEmployeeOnboardingTask(f, onboarding, onboardingTask);
    }

    public unCompleteTask(task: EmployeeOnboardingTask, onboarding: EmployeeOnboarding): void {
        this._employeeOnboardingService.unCompleteTask(onboarding.employee_onboarding_ID, task);
    }

    public unAssignEmployeeFromOnboarding(onboarding: EmployeeOnboarding): void {
        void this._onboardingService.unassignOnboardingFromEmployee(onboarding, onboarding.employee_ID);
    }

    public saveTask(employeeOnboarding: EmployeeOnboarding, task: EmployeeOnboardingTask, form: UntypedFormGroup): void {
        this.submittedTask = true;

        if (form.invalid) {
            return;
        }

        this.submittedTask = false;

        const data = {
            name: form.value.name,
            assignee_ID: form.value.assignee_ID,
            deadline: form.value.deadline,
        };
        this._employeeOnboardingService.saveEmployeeOnboardingTaskNew(form, employeeOnboarding, task, data);
    }
}
