import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { UIDataTableModule, UIItemsPerPageSelectModule, UIPaginationModule, UIButtonModule } from '../ui';

import { NoteRoutingModule } from './note-routing.module';

import { NoteComponent } from './components/sections/note/note.component';
import { NoteModalComponent } from './components/partials/note-modal/note-modal.component';

@NgModule({
  declarations: [
    NoteComponent,
    NoteModalComponent,
  ],
  imports: [
    NoteRoutingModule,

    SharedModule,

    UIDataTableModule,
    UIItemsPerPageSelectModule,
    UIPaginationModule,
    UIButtonModule,
  ],
  exports: [
    NoteComponent
  ]
})
export class NoteModule { }
