import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NumberSeries } from 'src/app/setting/models/NumberSeries';
import { NumberSeriesService } from 'src/app/setting/services/number-series.service';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { CreateEditNumberSeriesModalComponent } from '../create-edit-number-series-modal/create-edit-number-series-modal.component';

@Component({
    selector: 'app-number-series-list',
    templateUrl: './number-series-list.component.html',
    styleUrls: ['./number-series-list.component.css']
})
export class NumberSeriesListComponent extends FatherOfListComponent<NumberSeries> implements OnInit {
    public autocompleteItems = [
        {
            name: 'Rok 2020',
            value: '%Y%'
        },
        {
            name: 'Rok 20',
            value: '%y%'
        },
        {
            name: 'Číslo 0001',
            value: '%NMB_4%'
        },
        {
            name: 'Číslo 001',
            value: '%NMB_3%'
        },
        {
            name: 'Číslo 01',
            value: '%NMB_2%'
        }
    ];

    public canCreate$: Observable<boolean>;

    public sort: Sort<NumberSeries> = {
        column: 'name',
        direction: SORT_ASC
    };

    private _fetchNumberSeries$ = new ReplaySubject<void>(1);

    public constructor(
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        private _authService: AuthenticationService,
        private _modalService: NgbModal,
        private _numberSeriesService: NumberSeriesService
    ) {
        super(_ngZone, _changeDetectorRef);
    }

    public ngOnInit(): void {
        this.canCreate$ = this._authService.hasPermissionTo('account.create')
            .pipe(map(permission => permission.can));

        this._rows$ = this._fetchNumberSeries$
            .pipe(
                tap(() => this._loading$.next(true)),
                switchMap(() => this._numberSeriesService.getAllNumberSeries()),
                map(numberSeries => {
                    const mappedSeries = numberSeries.map(series => {
                        const codeFormatArray = series.code_format.split(',');
                        const formattedArray = [];

                        codeFormatArray.forEach(token => {
                            const codeFormatObject = this.autocompleteItems.find(object => object.value === token);

                            if (codeFormatObject) {
                                formattedArray.push(codeFormatObject.name);
                            } else {
                                formattedArray.push(token);
                            }
                        });

                        series.code_format = formattedArray.join(' - ');

                        return series;
                    });

                    return mappedSeries;
                }),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();

        this._fetchListData();
    }

    public openEditModal(numberSerieID?: number): void {
        const modalRef = this._modalService.open(CreateEditNumberSeriesModalComponent, {centered: true});

        if (numberSerieID) {
            modalRef.componentInstance.numberSerieID = numberSerieID;
        }

        modalRef.result
            .then(
                () => this._fetchNumberSeries$.next(),
                () => { }
            );
    }

    public searchChanged(search: string): void {
        this.search = search;
    }

    public sortChanged(sort: Sort<NumberSeries>): void {
        this.sort = sort;
    }

    public trackByFn(index: number, series: NumberSeries): number {
        return series.number_series_ID;
    }

    protected _fetchListData(): void {
        this._fetchNumberSeries$.next();
    }
}
