import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Routes } from '../shared/models/Route';
import { DocumentDetailComponent } from './components/sections/document-detail/document-detail.component';
import { DocumentListComponent } from './components/sections/document-list/document-list.component';
import { DocumentTemplatesListComponent } from './components/sections/document-templates-list/document-templates-list.component';

const routes: Routes = [
    {path: '', redirectTo: 'list', pathMatch: 'full'},
    {path: 'list', component: DocumentListComponent, pathMatch: 'full', data: {title: 'documents.page_title_index', help: 'documents.list'}},
    {path: 'detail/:documentHash', component: DocumentDetailComponent, data: {title: 'documents.page_title_view'}},
    {path: 'templates', component: DocumentTemplatesListComponent, data: {title: 'documents.document_templates'}}
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [
        RouterModule,
    ]
})
export class DocumentRoutingModule {}
