import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApexChart, ApexFill, ApexNonAxisChartSeries, ApexPlotOptions, ApexTooltip } from 'ng-apexcharts';
import { LocalizedCurrencyPipe } from '../../../pipes/localized-currency.pipe';

@Component({
    selector: 'app-radial-chart',
    templateUrl: './radial-chart.component.html',
    styleUrls: ['./radial-chart.component.css']
})
export class RadialChartComponent implements OnInit {
    @Input() public absoluteValue: number;
    @Input() public labelFormat: 'NULL' | 'RAW' | 'CURRENCY' | 'ABSOLUTE_VALUE_MAX_VALUE' | 'PERCENT' | 'HOURS';
    @Input() public labels: Array<string>;
    @Input() public maxValue: number;
    @Input() public ownContainer = false;
    @Input() public series: ApexNonAxisChartSeries;
    @Input() public title: string;
    @Input() public shadow = false;
    @Input() public height = 250;
    @Input() public colorType = 'gradient';
    @Input() public colors: string[];
    @Input() public attendanceStatsWidget = false;
    @Input() public tooltips: { used_formatted: string, used_formatted_days: string, left_formatted: string, left_formatted_days: string };

    public chart: ApexChart = {
        type: 'radialBar',
        height: 250,
        toolbar: {
            show: false
        },
        animations: {
            enabled: false
        },
    };

    public tooltip: ApexTooltip = {
        enabled: false
    };

    public fill: ApexFill = {
        type: 'gradient',
        gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#ABE5A1'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
        }
    };

    public plotOptions: ApexPlotOptions = {
        radialBar: {
            track: {
                show: true,
                startAngle: undefined,
                endAngle: undefined,
                background: '#f2f2f2',
                strokeWidth: '97%',
                opacity: 1,
                margin: 5,
                dropShadow: {
                    enabled: false,
                    top: 0,
                    left: 0,
                    blur: 3,
                    opacity: 0.5
                }
            },
            dataLabels: {
                show: true,
                name: {
                    show: true,
                    offsetY: -15,
                    fontSize: '18px'
                },
                value: {
                    formatter: value => this.formatLabelValue(value, this.labelFormat, this.absoluteValue, this.maxValue),
                    show: true,
                    offsetY: -5,
                    fontSize: '18px'
                }
            }
        }
    };

    public constructor(
        public translateService: TranslateService,
        private _localizedCurrency: LocalizedCurrencyPipe
    ) {}

    public ngOnInit(): void {
        this.chart.height = this.height;
        if (this.attendanceStatsWidget) {
            this._attendanceStatsConfig();
        }

        if (this.colorType === 'gradient') {
            this.fill.gradient.gradientToColors = this.colors;
        } else {
            this.fill.type = this.colorType;
            this.fill.colors = this.colors || ['#6979ed'];
        }
    }

    public formatLabelValue(value: number, format: string, absoluteValue: number, maxValue: number): string {
        if (format === 'NULL') {
            return '';
        } else if (format === 'CURRENCY') {
            return this._localizedCurrency.transform(value);
        } else if (format === 'HOURS') {
            return value + 'h';
        } else if (format === 'PERCENT') {
            return (isNaN(value) ? '0' : value.toFixed(2)) + ' %';
        } else if (format === 'ABSOLUTE_VALUE_MAX_VALUE') {
            return absoluteValue.toFixed(1) + ' / ' + maxValue.toFixed(1);
        } else {
            return (value / 10).toFixed(2);
        }

    }

    private _attendanceStatsConfig(): void {
        this.plotOptions.radialBar.dataLabels = {
            show: false
        };

        this.chart.width = '100%';
        this.chart.sparkline = {
            enabled: true
        };

        this.plotOptions.radialBar.hollow = {
            size: '40%'
        };

        this.tooltip = {
            enabled: true,
            marker: {
                show: false
            },
            fixed: {
                enabled: true,
                position: 'topRight',
                offsetY: 100
            },
            shared: false,
            fillSeriesColor: false,
            intersect: true,
            y: {
                title: {
                    formatter(seriesName: string): string {
                        return '';
                    }
                },
                formatter: (val: number, opts?: { dataPointIndex: number, series: number[], seriesIndex: number }): string => {
                    return (`
                        ${this.translateService.instant('employees.left')} ${this.tooltips.left_formatted} ~ ${this.tooltips.left_formatted_days},
                        ${this.translateService.instant('employees.used')} ${this.tooltips.used_formatted} ~ ${this.tooltips.used_formatted_days}
                    `);
                }
            }
        };
    }
}
