import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MenuItemState, MenuItemStore } from './menu-item.store';

@Injectable({ providedIn: 'root' })
export class MenuItemQuery extends QueryEntity<MenuItemState> {
    constructor(protected store: MenuItemStore) {
        super(store);
    }
}
