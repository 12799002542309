
<ng-container [formGroup]="formGroup">
    <ng-container formGroupName="target">
    <div class="row">
        <div class="col-6">
            <label class="control-label">{{ 'internal_documents.target.whole_company' | translate }}</label>
        </div>
        <div class="col-6 text-right">
            <input
                data-switch="success"
                id="whole_company"
                name="whole_company"
                type="checkbox"
                formControlName="whole_company"
            >
            <label class="mb-0" for="whole_company">&nbsp;</label>
        </div>
    </div>
        <div [(ngbCollapse)]="formGroup.value.target.whole_company">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="control-label">
                            <span>{{ 'internal_documents.target.employees' | translate }}</span>
                        </label>
                        <ng-select class="custom"
                                   formControlName="employee_IDs"
                                   name="employee_IDs"
                                   [items]="employees$ | async"
                                   bindValue="employee_ID"
                                   bindLabel="fullname"
                                   [closeOnSelect]="false"
                                   [multiple]="true">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="control-label">
                            <span>{{ 'internal_documents.target.company_groups' | translate }}</span>
                        </label>
                        <ng-select class="custom"
                                   formControlName="company_group_IDs"
                                   name="company_group_IDs"
                                   [items]="companyGroups$ | async"
                                   bindValue="company_group_ID"
                                   bindLabel="name"
                                   [closeOnSelect]="false"
                                   [multiple]="true">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="control-label">
                            <span>{{ 'internal_documents.target.company_branches' | translate }}</span>
                        </label>
                        <ng-select class="custom"
                                   formControlName="company_branch_IDs"
                                   name="company_branch_IDs"
                                   [items]="companyBranches$ | async"
                                   bindValue="company_branch_ID"
                                   bindLabel="name"
                                   [closeOnSelect]="false"
                                   [multiple]="true">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="control-label">
                            <span>{{ 'internal_documents.target.job_branches' | translate }}</span>
                        </label>
                        <ng-select class="custom"
                                   formControlName="job_branch_IDs"
                                   name="job_branch_IDs"
                                   [items]="companyBranches$ | async"
                                   bindValue="company_branch_ID"
                                   bindLabel="name"
                                   [closeOnSelect]="false"
                                   [multiple]="true">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="control-label">
                            <span>{{ 'internal_documents.target.company_positions' | translate }}</span>
                        </label>
                        <ng-select class="custom"
                                   formControlName="company_position_IDs"
                                   name="company_position_IDs"
                                   [items]="companyPositions$ | async"
                                   bindValue="company_position_ID"
                                   bindLabel="name"
                                   [closeOnSelect]="false"
                                   [multiple]="true">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="control-label">
                            <span>{{ 'internal_documents.target.roles' | translate }}</span>
                        </label>
                        <ng-select class="custom"
                                   formControlName="role_IDs"
                                   name="role_IDs"
                                   [items]="roles$ | async"
                                   bindValue="role_ID"
                                   bindLabel="name"
                                   [closeOnSelect]="false"
                                   [multiple]="true">
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
