import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AttendanceService } from 'src/app/employee/services/attendance.service';
import { Employee } from '../../../models/Employee';

export interface EmployeeAttendanceOverview {
    dates: {
        absence: {
            from: string;
            to: string;
            total: string;
        };
        date: string;
        is_holiday: boolean;
        is_weekend: boolean;
        saldo: string;
        work: {
            from: string;
            to: string;
            total: string;
        };
    }[];
    employee: Employee;
    summary: {
        until_now: {
            expected: string;
            real: string;
            saldo: string;
        };
        whole_month: {
            expected: string;
            real: string;
            saldo: string;
        }
    };
    month: number;
    year: number;
    is_uneven: boolean;
}

@Component({
    selector: 'app-employee-attendance-document-employee-detail',
    templateUrl: './employee-attendance-document-employee-detail.component.html',
    styleUrls: ['./employee-attendance-document-employee-detail.component.css']
})
export class EmployeeAttendanceDocumentEmployeeDetailComponent implements OnInit {
    public employeeID: number;
    public month: number;
    public year: number;
    public data$: Observable<EmployeeAttendanceOverview>;

    public constructor(
        private _route: ActivatedRoute,
        private _attendanceService: AttendanceService,
        protected _changeDetectorRef: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this.employeeID = this._route.snapshot.params.id;
        this.month = parseInt(this._route.snapshot.queryParamMap.get('month'), 10);
        this.year = parseInt(this._route.snapshot.queryParamMap.get('year'), 10);

        this.data$ = this._attendanceService.getAttendanceOverviewByEmployee(this.employeeID, this.month, this.year);
    }
}
