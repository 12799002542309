import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { cacheable } from '@datorama/akita';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { Employee } from 'src/app/employee/models/Employee';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { SwalHelper } from '../../shared/common/SwalHelper';
import { CompanyGroupForStructureTree } from '../interface/company-group-for-structure-tree.interface';
import { CompanyGroup } from './company-group.model';
import { CompanyGroupQuery } from './company-group.query';
import { CompanyGroupStore } from './company-group.store';

interface ResponseWithCompanyGroup extends ApiResponse {
    company_group: CompanyGroup;
}

@Injectable({
    providedIn: 'root'
})
export class CompanyGroupService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _companyGroupStore: CompanyGroupStore,
        private _companyGroupQuery: CompanyGroupQuery,
        private _http: HttpClient,
        private _modalService: NgbModal,
        private _router: Router,
        private _swalHelper: SwalHelper,
        private _loaderService: NgxUiLoaderService,
        private _translateService: TranslateService
    ) { }

    public assignEmployeesToCompanyGroup(companyGroupID: number | string, employees: Array<number>, allEmployees?: Array<Employee>): void {
        this._http.post<ApiResponse>(`/api/company-groups/${companyGroupID}/employees/assign`, {employees})
            .subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);

                    this._modalService.dismissAll();

                    if (allEmployees) {
                        const assignedEmployees = allEmployees.filter(e => employees.includes(e.employee_ID));

                        this._companyGroupStore.update(companyGroupID, cg => {
                            const structure = {...cg.structure};
                            structure.siblings = [...structure.siblings, ...assignedEmployees];
                            return {
                                ...cg,
                                structure,
                                employees_count: cg.employees_count + assignedEmployees.length
                            };
                        });
                    }
                },
                error => {
                    this._apiHelper.handleErrorResponse(error);
                }
            );
    }

    public assignManagerToCompanyGroup(companyGroupID: number | string, employeeID: number, allEmployees: Array<Employee>): void {
        this._http.post<ApiResponse>(`/api/company-groups/${companyGroupID}/employees/assign-manager`, {employeeID})
            .subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);

                    this._modalService.dismissAll();

                    const assignedEmployee = allEmployees.find(e => e.employee_ID === employeeID);

                    this._companyGroupStore.update(companyGroupID, cg => {
                        const structure = {...cg.structure};

                        structure.parents = [assignedEmployee];

                        return {
                            ...cg,
                            structure,
                            employees_count: cg.employees_count + 1
                        };
                    });
                },
                error => {
                    this._apiHelper.handleErrorResponse(error);
                }
            );
    }

    public deleteCompanyGroup(companyGroupID: number | string): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const text = this._translateService.instant('swal.delete_text_team');
            const checkboxText = this._translateService.instant('swal.delete_text_team_checkbox');

            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialogWithCheckbox(text, null, checkboxText);

            if (deleteConfirmed?.isConfirmed) {
                const body = {
                    move_to_parent: deleteConfirmed?.checkedBox
                };

                this._loaderService.start();

                this._http.delete<ApiResponse>(`/api/company-groups/${companyGroupID}`, {body})
                    .subscribe(
                        response => {
                            this._companyGroupStore.remove(companyGroupID);

                            this._loaderService.stop();

                            this._apiHelper.handleSuccessResponse(response);

                            this._router.navigate(['/company-group', 'list']);
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public get(): Observable<void> {
        const request = this._http.get<Array<CompanyGroup>>('/api/company-groups').pipe(
            map(response => this._companyGroupStore.set(response))
        );

        return cacheable(this._companyGroupStore, request);
    }

    public getAll(): Observable<Array<CompanyGroup>> {
        return this._http.get<Array<CompanyGroup>>('/api/company-groups');
    }

    public getByEmployee(employeeID: number): Observable<any> {
        const params = new HttpParams()
            .set('employee_ID', employeeID);
        return this._http.get<any>('/api/company-groups/get-by-employee', {params});
    }

    public getCompanyGroupByID(companyGroupID: number | string): Observable<void> {
        return this._http.get(`/api/company-groups/${companyGroupID}`)
            .pipe(
                map((companyGroup: CompanyGroup) => {
                    if (this._companyGroupQuery.hasEntity(companyGroupID)) {
                        this._companyGroupStore.update(companyGroupID, companyGroup);
                    } else {
                        this._companyGroupStore.add(companyGroup);
                    }
                })
            );
    }

    public getCompanyGroupStructure(companyGroupID: number): Observable<void> {
        return this._http.get(`/api/company-groups/${companyGroupID}/structure`)
            .pipe(
                map((structure: { parents: Array<Employee>, children: Array<Employee>, siblings: Array<Employee> }) => {
                    if (this._companyGroupQuery.hasEntity(companyGroupID)) {
                        this._companyGroupStore.update(companyGroupID, {structure});
                    }
                })
            );
    }

    public getCompanyGroupStructureTree(showArchived: boolean): Observable<Array<CompanyGroupForStructureTree>> {
        const params = new HttpParams()
            .set('archived', String(showArchived));

        return this._http.get<Array<CompanyGroupForStructureTree>>('/api/company-groups/structure-tree', {params});
    }

    public getCompanyGroupsList(params: ListParameters<CompanyGroup>): Observable<ListResponse<CompanyGroup>> {
        return this._http.post<ListResponse<CompanyGroup>>('/api/company-groups/list', {...transformParameters(params)});
    }

    public saveCompanyGroup(form: CompanyGroup, modalRef: NgbActiveModal, companyGroupID?: number | string): void {
        const apiUrl = companyGroupID ? `/api/company-groups/${companyGroupID}` : '/api/company-groups';

        this._http.post<ResponseWithCompanyGroup>(apiUrl, form)
            .subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);

                    modalRef.close();

                    if (!companyGroupID) {
                        this._companyGroupStore.add(response.company_group);
                    } else {
                        this._companyGroupStore.update(companyGroupID, response.company_group);
                    }
                },
                error => {
                    this._apiHelper.handleErrorResponse(error);
                }
            );
    }

    public unAssignEmployeeFromCompanyGroup(companyGroupID: number | string, employeeID: number): void {
        this._http.post<ApiResponse>(`/api/company-groups/${companyGroupID}/employees/unassign`, {employeeID})
            .subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);

                    this._companyGroupStore.update(companyGroupID, cg => {
                        const structure = {...cg.structure};

                        if (structure.parents.some(e => e.employee_ID === employeeID)) {
                            structure.parents = structure.parents.filter(e => e.employee_ID !== employeeID);
                            return {
                                ...cg,
                                structure,
                                employees_count: cg.employees_count - 1
                            };
                        }

                        if (structure.siblings.some(e => e.employee_ID === employeeID)) {
                            structure.siblings = structure.siblings.filter(e => e.employee_ID !== employeeID);
                            return {
                                ...cg,
                                structure,
                                employees_count: cg.employees_count - 1
                            };
                        }

                        if (structure.children.some(e => e.employee_ID === employeeID)) {
                            structure.children = structure.children.filter(e => e.employee_ID !== employeeID);
                            return {
                                ...cg,
                                structure,
                                employees_count: cg.employees_count - 1
                            };
                        }
                    });
                },
                error => {
                    this._apiHelper.handleErrorResponse(error);
                }
            );
    }

    public unAssignManagerFromCompanyGroup(companyGroupID: number | string): void {
        this._http.post<ApiResponse>(`/api/company-groups/${companyGroupID}/employees/unassign-manager`, {})
            .subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);

                    this._companyGroupStore.update(companyGroupID, cg => {
                        const structure = {...cg.structure};

                        structure.parents = [];

                        return {
                            ...cg,
                            structure,
                            employees_count: cg.employees_count - 1
                        };
                    });
                },
                error => {
                    this._apiHelper.handleErrorResponse(error);
                }
            );
    }
}
