import { Component, Input, OnInit } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { first, switchMap, switchMapTo } from 'rxjs/operators';
import { IntegrationProviderType } from 'src/app/core/integration-providers.type';
import { ActiveIntegrations } from 'src/app/core/models/ActiveIntegrations';
import { IntegrationService } from 'src/app/core/services/integration.service';

@Component({
    selector: 'app-integrations',
    templateUrl: './integrations.component.html',
    styleUrls: ['./integrations.component.css']
})
export class IntegrationsComponent implements OnInit {
    @Input() watchingMyself = false;
    @Input() activeIntegrations: string[];

    public activeUserIntegrations$: Observable<ActiveIntegrations>;

    private _fetchIntegrations$ = new ReplaySubject<void>(1);
    private _userID$ = new ReplaySubject<number>(1);

    public constructor(private _integrationService: IntegrationService) { }

    @Input()
    public set userId(userId: number) {
        this._userID$.next(userId);

        this._fetchIntegrations$.next();
    }

    public ngOnInit(): void {
        this.activeUserIntegrations$ = this._fetchIntegrations$
            .pipe(
                switchMapTo(this._userID$),
                switchMap(userId => this._integrationService.getAllActiveByUserId(userId))
            );
    }

    public activateIntegration(providerName: IntegrationProviderType): void {
        let stream$;

        switch (providerName) {
            case 'azure': {
                stream$ = this._integrationService.connectAzure();

                break;
            }

            default: {
                stream$ = of(null);
            }
        }

        stream$
            .subscribe(() => this._fetchIntegrations$.next());
    }

    public deactivateIntegration(providerName: IntegrationProviderType): void {
        this._userID$
            .pipe(
                first(),
                switchMap(userId => this._integrationService.disconnect(providerName, userId)))
            .subscribe(() => this._fetchIntegrations$.next());
    }
}
