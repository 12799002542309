<div class="modal-content">
  <div class="modal-header">
    <p *ngIf="property" class="modal-title font-16 mb-0 text-dark">{{'properties.page_title_edit' | translate}}</p>
    <p *ngIf="!property" class="modal-title font-16 mb-0 text-dark">{{'properties.page_title_create' | translate}}</p>
    <button (click)="activeModal.dismiss()" type="button" class="close" aria-label="Close">
      <span class="font-24 text-muted" aria-hidden="true"><i class="mdi mdi-close"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <form [formGroup]="propertyEditForm" (ngSubmit)="onSubmit()" autocomplete="off">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="control-label">{{'properties.property_category_ID' | translate}}</label>
                        <ng-select formControlName="property_category_ID" (change)="getNewCode($event)" placeholder="Vyberte kategorii" class="custom" [items]="propertyCategories$ | async" bindLabel="name" bindValue="property_category_ID" name="property_category_ID"></ng-select>
                        <div *ngIf="submitted && f.property_category_ID.errors">
                          <p *ngIf="f.property_category_ID.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{ 'properties.property_category_ID' | translate }}
                            {{ 'angular_validation.required' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                          <label class="control-label">{{'properties.state' | translate}}</label>
                          <ng-select
                              [clearable]="false"
                              [closeOnSelect]="true"
                              [items]="selectFilterValues"
                              [multiple]="false"
                              [searchable]="false"
                              bindLabel="name"
                              bindValue="value"
                              class="custom"
                              formControlName="state"
                          ></ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="control-label">{{'properties.name' | translate}}</label>
                          <input *ngIf="property" formControlName="name" type="text" class="form-control" name="name">
                          <div *ngIf="!property" class="">
                              <ng-autocomplete [data]="propertiesAutoComplete"
                                               [itemTemplate]="itemTemplate"
                                               (inputChanged)='onChangeSearch($event)'
                                               formControlName="name"
                                               [notFoundTemplate]="notFoundTemplate">
                              </ng-autocomplete>
                              <ng-template #itemTemplate let-item>
                                  <a [innerHTML]="item"></a>
                              </ng-template>

                              <ng-template #notFoundTemplate let-notFound>
                                  <div [innerHTML]="notFound"></div>
                              </ng-template>
                          </div>
                        <div *ngIf="submitted && f.name.errors">
                          <p *ngIf="f.name.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{'properties.name' | translate}}
                            {{'angular_validation.required' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="control-label">{{'properties.code' | translate}}</label>
                        <input formControlName="code" type="text" class="form-control" name="code">
                        <div *ngIf="submitted && f.code.errors">
                          <p *ngIf="f.code.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{'properties.code' | translate}}
                            {{'angular_validation.required' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="control-label">{{'properties.serial_number' | translate}}</label>
                        <input formControlName="serial_number" type="text" class="form-control" name="serial_number">
                        <div *ngIf="submitted && f.serial_number.errors">
                          <p *ngIf="f.serial_number.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{'properties.serial_number' | translate}}
                            {{'angular_validation.required' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="control-label">{{'properties.lost_fee' | translate}}</label>
                        <input formControlName="lost_fee" currencyMask type="text" class="form-control" name="lost_fee">
                        <div *ngIf="submitted && f.lost_fee.errors">
                          <p *ngIf="f.lost_fee.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{'properties.lost_fee' | translate}}
                            {{'angular_validation.required' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="control-label">{{'properties.date_of_registration' | translate}}</label>
                        <input formControlName="date_of_registration" type="text" mwlFlatpickr allowInput="true"
                               altInput="true"
                               altFormat="d.m.Y"
                               dateFormat="Y-m-d"
                               [locale]="locale$ | async"
                               (flatpickrClose)="fpHelper.applyAltInputValue($event, false)" class="form-control" name="date_of_registration">
                        <div *ngIf="submitted && f.date_of_registration.errors">
                          <p *ngIf="f.date_of_registration.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{'properties.date_of_registration' | translate}}
                            {{'angular_validation.required' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="control-label">{{'properties.date_of_decommissioning' | translate}}</label>
                        <input formControlName="date_of_decommissioning" type="text" mwlFlatpickr allowInput="true"
                               altInput="true"
                               altFormat="d.m.Y"
                               dateFormat="Y-m-d"
                               [locale]="locale$ | async"
                               (flatpickrClose)="fpHelper.applyAltInputValue($event, false)" class="form-control" name="date_of_decommissioning">
                        <div *ngIf="submitted && f.date_of_decommissioning.errors">
                          <p *ngIf="f.date_of_decommissioning.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{'properties.date_of_decommissioning' | translate}}
                            {{'angular_validation.required' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="control-label">{{'properties.end_of_warranty' | translate}}</label>
                        <input formControlName="end_of_warranty" type="text" mwlFlatpickr allowInput="true"
                               altInput="true"
                               altFormat="d.m.Y"
                               dateFormat="Y-m-d"
                               [locale]="locale$ | async"
                               (flatpickrClose)="fpHelper.applyAltInputValue($event, false)" class="form-control" name="end_of_warranty">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="control-label">{{'properties.description' | translate}}</label>
                        <input formControlName="description" type="text" class="form-control" name="description">
                      </div>
                    </div>
                  </div>

                    <div *ngIf="!property" class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="control-label">
                                    <span>{{ 'notes.text' | translate }}</span>
                                </label>

                                <input
                                    formControlName="note"
                                    name="note"
                                    type="text"
                                    [ngxSummernote]="summernoteHelper.getSummernoteConfig()"
                                >

                                <div *ngIf="submitted && f.note.errors">
                                    <p
                                        *ngIf="f.note.errors.required"
                                        class="text-danger validation-text"
                                    >
                                        <span>{{ 'angular_validation.field' | translate }} </span>
                                        <span>{{ 'notes.text' | translate }} </span>
                                        <span>{{ 'angular_validation.required' | translate }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                  <ng-container>
                    <app-custom-fields [customFields]="property?.custom_fields"
                                       [submitted]="submitted"
                                       [columns]="1"
                                       [widget]="'PROPERTY'"
                                       [hr]="true"
                                       [form]="propertyEditForm">
                    </app-custom-fields>
                  </ng-container>

                  <div *ngIf="!property" class="row">
                    <div class="col-6 mb-2">
                      <label class="control-label mb-0">{{'properties.create_multiple_items' | translate}}</label>
                    </div>
                    <div class="col-6 text-right align-self-center">
                      <div>
                        <input formControlName="multiple_items" type="checkbox" id="multiple_items" name="multiple_items" data-switch="success">
                        <label for="multiple_items">&nbsp;</label>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="f.multiple_items.value">
                    <div class="row mb-2">
                      <div class="col-12">
                        <label class="control-label">{{ 'properties.quantity' | translate }}</label>
                        <input formControlName="quantity" type="number" min="1" name="quantity" class="form-control">
                        <div *ngIf="submitted && f.quantity.errors">
                          <p *ngIf="f.quantity.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{ 'properties.quantity' | translate }}
                            {{'angular_validation.required' | translate }}
                          </p>
                          <p *ngIf="f.quantity.errors.min" class="text-danger validation-text">
                            {{'angular_validation.min_quantity' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr/>
                    <div *ngFor="let c of renderedSerialNumberControls; let i = index;" class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="control-label">{{'properties.serial_number' | translate}} - {{i + 1}}</label>
                          <input formControlName="serial_number-{{i + 1}}" type="text" name="serial_number-{{i + 1}}" class="form-control">
                        </div>
                      </div>
                    </div>
                  </ng-container >

                  <div class="row mt-2">
                    <div class="col d-flex justify-content-end">
                      <button *ngIf="property" class="btn btn-link text-muted pr-0 mr-4" type="button" (click)="openPropertyDeleteModal()">
                        <u>{{ 'properties.action_delete' | translate }}</u>
                      </button>
                      <button class="btn btn-success" type="submit">
                        {{'properties.action_save' | translate}}
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>








