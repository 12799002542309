import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, of, ReplaySubject } from 'rxjs';
import { first, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Permission } from 'src/app/setting/models/Permission';
import { Role } from 'src/app/setting/models/Role';
import { RolesService } from 'src/app/setting/services/roles.service';

@Component({
    selector: 'app-create-edit-role',
    templateUrl: './create-edit-role.component.html',
    styleUrls: ['./create-edit-role.component.css']
})
export class CreateEditRoleComponent implements OnInit {
    public permissions$: Observable<Array<Permission>>;

    public role$: Observable<Role>;

    private _fetchRole$ = new ReplaySubject<void>(1);

    private _permissions$ = new ReplaySubject<Array<Permission>>(1);

    private _roleID$: Observable<number>;

    public constructor(
        private _roleService: RolesService,
        private _route: ActivatedRoute,
        private _router: Router
    ) { }

    public ngOnInit(): void {
        this.permissions$ = this._permissions$;

        this._roleID$ = this._route.params.pipe(map(params => parseInt(params.id, 10)));

        this.role$ = this._fetchRole$
            .pipe(
                withLatestFrom(this._roleID$),
                switchMap(([noop, roleID]) => {
                    if (roleID) {
                        return this._roleService.getRoleByID(roleID);
                    } else {
                        return of(null);
                    }
                }),
                tap(role => {
                    if (role) {
                        this._permissions$.next(role.permissions);
                    } else {
                        this._navigateToRoleList();
                    }
                }),
                shareReplay()
            );

        this._fetchRole$.next();
    }

    public deleteRole(): void {
        this._roleID$
            .pipe(first())
            .subscribe(roleID => {
                this._roleService.deleteRole(roleID)
                    .then(() => this._navigateToRoleList())
                    .catch(() => { });
            });
    }

    public onSubmit(form): void {
        const result: Role = this._roleService.processEditRoleForm(form);

        this._roleID$
            .pipe(first())
            .subscribe(roleID => {
                if (roleID) {
                    this._roleService.saveRole(result, roleID);
                } else {
                    this._roleService.saveRole(result);
                }
            });
    }

    private _navigateToRoleList(): void {
        this._router.navigate(['/setting', 'user-accounts'], {fragment: 'roles'});
    }
}
