import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Routes } from '../shared/models/Route';
import { KpiListComponent } from './components/sections/kpi-list/kpi-list.component';
import { KpiOverviewComponent } from './components/sections/kpi-overview/kpi-overview.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },

    {
        path: 'list',
        component: KpiListComponent,
        data: {
            help: 'main_processes.employees_evaluation',
            title: 'kpis.page_title_index'
        }
    },

    {
        path: 'overview/:kpiID',
        component: KpiOverviewComponent,
        data: {
            help: 'main_processes.employees_evaluation',
            title: 'kpis.page_title_view'
        }
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [
        RouterModule,
    ]
})
export class KpiRoutingModule {}
