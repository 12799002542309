import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { EmployeeGrowthTask } from 'src/app/growth/employee-growth/employee-growth-task.model';
import { EmployeeGrowth } from 'src/app/growth/employee-growth/employee-growth.model';
import { EmployeeGrowthQuery } from 'src/app/growth/employee-growth/employee-growth.query';
import { EmployeeGrowthService } from 'src/app/growth/employee-growth/employee-growth.service';
import { GrowthService } from 'src/app/growth/growth/state/growth.service';

@Component({
    selector: 'app-employee-growth-overview-modal',
    templateUrl: './employee-growth-overview-modal.component.html',
    styleUrls: ['./employee-growth-overview-modal.component.css']
})
export class EmployeeGrowthOverviewModalComponent implements OnInit {
    public canEdit$: Observable<boolean>;
    public employeeGrowth$: Observable<EmployeeGrowth>;
    public employeeGrowthID: number;
    public isCollapsed: boolean[] = [];
    public submitted = false;
    public submittedTask = false;

    public constructor(
        public activeModal: NgbActiveModal,
        private _authService: AuthenticationService,
        private _employeeGrowthQuery: EmployeeGrowthQuery,
        private _employeeGrowthService: EmployeeGrowthService,
        private _growthService: GrowthService
    ) { }

    public ngOnInit(): void {
        this.canEdit$ = combineLatest([
            this._authService.hasPermissionTo('employee.edit')
                .pipe(map(permission => permission.can)),
            this._authService.hasPermissionTo('employee.editStructure')
                .pipe(map(permission => permission.can))
        ])
            .pipe(
                map(([canEdit, canEditStructure]) => canEdit || canEditStructure),
                shareReplay()
            );

        if (this.employeeGrowthID) {
            this.employeeGrowth$ = this._employeeGrowthQuery.selectEntity(this.employeeGrowthID);
        }
    }

    public completeTask(task: EmployeeGrowthTask, growth: EmployeeGrowth): void {
        this._employeeGrowthService.completeTask(growth.employee_growth_ID, task);
    }

    public deleteTask(growth: EmployeeGrowth, growthTask: EmployeeGrowthTask): void {
        void this._employeeGrowthService.deleteTask(growth.employee_growth_ID, growthTask.employee_growth_task_ID);
    }

    public onSubmit(f: UntypedFormGroup, growth: EmployeeGrowth, growthTask: EmployeeGrowthTask): void {
        this.submitted = true;

        if (f.invalid) {
            return;
        }

        this.submitted = false;

        this._employeeGrowthService.saveEmployeeGrowthTask(f, growth, growthTask);
    }

    public unCompleteTask(task: EmployeeGrowthTask, growth: EmployeeGrowth): void {
        this._employeeGrowthService.unCompleteTask(growth.employee_growth_ID, task);
    }

    public unAssignEmployeeFromGrowth(growthID: number, employeeID: number): void {
        void this._growthService.unAssignEmployeeFromGrowth(growthID, employeeID);
    }

    public saveTask(employeeGrowth: EmployeeGrowth, task: EmployeeGrowthTask, form: UntypedFormGroup) {
        this.submittedTask = true;

        if (form.invalid) {
            return;
        }

        this.submittedTask = false;

        this._employeeGrowthService.saveEmployeeGrowthTask(form, employeeGrowth, task);
    }
}
