import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { LoggedInUser } from 'src/app/core/models/LoggedUser';
import { File } from 'src/app/employee/models/File';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { FileService } from 'src/app/shared/services/file.service';
import Swal from 'sweetalert2';
import { AvailableFeatureExtended, AvailableFeatures } from '../../employee/models/AvailableFeatures';
import { MenuItemService } from '../../menu/state/menu-item.service';
import { UpdateSubscriptionOptions } from '../components/sections/subscription-settings-overview/subscription-settings-overview.component';
import { Order } from '../models/Order';
import { StripeProduct } from '../models/StripeProduct';
import { Subscription } from '../models/Subscription';

interface DataForSubscriptionUpdate {
    action: 'ACTIVATE' | 'ACTIVATE_WITH_INVITATION' | 'DEACTIVATE';
    user_ID: number;
}

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {
    public subscription$: Observable<Subscription>;
    public availableFeatures$: Observable<AvailableFeatures>;

    private _subscription$ = new ReplaySubject<Subscription>(1);
    private _availableFeatures$$ = new ReplaySubject<AvailableFeatures>(1);

    public constructor(
        private _apiHelper: ApiHelper,
        private _fileService: FileService,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _translateService: TranslateService,
        private _swalHelper: SwalHelper,
        private _menuService: MenuItemService,
    ) {
        this.subscription$ = this._subscription$;
        this.availableFeatures$ = this._availableFeatures$$;
    }

    public updateSubscriptionInfo(value: Subscription): void {
        this._subscription$.next(value);
    }

    public createBillingPortalURL(): void {
        this._http.get<{
            url: string;
        }>('/api/billing-portal')
            .subscribe(
                data => window.location.href = data.url,
                error => this._apiHelper.handleErrorResponse(error)
            );
    }

    public createCheckoutSession(dataForUpdate: Array<DataForSubscriptionUpdate>, options: UpdateSubscriptionOptions, planID: string): Promise<Observable<any>> {
        return new Promise(async (resolve, reject) => {
            const susbcriptionConfirmed = options.termsAgreed ? await this._swalHelper.showConfirmChangeDialog('subscription.change_plan_confirm_text', 'subscription.change_plan_confirm_name') : true;
            if (susbcriptionConfirmed) {
                resolve(this._http.post<ApiResponse>('/api/create-checkout-session', {users: dataForUpdate, ...options.terms, plan: planID, card_required: options.cardRequired})
                    .pipe(
                        catchError(error => {
                            this._apiHelper.handleErrorResponse(error);

                            return of(null);
                        })
                    ));
            } else {
                reject('error');
            }
        });
    }

    public downloadInvoice(orderID: number): void {
        this._loaderService.stop();

        this._http.post<File>('/api/subscription/orders/' + orderID + '/download-invoice', {})
            .subscribe(
                file => this._fileService.checkMimeTypeAndDownloadWithAnchor(file),
                () => this._loaderService.stop()
            );
    }

    public getAllProducts(): Observable<Array<StripeProduct>> {
        return this._http.get<Array<StripeProduct>>('/api/subscription/products');
    }

    public getAllPaymentHistory(): Observable<Array<Order>> {
        return this._http.get<Array<Order>>('/api/subscription/orders/history');
    }

    public getAllSubscribedUser(): Observable<Array<LoggedInUser>> {
        return this._http.get<Array<LoggedInUser>>('/api/subscription/users');
    }

    public getEmployeesUsersCounts(): Observable<Array<number>> {
        return this._http.get<Array<number>>('/api/subscription/employee-user-counts');
    }

    public getDataMode(): Observable<Subscription> {
        return this._http.get<Subscription>('/api/subscription/data')
            .pipe(
                tap(data => this._subscription$.next(data)),
                shareReplay()
            );
    }

    public getAvailableFeatures(): Observable<AvailableFeatures> {
        return this._http.get<AvailableFeatures>('/api/subscription/available-features')
            .pipe(
                tap(features => this._availableFeatures$$.next(features)),
                shareReplay()
            );
    }

    public getAvailableFeaturesExtended(): Observable<AvailableFeatureExtended[]> {
        return this._http.get<AvailableFeatureExtended[]>('/api/subscription/available-features?scope=extend');
    }

    public saveFeatures(features: AvailableFeatures, changedFeature: { [key: string]: boolean }): Promise<AvailableFeatureExtended[]> {
        this._loaderService.start();

        return new Promise(async (resolve, reject) => {
            this._http.post<ApiResponse & {
                features: AvailableFeatureExtended[]
            }>('/api/subscription/available-features', changedFeature)
                .subscribe(
                    (response) => {
                        this._loaderService.stop();
                        this._menuService.refetch();
                        this._availableFeatures$$.next(features);
                        this._apiHelper.handleSuccessResponse(response);
                        resolve(response.features);
                    },
                    error => {
                        this._loaderService.stop();
                        this._apiHelper.handleErrorResponse(error);
                        reject(error);
                    }
                );
        });
    }

    public sendInvite(user: {
        email: string;
        id: number
    }): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const isConfirmed = await this._swalHelper.showConfirmChangeDialog(
                this._translateService.instant('subscription.send_invite_confirm_text'),
                this._translateService.instant('subscription.send_invite_confirm_title')
            );

            if (isConfirmed) {
                this._loaderService.start();
                this._http.get<{
                    message: string;
                }>(`/api/subscription/users/${user.id}/send-invitation`)
                    .subscribe((response) => {
                        if (response.message === 'global.action_save_successful') {
                            Swal.fire({
                                ...this._apiHelper.successSwalOptions,
                                text: this._translateService.instant('subscription._invite_sent').replace('{user.email}', user.email)
                            });
                        } else {
                            throw new Error();
                        }

                        resolve('done');
                        this._loaderService.stop();
                    }, (error) => {

                        Swal.fire({
                            ...this._apiHelper.errorSwalOptions,
                            text: this._translateService.instant('subscription._invite_not_sent').replace('{user.email}', user.email)
                        });
                        this._loaderService.stop();
                        reject(error);
                    });
            }
        });
    }

    public sendNewPassword(user: {
        email: string;
        id: number
    }): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const isConfirmed = await this._swalHelper.showConfirmChangeDialog(
                this._translateService.instant('subscription.send_new_password_confirm_text'),
                this._translateService.instant('subscription.send_new_password_confirm_title')
            );

            if (isConfirmed) {
                this._loaderService.start();
                this._http.get<{
                    message: string;
                }>(`/api/subscription/users/${user.id}/send-new-password`)
                    .subscribe((response) => {
                        if (response.message === 'global.action_save_successful') {
                            Swal.fire({
                                ...this._apiHelper.successSwalOptions,
                                text: this._translateService.instant('subscription.new_password_sent').replace('{user.email}', user.email)
                            });
                        } else {
                            throw new Error();
                        }

                        resolve('done');
                        this._loaderService.stop();
                    }, (error) => {

                        Swal.fire({
                            ...this._apiHelper.errorSwalOptions,
                            text: this._translateService.instant('subscription.new_password_not_sent').replace('{user.email}', user.email)
                        });
                        this._loaderService.stop();
                        reject(error);
                    });
            }
        });
    }

    public switchData(): void {
        this._loaderService.start();

        this._http.post('/api/subscription/data/switch', {demo_mode: 0})
            .subscribe(
                () => window.location.reload(),
                error => {
                    this._loaderService.stop();

                    this._apiHelper.handleErrorResponse(error);
                }
            );
    }

    public updateSubscription(dataForUpdate: Array<DataForSubscriptionUpdate>): Promise<Observable<any>> {
        return new Promise(async (resolve, reject) => {
            resolve(this._http.post<ApiResponse>('/api/subscription/update-quantity', {users: dataForUpdate})
                .pipe(
                    tap(response => this._apiHelper.handleSuccessResponse(response)),
                    catchError((error: ApiResponse) => {
                        this._apiHelper.handleErrorResponse(error);

                        return of(null);
                    })
                ));
        });
    }

    public agreeTerms(terms1: boolean, marketingAgreed?: boolean): void {
        const body: any = {
            terms_1: terms1
        };

        if (marketingAgreed !== undefined && marketingAgreed !== null) {
            body.marketing_agreed = marketingAgreed;
        }
        this._http.post<ApiResponse>('/api/subscription/agreements', body)
            .subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);
                    this.getDataMode().subscribe();
                },
                error => this._apiHelper.handleErrorResponse(error)
            );
    }
}
