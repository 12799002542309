import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { CompanyGroup } from '../../../../company-group/state/company-group.model';
import { CompanyGroupQuery } from '../../../../company-group/state/company-group.query';
import { CompanyGroupService } from '../../../../company-group/state/company-group.service';
import { FileService } from '../../../../shared/services/file.service';
import { Employee } from '../../../models/Employee';

@Component({
    selector: 'app-change-employee-company-group-modal',
    templateUrl: './change-employee-company-group-modal.component.html',
    styleUrls: ['./change-employee-company-group-modal.component.css']
})

export class ChangeEmployeeCompanyGroupModalComponent implements OnInit {
    public submitted = false;
    public employee: Employee;
    public companyGroupForm: UntypedFormGroup;
    public companyGroups$: Observable<CompanyGroup[]>;

    constructor(
        private _employeeService: EmployeeService,
        private _translateService: TranslateService,
        private _fileService: FileService,
        private _fb: UntypedFormBuilder,
        private _companyGroupService: CompanyGroupService,
        private _companyGroupQuery: CompanyGroupQuery,
        public activeModal: NgbActiveModal,
    ) { }

    public ngOnInit(): void {
        this.companyGroupForm = this._fb.group({
            company_group_ID: [null, Validators.required]
        });

        this._companyGroupService.get()
            .subscribe();

        this.companyGroups$ = this._companyGroupQuery.selectAll();

        this.companyGroupForm.patchValue({
            company_group_ID: this.employee.company_group?.company_group_ID
        });
    }

    public onCompanyGroupSubmit(): void {
        this.submitted = true;
        if (this.companyGroupForm.invalid) {
            return;
        }

        this._companyGroupService.assignEmployeesToCompanyGroup(
            this.companyGroupForm.value.company_group_ID,
            [this.employee.employee_ID],
        );

        this.activeModal.close();
    }
}
