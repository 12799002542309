<ng-container *ngIf="checkPermission('employee') && ((availableWidgets$ | async)?.employee?.setting?.salutation || (availableWidgets$ | async)?.employee?.setting?.branch || (availableWidgets$ | async)?.employee?.setting?.role)">
  <div class="row">
    <div class="col-12">
      <div class="text-dark page-title mb-3">
        <h4> {{ 'employees.settings_section_user_title' | translate }} </h4>
      </div>
    </div>
  </div>

  <!--<editor-fold desc="Salutation">-->
  <div *ngIf="(availableWidgets$ | async)?.employee?.setting?.salutation" class="row">
    <div class="col-md-12">
      <div class="card shadow">
        <app-help name="employee.detail.settings.salutation"></app-help>

        <div class="card-header">
          <p class="modal-title font-16 text-dark pt-2 pb-2">
            <span>{{ 'employees.change_salutation' | translate }}</span>
          </p>
        </div>

        <div class="card-body">
          <form (ngSubmit)="onSubmitSalutation()" [formGroup]="salutationForm" autocomplete="off">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="control-label" for="salutation">
                    <span>{{ 'employees.salutation' | translate }}</span>
                  </label>
                  <input class="form-control" formControlName="salutation" id="salutation" name="salutation" type="salutation">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col d-flex justify-content-end">
                <button *ngIf="watchingMyself" class="btn btn-success" type="submit">
                  {{ 'employees.action_save' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!--</editor-fold>-->

  <!--<editor-fold desc="Branch">-->
  <div *ngIf="(availableWidgets$ | async)?.employee?.setting?.branch" class="row">
    <div class="col-md-12">
      <div class="card shadow">
        <app-help name="employee.detail.settings.branch"></app-help>

        <div class="card-header">
          <p class="modal-title font-16 text-dark pt-2 pb-2">
            <span *ngIf="canEditBranch; else CannotEditBranchTitle">{{ 'employees.main_company_branch' | translate }}</span>
            <ng-template #CannotEditBranchTitle>
              <span>{{ 'employees.main_company_branch' | translate }}</span>
            </ng-template>
          </p>
        </div>

        <div class="card-body">
          <form (ngSubmit)="onSubmitBranch()" [formGroup]="branchForm" autocomplete="off">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="control-label" for="branch">
                    <span>{{ 'employees.main_company_branch' | translate }}</span>
                  </label>
                  <ng-select [clearable]="false"
                             class="custom"
                             formControlName="branch"
                             id="branch"
                  >
                      <ng-option [value]="0">{{ 'company_branches.no_branch' | translate }}</ng-option>
                      <ng-option *ngFor="let companyBranch of branchStoreService.branches$ | async" [value]="companyBranch.company_branch_ID">
                          {{companyBranch.name }}
                      </ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
            <div *ngIf="canEditBranch" class="row">
              <div class="col d-flex justify-content-end">
                <button class="btn btn-success" type="submit">
                  {{ 'employees.action_save' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!--</editor-fold>-->

  <!--<editor-fold desc="Role">-->
  <div *ngIf="(availableWidgets$ | async)?.employee?.setting?.role" class="row">
    <div class="col-md-12">
      <div class="card shadow">
        <app-help name="employee.detail.settings.role"></app-help>

        <div class="card-header">
          <p class="modal-title font-16 text-dark pt-2 pb-2">
            <span *ngIf="canEditRole; else CannotEditRoleTitle">{{ 'employees.change_role' | translate }}</span>
            <ng-template #CannotEditRoleTitle>
              <span>{{ 'employees.role' | translate }}</span>
            </ng-template>
          </p>
        </div>

        <div class="card-body">
          <form (ngSubmit)="onSubmitRole()" [formGroup]="roleForm" autocomplete="off">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="control-label" for="role">
                    <span>{{ 'employees.role' | translate }}</span>
                  </label>
                  <ng-select [clearable]="false" [items]="employeeRoles$ | async"
                             bindLabel="name" bindValue="role_ID"
                             class="custom"
                             formControlName="role"
                             id="role"
                  ></ng-select>
                </div>
              </div>
            </div>
            <div *ngIf="canEditRole" class="row">
              <div class="col d-flex justify-content-end">
                <button class="btn btn-success" type="submit">
                  {{ 'employees.action_save' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!--</editor-fold>-->
</ng-container>

<!--<editor-fold desc="Certificate">-->
<ng-container *ngIf="employee$ | async as employee">
    <div *ngIf="(checkPermission('employee') && (availableFeatures$ | async)?.signatures) && employee.is_responsible && employee.permissions?.edit" class="row">
        <div class="col-md-12">
            <div class="card shadow">
                <app-help name="employee.detail.settings.certificate"></app-help>

                <div class="card-header">
                    <p class="modal-title font-16 text-dark pt-2 pb-2">
                        <span>{{ 'employees.certificate_page_title_view' | translate }}</span>
                    </p>
                </div>

                <div class="card-body">
                    <form autocomplete="off"
                    >
                        <ng-container *ngIf="!employee.signing_certificate; else existingCertificateTemplate">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="control-label required-label">
                                            <span>{{ 'employees.pem_file' | translate }}</span>
                                        </label>
                                        <div class="custom-file mb-1">
                                            <input
                                                #pemFileInput
                                                required
                                                class="custom-file-input"
                                                id="pem"
                                                name="pem"
                                                type="file"
                                                [attr.accept]="['.pem']"
                                                (change)="onUpload(pemFileInput.files, 'signing_certificate')"
                                            >

                                            <label
                                                class="custom-file-label"
                                                for="pem"
                                            >{{ 'employees.pick_a_file' | translate }}</label>
                                        </div>
                                        <div *ngIf="pemFileInput.files[0]">
                                            <span> {{ 'employees.picked_file' | translate }} </span>
                                            <span class="font-weight-bold"> {{pemFileInput.files[0]?.name}} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="control-label required-label">
                                            <span>{{ 'employees.key_file' | translate }}</span>
                                        </label>
                                        <div class="custom-file mb-1">
                                            <input
                                                #keyFileInput
                                                required
                                                class="custom-file-input"
                                                id="key"
                                                name="key"
                                                type="file"
                                                [attr.accept]="['.key', '.pem']"
                                                (change)="onUpload(keyFileInput.files, 'private_key')"
                                            >

                                            <label
                                                class="custom-file-label"
                                                for="key"
                                            >{{ 'employees.pick_a_file' | translate }}</label>
                                        </div>
                                        <div *ngIf="keyFileInput.files[0]">
                                            <span> {{ 'employees.picked_file' | translate }} </span>
                                            <span class="font-weight-bold"> {{keyFileInput.files[0]?.name}} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'employees.private_key_password' | translate }}</span>
                                        </label>
                                            <input #certPasswordInput
                                                   class="form-control"
                                                   id="cert_password"
                                                   name="cert_password"
                                                   type="password"
                                            >
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col d-flex justify-content-end">
                                    <button (click)="onSubmitCertificate(certPasswordInput.value)" class="btn btn-success">
                                        {{ 'employees.action_save' | translate }}
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #existingCertificateTemplate>
                            <div class="row">
                                <div class="col-12">
                                    <span>{{ 'employees.certificate_already_set_1' | translate }}</span>
                                    <span class="text-dark font-weight-bold px-1">{{ employee.signing_certificate }}</span>
                                    <span>{{ 'employees.certificate_already_set_2' | translate }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col d-flex justify-content-end">
                                    <button (click)="deleteCertificate()" class="btn btn-danger">
                                        {{ 'employees.action_delete' | translate }}
                                    </button>
                                </div>
                            </div>
                        </ng-template>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!--</editor-fold>-->

<!--<editor-fold desc="Contact list">-->
<div *ngIf="(checkPermission('employee') && (availableFeatures$ | async)?.contact_list)" class="row">
    <div class="col-md-12">
        <div class="card shadow">
            <app-help name="employee.detail.settings.help"></app-help>

            <div class="card-header">
                <p class="modal-title font-16 text-dark pt-2 pb-2">
                    <span>{{ 'employees.contact_list_page_title_view' | translate }}</span>
                </p>
            </div>

            <div class="card-body">
                <form
                    (ngSubmit)="onSubmitContactList()"
                    [formGroup]="contactListForm"
                    autocomplete="off"
                >
                    <div class="row">
                        <div class="col-6">
                            <label class="control-label mb-0">
                                <span>{{ 'employees.show_in_contact_list' | translate }}</span>
                            </label>
                        </div>

                        <div class="col-6 text-right align-self-center">
                            <div>
                                <input
                                    data-switch="success"
                                    formControlName="contact_list"
                                    id="contact_list"
                                    name="contact_list"
                                    type="checkbox"
                                >
                                <label for="contact_list">&nbsp;</label>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-12 text-right">
                            <small class="text-muted">
                                {{ 'employees.contact_list_page_description' | translate }}
                            </small>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col d-flex justify-content-end">
                            <button class="btn btn-success">
                                {{ 'employees.action_save' | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="(checkPermission('employee') || checkPermission('security')) && ((availableWidgets$ | async)?.employee?.setting?.integration_sso || (availableWidgets$ | async)?.employee?.setting?.two_factor || (availableWidgets$ | async)?.employee?.setting?.password_change)">
  <div class="row">
    <div class="col-12">
      <div class="text-dark page-title mb-3">
        <h4> {{ 'employees.settings_section_login_title' | translate }} </h4>
      </div>
    </div>
  </div>

  <!--<editor-fold desc="Integration">-->
  <ng-container *ngIf="checkPermission('employee') && (availableWidgets$ | async)?.employee?.setting?.integration_sso && (activeIntegrations$ | async) as activeIntegrations">
      <div *ngIf="activeIntegrations.includes('microsoft-0365-login') || activeIntegrations.includes('microsoft-teams')" class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <app-help name="employee.detail.settings.integration.sso"></app-help>

            <div class="card-header">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'integrations.microsoft-0365-login.name' | translate }}</span>
              </p>
            </div>

            <div class="card-body">
              <app-integrations
                  *ngIf="userID$ | async; let userId"
                  [activeIntegrations]="activeIntegrations"
                  [userId]="userId"
                  [watchingMyself]="watchingMyself"
              ></app-integrations>
            </div>
          </div>
        </div>
      </div>
  </ng-container>
  <!--</editor-fold>-->

  <!--<editor-fold desc="2FA">-->
  <div *ngIf="checkPermission('security') && (availableWidgets$ | async)?.employee?.setting?.two_factor && (securitySetting$ | async)?.twoFactor?.enabled" class="row">
    <div class="col-md-12">
      <div class="card shadow">
        <app-help name="employee.detail.settings.2fa"></app-help>

        <div class="card-header">
          <p class="modal-title font-16 text-dark pt-2 pb-2">
            <span>{{ 'employees.2fa_title' | translate }}</span>
          </p>
        </div>

        <div class="card-body">
          <form
              (ngSubmit)="onSubmitTwoFactor()"
              [formGroup]="twoFactorForm"
              autocomplete="off"
          >
            <ng-container *ngIf="!hasTwoFactorActive">
              <div class="row">
                <div class="col-6">
                  <label class="control-label">
                    <span>{{ 'employees.2fa_activate' | translate }}</span>
                  </label>
                </div>
                <div class="col-6 text-right">
                  <input (click)="enableTwoFactor()"
                         [attr.aria-expanded]="twoFactorCollapsed"
                         aria-controls="collapse"
                         data-switch="success"
                         formControlName="enabled"
                         id="enabledTwoFactor"
                         type="checkbox"
                  >
                  <label for="enabledTwoFactor">&nbsp;</label>
                </div>
              </div>

              <div #haha="ngbCollapse"
                   [(ngbCollapse)]="twoFactorCollapsed">
                <div *ngIf="twoFactorSecretKey$ | async; let secretKey" class="row mb-0 mt-3 justify-content-between align-items-center">
                  <div class="col-12 col-lg-9 col-md-8">
                    <p> {{ 'employees.2fa_step_1' | translate }}</p>
                    <p> {{ 'employees.2fa_step_2' | translate }}</p>
                    <p> {{ 'employees.2fa_step_3' | translate }}</p>
                  </div>
                  <div class="col-12 col-lg-3 col-md-4 d-md-flex justify-content-md-end">
                    <div class="img-wrapper text-center">
                      <img alt="secret_qr"
                           class="mw-100 mb-1"
                           src="{{ secretKey.qr_code_url }}"/>
                      <div class="">
                        {{ secretKey.secret_key }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label" for="two_factor_code">
                        <span>{{ 'employees.2fa_input_code' | translate }}</span>
                      </label>
                      <div class="two-fa-wrapper m-md-0 m-lg-0 m-xl-0">
                        <div class="row boxes">
                          <ng-container *ngFor="let i of [0, 1, 2, 3, 4, 5]; let last = last;">
                            <div [ngClass]="{'selected': inputField.value.length === i}" class="box" id="box-{{i}}">
                            </div>
                          </ng-container>
                        </div>

                        <input #inputField
                               (ngModelChange)="checkCount($event)"
                               class="form-control two-factor-partitioned"
                               formControlName="two_factor_code"
                               id="two_factor_code"
                               maxlength="6"
                               name="two_factor_code"
                               onlyNumber
                               type="text">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col d-flex justify-content-end">
                  <button [disabled]="twoFactorCollapsed || loadingTwoFactor"
                          class="btn btn-success">
                    <div *ngIf="loadingTwoFactor" class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <span *ngIf="!loadingTwoFactor">{{ 'employees.2fa_submit_action' | translate }}</span>
                  </button>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="hasTwoFactorActive">
              <div class="row align-items-center">
                <div class="col-12 col-md-6">
                  <p class="mb-2 mb-md-0">
                    {{ 'employees.2fa_activated_text' | translate }}
                  </p>
                </div>
                <div class="col-12 col-md-6 text-right">
                  <button (click)="deactivateTwoFactor()"
                          class="btn btn-danger"
                          type="button">
                    {{ 'employees.2fa_deactivate_action' | translate }}
                  </button>
                </div>
              </div>
            </ng-container>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!--</editor-fold>-->

    <!--<editor-fold desc="Kiosk Mode">-->
    <ng-container *ngIf="checkPermission('employee') && (availableWidgets$ | async)?.employee?.setting?.kiosk_mode && employee$ | async as employee">
        <div *ngIf="employee.login_code" class="row">
            <div class="col-md-12">
                <div class="card shadow">
                    <app-help name="employee.detail.settings.code_for_kiosk_mode"></app-help>

                    <div class="card-header">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">
                            <span>{{ 'employees.code_for_kiosk_mode' | translate }}</span>
                        </p>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="control-label">
                                        <span>{{ 'employees.your_kiosk_code' | translate }}</span>
                                    </label>
                                    <div class="input-group">
                                        <input type="{{showKioskCode ? 'text' : 'password'}}"
                                               class="form-control"
                                               readonly
                                               value="{{employee.login_code}}"

                                        >
                                        <div class="input-group-append input-group-append-over">
                                            <button
                                                (click)="toggleKioskCode()"
                                                [class.input-icon]="showKioskCode"
                                                class="btn btn-link btn-icon pl-0 pr-0 pt-2 text-dark"
                                                type="button"
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    class="mdi mdi-eye-outline mdi-24px"
                                                ></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-right mb-2">
                                <small class="text-muted">
                                    {{ 'employees.code_for_kiosk_mode_description' | translate }}
                                    {{ loggedInUser?.workspace?.admin?.name + ' ' + loggedInUser?.workspace?.admin?.surname }}, email:
                                    {{ loggedInUser?.workspace?.admin?.email }}
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-success"
                                        type="submit"
                                        (click)="generateKioskLoginCode(employee)">
                                    {{ 'employees.action_generate_new_kiosk_code' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <!--</editor-fold>-->

  <!--<editor-fold desc="Password">-->
  <div *ngIf="checkPermission('employee') && (availableWidgets$ | async)?.employee?.setting?.password_change" class="row">
    <div class="col-md-12">
      <div class="card shadow">
        <app-help name="employee.detail.settings.password_change"></app-help>

        <div class="card-header">
          <p class="modal-title font-16 text-dark pt-2 pb-2">
            <span>{{ 'employees.change_password' | translate }}</span>
          </p>
        </div>

        <div class="card-body">
          <form
              (ngSubmit)="onSubmit()"
              [formGroup]="passwordEditForm"
              autocomplete="off"
          >
            <div *ngIf="watchingMyself" class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label class="control-label">
                    <span>{{ 'employees.password' | translate }}</span>
                  </label>

                  <input
                      class="form-control"
                      formControlName="password"
                      name="password"
                      type="password"
                  >

                  <div *ngIf="submitted && f.password.errors">
                    <p
                        *ngIf="f.password.errors.required"
                        class="text-danger validation-text"
                    >
                      <span>{{ 'angular_validation.field' | translate }} </span>
                      <span>{{ 'employees.password' | translate }} </span>
                      <span>{{ 'angular_validation.required' | translate }}</span>
                    </p>

                    <p
                        *ngIf="f.password.errors.minlength"
                        class="text-danger validation-text"
                    >
                      <span>{{ 'angular_validation.min_password_length' | translate }}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div *ngIf="watchingMyself" class="col-lg-6">
                <div class="form-group">
                  <label class="control-label">
                    <span>{{ 'employees.password_confirmation' | translate }}</span>
                  </label>

                  <input
                      class="form-control"
                      formControlName="passwordConfirm"
                      name="password_confirmation"
                      type="password"
                  >

                  <div *ngIf="submitted && f.passwordConfirm.errors">
                    <p
                        *ngIf="f.passwordConfirm.errors.required"
                        class="text-danger validation-text"
                    >
                      <span>{{ 'angular_validation.field' | translate }} </span>
                      <span>{{ 'employees.password_confirmation' | translate }} </span>
                      <span>{{ 'angular_validation.required' | translate }}</span>
                    </p>

                    <p
                        *ngIf="f.passwordConfirm.errors.minlength"
                        class="text-danger validation-text"
                    >
                      <span>{{ 'angular_validation.min_password_length' | translate }}</span>
                    </p>
                  </div>

                  <div *ngIf="submitted && passwordEditForm.errors">
                    <p
                        *ngIf="passwordEditForm.errors.mismatch"
                        class="text-danger validation-text"
                    >
                      <span>{{ 'angular_validation.passwords_not_same' | translate }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col d-flex justify-content-end">
                  <ng-container *ngIf="employee$ | async as employee">
                      <button *ngIf="!watchingMyself && showPasswordReset && employee.has_active_access" (click)="sendInvitation(employee)" class="btn btn-success mr-2" submitButton type="button">
                          {{ 'employees.send_invite' | translate }}
                      </button>
                  </ng-container>
                  <button *ngIf="!watchingMyself && showPasswordReset" (click)="resetPassword()" class="btn btn-success" type="button">
                      {{ 'employees.change_password_action' | translate }}
                  </button>
                <button *ngIf="watchingMyself" class="btn btn-success" submitButton type="submit">
                  {{ 'employees.action_save' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!--</editor-fold>-->

  <!--<editor-fold desc="ActiveUser">-->
  <!-- <div class="row">
    <div class="col-md-12">
      <div class="card shadow">
        <app-help name="employee.detail.settings.activeUser"></app-help>

        <div class="card-header">
          <p class="modal-title font-16 text-dark pt-2 pb-2">
            <span>{{ 'employees.can_login' | translate }}</span>
          </p>
        </div>

        <div class="card-body">
          <form autocomplete="off" [formGroup]="activeUserForm" (ngSubmit)="onSubmitActiveUser()">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="activeUser" class="control-label">
                    <span>{{ 'employees.can_login' | translate }}</span>
                  </label>
                </div>
              </div>
              <div class="col-6 text-right align-self-center">
                <div>
                  <input
                    [disabled]="!(canEditAccess$ | async)" [readonly]="!(canEditAccess$ | async)"
                    data-switch="success" formControlName="activeUser" id="activeUser" name="activeUser" type="checkbox">
                  <label for="activeUser">&nbsp;</label>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="canEditAccess$ | async">
              <div class="col d-flex justify-content-end">
                <button class="btn btn-success" type="submit">
                  {{ 'employees.action_save' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> -->
  <!--</editor-fold>-->
</ng-container>

<ng-container *ngIf="checkPermission('employee') && ((availableWidgets$ | async)?.employee?.setting?.language || (availableWidgets$ | async)?.employee?.setting?.help)">
  <div class="row">
    <div class="col-12">
      <div class="text-dark page-title mb-3">
        <h4> {{ 'employees.settings_section_app_title' | translate }} </h4>
      </div>
    </div>
  </div>

  <!--<editor-fold desc="Language">-->
  <div *ngIf="(availableWidgets$ | async)?.employee?.setting?.language" class="row">
    <div class="col-md-12">
      <div class="card shadow">
        <app-help name="employee.detail.settings.language"></app-help>

        <div class="card-header">
          <p class="modal-title font-16 text-dark pt-2 pb-2">
            <span>{{ 'global.language' | translate }}</span>
          </p>
        </div>

        <div class="card-body">
          <form
              (ngSubmit)="onSubmitSetLocale()"
              [formGroup]="localeEditForm"
              autocomplete="off"
          >
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label class="control-label">
                    <span>{{ 'global.change_language' | translate }}</span>
                  </label>

                  <ng-select
                      [clearable]="false"
                      [items]="availableLocales$ | async"
                      class="custom"
                      formControlName="locale"
                      name="locale"
                  >
                    <ng-template
                        let-item="item"
                        ng-option-tmp
                    >
                      <span>{{ 'global.language_' + item | translate }}</span>
                    </ng-template>

                    <ng-template
                        let-item="item"
                        ng-label-tmp
                    >
                      <span>{{ 'global.language_' + item | translate }}</span>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col d-flex justify-content-end">
                <button class="btn btn-success">
                  {{ 'employees.action_save' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!--</editor-fold>-->

  <!--<editor-fold desc="Help mode">-->
  <div *ngIf="(availableWidgets$ | async)?.employee?.setting?.help" class="row">
    <div class="col-md-12">
      <div class="card shadow">
        <app-help name="employee.detail.settings.help"></app-help>

        <div class="card-header">
          <p class="modal-title font-16 text-dark pt-2 pb-2">
            <span>{{ 'global.help_mode' | translate }}</span>
          </p>
        </div>

        <div class="card-body">
          <form
              (ngSubmit)="onSubmitHelp()"
              [formGroup]="helpEditForm"
              autocomplete="off"
          >
            <div class="row">
              <div class="col-6">
                <label class="control-label mb-0">
                  <span>{{ 'global.app_help_mode' | translate }}</span>
                </label>
              </div>

              <div class="col-6 text-right align-self-center">
                <div>
                  <input
                      data-switch="success"
                      formControlName="help_mode"
                      id="help_mode"
                      name="help_mode"
                      type="checkbox"
                  >
                  <label for="help_mode">&nbsp;</label>
                </div>
              </div>
            </div>

            <p>
              <span>{{ 'global.app_help_mode_text_1' | translate }} </span>
              <i class="mdi mdi-help-circle mdi-24px text-primary"></i>
              <span>{{ 'global.app_help_mode_text_2' | translate }}</span>
            </p>

            <div class="row mt-4">
              <div class="col d-flex justify-content-end">
                <button class="btn btn-success">
                  {{ 'employees.action_save' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!--</editor-fold>-->
</ng-container>

<ng-container *ngIf="checkPermission('employee') && (availableWidgets$ | async)?.employee?.setting?.signature && signaturesAllowed && (employee$ | async)">
  <div class="row">
    <div class="col-12">
      <div class="text-dark page-title mb-3">
        <h4> {{ 'employees.settings_section_signature_title' | translate }} </h4>
      </div>
    </div>
  </div>

  <!--<editor-fold desc="Signature">-->
  <div class="row"
  >
    <div class="col-md-12">
      <div class="card shadow">
        <app-help name="employee.detail.settings.signature"></app-help>

        <div class="card-header">
          <p class="modal-title font-16 text-dark pt-2 pb-2">
            <span>{{ 'documents.signatures' | translate }}</span>
          </p>
        </div>

        <div class="card-body">
          <p *ngIf="!(employee$ | async)?.signature_saved">
            <span>{{ 'employees.signature_no_signature' | translate }}</span>

            <span>&nbsp;</span>

            <span>{{ 'employees.signature_do_you_want_to' | translate }}</span>

            <span>&nbsp;</span>

            <span (click)="openSignaturePadSettingModal()"
                  *ngIf="watchingMyself"
                  class="btn-link pointer text-primary"
            >{{ 'employees.signature_action_set' | translate }}</span>
            <span *ngIf="!watchingMyself">{{ 'employees.signature_action_set' | translate }}</span>
          </p>

          <p *ngIf="(employee$ | async)?.signature_saved">
            <span>{{ 'employees.signature_current_set_at' | translate }}</span>

            <span>&nbsp;</span>

            <span class="text-dark">{{ (employee$ | async).signature_created_at | date: 'dd.MM.yyyy' }}</span>

            <span>.</span>

            <span>&nbsp;</span>

            <span>{{ 'employees.signature_do_you_want_to' | translate }}</span>

            <span>&nbsp;</span>

            <span (click)="openSignaturePadSettingModal()"
                  *ngIf="watchingMyself"
                  class="btn-link pointer text-primary"
            >{{ 'employees.signature_action_change' | translate }}</span>
            <span *ngIf="!watchingMyself">{{ 'employees.signature_action_change' | translate }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <!--</editor-fold>-->
</ng-container>
