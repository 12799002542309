import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cacheable } from '@datorama/akita';
import { Observable, ReplaySubject } from 'rxjs';
import { first, map, shareReplay, tap } from 'rxjs/operators';
import { MenuItem } from './menu-item.model';
import { MenuItemStore } from './menu-item.store';

@Injectable({
    providedIn: 'root'
})
export class MenuItemService {
    public menuStats$: Observable<{
        [key: string]: string
    }>;

    private _menuStats$ = new ReplaySubject<{
        [key: string]: string
    }>(1);

    public constructor(
        private _http: HttpClient,
        private _menuItemStore: MenuItemStore,
    ) {
        this.menuStats$ = this._menuStats$;
    }

    public get(): Observable<void> {
        const request = this._getMenuItems();

        return cacheable(this._menuItemStore, request);
    }

    public refetch(): void {
        this._getMenuItems()
            .pipe(first())
            .subscribe(
                () => { },
                () => { }
            );
    }

    public _getMenuStats(): Observable<{
        [key: string]: string
    }> {
        return this._http.get<{
            [key: string]: string
        }>('/api/menu/badges')
            .pipe(
                tap(stats => this._menuStats$.next(stats)),
                shareReplay());
    }

    public refetchMenuStats(): void {
        this._getMenuStats().subscribe();
    }

    private _getMenuItems(): Observable<void> {
        return this._http.get<Array<MenuItem>>('/api/menu')
            .pipe(
                map(menuItems => this._menuItemStore.set(menuItems))
            );
    }
}
