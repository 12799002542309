import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { DocumentItem } from 'src/app/crm/models/DocumentItem';
import { DocumentDetailComponent } from 'src/app/document/components/sections/document-detail/document-detail.component';
import { Document } from 'src/app/document/models/Document';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { FileService } from 'src/app/shared/services/file.service';
import { DocumentTemplate } from '../../setting/models/DocumentTemplate';
import { File } from '../models/File';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _fileService: FileService,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _modalService: NgbModal,
        private _swalHelper: SwalHelper
    ) { }

    public attachCustomerToDocument(documentID: number, customerID: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/documents/' + documentID + '/attach-customer', {customer_ID: customerID})
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public attachOrderToDocument(documentID: number, orderID: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/documents/' + documentID + '/attach-order', {order_ID: orderID})
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public deleteDocument(documentID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete<ApiResponse>('/api/documents/' + documentID)
                    .subscribe(
                        response => {
                            this._loaderService.stop();

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public deleteDocumentItem(documentID: number, documentItemID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete<ApiResponse>('/api/documents/' + documentID + '/items/' + documentItemID)
                    .subscribe(
                        response => {
                            this._loaderService.stop();

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public deleteRepeatingAction(repeatingActionID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete('/api/repeating-actions/' + repeatingActionID, {observe: 'response'})
                    .pipe(
                        map(response => {
                            if (response.status === 204) {
                                return {message: 'repeating_actions.action_delete_successful'} as ApiResponse;
                            } else {
                                return response.body as ApiResponse;
                            }
                        })
                    )
                    .subscribe(
                        response => {
                            this._loaderService.stop();

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public detachCustomerFromDocument(documentID: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/documents/' + documentID + '/detach-customer', {})
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public detachOrderFromDocument(documentID: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/documents/' + documentID + '/detach-order', {})
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public downloadAllDatetimeExports(from: string, to: string, types: string[], paymentState: string): void {
        let params = null;

        if (
            from &&
            to
        ) {
            params = new HttpParams()
                .set('from', from)
                .set('to', to)
                .set('types', types.join(','))
                .set('payment_state', paymentState);
        }

        this._http.get<File>(`/api/documents/export`, {params})
            .subscribe(file => this._fileService.checkMimeTypeAndDownloadWithAnchor(file));
    }

    public duplicateDocument(
        documentID: number,
        form: {
            code: string;
            copy_documents: boolean;
            name: string;
            state: string;
        }
    ): Promise<Document> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse & { document: Document; }>('/api/documents/' + documentID + '/duplicate', form)
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve(response.document);
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public generateFileForDocument(documentID: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/documents/' + documentID + '/generate-file', {document_ID: documentID})
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public getAllDocuments(types?: Array<string>): Observable<Array<Document>> {
        let params = null;

        if (types && types.length) {
            params = new HttpParams().set('types', types.join(','));
        }

        return this._http.get<Array<Document>>('/api/documents', {params});
    }

    public getAllDocumentItems(documentID: number): Observable<{ items: Array<DocumentItem>, source: 'ORDER' | 'DOCUMENT' }> {
        return this._http.get<{ items: Array<DocumentItem>, source: 'ORDER' | 'DOCUMENT' }>('/api/documents/' + documentID + '/items');
    }

    public getAllFilesByDocumentID(documentID: number): Observable<Array<File>> {
        return this._http.get<Array<File>>('/api/documents/' + documentID + '/files');
    }

    public getDocumentByID(documentID: number, style?: string): Observable<Document> {
        let params = null;

        if (style) {
            params = new HttpParams().set('style', style);
        }

        return this._http.get<Document>('/api/documents/' + documentID, {params});
    }

    public getAllUnsignedFiles(length: number, page: number): Observable<ListResponse<File>> {
        const params = new HttpParams().set('length', length).set('page', page);

        return this._http.get<ListResponse<File>>('/api/employees/files/unsigned', {params});
    }

    public getAvailableDocumentTemplates(employeeId: number, entityType: string = null, entityId: number = null): Observable<Array<DocumentTemplate>> {
        let params = null;
        if (entityType && entityId) {
            params = new HttpParams().set('entity_type', entityType).set('entity_id', entityId.toString());
        }
        return this._http.get<Array<DocumentTemplate>>('/api/document-templates/' + employeeId + '/get-for-employee', {params});
    }

    public saveDocumentFromTemplate(employeeId: number, data: any): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/document-templates/' + employeeId + '/generate-to-employee', data)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public getDocumentsList(params: ListParameters<Document>, filter?: any): Observable<ListResponse<Document>> {
        const p = transformParameters(params);

        if (filter) {
            p.filter = filter;
        }

        return this._http.post<ListResponse<Document>>('/api/documents/list', {...p});
    }

    public getDocumentsListCrm(params: ListParameters<Document>, filter?: Array<string>): Observable<ListResponse<Document>> {
        const p = transformParameters(params);

        if (
            filter &&
            filter.length
        ) {
            p.filter = filter.join();
        }

        return this._http.post<ListResponse<Document>>('/api/documents/list-crm', {...p});
    }

    public openDocumentDetailModal(documentHash: string, allowedDocumentSignature = false): void {
        const modalRef = this._modalService.open(DocumentDetailComponent, {centered: true, size: 'xl'});
        modalRef.componentInstance.allowedDocumentSignature = allowedDocumentSignature;
        modalRef.componentInstance.fileHash = documentHash;
    }

    public saveDocument(form: Document, documentID?: number): Promise<number> {
        this._loaderService.start();

        let apiUrl = '/api/documents';

        if (documentID) {
            apiUrl += `/${documentID}`;
        }

        return new Promise(resolve => {
            this._http.post<ApiResponse & { document_ID: number; }>(apiUrl, form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve(response.document_ID);

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public saveDocumentItem(form: DocumentItem, documentID: number, documentItemID?: number): Promise<'done'> {
        this._loaderService.start();

        let apiUrl = `/api/documents/${documentID}/items`;

        if (documentItemID) {
            apiUrl += `/${documentItemID}`;
        }

        return new Promise(resolve => {
            this._http.post<ApiResponse>(apiUrl, form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._loaderService.stop();

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public sendDocumentViaEmailInternal(documentID: number, email: string): void {
        this._http.post<ApiResponse>(`/api/documents/${documentID}/send-internal`, {email})
            .subscribe(
                response => this._apiHelper.handleSuccessResponse(response),
                error => this._apiHelper.handleErrorResponse(error)
            );
    }

    public sendDocumentViaEmailToCustomer(documentID: number, email: string): void {
        this._http.post<ApiResponse>(`/api/documents/${documentID}/send-customer`, {email})
            .subscribe(
                response => this._apiHelper.handleSuccessResponse(response),
                error => this._apiHelper.handleErrorResponse(error)
            );
    }

    public setDocumentState(documentID: number, type: string): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/documents/' + documentID + '/set-state', {type})
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }
}
