<div class="table-responsive">
    <table class="table table-centered mb-0">
        <tbody>
            <tr>
                <td>
                    <content-loader
                        [style]="{ width: '100%', height: '100px' }"
                    >
                        <svg:rect
                            height="10"
                            rx="3"
                            ry="3"
                            width="100%"
                            x="0"
                            y="0"
                        />

                        <svg:rect
                            height="10"
                            rx="3"
                            ry="3"
                            width="100%"
                            x="0"
                            y="20"
                        />

                        <svg:rect
                            height="10"
                            rx="3"
                            ry="3"
                            width="100%"
                            x="0"
                            y="40"
                        />

                        <svg:rect
                            height="10"
                            rx="3"
                            ry="3"
                            width="100%"
                            x="0"
                            y="60"
                        />

                        <svg:rect
                            height="10"
                            rx="3"
                            ry="3"
                            width="100%"
                            x="0"
                            y="80"
                        />

                        <svg:rect
                            height="10"
                            rx="3"
                            ry="3"
                            width="100%"
                            x="0"
                            y="100"
                        />
                    </content-loader>
                </td>
            </tr>
        </tbody>
    </table>
</div>
