<ng-template #chartTemplate>
    <div class="d-flex justify-content-between">
        <div>
            <label class="control-label mr-2">
                <span>{{ 'employees.show_archived' | translate }}</span>
            </label>
            <div>
                <input data-switch="success" id="show_archived" name="show_archived" type="checkbox" [ngModel]="showArchived$ | async" (ngModelChange)="showArchivedChanged($event)">
                <label for="show_archived">&nbsp;</label>
            </div>
        </div>
        <div *ngIf="employeeId">
            <label class="control-label mr-2">
                <span>{{ 'employees.hide_from_structure' | translate }}</span>
            </label>
            <div>
                <input data-switch="success" id="is_hidden_in_structure" name="is_hidden_in_structure" type="checkbox" [ngModel]="hideEmployeeFromStucture" (ngModelChange)="hideEmployeeFromStructure($event)">
                <label for="is_hidden_in_structure">&nbsp;</label>
            </div>
        </div>
        <div *ngIf="showSyncButton">
            <button class="btn btn-primary" (click)="syncByTeams()">{{ 'company_groups.sync_by_company_groups' | translate }}</button>
        </div>
    </div>

    <div class="overflow-auto">
        <app-d3-org-chart [data]="employees$ | async"></app-d3-org-chart>
    </div>
</ng-template>

<div
    *ngIf="!justChart; else chartTemplate"
    class="row"
>
    <div class="col-12">
        <div class="card shadow">
            <app-help name="employees.structure_tree"></app-help>
            <div class="row mb-0">
                <div class="col-12">
                    <ul class="nav nav-tabs nav-justified nav-bordered">
                        <li class="nav-item">
                            <a
                                aria-expanded="false"
                                class="nav-link"
                                data-toggle="tab"
                                routerLinkActive="active"
                                [routerLink]="['/company-group', 'structure-tree']"
                            >
                                <i class="mdi mdi-24px mdi-briefcase-account-outline d-block"></i>

                                <span class="d-none d-sm-block">{{ 'company_groups.page_title_structure_tree' | translate }}</span>
                            </a>
                        </li>

                        <li class="nav-item">
                            <a
                                aria-expanded="false"
                                class="nav-link"
                                data-toggle="tab"
                                routerLinkActive="active"
                                [routerLink]="['/employee', 'structure-tree']"
                            >
                                <i class="mdi mdi-24px mdi-account-outline d-block"></i>

                                <span class="d-none d-sm-block">{{ 'employees.page_title_structure_tree' | translate }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card-body">


                <ng-container [ngTemplateOutlet]="chartTemplate"></ng-container>
            </div>
        </div>
    </div>
</div>
