import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { arrayAdd, arrayRemove, arrayUpdate, cacheable } from '@datorama/akita';

import * as moment from 'moment';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';

import { EmployeeGrowth } from './employee-growth.model';
import { EmployeeGrowthStore } from './employee-growth.store';

import { EmployeeGrowthTask } from './employee-growth-task.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeGrowthService {
  public constructor(
    private _apiHelper: ApiHelper,
    private _employeeGrowthStore: EmployeeGrowthStore,
    private _http: HttpClient,
    private _swalHelper: SwalHelper
  ) { }

  public completeTask(employeeGrowthID: number | string, employeeGrowthTask: EmployeeGrowthTask): void {
    this._http.post<ApiResponse>(`/api/employees-growths/${employeeGrowthID}/tasks/${employeeGrowthTask.employee_growth_task_ID}/complete`, {})
      .subscribe(
        response => {
          this._apiHelper.handleSuccessResponse(response);

          this._employeeGrowthStore.update(
            employeeGrowthID,
            originalGrowth => {
              const allTasksCount = originalGrowth.tasks.length;
              const completedTasksCount = originalGrowth.tasks.filter(t => t.completed_at).length + 1;
              const percentProgress = (completedTasksCount / allTasksCount) * 100;

              return {
                ...originalGrowth,
                tasks: arrayUpdate(
                  originalGrowth.tasks,
                  employeeGrowthTask.employee_growth_task_ID,
                  {
                    ...employeeGrowthTask,
                    completed_at: moment().format('YYYY-MM-DD')
                  },
                  'employee_growth_task_ID'
                ),
                percent_progress: percentProgress
              };
            }
          );
        },
        error => this._apiHelper.handleErrorResponse(error)
      );
  }

  public createAndAssignGrowthToEmployee(name: string, description: string, costs: number, employeeID: number): Promise<'done'> {
    return new Promise(resolve => {
      this._http.post<ApiResponse & { employee_growth: EmployeeGrowth; }>('/api/employees/' + employeeID + '/growths', { description, name, costs })
        .subscribe(
          response => {
            this._employeeGrowthStore.add(response.employee_growth);

            this._apiHelper.handleSuccessResponse(response);

            resolve('done');
          },
          error => this._apiHelper.handleErrorResponse(error));
    });
  }

  public async deleteEmployeeGrowth(employeeGrowthID: number | string): Promise<void> {
    const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

    if (deleteConfirmed) {
      const apiUrl = `/api/employees-growths/${employeeGrowthID}`;

      this._http.delete<ApiResponse>(apiUrl)
        .subscribe(
          response => {
            this._apiHelper.handleSuccessResponse(response);

            this._employeeGrowthStore.remove(employeeGrowthID);
          },
          error => this._apiHelper.handleErrorResponse(error)
        );
    }
  }

  public async deleteTask(employeeGrowthID: number | string, employeeGrowthTaskID: number): Promise<void> {
    const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

    if (deleteConfirmed) {
      this._http.delete<ApiResponse>(`/api/employees-growths/${employeeGrowthID}/tasks/${employeeGrowthTaskID}`)
        .subscribe(
          response => {
            this._apiHelper.handleSuccessResponse(response);

            this._employeeGrowthStore.update(
              employeeGrowthID,
              originalGrowth => ({
                tasks: arrayRemove(originalGrowth.tasks, employeeGrowthTaskID, 'employee_growth_task_ID')
              })
            );
          },
          error => this._apiHelper.handleErrorResponse(error)
        );
    }
  }

  public get(employeeID: number): Observable<void> {
    const request = this._http.get<Array<EmployeeGrowth>>(`/api/employees/${employeeID}/growths`)
      .pipe(map(response => this._employeeGrowthStore.add(response)));

    return cacheable(this._employeeGrowthStore, request);
  }

  public getActiveGrowths(): Observable<Array<EmployeeGrowth>> {
    return this._http.get<Array<EmployeeGrowth>>('/api/employees-growths/active-growths');
  }

  public saveEmployeeGrowth(employeeID: number): Promise<'done'> {
    const apiUrl = '/api/employees-growths';

    return new Promise(resolve => {
      this._http.post(apiUrl, { employee_ID: employeeID })
        .subscribe(
          (response: any) => {
            this._apiHelper.handleSuccessResponse(response);

            this._employeeGrowthStore.add(response.employeeGrowth);

            resolve('done');
          },
          error => this._apiHelper.handleErrorResponse(error)
        );
    });
  }

  public saveEmployeeGrowthTask(form: UntypedFormGroup, employeeGrowth: EmployeeGrowth, employeeGrowthTask: EmployeeGrowthTask) {
    let apiUrl = `/api/employees-growths/${employeeGrowth.employee_growth_ID}/tasks`;

    if (employeeGrowthTask) {
      apiUrl += `/${employeeGrowthTask.employee_growth_task_ID}`;
    }

    this._http.post(apiUrl, form.value)
      .subscribe(
        (response: any) => {
          this._apiHelper.handleSuccessResponse(response);

          if (!employeeGrowthTask) {
            form.reset();
          }

          this._employeeGrowthStore.update(
            employeeGrowth.employee_growth_ID,
            originalGrowth => {
              if (!employeeGrowthTask) {
                const allTasksCount = originalGrowth.tasks.length;
                const completedTasksCount = originalGrowth.tasks.filter(t => t.completed_at).length - 1;
                const percentProgress = (completedTasksCount / allTasksCount) * 100;

                return {
                  ...originalGrowth,
                  tasks: arrayAdd(originalGrowth.tasks, response.task),
                  percent_progress: percentProgress
                };
              } else {
                return {
                  ...originalGrowth,
                  tasks: arrayUpdate(
                    originalGrowth.tasks,
                    employeeGrowthTask.employee_growth_task_ID,
                    {
                      ...employeeGrowthTask,
                      name: form.value.name
                    },
                    'employee_growth_task_ID'
                  ),
                };
              }
            }
          );
        },
        error => this._apiHelper.handleErrorResponse(error)
      );
  }

  public unCompleteTask(employeeGrowthID: number | string, employeeGrowthTask: EmployeeGrowthTask): void {
    this._http.post<ApiResponse>(`/api/employees-growths/${employeeGrowthID}/tasks/${employeeGrowthTask.employee_growth_task_ID}/uncomplete`, {})
      .subscribe(
        response => {
          this._apiHelper.handleSuccessResponse(response);

          this._employeeGrowthStore.update(
            employeeGrowthID,
            originalGrowth => {
              const allTasksCount = originalGrowth.tasks.length;
              const completedTasksCount = originalGrowth.tasks.filter(t => t.completed_at).length - 1;
              const percentProgress = (completedTasksCount / allTasksCount) * 100;

              return {
                ...originalGrowth,
                tasks: arrayUpdate(
                  originalGrowth.tasks,
                  employeeGrowthTask.employee_growth_task_ID,
                  {
                    ...employeeGrowthTask,
                    completed_at: null
                  },
                  'employee_growth_task_ID'
                ),
                percent_progress: percentProgress
              };
            });
        },
        error => this._apiHelper.handleErrorResponse(error)
      );
  }
}
