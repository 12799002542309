import { NgModule } from '@angular/core';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DocumentModule } from '../document/document.module';
import { NoteModule } from '../note/note.module';
import { ScheduledTaskListModule } from '../scheduled-task/scheduled-task-list.module';
import { SharedModule } from '../shared/shared.module';
import { UIButtonModule, UIDataTableModule, UIFileUploadModule, UIItemsPerPageSelectModule, UIPaginationModule, UIValueOrDashModule } from '../ui';
import { DeletePropertyModalComponent } from './components/partials/delete-property-modal/delete-property-modal.component';
import { AssignPropertyComponent } from './components/sections/assign-property/assign-property.component';
import { CategoryListComponent } from './components/sections/category-list/category-list.component';
import { CreateEditCategoryComponent } from './components/sections/create-edit-category/create-edit-category.component';
import { CreateEditPropertyComponent } from './components/sections/create-edit-property/create-edit-property.component';
import { MultiAssignUnassignPropertyComponent } from './components/sections/multi-assign-unassign-property/multi-assign-unassign-property.component';
import { PropertyListComponent } from './components/sections/property-list/property-list.component';
import { PropertyOverviewComponent } from './components/sections/property-overview/property-overview.component';
import { PropertyRoutingModule } from './property-routing.module';

@NgModule({
    declarations: [
        PropertyListComponent,
        CreateEditPropertyComponent,
        CategoryListComponent,
        CreateEditCategoryComponent,
        PropertyOverviewComponent,
        DeletePropertyModalComponent,
        AssignPropertyComponent,
        MultiAssignUnassignPropertyComponent
    ],
    imports: [
        PropertyRoutingModule,
        SharedModule,
        DocumentModule,
        ScheduledTaskListModule,
        UIButtonModule,
        UIDataTableModule,
        UIFileUploadModule,
        UIItemsPerPageSelectModule,
        UIPaginationModule,
        UIValueOrDashModule,
        AutocompleteLibModule,
        NoteModule,
        NgxSkeletonLoaderModule
    ],
    exports: [
        MultiAssignUnassignPropertyComponent
    ]
})
export class PropertyModule {}
