import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { arrayAdd, arrayRemove, arrayUpdate, cacheable } from '@datorama/akita';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { Employee } from 'src/app/employee/models/Employee';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { EmployeeGrowth } from '../../employee-growth/employee-growth.model';
import { EmployeeGrowthQuery } from '../../employee-growth/employee-growth.query';
import { EmployeeGrowthStore } from '../../employee-growth/employee-growth.store';
import { GrowthTask } from './growth-task.model';
import { Growth } from './growth.model';
import { GrowthQuery } from './growth.query';
import { GrowthStore } from './growth.store';

@Injectable({
    providedIn: 'root'
})
export class GrowthService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _employeeGrowthQuery: EmployeeGrowthQuery,
        private _employeeGrowthStore: EmployeeGrowthStore,
        private _growthStore: GrowthStore,
        private _growthQuery: GrowthQuery,
        private _http: HttpClient,
        private _modalService: NgbModal,
        private _router: Router,
        private _swalHelper: SwalHelper
    ) { }

    public assignEmployeesToGrowth(
        growth: Growth,
        employeesIDs: Array<number>
    ): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            this._http.post<ApiResponse>(`/api/growths/${growth.growth_ID}/employees/assign`, {employeesIDs})
                .subscribe(
                    response => {
                        this._growthStore.update(
                            growth.growth_ID,
                            growth
                        );

                        this._modalService.dismissAll();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve(true);
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        reject('error');
                    }
                );
        });
    }

    public assignGrowthsToEmployee(growthIDs: Array<number>, employeeID: number): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<ApiResponse & { employeeGrowths: Array<EmployeeGrowth>; }>('/api/employees/' + employeeID + '/growths/assign', {growthIDs})
                .subscribe(
                    response => {
                        this._employeeGrowthStore.add(response.employeeGrowths);

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        });
    }

    public async deleteGrowth(growthID: number | string): Promise<void> {
        const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

        if (deleteConfirmed) {
            this._http.delete<ApiResponse>(`/api/growths/${growthID}`, {})
                .subscribe(
                    response => {
                        this._growthStore.remove(growthID);

                        this._apiHelper.handleSuccessResponse(response);

                        this._router.navigate(['/growth', 'list']);
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        }
    }

    public async deleteGrowthTask(growthID: number | string, growthTaskID: number | string, modalRef: NgbActiveModal): Promise<void> {
        const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

        if (deleteConfirmed) {
            this._http.delete<ApiResponse>(`/api/growths/${growthID}/tasks/${growthTaskID}`, {})
                .subscribe(
                    response => {
                        this._growthStore.update(
                            growthID,
                            originalGrowth => ({
                                ...originalGrowth,
                                tasks: arrayRemove(originalGrowth.tasks, growthTaskID, 'growth_task_ID')
                            })
                        );

                        modalRef.close();

                        this._apiHelper.handleSuccessResponse(response);
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        }
    }

    public get(): Observable<void> {
        const request = this._http.get<Array<Growth>>('/api/growths')
            .pipe(map(response => this._growthStore.add(response)));

        return cacheable(this._growthStore, request);
    }

    public getEmployeesForGrowth(growthID: number | string): Observable<Array<Employee>> {
        return this._http.get<Array<Employee>>(`/api/growths/${growthID}/employees`);
    }

    public getGrowthByID(growthID: number | string): Observable<Growth> {
        return this._http.get<Growth>(`/api/growths/${growthID}`);
    }

    public getGrowthsList(params: ListParameters<Growth>): Observable<ListResponse<Growth>> {
        return this._http.post<ListResponse<Growth>>('/api/growths/list', {...transformParameters(params)});
    }

    public saveGrowth(form: Growth, modalRef: NgbActiveModal, growthID?: number | string): void {
        let apiUrl = '/api/growths';

        if (growthID) {
            apiUrl += `/${growthID}`;
        }

        this._http.post<ApiResponse & { growth: Growth; }>(apiUrl, form)
            .subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);

                    if (!growthID) {
                        this._growthStore.add(response.growth);
                    } else {
                        this._growthStore.update(growthID, response.growth);
                    }

                    modalRef.close();
                },
                error => this._apiHelper.handleErrorResponse(error)
            );
    }

    public saveGrowthTask(
        form: GrowthTask,
        growthID: string | number,
        modalRef: NgbActiveModal,
        growthTaskID?: number | string
    ): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let apiUrl = `/api/growths/${growthID}/tasks`;

            if (growthTaskID) {
                apiUrl += `/${growthTaskID}`;
            }

            this._http.post<ApiResponse & { growth_task: GrowthTask; }>(apiUrl, form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        if (growthTaskID) {
                            this._growthStore.update(
                                growthID,
                                originalGrowth => ({
                                    tasks: arrayUpdate(
                                        originalGrowth.tasks,
                                        growthTaskID,
                                        response.growth_task,
                                        'growth_task_ID'
                                    )
                                })
                            );
                        } else {
                            this._growthStore.update(
                                growthID,
                                originalGrowth => ({
                                    tasks: arrayAdd(originalGrowth.tasks, response.growth_task)
                                })
                            );
                        }

                        if (modalRef) {
                            modalRef.close();
                        }

                        resolve(true);
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        reject('error');
                    }
                );
        });
    }

    public async unAssignEmployeeFromGrowth(growthID: number | string, employeeID: number): Promise<void> {
        const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

        if (deleteConfirmed) {
            this._http.post<ApiResponse>(`/api/growths/${growthID}/employees/unassign`, {employee_ID: employeeID})
                .subscribe(
                    response => {
                        this._growthStore.update(
                            growthID,
                            growth => ({
                                employees: arrayRemove(growth.employees, employeeID, 'employee_ID')
                            })
                        );

                        this._apiHelper.handleSuccessResponse(response);
                        this._modalService.dismissAll();
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        }
    }

    public async unassignGrowthFromEmployee(employeeGrowth: EmployeeGrowth, employeeID: number): Promise<void> {
        const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

        if (deleteConfirmed) {
            this._http.post<ApiResponse>(`/api/growths/${employeeGrowth.growth_ID}/employees/unassign`, {employee_ID: employeeID})
                .subscribe(
                    response => {
                        this._growthStore.update(
                            employeeGrowth.growth_ID,
                            growth => ({
                                employees: arrayRemove(growth.employees, employeeID, 'employee_ID')
                            })
                        );

                        if (this._employeeGrowthQuery.hasEntity(employeeGrowth.employee_growth_ID)) {
                            this._employeeGrowthStore.remove(employeeGrowth.employee_growth_ID);
                        }

                        this._apiHelper.handleSuccessResponse(response);
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        }
    }
}
