<div class="modal-content">
	<div class="modal-header">
			<p *ngIf="numberSerieID" class="modal-title font-16 mb-0 text-dark">{{'numbers_series.page_title_edit' | translate}}</p>
			<p *ngIf="!numberSerieID" class="modal-title font-16 mb-0 text-dark">{{'numbers_series.page_title_create' | translate}}</p>
		<button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
			<span aria-hidden="true" class="font-24 text-muted"><i class="mdi mdi-close"></i></span>
		  </button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-0 shadow-none">
					<div class="card-body p-0">
						<form (ngSubmit)="onSubmit()" [formGroup]="seriesEditForm" autocomplete="off" name="seriesEditForm">
							<div class="row">
								<div class="col-lg-6">
									<div class="form-group">
										<label class="control-label">{{'numbers_series.name' | translate}}</label>
										<input class="form-control"
											formControlName="name" name="name" type="text">
										<div *ngIf="submitted && f.name.errors">
											<p *ngIf="f.name.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'properties.name' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="form-group">
										<label class="control-label">{{'numbers_series.default_for' | translate}}</label>
										<ng-select [clearable]="false" class="custom" formControlName="default_for" name="default_for">
											<ng-option value="Order">{{ 'numbers_series.orders' | translate }}</ng-option>
											<ng-option value="Document-INVOICE">{{ 'numbers_series.issued_invoices' | translate }}</ng-option>
											<ng-option value="Document-INVOICE_ACCEPTED">{{ 'numbers_series.accepted_nvoices' | translate }}</ng-option>
											<ng-option value="Project">{{ 'numbers_series.projects' | translate }}</ng-option>
											<ng-option value="Document-CASH_RECEIPT">{{ 'numbers_series.income_documents' | translate }}</ng-option>
										</ng-select>
										<div *ngIf="submitted && f.default_for.errors">
											<p *ngIf="f.default_for.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }}
												{{'properties.default_for' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-sm-5 align-self-center">
									<div class="form-group">
										<label class="control-label mb-0">{{ 'numbers_series.prefix' | translate }}</label>
									</div>
								</div>
								<div class="col-12 col-sm-7 text-right">
									<div class="form-group">
										<input class="form-control form-control-prefix" formControlName="prefix" name="prefix" type="text">
										<div *ngIf="submitted && f.prefix.errors">
											<p *ngIf="f.prefix.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'numbers_series.prefix' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
									<div class="col-12 col-sm-5 align-self-center">
										<div class="form-group">
											<label class="control-label mb-0">{{ 'numbers_series.year_format' | translate }}</label>
										</div>
									</div>
								<div class="col-12 col-sm-7 text-left text-sm-right">
										<div class="btn-group btn-group-sm" role="group">
											<input autocomplete="off" class="btn-check" formControlName="year_format" id="%Y%" name="year_format" type="radio" value="%Y%">
											<label class="btn btn-outline-primary btn-link btn-sm" for="%Y%">2021</label>
											<input autocomplete="off" class="btn-check" formControlName="year_format" id="%y%" name="year_format" type="radio" value="%y%">
											<label class="btn btn-outline-primary btn-link btn-sm" for="%y%">21</label>
											<input autocomplete="off" class="btn-check" formControlName="year_format" id="EMPTY" name="year_format" type="radio" value="EMPTY">
											<label class="btn btn-outline-primary btn-link btn-sm" for="EMPTY">{{ 'numbers_series.empty' | translate }}</label>
										  </div>
								</div>
							</div>
							<div class="row">
									<div class="col-12 col-sm-5 align-self-center">
										<div class="form-group">
											<label class="control-label mb-0">{{ 'numbers_series.number_of_invoices_per_year' | translate }}</label>
										</div>
									</div>
								<div class="col-12 col-sm-7 text-left text-sm-right">
										<div class="btn-group btn-group-sm" role="group">
											<input autocomplete="off" class="btn-check" formControlName="count" id="%NMB_3%" name="count" type="radio" value="%NMB_3%">
											<label class="btn btn-outline-primary btn-link btn-sm" for="%NMB_3%">100</label>

											<input autocomplete="off" class="btn-check" formControlName="count" id="%NMB_4%" name="count" type="radio" value="%NMB_4%">
											<label class="btn btn-outline-primary btn-link btn-sm" for="%NMB_4%">1000</label>

											<input autocomplete="off" class="btn-check" formControlName="count" id="%NMB_5%" name="count" type="radio" value="%NMB_5%">
											<label class="btn btn-outline-primary btn-link btn-sm" for="%NMB_5%">10000</label>
										  </div>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-sm-5 align-self-center">
									<div class="form-group">
										<label class="control-label mb-0">{{ 'numbers_series.markup_preview' | translate }}</label>
									</div>
								</div>
								<div class="col-12 col-sm-7 text-left text-sm-right">
									<span class="text-dark">{{numberSeriePreview}}</span>
								</div>
							</div>
							<p *ngIf="showNumberSerieChangeWarning" class="text-muted">
								{{ 'numbers_series.no_recount_alert' | translate }}
							</p>
							<div class="row mt-2">

								<div class="col-6 text-left">
									<button class="btn btn-success mr-2"
										type="submit">{{'numbers_series.action_save' | translate}}</button>

								</div>
								<div class="col-6 text-right my-auto">
									<u (click)="deleteNumberSeries(numberSerieID)" *ngIf="numberSerieID" class="text-muted">{{'numbers_series.action_delete' | translate}}</u>

								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>









