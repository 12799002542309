<div class="modal-content">
  <div class="modal-header">
    <p
      *ngIf="!scheduledTask"
      class="modal-title font-16 mb-0 text-dark"
    >{{ 'scheduled_task.page_title_create' | translate }}</p>

    <p
      *ngIf="scheduledTask"
      class="modal-title font-16 mb-0 text-dark"
    >{{ 'scheduled_task.page_title_edit' | translate }}</p>

    <button
      (click)="activeModal.dismiss()"
      aria-label="Close"
      class="close"
    >
      <span
        aria-hidden="true"
        class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <form
              (ngSubmit)="onSubmit()"
              [formGroup]="form"
              autocomplete="off"
            >
              <div
                *ngIf="hideType === false"
                class="row"
              >
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'scheduled_task.type' | translate }}</span>
                    </label>

                    <ng-select
                      [clearable]="false"
                      [placeholder]="'scheduled_task.type_select' | translate"
                      class="custom"
                      formControlName="type"
                      name="type"
                    >
                      <ng-option value="DUPLICATION">
                        <span>{{ 'scheduled_task.type_DUPLICATION' | translate }}</span>
                      </ng-option>

                      <ng-option value="NOTIFICATION">
                        <span>{{ 'scheduled_task.type_NOTIFICATION' | translate }}</span>
                      </ng-option>
                    </ng-select>

                    <div *ngIf="submitted && f.type.errors">
                      <p
                        *ngIf="f.type.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'scheduled_task.type' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'scheduled_task.started_at' | translate }}</span>
                    </label>

                    <input
                      (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                      [locale]="locale$ | async"
                      allowInput="true"
                      altFormat="d.m.Y"
                      altInput="true"
                      class="form-control"
                      dateFormat="Y-m-d"
                      formControlName="started_at"
                      mwlFlatpickr
                      name="started_at"
                      type="text"
                    >

                    <div *ngIf="submitted && f.started_at.errors">
                      <p
                        *ngIf="f.started_at.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'scheduled_task.started_at' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'scheduled_task.ended_at' | translate }}</span>
                    </label>

                    <input
                      (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                      [locale]="locale$ | async"
                      allowInput="true"
                      altFormat="d.m.Y"
                      altInput="true"
                      class="form-control"
                      dateFormat="Y-m-d"
                      formControlName="ended_at"
                      mwlFlatpickr
                      name="ended_at"
                      type="text"
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'scheduled_task.name' | translate }}</span>
                    </label>

                    <input
                      class="form-control"
                      formControlName="name"
                      name="name"
                      type="text"
                    >

                    <div *ngIf="submitted && f.name.errors">
                      <p
                        *ngIf="f.name.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'scheduled_task.name' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'scheduled_task.description' | translate }}</span>
                    </label>

                    <input
                      [ngxSummernote]="summernoteHelper.getSummernoteConfig()"
                      class="form-control"
                      formControlName="description"
                      name="description"
                      rows="5"
                      type="textarea"
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'scheduled_task.notification_channel' | translate }}</span>
                    </label>

                    <ng-select
                      [clearable]="false"
                      [placeholder]="'scheduled_task.notification_channel_select' | translate"
                      class="custom"
                      formControlName="target"
                      name="target"
                    >
                      <ng-option value="mail">
                        <span>{{ 'scheduled_task.notification_channel_EMAIL' | translate }}</span>
                      </ng-option>

                      <ng-option value="database">
                        <span>{{ 'scheduled_task.notification_channel_IN_APP' | translate }}</span>
                      </ng-option>

                      <ng-option value="both">
                        <span>{{ 'scheduled_task.notification_channel_ALL' | translate }}</span>
                      </ng-option>
                    </ng-select>

                    <div *ngIf="submitted && f.target.errors">
                      <p
                        *ngIf="f.target.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'scheduled_task.notification_channel' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngIf="hideEntitySelection === false"
                class="row"
              >
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'scheduled_task.schedulable_type' | translate }}</span>
                    </label>

                    <ng-select
                      [placeholder]="'scheduled_task.schedulable_type_select' | translate"
                      class="custom"
                      formControlName="schedulable_type"
                      name="schedulable_type"
                    >
                      <ng-option value="Document">
                        <span>{{ 'scheduled_task.schedulable_type_DOCUMENT' | translate }}</span>
                      </ng-option>

                      <ng-option value="Property">
                        <span>{{ 'scheduled_task.schedulable_type_PROPERTY' | translate }}</span>
                      </ng-option>

                      <ng-option value="Order">
                        <span>{{ 'scheduled_task.schedulable_type_ORDER' | translate }}</span>
                      </ng-option>

                      <ng-option value="Employee">
                        <span>{{ 'scheduled_task.schedulable_type_EMPLOYEE' | translate }}</span>
                      </ng-option>
                    </ng-select>

                    <div *ngIf="submitted && f.schedulable_type.errors">
                      <p
                        *ngIf="f.schedulable_type.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'scheduled_task.schedulable_type' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'scheduled_task.schedulable_entity' | translate }}</span>
                    </label>

                    <ng-select
                      [clearable]="false"
                      [items]="entities$ | async"
                      [loading]="entitiesLoading$ | async"
                      [placeholder]="(f.schedulable_type.value ? 'scheduled_task.schedulable_entity_select' : 'scheduled_task.schedulable_type_select') | translate"
                      bindLabel="name"
                      bindValue="value"
                      class="custom"
                      formControlName="schedulable_id"
                      name="schedulable_id"
                    ></ng-select>

                    <div *ngIf="submitted && f.schedulable_id.errors">
                      <p
                        *ngIf="f.schedulable_id.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'scheduled_task.schedulable_entity' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <fieldset class="row mb-2">
                <legend>{{ 'scheduled_task.frequency_and_interval_group' | translate }}</legend>

                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'scheduled_task.interval' | translate }}</span>
                    </label>

                    <input
                      class="form-control"
                      formControlName="interval"
                      min="1"
                      name="interval"
                      type="number"
                    >

                    <div *ngIf="submitted && f.interval.errors">
                      <p
                        *ngIf="f.interval.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'scheduled_task.interval' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                      <p
                          *ngIf="f.interval.errors.min"
                          class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'scheduled_task.interval' | translate }} </span>
                        <span>{{ 'angular_validation.cannot_be_lower' | translate }}</span>
                        <span> 1 </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'scheduled_task.frequency' | translate }}</span>
                    </label>

                    <ng-select
                      [clearable]="false"
                      [placeholder]="'scheduled_task.frequency_select' | translate"
                      class="custom"
                      formControlName="frequency"
                      name="frequency"
                    >
                      <ng-option value="ONE_TIME">
                        <span>{{ 'scheduled_task.frequency_ONE_TIME' | translate }}</span>
                      </ng-option>

                      <ng-option value="DAILY">
                        <span>{{ 'scheduled_task.frequency_DAILY' | translate }}</span>
                      </ng-option>

                      <ng-option value="WEEKLY">
                        <span>{{ 'scheduled_task.frequency_WEEKLY' | translate }}</span>
                      </ng-option>

                      <ng-option value="MONTHLY">
                        <span>{{ 'scheduled_task.frequency_MONTHLY' | translate }}</span>
                      </ng-option>

                      <ng-option value="YEARLY">
                        <span>{{ 'scheduled_task.frequency_YEARLY' | translate }}</span>
                      </ng-option>
                    </ng-select>

                    <div *ngIf="submitted && f.frequency.errors">
                      <p
                        *ngIf="f.frequency.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'scheduled_task.frequency' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div *ngIf="form.value.frequency && form.value.interval" class="col-12">
                  <p class="text-muted">
                    <span>{{ 'scheduled_task.will_be_scheduled' | translate }} </span>
                    <span>1x </span>
                    <span>{{ 'scheduled_task.by' | translate }} </span>
                    <span *ngIf="form.value.interval === 1">{{ 'scheduled_task.frequency_' + form.value.frequency + '_1' | translate }}</span>
                    <span *ngIf="form.value.interval > 1 && form.value.interval < 5"> {{ form.value.interval }} {{ 'scheduled_task.frequency_' + form.value.frequency + '_2' | translate }}</span>
                    <span *ngIf="form.value.interval > 4">{{ form.value.interval }} {{ 'scheduled_task.frequency_' + form.value.frequency + '_3' | translate }}</span>
                  </p>
                </div>
              </fieldset>

              <div class="row mt-2">
                <div class="col d-flex justify-content-end">
                  <button (click)="deleteScheduledTask()" *ngIf="scheduledTask" class="btn btn-link text-muted pr-0 mr-4" type="button">
                    <u>{{ 'scheduled_task.action_delete' | translate }}</u>
                  </button>
                  <button class="btn btn-success" type="submit">
                    {{ 'scheduled_task.action_save' | translate }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
