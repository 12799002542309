import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeDateTime } from 'src/app/employee/models/EmployeeDateTime';
import { AttendanceService } from 'src/app/employee/services/attendance.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { CreateEditEmployeeDatetimeModalComponent } from '../../partials/create-edit-employee-datetime-modal/create-edit-employee-datetime-modal.component';

@Component({
    selector: 'app-employee-attendance-detail',
    templateUrl: './employee-attendance-detail.component.html',
    styleUrls: ['./employee-attendance-detail.component.css']
})
export class EmployeeAttendanceDetailComponent implements OnInit, OnDestroy {
    public employee: Employee;
    public employeeDateTime: EmployeeDateTime;
    public employeeDateTimeID: number;
    public employeeID: number;
    public routeParamsSubscription: Subscription;

    public constructor(
        public authService: AuthenticationService,
        private _attendanceService: AttendanceService,
        private _modalService: NgbModal,
        private _route: ActivatedRoute,
    ) { }

    public ngOnDestroy(): void {
        this.routeParamsSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.employeeDateTimeID = parseInt(this._route.snapshot.paramMap.get('dateTimeID'), 10);

        this.employeeID = parseInt(this._route.snapshot.paramMap.get('id'), 10);

        this.routeParamsSubscription = this._route.params
            .pipe(
                map(params => parseInt(params.dateTimeID, 10)),
                switchMap(dateTimeID => {
                    if (dateTimeID) {
                        return this._attendanceService.getEmployeeDateTimeByID(this.employeeID, dateTimeID, 'extend');
                    } else {
                        return of(null);
                    }
                })
            )
            .subscribe(datetime => {
                this.employeeDateTime = datetime;
            });

        if (this.employeeDateTimeID) {
            this.getDateTime();
        }
    }

    public approveDatetime(): void {
        this._attendanceService.saveEmployeeDateTimeState({state: 'approved'} as EmployeeDateTime, this.employeeDateTimeID)
            .then(() => this.getDateTime());
    }

    public declineDatetime(): void {
        this._attendanceService.saveEmployeeDateTimeState({state: 'denied'} as EmployeeDateTime, this.employeeDateTimeID)
            .then(() => this.getDateTime());
    }

    public getDateTime(): void {
        this._attendanceService.getEmployeeDateTimeByID(this.employeeID, this.employeeDateTimeID, 'extend')
            .subscribe(datetime => {
                this.employeeDateTime = datetime;

                this.employee = datetime.employee;
            });
    }

    public openDatetimeEditModal(): void {
        const modalRef = this._modalService.open(CreateEditEmployeeDatetimeModalComponent, {centered: true});

        modalRef.componentInstance.employeeDateTime = this.employeeDateTime;

        modalRef.componentInstance.employeeID = this.employee?.employee_ID;

        modalRef.result
            .then(
                () => this.getDateTime(),
                () => { }
            );
    }
}
