import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/core/services/authentication.service';

import { EmployeeAccess } from 'src/app/employee/models/EmployeeAccess';
import { AccessService } from 'src/app/employee/services/access.service';

import { CreateEmployeeAccessModalComponent } from '../create-employee-access-modal/create-employee-access-modal.component';

@Component({
  selector: 'app-employee-access',
  templateUrl: './employee-access.component.html',
  styleUrls: ['./employee-access.component.css']
})
export class EmployeeAccessComponent implements OnInit {
  public accesses$: Observable<Array<EmployeeAccess>>;

  public canEditAccess$: Observable<boolean>;

  public employeeAccessIDs: Array<number> = [];

  public loading$: Observable<boolean>;

  private _employeeID$: Observable<number>;

  private _fetchEmployeeAccesses$ = new ReplaySubject<void>(1);

  private _loading$ = new BehaviorSubject<boolean>(true);

  public constructor(
    private _authService: AuthenticationService,
    private _accessService: AccessService,
    private _modalService: NgbModal,
    private _route: ActivatedRoute
  ) { }


  public ngOnInit(): void {
    this.canEditAccess$ = this._authService.hasPermissionTo('employeeAccess.edit').pipe(map(permission => permission.can));

    this.loading$ = this._loading$;

    this._employeeID$ = this._route.parent.params
      .pipe(map(params => parseInt(params.id, 10)));

    this.accesses$ = this._fetchEmployeeAccesses$
      .pipe(
        tap(() => this._loading$.next(true)),
        withLatestFrom(this._employeeID$),
        switchMap(([noop, id]) => this._accessService.getAllAccessesByEmployeeID(id)),
        tap(() => this._loading$.next(false)),
      );

    this._fetchEmployeeAccesses$.next();
  }

  public openEditCreateModal(access?: EmployeeAccess): void {
    this._employeeID$
      .pipe(first())
      .subscribe(employeeID => {
        const modalRef = this._modalService.open(CreateEmployeeAccessModalComponent, { centered: true });

        modalRef.componentInstance.access = access;
        modalRef.componentInstance.employeeID = employeeID;

        modalRef.closed
          .subscribe(() => this._fetchEmployeeAccesses$.next());
      });
  }

  public trackByAccess(index: number, employeeAccess: EmployeeAccess): number {
    return employeeAccess.employee_access_ID;
  }
}
