import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Employee } from 'src/app/employee/models/Employee';

import { CompanyEmployeePolicy } from 'src/app/policy/models/CompanyEmployeePolicy';
import { CompanyEmployeePolicyAdjustement } from 'src/app/policy/models/CompanyEmployeePolicyAdjustement';
import { PolicyService } from 'src/app/policy/services/policy.service';

import { CreateEditEmployeeAdjustementModalComponent } from '../create-edit-employee-adjustement-modal/create-edit-employee-adjustement-modal.component';

@Component({
    selector: 'app-employee-policies',
    templateUrl: './employee-policies.component.html',
    styleUrls: ['./employee-policies.component.css']
})
export class EmployeePoliciesComponent implements OnInit {
    @Input()
    public employee: Employee;

    @Input()
    public employeeAdjustments$: Observable<Array<CompanyEmployeePolicyAdjustement>>;

    @Input()
    public set employeePolicy$(employeePolicy: Observable<CompanyEmployeePolicy>) {
        this._employeePolicy$ = employeePolicy
            .pipe(
                tap(policy => {
                    this.addPolicyToEmployeeForm.patchValue({
                        company_employee_policy_ID: policy?.company_employee_policy_ID
                    });
                })
            );
    }

    public get employeePolicy$(): Observable<CompanyEmployeePolicy> {
        return this._employeePolicy$;
    }

    @Output()
    public refetchAdjustments = new EventEmitter<string>();

    @Output()
    public refetchPolicy = new EventEmitter<string>();

    public addPolicyToEmployeeForm: UntypedFormGroup;

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.addPolicyToEmployeeForm.controls;
    }

    public submitted = false;

    private _employeePolicy$: Observable<CompanyEmployeePolicy>;

    public constructor(
        public policyService: PolicyService,
        private _fb: UntypedFormBuilder,
        private _modalService: NgbModal
    ) { }

    public ngOnInit(): void {
        this.addPolicyToEmployeeForm = this._fb.group({
            company_employee_policy_ID: [null, Validators.required]
        });

    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.addPolicyToEmployeeForm.valid) {
            return;
        }

        this.policyService.savePolicytoEmployee(this.employee.employee_ID, this.addPolicyToEmployeeForm.get('company_employee_policy_ID').value)
            .then(() => {
                this.refetchPolicy.emit('refetch');
                this._modalService.dismissAll();
            });
    }

    public openAdjustmentEditModal(adjustment: CompanyEmployeePolicyAdjustement): void {
        const modalRef = this._modalService.open(CreateEditEmployeeAdjustementModalComponent, {centered: true});

        modalRef.componentInstance.employeeID = this.employee.employee_ID;

        if (adjustment) {
            modalRef.componentInstance.companyEmployeeAdjustementID = adjustment.employee_policy_adjustement_ID;

            modalRef.componentInstance.companyEmployeeAdjustement = adjustment;
        }

        modalRef.result
            .then(
                () => this.refetchAdjustments.emit('refetch'),
                () => { }
            );
    }

    public openPolicyAssignModal(content): void {
        this._modalService.open(content, {centered: true});
    }
}
