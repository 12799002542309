<div class="modal-content">
	<div class="modal-header">
        <p *ngIf="!employeeContactPerson" class="modal-title font-16 text-dark">{{'employees_contact_persons.page_title_create' | translate}}</p>
        <p *ngIf="employeeContactPerson" class="modal-title font-16 text-dark">{{'employees_contact_persons.page_title_edit' | translate}}</p>
		<button (click)="activeModal.dismiss()" type="button" class="close" aria-label="Close">
			<span class="font-24 text-muted" aria-hidden="true"><i class="mdi mdi-close"></i></span>
		  </button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-0 shadow-none">
					<div class="card-body p-0">
                        <app-contact-person-form #childRef
                                                 [submitted]="submitted"
                                                 [employeeContactPerson]="employeeContactPerson">
                        </app-contact-person-form>
                        <div *ngIf="hasRequestEditPermission && !canEdit" class="row">
                            <div class="col-12 text-right">
                                <p class="text-muted mb-1"><small> {{'employees_addresses.request_will_be_sent' | translate}} </small></p>
                            </div>
                        </div>
                        <div class="row justify-content-end mt-2">
                            <div class="col-6 text-right my-auto">
                                <u *ngIf="employeeContactPerson" (click)="deleteContactPerson()" class="text-muted">{{'employees_contact_persons.action_delete'  | translate}}</u>
                            </div>
                            <div class="col-6 text-right">
                                <button (click)="onSubmit()" [disabled]="updateRequested && !canEdit"  class="btn btn-success">{{'employees_contact_persons.action_edit' | translate}}</button>
                            </div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
