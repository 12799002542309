import { NgModule } from '@angular/core';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SharedModule } from '../shared/shared.module';
import { UIButtonModule, UIDataTableModule, UIItemsPerPageSelectModule, UIPaginationModule } from '../ui';
import { TagModalComponent } from './components/partials/tag-modal/tag-modal.component';
import { TagListComponent } from './components/sections/tag-list/tag-list.component';
import { TagRoutingModule } from './tag-routing.module';

@NgModule({
    declarations: [
        TagListComponent,
        TagModalComponent,
    ],
    imports: [
        TagRoutingModule,
        SharedModule,
        UIDataTableModule,
        UIItemsPerPageSelectModule,
        UIPaginationModule,
        UIButtonModule,
        AutocompleteLibModule,
    ],
    exports: [
        TagListComponent,
    ]
})
export class TagModule {}
