<div
    *ngIf="employeeDateTime"
    class="row"
>
    <div class="col-lg-6 mb-3">
        <div class="card h-100 shadow">
            <app-help name="attendance.detail.info"></app-help>
            <div class="card-header py-3">
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-7">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">
                            <span>{{ 'menu.employees_datetimes' | translate }}</span>
                        </p>
                    </div>

                    <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
                        <button
                            [updateButton]="'employees_datetimes.action_edit' | translate"
                            (click)="openDatetimeEditModal()"
                        ></button>
                    </div>
                </div>
            </div>

            <div class="card-body px-4">
                <div class="row border-bottom">
                    <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
                        <span class="text-dark">{{ 'employees_datetimes.type' | translate }}</span>
                    </div>

                    <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
                        <span>{{ employeeDateTime.type.name }}</span>
                    </div>
                </div>

                <div class="row border-bottom">
                    <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
                        <span class="text-dark">{{ 'employees_datetimes.state' | translate }}</span>
                    </div>

                    <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
                        <span class="text-{{ employeeDateTime.current_state_class }}">
                          <span>{{ employeeDateTime.current_state_name }}</span>
                        </span>
                    </div>
                </div>

                <div *ngIf="authService.loggedUser?.attendance_projects?.active" class="row border-bottom">
                    <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
                        <span class="text-dark">{{ 'employees_datetimes.project' | translate }}</span>
                    </div>

                    <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
                        <span>{{ employeeDateTime.employee_datetime_project_name }}</span>
                    </div>
                </div>

                <div class="row border-bottom">
                    <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
                        <span class="text-dark">{{ 'employees_datetimes.from' | translate }}</span>
                    </div>

                    <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2 text-truncate">
                        <span>{{ employeeDateTime?.from | date:'dd.MM.yyyy HH:mm' }}</span>
                    </div>
                </div>

                <div class="row border-bottom">
                    <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
                        <span class="text-dark">{{ 'employees_datetimes.to' | translate }}</span>
                    </div>

                    <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
                        <span>{{ employeeDateTime?.to | date:'dd.MM.yyyy HH:mm' }}</span>
                    </div>
                </div>

                <div class="row border-bottom">
                    <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
                        <span class="text-dark">{{ 'employees_datetimes.hours_duration' | translate }}</span>
                    </div>

                    <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
                        <span>{{ employeeDateTime?.hours_duration }} {{ 'employees_datetimes.hours' | translate }}</span>
                    </div>
                </div>

                <div class="row border-bottom">
                    <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
                        <span class="text-dark">{{ 'employees_datetimes.action_set_on_shift_start_end' | translate }}</span>
                    </div>

                    <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
                        <span *ngIf="employeeDateTime.set_on_shift">{{ 'global.YES' | translate }}</span>
                        <span *ngIf="!employeeDateTime.set_on_shift">{{ 'global.NO' | translate }}</span>
                    </div>
                </div>

                <div class="row border-bottom">
                    <div class="col-12 col-sm-12 col-md-5 py-1 py-md-2">
                        <span class="text-dark">{{ 'employees_datetimes.waiting_to_approve_from' | translate }}</span>
                    </div>

                    <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
                        <span>{{ employeeDateTime.owner_fullname }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6 mb-3">
        <div
            *ngIf="employeeDateTime.permissions.can_set_state"
            class="row"
        >
            <div class="col-12 mb-3">
                <div class="card h-100 justify-content-center shadow">
                    <app-help name="attendance.detail.action.approve"></app-help>
                    <div class="card-header border-bottom-0 py-3">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-7">
                                <p class="modal-title font-16 text-dark pt-2 pb-2">
                                    <span>{{ 'employees_datetimes.action_approve' | translate }}</span>
                                </p>
                            </div>

                            <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
                                <button
                                    [checkButton]="'employees_datetimes.action_approve' | translate"
                                    [disabled]="employeeDateTime?.state === 'APPROVED'"
                                    (click)="approveDatetime()"
                                ></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 mb-3">
                <div class="card h-100 justify-content-center shadow">
                    <app-help name="attendance.detail.action.deny"></app-help>
                    <div class="card-header border-bottom-0 py-3">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-7">
                                <p class="modal-title font-16 text-dark pt-2 pb-2">
                                    <span>{{ 'employees_datetimes.action_deny' | translate }}</span>
                                </p>
                            </div>

                            <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
                                <button
                                    [denyButton]="'employees_datetimes.action_deny' | translate"
                                    [disabled]="employeeDateTime?.state === 'DENIED'"
                                    (click)="declineDatetime()"
                                ></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="card h-100 justify-content-center shadow">
                    <app-help name="attendance.detail.history"></app-help>
                    <div class="card-header px-4">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">
                            <span>{{ 'employees_datetimes.states_history' | translate }}</span>
                        </p>
                    </div>

                    <div class="card-body border-bottom-0 py-3">
                        <small
                            *ngFor="let stateHistoryItem of employeeDateTime.states_history"
                            class="pb-1 my-auto"
                        >
                            <span>{{ stateHistoryItem?.created_at_formatted }}&nbsp;→&nbsp;</span>
                            <span class="text-dark">{{ stateHistoryItem?.author_fullname }}</span>
                            <span> {{ 'employees_datetimes.person_changed_state_to' | translate }}:&nbsp;</span>
                            <span class="text-{{ stateHistoryItem.state_class }}">
                <i class="{{ stateHistoryItem.state_icon }}"></i>
                <span>&nbsp;{{ stateHistoryItem?.state_name }}</span>
              </span>
                            <br>
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="employeeDateTime; else loadingTemplate">
    <div class="row">
        <div class="col-12">
            <div class="card shadow">
                <app-help name="attendance.detail.report"></app-help>
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-7">
                            <p class="modal-title font-16 text-dark pt-2 pb-2">
                                <span>{{ 'employees_datetimes.report' | translate }}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card-body px-4">
                    <ng-container *ngIf="employeeDateTime.report; else noDataTemplate">
                        <p
                            *ngIf="employeeDateTime?.report as report; else noDataTemplate"
                            [innerHtml]="report | safeHtml"
                        ></p>
                    </ng-container>

                    <ng-template #noDataTemplate>
                        <div class="text-center text-muted">{{ 'global.empty_widget_error' | translate }}</div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loadingTemplate>
    <app-row-loader></app-row-loader>
</ng-template>

<div class="row">
    <div class="col-12">
        <app-employee-attendance-parts
            *ngIf="employeeDateTime"
            [employeeDateTime]="employeeDateTime"
            [employeeID]="employeeID"
            (refetchDateTime)="getDateTime()"
        ></app-employee-attendance-parts>
    </div>
</div>
