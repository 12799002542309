import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { CustomField } from '../../../../shared/models/CustomField';
import { CustomFieldService } from '../../../services/custom-field.service';
import { CreateEditCustomFieldModalComponent } from '../create-edit-custom-field-modal/create-edit-custom-field-modal.component';

@Component({
    selector: 'app-custom-field-list',
    templateUrl: './custom-field-list.component.html',
    styleUrls: ['./custom-field-list.component.css']
})
export class CustomFieldListComponent implements OnInit {
    @Output() public setDefinitions = new EventEmitter<CustomField[]>();

    public customFields$: Observable<CustomField[]>;
    public canCreate = false;

    public constructor(
        protected _changeDetectorRef: ChangeDetectorRef,
        private _customFieldService: CustomFieldService,
        private _authService: AuthenticationService,
        private _modalService: NgbModal,
    ) { }

    public ngOnInit(): void {
        this.canCreate = this._authService.checkPermission('customFieldDefinition.create');
        this._fetchListData();
    }

    public openEditModal(customField: CustomField): void {
        const modalRef = this._modalService.open(CreateEditCustomFieldModalComponent, {centered: true});

        modalRef.componentInstance.customField = customField;

        modalRef.result
            .then(
                () => this._fetchListData(),
                () => { }
            );
    }

    protected _fetchListData(): void {
        this.customFields$ = this._customFieldService.getCustomFields()
            .pipe(tap(definitions => this.setDefinitions.emit(definitions)));
    }
}
