import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { EmployeeForStructureTree } from 'src/app/employee/interface/employee-for-structure-tree.interface';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../../../../core/services/authentication.service';

@Component({
    selector: 'app-employee-structure-tree',
    templateUrl: './employee-structure-tree.component.html',
    styleUrls: ['./employee-structure-tree.component.css']
})
export class EmployeeStructureTreeComponent implements OnInit {
    @Input() public justChart = false;
    @Input() public hideEmployeeFromStucture = false;

    @Input()
    public set employeeId(employeeId: number) {
        this._employeeId$.next(employeeId);
    }

    public get employeeId(): number | null {
        return this._employeeId$?.getValue();
    }

    @Input()
    public set companyGroupId(groupId: number) {
        this._companyGroupId$.next(groupId);
    }

    public data: null;
    public employees$: Observable<Array<EmployeeForStructureTree>>;
    public showArchived$: Observable<boolean>;
    public showSyncButton = false;

    private _employeeId$ = new BehaviorSubject<number>(null);
    private _companyGroupId$ = new BehaviorSubject<number>(null);
    private _showArchived$ = new BehaviorSubject<boolean>(false);
    private _fetchStructure$ = new BehaviorSubject<boolean>(this.hideEmployeeFromStucture);

    public constructor(
        private _employeeService: EmployeeService,
        private _ts: TranslateService,
        private _authService: AuthenticationService,
        private _detectorRef: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {
        this.showArchived$ = this._showArchived$;

        this.employees$ = combineLatest([
            this._employeeId$,
            this._companyGroupId$,
            this._showArchived$,
            this._fetchStructure$
        ])
            .pipe(
                switchMap(([employeeID, groupId, showArchived]) => {
                    let req$: Observable<Array<EmployeeForStructureTree>>;

                    if (employeeID) {
                        req$ = this._employeeService.getStructureTree({employeeID, showArchived});
                    } else if (groupId) {
                        req$ = this._employeeService.getStructureTree({groupId, showArchived});
                    } else {
                        req$ = this._employeeService.getStructureTree({showArchived, flat: true});
                    }

                    this.showSyncButton = this._authService.loggedUser?.default_filters?.structure_tree?.visible_sync_button;

                    this._detectorRef.detectChanges();

                    return req$;
                }),
                map(groups => this._enrichAvatarImagePaths(groups)),
                shareReplay()
            );
    }

    public showArchivedChanged(show: boolean): void {
        this._showArchived$.next(show);
    }

    public syncByTeams(): void {
        Swal.fire({
            title: this._ts.instant('company_groups.sync_by_company_groups'),
            text: this._ts.instant('company_groups.really_sync'),
            showCancelButton: true,
            confirmButtonColor: 'red',
            confirmButtonText: this._ts.instant('global.action_continue'),
            cancelButtonText: this._ts.instant('global.action_cancel')
        }).then((result) => {
            if (result.value) {
                this._employeeService.syncStructureByTeams().then(() => {
                    this._fetchStructure$.next(true);
                });
            }
        });
    }

    public hideEmployeeFromStructure($event: any): void {
        this._employeeService.saveMeta(this.employeeId, 'is_hidden_in_structure', $event ? '1' : '0', null, this._fetchStructure$.next.bind(this._fetchStructure$));
    }

    private _enrichAvatarImagePaths(groups: Array<EmployeeForStructureTree>): Array<EmployeeForStructureTree> {
        const enrichedGroups: Array<EmployeeForStructureTree> = [];

        let group;

        for (let i = 0, max = groups.length; i < max; i++) {
            group = groups[i];

            if (group.image) {
                group = {
                    ...group,
                    image: group.image
                };
            }

            if (group.childs?.length > 0) {
                group = {
                    ...group,
                    childs: this._enrichAvatarImagePaths(group.childs)
                };
            }

            enrichedGroups.push(group);
        }

        return enrichedGroups;
    }
}
