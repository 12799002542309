import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { PromptResponse } from '../../../../chat/state/chat.model';
import { ChatService } from '../../../../chat/state/chat.service';
import { Subscription } from '../../../../setting/models/Subscription';
import { SubscriptionService } from '../../../../setting/services/subscription.service';
import { SwalHelper } from '../../../common/SwalHelper';

@Component({
    selector: 'app-ai-helper',
    templateUrl: './ai-helper.component.html',
    styleUrls: ['./ai-helper.component.css']
})
export class AiHelperComponent implements OnInit {
    @Input() public scope: 'EVALUATION' | 'ACTIVITY' | 'FORM';
    @Input() public formGroup: UntypedFormGroup;
    @Input() public controlName: string;
    @Input() public formPatchFn: (value: any, replace?: boolean) => void;

    public aiForm: UntypedFormGroup;
    public promptResponse: PromptResponse;
    public submitted = false;
    public helperActive = false;
    public subscriptionInfo$: Observable<Subscription>;

    constructor(
        private _fb: UntypedFormBuilder,
        private _chatService: ChatService,
        private _swalHelper: SwalHelper,
        private _susbcriptionService: SubscriptionService
    ) {}

    public ngOnInit(): void {
        this.subscriptionInfo$ = this._susbcriptionService.subscription$;

        this.aiForm = this._fb.group({
            scope: [this.scope],
            message: ['', Validators.required],
            meta: this._fb.group({
                title: ['', Validators.required],
            })
        });
    }

    public onSubmit(subscriptionInfo: Subscription): void {
        this.submitted = true;

        this.aiForm.patchValue({
            meta: {
                title: this.formGroup.value.name
            }
        });

        if (this.aiForm.invalid) {
            return;
        }

        this._chatService.newPrompt(this.aiForm.value)
            .then(response => {
                const newSubscriptionInfo = {...subscriptionInfo} as Subscription;
                newSubscriptionInfo.ai.available_credits = response.remaining_credits;

                this._susbcriptionService.updateSubscriptionInfo(newSubscriptionInfo);
                void this.patchValueLogic(response);
            });
    }

    public async patchValueLogic(response: PromptResponse): Promise<void> {
        const control = this.formGroup.get(this.controlName);
        const shouldNotAsk = control.pristine && !this.promptResponse;
        this.promptResponse = response;
        const replaceConfirmed = shouldNotAsk || await this._swalHelper.showConfirmChangeDialog('chat.replace_text');

        if (typeof this.formPatchFn === 'function') {
            this.formPatchFn(this.promptResponse.message, replaceConfirmed);
            return;
        }

        if (replaceConfirmed) {
            this.formGroup.patchValue({[this.controlName]: this.promptResponse.message});
        } else {
            this.formGroup.patchValue({[this.controlName]: control.value + ' ' + this.promptResponse.message});
        }
    }
}
