import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { BehaviorSubject, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';

import { AnalysisService, AttendanceAnalysisFilterForm } from 'src/app/analysis/services/analysis.service';

import { DatetimesTypesService } from 'src/app/setting/services/datetimes-types.service';

import { AxisChart } from 'src/app/shared/common/AxisChart';
import { FileService } from 'src/app/shared/services/file.service';

interface CategoryType {
    label: string;
    value: string;
}

@Component({
    selector: 'app-employee-analysis-attendance',
    templateUrl: './employee-analysis-attendance.component.html',
    styleUrls: ['./employee-analysis-attendance.component.css']
})
export class EmployeeAnalysisAttendanceComponent implements OnChanges, OnDestroy, OnInit {
    @Input()
    public categoryIds: Array<number>;

    @Input()
    public set employeeIds(employeeIds: Array<number>) {
        this._employeeIds$.next(employeeIds || null);
    }

    @Input()
    public from: string;

    @Input()
    public groupByCategory = false;

    @Input()
    public groupByEmployee = false;

    @Input()
    public periodType: 'month' | 'quarter' | 'year' = 'month';

    @Input()
    public to: string;

    @Output()
    public stats = new EventEmitter();

    public attendanceCategories$: Observable<Array<CategoryType>>;

    public attendanceLoading$: Observable<boolean>;

    public attendanceStats$: Observable<AxisChart | null>;

    private _attendanceLoading$ = new BehaviorSubject<boolean>(true);

    private _attendanceSettings$ = new BehaviorSubject<AttendanceAnalysisFilterForm | null>(null);

    private _employeeIds$ = new ReplaySubject<Array<number> | null>(1);

    private _subscription: Subscription;

    public constructor(
        private _analysisService: AnalysisService,
        private _attendanceTypesService: DatetimesTypesService,
        private _fileService: FileService,
        private _loaderService: NgxUiLoaderService,
    ) { }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public ngOnChanges(): void {
        this._subscription = this._employeeIds$
            .subscribe(employeeIds => {
                this._attendanceSettings$.next({
                    periodType: this.periodType,
                    employeeDatetimeTypeIDs: this.categoryIds,
                    employeesIDs: employeeIds,
                    from: this.from,
                    groupByCategory: this.groupByCategory,
                    groupByEmployee: this.groupByEmployee,
                    to: this.to
                });
            });
    }

    public ngOnInit(): void {
        this.attendanceLoading$ = this._attendanceLoading$;

        this.attendanceCategories$ = this._attendanceTypesService.getAllDatetimeTypes()
            .pipe(
                map(types => types.map(type => ({
                    label: type.name,
                    value: String(type.employee_datetime_type_ID)
                })))
            );

        this.attendanceStats$ = this._attendanceSettings$
            .pipe(
                tap(() => this._attendanceLoading$.next(true)),
                switchMap(settings => this._analysisService.getAttendanceAnalysis(settings)
                    .pipe(
                        catchError(() => of(null))
                    )),
                tap(() => this._attendanceLoading$.next(false)),
                tap(stats => this.stats.emit(stats))
            );
    }

    public exportAttendance(): void {
        this._attendanceSettings$
            .pipe(
                first(),
                tap(() => this._loaderService.start()),
                switchMap(settings => this._analysisService.exportAttendanceAnalysisToExcel(settings)),
            )
            .subscribe(exportedAttendance => this._fileService.checkMimeTypeAndDownloadWithAnchor(exportedAttendance));
    }
}
