import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Employee } from 'src/app/employee/models/Employee';
import { File } from 'src/app/employee/models/File';
import { SubscriptionService } from 'src/app/setting/services/subscription.service';
import { AvailableFeatures } from '../../../models/AvailableFeatures';
import { CreateDocumentByTemplateModalComponent } from '../create-document-by-template-modal/create-document-by-template-modal.component';

@Component({
    selector: 'app-employee-files',
    templateUrl: './employee-files.component.html',
    styleUrls: ['./employee-files.component.css']
})
export class EmployeeFilesComponent implements OnInit {
    @Input() public employee: Employee;
    @Input() public employeeFiles$: Observable<Array<File>>;
    @Input() public employeeID: number;

    @Output() public refetchFiles = new EventEmitter<string>();

    public allowDelete$: Observable<boolean>;
    public availableFeatures$: Observable<AvailableFeatures>;

    public constructor(
        private _authService: AuthenticationService,
        private _modalService: NgbModal,
        private _subscriptionService: SubscriptionService
    ) { }

    public ngOnInit(): void {
        this.availableFeatures$ = this._subscriptionService.availableFeatures$;

        this.allowDelete$ = this._authService.hasPermissionTo('employee.edit')
            .pipe(
                map(permission => permission.can),
                map(canEdit => canEdit || this.employee?.permissions.deleteFile)
            );
    }

    public onFileDelete(): void {
        this.refetchFiles.emit('refetch');
    }

    public openCreateDocumentByTemplateModal(): void {
        const modalRef = this._modalService.open(CreateDocumentByTemplateModalComponent, {centered: true});

        modalRef.componentInstance.employeeId = this.employeeID;

        modalRef.result
            .then(() => {
                this.refetchFiles.emit('refetch');
            }, () => { });
    }
}
