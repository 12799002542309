import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as iban from 'iban';
import { of, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { CustomerService } from 'src/app/crm/services/customer.service';
import { CountryStoreService } from 'src/app/setting/services/country-store.service';
import { CurrencyStoreService } from 'src/app/setting/services/currency-store.service';
import { FormHelper } from 'src/app/shared/common/FormHelper';

@Component({
    selector: 'app-setting-guide-basic-modal',
    templateUrl: './setting-guide-basic-modal.component.html',
    styleUrls: ['./setting-guide-basic-modal.component.css']
})
export class SettingGuideBasicModalComponent implements OnInit, OnDestroy {
    public closeable = true;
    public submitted = false;
    public loading = false;
    public basicSettingForm: UntypedFormGroup;
    public in: string;
    public inValueChangeSubscription: Subscription;
    public formValueSubscription: Subscription;
    public showOtherFormFields = false;
    public progressBarWidth = 0;
    public customerInfoLoading = false;
    public accounts = [];

    constructor(
        public activeModal: NgbActiveModal,
        public countryStoreService: CountryStoreService,
        public currencyStoreService: CurrencyStoreService,
        private _fb: UntypedFormBuilder,
        private _formHelper: FormHelper,
        private _customerService: CustomerService,
    ) { }

    public ngOnInit(): void {
        this.basicSettingForm = this._fb.group({
            'company.info.in': [null],
            'company.info.name': [null],
            'company.info.tin': [null],
            'company.info.registered_for_vat': [null],
            'company.info.registration_text': [null],
            'company.address.addr_line_1': [null],
            'company.address.addr_line_2': [null],
            'company.address.city': [null],
            'company.address.postal_code': [null],
            'company.address.country_code': [null]
        });

        this.formValueSubscription = this.basicSettingForm.valueChanges.subscribe(() => {
            const filledControlsCount = this._formHelper.getFilledRequiredControlsCount(this.f);
            this.progressBarWidth = parseInt(((filledControlsCount / this._formHelper.getRequiredControlsCount(this.f)) * 100).toFixed(1), 10);
        });

        this.inValueChangeSubscription = this.f['company.info.in'].valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => this.customerInfoLoading = true)).subscribe(val => {
            this._customerService.findCompany(val).pipe(
                catchError(() => of(null)),
                tap(() => this.customerInfoLoading = false)
            ).subscribe((customer: any) => {
                if (customer) {
                    if (customer.accounts) {
                        this.accounts = [...this.accounts, ...customer.accounts];
                    }
                    this.accounts.forEach((a, i) => {
                        this.basicSettingForm.addControl('account_number-' + i, this._fb.control([a.number]));
                        this.basicSettingForm.addControl('account_code-' + i, this._fb.control([a.code]));
                        this.basicSettingForm.addControl('account_iban-' + i, this._fb.control([this.getIBANFromBankAccount(a.number, a.code)]));
                        this.basicSettingForm.addControl('account_currency-' + i, this._fb.control(['CZK']));
                        this.basicSettingForm.get('account_currency-' + i).patchValue('CZK');
                    });
                    this.showOtherFormFields = true;
                    this.basicSettingForm.patchValue({
                        'company.info.name': customer.company,
                        'company.info.tin': customer.tin,
                        'company.info.registered_for_vat': customer.vat_payer,
                        'company.info.registration_text': customer.registration_text,
                        'company.address.addr_line_1': customer.street + ' ' + customer.house_number,
                        'company.address.city': customer.city,
                        'company.address.postal_code': customer.zip,
                        'company.address.country_code': 'CZ'
                    });
                }
            });
        });
        if (this.in) {
            this.f['company.info.in'].patchValue(this.in);
        }
    }

    public get f() {
        return this.basicSettingForm.controls;
    }

    public onSubmit(): void {
        this.submitted = true;
        if (!this.basicSettingForm.valid) {
            return;
        }
        this.loading = true;
        const accounts = [];
        const data = this.basicSettingForm.value;
        const entries = Object.keys(data).filter((k) => k.indexOf('account_') === 0)
            .reduce((newData, k) => {
                newData[k] = data[k];
                return newData;
            }, {});
        this.accounts.forEach((a, i) => {
            const nmbr = entries['account_number-' + i][0];
            const code = entries['account_code-' + i][0];
            const ibanCode = entries['account_iban-' + i][0];
            const currency = entries['account_currency-' + i];
            accounts.push({number: nmbr, code, iban: ibanCode, currency});
        });
        data.accounts = accounts;
        data.settings_type = 'onboarding';
        const promise = this._customerService.saveBasicOnBoarding(data);
        promise.then(() => {
            this.activeModal.close();
            this.loading = false;
        });
    }

    public getIBANFromBankAccount(accountNumber: string, accountCode: string): string {
        const accountNumberLength = accountNumber.length;
        if (accountNumberLength < 10) {
            const missingZeros = 10 - accountNumberLength;
            const missingZerosArray = [];
            for (let i = 0; i < missingZeros; i++) {
                missingZerosArray.push('0');
            }
            accountNumber = missingZerosArray.join('') + accountNumber;
        }
        const bban = accountCode + '000000' + accountNumber;
        return iban.fromBBAN('CZ', bban);
    }

    public setFormCollapse(): void {
        this.showOtherFormFields = !this.showOtherFormFields;
    }

    public ngOnDestroy(): void {
        this.formValueSubscription?.unsubscribe();
        this.inValueChangeSubscription?.unsubscribe();
    }
}
