import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { CompanyGroup } from './company-group.model';

export interface CompanyGroupState extends EntityState<CompanyGroup> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'company-groups', idKey: 'company_group_ID' })
export class CompanyGroupStore extends EntityStore<CompanyGroupState> {
  constructor() {
    super();
  }
}
