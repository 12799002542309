import { NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from '../shared/shared.module';
import { UIButtonModule, UIDataTableModule, UIFormModule, UIItemsPerPageSelectModule, UIPaginationModule } from '../ui';
import { CreateEditGrowthModalComponent } from './components/partials/create-edit-growth-modal/create-edit-growth-modal.component';
import { CreateEditGrowthTaskModalComponent } from './components/partials/create-edit-growth-task-modal/create-edit-growth-task-modal.component';
import { EmployeeGrowthOverviewModalComponent } from './components/partials/employee-growth-overview-modal/employee-growth-overview-modal.component';
import { ActiveGrowths } from './components/sections/active-growth/active-growth.component';
import { GrowthListComponent } from './components/sections/growth-list/growth-list.component';
import { GrowthOverviewComponent } from './components/sections/growth-overview/growth-overview.component';
import { GrowthRoutingModule } from './growth-routing.module';

@NgModule({
    declarations: [
        CreateEditGrowthModalComponent,
        CreateEditGrowthTaskModalComponent,
        GrowthListComponent,
        GrowthOverviewComponent,
        EmployeeGrowthOverviewModalComponent,
        ActiveGrowths
    ],
    imports: [
        GrowthRoutingModule,
        SharedModule,
        UIButtonModule,
        UIDataTableModule,
        UIFormModule,
        UIItemsPerPageSelectModule,
        UIPaginationModule,
        NgxSkeletonLoaderModule
    ],
    exports: [
        ActiveGrowths,
        EmployeeGrowthOverviewModalComponent
    ]
})
export class GrowthModule {}
