import { NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SettingModule } from '../setting/setting.module';
import { SharedModule } from '../shared/shared.module';
import { UIValueOrDashModule } from '../ui';
import { FooterComponent } from './components/layout/footer/footer.component';
import { NavbarComponent } from './components/layout/navbar/navbar.component';
import { ScreenSizeComponent } from './components/layout/screen-size/screen-size.component';

import { MainComponent } from './components/sections/main/main.component';
import { WelcomeModalComponent } from './components/welcome-modal/welcome-modal.component';
import { DisableDemoModeModule } from './disable-demo-mode.module';
import { NotFoundComponent } from './sections/main/not-found/not-found.component';

@NgModule({
    declarations: [
        MainComponent,
        NavbarComponent,
        FooterComponent,
        NotFoundComponent,
        ScreenSizeComponent,
        WelcomeModalComponent
    ],
    imports: [
        RouterModule,
        SharedModule,
        DisableDemoModeModule,
        SettingModule,
        UIValueOrDashModule,
        NgOptimizedImage,
    ]
})
export class CoreModule {}
