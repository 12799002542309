import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, from, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { DisableDemoModeModalComponent } from 'src/app/core/components/disable-demo-mode-modal/disable-demo-mode-modal.component';
import { LoggedInUser } from 'src/app/core/models/LoggedUser';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ScriptLoader } from 'src/app/core/services/script-loader';
import { Plan } from 'src/app/setting/models/Plan';
import { StripeProduct } from 'src/app/setting/models/StripeProduct';
import { Subscription as SubscriptionData } from 'src/app/setting/models/Subscription';
import { CompanyService } from 'src/app/setting/services/company.service';
import { SubscriptionService } from 'src/app/setting/services/subscription.service';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { partition } from 'src/app/utils/array.functions';
import { environment } from 'src/environments/environment';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { CompanyGroup } from '../../../../company-group/state/company-group.model';
import { EmployeeService } from '../../../../employee/services/employee.service';
import { SettingGuideBasicModalComponent } from '../../partials/setting-guide-basic-modal/setting-guide-basic-modal.component';
import { CreateEditUserModalComponent } from '../create-edit-user-modal/create-edit-user-modal.component';

const STRIPE_URL = 'https://js.stripe.com/v3/';

const DEFAULT_CONFIRM_DIALOG_OPTIONS: Pick<ConfirmDialogOptions, 'confirmButtonColor' | 'showCancelButton'> = {
    confirmButtonColor: '#6979ED',
    showCancelButton: true
};

interface ConfirmDialogOptions {
    cancelButtonText?: string;
    confirmButtonText: string;
    title: string;
    text: string;
    confirmButtonColor?: string;
    showCancelButton?: boolean;
}

interface UserForSubscription extends LoggedInUser {
    canBeInvitedDirectly: boolean;
    changed?: boolean;
    invitable?: boolean;
    subscription_action: 'ACTIVATE' | 'ACTIVATE_WITH_INVITATION' | 'DEACTIVATE' | null;
    withInvite?: boolean;
}

interface UserDictionary {
    [userId: number]: UserForSubscription;
}

interface UserStore {
    entities: UserDictionary;
    ids: Array<number>;
}

interface UserSplitStore {
    current: UserStore;
    new: UserStore;
    original: Omit<UserStore, 'original'>;
}

interface PackageInfo {
    canActivate: boolean;
    paid: boolean;
    pending: boolean;
    selected: boolean;
    show: boolean;
}

interface WithPackageInfo {
    STANDARD: PackageInfo;
    PREMIUM: PackageInfo;
    BASIC: PackageInfo;
    DEMO: PackageInfo;
    INDIVIDUAL: PackageInfo;
    showPrice: boolean;
}

interface SubscriptionWithPackageInfo extends SubscriptionData, WithPackageInfo {}

export interface UpdateSubscriptionOptions {
    isNew?: boolean;
    termsAgreed?: boolean;
    terms?: {
        terms_1: boolean;
    };
    cardRequired?: boolean;
}

@Component({
    selector: 'app-subscription-settings-overview',
    templateUrl: './subscription-settings-overview.component.html',
    styleUrls: ['./subscription-settings-overview.component.css']
})
export class SubscriptionSettingsOverviewComponent implements OnDestroy, OnInit {
    @ViewChild('preActivationModal')
    public preActivationModal: TemplateRef<ElementRef>;
    @ViewChild('searchInput')
    public searchInput: ElementRef;

    public activeUsersChange$: Observable<boolean>;
    public activeUsersChangeWithInvite$: Observable<boolean>;
    public canCreateEmployee$: Observable<boolean>;
    public canCreateUser$: Observable<boolean>;
    public canEdit$: Observable<boolean>;
    public companySettings$: Observable<any>;
    public preActivationModalRef: NgbModalRef | null = null;
    public activePlan: Plan;
    public stripe: any;
    public subscriptionPrice$: Observable<number>;
    public subscriptionProcessingLoading$: Observable<boolean>;
    public subscriptionStats$: Observable<SubscriptionWithPackageInfo>;
    public users$: Observable<Array<UserForSubscription>>;
    public usersCurrent$: Observable<Array<UserForSubscription>>;
    public usersNew$: Observable<Array<UserForSubscription>>;
    public stripeProducts$: Observable<Array<StripeProduct>>;
    public employeesUsersCounts$: Observable<Array<number>>;
    public selectedPlanID: string;
    public userAdded = false;
    public loadingBillingPortal = false;
    public loadingUsersChange = false;
    public searchString = '';

    private _basicSettingsModalRef: NgbModalRef | null = null;
    private _fetchUsers$ = new ReplaySubject<void>(1);
    private _subscriptionProcessingLoading$ = new ReplaySubject<boolean>(1);
    private _usersSubscription: Subscription;
    private _userStore$ = new ReplaySubject<UserSplitStore>(1);
    private _inputTimeout: any;
    private _searchSubscription: Subscription;

    public constructor(
        private _apiHelper: ApiHelper,
        private _authService: AuthenticationService,
        private _companyService: CompanyService,
        private _modalService: NgbModal,
        private _route: ActivatedRoute,
        private _router: Router,
        private _scriptLoader: ScriptLoader,
        private _subscriptionService: SubscriptionService,
        private _employeeService: EmployeeService,
        private _translateService: TranslateService
    ) {
        this._loadStripe();
    }

    public ngOnDestroy(): void {
        this._usersSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        if (Capacitor.getPlatform() === 'ios') {
            void this._router.navigateByUrl('/dashboard');
        }
        this._assignPermissions();

        this.usersCurrent$ = this._userStore$
            .pipe(map(store => store.current.ids.map(id => store.current.entities[id])),
                tap((store) => {
                    if (this.userAdded) {
                        this._updateSubscription(null, store);
                    }
                }));

        this.usersNew$ = this._userStore$
            .pipe(map(store => store.new.ids.map(id => store.new.entities[id])));

        this.users$ = combineLatest([
            this.usersCurrent$,
            this.usersNew$
        ])
            .pipe(
                map(([currentUsers, newUsers]) => [...currentUsers, ...newUsers])
            );

        this.activeUsersChange$ = this.users$
            .pipe(
                map(users => users.find(user => user.changed) && true || false),
                shareReplay()
            );

        this.activeUsersChangeWithInvite$ = this.users$
            .pipe(
                map(users => users.find(user => user.withInvite && user.is_active) && true || false),
                shareReplay()
            );

        this.subscriptionPrice$ = this.users$
            .pipe(
                map(users => users.filter(user => user.is_active).length * this.activePlan?.amount / 100),
                shareReplay()
            );

        this.subscriptionProcessingLoading$ = this._subscriptionProcessingLoading$;

        this.subscriptionStats$ = this._subscriptionService.subscription$
            .pipe(
                map(stats => this._extendStats(stats)),
                tap(stats => {
                    this._route.fragment.subscribe(fragment => {
                        if (fragment === 'users') {
                            setTimeout(() => {
                                const element = document.getElementById(fragment);
                                if (element) {
                                    const headerOffset = 70;
                                    const elementPosition = element.getBoundingClientRect().top;
                                    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                    window.scrollTo({
                                        top: offsetPosition,
                                        behavior: 'smooth'
                                    });
                                }
                            });
                        }
                    });
                }),
            );

        this.companySettings$ = this._companyService.getCompanySetting()
            .pipe(map(settings => ({
                address: settings?.company_settings?.address,
                in: settings?.company_settings?.info?.in,
                name: settings?.company_settings?.info?.name,
                tin: settings?.company_settings?.info?.tin,
            })));

        this._usersSubscription = this._fetchUsers$
            .pipe(
                switchMap(() => this._subscriptionService.getAllSubscribedUser()),
                tap(() => {
                    this._route.queryParams.subscribe(params => {
                        if (params?.search) {
                            this.searchString = params.search;
                            this.searchInput.nativeElement.value = params.search;
                            this.onSearchChange(null, this.searchString);
                        }
                    });
                }),
                map((users: Array<UserForSubscription>) => this._createStore(users)),
                map(store => this._prefillNewStore(store))
            )
            .subscribe(store => this._userStore$.next(store));

        this.stripeProducts$ = this._subscriptionService.getAllProducts().pipe(shareReplay());

        this._fetchUsers$.next();
    }

    public activateSubscription(terms?: any, selectedPlan?: string, cardRequired = true): void {
        if (selectedPlan) {
            this.selectedPlanID = selectedPlan;
        }

        this.subscriptionStats$
            .pipe(first())
            .subscribe(stats => {
                if (!stats.terms_agreed) {
                    if (!stats.basic_settings) {
                        this._openBasicSettingsModal();
                    } else if (stats.demo_mode === true) {
                        this.disableDemoMode();
                    } else if (terms) {
                        this._updateSubscription({isNew: true, terms, cardRequired});
                    } else {
                        this._openPreActivationModal();
                    }
                } else {
                    this._updateSubscription({isNew: true, termsAgreed: true, cardRequired});
                }
            });
    }

    public redirectToBillingPortal(): void {
        this.loadingBillingPortal = true;
        this._subscriptionService.createBillingPortalURL();
    }

    public addUser(): void {
        const modalRef = this._modalService.open(CreateEditUserModalComponent, {centered: true});
        modalRef.componentInstance.guard = 'all';
        modalRef.componentInstance.guardType = 'employee';
        modalRef.result
            .then(
                () => {
                    this.userAdded = true;
                    this._fetchUsers$.next();
                },
                () => { }
            );
    }

    public inviteChanged(withInvite: boolean, user: UserForSubscription, list: 'current' | 'new'): void {
        this._userStore$
            .pipe(first())
            .subscribe(store => {
                this._updateUserStore({
                    list,
                    store,
                    userToBeChanged: {
                        ...store[list].entities[user.user_ID],
                        withInvite
                    }
                });
            });
    }

    public sendInvite(user: UserForSubscription): void {
        this._subscriptionService.sendInvite({
            email: user.email,
            id: user.user_ID
        }).then(() => {});
    }

    public sendNewPassword(user: UserForSubscription): void {
        this._subscriptionService.sendNewPassword({
            email: user.email,
            id: user.user_ID
        }).then(() => {});
    }

    public toggleActiveUser(user: UserForSubscription, list: 'current' | 'new'): void {
        console.log(user);
        this._userStore$
            .pipe(first())
            .subscribe(store => {
                const originalUser = store.original.entities[user.user_ID];
                let changed = false;
                let invitable = false;

                const userToBeChanged = {
                    ...store[list].entities[user.user_ID],
                    is_active: !store[list].entities[user.user_ID].is_active
                };

                if (userToBeChanged.is_active !== originalUser.is_active) {
                    changed = true;

                    if (userToBeChanged.is_active) {
                        invitable = true;
                    }
                }

                this._updateUserStore({
                    list,
                    store,
                    userToBeChanged: {
                        ...userToBeChanged,
                        changed,
                        invitable
                    }
                });
            });
    }

    public updateSubscription(): void {
        this._updateSubscription();
    }

    public disableDemoMode(): void {
        const modalRef = this._modalService.open(DisableDemoModeModalComponent, {centered: true});
        modalRef.componentInstance.showExplanation = true;
        modalRef.result
            .then(
                disabled => disabled ? this._subscriptionService.switchData() : null,
                () => { }
            );
    }

    public archiveEmployee(user: LoggedInUser & { is_supervisor: boolean, owned_company_groups: Array<CompanyGroup> }, subscription: SubscriptionData): void {
        if (user.owned_company_groups.length > 0) {
            this._showConfirmDialog({
                showCancelButton: false,
                confirmButtonText: this._translateService.instant('swal.action_ok'),
                text: this._translateService.instant('employees.cannot_archive_owner_text'),
                title: this._translateService.instant('employees.action_archive')
            }).then(() => { });
            return;
        }
        if (user.is_supervisor) {
            this._showConfirmDialog({
                showCancelButton: false,
                confirmButtonText: this._translateService.instant('swal.action_ok'),
                text: this._translateService.instant('employees.cannot_archive_supervisor_text'),
                title: this._translateService.instant('employees.action_archive')
            }).then(() => { });
            return;
        }

        const text = subscription.subscription.per_seats_pricing ? 'employees.action_archive_per_seat_text' : 'employees.action_archive_text';
        this._showConfirmDialog({
            cancelButtonText: this._translateService.instant('swal.action_cancel_delete'),
            confirmButtonText: this._translateService.instant('swal.action_confirm_delete'),
            text: this._translateService.instant(text),
            title: this._translateService.instant('employees.action_archive')
        })
            .then(result => {
                if (result.value) {
                    this._employeeService.archiveEmployee(user.employee_ID)
                        .then(
                            () => this._fetchUsers$.next(),
                            () => {
                            }
                        );
                }
            });
    }

    private _showConfirmDialog(options: ConfirmDialogOptions): Promise<SweetAlertResult> {
        return Swal.fire({
            ...DEFAULT_CONFIRM_DIALOG_OPTIONS,
            ...options
        });
    }

    private _assignPermissions(): void {
        this.canCreateEmployee$ = this._authService.hasPermissionTo('employee.create')
            .pipe(
                map(permission => permission.can),
                shareReplay()
            );

        this.canCreateUser$ = this._authService.hasPermissionTo('user.create')
            .pipe(
                map(permission => permission.can),
                shareReplay()
            );

        this.canEdit$ = this._authService.hasPermissionTo('subscription.edit')
            .pipe(
                map(permission => permission.can),
                shareReplay()
            );
    }

    private _createStore(allUsers: Array<UserForSubscription>): UserSplitStore {
        const createStore = (users: Array<UserForSubscription>) => {
            const entities: UserDictionary = {};
            const ids: Array<number> = [];

            for (let i = 0, max = users.length; i < max; i++) { // tslint:disable-line:prefer-const
                const user = users[i];

                entities[user.user_ID] = {
                    ...user,
                    canBeInvitedDirectly: user.is_active || false,
                    withInvite: user.subscription_action === 'ACTIVATE_WITH_INVITATION'
                };

                ids.push(user.user_ID);
            }

            return {
                entities,
                ids
            };
        };

        const {currentUsers, newUsers} = this._splitUsers(allUsers);

        const currentStore = createStore(currentUsers);
        const newStore = createStore(newUsers);

        const store = {
            ...currentStore,
            entities: {
                ...currentStore.entities,
                ...newStore.entities
            },
            ids: [
                ...currentStore.ids,
                ...newStore.ids
            ]
        };

        return {
            current: {
                ...currentStore
            },
            new: {
                ...newStore
            },
            original: store
        };
    }

    private _extendStats(stats: SubscriptionData): SubscriptionWithPackageInfo {
        const subscription = stats.subscription;

        const demoSelected = subscription.package === 'DEMO';
        const standardSelected = subscription.package === 'STANDARD';
        const premiumSelected = subscription.package === 'PREMIUM';
        const basicSelected = subscription.package === 'BASIC';
        const individualSelected = subscription.package === 'INDIVIDUAL';

        const paid = subscription.status === 'PAID';
        const pending = subscription.status === 'PENDING';

        this.activePlan = stats?.subscription?.plan;

        return {
            ...stats,
            // based on /resource/subscription-packages-logic.xls
            STANDARD: {
                canActivate: !standardSelected,
                paid: Boolean(standardSelected && paid),
                pending: Boolean(standardSelected && pending),
                selected: standardSelected,
                show: !individualSelected && !demoSelected
            },
            PREMIUM: {
                canActivate: !premiumSelected,
                paid: Boolean(premiumSelected && paid),
                pending: Boolean(premiumSelected && pending),
                selected: premiumSelected,
                show: !individualSelected && !demoSelected
            },
            BASIC: {
                canActivate: !basicSelected,
                paid: Boolean(basicSelected && paid),
                pending: Boolean(basicSelected && pending),
                selected: basicSelected,
                show: !individualSelected && !demoSelected
            },
            DEMO: {
                canActivate: false,
                paid: Boolean(demoSelected && paid),
                pending: Boolean(demoSelected && pending),
                selected: demoSelected,
                show: demoSelected
            },
            INDIVIDUAL: {
                canActivate: !individualSelected,
                paid: Boolean(individualSelected && paid),
                pending: Boolean(individualSelected && pending),
                selected: individualSelected,
                show: !individualSelected && !demoSelected
            },
            showPrice: basicSelected || standardSelected || premiumSelected
        };
    }

    private _loadStripe(): void {
        this._scriptLoader.load(STRIPE_URL, 'Stripe')
            .then(scriptStatus => {
                if (scriptStatus.value) {
                    this.stripe = scriptStatus.value(environment.stripe_key);
                }
            })
            .catch(e => console.error('NOPE', e));
    }

    private _openBasicSettingsModal(): void {
        if (this._basicSettingsModalRef === null) {
            this._basicSettingsModalRef = this._modalService.open(
                SettingGuideBasicModalComponent,
                {
                    backdrop: 'static',
                    centered: true,
                    keyboard: false
                }
            );

            this._basicSettingsModalRef.componentInstance.closeable = false;

            from(this._basicSettingsModalRef.result)
                .pipe(
                    first(),
                    catchError(() => of(null)),
                    switchMap(() => {
                        this._basicSettingsModalRef = null;

                        return this._subscriptionService.subscription$
                            .pipe(map(stats => this._extendStats(stats)));
                    })
                )
                .subscribe();
        }
    }

    private _openPreActivationModal(): void {
        this.preActivationModalRef = this._modalService.open(this.preActivationModal);

        from(this.preActivationModalRef.result)
            .pipe(first())
            .subscribe(
                () => { },
                () => { }
            );
    }

    private _prefillNewStore(store: UserSplitStore): UserSplitStore {
        const entities: UserDictionary = {};

        for (let i = 0, max = store.new.ids.length; i < max; i++) { // tslint:disable-line:prefer-const
            const id = store.new.ids[i];

            entities[id] = {
                ...store.new.entities[id],
                changed: true,
                invitable: true,
                is_active: true,
            };
        }

        return {
            ...store,
            new: {
                ...store.new,
                entities
            }
        };
    }

    private _splitUsers(users: Array<UserForSubscription>): {
        currentUsers: Array<UserForSubscription>;
        newUsers: Array<UserForSubscription>;
    } {

        this.employeesUsersCounts$ = this._subscriptionService.getEmployeesUsersCounts();

        const [currentUsers, newUsers] = partition(users, user => user.subscription_action === null);

        return {
            currentUsers,
            newUsers
        };
    }

    private _updateSubscription(options?: UpdateSubscriptionOptions, usersStore?: any): void {
        this.userAdded = false;
        this._subscriptionProcessingLoading$.next(true);
        let users;
        let dataForUpdate;
        let stats;
        this.subscriptionStats$.subscribe(sub => stats = sub);
        if (usersStore) {
            users = usersStore;
        } else {
            this.users$.subscribe(sub => users = sub);
        }

        dataForUpdate = users.map(user => {
            let action: 'ACTIVATE' | 'ACTIVATE_WITH_INVITATION' | 'DEACTIVATE';

            if (user.is_active) {
                if (user.withInvite) {
                    action = 'ACTIVATE_WITH_INVITATION';
                } else {
                    action = 'ACTIVATE';
                }
            } else {
                action = 'DEACTIVATE';
            }

            return {
                user_ID: user.user_ID,
                action
            };
        });

        if (
            options?.isNew &&
            stats.subscription.package !== 'DEMO'
        ) {
            this._subscriptionService.createCheckoutSession(dataForUpdate, options, this.selectedPlanID)
                .then(promise => {
                    promise.subscribe(response => {
                        if (response) {
                            if (response.message) {
                                this._apiHelper.handleSuccessResponse(response);
                                setTimeout(() => {
                                    window.location.reload();
                                }, 2000);
                            }

                            if (response.sessionId) {
                                this.stripe.redirectToCheckout(response);
                            }

                            this._subscriptionProcessingLoading$.next(false);

                            this.preActivationModalRef?.close();
                        }
                    });
                }, (error) => this._subscriptionProcessingLoading$.next(false));
        } else {
            this.loadingUsersChange = true;
            this._subscriptionService.updateSubscription(dataForUpdate)
                .then(promise => {
                    promise.subscribe(() => {
                        this._subscriptionProcessingLoading$.next(false);
                        this.loadingUsersChange = false;
                        this._fetchUsers$.next();
                    });
                });
        }
    }

    private _updateUserStore(update: {
        list: 'current' | 'new';
        store: UserSplitStore;
        userToBeChanged: UserForSubscription;
    }): void {
        const {list, store, userToBeChanged} = update;

        this._userStore$.next({
            ...store,
            [list]: {
                ...store[list],
                entities: {
                    ...store[list].entities,
                    [userToBeChanged.user_ID]: userToBeChanged
                }
            }
        });
    }

    public onSearchChange($event: Event, searchedValue = null): void {
        let inputValue = ($event?.target as HTMLInputElement)?.value;
        if (searchedValue !== null) {
            inputValue = searchedValue;
        }
        this.searchString = inputValue;
        clearTimeout(this._inputTimeout);
        this._inputTimeout = setTimeout(() => {
            this._searchSubscription = this.users$.subscribe(users => {
                if (inputValue !== null && inputValue !== '') {
                    const normalizedInput = inputValue.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                    const filtered = users.filter(user => {
                        if (user.email.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(normalizedInput)) {
                            return user;
                        }
                        if (user.fullname.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(normalizedInput)) {
                            return user;
                        }
                    });
                    this.usersCurrent$ = of(filtered);
                } else {
                    this.usersCurrent$ = of(users);
                }
            });
            this._searchSubscription?.unsubscribe();
        }, 500);
    }
}
