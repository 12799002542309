import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { arrayAdd, arrayRemove, arrayUpdate, cacheable } from '@datorama/akita';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { Employee } from 'src/app/employee/models/Employee';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { KPIMetric } from './kpi-metric.model';
import { KPI } from './kpi.model';
import { KPIStore } from './kpi.store';

@Injectable({
    providedIn: 'root'
})
export class KPIService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _http: HttpClient,
        private _kpiStore: KPIStore,
        private _modalService: NgbModal,
        private _router: Router,
        private _swalHelper: SwalHelper
    ) { }

    public assignEmployeesToKPI(kpi: KPI, employeesIDs: Array<number>, allEmployees: Array<Employee>): Observable<ApiResponse> {
        return this._http.post<ApiResponse>(`/api/evaluations/${kpi.kpi_ID}/employees/assign`, {employeesIDs})
            .pipe(
                tap(response => {
                    const assignedEmployees = allEmployees.filter(e => employeesIDs.includes(e.employee_ID));
                    const assignedEmployeesWithMetrics = assignedEmployees.map(e => ({...e, metrics: kpi.metrics}));
                    this._kpiStore.update(
                        kpi.kpi_ID,
                        kpi
                    );

                    this._modalService.dismissAll();

                    this._apiHelper.handleSuccessResponse(response);
                }),
                catchError(error => {
                    console.log(error);
                    this._apiHelper.handleErrorResponse(error);

                    return of(error);
                })
            );
    }

    public assignKPIsToEmployee(kpiIDs: Array<number>, employeeID: number): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/employees/' + employeeID + '/evaluations/assign', {kpiIDs})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        });
    }

    public async deleteKPI(kpiID: number | string): Promise<void> {
        const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

        if (deleteConfirmed) {
            this._http.delete<ApiResponse>(`/api/evaluations/${kpiID}`, {})
                .subscribe(
                    response => {
                        this._kpiStore.remove(kpiID);

                        this._apiHelper.handleSuccessResponse(response);

                        this._router.navigate(['/evaluation', 'list']);
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        }
    }

    public async deleteKPIMetric(kpiID: number | string, kpiMetricID: number | string, modalRef: NgbActiveModal): Promise<void> {
        const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

        if (deleteConfirmed) {
            this._http.delete<ApiResponse>(`/api/evaluations/${kpiID}/metrics/${kpiMetricID}`, {})
                .subscribe(
                    response => {
                        this._kpiStore.update(
                            kpiID,
                            originalKPI => ({
                                ...originalKPI,
                                metrics: arrayRemove(originalKPI.metrics, kpiMetricID, 'kpi_metric_ID')
                            })
                        );
                        modalRef.close();

                        this._apiHelper.handleSuccessResponse(response);
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        }
    }

    public get(): Observable<void> {
        const request = this._http.get<Array<KPI>>('/api/evaluations')
            .pipe(map(response => this._kpiStore.set(response)));

        return cacheable(this._kpiStore, request);
    }

    public getAllKPIs(): Observable<Array<KPI>> {
        return this._http.get<Array<KPI>>('/api/evaluations');
    }

    public getEmployeesForKPI(kpiID: number | string): Observable<Array<Employee>> {
        return this._http.get<Array<Employee>>(`/api/evaluations/${kpiID}/employees`);
    }

    public getKPIByID(kpiID: number | string): Observable<KPI> {
        return this._http.get<KPI>(`/api/evaluations/${kpiID}`);
    }

    public getKpisByEmployee(employeeID: number): Observable<Array<KPI>> {
        return this._http.get<Array<KPI>>(`/api/employees/${employeeID}/evaluations`);
    }

    public getKPIsList(params: ListParameters<KPI>): Observable<ListResponse<KPI>> {
        return this._http.post<ListResponse<KPI>>('/api/evaluations/list', {...transformParameters(params)});
    }

    public unAssignEmployeeFromKPI(kpiID: number | string, employeeID: number): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.post<ApiResponse>(`/api/evaluations/${kpiID}/employees/unassign`, {employee_ID: employeeID})
                    .subscribe(
                        response => {
                            this._kpiStore.update(
                                kpiID,
                                kpi => ({
                                    employees: arrayRemove(kpi.employees, employeeID, 'employee_ID')
                                })
                            );

                            this._apiHelper.handleSuccessResponse(response);

                            resolve(true);
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);

                            reject(false);
                        }
                    );
            } else {
                reject(false);
            }
        });
    }

    public saveKPI(form: KPI, modalRef: NgbActiveModal, kpiID?: number | string): void {
        const apiUrl = kpiID ? `/api/evaluations/${kpiID}` : '/api/evaluations';

        this._http.post(apiUrl, form)
            .subscribe(
                (response: any) => {
                    this._apiHelper.handleSuccessResponse(response);

                    if (!kpiID) {
                        this._kpiStore.add(response.kpi);
                    } else {
                        this._kpiStore.update(kpiID, response.kpi);
                    }

                    modalRef.close();
                },
                error => this._apiHelper.handleErrorResponse(error)
            );
    }

    public saveKPIMetric(form: KPIMetric, kpiID: string | number, modalRef: NgbActiveModal, kpiMetricID?: number | string): void {
        const apiUrl = kpiMetricID ? `/api/evaluations/${kpiID}/metrics/${kpiMetricID}` : `/api/evaluations/${kpiID}/metrics`;

        this._http.post(apiUrl, form)
            .subscribe(
                (response: any) => {
                    this._apiHelper.handleSuccessResponse(response);

                    if (kpiMetricID) {
                        this._kpiStore.update(
                            kpiID,
                            originalKPI => ({
                                metrics: arrayUpdate(originalKPI.metrics, kpiMetricID, response.kpi_metric, 'kpi_metric_ID')
                            })
                        );
                    } else {
                        this._kpiStore.update(
                            kpiID,
                            originalKPI => ({
                                metrics: arrayAdd(originalKPI.metrics, response.kpi_metric)
                            })
                        );
                    }

                    modalRef.close();
                },
                error => this._apiHelper.handleErrorResponse(error)
            );
    }

    public saveKPIMetricModifications(form: any, kpiID: string | number, employeeID: number): Promise<'done'> {
        const apiUrl = `/api/evaluations/${kpiID}/employees/${employeeID}/modifications`;

        return new Promise(resolve => {
            this._http.post<ApiResponse>(apiUrl, form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);
                        resolve('done');
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        });
    }
}
