<div class="modal-content">
  <div class="modal-header">
    <p
      *ngIf="access"
      class="modal-title font-16 mb-0 text-dark"
    >{{ 'employees_accesses.page_title_edit' | translate }}</p>

    <p
      *ngIf="!access"
      class="modal-title font-16 mb-0 text-dark"
    >{{ 'employees_accesses.page_title_create' | translate }}</p>

    <button
      aria-label="Close"
      class="close"
      (click)="activeModal.dismiss()"
    >
      <span
        aria-hidden="true"
        class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <form
              autocomplete="off"
              [formGroup]="form"
              (ngSubmit)="onSubmit()"
            >
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_accesses.name' | translate }}</span>
                    </label>

                    <input
                      class="form-control"
                      formControlName="name"
                      name="name"
                      type="text"
                    >

                    <div *ngIf="submitted && f.name.errors">
                      <p
                        class="text-danger validation-text"
                        *ngIf="f.name.errors.required"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'employees_accesses.name' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_accesses.login' | translate }}</span>
                    </label>

                    <input
                      class="form-control"
                      formControlName="login"
                      name="login"
                      type="text"
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_accesses.from' | translate }}</span>
                    </label>

                    <input
                      mwlFlatpickr
                      allowInput="true"
                      altFormat="d.m.Y"
                      altInput="true"
                      class="form-control"
                      dateFormat="Y-m-d"
                      formControlName="from"
                      name="from"
                      type="text"
                      [locale]="locale$ | async"
                      (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_accesses.to' | translate }}</span>
                    </label>

                    <input
                      mwlFlatpickr
                      allowInput="true"
                      altFormat="d.m.Y"
                      altInput="true"
                      class="form-control"
                      dateFormat="Y-m-d"
                      formControlName="to"
                      name="to"
                      type="text"
                      [locale]="locale$ | async"
                      (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_accesses.description' | translate }}</span>
                    </label>

                    <input
                      class="form-control"
                      formControlName="description"
                      name="description"
                      type="text"
                    >
                  </div>
                </div>
              </div>

              <div class="row mt-4 d-flex justify-content-end">
                <div class="col-6 my-auto text-right">
                  <u class="text-muted"
                     *ngIf="access"
                     (click)="deleteAccess()"
                  >{{ 'employees_accesses.action_delete' | translate }}</u>
                </div>
                <div class="col-6 text-right">
                  <button class="btn btn-success">{{ 'employees_accesses.action_save' | translate }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
