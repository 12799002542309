import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { KPI } from './kpi.model';

export interface KPIState extends EntityState<KPI> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'kpis', idKey: 'kpi_ID' })
export class KPIStore extends EntityStore<KPIState> {
  constructor() {
    super();
  }
}
