import { ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ListResponse } from 'src/app/api';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';
import { TagService } from 'src/app/tag/state/tag.service';
import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { CompanyBranch } from '../../../../setting/models/CompanyBranch';
import { CompanyService } from '../../../../setting/services/company.service';
import { CustomField } from '../../../../shared/models/CustomField';
import { EmployeeListType } from '../../../types';
import { CreateEditEmployeeModalComponent } from '../create-edit-employee-modal/create-edit-employee-modal.component';

interface EmployeeWithCompanyGroupNames extends Employee {
    companyGroupNames: string;
}

@Component({
    selector: 'app-employee-list',
    templateUrl: './employee-list.component.html',
    styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent extends FatherOfListComponent<Employee> implements OnInit, OnDestroy {
    @ViewChild('filterFormOptions', {static: false})

    public filterFormOptions: TemplateRef<ElementRef>;
    public canRender$: Observable<boolean>;
    public listTypeWithCreate: Array<Omit<'archived', EmployeeListType>> = ['COMMON', 'CONTRACTOR', 'POTENTIAL'];
    public type: EmployeeListType = 'COMMON';
    public availableWidgets$: Observable<AvailableWidgets>;
    public filterForm: UntypedFormGroup;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public statesSelect: Array<{ label: string, value: string }> = [];
    public positionPeriodsSelect: Array<{ label: string, value: string }> = [];
    public employees$: Observable<any>;
    public customFieldColumns: CustomField[];

    public sort: Sort<Employee> = {
        column: 'fullname',
        direction: SORT_ASC
    };

    public canViewEmployee: boolean;
    public canCreateEmployee: boolean;
    public canEditEmployee: boolean;
    public canViewOnboarding: boolean;
    public companyGroups$: Observable<any>;
    public employeePositionTypes$: Observable<any>;
    public companyBranches$: Observable<any>;
    public tags$: Observable<any>;
    public hardSkillsTags$: Observable<any>;
    public softSkillsTags$: Observable<any>;

    private _fetchEmployees$ = new ReplaySubject<void>(1);
    private _filterFormModalRef: NgbModalRef;
    private _modalSizeSubscription: Subscription;

    public constructor(
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        private _authService: AuthenticationService,
        private _employeeService: EmployeeService,
        private _tagService: TagService,
        private _modalService: NgbModal,
        private _route: ActivatedRoute,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _fb: UntypedFormBuilder,
        private _companyService: CompanyService,
        private _translateService: TranslateService,
    ) {
        super(_ngZone, _changeDetectorRef);
    }

    public getCount(field: string) {
        return this.filterForm.get(field).value?.length;
    }

    public ngOnInit(): void {
        this.availableWidgets$ = this._companyService.availableWidgets$;

        this.type = this._route.snapshot.data.type || 'COMMON';

        this._init();
        this._fetchListData();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this._modalSizeSubscription?.unsubscribe();
    }

    public onSubmit(): void {
        this._filterFormModalRef?.close();

        this._fetchListData();
    }

    public openFilterFormOptions(): void {
        this._filterFormModalRef = this._modalService.open(this.filterFormOptions);
    }

    public openEditModal(): void {
        const modalRef = this._modalService.open(CreateEditEmployeeModalComponent, {centered: true, size: 'md'});

        if (this.type === 'archived') {
            modalRef.componentInstance.archived = this.type === 'archived';
        } else if (this.listTypeWithCreate.includes(this.type)) {
            modalRef.componentInstance.employeeType = this.type;
        }

        this._modalSizeSubscription = (modalRef.componentInstance.setModalSize as EventEmitter<string>)
            .pipe(
                tap((size: string) => {
                    // _windowCmptRef is an internal API this is why we need to use modal as any to circumvent TypeScript type checking
                    (modalRef as any)._windowCmptRef.instance.size = size;
                }),
            ).subscribe();

        modalRef.result
            .then(
                () => this._fetchListData(),
                () => { }
            );
    }

    public trackByFn(index: number, employee: Employee): number {
        return employee.employee_ID;
    }

    public setColumnsWidth(obj: { contact: boolean, main_branch: boolean, teams: boolean, position_type: boolean }): string[] {
        const arr = ['25%'];
        const tmpObj = {...obj};
        let firstColWidth = 25;
        if (this.rows?.length > 0 && this.rows[0].custom_fields) {
            const customFields = this.rows[0].custom_fields;
            customFields.forEach(field => tmpObj[field.key] = true);
            firstColWidth = 25 / (0.8 * customFields.length);
            arr[0] = `${firstColWidth}%)`;
        }

        let count = 0;
        const objArr = Object.keys(tmpObj);

        for (const item of objArr) {
            if (tmpObj[item]) {
                count++;
            }
        }

        for (let i = 0; i < count; i++) {
            arr.push(((100 - firstColWidth - 10) / count).toString() + '%');
        }

        arr.push('10%');

        return arr;
    }

    protected _init(): void {
        this.canCreateEmployee = this._authService.checkPermission('employee.create');
        this.canEditEmployee = this._authService.checkPermission(['employee.editStructure', 'employee.edit']);
        this.canViewEmployee = this._authService.checkPermission(['employee.viewStructure', 'employee.view']);
        this.canViewOnboarding = this._authService.checkPermission('onboarding.view');

        if (this.canViewOnboarding) {
            this.statesSelect.push({label: this._translateService.instant('employees.filter_states_checklist'), value: 'checklist'});
        }

        if (this.canViewEmployee) {
            this.statesSelect.push(
                {label: this._translateService.instant('employees.filter_states_joining'), value: 'joining'},
                {label: this._translateService.instant('employees.filter_states_position_end'), value: 'position_end'},
                {label: this._translateService.instant('employees.filter_states_termination_period'), value: 'termination_period'},
                {label: this._translateService.instant('employees.filter_states_test_end'), value: 'testing_end'}
            );
            this.positionPeriodsSelect.push(
                {label: this._translateService.instant('employees.filter_employee_fixed_period'), value: 'fixed_period'},
                {label: this._translateService.instant('employees.filter_employee_testing_period'), value: 'testing_period'},
            );
        }

        if (this.canEditEmployee) {
            this.statesSelect.push(
                {label: this._translateService.instant('employees.filter_states_change_request'), value: 'change_request'},
            );
        }

        this.filterForm = this._fb.group({
            from: [null],
            to: [null],
            companyGroups: [null],
            employeePositionTypes: [null],
            positionCompanyBranches: [null],
            mainCompanyBranches: [null],
            tags: [null],
            hardSkillsTags: [null],
            softSkillsTags: [null],
            states: [null],
            position_periods: [null],
        });

        // this.types$ = this._todoService.getTodoTypes();
        this.companyGroups$ = this._employeeService.getAllCompanyGroups();
        this.employeePositionTypes$ = this._employeeService.getAllEmployeePositionTypes();
        this.companyBranches$ = this._employeeService.getAllCompanyBranches('minimal')
            .pipe(
                map(branches => {
                    branches.unshift({company_branch_ID: 0, name: this._translateService.instant('company_branches.no_branch')} as CompanyBranch);
                    return branches;
                }),
                shareReplay()
            );

        this.tags$ = this._tagService.getAllTags('employee');
        this.hardSkillsTags$ = this._tagService.getAllTags('employee', 'hard_skill');
        this.softSkillsTags$ = this._tagService.getAllTags('employee', 'soft_skill');

        this._rows$ = this._fetchEmployees$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                tap(params => this.employees$ = this._employeeService.getEmployeesList(params, this.type, this.filterForm.value)),
                switchMap(params => this.employees$),
                map(response => this._setupList(response)),
                tap((employees) => {
                    if (!this.customFieldColumns) {
                        this.customFieldColumns = employees.length > 0 ? employees[0].custom_fields : [];
                    }
                }),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this.canRender$ = combineLatest([
            this.companyGroups$
        ])
            .pipe(map(() => true));

        super._init();

    }

    protected _fetchListData(): void {
        this._fetchEmployees$.next();
    }

    protected _setupList(response: ListResponse<Employee>): Array<EmployeeWithCompanyGroupNames> {
        return this._extendCompanyGroupNames(super._setupList(response));
    }

    private _extendCompanyGroupNames(employees: Array<Employee>): Array<EmployeeWithCompanyGroupNames> {
        const extendedEmployees: Array<EmployeeWithCompanyGroupNames> = [];

        let companyGroupNames = [];

        for (let i = 0, max = employees.length; i < max; i++) {
            const employee = employees[i];

            companyGroupNames = employee.owned_company_groups?.map(group => `👑 ${group.name}`) || [];

            if (employee.company_group) {
                companyGroupNames.push(employee.company_group.name);
            }

            extendedEmployees.push({
                ...employee,
                companyGroupNames: companyGroupNames.join(', ')
            });
        }

        return extendedEmployees;
    }
}
