import { NgModule } from '@angular/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from '../shared/shared.module';
import { UIButtonModule, UIDataTableModule, UIFileUploadModule, UIItemsPerPageSelectModule, UIPaginationModule, UIValueOrDashModule } from '../ui';
import { FilePreviewModalComponent } from './components/partials/file-preview-modal/file-preview-modal.component';
import { ImportListComponent } from './components/sections/import-list/import-list.component';
import { ImportOverviewComponent } from './components/sections/import-overview/import-overview.component';
import { ImportPayrollsComponent } from './components/sections/import-payrolls/import-payrolls.component';
import { ImportPreviewComponent } from './components/sections/import-preview/import-preview.component';
import { ImportRoutingModule } from './import-routing.module';

@NgModule({
    declarations: [
        ImportOverviewComponent,
        ImportPreviewComponent,
        ImportListComponent,
        ImportPayrollsComponent,
        FilePreviewModalComponent
    ],
    imports: [
        ImportRoutingModule,
        SharedModule,
        UIButtonModule,
        UIDataTableModule,
        UIItemsPerPageSelectModule,
        UIPaginationModule,
        UIValueOrDashModule,
        UIFileUploadModule,
        PdfViewerModule,
        NgxSkeletonLoaderModule
    ]
})
export class ImportModule {}
