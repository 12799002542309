import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeContactPerson } from 'src/app/employee/models/EmployeeContactPerson';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { ContactPersonFormComponent } from '../../embedded-forms/contact-person/contact-person-form.component';

@Component({
    selector: 'app-create-edit-employee-contact-person',
    templateUrl: './create-edit-employee-contact-person.component.html',
    styleUrls: ['./create-edit-employee-contact-person.component.css']
})
export class CreateEditEmployeeContactPersonComponent implements OnInit {
    @ViewChild('childRef', {static: false}) formComponent: ContactPersonFormComponent;

    public employeeContactPerson: EmployeeContactPerson;
    public employeeID: number;
    public employee: Employee;
    public canEdit: boolean;
    public submitted = false;
    public hasRequestEditPermission = false;
    public updateRequested = false;

    constructor(
        private employeeService: EmployeeService,
        public activeModal: NgbActiveModal,
        public authService: AuthenticationService
    ) { }

    public ngOnInit(): void {
        if (this.employee.permissions.requestEditAddress) {
            this.hasRequestEditPermission = true;
        }

        this.canEdit = this.authService.checkPermission(['employeeContactPerson.edit', 'employeeContactPerson.editStructure']);
    }

    public onSubmit(): void {
        this.submitted = true;
        let promise: Promise<any>;
        const form = this.formComponent.form;

        if (form.invalid) {
            return;
        }

        if (this.employeeContactPerson) {
            promise = this.employeeService.saveContactPerson(
                form.value,
                this.employeeID,
                this.employeeContactPerson.employee_contact_person_ID);
        } else {
            promise = this.employeeService.saveContactPerson(form.value, this.employeeID);
        }

        promise.then(() => {
            this.activeModal.close('close');
        });
    }

    public deleteContactPerson(): void {
        this.employeeService.deleteContactPerson(this.employeeID, this.employeeContactPerson?.employee_contact_person_ID)
            .then(() => this.activeModal.close('close')).catch(() => {});
    }
}
