import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';

import { ID } from '@datorama/akita';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/core/services/authentication.service';

import { FatherOfListComponent, Sort, SORT_ASC } from 'src/app/ui';

import { Benefit } from '../../../benefit/state/benefit.model';
import { BenefitService } from '../../../benefit/state/benefit.service';

import { CreateEditBenefitModalComponent } from '../../partials/create-edit-benefit-modal/create-edit-benefit-modal.component';

@Component({
    selector: 'app-benefit-list',
    templateUrl: './benefit-list.component.html',
    styleUrls: ['./benefit-list.component.css']
})
export class BenefitListComponent extends FatherOfListComponent<Benefit> implements OnInit {
    public canCreate$: Observable<boolean>;

    public sort: Sort<Benefit> = {
        column: 'name',
        direction: SORT_ASC
    };

    private _fetchBenefits$ = new ReplaySubject<void>(1);

    public constructor(
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        private _authService: AuthenticationService,
        private _benefitService: BenefitService,
        private _modalService: NgbModal
    ) {
        super(_ngZone, _changeDetectorRef);
    }

    public ngOnInit(): void {
        this.canCreate$ = this._authService.hasPermissionTo('benefit.create')
            .pipe(map(permission => permission.can));

        this._rows$ = this._fetchBenefits$
            .pipe(
                tap(() => this._loading$.next(true)),
                map(() => this._buildParams()),
                switchMap(params => this._benefitService.getBenefitsList(params)),
                map(response => this._setupList(response)),
                catchError(() => of([])),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();

        this._fetchListData();
    }

    public openEditModal(benefit?: Benefit): void {
        const modalRef = this._modalService.open(CreateEditBenefitModalComponent, {centered: true});

        modalRef.componentInstance.benefit = benefit;

        modalRef.result
            .then(
                () => this._fetchListData(),
                () => { }
            );
    }

    public trackByFn(index: number, benefit: Benefit): ID {
        return benefit.benefit_ID;
    }

    protected _fetchListData(): void {
        this._fetchBenefits$.next();
    }
}
