import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { first, shareReplay, switchMap } from 'rxjs/operators';
import { Note } from 'src/app/note/state/note.model';
import { NoteService } from 'src/app/note/state/note.service';
import { NoteModalComponent } from '../../partials/note-modal/note-modal.component';

@Component({
    selector: 'app-note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.css']
})
export class NoteComponent implements OnInit {
    @Input() isDashboard = false;
    @Input()
    public entity: {
        type: 'Employee' | 'Property' | 'Dashboard' | null;
        id: number;
    } | null = null;

    private _fetchNote$ = new ReplaySubject<void>(1);
    private _updateNote$ = new Subject<Note>();

    public note$: Observable<Note>;

    public constructor(
        protected _changeDetectorRef: ChangeDetectorRef,
        private _noteService: NoteService,
        private _fb: UntypedFormBuilder,
        private _modalService: NgbModal
    ) {
    }

    public ngOnInit(): void {
        this._init();
        this._fetchData();
    }

    protected _init(): void {

        this._fetchNote$.next();

    }

    private _fetchData() {

        this.note$ = merge(
            this._fetchNote$
                .pipe(
                    switchMap(() => this._noteService.getNote(this.entity))
                ),
            this._updateNote$
                .pipe(
                    switchMap(note => this._noteService.saveNote(note, this.entity))
                )
        ).pipe(shareReplay());

    }

    public updateNote(note: Note): void {
        this._updateNote$.next(note);
    }

    public openNoteModal(): void {
        this.note$
            .pipe(first())
            .subscribe(note => {
                const modalRef = this._modalService.open(NoteModalComponent, {centered: true});

                modalRef.componentInstance.note = note;

                modalRef.result
                    .then(
                        text => {

                            note.text = text.text;
                            this.updateNote({
                                ...note
                            });

                        },
                        () => { }
                    );
            });

    }

}
