import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { CompanyBranch } from '../models/CompanyBranch';

@Injectable({
    providedIn: 'root'
})
export class BranchService {
    public constructor(private _http: HttpClient) { }

    public deleteBranch(companyBranchID: number | string): Observable<ApiResponse> {
        return this._http.delete<ApiResponse>(`/api/company-branches/${companyBranchID}`);
    }

    public getAllBranches(scope: string): Observable<Array<CompanyBranch>> {
        const params = new HttpParams().set('scope', scope);
        return this._http.get<Array<CompanyBranch>>('/api/company-branches', {params});
    }

    public getBranchByID(companyBranchID: number): Observable<CompanyBranch> {
        return this._http.get<CompanyBranch>(`/api/company-branches/${companyBranchID}`);
    }

    public getBranchesList(params: ListParameters<CompanyBranch>): Observable<ListResponse<CompanyBranch>> {
        return this._http.post<ListResponse<CompanyBranch>>('/api/company-branches/list', {...transformParameters(params)});
    }

    public saveBranch(form: FormData): Observable<ApiResponse> {
        return this._http.post<ApiResponse>('/api/company-branches', form);
    }

    public updateBranch(companyBranchID: number | string, form: FormData): Observable<ApiResponse> {
        return this._http.post<ApiResponse>(`/api/company-branches/${companyBranchID}`, form);
    }
}
