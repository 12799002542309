import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { arrayRemove, ID } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ACCOUNT } from 'src/app/import/entity-target-model.type';
import { Import, ImportItem } from 'src/app/import/state/import.model';
import { ImportService } from 'src/app/import/state/import.service';

interface ImportWithColumns extends Import {
    columns: { key: string; label: string }[];
}

interface ImportWithPermissions extends Import {
    permissions: {
        delete: boolean;
        edit: boolean;
    };
}

interface ImportWithPermissionsAndColumns extends ImportWithColumns, ImportWithPermissions {
}

@Component({
    selector: 'app-import-overview',
    templateUrl: './import-overview.component.html',
    styleUrls: ['./import-overview.component.css']
})
export class ImportOverviewComponent implements OnInit, OnDestroy {
    public import$: Observable<ImportWithPermissionsAndColumns>;
    public routeParams: Array<any> = [];

    private _deleteSubscription: Subscription;
    private _deleteItemSubscription: Subscription;
    private _removeItemId$ = new BehaviorSubject<number>(null);

    public constructor(
        private _authService: AuthenticationService,
        private _importService: ImportService,
        private _route: ActivatedRoute,
        private _router: Router,
        public translateService: TranslateService
    ) { }

    public ngOnDestroy(): void {
        this._deleteSubscription?.unsubscribe();
        this._deleteItemSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.import$ = combineLatest([
            this._route.params
                .pipe(
                    switchMap(params => {
                        return combineLatest([
                            this._importService.getImportByID(params.importID),
                            this._authService.hasPermissionTo('import.delete'),
                            this._authService.hasPermissionTo('import.edit')
                        ]);
                    }),
                    map(([importData, canDelete, canEdit]) => {
                        return {
                            ...this._prepareColumns(importData),
                            permissions: {
                                delete: canDelete.can,
                                edit: canEdit.can
                            }
                        } as ImportWithPermissionsAndColumns;
                    }),
                    tap(importData => this._prepareRoute(importData)),
                    catchError(error => {
                        if (error.status === 404) {
                            this._router.navigateByUrl('/404');
                        }

                        return of(null);
                    })
                ),
            this._removeItemId$
        ])
            .pipe(
                map(([importData, importItemIdToRemove]) => {
                    if (importItemIdToRemove) {
                        importData = {
                            ...importData,
                            items: arrayRemove(importData.items, importItemIdToRemove, 'import_item_ID')
                        };
                    }

                    return importData;
                })
            );
    }

    public deleteImport(importID: number): void {
        this._deleteSubscription = this._importService.deleteImport(importID)
            .subscribe(
                () => this._router.navigate(['/import', 'list']),
                () => { }
            );
    }

    public deleteImportItem(importItemID: number): void {
        this._deleteItemSubscription = this._importService.deleteImportItem(importItemID)
            .subscribe(
                () => this._removeItemId$.next(importItemID),
                () => { }
            );
    }

    public trackByFn(index: number, importItem: ImportItem): ID {
        return importItem.import_item_ID;
    }

    private _prepareRoute(importData: Import): void {
        switch (importData.entity_target_model) {
            case (ACCOUNT): {
                this.routeParams = ['/account', 'detail', importData.entity_target.account_ID, 'overview'];

                break;
            }

            default: {
                this.routeParams = [];
            }
        }
    }

    private _prepareColumns(importData: Import): ImportWithColumns {
        const columns: { key: string; label: string }[] = [];
        const rows = [];
        let index = 0;

        const mappingKeys = Object.keys(importData.mappings);

        for (const mappingKey of mappingKeys) {
            let subIndex = 0;
            const subKeys = mappingKey.split('.');
            const col = {
                key: mappingKey,
                label: this.translateService.instant(`imports.column_${subKeys[0]}_${subKeys[1]}`)
            };
            columns.push(col);

            for (const item of importData.items) {
                if (rows[subIndex] === undefined) {
                    rows[subIndex] = [];
                }
                rows[subIndex][index] = item.data[subKeys[0]][subKeys[1]];
                subIndex++;
            }

            index++;
        }

        return {
            ...importData,
            columns,
            items: rows
        };
    }
}
