import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { HelpState, HelpStore } from './help.store';

@Injectable({
    providedIn: 'root'
})
export class HelpQuery extends QueryEntity<HelpState> {
    public constructor(protected store: HelpStore) {
        super(store);
    }
}
