import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Integration } from 'src/app/core/models/Integration';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { IntegrationService } from 'src/app/core/services/integration.service';
import { IntegrationModal } from '../../partials/integration-modal/integration-modal.component';

@Component({
    selector: 'app-setting-integration',
    templateUrl: './setting-integration.component.html',
    styleUrls: ['./setting-integration.component.css']
})
export class SettingIntegrationComponent implements OnInit {
    public canViewCompanySettings$: Observable<{ can: boolean }>;
    public availableIntegrations$: Observable<Integration[]>;
    public readonly Array = Array;

    public constructor(
        private _authService: AuthenticationService,
        private _modalService: NgbModal,
        private _integrationService: IntegrationService,
        private _route: ActivatedRoute,
        private _router: Router
    ) { }

    public ngOnInit(): void {
        this.canViewCompanySettings$ = this._authService.hasPermissionTo('companySetting.view');

        this.fetchIntegrations();
    }

    public openIntegrationModal(integration: Integration, slackCode?: string): void {
        const modalRef = this._modalService.open(IntegrationModal, {centered: true, size: 'lg'});

        modalRef.componentInstance.integration = integration;
        modalRef.componentInstance.slackCode = slackCode;

        modalRef.result
            .then(
                () => this.fetchIntegrations(),
                () => { }
            );
    }

    private fetchIntegrations(): void {
        this.availableIntegrations$ = this._integrationService.getAvailableIntegrations()
            .pipe(
                tap((integrations: Integration[]) => {
                    const params = this._route.snapshot.queryParams;
                    if (params && params.open) {
                        const integration = integrations.find((i: Integration) => i.key === params.open);
                        if (integration) {
                            const slackCode = params.code;
                            setTimeout(() => {
                                this.openIntegrationModal(integration, slackCode);
                            });
                        }

                        void this._router.navigate([], {queryParams: {}, fragment: this._route.snapshot.fragment});
                    }
                })
            );
    }
}
