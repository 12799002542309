export const environment = {
    production: true,
    domain: 'speybl.com',
    serverUrl: 'api.speybl.com',
    microsoftRedirectUrl: 'https://app.speybl.com/auth/login',
    protocol: 'https://',
    stripe_key: 'pk_live_51Ivdy4DZXw95fFLLfmYr20scKH5W45h8EjNFP5059EughCfRElv6bhZRW1IPh8bIGmOSbmXx673J2Pjo3bPTvydB00u6yRudY7',
    firebase: {
        apiKey: 'AIzaSyDH1htxKAQlkgMK9qYqhxx99sGNm39dXCo',
        authDomain: 'speybl-73503.firebaseapp.com',
        projectId: 'speybl-73503',
        storageBucket: 'speybl-73503.appspot.com',
        messagingSenderId: '760278164750',
        appId: '1:760278164750:web:33b1cb7c6c1a5234cf635f',
        vapidKey: 'BMfx4O0QSKyqNG8R6LeLNRb_AYjvIRq8QRTbM61ow210gXlzNZ2Rts-OkvLf4j6Gtod8YEGF1o48nGLGMFi83qA'
    }
};
