<div class="row">
    <div class="col-12">
        <div class="card shadow">
            <app-help name="{{'settings.appearance.widget_templates'}}"></app-help>
            <div class="card-header py-3">
                <div class="row">
                    <div class="col text-left my-auto">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">
                            <span>{{ ('settings.appearance.widget_templates.title') | translate }}</span>
                        </p>
                    </div>
                    <div class="col-auto text-right">
                        <button *ngIf="canEdit"
                                (click)="openEditModal(null)"
                                [createButton]="'settings.appearance.widget_templates.action_create' | translate"
                                class="ml-sm-2"
                        ></button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive">
                            <app-data-table
                                [disableInPlaceSorting]="true"
                                [rows]="rows"
                                [showSearch]="false"
                                [loading]="loading"
                            >
                                <ng-template #headerRow
                                >
                                    <th [disableSort]="true"
                                        appDataTableHeaderCell="name"
                                    >{{ 'settings.appearance.widget_templates.name' | translate }}</th>

                                    <th
                                        [disableSort]="true"
                                        appDataTableHeaderCell
                                        class="text-right"
                                    >{{ 'settings.appearance.widget_templates.actions' | translate }}</th>
                                </ng-template>

                                <ng-template
                                    #dataRow
                                    let-row
                                >
                                    <td appDataTableDataCell class="w-sm-15">
                                        {{ row.name }}
                                    </td>

                                    <td
                                        appDataTableDataCell
                                        class="text-right"
                                    >
                                        <a
                                            (click)="openEditModal(row)"
                                            class="btn btn-white btn-icon btn-sm mr-1"
                                            title="{{ 'settings.appearance.widget_templates.action_view' | translate }}"
                                        >
                                            <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                        </a>
                                    </td>
                                </ng-template>
                            </app-data-table>
                        </div>
                    </div>
                </div>
                <div class="row mt-3 flex-column-reverse flex-sm-row">
                    <div class="col-12 col-sm-2">
                        <app-items-per-page-select
                            (itemsPerPageChanged)="itemsPerPageChanged($event)"
                            [itemsPerPage]="itemsPerPage"
                            [total]="itemsTotal"
                        ></app-items-per-page-select>
                    </div>

                    <div class="col-12 col-sm-10">
                        <app-pagination
                            (pageChanged)="pageChanged($event)"
                            [itemsPerPage]="itemsPerPage"
                            [page]="page"
                            [total]="itemsTotal"
                        ></app-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="defaultWidgetTemplateID$ | async" class="row">
    <div class="col-12">
        <div class="card shadow">
            <app-help name="{{'settings.appearance.widget_default_template'}}"></app-help>
            <div class="card-header py-3">
                <div class="row">
                    <div class="col text-left my-auto">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">
                            <span>{{ ('settings.appearance.widget_default_template.title') | translate }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <form
                    autocomplete="off"
                    [formGroup]="defaultWidgetTemplateForm"
                    (ngSubmit)="onSubmitDefaultTemplate()"
                >
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label class="control-label">
                                    <span>{{ 'settings.appearance.widget_default_template.template' | translate }}</span>
                                </label>

                                <ng-select
                                    [multiple]="false"
                                    [closeOnSelect]="true"
                                    [items]="rows"
                                    [bindLabel]="'name'"
                                    [bindValue]="'id'"
                                    class="custom"
                                    formControlName="widget_templates.default_id"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-2">
                        <div class="col d-flex justify-content-end">
                            <button class="btn btn-success">
                                <span>{{ 'global.action_save' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
