import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeePosition } from 'src/app/employee/models/EmployeePosition';
import { EmployeePositionService } from 'src/app/employee/services/employee-position.service';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { CompanyService } from '../../../../setting/services/company.service';
import { ChangeEmployeeCompanyGroupModalComponent } from '../../partials/change-employee-company-group/change-employee-company-group-modal.component';

@Component({
    selector: 'app-employee-positions-files',
    templateUrl: './employee-positions-files.component.html',
    styleUrls: ['./employee-positions-files.component.css']
})
export class EmployeePositionsFilesComponent implements OnInit, OnDestroy {
    public employee: Employee;
    public employee$: Observable<Employee>;
    public employeeID: number;
    public employeePositions$: Observable<Array<EmployeePosition>>;
    public availableWidgets$: Observable<AvailableWidgets>;
    public permissions: Array<string>;
    public watchingMyself = false;
    public noCompanyGroup = false;
    public companyGroupChanging = false;

    private _routeSubscription: Subscription;

    public constructor(
        private _employeeService: EmployeeService,
        private _companyService: CompanyService,
        private _positionService: EmployeePositionService,
        private _authService: AuthenticationService,
        private _route: ActivatedRoute,
        private _cdr: ChangeDetectorRef,
        private _modalService: NgbModal,
    ) { }

    public ngOnDestroy(): void {
        this._routeSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.availableWidgets$ = this._companyService.availableWidgets$;

        this.permissions = this._authService?.permissions?.map(perm => perm.name);

        this._routeSubscription = this._route.parent.params.subscribe(params => {
            this.employeeID = parseInt(params.id, 10);
            if (this.employeeID) {
                const loggedEmployeeID = this._authService?.employee?.employee_ID;
                this.watchingMyself = loggedEmployeeID === this.employeeID;
                this._cdr.detectChanges();

                this.employee$ = this._employeeService.getEmployeeInfoByEmployeeID(this.employeeID, 'extend')
                    .pipe(tap(employee => {
                            if (employee.company_group === null) {
                                this.noCompanyGroup = true;
                                this.employee = employee;
                            }
                        }),
                        shareReplay());

                this.getPositions();
            }
        });

    }

    public checkPermission(name: string): boolean {
        return (this.watchingMyself || this.permissions.includes(name + '.view') || this.permissions.includes(name + '.viewStructure'));
    }

    public getPositions(): void {
        this.employeePositions$ = this._positionService.getAllPositionsByEmployeeID(this.employeeID);
    }

    public openEmployeeChangeCompanyGroupModal(): void {
        const modalRef = this._modalService.open(ChangeEmployeeCompanyGroupModalComponent, {centered: true});

        this.companyGroupChanging = true;

        modalRef.componentInstance.employee = this.employee;

        modalRef.result
            .then(
                () => this.companyGroupChanging = false,
                () => this.companyGroupChanging = false
            );
    }
}
