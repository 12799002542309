import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ValueOrDashComponent } from './components/value-or-dash.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ValueOrDashComponent
  ],
  exports: [
    ValueOrDashComponent
  ]
})
export class UIValueOrDashModule { }
