import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, merge, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

interface RouteDefinition {
    name: string;
    routerLink: string;
}

@Component({
    selector: 'app-employee-attendance',
    templateUrl: './employee-attendance.component.html',
    styleUrls: ['./employee-attendance.component.css']
})
export class EmployeeAttendanceComponent implements OnInit {
    public pageTitle$: Observable<string>;
    public routes$: Observable<Array<RouteDefinition>>;

    private _canViewDocuments$: Observable<boolean>;
    private _canViewToApprove$: Observable<boolean>;

    public constructor(
        private _authService: AuthenticationService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _translateService: TranslateService
    ) {
        this._setup();
    }

    public ngOnInit(): void {
        this.pageTitle$ = merge(
            this._router.events.pipe(filter(e => e instanceof NavigationEnd)),
            this._route.firstChild.data
        )
            .pipe(
                switchMap(() => merge(
                    this._route.data.pipe(map(data => data.title)),
                    this._route.firstChild.data.pipe(map(data => data.title)),
                    this._authService.pageTitle
                )),
                filter(title => title !== null)
            );
    }

    private _setup(): void {
        const canApproveOrDeny$ = this._authService.hasPermissionTo('employeeDatetime.approveOrDeny')
            .pipe(
                map(permission => permission.can),
                shareReplay()
            );

        const canApproveOrDenyStructure$ = this._authService.hasPermissionTo('employeeDatetime.approveOrDenyStructure')
            .pipe(
                map(permission => permission.can),
                shareReplay()
            );

        this._canViewDocuments$ = combineLatest([
            canApproveOrDeny$,
            canApproveOrDenyStructure$,
        ])
            .pipe(
                map(([canApproveOrDeny, canApproveOrDenyStructure]) => canApproveOrDeny || canApproveOrDenyStructure),
                shareReplay()
            );

        this._canViewToApprove$ = combineLatest([
            canApproveOrDeny$,
            canApproveOrDenyStructure$,
        ])
            .pipe(
                map(([canApproveOrDeny, canApproveOrDenyStructure]) => canApproveOrDeny || canApproveOrDenyStructure),
                shareReplay()
            );

        this.routes$ = combineLatest([
            this._canViewDocuments$,
            this._canViewToApprove$,
        ])
            .pipe(
                map(([canViewDocuments, canViewToApprove]) => {
                    const routes: Array<RouteDefinition | null> = [];

                    if (canViewToApprove) {
                        routes.push({
                            name: this._translateService.instant('employees_datetimes.page_title_index_to_approve'),
                            routerLink: 'to-approve'
                        });
                    }

                    routes.push(
                        {
                            name: this._translateService.instant('employees_datetimes.page_title_index_chart'),
                            routerLink: 'chart'
                        },
                        {
                            name: this._translateService.instant('employees_datetimes.page_title_index_history'),
                            routerLink: 'history'
                        }
                    );

                    if (canViewDocuments) {
                        routes.push({
                            name: this._translateService.instant('employees_datetimes.page_title_index_attendance_lists'),
                            routerLink: 'documents'
                        });
                    }

                    return routes;
                })
            );
    }
}
