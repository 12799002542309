import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { localStorageSafe } from '../functions';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public canShowBasicSettingsModal$: Observable<boolean>;
    public canShowUsersToBeActivatedMessage$: Observable<boolean>;
    public pageTitleObject = new BehaviorSubject<{ label: string, type: string }>({label: null, type: null});
    public appVersion: string;
    public notifyNewVersion = false;

    private _canShowBasicSettingsModal$ = new ReplaySubject<boolean>(1);
    private _canShowUsersToBeActivatedMessage$ = new ReplaySubject<boolean>(1);

    public constructor(private _http: HttpClient) {
        this.canShowBasicSettingsModal$ = this._canShowBasicSettingsModal$;
        this.canShowUsersToBeActivatedMessage$ = this._canShowUsersToBeActivatedMessage$;
    }

    public getAppVersion(): Observable<string> {
        return this._http.get<{ version: string }>('/api/app/version')
            .pipe(map(response => response.version));
    }

    public getAvailableLanguages(): Observable<Array<string>> {
        return this._http.get<{ available_locales: Array<string>; }>('/api/app/locales')
            .pipe(map(response => response.available_locales));
    }

    public getAvailableLocales(): Observable<Array<string>> {
        return this._http.get<Array<string>>('/api/translations/locales');
    }

    public getLogoUrl(): Observable<string> {
        return this._http.get<{ company_logo_file: { url: string; } }>('/api/app/logo')
            .pipe(
                map(response => response.company_logo_file.url),
                catchError(() => of('assets/images/logo-inove.svg'))
            );
    }

    public setCurrentRoute(navigationEnd: NavigationEnd): void {
        if (navigationEnd.urlAfterRedirects !== '/setting/subscription-settings') {
            this._canShowBasicSettingsModal$.next(true);
            this._canShowUsersToBeActivatedMessage$.next(true);
        } else {
            this._canShowBasicSettingsModal$.next(false);
            this._canShowUsersToBeActivatedMessage$.next(false);
        }
    }

    public reloadAppWithNewVersion(): void {
        localStorageSafe.setItem('new_version_loaded', 'true');
        console.log('RELOADING THE PAGE');
        window.location.reload();
    }
}
