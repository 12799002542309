<!-- <ng-container *ngIf="(canViewRoles$ | async)?.can">
  <app-roles id="roles"></app-roles>
</ng-container> -->

<ng-container *ngIf="(canViewUsers$ | async)?.can">
  <app-user-list id="users"></app-user-list>
</ng-container>

<ng-container *ngIf="(canViewRoles$ | async)?.can">
  <app-roles-review id="roles"></app-roles-review>
</ng-container>
