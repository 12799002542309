import { Component, Input, EventEmitter, Output } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { EmployeeDateTime } from 'src/app/employee/models/EmployeeDateTime';

import { CreateEditEmployeeDatetimeModalComponent } from '../create-edit-employee-datetime-modal/create-edit-employee-datetime-modal.component';

@Component({
  selector: 'app-employee-attendance-parts',
  templateUrl: './employee-attendance-parts.component.html',
  styleUrls: ['./employee-attendance-parts.component.css']
})
export class EmployeeAttendancePartsComponent {
  @Input()
  public employeeID: number;

  @Input()
  public employeeDateTime: EmployeeDateTime;

  @Output()
  public refetchDateTime = new EventEmitter<string>();

  public constructor(private _modalService: NgbModal) { }

  public openEditModal(datetime: EmployeeDateTime): void {
    const modalRef = this._modalService.open(CreateEditEmployeeDatetimeModalComponent, { centered: true });

    modalRef.componentInstance.employeeDateTime = datetime;

    modalRef.componentInstance.employeeID = datetime.employee_ID;

    modalRef.result
      .then(
        () => this.refetchDateTime.emit('refresh'),
        () => { }
      );
  }
}
