<div *ngIf="checkPermission('employeePosition') && (availableWidgets$ | async)?.employee?.['positions-files']?.position" class="row">
  <div class="col-12">
    <app-employee-positions
      (refetchPositions)="getPositions()"
      *ngIf="employeePositions$"
      [employee]="employee$ | async"
      [positions$]="employeePositions$"
    ></app-employee-positions>
  </div>
</div>

<app-employee-salaries-payrolls [employee$]="employee$"></app-employee-salaries-payrolls>

<div *ngIf="(availableWidgets$ | async)?.employee?.['positions-files']?.structure" class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help name="employee.detail.structure"></app-help>

      <div class="card-header py-3">
        <p class="modal-title font-16 text-dark pt-2 pb-2">
          <span>{{ 'company_groups.structure_tree' | translate }}</span>
        </p>
      </div>

      <div class="card-body px-4">
          <div *ngIf="noCompanyGroup" class="row mb-2">
              <div class="col-12">
                  <span> {{ 'company_groups.no_company_group_text' | translate }} </span>
                  <u (click)="openEmployeeChangeCompanyGroupModal()"> {{ 'employees.change_company_group' | translate }} </u>
              </div>
          </div>
        <app-employee-structure-tree *ngIf="!companyGroupChanging"
                                     [hideEmployeeFromStucture]="(employee$ | async)?.is_hidden_in_structure"
                                     [employeeId]="employeeID"
                                     [justChart]="true"
        ></app-employee-structure-tree>
      </div>
    </div>
  </div>
</div>
