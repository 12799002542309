import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { cacheable } from '@datorama/akita';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';

import { Receivable } from './receivable.model';
import { ReceivableStore } from './receivable.store';

@Injectable({
  providedIn: 'root'
})
export class ReceivableService {
  public constructor(
    private _apiHelper: ApiHelper,
    private _http: HttpClient,
    private _receivableStore: ReceivableStore,
    private _swalHelper: SwalHelper
  ) { }

  async deleteReceivable(employeeID: number, receivableID: number | string, modalRef: NgbActiveModal): Promise<void> {
    const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

    if (deleteConfirmed) {
      this._http.delete(`/api/employees/${employeeID}/receivables/${receivableID}`, {})
        .subscribe(
          (response: any) => {
            this._receivableStore.remove(receivableID);

            this._apiHelper.handleSuccessResponse(response);

            modalRef.close();
          },
          error => this._apiHelper.handleErrorResponse(error)
        );
    }
  }

  public get(employeeID: number): Observable<void> {
    const request = this._http.get<Array<Receivable>>(`/api/employees/${employeeID}/receivables`)
      .pipe(
        map(response => this._receivableStore.set(response))
      );

    return cacheable(this._receivableStore, request);
  }

  public getAllByEmployeeId(employeeID: number): Observable<Array<Receivable>> {
    return this._http.get<Array<Receivable>>(`/api/employees/${employeeID}/receivables`);
  }

  public saveReceivable(form: Receivable, employeeID: number, modalRef: NgbActiveModal, receivableID?: number | string): void {
    const apiUrl = receivableID ? `/api/employees/${employeeID}/receivables/${receivableID}` : `/api/employees/${employeeID}/receivables`;

    this._http.post<ApiResponse & { receivable: Receivable; }>(apiUrl, form)
      .subscribe(
        response => {
          this._apiHelper.handleSuccessResponse(response);

          if (receivableID) {
            this._receivableStore.update(receivableID, response.receivable);
          } else {
            this._receivableStore.add(response.receivable);
          }

          modalRef.close();
        },
        error => this._apiHelper.handleErrorResponse(error)
      );
  }
}
