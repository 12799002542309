<div
  *ngIf="shown && !listLoading"
  class="d-flex align-items-center justify-content-end"
>
  <!-- <button
    class="pagination-button"
    [disabled]="isCurrentPageFirst"
    (click)="pageChange(page - 1)"
  >
    <i class="mdi mdi-chevron-left"></i>
    <span>{{ 'pagination.previous' | translate }}</span>
  </button> -->

  <button
    class="pagination-button"
    *ngFor="let step of steps"
    [class.active]="step === page"
    [disabled]="step === 0 || step === page"
    (click)="pageChange(step)"
  >{{ step || '…' }}</button>

  <!-- <button
    class="pagination-button"
    [disabled]="isCurrentPageLast"
    (click)="pageChange(page + 1)"
  >
    <i class="mdi mdi-chevron-right"></i>
    <span>{{ 'pagination.next' | translate }}</span>
  </button> -->
</div>
