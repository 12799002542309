import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable, Subscription } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { SubscriptionService } from 'src/app/setting/services/subscription.service';
import { ModuleNavigationRoute } from 'src/app/shared/models/ModuleNavigationRoute';
import { AppService } from 'src/app/shared/services/app.service';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { CompanyService } from '../../../../setting/services/company.service';
import { AvailableFeatures } from '../../../models/AvailableFeatures';

@Component({
    selector: 'app-employee-detail',
    templateUrl: './employee-detail.component.html',
    styleUrls: ['./employee-detail.component.css']
})
export class EmployeeDetailComponent implements OnInit, OnDestroy {
    public employee: Employee;
    public pageTitle$: Observable<string>;
    public availableFeatures$: Observable<AvailableFeatures>;
    public availableWidgets$: Observable<AvailableWidgets>;
    public availableRoutes: Array<ModuleNavigationRoute> = [];
    public watchingMyself: boolean;
    public loggedUserPermissions: Array<string>;
    public allRoutes: Array<ModuleNavigationRoute> = [
        {
            routerLink: 'overview',
            title: this._translateService.instant('employees.page_title_view_overview')
        },
        {
            routerLink: 'positions-files',
            title: this._translateService.instant('employees.page_title_view_position_structure')
        },
        {
            routerLink: 'documents',
            title: this._translateService.instant('employees.page_title_view_files_payrolls')
        },
        {
            routerLink: 'properties',
            title: this._translateService.instant('employees.page_title_view_properties_benefits')
        },
        {
            routerLink: 'evaluations',
            title: this._translateService.instant('employees.page_title_view_kpis'),
        },
        {
            routerLink: 'analysis',
            title: this._translateService.instant('employees.page_title_view_analysis')
        },
        {
            routerLink: 'notes',
            title: this._translateService.instant('employees.page_title_view_activity')
        },
        {
            routerLink: 'datetimes',
            title: this._translateService.instant('employees.page_title_view_datetimes')
        },
        {
            routerLink: 'setting',
            title: this._translateService.instant('employees.page_title_view_setting')
        },
    ];

    private _employeeSubscription: Subscription;

    public constructor(
        private _appService: AppService,
        private _authService: AuthenticationService,
        private _employeeService: EmployeeService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _translateService: TranslateService,
        private _subscriptionService: SubscriptionService,
        private _companyService: CompanyService
    ) { }

    public ngOnDestroy(): void {
        if (
            this._employeeSubscription &&
            typeof this._employeeSubscription.unsubscribe === 'function'
        ) {
            this._employeeSubscription?.unsubscribe();
        }

        this._appService.pageTitleObject.next({label: null, type: null});
    }

    public ngOnInit(): void {
        this.availableFeatures$ = this._subscriptionService.availableFeatures$;

        this.availableWidgets$ = this._companyService.availableWidgets$;

        this.loggedUserPermissions = this._authService?.permissions?.map(perm => perm.name);

        this.pageTitle$ = merge(
            this._router.events.pipe(filter(e => e instanceof NavigationEnd)),
            this._route.firstChild.data
        )
            .pipe(
                switchMap(() => merge(
                    this._route.data.pipe(map(data => data.title)),
                    this._route.firstChild.data.pipe(map(data => data.title)),
                    this._authService.pageTitle
                )),
                filter(title => title !== null)
            );

        this._employeeSubscription = this._route.params
            .pipe(
                map(params => parseInt(params.id, 10)),
                switchMap(employeeId => this._employeeService.getEmployeeInfoByEmployeeID(employeeId, 'minimal|navigation|permissions:view')
                    .pipe(map(employee => employee))
                ),
                switchMap(employee => this.availableWidgets$
                    .pipe(map(availableWidgets => ({employee, availableWidgets})))
                ),
                switchMap(({employee, availableWidgets}) => this.availableFeatures$
                    .pipe(map(availableFeatures => ({employee, availableWidgets, availableFeatures})))
                ),
                map(({employee, availableWidgets, availableFeatures}) => {
                    const loggedEmployeeID = this._authService?.employee?.employee_ID;
                    this.watchingMyself = loggedEmployeeID === employee?.employee_ID;
                    this.availableRoutes = [];
                    this.employee = employee;

                    this.allRoutes.forEach(route => {
                        if (this.isMenuItemVisible(route, availableWidgets, availableFeatures)) {
                            this.availableRoutes.push(route);
                        }
                    });

                    return employee;
                }),
                shareReplay()
            ).subscribe(employee => {
                this._appService.pageTitleObject.next({label: employee.fullname, type: 'EMPLOYEE'});
            });
    }

    public isMenuItemVisible(route: ModuleNavigationRoute, availableWidgets: AvailableWidgets, availableFeatures: AvailableFeatures): boolean {
        const currentWidgets = Object.values(availableWidgets?.employee[route.routerLink]);

        // check if user has permission for more than 0 widgets for this route
        switch (route.routerLink) {
            case 'overview':
                return true;
            case 'documents':
                if (!this.checkPermissions(['employeeFile', 'employeePayroll'])) {
                    return false;
                }
                break;
            case 'properties':
                if (!this.checkPermissions(['benefit', 'employeeProperty'])) {
                    return false;
                }
                break;
            case 'evaluations':
                if (!this.checkPermissions(['kpi', 'growth', 'employeeReview'])) {
                    return false;
                }
                if (!(availableFeatures.growths || availableFeatures.evaluations)) {
                    return false;
                }
                break;
            case 'analysis':
                if (!this.checkPermissions(['analyse'])) {
                    return false;
                }
                if (!this.checkPermissions(['employeeSalary', 'employeeBonus', 'employeeDatetime', 'employeeReview'])) {
                    return false;
                }
                break;
            case 'notes':
                if (!this.checkPermissions(['employeeActivity'])) {
                    return false;
                }
                break;
            case 'datetimes':
                if (!this.checkPermissions(['companyEmployeePolicy', 'employeePolicyAdjustement', 'employeeDatetime'])) {
                    return false;
                }
                break;
            case 'setting':
                if (!this.checkPermissions(['employee', 'security'], 'edit')) {
                    return false;
                }
                break;
        }

        // check if more than 0 widgets is available for this route
        for (const item of currentWidgets) {
            if (item) {
                return true;
            }
        }

        return false;
    }

    public checkPermissions(permissions: Array<string>, action = 'view'): boolean {
        if (this.watchingMyself) {
            return true;
        }

        for (const perm of permissions) {
            if (this.loggedUserPermissions.includes(perm + '.' + action) || this.loggedUserPermissions.includes(perm + '.' + action + 'Structure')) {
                return true;
            }
        }

        return false;
    }

    public navigateToEntity(id: number): void {
        this._route.params.subscribe(params => {
            const oldID = params.id;
            void this._router.navigateByUrl(this._router.url.replace(oldID, id.toString()));
        });
    }
}
