import { QueryList } from '@angular/core';

import { DataTableDataCellDirective } from './directives/data-table-data-cell/data-table-data-cell.directive';
import { DataTableHeaderCellDirective } from './directives/data-table-header-cell/data-table-header-cell.directive';

import type { GenericObject } from './interface/generic-object.interface';
import type { Sort } from './interface/sort.interface';

import { SORT_ASC, SORT_DESC } from './types';

/**
 * Higher-order function
 *
 * // w/o lang
 * @example
 * // returns (a: T, b: T) => number
 * defaultSort({ column: 'foo', direction: SORT_ASC })
 *
 * @example
 * // returns (a: T, b: T) => number
 * defaultSort({ column: 'foo', direction: SORT_DESC })
 *
 * @example
 * // returns (a: T, b: T) => number
 * defaultSort({ column: 'foo.bar', direction: SORT_ASC })
 *
 * @example
 * // returns (a: T, b: T) => number
 * defaultSort({ column: 'foo.bar', direction: SORT_DESC })
 *
 * // w/ lang
 * @example
 * // returns (a: T, b: T) => number
 * defaultSort({ column: 'foo', direction: SORT_ASC }, 'cs')
 *
 * @example
 * // returns (a: T, b: T) => number
 * defaultSort({ column: 'foo', direction: SORT_DESC }, 'cs')
 *
 * @example
 * // returns (a: T, b: T) => number
 * defaultSort({ column: 'foo.bar', direction: SORT_ASC }, 'cs')
 *
 * @example
 * // returns (a: T, b: T) => number
 * defaultSort({ column: 'foo.bar', direction: SORT_DESC }, 'cs')
 *
 * @param sort Sort<T> object
 * @returns Sort function based on provided Sort<T> object
 */
function defaultSort<T extends GenericObject>(sort: Sort<T>, lang?: string, groupByKey?: string/*NestedPath<T>*/): (a: T, b: T) => number {
  const column = String(sort.column);
  const useAccessor = (sort.column as string).indexOf('.') > -1;

  return (a: T, b: T): number => {
    if (groupByKey) {
      const aGroup = getObjectValueByAccessor(a, groupByKey);
      const bGroup = getObjectValueByAccessor(b, groupByKey);

      if (aGroup > bGroup) {
        return 1;
      } else if (aGroup < bGroup) {
        return -1;
      }
    }

    let aValue = a[column];
    let bValue = b[column];

    if (useAccessor) {
      aValue = getObjectValueByAccessor(a, column);
      bValue = getObjectValueByAccessor(b, column);
    }

    if (
      typeof lang !== 'undefined' &&
      typeof aValue === 'string' &&
      typeof bValue === 'string'
    ) {
      if (sort.direction === SORT_ASC) {
        if (aValue) {
          return aValue?.localeCompare(bValue, lang);
        } else {
          return -1;
        }
      } else if (sort.direction === SORT_DESC) {
        if (bValue) {
          return aValue?.localeCompare(bValue, lang) * -1;
        } else {
          return -1;
        }
      } else {
        return 0;
      }
    }

    if (
      sort.direction === SORT_ASC &&
      aValue > bValue ||
      sort.direction === SORT_DESC &&
      aValue < bValue
    ) {
      return 1;
    } else if (
      sort.direction === SORT_ASC &&
      aValue < bValue ||
      sort.direction === SORT_DESC &&
      aValue > bValue
    ) {
      return -1;
    } else {
      return 0;
    }
  };
}

function extendDataCellWithHeaderCellValue<T>(
  headerCells: QueryList<DataTableHeaderCellDirective<T>>,
  dataCells: QueryList<DataTableDataCellDirective>
): void {
  const dataCellsCount = dataCells.length;
  const headerCellsCount = headerCells.length;

  const dataCellIndicesIndexedByHeaderCellIndex: { [headerCellIndex: number]: Array<number>; } = {};
  const rowsCount = dataCellsCount / headerCellsCount;

  /**
   * @example for 10 headerCells
   * // 0: 0, 10, 20, …
   * // 1: 1, 11, 21, …
   * // 2: 2, 12, 22, …
   * // 3: 3, 13, 23, …
   * // …
   *
   * @example for 6 headerCells
   * // 0: 0, 6, 12, …
   * // 1: 1, 7, 13, …
   * // 2: 2, 8, 14, …
   * // 3: 3, 9, 15, …
   * // …
   */
  for (let i = 0; i < headerCellsCount; i++) { // tslint:disable-line:prefer-const
    dataCellIndicesIndexedByHeaderCellIndex[i] = [i];

    for (let n = 1; n < rowsCount; n++) {
      dataCellIndicesIndexedByHeaderCellIndex[i].push(i + (n * headerCellsCount));
    }
  }

  let cell;

  headerCells.forEach((headerCell, index) => {
    for (let header of dataCellIndicesIndexedByHeaderCellIndex[index]) { // tslint:disable-line:prefer-const
      cell = dataCells.get(header);

      if (cell) {
        cell.headerName = headerCell.content;
      } else {
        if (dataCellsCount) {
          console.error('Possible header & body cell count mismatch');
        }
      }
    }
  });
}

/**
 * @example
 * // returns 'bar'
 * getObjectValueByAccessor({ foo: 'bar' }, 'foo')
 *
 * @example
 * // returns 'baz'
 * getObjectValueByAccessor({ foo: { bar: 'baz' } }, 'foo.bar')
 *
 * @param object Object to search in
 * @param accessor Key or nested keys separated by dot
 * @returns Value found using accessor
 */
function getObjectValueByAccessor<T>(object: T, accessor: string): any {
  accessor = accessor.replace(/\[(\w+)\]/g, '.$1'); // convert indices to properties
  accessor = accessor.replace(/^\./, ''); // strip a leading dot

  const keys = accessor.split('.');
  let key;

  let value: any = object;

  for (let i = 0, n = keys.length; i < n; ++i) {
    key = keys[i];

    if (
      typeof value !== 'undefined' &&
      key in value
    ) {
      value = value[key];
    } else {
      return '';
    }
  }

  return value;
}

export {
  defaultSort,
  extendDataCellWithHeaderCellValue,
  getObjectValueByAccessor
};
