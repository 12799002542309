import { Injectable } from '@angular/core';

import { IWindow } from '../interface/window.interface';

function _window(): IWindow {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class WindowRef {
  public get nativeWindow(): IWindow {
    return _window();
  }
}
