<ng-template #tableTemplate>
  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <app-data-table
          [disableInPlaceFiltering]="true"
          [disableInPlaceSorting]="true"
          [loading]="loading"
          [rows]="rows"
          [search]="search"
          [sort]="sort"
          [trackByFn]="trackByFn"
          [showSearch]="false"
          (searchChanged)="searchChanged($event)"
          (sortChanged)="sortChanged($event)"
        >
          <ng-template
            #headerButton
            *ngIf="dataOnly && (canCreate$ | async)"
          >
            <button
              class="ml-sm-2"
              [createButton]="'scheduled_task.action_create' | translate"
              (click)="openEditModal()"
            ></button>
          </ng-template>

          <ng-template #headerRow *ngIf="showHeader">
            <th
              appDataTableHeaderCell="data.title"
              [disableSort]="true"
            >{{ 'scheduled_task.name' | translate }}</th>


            <th appDataTableHeaderCell="scheduled_at" >{{ 'scheduled_task.scheduled_at' | translate }}</th>

            <th
              appDataTableHeaderCell
              class="text-right"
              [disableSort]="true"
            >{{ 'scheduled_task.table_action' | translate }}</th>
          </ng-template>

          <ng-template
            #dataRow
            let-row
          >
            <td appDataTableDataCell>
              <div>{{ row.data.title }}</div>
              <div *ngIf="mode == 'compact'" class="text-muted font-13">{{ row.scheduled_at | date:'d. M. Y' }}</div>
            </td>

            <td appDataTableDataCell *ngIf="mode != 'compact'">{{ row.scheduled_at | date:'d. M. Y' }}</td>

            <td
              appDataTableDataCell
              class="text-right"
            >
              <button
                class="btn btn-white btn-icon btn-sm mr-1"
                title="{{ 'scheduled_task.action_view' | translate }}"
                (click)="openEditModal(row)"
              >
                <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
              </button>
            </td>
          </ng-template>
        </app-data-table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #pageTemplate>
  <div class="row h-100">
    <div class="col-12">
      <div class="card shadow h-100">
        <app-help name="employee.detail.notifications"></app-help>

        <div class="card-header py-3">
          <div class="row">
            <div class="col-6 text-left">
              <p class="modal-title font-16 pt-2 pb-2 text-dark">
                <span>{{ 'scheduled_task.page_title_index' | translate }}</span>
              </p>
            </div>
            <div class="col-6 text-right">
              <button
                [createButton]="'scheduled_task.action_create' | translate"
                (click)="openEditModal()"
              ></button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <ng-container [ngTemplateOutlet]="tableTemplate"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-container
  *ngIf="dataOnly; else pageTemplate"
  [ngTemplateOutlet]="tableTemplate"
></ng-container>
