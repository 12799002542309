<div class="position-fixed px-2 rounded-pill text-nowrap" style="font-size: 0.75rem; line-height: 1rem; background-color: rgb(229 231 235 / 0.5); border-color: rgb(229 231 235); left:50%; transform: translate(-50%, -50%); z-index: 1001">
    <span class="py-1 rounded-pill px-3 d-inline d-sm-none text-white" style="border-width: 1px; background-color: rgba(239 68 68 / 0.5); border-color: rgba(239 68 68 / 0.9);">XS</span>
    <span class="py-1 rounded-pill px-2 d-none d-sm-inline text-dark">XS</span>
    <span class="py-1 rounded-pill px-3 d-none d-sm-inline d-md-none text-white" style="border-width: 1px; background-color: rgba(245 158 11 / 0.5); border-color: rgba(245 158 11 / 0.9);">SM</span>
    <span class="py-1 rounded-pill px-2 d-inline d-sm-none d-md-inline text-dark">SM</span>
    <span class="py-1 rounded-pill px-3 d-none d-md-inline d-lg-none text-white" style="border-width: 1px; background-color: rgba(16 185 129 / 0.5); border-color: rgba(16 185 129 / 0.9);">MD</span>
    <span class="py-1 rounded-pill px-2 d-inline d-md-none d-lg-inline text-dark">MD</span>
    <span class="py-1 rounded-pill px-3 d-none d-lg-inline d-xl-none text-white" style="border-width: 1px; background-color: rgba(14 165 233 / 0.5); border-color: rgba(14 165 233 / 0.9);">LG</span>
    <span class="py-1 rounded-pill px-2 d-inline d-lg-none d-xl-inline text-dark">LG</span>
    <span class="py-1 rounded-pill px-3 d-none d-xl-inline d-xxl-none text-white" style="border-width: 1px; background-color: rgba(168 85 247 / 0.5); border-color: rgba(168 85 247 / 0.9);">XL+</span>
    <span class="py-1 rounded-pill px-2 d-inline d-xl-none d-xxl-inline text-dark">XL+</span>


</div>
