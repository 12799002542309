<ng-container *ngIf="(canViewCompanySettings$ | async)?.can && (availableIntegrations$ | async) as availableIntegrations; else loadingTemplate">
    <app-help name="settings.integrations"></app-help><!--(canViewCompanySettings$ | async)?.can && (availableIntegrations$ | async) as availableIntegrations-->
    <div class="row">
        <ng-container *ngFor="let integration of availableIntegrations">
            <div class="col-md-6 mb-3">
                <div class="card h-100 shadow integration-card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4 text-left my-auto">
                    <span class="integration-icon">
                      <img [alt]="integration.name" [src]="'assets/images/integrations/' + integration.logo_url"/>
                    </span>
                            </div>
                            <div class="col-8 text-right integration-button">
                                <ng-container *ngIf="integration.is_active && integration.is_available">
                                    <span class="btn btn-sm btn-primary status-text">
                                        <i class="mdi mdi-check"></i>
                                        {{ 'integrations.active' | translate }}
                                    </span>
                                    <button (click)="openIntegrationModal(integration)"
                                            class="btn btn-sm btn-light">
                                        {{ 'integrations.settings' | translate }}
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="!integration.is_active && integration.is_available">
                                    <button (click)="openIntegrationModal(integration)"
                                            class="btn btn-sm btn-light">
                                        {{ 'integrations.settings' | translate }}
                                    </button>
                                </ng-container>
                                <a
                                    *ngIf="!integration.is_active && !integration.is_available"
                                    aria-label=""
                                    class="btn btn-sm btn-light btn-disabled"
                                    href="mailto:support@speybl.com"
                                >{{ 'integrations.contact_us' | translate }}
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="mt-3 text-dark">{{ integration.name }}</h4>
                                <p *ngIf="false" class="mt-2 mb-0"> {{ integration.description }} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="row">
        <div class="col-12 mb-3 text-center my-auto">
            <div class="card h-100 shadow integration-card d-flex">
                <div class="card-body align-items-center d-flex justify-content-center">
                    <div>
                        {{ 'integrations.contact_us_text' | translate }}
                        <a href="mailto:support@speybl.com"> support@speybl.com </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container>

<ng-template #loadingTemplate>
    <div class="row">
        <div *ngFor="let num of Array(20)" class="col-12 col-md-6">
            <div class="card shadow p-3 pb-4">
                <div class="d-flex w-100 justify-content-between">
                    <div>
                        <ngx-skeleton-loader
                            [theme]="{ width: '50px', height: '50px' }"
                        ></ngx-skeleton-loader>
                    </div>
                    <div>
                        <ngx-skeleton-loader
                            [theme]="{ width: '150px', height: '30px' }"
                        ></ngx-skeleton-loader>
                    </div>
                </div>
                <div>
                    <ngx-skeleton-loader
                        [theme]="{ width: '170px' }"
                    >
                    </ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</ng-template>
