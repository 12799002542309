import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Note } from './note.model';


@Injectable({
  providedIn: 'root'
})
export class NoteService {
  public constructor(
    private _http: HttpClient,
    private _apiHelper: ApiHelper,
    private _loaderService: NgxUiLoaderService,
    ) { }

  public getNote(entity?: any): Observable<Note> {
    if (entity) {
      return this._http.post<Note>('/api/notes/get', entity);
    }
    else {
      return this._http.post<Note>('/api/notes/get', null);
    }
  }


  public saveNote(note: Note, entity?: any): Observable<Note | null> {

    this._loaderService.start();
    const apiUrl = '/api/notes';

    if (entity){
      note.id = entity.id;
      note.type = entity.type;
    }


    return this._http.post<ApiResponse & {note: Note}> (apiUrl, note)
        .pipe(
          tap(response => {
            this._apiHelper.handleSuccessResponse(response);
            this._loaderService.stop();
          }),
          map(response => response.note),
          catchError(error => {
            this._apiHelper.handleErrorResponse(error);
            this._loaderService.stop();
            return of(null);
          })
        );

  }

}
