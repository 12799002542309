import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { CompanyBranch } from 'src/app/setting/models/CompanyBranch';
import { BranchStoreService } from 'src/app/setting/services/branch-store.service';
import { CountryStoreService } from 'src/app/setting/services/country-store.service';
import { DocumentDetailComponent } from '../../../../document/components/sections/document-detail/document-detail.component';
import { FileService } from '../../../../shared/services/file.service';

@Component({
    selector: 'app-create-edit-branch',
    templateUrl: './create-edit-branch.component.html',
    styleUrls: ['./create-edit-branch.component.css']
})
export class CreateEditBranchComponent implements OnInit {
    public submitted = false;
    public branchEditForm: UntypedFormGroup;
    public branch: CompanyBranch;
    public isCompany = false;
    public hasSignature = false;
    public processFileQueue$: Observable<void>;
    public clearFileQueue$: Observable<void>;
    public file: File;

    private _clearFileQueue$ = new Subject<void>();
    private _processFileQueue$ = new Subject<void>();

    constructor(
        private fb: UntypedFormBuilder,
        private _modalService: NgbModal,
        private _fileService: FileService,
        public activeModal: NgbActiveModal,
        public countryStoreService: CountryStoreService,
        private branchStoreService: BranchStoreService
    ) { }

    public get f() {
        return this.branchEditForm.controls;
    }

    public ngOnInit(): void {
        this.branchEditForm = this.fb.group({
            name: ['', Validators.required],
            addr_line_1: ['', Validators.required],
            addr_line_2: [''],
            city: ['', Validators.required],
            postal_code: ['', Validators.required],
            country_code: [null, [Validators.required, Validators.maxLength(2)]],
            in: [null],
            tin: [null],
            signature: [null]
        });

        if (this.branch) {
            this.branchEditForm.patchValue(this.branch);
            this.hasSignature = this.branch.has_signature;
            this.isCompany = this.branch.is_company;
            if (this.branch.signature) {
                this.addedFile(this._fileService.dataURItoFile(this.branch.signature, 'company_branch_signature.png'));
            }
        }

        this.clearFileQueue$ = this._clearFileQueue$;
        this.processFileQueue$ = this._processFileQueue$;
    }

    public onSubmit(): void {
        this.submitted = true;
        if (!this.branchEditForm.valid) {
            return;
        }
        const formData = new FormData();
        formData.append('name', this.branchEditForm.value.name);
        formData.append('addr_line_1', this.branchEditForm.value.addr_line_1);
        formData.append('addr_line_2', this.branchEditForm.value.addr_line_2 ?? '');
        formData.append('city', this.branchEditForm.value.city);
        formData.append('postal_code', this.branchEditForm.value.postal_code);
        formData.append('country_code', this.branchEditForm.value.country_code);
        formData.append('signature', this.file ?? '');

        if (this.isCompany) {
            formData.append('in', this.branchEditForm.value.in ?? '');
            formData.append('tin', this.branchEditForm.value.tin ?? '');
        } else {
            formData.append('in', '');
            formData.append('tin', '');
        }

        let result: Promise<any>;
        if (this.branch) {
            result = this.branchStoreService.updateBranch(this.branch.company_branch_ID, formData, this.branchEditForm.value);
        } else {
            result = this.branchStoreService.addBranch(formData, this.branchEditForm.value);
        }
        result.then(() => this.activeModal.close('close'));
    }

    public deleteBranch(): void {
        this.branchStoreService.removeBranch(this.branch.company_branch_ID, true)
            .then(() => this.activeModal.close('close'));
    }

    public addedFile(file: File): void {
        this.file = file;
        this.branchEditForm.addControl('signature', new UntypedFormControl(file));
    }

    public viewSignature(): void {
        const modalRef = this._modalService.open(DocumentDetailComponent, {centered: true, size: 'xl'});
        modalRef.componentInstance.allowedDocumentSignature = false;
        modalRef.componentInstance.file = {fileBase64: this.branch.signature, name: 'Podpis', mime_type: 'image'};
    }

    public removeFile(): void {
        this.branchEditForm.removeControl('signature');
        this.file = null;
    }
}
