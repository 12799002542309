<div [ngClass]="{'background-img': step === 0, 'background-img-last': step === 3}"
     class="modal-content"
>
  <div *ngIf="tenantSubscription | async as tenantSubscription" class="modal-body">
    <ng-container *ngIf="loggedInUser?.is_admin && (!tenantSubscription.terms_agreed || showComplexModal || step > 1); else smallModal">
        <div class="row justify-content-center mb-4">
          <div class="col-12">
            <div class="steps-progress">
              <div class="steps-indicator">
                <div [style.width.%]="progress"
                     class="steps-indicator-progress bg-primary"
                ></div>
              </div>
              <div class="steps">
                <div [ngClass]="{'step-done' : step >= 0}"
                     class="step">
                  <span> {{ 'global.welcome_modal.step_welcome' | translate }} </span>
                </div>
                <div [ngClass]="{'step-done' : step >= 1, 'text-muted': step < 1}"
                     class="step">
                  <span> {{ 'global.welcome_modal.step_you' | translate }} </span>
                </div>
                <div [ngClass]="{'step-done' : step >= 2, 'text-muted': step < 2}"
                     class="step">
                  <span> {{ 'global.welcome_modal.step_company' | translate }} </span>
                </div>
                <div [ngClass]="{'step-done' : step >= 3, 'text-muted': step < 3}"
                     class="step">
                  <span> {{ 'global.welcome_modal.step_lets_go' | translate }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>

    <!-- welcome modal -->
        <div *ngIf="step === 0" class="card mb-0 shadow-none background-transparent">
          <div class="row">
            <div class="col-12">
              <h3 class="font-weight-bold">{{ 'global.welcome_modal.welcome_to_company_cloud' | translate}}</h3>
            </div>
          </div>
          <div style="padding: 40px 0 0 0;">
            <div class="row">
              <div class="col-8">
                <div>
                  <p>
                    {{ 'global.welcome_modal.welcome_text_1' | translate }}
                  </p>
                  <p>
                    {{ 'global.welcome_modal.welcome_text_2' | translate }}
                  </p>
                  <p class="m-0">
                    {{ 'global.welcome_modal.welcome_text_3' | translate }}
                  </p>
                  <p>
                    <a href="mailto:support@speybl.com">support@speybl.com</a>.
                  </p>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox mb-1">
                            <input #termsAgreeInput type="checkbox" id="terms_agreed" name="terms_agreed" class="custom-control-input">
                            <label for="terms_agreed" class="custom-control-label">
                                <span> {{ 'subscription.by_registering_i_agree_with' | translate }} </span>
                                <span>
                                                        <a (click)="$event.stopPropagation()" [href]="translateService.currentLang === 'cs' ? 'https://www.speybl.com/cs/obchodni-podminky' :  'https://www.speybl.com/terms-and-conditions'" target="_blank">
                                                            {{ 'subscription.agreement_terms_1_anchor_text' | translate }}.
                                                        </a>
                                                    </span>
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input #marketingInput type="checkbox" id="marketing_agreed" name="marketing_agreed" class="custom-control-input">
                            <label for="marketing_agreed" class="custom-control-label"> {{ 'public_pages.marketing_agreed' | translate }} </label>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col d-flex justify-content-start">
                <button (click)="agreeTerms(!tenantSubscription.terms_agreed, marketingInput.checked)"
                        [disabled]="!termsAgreeInput.checked"
                        class="btn btn-success"
                        type="submit">
                  {{ 'global.welcome_modal.lets_start' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- user form -->
        <div *ngIf="step === 1" class="card mb-0 shadow-none background-transparent">
          <form (ngSubmit)="onSubmitUserData()"
                [formGroup]="userDataForm">
            <div class="card-body p-0 pb-1">
              <div class="row mb-2">
                <div class="col-12">
                  <h3 class="font-weight-bold">{{ 'global.welcome_modal.about_you' | translate }}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'global.welcome_modal.name' | translate }}</span>
                    </label>
                    <input class="form-control"
                           formControlName="name"
                           name="name"
                           required
                           type="text"
                    >
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'global.welcome_modal.surname' | translate }}</span>
                    </label>
                    <input class="form-control"
                           formControlName="surname"
                           name="surname"
                           required
                           type="text"
                    >
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'global.welcome_modal.email' | translate }}</span>
                    </label>
                    <input class="form-control"
                           formControlName="email"
                           name="email"
                           readonly
                           type="text"
                    >
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'global.welcome_modal.phone' | translate }}</span>
                    </label>
                    <input class="form-control"
                           formControlName="phone"
                           name="phone"
                           type="text"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer p-0 pt-3">
              <div class="row">
                <div class="col d-flex justify-content-end">
                  <button [disabled]="userDataForm.invalid"
                          class="btn btn-success"
                          type="submit"
                  >
                    {{ 'global.welcome_modal.next_step' | translate}}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!-- company form -->
        <div *ngIf="step === 2" class="card mb-0 shadow-none background-transparent">
          <form (ngSubmit)="onSubmitCompanyData()" [formGroup]="companyDataForm" autocomplete="off">
            <div class="card-body p-0">
              <div class="row mb-2">
                <div class="col-12">
                  <h3 class="font-weight-bold">{{ 'global.welcome_modal.about_company' | translate }}</h3>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-lg-12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-3">
                        <label class="control-label">{{ 'employees.in' | translate }}</label>
                      </div>
                      <div class="col-9 text-right">
                        <span *ngIf="!customerInfoLoading" class="text-muted">{{ 'settings.basic_settings_modal.data_autoload' | translate }}</span>
                        <div *ngIf="customerInfoLoading" class="spinner-border spinner-border-sm" role="status">
                          <span class="sr-only">{{ 'settings.basic_settings_modal.loading' | translate }}</span>
                        </div>
                      </div>
                    </div>
                    <input class="form-control" formControlName="company.info.in" name="company.info.in" type="text">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="control-label">{{ 'employees.company_name' | translate }} </label>
                    <input class="form-control" formControlName="company.info.name" name="company.info.name" type="text">
                    <div *ngIf="submitted && f['company.info.name'].errors">
                      <!--<p *ngIf="f['company.info.name'].errors.required" class="text-danger validation-text">
                          {{ 'angular_validation.field' | translate }} {{ 'employees.company_name' | translate }}
                          {{ 'angular_validation.required' | translate }}
                      </p>-->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <label class="control-label mr-2" for="showForm">{{ 'settings.company.info.add_more_info' | translate }}</label>
                </div>
                <div class="col-6 text-right">
                  <input
                          (click)="setFormCollapse()"
                          [attr.aria-expanded]="showOtherFormFields"
                          [checked]="showOtherFormFields"
                          aria-controls="collapse"
                          data-switch="success"
                          id="showForm"
                          name="showForm"
                          type="checkbox"
                  >
                  <label for="showForm">&nbsp;</label>
                </div>
              </div>
              <div
                      #collapse="ngbCollapse"
                      [(ngbCollapse)]="!showOtherFormFields"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label">{{ 'employees.tin' | translate }}</label>
                      <input class="form-control" formControlName="company.info.tin" name="company.info.tin" type="text">
                      <div *ngIf="submitted && f['company.info.tin'].errors">
                        <p *ngIf="f['company.info.tin'].errors.required" class="text-danger validation-text">
                          {{ 'angular_validation.field' | translate }} {{ 'employees.tin' | translate }}
                          {{ 'angular_validation.required' | translate }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label">{{ 'settings.company.info.registered_for_vat-1' | translate }}</label>
                      <div>
                        <input data-switch="success" formControlName="company.info.registered_for_vat" id="company.info.registered_for_vat" name="company.info.registered_for_vat" type="checkbox">
                        <label for="company.info.registered_for_vat">&nbsp;</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label">{{ 'settings.company.info.registration_text' | translate }}</label>
                      <input class="form-control" formControlName="company.info.registration_text" name="company.info.registration_text" type="text">
                      <div *ngIf="submitted && f['company.info.registration_text'].errors">
                        <p *ngIf="f['company.info.registration_text'].errors.required" class="text-danger validation-text">
                          {{ 'angular_validation.field' | translate }} {{ 'settings.company.info.registration_text' | translate }}
                          {{ 'angular_validation.required' | translate }}
                        </p>
                      </div>
                      <!-- <small class="text-muted">{{ 'settings.basic_settings_modal.auto_footer' | translate }}</small> -->
                    </div>
                  </div>
                </div>
                <hr *ngIf="accounts?.length"/>
                <div *ngFor="let a of accounts; let i = index" class="row">
                  <div class="col-8">
                    <div class="form-group">
                      <label class="control-label">{{ 'accounts.bank_account' | translate }}</label>
                      <input class="form-control" formControlName="account_number-{{i}}" name="account_number-{{i}}" type="text">
                    </div>
                  </div>
                  <div class="col-4">
                    <label class="control-label">{{ 'settings.company.bank_account.bank_code' | translate }}</label>
                    <input class="form-control" formControlName="account_code-{{i}}" name="account_code-{{i}}" type="text">
                  </div>
                  <div class="col-12 mb-2">
                    <label class="control-label">{{ 'employees.bank_account_iban' | translate }}]</label>
                    <input class="form-control" formControlName="account_iban-{{i}}" name="account_iban-{{i}}" type="text">
                    <small class="text-muted">{{ 'settings.basic_settings_modal.auto_iban' | translate }}</small>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="control-label">{{ 'accounts.currency' | translate }}</label>
                      <ng-select
                              [clearable]="false"
                              [items]="currencyStoreService.currencies$ | async"
                              bindLabel="code"
                              bindValue="code"
                              class="custom"
                              formControlName="account_currency-{{i}}"
                      >
                        <ng-template let-item="item" ng-option-tmp>
                          <span>{{ 'currencies.' + item.code | translate }}</span>
                        </ng-template>
                        <ng-template let-item="item" ng-label-tmp>
                          <span>{{ 'currencies.' + item.code | translate }}</span>
                        </ng-template>
                      </ng-select>
                    </div>
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="control-label">{{ 'employees_addresses.addr_line_1' | translate }}</label>
                      <input class="form-control" formControlName="company.address.addr_line_1" name="company.address.addr_line_1" type="text">
                      <div *ngIf="submitted && f['company.address.addr_line_1'].errors">
                        <p *ngIf="f['company.address.addr_line_1'].errors.required" class="text-danger validation-text">
                          {{ 'angular_validation.field' | translate }} {{ 'employees_addresses.addr_line_1' | translate }}
                          {{ 'angular_validation.required' | translate }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="control-label">{{ 'employees_addresses.addr_line_2' | translate }}</label>
                      <input class="form-control" formControlName="company.address.addr_line_2" name="company.address.addr_line_2" type="text">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="control-label">{{ 'employees_addresses.city' | translate }}</label>
                      <input class="form-control" formControlName="company.address.city" name="company.address.city" type="text">
                      <div *ngIf="submitted && f['company.address.city'].errors">
                        <p *ngIf="f['company.address.city'].errors.required" class="text-danger validation-text">
                          {{ 'angular_validation.field' | translate }} {{ 'employees_addresses.city' | translate }}
                          {{ 'angular_validation.required' | translate }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="control-label">{{ 'employees_addresses.postal_code' | translate }}</label>
                      <input class="form-control" formControlName="company.address.postal_code" name="company.address.postal_code" type="text">
                      <div *ngIf="submitted && f['company.address.postal_code'].errors">
                        <p *ngIf="f['company.address.postal_code'].errors.required" class="text-danger validation-text">
                          {{ 'angular_validation.field' | translate }} {{ 'employees_addresses.postal_code' | translate }}
                          {{ 'angular_validation.required' | translate }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="control-label">{{ 'employees_addresses.country_code' | translate }}</label>
                      <ng-select
                              [clearable]="false"
                              [items]="countryStoreService.countries$ | async"
                              bindLabel="name"
                              bindValue="country_code"
                              class="custom"
                              formControlName="company.address.country_code"
                      >
                      </ng-select>
                      <div *ngIf="submitted && f['company.address.country_code'].errors">
                        <p *ngIf="f['company.address.country_code'].errors.required" class="text-danger validation-text">
                          {{ 'angular_validation.field' | translate }} {{ 'employees_addresses.country_code' | translate }}
                          {{ 'angular_validation.required' | translate }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer p-0 pt-3">
              <div class="row mb-2">
                <div class="col d-flex justify-content-end">
                  <button class="btn btn-success" type="submit">
                      <span>
                        {{ 'global.welcome_modal.next_step' | translate }}
                      </span>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-right">
                  <small class="text-muted">
                    {{ 'global.welcome_modal.saving_company_data_reason' | translate}}
                  </small>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!-- ending modal -->
        <div *ngIf="step === 3" class="card mb-0 shadow-none background-transparent">
          <div class="row mb-2">
            <div class="col-12">
              <h3 class="font-weight-bold">{{ 'global.welcome_modal.finish_title' | translate}}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <div>
                <p>
                  {{ 'global.welcome_modal.finish_text_1' | translate}}
                </p>
                <p>
                  {{ 'global.welcome_modal.finish_text_2' | translate}}
                  <i class="mdi mdi-help-circle mdi-22px text-primary"></i>.
                </p>
                <p class="m-0">
                  {{ 'global.welcome_modal.finish_text_3' | translate}}
                </p>
                <p>
                  <a href="mailto:support@speybl.com">support@speybl.com</a>.
                </p>
                <p>
                  {{ 'global.welcome_modal.finish_text_4' | translate}}
                </p>
              </div>
            </div>
          </div>
          <div class="row mt-2">
              <div class="col">
                  <button (click)="close(true, true)" class="btn btn-success" type="submit">
                      {{ 'global.welcome_modal.finish_action' | translate }}
                  </button>
              </div>
          </div>
          <div class="row mt-2">
            <div class="col">
                <button (click)="close(true)" class="btn btn-light" type="submit">
                    {{ 'global.welcome_modal.start_using' | translate }}
                </button>
            </div>
          </div>
        </div>
    </ng-container>
    <ng-template #smallModal>
        <div class="row">
            <div class="col-12">
                <h3 class="font-weight-bold">{{ 'global.welcome_modal.welcome_to_company_cloud' | translate}}</h3>
            </div>
        </div>
        <div style="padding: 40px 0 0 0;" class="row">
            <div class="col-8">
                <div>
                    <p>
                        {{ 'global.welcome_modal.welcome_text_1_employee' | translate}}
                    </p>
                    <p>
                        {{ 'global.welcome_modal.welcome_text_2_employee' | translate}}
                        <a target="_blank" href="{{getSmallModalDocHref()}}">
                            {{ 'global.welcome_modal.welcome_text_3_employee' | translate}}</a>.
                    </p>
                    <p *ngIf="loggedInUser?.is_admin">
                        {{ 'global.welcome_modal.welcome_text_4_employee' | translate}}
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="loggedInUser?.is_admin" class="row mt-4">
            <div class="col d-flex justify-content-start">
                <button (click)="close(false, true)" class="btn btn-success mr-2" type="submit">
                    {{ 'global.welcome_modal.finish_action' | translate }}
                </button>
                <button (click)="close()" class="btn btn-light" type="submit">
                    {{ 'global.welcome_modal.start_using' | translate }}
                </button>
            </div>
        </div>
        <div *ngIf="!loggedInUser?.is_admin" class="row mt-4">
            <div class="col d-flex justify-content-start">
                <button (click)="close()" class="btn btn-success" type="submit">
                    {{ 'global.welcome_modal.start_using' | translate }}
                </button>
            </div>
        </div>
    </ng-template>
  </div>
</div>
