import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { EmployeeDatetimeType } from '../models/EmployeeDatetimeType';

@Injectable({
    providedIn: 'root'
})
export class DatetimesTypesService {
    public constructor(private _http: HttpClient) { }

    public deleteDatetimeType(employeeDatetimeTypeID: number | string): Observable<ApiResponse> {
        return this._http.delete<ApiResponse>(`/api/datetimes-types/${employeeDatetimeTypeID}`);
    }

    public getAllDatetimeTypes(): Observable<Array<EmployeeDatetimeType>> {
        return this._http.get<Array<EmployeeDatetimeType>>('/api/datetimes-types');
    }

    public getDatetimeTypeByID(employeeDatetimeTypeID: number): Observable<EmployeeDatetimeType> {
        return this._http.get<EmployeeDatetimeType>(`/api/datetimes-types/${employeeDatetimeTypeID}`);
    }

    public getDatetimeTypesList(params: ListParameters<EmployeeDatetimeType>): Observable<ListResponse<EmployeeDatetimeType>> {
        return this._http.post<ListResponse<EmployeeDatetimeType>>('/api/datetimes-types/list', {...transformParameters(params)});
    }

    public saveDatetimeType(form: EmployeeDatetimeType) {
        return this._http.post('/api/datetimes-types', form);
    }

    public updateDatetimeType(employeeDatetimeTypeID: number | string, form: EmployeeDatetimeType) {
        return this._http.post(`/api/datetimes-types/${employeeDatetimeTypeID}`, form);
    }
}
