import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostBinding, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appDataTableDataCell]'
})
export class DataTableDataCellDirective implements AfterViewInit, OnDestroy {
  @HostBinding('attr.data-header')
  public get dataHeader(): string | null {
    return this._headerName;
  }

  @HostBinding('attr.title')
  public title: string;

  @Input()
  public set headerName(headerName: string | null) {
    this._headerName = headerName;

    this._changeDetectorRef.markForCheck();
  }

  /**
   * MutationObserver is necessary when
   * table is using VirtualScroll
   */
  @Input()
  public useMutationObserver = false;

  private _headerName: string | null;

  private _mutationObserver: MutationObserver;

  public constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _el: ElementRef
  ) { }

  public ngOnDestroy(): void {
    this._mutationObserver?.disconnect();
  }

  public ngAfterViewInit(): void {
    if (this.useMutationObserver) {
      this._setupMutationObserver();
    }

    setTimeout(() => this._setTitle()); // Need to make use of event loop
  }

  private _setupMutationObserver(): void {
    this._mutationObserver = new MutationObserver(() => this._setTitle());

    this._mutationObserver.observe(this._el.nativeElement, { characterData: true, subtree: true });
  }

  private _setTitle(): void {
    const elementText = this._el.nativeElement.innerText;

    if (this.title !== elementText) {
      this.title = elementText;
    }
  }
}
