import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomainService } from 'src/app/core/services/domain.service';
import { HelpQuery } from 'src/app/help/state/help.query';
import { HelpService } from 'src/app/shared/services/help.service';
import { WindowRef } from 'src/app/ui/window';
import { Help } from '../../../../help/state/help.model';

const LOCALHOST_ADDRESS = 'cc.lan';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
    @Input()
    public name: string;
    public help$: Observable<Help>;
    public helpModeActive$: Observable<boolean>;
    public canShowHelpKey = false;

    private _forceHelpModeForTheseTenants: Array<string> = [
        'inove',
        'wca',
    ];

    public constructor(
        private _domainService: DomainService,
        private _helpQuery: HelpQuery,
        private _helpService: HelpService,
        private _modalService: NgbModal,
        private _window: WindowRef
    ) { }

    public ngOnInit(): void {
        this.help$ = this._helpService.helpData$
            .pipe(map(data => data.find(help => help.external_ID === this.name)));

        if (
            this._forceHelpModeForTheseTenants.indexOf(this._domainService.getTenant()) > -1 ||
            this._isLocalhost()
        ) {
            this.canShowHelpKey = true;
        }

        this.helpModeActive$ = this._helpQuery.select('help_mode');
    }

    public openModal(content: any) {
        this._modalService.open(content, {centered: true});
    }

    private _isLocalhost(): boolean {
        return this._window?.nativeWindow?.location?.href?.includes(LOCALHOST_ADDRESS) || false;
    }
}
