<ng-template
  #contentSubNotPaidModal
  let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">{{ 'subscription.not_paid_title' | translate }}</p>
      <button (click)="editSubscription(modal)" *ngIf="closeable" aria-label="Close" class="close" type="button">
        <span aria-hidden="true" class="font-24 text-muted"><i class="mdi mdi-close"></i></span>
    </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <p *ngIf="!notPaidAlternativeModal" class="text-dark mb-3">{{ 'subscription.not_paid_text' | translate }}</p>
              <p *ngIf="notPaidAlternativeModal" class="text-dark mb-3">{{ 'subscription.basic_not_paid_text' | translate }}</p>
              <p *ngIf="!canEditSubscription" class="text-dark mb-3">{{ 'subscription.not_paid_text_employee' | translate }}
                {{ 'subscription.admin' | translate }} {{ loggedUser?.workspace?.admin?.name }} {{ loggedUser?.workspace?.admin?.surname }}, {{ loggedUser?.workspace?.admin?.email }}
              </p>
              <p class="text-dark mb-3">
                <span>{{ 'global.welcome_modal.welcome_text_3' | translate }} </span>
                <span>
                  <a href="mailto:info@speybl.com"> support@speybl.com </a>
                </span>
              </p>
              <div class="text-right">
                <button
                    (click)="closeable ? editSubscription(modal) : editSubscription()"
                    *ngIf="canEditSubscription"
                    class="btn btn-primary"
                >{{ 'subscription.edit_subscription_button' | translate }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template
        #preActivationModal
        let-modal
>
  <div *ngIf="companySettings" class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark d-block">
        <span>{{ 'subscription.terms_agreement_modal_title' | translate }}</span>
      </p>
    </div>

    <div class="modal-body">
      <ng-container *ngIf="loggedUser?.is_admin; else employeeTemplate">
        <div class="row mb-3">
          <div class="col-12">
            <h5 class="font-weight-bold">
              {{ 'subscription.terms_1_agreement_description' | translate }}
            </h5>
          </div>
        </div>

          <div class="form-group">
              <div class="custom-control custom-checkbox mb-1">
                  <input #termsAgreeInput type="checkbox" id="terms_agreed" name="terms_agreed" class="custom-control-input">
                  <label for="terms_agreed" class="custom-control-label">
                      <span> {{ 'subscription.by_registering_i_agree_with' | translate }} </span>
                      <span>
                            <a (click)="$event.stopPropagation()" [href]="translateService.currentLang === 'cs' ? 'https://www.speybl.com/cs/obchodni-podminky' :  'https://www.speybl.com/terms-and-conditions'" target="_blank">
                                {{ 'subscription.agreement_terms_1_anchor_text' | translate }}.
                            </a>
                        </span>
                  </label>
              </div>
          </div>
          <div [ngClass]="{'d-none': !showMarketingAgreement}" class="form-group">
              <div class="custom-control custom-checkbox">
                  <input #marketingInput type="checkbox" id="marketing_agreed" name="marketing_agreed" class="custom-control-input">
                  <label for="marketing_agreed" class="custom-control-label"> {{ 'public_pages.marketing_agreed' | translate }} </label>
              </div>
          </div>

        <div class="row mt-3">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-end">

              <button
                      (click)="agreeTerms(termsAgreeInput.checked, marketingInput?.checked, modal)"
                      [disabled]="!termsAgreeInput.checked"
                      class="btn btn-primary"
              >
                <span>{{ 'subscription.terms_agreement_modal_submit' | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #employeeTemplate>
        <p>
          {{ 'subscription.terms_agreement_employee_case' | translate }}
        </p>
        <p>
          <span> {{ 'subscription.admin' | translate }} </span>
          <span class="text-dark"> {{ loggedUser?.workspace?.admin?.name }} {{ loggedUser?.workspace?.admin?.surname }}, {{ loggedUser?.workspace?.admin?.email }} </span>
        </p>
      </ng-template>
    </div>
  </div>
</ng-template>

<div *ngIf="alerts" class="fixed-alert-wrap">
  <div *ngFor="let alert of alerts">
    <div
      [class.alert-dismissible]="alert.closeable"
      class="alert alert-{{alert.type}} alert-dismissible bg-{{alert.type}} text-white border-0 fade show"
      role="alert"
    >
      <button
        (click)="close(alert)"
        *ngIf="alert.closeable"
        aria-label="Close"
        class="close"
        data-dismiss="alert"
        type="button"
      >
        <span aria-hidden="true">&times;</span>
      </button>

      <span>{{ alert.message | translate }}</span>

        <u class="text-white" *ngIf="alert.message === 'subscription.import_alert_employees'">
            <a class="text-white text-decoration-underline font-weight-bold" [routerLink]="['/employee', 'list']"> {{ 'subscription.import_alert_employees_2' | translate }}</a>
        </u>

        <ng-container *ngIf="alert.message === 'subscription.import_alert_employees'">
            <ng-container *ngIf="(availableFeatures$ | async)?.imports; else withoutImportTemplate">
              <span>{{ 'subscription.import_alert_employees_3' | translate }}</span>
                <u class="text-white" *ngIf="alert.message === 'subscription.import_alert_employees'">
              <a class="text-white text-decoration-underline font-weight-bold" [routerLink]="['/import', 'preview']"> Import</a>
                </u>.
            </ng-container>
        </ng-container>
        <ng-template #withoutImportTemplate>.</ng-template>
      <span>&nbsp;</span>

      <a
        (click)="close(alert)"
        *ngIf="alert.message === 'subscription.users_to_be_activated'"
        [routerLink]="['/setting/subscription-settings']"
        class="text-white"
      >
        <u>{{ 'subscription.to_subscription_anchor' | translate }}</u>
      </a>

      <u
        (click)="disableDemoMode()"
        *ngIf="alert.message === 'demo.alert_text'"
        class="text-white"
      >{{ 'demo.alert_text_anchor' | translate }}</u>
    </div>
  </div>
</div>


<!-- Footer Start -->
<footer class="footer">
  <div class="container-fluid">
    <div
      *ngIf="false"
      class="row"
    >
      <div
        class="col-12 text-center"
        style="font-size: 10px;"
      >{{ year }} © Inove Agency</div>
    </div>
  </div>
</footer>
<!-- end Footer -->
