import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Onboarding } from 'src/app/onboarding/onboarding/state/onboarding.model';
import { OnboardingService } from 'src/app/onboarding/onboarding/state/onboarding.service';

@Component({
    selector: 'app-create-edit-onboarding-modal',
    templateUrl: './create-edit-onboarding-modal.component.html',
    styleUrls: ['./create-edit-onboarding-modal.component.css']
})
export class CreateEditOnboardingModalComponent implements OnInit {
    public submitted = false;
    public onboardingEditForm: UntypedFormGroup;
    public onboarding: Onboarding;

    constructor(
        public activeModal: NgbActiveModal,
        private _fb: UntypedFormBuilder,
        private onboardingService: OnboardingService
    ) { }

    public ngOnInit(): void {
        this.onboardingEditForm = this._fb.group({
            name: ['', Validators.required],
            type: [null, Validators.required],
            description: ['']
        });

        if (this.onboarding) {
            this.onboardingEditForm.patchValue({
                name: this.onboarding.name,
                type: this.onboarding.type,
                description: this.onboarding.description
            });
        }
    }

    public get f() {
        return this.onboardingEditForm.controls;
    }

    public onSubmit(): void {
        this.submitted = true;
        if (!this.onboardingEditForm.valid) {
            return;
        }
        this.onboardingService.saveOnboarding(this.onboardingEditForm.value, this.activeModal, this.onboarding?.onboarding_ID);
    }

    public deleteOnboarding(): void {
        this.onboardingService.deleteOnboarding(this.onboarding.onboarding_ID);
    }
}
