import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-setting-other',
  templateUrl: './setting-other.component.html',
  styleUrls: ['./setting-other.component.css']
})
export class SettingOtherComponent implements OnInit {
  public canViewCompanySettings$: Observable<{ can: boolean }>;

  public constructor(private _authService: AuthenticationService) { }

  public ngOnInit(): void {
    this.canViewCompanySettings$ = this._authService.hasPermissionTo('companySetting.view');
  }
}
