import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { MenuItemService } from 'src/app/menu/state/menu-item.service';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { FormatHelper } from 'src/app/shared/common/FormatHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { RolesReviewFromServer } from '../components/sections/roles-review/roles-review.component';
import { Permission } from '../models/Permission';
import { Role } from '../models/Role';

@Injectable({
    providedIn: 'root'
})
export class RolesService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _formatHelper: FormatHelper,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _menuService: MenuItemService,
        private _swalHelper: SwalHelper,
    ) { }

    public assignPrivilegesToObject(body: any): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/privileges', body)
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public deleteRole(roleID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete<ApiResponse>('/api/roles/' + roleID)
                    .subscribe(
                        response => {
                            this._loaderService.stop();

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public getAllPermissions(): Observable<Array<Permission>> {
        return this._http.get<Array<Permission>>('/api/roles/permissions');
    }

    public getAllPrivilegesByObject(objectName: string, objectID: number) {
        return this._http.get('/api/privileges/' + objectName + '/' + objectID);
    }

    public getAllRoles(guard?: string): Observable<Array<Role>> {
        let params = null;

        if (guard) {
            params = new HttpParams()
                .set('guard', guard);
        }

        return this._http.get<Array<Role>>('/api/roles', {params});
    }

    public getRoleByID(roleID: number): Observable<Role> {
        return this._http.get<Role>('/api/roles/' + roleID);
    }

    public getRolesList(params: ListParameters<Role>): Observable<ListResponse<Role>> {
        return this._http.post<ListResponse<Role>>('/api/roles/list', {...transformParameters(params)});
    }

    public getRolesReview(): Observable<RolesReviewFromServer> {
        return this._http.get<RolesReviewFromServer>('/api/roles/permissions/review');
    }

    public processEditRoleForm(form: any) {
        const formCopy = this._formatHelper.jsonCopy(form);

        const result = {
            level: formCopy.level,
            name: formCopy.name,
            permissions: []
        };

        delete formCopy.name;

        const entries = Object.entries(formCopy);

        entries.forEach(entry => {
            const actionsEntries = Object.entries(entry[1]);

            const actions = [];

            actionsEntries.forEach(actionEntry => {
                actions.push({name: actionEntry[0], active: actionEntry[1]});
            });

            result.permissions.push({name: entry[0], values: actions});
        });

        return result;
    }

    public saveRole(role: Role, roleID?: number): Promise<'done'> {
        this._loaderService.start();

        let apiUrl = '/api/roles';

        if (roleID) {
            apiUrl += `/${roleID}`;
        }

        return new Promise(resolve => {
            this._http.post<ApiResponse>(apiUrl, role)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._menuService.refetch();

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public saveRoleWithPermissions(form: any, roleID?: number): Promise<'done'> {
        this._loaderService.start();

        const apiUrl = roleID ? `/api/roles/${roleID}/edit` : '/api/roles/create';

        return new Promise(resolve => {
            this._http.post<ApiResponse>(apiUrl, form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._menuService.refetch();

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public unassignPrivilegesFromObject(objectName: string, objectID: number, privilegedID: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.delete<ApiResponse>('/api/privileges/' + objectName + '/' + objectID + '/' + privilegedID)
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public getRolesByPermissions(permissions: Array<string>): Observable<Array<string>> {
        return this._http.post<Array<string>>('/api/roles/get-by-permissions', {permissions});
    }
}
