import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { EmployeeStructureTreeComponent } from './components/sections/employee-structure-tree/employee-structure-tree.component';

@NgModule({
    declarations: [
        EmployeeStructureTreeComponent,
    ],
    imports: [
        RouterModule,
        SharedModule,
    ],
    exports: [
        EmployeeStructureTreeComponent,
    ],
})
export class EmployeeStructureTreeModule {}
