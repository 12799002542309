<div *ngIf="checkPermission('employeeActivity') && (availableWidgets$ | async)?.employee?.notes?.notes" class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help name="employee.detail.notes"></app-help>

      <div class="card-header py-3">
        <div class="row">
          <div class="col-6 text-left my-auto">
            <p class="modal-title font-16 pt-2 pb-2 text-dark">
              <span>{{ 'employees.page_title_view_activity' | translate }}</span>
            </p>
          </div>
          <div class="col-6 text-right">
            <button
                (click)="openCreateEditModal()"
                *ngIf="canEditActivity$ | async"
                [createButton]="'employees_activities.action_create' | translate"
                class="ml-sm-2"
              ></button>
          </div>
        </div>
      </div>

      <div class="card-body px-4">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <app-data-table
                [colsConfig]="{width: ['5%', '15%','15%', '50%', '10%']}"
                [columnsToFilterIn]="['date', 'type', 'name']"
                [loading]="loading$ | async"
                [rows]="activities$ | async"
                [trackByFn]="trackByActivity"
              >
                <ng-template #headerRow>
                  <th [disableSort]="true" appDataTableHeaderCell></th>
                  <th
                    [disableSort]="true"
                    appDataTableHeaderCell
                  >{{ 'employees_activities.date' | translate }}</th>

                    <th
                        [disableSort]="true"
                        appDataTableHeaderCell
                    >{{ 'employees_activities.type' | translate }}</th>

                  <th
                    [disableSort]="true"
                    appDataTableHeaderCell
                  >{{ 'employees_activities.name' | translate }}</th>

                  <th
                    [disableSort]="true"
                    appDataTableHeaderCell
                    class="text-right"
                  >{{ 'employees_activities.table_action' | translate }}</th>
                </ng-template>

                <ng-template
                  #dataRow
                  let-row
                >
                  <td appDataTableDataCell>
                    <span *ngIf="!row.public" title="{{ 'employees_activities.visibility_private' | translate }}"><i class="mdi mdi-lock mdi-24px"></i></span>
                    <span *ngIf="row.public" title="{{ 'employees_activities.visibility_public' | translate }}"><i class="mdi mdi-lock-open-variant mdi-24px"></i></span>
                  </td>
                  <td appDataTableDataCell>{{ row.date | date:'d. M. Y' }}</td>

                    <td appDataTableDataCell>
                        <span>{{ 'employees_activities.types.' + row.type | translate }}</span>
                    </td>

                  <td appDataTableDataCell>
                    <span>{{ row.name }}</span>
                  </td>

                  <td
                    appDataTableDataCell
                    class="text-right"
                  >
                    <button
                      (click)="openCreateEditModal(row)"
                      class="btn btn-white btn-icon btn-sm mr-1"
                      title="{{ 'employees_activities.action_view' | translate }}"
                    >
                      <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                    </button>
                  </td>
                </ng-template>
              </app-data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
