<div  class="modal-content">
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">
      <span>{{ modalTitle ? modalTitle : ('tags.modal_name' | translate) }}</span>
    </p>
    <button (click)="activeModal.dismiss()" aria-label="Close" class="close">
      <span aria-hidden="true" class="font-24 text-muted">
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>


  <!--<ng-container *ngIf="allTags$ | async as tags">
    <datalist id="allTagsList">
      <ng-container *ngFor="let tag of tags">
        <option> {{ tag.label }} </option>
      </ng-container>
    </datalist>
  </ng-container>-->

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <div *ngIf="canEdit$ | async">
              <form #f="ngForm">
                <div class="d-inline-flex w-100">
                    <ng-autocomplete [data]="allTagsAutoComplete"
                                     (keydown.enter)="onSubmit(f.form)"
                                     [itemTemplate]="itemTemplate"
                                     placeholder="{{ 'tags.add_tag' | translate }}"
                                     name="label"
                                     ngModel>
                    </ng-autocomplete>
                    <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item"></a>
                    </ng-template>

                  <button
                    (click)="onSubmit(f.form)"
                    [createButton]="'employees.action_add_tag' | translate"
                    class="ml-2 min-width-45px"
                  ></button>
                </div>
              </form>
            </div>
              <div class="row mt-2">
                  <div class="col-12 text-right">
                      <small class="text-right">
                            {{ ('tags.modal_description_category_' + category) | translate }}
                      </small>
                  </div>
              </div>
            <div *ngIf="entityTags" class="mt-3">
              <ng-container *ngFor="let tag of entityTags">
                <span class="tag-badge badge badge-success">
                  {{ tag.label }}
                  <i (click)="detachTag(tag)"
                    class="mdi mdi-close tag-detach"
                  ></i>
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
