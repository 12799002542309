<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">{{('properties.page_title_' + action) | translate}}</p>
    <button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true" class="font-24 text-muted"><i class="mdi mdi-close"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <form
        (ngSubmit)="onSubmit()"
        [formGroup]="form"
        autocomplete="off"
      >
        <div *ngIf="!employeeID" class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label class="control-label">{{'properties.employee' | translate}}</label>
                <ng-select
                  [items]="employees$ | async"
                  bindLabel="fullname"
                  bindValue="employee_ID"
                  class="custom"
                  formControlName="employee_ID"
                  name="employee_ID"
                ></ng-select>

              <div *ngIf="submitted && f.employee_ID.errors">
                <p
                  *ngIf="f.employee_ID.errors.required"
                  class="text-danger"
                >
                  <span>{{ 'angular_validation.field' | translate }} </span>
                  <span>{{ 'properties.employees' | translate }} </span>
                  <span>{{ 'angular_validation.required' | translate }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label class="control-label">{{'properties.date_of_registration' | translate}}</label>
                <input
                  (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                  [locale]="locale$ | async"
                  allowInput="true"
                  altFormat="d.m.Y"
                  altInput="true"
                  class="form-control"
                  dateFormat="Y-m-d"
                  formControlName="handover_date"
                  mwlFlatpickr
                  name="handover_date"
                  type="text"
                >

              <div *ngIf="submitted && f.handover_date.errors">
                <p
                  *ngIf="f.handover_date.errors.required"
                  class="text-danger"
                >
                  <span>{{ 'angular_validation.field' | translate }} </span>
                  <span>{{ 'properties.handover_date' | translate }} </span>
                  <span>{{ 'angular_validation.required' | translate }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="action === 'assign'" class="row">
            <div class="col-12 text-right">
                <small class="text-muted">{{ 'properties.assign_explanation' | translate}}</small>
            </div>
        </div>

        <div class="row mt-2">
          <div class="col d-flex justify-content-end">
            <button class="btn btn-success" type="submit">
              {{'properties.action_save' | translate}}
            </button>
          </div>
        </div>
        </form>
      </div>
  </div>








