<div
  *ngIf="companyFacturationSettings$ | async"
  class="row"
>
  <div class="col-12">
    <div class="card shadow">
      <div class="card-header py-3">
        <div class="row">
          <div class="col-sm-6 text-left my-auto">
            <p class="modal-title font-16 text-dark pt-2 pb-2">
              <span>{{ 'settings.company.section.facturation' | translate }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="card-body">
        <form
          [formGroup]="companyFacturationSettingEditForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">
                  <span>{{ 'settings.company.facturation.due_date_days' | translate }}</span>
                </label>

                <input
                  class="form-control"
                  formControlName="company.facturation.due_date_days"
                  name="company.facturation.due_date_days"
                  min="0"
                  type="number"
                />
                <div *ngIf="submitted && f['company.facturation.due_date_days'].errors">
                  <p *ngIf="f['company.facturation.due_date_days'].errors.min" class="text-danger validation-text">
                    {{ 'angular_validation.field' | translate }} {{ 'settings.company.facturation.due_date_days' | translate }}
                    {{ 'angular_validation.cannot_be_lower' | translate }} 0
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">
                  <span>{{ 'settings.vat' | translate }}</span>
                </label>

                <input currencyMask class="form-control" formControlName="company.facturation.vat" name="company.facturation.vat" type="text">
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col d-flex justify-content-end">
              <button class="btn btn-success">{{ 'settings.site_action_save' | translate }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card shadow">
      <div class="card-header py-3">
        <div class="row">
          <div class="col-sm-6 text-left my-auto">
            <p class="modal-title font-16 text-dark pt-2 pb-2">
              <span>{{ 'settings.facturation_notifications' | translate }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="card-body">
        <form
          [formGroup]="companyFacturationNotificationsSettingEditForm"
          (ngSubmit)="onSubmitNoti()"
        >
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">
                  <span>{{ 'settings.company.facturation.invoice_internal_email' | translate }}</span>
                </label>

                <input
                  class="form-control"
                  formControlName="company.facturation.invoice_internal_email"
                  name="company.facturation.invoice_internal_email"
                  type="text"
                />

                <div *ngIf="submitted && fNoti['company.facturation.invoice_internal_email'].errors">
                  <p
                    *ngIf="fNoti['company.facturation.invoice_internal_email'].errors.required"
                    class="text-danger validation-text"
                  >
                    <span>{{ 'angular_validation.field' | translate }} </span>
                    <span>{{ 'settings.company.facturation.invoice_internal_email' | translate }} </span>
                    <span>{{ 'angular_validation.required' | translate }}</span>
                  </p>

                  <p
                    *ngIf="fNoti['company.facturation.invoice_internal_email'].errors.email"
                    class="text-danger validation-text"
                  >
                    <span>{{ 'angular_validation.valid_email' | translate }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">
                  <span>{{ 'settings.company.facturation.due_date_notification_before' | translate }}</span>
                </label>

                <input
                  formControlName="company.facturation.due_date_notification_before"
                  class="form-control"
                  min="0"
                  name="company.facturation.due_date_notification_before"
                  type="number"
                />
                <div *ngIf="submitted && fNoti['company.facturation.due_date_notification_before'].errors">
                  <p *ngIf="fNoti['company.facturation.due_date_notification_before'].errors.min" class="text-danger validation-text">
                    {{ 'angular_validation.field' | translate }} {{ 'settings.company.facturation.due_date_notification_before' | translate }}
                    {{ 'angular_validation.cannot_be_lower' | translate }} 0
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">
                  <span>{{ 'settings.company.facturation.due_date_notification_after' | translate }}</span>
                </label>

                <input
                  class="form-control"
                  formControlName="company.facturation.due_date_notification_after"
                  min="0"
                  name="company.facturation.due_date_notification_after"
                  type="number"
                />
                <div *ngIf="submitted && fNoti['company.facturation.due_date_notification_after'].errors">
                  <p *ngIf="fNoti['company.facturation.due_date_notification_after'].errors.min" class="text-danger validation-text">
                    {{ 'angular_validation.field' | translate }} {{ 'settings.company.facturation.due_date_notification_after' | translate }}
                    {{ 'angular_validation.cannot_be_lower' | translate }} 0
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">
                  <span>{{ 'settings.company.facturation.due_date_notification_today' | translate }}</span>
                </label>
              </div>
            </div>

            <div class="col-md-6 text-right">
              <div>
                <input
                  data-switch="success"
                  formControlName="company.facturation.due_date_notification_today"
                  id="company.facturation.due_date_notification_today"
                  name="is_correct"
                  type="checkbox"
                >

                <label for="company.facturation.due_date_notification_today">&nbsp;</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">
                  <span>{{ 'settings.company.facturation.due_date_notification_after_repeat' | translate }}</span>
                </label>
              </div>
            </div>

            <div class="col-md-6 text-right">
              <div>
                <input
                  data-switch="success"
                  formControlName="company.facturation.due_date_notification_after_repeat"
                  id="company.facturation.due_date_notification_after_repeat"
                  name="is_correct"
                  type="checkbox"
                >

                <label for="company.facturation.due_date_notification_after_repeat">&nbsp;</label>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col d-flex justify-content-end">
              <button class="btn btn-success">
                {{ 'settings.site_action_save' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
