<ng-container *ngIf="documentTemplate$ | async; let documentTemplate">
    <form (ngSubmit)="saveTemplate(documentTemplate)" [formGroup]="form">
        <div class="row mb-3">
            <div class="col-12">
                <app-help name="settings.documents.templates.detail.html"></app-help>
                <div class="card h-100 mb-1 shadow">
                    <div class="card-header py-3">
                        <div class="row">
                            <div class="col-12">
                                <div class="modal-title font-16 text-dark pt-2 pb-2">
                                    {{ 'document_templates.html_content' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body px-4">
                        <app-ai-helper [formGroup]="form" [scope]="'DOCUMENT_TEMPLATE'" [controlName]="'content'"></app-ai-helper>

                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="form-group">
                                    <input
                                        [ngxSummernote]="summernoteHelper.getSummernoteConfig(300)"
                                        class="form-control"
                                        name="content"
                                        formControlName="content"
                                        type="text"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-right">
                <button
                    class="btn btn-primary"
                    [disabled]="form.invalid"
                    type="submit"
                >
                    {{ 'global.action_save' | translate }}
                </button>
            </div>
        </div>
    </form>
</ng-container>
