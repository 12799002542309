import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, from, Observable, ReplaySubject } from 'rxjs';
import { first, map, shareReplay, switchMap } from 'rxjs/operators';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { EmployeeOnboardingService } from 'src/app/onboarding/employee-onboarding/state/employee-onboarding.service';
import { OnboardingTask } from 'src/app/onboarding/onboarding/state/onboarding-task.model';
import { Onboarding } from 'src/app/onboarding/onboarding/state/onboarding.model';
import { OnboardingService } from 'src/app/onboarding/onboarding/state/onboarding.service';
import { TEXT_MAX_LENGTH } from 'src/app/ui';
import { FlatpickrLocaleService } from '../../../../shared/services/flatpickr-locale.service';
import { EmployeeOnboarding } from '../../../employee-onboarding/state/employee-onboarding.model';
import { CreateEditOnboardingModalComponent } from '../../partials/create-edit-onboarding-modal/create-edit-onboarding-modal.component';
import { CreateEditOnboardingTaskModalComponent } from '../../partials/create-edit-onboarding-task-modal/create-edit-onboarding-task-modal.component';
import { EmployeeOnboardingOverviewModalComponent } from '../../partials/employee-onboarding-overview-modal/employee-onboarding-overview-modal.component';

@Component({
    selector: 'app-onboarding-overview',
    templateUrl: './onboarding-overview.component.html',
    styleUrls: ['./onboarding-overview.component.css']
})
export class OnboardingOverviewComponent implements OnInit {
    public employees$: Observable<Array<Employee>>;
    public maxTaskNameLength = TEXT_MAX_LENGTH;
    public onboarding$: Observable<Onboarding>;
    public taskForm: UntypedFormGroup;
    public taskFormSubmitted = false;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;

    private _fetchOnboarding$ = new ReplaySubject<void>(1);
    private _onboardingId$: Observable<number>;

    public constructor(
        private _employeeService: EmployeeService,
        private _formBuilder: UntypedFormBuilder,
        private _onboardingService: OnboardingService,
        private _modalService: NgbModal,
        private _route: ActivatedRoute,
        private _employeeOnboardingService: EmployeeOnboardingService,
        private _flatpickrLocale: FlatpickrLocaleService,
    ) { }

    public ngOnInit(): void {

        this._onboardingId$ = this._route.params
            .pipe(
                map(params => parseInt(params.onboardingID, 10)),
                shareReplay()
            );

        this.onboarding$ = combineLatest([
            this._fetchOnboarding$,
            this._onboardingId$
        ])
            .pipe(
                switchMap(([, onboardingId]) => combineLatest([
                    this._onboardingService.getOnboardingByID(onboardingId),
                    this._onboardingService.getEmployeesForOnboarding(onboardingId)
                ])),
                map(([onboarding, employees]) => ({
                    ...onboarding,
                    employees
                }))
            );

        this.employees$ = this._employeeService.getAllEmployees(false, 'select');

        this._fetchOnboarding$.next();

        this.taskForm = this._formBuilder.group({
            name: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(this.maxTaskNameLength)
                ]
            ]
        });
    }

    public onSubmit(f: UntypedFormGroup, onboarding: Onboarding): void {
        if (!f.valid) {
            return;
        }

        const value = f.value;

        this._onboardingService.assignEmployeesToOnboarding(onboarding, value.employeesIDs, value.start_date)
            .then(() => this._fetchOnboarding$.next());
    }

    public onSubmitTask(): void {
        this.taskFormSubmitted = true;

        if (!this.taskForm.valid) {
            return;
        }

        const formValue = {
            ...this.taskForm.value,
            deadline: 'NONE',
            assignee_ID: null,
            assignee_entity: 'STRUCTURE'
        };

        this.onboarding$
            .pipe(
                first(),
                switchMap(onboarding => from(this._onboardingService.saveOnboardingTask(formValue, onboarding.onboarding_ID, null, null)))
            )
            .subscribe(() => {
                this.taskForm.reset();

                this.taskFormSubmitted = false;

                this._fetchOnboarding$.next();
            });
    }

    public openAssignModal(content: any): void {
        const modalRef = this._modalService.open(content, {centered: true});

        modalRef.result
            .then(
                () => this._fetchOnboarding$.next(),
                () => { }
            );
    }

    public openEditModal(onboarding: Onboarding): void {
        const modalRef = this._modalService.open(CreateEditOnboardingModalComponent, {centered: true});

        modalRef.componentInstance.onboarding = onboarding;

        modalRef.result
            .then(
                () => this._fetchOnboarding$.next(),
                () => { }
            );
    }

    public openOnboardingTaskEditModal(onboardingTask: OnboardingTask, onboarding: Onboarding): void {
        const modalRef = this._modalService.open(CreateEditOnboardingTaskModalComponent, {size: 'lg', centered: true});

        modalRef.componentInstance.onboarding = onboarding;

        modalRef.componentInstance.onboardingTask = onboardingTask;

        modalRef.result
            .then(
                () => this._fetchOnboarding$.next(),
                () => { }
            );
    }

    public openEmployeeOnboardingOverviewModal(employeeID: number, employeeOnboarding: EmployeeOnboarding): void {
        const modalRef = this._modalService.open(EmployeeOnboardingOverviewModalComponent, {size: 'lg', centered: true});
        modalRef.componentInstance.employeeOnboardingID = employeeOnboarding.employee_onboarding_ID;
        modalRef.result
            .then(
                () => {},
                () => this._fetchOnboarding$.next()
            );
        this._employeeOnboardingService.get(employeeID, true).subscribe();
    }
}
