import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Growth } from 'src/app/growth/growth/state/growth.model';
import { GrowthService } from 'src/app/growth/growth/state/growth.service';
import { FormHelper } from 'src/app/shared/common/FormHelper';

@Component({
    selector: 'app-create-edit-growth',
    templateUrl: './create-edit-growth-modal.component.html',
    styleUrls: ['./create-edit-growth-modal.component.css']
})
export class CreateEditGrowthModalComponent implements OnInit {
    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.growthEditForm.controls;
    }

    public growth: Growth;
    public growthEditForm: UntypedFormGroup;
    public submitted = false;

    public constructor(
        public activeModal: NgbActiveModal,
        private _fb: UntypedFormBuilder,
        private _formHelper: FormHelper,
        private _growthService: GrowthService
    ) { }

    public ngOnInit(): void {
        this.growthEditForm = this._fb.group({
            name: ['', Validators.required],
            description: [''],
            costs: [0],
        });

        if (this.growth) {
            this.growthEditForm.patchValue({
                name: this.growth.name,
                description: this.growth.description,
                costs: this.growth.costs
            });
        }
    }

    public deleteGrowth(): void {
        this._growthService.deleteGrowth(this.growth.growth_ID);
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.growthEditForm.valid) {
            return;
        }

        this._growthService.saveGrowth(this.growthEditForm.value, this.activeModal, this.growth?.growth_ID);
    }
}
