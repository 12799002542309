import { environment } from '../../environments/environment';

/**
 * @example
 * // returns 'hidden'
 * getCookie('dashboard_alert_12')
 *
 * @param name searched key
 * @returns cookie value
 */
export function getCookie(name: string): string {
    let cValue = ' ' + document.cookie;
    let cStart = cValue.indexOf(' ' + name + '=');
    if (cStart === -1) {
        cValue = null;
    } else {
        cStart = cValue.indexOf('=', cStart) + 1;
        let cEnd = cValue.indexOf(';', cStart);
        if (cEnd === -1) {
            cEnd = cValue.length;
        }
        cValue = unescape(cValue.substring(cStart, cEnd));
    }
    return cValue;
}

/**
 * @returns Deletes cookie key by name
 */
export function deleteCookie(name: string): void {
    if (getCookie(name)) {
        document.cookie = name + '=' + ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
    }
}

/**
 * @returns Sets cookie key by nameand date
 */
export function setCookie(name: string, value: string): void {
    const expires = '; expires=session';
    document.cookie = name + '=' + (value || '') + expires + '; path=/; domain=' + environment.domain;
}

/**
 * @returns Operating system of client
 */
export function getOS(): string {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i;
    const windowsPlatforms = /(win32|win64|windows|wince)/i;
    const iosPlatforms = /(iphone|ipad|ipod)/i;

    if (macosPlatforms.test(userAgent)) {
        return 'macos';
    } else if (iosPlatforms.test(userAgent)) {
        return 'ios';
    } else if (windowsPlatforms.test(userAgent)) {
        return 'windows';
    } else if (/android/.test(userAgent)) {
        return 'android';
    } else if (/linux/.test(userAgent)) {
        return 'linux';
    }
}

/**
 * @returns Safe localStorage object, because Safari in private mode throws an error
 */
export const localStorageSafe = {
    getItem(key: string): string {
        try {
            return localStorage.getItem(key);
        } catch (e) {
            // localStorage does not exist, get a new browser !
        }
    },
    setItem(key: string, value: string): void {
        try {
            localStorage.setItem(key, value);
        } catch (e) {
            // localStorage does not exist, get a new browser !
        }
    },
    removeItem(key: string): void {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            // localStorage does not exist, get a new browser !
        }
    }
};

/**
 * @returns string converted from camelCase to snake_case
 */
export function camelToSnakeCase(str: string): string {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

/**
 * @returns string converted from snake_case to camelCase
 */
export function snakeToCamelCase(str: string): string {
    return str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));
}
