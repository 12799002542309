<div class="modal-content">
  <div class="modal-header">
    <p *ngIf="onboarding" class="modal-title font-16 mb-0 text-dark">{{'onboardings.page_title_edit' | translate}}</p>
    <p *ngIf="!onboarding" class="modal-title font-16 mb-0 text-dark">{{'onboardings.page_title_create' | translate}}</p>
    <button (click)="activeModal.dismiss()" type="button" class="close" aria-label="Close">
      <span class="font-24 text-muted" aria-hidden="true"><i class="mdi mdi-close"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <form [formGroup]="onboardingEditForm" (ngSubmit)="onSubmit()" autocomplete="off">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">{{ 'onboardings.name' | translate }}</label>
                    <input formControlName="name" class="form-control" type="text" name="name">
                    <div *ngIf="submitted && f.name.errors">
                      <p *ngIf="f.name.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{ 'onboardings.name' | translate }}
                        {{ 'angular_validation.required' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">{{ 'onboardings.type' | translate }}</label>
                    <ng-select
                      class="custom"
                      formControlName="type"
                      name="type"
                    >
                      <ng-option value="ONBOARDING">Onboarding</ng-option>
                      <ng-option value="OFFBOARDING">Offboarding</ng-option>
                    </ng-select>
                    <div *ngIf="submitted && f.type.errors">
                      <p *ngIf="f.type.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{ 'onboardings.type' | translate }}
                        {{ 'angular_validation.required' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">{{ 'onboardings.description' | translate }}</label>
                    <input formControlName="description" class="form-control" type="text" name="description">
                    <div *ngIf="submitted && f.description.errors">
                      <p *ngIf="f.description.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{ 'onboardings.description' | translate }}
                        {{ 'angular_validation.required' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col d-flex justify-content-end">
                  <button *ngIf="onboarding" class="btn btn-link text-muted pr-0 mr-4" type="button" (click)="deleteOnboarding()">
                    <u>{{ 'onboardings.action_delete' | translate }}</u>
                  </button>
                  <button class="btn btn-success" type="submit">
                    {{'onboardings.action_save' | translate}}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
