import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as iban from 'iban';
import { of, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { SubscriptionService } from 'src/app/setting/services/subscription.service';
import { localStorageSafe } from 'src/app/shared/functions';
import { CustomerService } from '../../../crm/services/customer.service';
import { CountryStoreService } from '../../../setting/services/country-store.service';
import { CurrencyStoreService } from '../../../setting/services/currency-store.service';
import { UserService } from '../../../setting/services/user.service';
import { FormHelper } from '../../../shared/common/FormHelper';
import { LoggedInUser } from '../../models/LoggedUser';
import { DomainService } from '../../services/domain.service';

@Component({
    selector: 'app-welcome-modal',
    templateUrl: './welcome-modal.component.html',
    styleUrls: ['./welcome-modal.component.css']
})
export class WelcomeModalComponent implements OnInit, OnDestroy {

    public userDataForm: UntypedFormGroup;
    public companyDataForm: UntypedFormGroup;
    public inValueChangeSubscription: Subscription;
    public formValueSubscription: Subscription;
    public loggedInUser: LoggedInUser;
    public step = 0;
    public progress = 0;
    public progressBarWidth = 0;
    public in: string;
    public closeable = true;
    public showOtherFormFields = false;
    public submitted = false;
    public customerInfoLoading = false;
    public accounts = [];
    public tenantKey = this._domainService.getTenantNameWithStringAppend('');
    public tenantSubscription = this._subscriptionService.subscription$;
    public showComplexModal = false;

    public constructor(
        private _activeModal: NgbActiveModal,
        private _subscriptionService: SubscriptionService,
        private _fb: UntypedFormBuilder,
        private _formHelper: FormHelper,
        private _userService: UserService,
        private customerService: CustomerService,
        private _location: Location,
        private _domainService: DomainService,
        public countryStoreService: CountryStoreService,
        public currencyStoreService: CurrencyStoreService,
        public translateService: TranslateService,
    ) { }

    public get f() {
        return this.companyDataForm.controls;
    }

    public ngOnDestroy(): void {
        this.formValueSubscription?.unsubscribe();
        this.inValueChangeSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.step = Number(localStorageSafe.getItem('welcomeModalStep-' + this.tenantKey));
        this.progress = this.step * 33;

        this.userDataForm = this._fb.group({
            name: ['', Validators.required],
            surname: ['', Validators.required],
            email: ['', Validators.required],
            phone: ['']
        });

        if (this.loggedInUser) {
            this.userDataForm.patchValue({
                email: this.loggedInUser.email
            });
        }

        this.companyDataForm = this._fb.group({
            'company.info.in': [null],
            'company.info.name': [null],
            'company.info.tin': [null],
            'company.info.registered_for_vat': [null],
            'company.info.registration_text': [null],
            'company.address.addr_line_1': [null],
            'company.address.addr_line_2': [null],
            'company.address.city': [null],
            'company.address.postal_code': [null],
            'company.address.country_code': [null]
        });

        this.formValueSubscription = this.companyDataForm.valueChanges.subscribe(() => {
            const filledControlsCount = this._formHelper.getFilledRequiredControlsCount(this.f);
            this.progressBarWidth = parseInt(((filledControlsCount / this._formHelper.getRequiredControlsCount(this.f)) * 100).toFixed(1), 10);
        });

        this.inValueChangeSubscription = this.f['company.info.in'].valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => this.customerInfoLoading = true)).subscribe(val => {
            this.customerService.findCompany(val).pipe(
                catchError(() => of(null)),
                tap(() => this.customerInfoLoading = false)
            ).subscribe(customer => {
                if (customer) {
                    if (customer.accounts) {
                        this.accounts = [...this.accounts, ...customer.accounts];
                    }
                    this.accounts.forEach((a, i) => {
                        this.companyDataForm.addControl('account_number-' + i, this._fb.control([a.number]));
                        this.companyDataForm.addControl('account_code-' + i, this._fb.control([a.code]));
                        this.companyDataForm.addControl('account_iban-' + i, this._fb.control([this.getIBANFromBankAccount(a.number, a.code)]));
                        this.companyDataForm.addControl('account_currency-' + i, this._fb.control(['CZK']));
                        this.companyDataForm.get('account_currency-' + i).patchValue('CZK');
                    });
                    this.showOtherFormFields = true;
                    this.companyDataForm.patchValue({
                        'company.info.name': customer.company,
                        'company.info.tin': customer.tin,
                        'company.info.registered_for_vat': customer.vat_payer,
                        'company.info.registration_text': customer.registration_text,
                        'company.address.addr_line_1': customer.street + ' ' + customer.house_number,
                        'company.address.city': customer.city,
                        'company.address.postal_code': customer.zip,
                        'company.address.country_code': 'CZ'
                    });
                }
            });
        });
        if (this.in) {
            this.f['company.info.in'].patchValue(this.in);
        }
    }

    public getIBANFromBankAccount(accountNumber: string, accountCode: string): string {
        const accountNumberLength = accountNumber.length;
        if (accountNumberLength < 10) {
            const missingZeros = 10 - accountNumberLength;
            const missingZerosArray = [];
            for (let i = 0; i < missingZeros; i++) {
                missingZerosArray.push('0');
            }
            accountNumber = missingZerosArray.join('') + accountNumber;
        }
        const bban = accountCode + '000000' + accountNumber;
        return iban.fromBBAN('CZ', bban);
    }

    public setFormCollapse(): void {
        this.showOtherFormFields = !this.showOtherFormFields;
    }

    public onSubmitCompanyData() {
        this.submitted = true;
        if (!this.companyDataForm.valid) {
            return;
        }
        const accounts = [];
        const data = this.companyDataForm.value;
        const entries = Object.keys(data).filter((k) => k.indexOf('account_') === 0)
            .reduce((newData, k) => {
                newData[k] = data[k];
                return newData;
            }, {});
        this.accounts.forEach((a, i) => {
            const nmbr = entries['account_number-' + i][0];
            const code = entries['account_code-' + i][0];
            const ibanCode = entries['account_iban-' + i][0];
            const currency = entries['account_currency-' + i];
            accounts.push({number: nmbr, code, iban: ibanCode, currency});
        });
        data.accounts = accounts;
        data.settings_type = 'onboarding';

        this.customerService.saveBasicOnBoarding(data);
        this.nextStep();
    }

    public onSubmitUserData(): void {
        let value = this.userDataForm.value;
        value = {...value, is_welcome_modal: true};
        this.nextStep();
        this._userService.saveUser(value, this.loggedInUser.user_ID, false);
    }

    public agreeTerms(showComplexModal: boolean, marketingAgreed: boolean): void {
        this.showComplexModal = showComplexModal;
        this._subscriptionService.agreeTerms(true, marketingAgreed);
        this.nextStep();
    }

    public nextStep(): void {
        this.step++;
        localStorageSafe.setItem('welcomeModalStep-' + this.tenantKey, this.step.toString());

        const interval = setInterval(() => {
            this.progress++;

            if (this.progress >= this.step * 33) {
                clearInterval(interval);
            }
        }, 5);
    }

    public close(refreshProfile = false, triggerUsetiful = false): void {
        if (triggerUsetiful) {
            this.triggerUsetifulEvent();
        }
        this._userService.markWelcomeModalViewed(this.loggedInUser.user_ID);
        this._activeModal.close(refreshProfile);
        localStorageSafe.removeItem('welcomeModalStep-' + this.tenantKey);
    }

    public triggerUsetifulEvent(): void {
        window.dispatchEvent(new CustomEvent('usf:open'));
    }

    public getSmallModalDocHref(): string {
        if (this.translateService.currentLang === 'cs' && this.loggedInUser?.is_admin) {
            return 'https://docs.speybl.com/cs/manual/pruvodce-aplikaci-pri-jejim-prvnim-spusteni-163';
        }
        if (this.translateService.currentLang !== 'cs' && this.loggedInUser?.is_admin) {
            return 'https://docs.speybl.com/en/manual/the-application-guide-when-you-first-start-the-application-163';
        }
        if (this.translateService.currentLang === 'cs' && !this.loggedInUser?.is_admin) {
            return 'https://docs.speybl.com/cs/manual/pruvodce-zamestnaneckym-pristupem-164';
        }
        if (this.translateService.currentLang !== 'cs' && !this.loggedInUser?.is_admin) {
            return 'https://docs.speybl.com/en/manual/employee-access-guide-164';
        }
    }
}
