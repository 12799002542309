import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeService } from 'src/app/employee/services/employee.service';

@Component({
    selector: 'app-check-extra-verification',
    templateUrl: './check-extra-verification-modal.component.html',
    styleUrls: ['./check-extra-verification-modal.component.css']
})
export class CheckExtraVerificationModalComponent implements OnInit, AfterViewInit {
    public state: string;
    public methods: Array<string>;
    public labels: { verification_password: string, verification_code: string };
    public verificationForm: UntypedFormGroup;
    public loading = false;

    @ViewChildren('inputField') inputFields: QueryList<ElementRef>;
    @ViewChildren('twoFactorField') twoFactorFields: QueryList<ElementRef>;

    constructor(
        private _employeeService: EmployeeService,
        private _fb: FormBuilder,
        public activeModal: NgbActiveModal
    ) {
    }

    public ngOnInit(): void {
        this.verificationForm = this._fb.group({
            verification_code: [null],
            verification_password: [null]
        });
    }

    public ngAfterViewInit(): void {
        const firstInput: HTMLInputElement = this.inputFields.first.nativeElement;
        firstInput.focus();
    }

    public onSubmit(): void {
        this.activeModal.close(this.verificationForm.value);
    }

    public checkCount(event: string): void {
        const length = event.length;
        if (length === 6) {
            this.loading = true;
            this.inputFields.forEach(el => {
                el.nativeElement.blur();
            });
            setTimeout(() => {
                this.onSubmit();
            }, 1000);
        }
    }
}
