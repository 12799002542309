import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityLog } from 'src/app/shared/models/ActivityLog';

@Component({
    selector: 'app-activity-log-detail-modal',
    templateUrl: './activity-log-detail-modal.component.html',
    styleUrls: ['./activity-log-detail-modal.component.css']
})
export class ActivityLogDetailModalComponent {

    public selectedActivityLog: ActivityLog;

    public constructor(
        public activeModal: NgbActiveModal,
    ) { }
}
