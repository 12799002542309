import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { Employee } from 'src/app/employee/models/Employee';
import { File } from 'src/app/employee/models/File';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { CompanyService } from '../../../../setting/services/company.service';

@Component({
    selector: 'app-employee-documents',
    templateUrl: './employee-documents.component.html',
    styleUrls: ['./employee-documents.component.css']
})
export class EmployeeDocumentsComponent implements OnInit, OnDestroy {
    public employee$: Observable<Employee>;
    public employeeFiles$: Observable<Array<File>>;
    public employeeID: number;
    public employeePayrolls$: Observable<Array<File>>;
    public availableWidgets$: Observable<AvailableWidgets>;
    public permissions: Array<string>;
    public watchingMyself = false;

    private _fetchFiles$ = new ReplaySubject<void>(1);
    private _fetchPayrolls$ = new ReplaySubject<void>(1);
    private _subscriptions: Subscription;

    public constructor(
        private _employeeService: EmployeeService,
        private _companyService: CompanyService,
        private _route: ActivatedRoute,
        private location: Location,
        private _authService: AuthenticationService,
        private _cdr: ChangeDetectorRef
    ) { }

    public ngOnDestroy(): void {
        this._subscriptions?.unsubscribe();
    }

    public ngOnInit(): void {
        this.availableWidgets$ = this._companyService.availableWidgets$;

        this.permissions = this._authService?.permissions?.map(perm => perm.name);

        this._subscriptions = this._route.fragment.subscribe(f => {
            setTimeout(() => {
                const element = document.getElementById(f);
                if (element) {
                    const headerOffset = 124;
                    const elementPosition = element.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
                this.location.replaceState(this.location.path(false));
            });
        });

        this._subscriptions.add(this._route.parent.params.subscribe(params => {
            this.employeeID = parseInt(params.id, 10);

            if (this.employeeID) {
                const loggedEmployeeID = this._authService?.employee?.employee_ID;
                this.watchingMyself = loggedEmployeeID === this.employeeID;
                this._cdr.detectChanges();

                this.employee$ = this._employeeService.getEmployeeInfoByEmployeeID(this.employeeID, 'extend')
                    .pipe(shareReplay());
            }

            this.employeePayrolls$ = combineLatest([
                this._fetchPayrolls$,
                this.employee$
            ])
                .pipe(
                    switchMap(([noop, employee]) => this._employeeService.getEmployeePayrollsByEmployeeID(this.employeeID)),
                    shareReplay()
                );

            this.employeeFiles$ = combineLatest([
                this._fetchFiles$,
                this.employee$
            ])
                .pipe(
                    switchMap(([noop, employee]) => this._employeeService.getEmployeeFilesByEmployeeID(this.employeeID)),
                    shareReplay()
                );
        }));

        this.refreshEmployeeFiles();
        this.refreshEmployeePayrolls();
    }

    public refreshEmployeePayrolls(): void {
        this._fetchPayrolls$.next();
    }

    public refreshEmployeeFiles(): void {
        this._fetchFiles$.next();
    }

    public checkPermission(name: string): boolean {
        return (this.watchingMyself || this.permissions.includes(name + '.view') || this.permissions.includes(name + '.viewStructure'));
    }
}
