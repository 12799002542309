<ng-container *ngIf="employeeContactPersons$ | async; let persons; else loadingTemplate">
  <div
   [ngClass]="requiredUpdates && requiredUpdates.contact_person === 'REQUESTED' ? 'notify-dot' : ''"
   class="card h-100 shadow">
    <app-help name="employee.detail.contact_persons"></app-help>

    <div class="card-header py-3">
      <div class="row">
        <div class="col-6 text-left my-auto">
          <p class="modal-title font-16 text-dark pt-2 pb-2">
            <span>{{ 'employees_contact_persons.page_title_index' | translate }}</span>
          </p>
        </div>

        <div class="col-6 text-right my-auto">
          <button *ngIf="widgetTemplatesService.canSeeButton('ContactPerson', 'Create')"
            (click)="openEditModal(null)"
            [createButton]="'employees_contact_persons.action_create' | translate"
          ></button>
        </div>
      </div>
    </div>

    <div class="card-body">
      <ng-container *ngIf="entityRequests">
        <ng-container *ngFor="let request of entityRequests">
          <div *ngIf="request?.entity_type === 'App\\Models\\EmployeeContactPerson'" class="alert alert-warning mb-3 w-100 shadow">
            <span>{{ 'employees_contact_persons.waiting_to_be_processed' | translate }} </span>
            <span><u (click)="openEntityUpdateRequestModal(request)">{{ 'employees_contact_persons.waiting_to_be_processed_part_2' | translate }}</u></span>
            <span>{{ 'employees_contact_persons.waiting_to_be_processed_'+(request?.action) | translate }} </span>
            <span class="font-weight-bold">{{ request?.entity_label }}</span>.
          </div>
        </ng-container>
      </ng-container>
      <div *ngIf="requiredUpdates && requiredUpdates.contact_person === 'REQUESTED'" class="alert alert-warning mb-3 w-100 shadow">
        <span> {{ 'employees_contact_persons.require_update_description' | translate }} </span>
        <span><u (click)="openEditModal(null)" > {{ 'employees_contact_persons.require_update_create_action'| translate }}</u> </span>
        <span *ngIf="persons?.length > 0"> {{ 'employees_contact_persons.require_update_edit_action' | translate }}</span>.
        <u (click)="refuseUpdateData()"> {{ 'employees_contact_persons.require_update_cancel_action' | translate }}</u>.
      </div>
      <ng-container *ngIf="persons?.length; else noDataTemplate">
        <div
          *ngFor="let con of persons; let i = index;"
          class="row"
        >
          <div class="col-12">
            <div class="form-group">
              <label class="control-label">
                <span>{{ 'employees_contact_persons.page_title_view' | translate }} </span>

                <span>{{ i + 1 }} </span>

                <span
                  *ngIf="con.description"
                  class="text-muted"
                >({{ con.description }})</span>
              </label>

              <div class="input-group">
                <input
                  class="form-control style-default"
                  readonly
                  type="text"
                  value="{{ con.fullname }}"
                />

                <div class="input-group-append input-group-append-over">
                  <button *ngIf="widgetTemplatesService.canSeeButton('ContactPerson', 'Edit')"
                    (click)="openEditModal(con)"
                    class="btn btn-link btn-icon text-muted pl-0 pr-0"
                  >
                    <i
                      aria-hidden="true"
                      class="mdi mdi-eye-outline mdi-24px"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noDataTemplate>
        <div class="row">
          <div class="col-12 text-center">
            <span>{{ 'global.empty_widget_error' | translate }}</span>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>

<ng-template #loadingTemplate>
  <div class="card shadow p-3">
    <div class="item">
      <ngx-skeleton-loader [theme]="{
        'margin': '15px 0'
      }" appearance="line" count="5"></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>
