import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { CountryStoreService } from '../../../../setting/services/country-store.service';
import { WidgetTemplate } from '../../../../shared/models/WidgetTemplate';
import { WidgetTemplatesService } from '../../../../shared/services/widget-templates.service';
import { EmployeeAddress } from '../../../models/EmployeeAddress';

@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.css']
})
export class AddressFormComponent implements OnInit {
    @Input() public submitted = false;
    @Input() public isQuestionnaire = false;
    @Input() public employeeAddress: EmployeeAddress;
    @Input() public employeeAddressResidence: EmployeeAddress = null;
    @Input() public presetPrimaryAddress = false;

    public showResidenceAddress = false;
    public form: UntypedFormGroup;
    public formConfig: WidgetTemplate['address'];
    public readonly Validators = Validators;

    constructor(
        public countryStoreService: CountryStoreService,
        private _widgetTemplatesService: WidgetTemplatesService,
        private _authService: AuthenticationService,
        private _fb: UntypedFormBuilder,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.form.controls;
    }

    public ngOnInit(): void {
        this.formConfig = this._widgetTemplatesService.defaultWidgetTemplate.address;

        this.form = this._fb.group({
            addr_line_1: ['', Validators.required],
            addr_line_2: [''],
            city: ['', Validators.required],
            postal_code: [''],
            country_code: ['', Validators.required],
            type: ['OTHER'],
            description: [''],
        });

        if (this.formConfig && !this._authService.checkPermission(['employeeAddress.edit', 'employeeAddress.editStructure'])) {
            this._widgetTemplatesService.presetFormByConfig(this.form, this.formConfig);
        }

        if (this.employeeAddress) {
            this.form.patchValue(this.employeeAddress);
        } else {
            this.form.patchValue({type: this.presetPrimaryAddress ? 'DOMICILE' : 'OTHER'});
        }

        if (this.isQuestionnaire && this.employeeAddressResidence) {
            this.toggleResidenceAddress();
            this.form.get('employee_address_residence').patchValue(this.employeeAddressResidence);
        }
    }

    public toggleResidenceAddress(): void {
        this.showResidenceAddress = !this.showResidenceAddress;
        if (this.showResidenceAddress) {
            this.form.addControl('employee_address_residence', this._fb.group({
                addr_line_1: ['', Validators.required],
                addr_line_2: [''],
                city: ['', Validators.required],
                postal_code: [''],
                country_code: ['', Validators.required],
                type: ['RESIDENCE'],
                description: [''],
            }));
            if (this.formConfig) {
                this._widgetTemplatesService.presetFormByConfig(this.form.get('employee_address_residence') as UntypedFormGroup, this.formConfig);
            }
        } else {
            this.form.removeControl('employee_address_residence');
        }
    }
}
