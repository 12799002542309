import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Routes } from '../shared/models/Route';
import { ImportListComponent } from './components/sections/import-list/import-list.component';
import { ImportOverviewComponent } from './components/sections/import-overview/import-overview.component';
import { ImportPayrollsComponent } from './components/sections/import-payrolls/import-payrolls.component';
import { ImportPreviewComponent } from './components/sections/import-preview/import-preview.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
    },

    {
        path: 'list',
        component: ImportListComponent,
        data: {
            help: 'import.list',
            title: 'imports.page_title_index'
        }
    },

    {
        path: 'preview',
        component: ImportPreviewComponent,
        data: {
            help: 'import.preview',
            title: 'imports.page_title_view'
        }
    },

    {
        path: 'overview/:importID',
        component: ImportOverviewComponent,
        data: {
            help: 'import.overview',
            title: 'imports.page_title_view'
        }
    },

    {
        path: 'payrolls',
        component: ImportPayrollsComponent,
        data: {
            help: 'import.payrolls',
            title: 'imports.payrolls.page_title_view'
        }
    },

    {
        path: 'template',
        loadChildren: () => import('../import-template/import-template.module').then(m => m.ImportTemplateModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [
        RouterModule,
    ]
})
export class ImportRoutingModule {}
