<div class="modal-content">
  <div class="modal-header">
    <p *ngIf="kpi" class="modal-title font-16 mb-0 text-dark">{{'kpis.page_title_edit' | translate}}</p>
    <p *ngIf="!kpi" class="modal-title font-16 mb-0 text-dark">{{'kpis.page_title_create' | translate}}</p>
    <button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true" class="font-24 text-muted"><i class="mdi mdi-close"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <form (ngSubmit)="onSubmit()" [formGroup]="kpiEditForm" autocomplete="off">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">{{ 'kpis.name' | translate }}</label>
                    <input class="form-control" formControlName="name" name="name" type="text">
                    <div *ngIf="submitted && f.name.errors">
                      <p *ngIf="f.name.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{ 'kpis.name' | translate }}
                        {{ 'angular_validation.required' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">{{ 'kpis.threshold_count' | translate }}</label>
                    <ng-select
                      [clearable]="false"
                      class="custom"
                      formControlName="threshold_count"
                      name="threshold_count"
                    >
                      <ng-option value="NONE">{{ 'kpis.threshold_none' | translate }}</ng-option>
                      <ng-option value="1">1</ng-option>
                      <ng-option value="2">2</ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>
              <div *ngIf="f.threshold_count.value !== 'NONE'" class="row">
                <div *ngIf="f.threshold_count.value !== 'NONE'" class="col-lg-6">
                  <div class="form-group">
                    <label class="control-label">{{ 'kpis.threshold_1' | translate }}</label>
                    <input class="form-control" currencyMask [options]="{'suffix': ' %', 'min': 0}" formControlName="threshold_1" name="threshold_1" type="text">
                  </div>
                </div>
                <div *ngIf="f.threshold_count.value === '2'" class="col-lg-6">
                  <div class="form-group">
                    <label class="control-label">{{ 'kpis.threshold_2' | translate }}</label>
                    <input class="form-control" currencyMask [options]="{'suffix': ' %', 'min': 0}" formControlName="threshold_2" name="threshold_2" type="text">
                  </div>
                </div>
              </div>

              <app-ai-helper *ngIf="!kpi" [formGroup]="kpiEditForm" [scope]="'EVALUATION'" [formPatchFn]="patchMetricsToForm.bind(this)" [controlName]="'metrics'"></app-ai-helper>

                <fieldset *ngIf="!kpi" class="fieldset-bordered" formArrayName="metrics">
                    <legend>{{ 'kpis.metrics' | translate }}</legend>
                    <div class="row">
                        <div class="col-7">
                            <label class="control-label">
                                <span>{{ 'kpis.name' | translate }}</span>
                            </label>
                        </div>
                    </div>
                    <ng-container *ngFor="let metric of metrics?.controls; let i = index; let last = last">
                        <div [formGroupName]="i" class="row mb-2 align-items-center">
                            <div class="col pr-1">
                                <input class="form-control"
                                       formControlName="name"
                                       type="text">
                            </div>
                            <div *ngIf="last" class="col-auto pl-1">
                                <button (click)="addMetric()" [createButton]="'add_option'" type="button"></button>
                            </div>
                            <div *ngIf="!last" class="col-auto pl-1">
                                <label class="control-label"> </label>
                                <button (click)="removeMetric(i)" class="btn btn-icon btn-primary" type="button">
                                    <i class="mdi mdi-trash-can-outline mdi-24px"></i>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </fieldset>

              <div class="row mt-2">
                <div class="col d-flex justify-content-end">
                  <button (click)="deleteKPI()" *ngIf="kpi" class="btn btn-link text-muted pr-0 mr-4" type="button">
                    <u>{{ 'kpis.action_delete' | translate }}</u>
                  </button>
                  <button class="btn btn-success" type="submit">
                    {{'kpis.action_save' | translate}}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
