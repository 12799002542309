import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';

import { ListParameters, ListResponse, transformParameters } from 'src/app/api';

import { Currency } from '../models/Currency';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  public constructor(
    private _apiHelper: ApiHelper,
    private _http: HttpClient
  ) { }

  public deleteCurrency(code: string): Observable<ApiResponse> {
    return this._http.delete<ApiResponse>(`/api/currencies/${code}`);
  }

  public getAllCurrencies(q?: string): Observable<Array<Currency>> {
    let params = null;

    if (q) {
      params = new HttpParams()
        .set('q', q);
    }

    return this._http.get<Array<Currency>>('/api/currencies', { params });
  }

  public getCurrencyByCode(code: string): Observable<Currency> {
    return this._http.get<Currency>(`/api/currencies/${code}`);
  }

  public getCurrenciesList(params: ListParameters<Currency>): Observable<ListResponse<Currency>> {
    return this._http.post<ListResponse<Currency>>('/api/currencies/list', { ...transformParameters(params) });
  }

  public saveCurrency(form: Currency): Observable<ApiResponse> {
    return this._http.post<ApiResponse>('/api/currencies', form);
  }

  public setPrimary(code: string): Promise<'done'> {
    return new Promise(resolve => {
      this._http.post<ApiResponse>(`/api/currencies/${code}/set-primary`, {})
        .subscribe(
          response => {
            this._apiHelper.handleSuccessResponse(response);

            resolve('done');
          },
          error => this._apiHelper.handleErrorResponse(error)
        );
    });
  }

  public updateCurrency(code: string, form: Currency): Observable<ApiResponse> {
    return this._http.post<ApiResponse>(`/api/currencies/${code}`, form);
  }
}
