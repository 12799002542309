<ng-container *ngIf="(canViewCompanySettings$ | async)?.can">
  <ng-template
  #logoModal
  let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'settings.company.custom_logo' | translate }}</span>
      </p>

      <button
        (click)="modal.dismiss()"
        aria-label="Close"
        class="close"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <img [src]="imagePath" alt="logo" class="rounded mt-1">
    </div>
  </div>
</ng-template>

  <section id="basic">
    <app-company-settings-basic (refetchCompanySettings)="refetchSettings()"
                                [companySettings$]="companySettings$"
    ></app-company-settings-basic>
  </section>

  <section id="address">
    <app-company-settings-address [companySettings$]="companySettings$"></app-company-settings-address>
  </section>

  <section id="locale">
    <div class="row">
    <div class="col-md-12">
      <div class="card shadow">
        <app-help name="setting.company.default_language"></app-help>

        <div class="card-header">
          <p class="modal-title font-16 text-dark pt-2 pb-2">
            <span>{{ 'settings.company.app.default_language' | translate }}</span>
          </p>
        </div>

        <div *ngIf="companySettings$ | async; else loadingTemplate" class="card-body">
          <form
              (ngSubmit)="onSubmitLocale()"
              [formGroup]="localeForm"
              autocomplete="off"
          >
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="control-label">
                    <span>{{ 'settings.company.app.change_default_language' | translate }}</span>
                  </label>

                  <ng-select
                      [clearable]="false"
                      [items]="availableLocales$ | async"
                      class="custom"
                      formControlName="app.locale"
                      name="app.locale"
                  >
                    <ng-template
                        let-item="item"
                        ng-option-tmp
                    >
                      <span>{{ 'global.language_' + item | translate }}</span>
                    </ng-template>

                    <ng-template
                        let-item="item"
                        ng-label-tmp
                    >
                      <span>{{ 'global.language_' + item | translate }}</span>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
              <div class="row">
                  <div class="col-6">
                      <label class="control-label">{{ 'settings.company.app.locale_update_self' | translate }}</label>
                  </div>
                  <div class="col-6 text-right align-self-center">
                      <div>
                          <input data-switch="success" formControlName="app.locale_update_self" id="app.locale_update_self" name="app.locale_update_self" type="checkbox">
                          <label for="app.locale_update_self">&nbsp;</label>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <label class="control-label">{{ 'settings.company.app.locale_update_all' | translate }}</label>
                  </div>
                  <div class="col-6 text-right align-self-center">
                      <div>
                          <input data-switch="success" formControlName="app.locale_update_all" id="app.locale_update_all" name="app.locale_update_all" type="checkbox">
                          <label for="app.locale_update_all">&nbsp;</label>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <label class="control-label">{{ 'settings.company.app.locale_update_enforce' | translate }}</label>
                  </div>
                  <div class="col-6 text-right align-self-center">
                      <div>
                          <input data-switch="success" formControlName="app.locale_update_enforce" id="app.locale_update_enforce" name="app.locale_update_enforce" type="checkbox">
                          <label for="app.locale_update_enforce">&nbsp;</label>
                      </div>
                  </div>
              </div>

            <div class="row mt-2">
              <div class="col d-flex justify-content-end">
                <button class="btn btn-success">
                  <span>{{ 'employees.action_save' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  </section>

  <section id="currency">
    <app-company-settings-currency></app-company-settings-currency>
  </section>

  <section id="logo">
    <div class="card shadow">
      <app-help name="settings.company.logo"></app-help>

      <div class="card-header py-3">
        <div class="row">
          <div class="col-sm-6 text-left my-auto">
            <p class="modal-title font-16 text-dark pt-2 pb-2">
              <span>{{ 'settings.company.info.logo_file_ID' | translate }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="card-body">
        <app-upload
          (success)="refetchSettings()"
          [allowTakingPicture]="false"
          [modelName]="'file_type'"
          [modelValue]="'settings_company_info_logo_file_ID'"
        ></app-upload>

        <div *ngIf="customLogo" class="mt-2">
          <table class="table table-centered mb-0">
            <tbody>
              <tr>
                <td class="pl-0 text-truncate">{{ 'settings.company.custom_logo' | translate }}</td>

                <td class="pr-0 text-right text-nowrap">
                  <button (click)="openLogoModal()" class="btn btn-link btn-icon btn-sm mr-2" title="{{ 'employees.action_view' | translate }}" type="button">
                    <i class="mdi mdi-eye-outline text-muted mdi-24px"></i>
                  </button>

                    <a href="{{logoDownloadUrl}}" target="_blank" download="logo.png" class="btn btn-link btn-icon btn-sm mr-2">
                        <i class="mdi mdi-tray-arrow-down text-muted mdi-24px"></i>
                    </a>

                  <button (click)="removeLogo()" class="btn btn-link btn-icon waves-effect pt-1 pb-0 pl-0 pr-0" type="button">
                    <i class="mdi mdi-trash-can-outline mdi-24px text-muted"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>

    <section id="delete_tenant">
        <div class="card shadow">
            <app-help name="settings.company.delete_tenant"></app-help>

            <div class="card-header py-3">
                <div class="row">
                    <div class="col-sm-6 text-left my-auto">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">
                            <span>{{ 'settings.company.info.delete_tenant' | translate }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-12 text-right">
                        <small *ngIf="!deleteRequested">{{ 'settings.company.info.delete_tenant_description' | translate }}</small>
                        <small *ngIf="deleteRequested">{{ 'settings.company.info.delete_tenant_requested' | translate }} {{deleteRequested | date:'dd.MM.yyyy'}}.</small>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col d-flex justify-content-end">
                        <button *ngIf="!deleteRequested" (click)="deleteTenant(false)" class="btn btn-success">
                            <span>{{ 'settings.company.info.delete_tenant_action' | translate }}</span>
                        </button>
                        <button *ngIf="deleteRequested" (click)="deleteTenant(true)" class="btn btn-success">
                            <span>{{ 'settings.company.info.delete_tenant_action_cancel' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-container>
<ng-template #loadingTemplate>
  <div class="row">
    <div class="col-12">
      <div class="p-3">
        <div class="item">
          <ngx-skeleton-loader [theme]="{
        'margin': '15px 0'
      }" appearance="line" count="4"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</ng-template>
