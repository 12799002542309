<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">
      <span>{{ 'files.signature' | translate }}</span>
    </p>

    <button
      (click)="activeModal.dismiss()"
      aria-label="Close"
      class="close"
    >
      <span
        aria-hidden="true"
        class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <div class="signature-pad-wrapper mb-2">
              <signature-pad
                (drawEnd)="drawComplete()"
                [options]="signaturePadOptions"
                id="signaturePad"
              ></signature-pad>
            </div>

            <ng-container *ngIf="mode === 'SIGN'">
              <div class="row mt-2">
                <div class="col">
                  <button
                    (click)="collapse.toggle()"
                    [attr.aria-expanded]="!isCollapsed"
                    aria-controls="collapseExample"
                    class="btn btn-link text-muted pl-0"
                  >
                    <u>{{ 'files.upload_img_signature' | translate }}</u>
                  </button>
                </div>
                  <div class="col-auto text-right">
                      <button
                          (click)="clearPad()"
                          class="btn btn-link text-muted pr-0"
                      >
                          <u>{{ 'files.action_clear' | translate }}</u>
                      </button>
                  </div>
              </div>

              <div
                #collapse="ngbCollapse"
                [(ngbCollapse)]="isCollapsed"
                class="row mt-2"
              >
                <div class="col-12">
                  <app-upload
                    (reset)="onRemoveFile()"
                    (thumbnail)="onAddedFile($event)"
                    [allowTakingPicture]="false"
                    [process$]="processFileQueue$"
                  ></app-upload>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'files.location' | translate }}</span></label>

                    <input
                      #location="ngModel"
                      [(ngModel)]="signLocation"
                      class="form-control"
                      name="location"
                      required
                      type="text"
                    >
                  </div>
                </div>
              </div>

              <div
                *ngIf="showSaveSignatureControl"
                class="row"
              >
                <div class="col-6">
                  <label class="control-label mb-0">
                    <span>{{ 'files.save_signature' | translate }}</span>
                  </label>
                </div>

                <div class="col-6 text-right align-self-center">
                  <div>
                    <input
                      [(ngModel)]="saveSignature"
                      data-switch="success"
                      id="save_signature"
                      name="save_signature"
                      type="checkbox"
                    >

                    <label for="save_signature">&nbsp;</label>
                  </div>
                </div>
              </div>

              <!--<div
                *ngIf="!showSaveSignatureControl"
                class="row"
              >
                <div class="col-12">
                  <button
                    class="btn btn-link text-muted pl-0"
                    [disabled]="!location.valid"
                    (click)="closeModal(true)"
                  >
                    <u>{{ 'files.user_saved_signature' | translate }}</u>
                  </button>
                </div>
              </div>-->

              <div class="row mt-2 justify-content-end">
                <div class="col-6 text-right">
                  <button
                    (click)="closeModal(false)"
                    [disabled]="!signature"
                    class="btn btn-primary pr-0"
                  >
                    <span>{{ 'files.action_sign' | translate }}</span>
                  </button>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="mode === 'SETTING'">
              <div class="row mt-2">
                <div class="col-12">
                  <button
                    (click)="collapse.toggle()"
                    [attr.aria-expanded]="!isCollapsed"
                    aria-controls="collapseExample"
                    class="btn btn-link text-muted pl-0"
                  >
                    <u>{{ 'files.upload_img_signature' | translate }}</u>
                  </button>
                </div>
              </div>

              <div
                #collapse="ngbCollapse"
                [(ngbCollapse)]="isCollapsed"
                class="row mt-2"
              >
                <div class="col-12">
                  <app-upload
                    (reset)="onRemoveFile()"
                    (thumbnail)="onAddedFile($event)"
                    [allowTakingPicture]="false"
                    [process$]="processFileQueue$"
                  ></app-upload>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-6">
                  <button
                    (click)="activeModal.close(signature)"
                    [disabled]="!signature"
                    class="btn btn-primary"
                  >
                    <span>{{ 'files.action_sign' | translate }}</span>
                  </button>
                </div>

                <div class="col-6 text-right">
                  <button
                    (click)="clearPad()"
                    class="btn btn-link text-muted pr-0"
                  >
                    <u>{{ 'files.action_delete' | translate }}</u>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
