import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-value-or-dash',
  templateUrl: './value-or-dash.component.html',
  styleUrls: ['./value-or-dash.component.css']
})
export class ValueOrDashComponent {
  @ViewChild('ref')
  public set foo(element: ElementRef) {
    const el = element.nativeElement;

    this.hasContent = el.innerText !== '' || el.children.length > 0;

    this._changeDetector.detectChanges();
  }

  public hasContent: boolean;

  public constructor(private _changeDetector: ChangeDetectorRef) { }
}
