import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateTimeProject } from '../../models/DatetimeProject';
import { DatetimeProjectService } from '../../services/datetime-project.service';

@Component({
    selector: 'app-create-edit-datetime-project-modal',
    templateUrl: './create-edit-datetime-project-modal.component.html',
    styleUrls: ['./create-edit-datetime-project-modal.component.css']
})
export class CreateEditDatetimeProjectModalComponent implements OnInit {
    public datetimeProject: DateTimeProject;
    public datetimeProjectForm: UntypedFormGroup;
    public submitted = false;

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.datetimeProjectForm.controls;
    }

    public constructor(
        public activeModal: NgbActiveModal,
        private _fb: UntypedFormBuilder,
        private _datetimeProjectService: DatetimeProjectService
    ) { }

    public ngOnInit(): void {
        this.datetimeProjectForm = this._fb.group({
            name: ['', Validators.required],
            description: [''],
        });

        if (this.datetimeProject) {
            this.datetimeProjectForm.patchValue(this.datetimeProject);
        }
    }

    public deleteProject(): void {
        this._datetimeProjectService.deleteDatetimeProject(this.datetimeProject.employee_datetime_project_ID)
            .then(
                () => this.activeModal.close(),
                () => { }
            );
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.datetimeProjectForm.valid) {
            return;
        }

        this._datetimeProjectService.saveDatetimeProject(this.datetimeProjectForm.value, this.datetimeProject?.employee_datetime_project_ID)
            .then(() => this.activeModal.close());
    }
}
