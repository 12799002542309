import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, } from 'rxjs';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { NumberSeries } from '../models/NumberSeries';

@Injectable({
    providedIn: 'root'
})
export class NumberSeriesService {

    constructor(
        private http: HttpClient,
        private loaderService: NgxUiLoaderService,
        private swalHelper: SwalHelper,
        private apiHelper: ApiHelper
    ) { }

    getAllNumberSeries(): Observable<NumberSeries[]> {
        return this.http.get<NumberSeries[]>('/api/number-series');
    }

    getNumberSeriesByID(numberSerieID: number): Observable<NumberSeries> {
        return this.http.get<NumberSeries>('/api/number-series/' + numberSerieID);
    }

    getNewCodeForObject(objectName: string): Observable<{ code: string }> {
        return this.http.get<{ code: string }>('/api/number-series/codes/' + objectName + '/new-for-object');
    }

    saveNumberSeries(name: string, defaultFor: string, codeFormat: string, numberSerieID?: number) {
        this.loaderService.start();
        return new Promise(resolve => {
            if (numberSerieID) {
                this.http.post('/api/number-series/' + numberSerieID, {name, default_for: defaultFor, code_format: codeFormat}).subscribe((response: any) => {
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                    this.loaderService.stop();
                }, error => {
                    this.apiHelper.handleErrorResponse(error);
                    this.loaderService.stop();
                });
            } else {
                this.http.post('/api/number-series', {name, default_for: defaultFor, code_format: codeFormat}).subscribe((response: any) => {
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                    this.loaderService.stop();
                }, error => {
                    this.apiHelper.handleErrorResponse(error);
                    this.loaderService.stop();
                });
            }
        });
    }

    deleteNumberSeries(numberSerieID: number) {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this.swalHelper.showConfirmDeleteDialog();
            if (deleteConfirmed) {
                this.loaderService.start();
                this.http.delete('/api/number-series/' + numberSerieID).subscribe((response: ApiResponse) => {
                    this.loaderService.stop();
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                }, error => {
                    this.loaderService.stop();
                    this.apiHelper.handleErrorResponse(error);
                });
            } else {
                reject('error');
            }
        });
    }
}
