import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Benefit } from 'src/app/benefit-bonus/benefit/state/benefit.model';
import { BenefitService } from 'src/app/benefit-bonus/benefit/state/benefit.service';
import { InputVisibilityService } from '../../../../shared/services/InputVisibilityService';

@Component({
    selector: 'app-create-edit-benefit-modal',
    templateUrl: './create-edit-benefit-modal.component.html',
    styleUrls: ['./create-edit-benefit-modal.component.css']
})
export class CreateEditBenefitModalComponent implements OnInit {
    public benefit: Benefit;
    public benefitEditForm: UntypedFormGroup;
    public submitted = false;

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.benefitEditForm.controls;
    }

    public get hiddenInputs(): boolean {
        return this._inputVisibilityService.hiddenInputs;
    }

    public constructor(
        public activeModal: NgbActiveModal,
        private _benefitService: BenefitService,
        private _fb: UntypedFormBuilder,
        private _inputVisibilityService: InputVisibilityService
    ) { }

    public ngOnInit(): void {
        this.benefitEditForm = this._fb.group({
            name: ['', Validators.required],
            employee_cost: [0, Validators.required],
            employer_cost: [0, Validators.required],
            note: ['']
        });

        if (this.benefit) {
            this.benefitEditForm.patchValue({
                name: this.benefit.name,
                employee_cost: this.benefit.employee_cost,
                employer_cost: this.benefit.employer_cost,
                note: this.benefit.note
            });

            if (this.hiddenInputs) {
                this.benefitEditForm.disable();
            }
        }
    }

    public deleteBenefit(): void {
        this._benefitService.deleteBenefit(this.benefit.benefit_ID);
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.benefitEditForm.valid) {
            return;
        }

        const benefit = this.benefitEditForm.value;

        if (this.benefit) {
            this._benefitService.saveBenefit(benefit, this.activeModal, this.benefit.benefit_ID);
        } else {
            this._benefitService.saveBenefit(benefit, this.activeModal);
        }
    }
}
