<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">
            <span>{{ 'employees.change_active_access' | translate }}</span>
        </p>

        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
        >
                    <span
                        aria-hidden="true"
                        class="font-24 text-muted"
                    >
                      <i class="mdi mdi-close"></i>
                    </span>
        </button>
    </div>
    <div class="modal-body">
        <form
            (ngSubmit)="onSubmit()"
            [formGroup]="form"
        >
            <ng-container *ngIf="!employee.has_active_access">
                <div class="row mb-2">
                    <div class="col-12">
                        <span>{{ 'employees.no_active_access_text' | translate }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <label class="control-label">{{ 'employees.send_invite' | translate }}</label>
                    </div>
                    <div class="col-6 text-right align-self-center">
                        <div>
                            <input data-switch="success" formControlName="send_invite" id="send_invite" name="send_invite" type="checkbox">
                            <label for="send_invite">&nbsp;</label>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12 text-right">
                        <button submitButton type="submit" style="padding-left: 8px !important; padding-right: 8px !important;" class="btn btn-primary">
                            <span>{{ 'employees.action_activate_employee' | translate }}</span>
                        </button>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="employee.has_active_access">
                <div class="row mb-2">
                    <div class="col-12">
                        <span>{{ 'employees.active_access_text' | translate }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <span>{{ 'employees.change_password_text' | translate }} </span>
                        <u [routerLink]="['/employee', employee.employee_ID, 'detail', 'setting']" class="text-muted"><span>{{ 'employees.change_password_action' | translate }}</span></u>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12 text-right">
                        <button submitButton type="submit" style="padding-left: 8px !important; padding-right: 8px !important;" class="btn btn-primary">
                            <span>{{ 'employees.action_deactivate_employee' | translate }}</span>
                        </button>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
</div>
