import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { IntegrationProviderType } from '../integration-providers.type';
import { ActiveIntegrations } from '../models/ActiveIntegrations';
import { Integration } from '../models/Integration';
import { IntegrationConfig } from '../models/IntegrationConfig';

interface IntegrationFromServer {
    provider_id: string;
    provider_name: string;
    email: string;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class IntegrationService {
    public constructor(
        private _azureAuthService: MsalService,
        private _http: HttpClient,
        private _apiHelper: ApiHelper,
        private _loaderService: NgxUiLoaderService,
    ) { }

    public connectAzure(): Observable<any> {
        return this._azureAuthService.loginPopup()
            .pipe(switchMap(azureResponse => this._http.post('/api/azure/pair-myself', azureResponse)));
    }

    public disconnect(providerName: IntegrationProviderType, userId: number): Observable<any> {
        return this._http.post(`/api/auth/providers/${providerName}/unpair`, {userId});
    }

    public getAvailableIntegrations(): Observable<Integration[]> {
        return this._http.post<Integration[]>(`/api/integrations/list`, {});
    }

    public getActiveIntegrations(): Observable<string[]> {
        return this._http.get<string[]>(`/api/integrations/active`, {});
    }

    public getIntegrationConfig(key: string): Observable<IntegrationConfig> {
        return this._http.post<IntegrationConfig>(`/api/integrations/config`, {key});
    }

    public saveIntegration(formData: any, key: string): Promise<'done'> {
        this._loaderService.start();
        const data = {
            is_active: formData.is_active,
            config: formData.config,
            key,
            msal: formData.msal
        };

        return new Promise(resolve => {
                this._http.post<ApiResponse>('/api/integrations/save', data)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            this._loaderService.stop();

                            resolve('done');
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            }
        );
    }

    public getAllActiveByUserId(userId: number): Observable<ActiveIntegrations> {
        return this._http.get<Array<IntegrationFromServer>>(`/api/auth/providers/${userId}`)
            .pipe(
                map(providers => {
                    const integrations = {};

                    providers.forEach(p => {
                        integrations[p.provider_name] = {
                            email: p.email,
                            id: p.provider_id,
                            name: p.name
                        };
                    });

                    return integrations;
                })
            );
    }

    public generateSlackAccessToken(code: string): Promise<'done'> {
        this._loaderService.start();

        return new Promise((resolve, reject) => {
                this._http.post<ApiResponse>('/api/integrations/slack/callback', {code})
                    .subscribe(
                        response => {
                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();
                            this._apiHelper.handleErrorResponse(error);
                            reject(error);
                        }
                    );
            }
        );
    }

    public syncSlackUsers(): Promise<'done'> {
        return new Promise((resolve, reject) => {
            this._http.get<any>(`/api/integrations/active`, {})
                .subscribe(
                    response => {
                        resolve(response);
                        this._loaderService.stop();
                    },
                    error => {
                        this._loaderService.stop();
                        this._apiHelper.handleErrorResponse(error);
                        reject(error);
                    });
        });
    }
}
