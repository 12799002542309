<ng-template
    #content
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'employees_kpis.assign_kpi' | translate }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-0 shadow-none">
                        <div class="card-body p-0">
                            <form
                                (ngSubmit)="onSubmitKPI()"
                                [formGroup]="assignKPIsForm"
                                autocomplete="off"
                            >
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label">
                                                <span>{{ 'employees_kpis.kpi' | translate }}</span>
                                            </label>

                                            <ng-select
                                                [items]="kpis$ | async"
                                                [multiple]="true"
                                                [placeholder]="'employees_kpis.without_kpi' | translate"
                                                bindLabel="name"
                                                bindValue="kpi_ID"
                                                class="custom"
                                                formControlName="kpiIDs"
                                            ></ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    *ngIf="canCreateKPI$ | async"
                                    class="row"
                                >
                                    <div class="col-12 text-right">
                                        <button
                                            (click)="openKPICreateModal()"
                                            class="btn btn-link p-0"
                                            type="button"
                                        >{{ 'kpis.action_create' | translate }}</button>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-6">
                                        <button class="btn btn-primary">
                                            <span>{{ 'employees_kpis.assign_kpi' | translate }}</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template
    #contentGrowth
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'employees_kpis.assign_growth' | translate }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-0 shadow-none">
                        <div class="card-body p-0">
                            <form
                                (ngSubmit)="onSubmitGrowth()"
                                [formGroup]="assignGrowthsForm"
                                autocomplete="off"
                            >
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label">
                                                <span>{{ 'employees_kpis.apply_template' | translate }}</span>
                                            </label>

                                            <ng-select
                                                (change)="growthSelectionChanged()"
                                                [items]="growths$ | async"
                                                [multiple]="true"
                                                [placeholder]="'employees_kpis.create_new' | translate"
                                                bindLabel="name"
                                                bindValue="growth_ID"
                                                class="custom"
                                                formControlName="growthIDs"
                                            ></ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    *ngIf="canCreateGrowth$ | async"
                                    class="row"
                                >
                                    <div class="col-12 text-right">
                                        <button
                                            (click)="openGrowthCreateModal()"
                                            class="btn btn-link p-0"
                                            type="button"
                                        >{{ 'growths.action_create' | translate }}</button>
                                    </div>
                                </div>

                                <div
                                    *ngIf="assignGrowthsForm.controls.name"
                                    class="row"
                                >
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label">
                                                <span>{{ 'employees_kpis.name' | translate }}</span>
                                            </label>

                                            <input
                                                [placeholder]="'employees_kpis.name' | translate"
                                                class="form-control"
                                                formControlName="name"
                                                type="text"
                                            >

                                            <div *ngIf="submittedGrowths && fGrowths.name.errors">
                                                <p
                                                    *ngIf="fGrowths.name.errors.required"
                                                    class="text-danger validation-text"
                                                >
                                                    <span>{{ 'angular_validation.field' | translate }} </span>
                                                    <span>{{ 'employees_onboardings.name' | translate }} </span>
                                                    <span>{{ 'angular_validation.required' | translate }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    *ngIf="assignGrowthsForm.controls.description"
                                    class="row"
                                >
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label">
                                                <span>{{ 'employees_kpis.description' | translate }}</span>
                                            </label>

                                            <textarea
                                                class="form-control"
                                                formControlName="description"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    *ngIf="assignGrowthsForm.controls.costs"
                                    class="row"
                                >
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label">
                                                <span>{{ 'growths.costs' | translate }}</span>
                                            </label>

                                            <input class="form-control" currencyMask formControlName="costs" name="costs" type="text">
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-6">
                                        <button class="btn btn-primary">
                                            <span>{{ 'employees_kpis.assign_growth' | translate }}</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-container *ngIf="checkPermission('kpi') && (availableFeatures$ | async)?.evaluations">
    <div *ngIf="(availableWidgets$ | async)?.employee?.evaluations?.kpi && (assignedKpis$ | async); let kpis" class="row">
        <div class="col-12">
            <div class="card shadow">
                <app-help name="employee.detail.kpi"></app-help>

                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-sm-6 text-left my-auto">
                            <p class="modal-title font-16 pt-2 pb-2 text-dark">
                                <span>{{ 'employees_kpis.employee_kpi' | translate }}</span>
                            </p>
                        </div>

                        <div
                            *ngIf="canEditKPI$ | async"
                            class="col-sm-6 text-right my-auto"
                        >
                            <button
                                (click)="openKPIAssignModal(content)"
                                [createButton]="'employees_kpis.assign_kpi' | translate"
                            ></button>
                        </div>
                    </div>
                </div>

                <div class="card-body px-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <app-data-table
                                    [columnsToFilterIn]="['name']"
                                    [rows]="kpis"
                                    [trackByFn]="trackByKPI"
                                >
                                    <ng-template #headerRow>
                                        <th
                                            [disableSort]="true"
                                            appDataTableHeaderCell
                                        >{{ 'employees_kpis.name' | translate }}</th>

                                        <th>
                                            <span>{{ 'employees_kpis.score_average' | translate }}</span>
                                            <span> (%)</span>
                                        </th>
                                        <th>
                                            <span>{{ 'employees_kpis.score_last' | translate }}</span>
                                            <span> (%)</span>
                                        </th>

                                        <th
                                            [disableSort]="true"
                                            appDataTableHeaderCell
                                            class="text-right"
                                        >{{ 'employees_kpis.table_action' | translate }}</th>
                                    </ng-template>

                                    <ng-template
                                        #dataRow
                                        let-row
                                    >
                                        <td appDataTableDataCell>{{ row.name }}</td>

                                        <td class="text-nowrap">
                                            {{ row.avg_score | number: '1.0-2' }}
                                        </td>
                                        <td class="text-nowrap">
                                            {{ row.last_score | number: '1.0-2' }}
                                        </td>

                                        <td
                                            *ngIf="canEditKPI$ | async"
                                            appDataTableDataCell
                                            class="text-right"
                                        >
                                            <!--<button
                                                    class="btn btn-white btn-icon btn-sm mr-1"
                                                    title="{{ 'kpis.action_edit' | translate }}"
                                                    (click)="openModificationModal(row)"
                                            >
                                              <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                            </button>-->

                                            <button (click)="openRadarChart('both', row)"
                                                    class="btn btn-white btn-icon btn-sm">
                                                <i class="ml-2 cursor-pointer mdi-24px text-muted mdi mdi-chart-line"> </i>
                                            </button>
                                            <button
                                                (click)="unassignEmployeeFromKPI(row)"
                                                class="btn btn-white btn-icon btn-sm"
                                                title="{{ 'employees_kpis.action_remove' | translate }}"
                                            >
                                                <i class="mdi mdi-close mdi-24px text-muted"></i>
                                            </button>
                                        </td>
                                    </ng-template>
                                </app-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="checkPermission('employeeReview') && (availableFeatures$ | async)?.evaluations">
    <div *ngIf="(availableWidgets$ | async)?.employee?.evaluations?.reviews && (kpiReviews$ | async); let kpiReviews" class="row">
        <div class="col-12">
            <div class="card shadow">
                <app-help name="employee.detail.reviews"></app-help>

                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-sm-6 text-left my-auto">
                            <p class="modal-title font-16 pt-2 pb-2 text-dark">
                                <span>{{ 'employees_kpis.rating' | translate }}</span>
                            </p>
                        </div>

                        <div
                            *ngIf="(canEditKPI$ | async) && (assignedKpis$ | async); let assignedKpis"
                            class="col-sm-6 text-right my-auto"
                        >
                            <button
                                (click)="openReviewEditModal(assignedKpis, null)"
                                *ngIf="true || assignedKpis.length"
                                [createButton]="'employees_kpis.action_fill' | translate"
                            ></button>
                        </div>
                    </div>
                </div>

                <div class="card-body px-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <app-data-table
                                    [columnsToFilterIn]="['date', 'score']"
                                    [rows]="kpiReviews"
                                    [trackByFn]="trackByReview"
                                >
                                    <ng-template #headerRow>
                                        <th
                                            [disableSort]="true"
                                            appDataTableHeaderCell
                                        >{{ 'employees_kpis.date' | translate }}</th>

                                        <th
                                            [disableSort]="true"
                                            appDataTableHeaderCell
                                        >{{ 'employees_kpis.rating' | translate }}</th>

                                        <th
                                            [disableSort]="true"
                                            appDataTableHeaderCell
                                        >{{ 'employees_kpis.score' | translate }}</th>

                                        <th
                                            [disableSort]="true"
                                            appDataTableHeaderCell
                                            class="text-right"
                                        >{{ 'employees_kpis.table_action' | translate }}</th>
                                    </ng-template>

                                    <ng-template
                                        #dataRow
                                        let-row
                                    >
                                        <td appDataTableDataCell>{{ row.date | date: 'dd.MM.yyyy' }}</td>

                                        <td appDataTableDataCell>
                      <span>
                        <img *ngIf="row.rating" height="22" src="{{ 'assets/svg/emoji_rating/' + row.rating + '.svg' }}"/>
                        <span *ngIf="!row.rating">--</span>
                      </span>
                                        </td>


                                        <td appDataTableDataCell>
                                            <span>{{ row.score | number: '1.0-2' }}</span>
                                        </td>

                                        <td
                                            appDataTableDataCell
                                            class="text-right"
                                        >
                                            <a
                                                (click)="openReviewEditModal(kpis, row)"
                                                *ngIf="kpis$ | async; let kpis"
                                                class="btn btn-white btn-icon btn-sm mr-1"
                                                title="{{ 'employees.action_view' | translate }}"
                                            >
                                                <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                            </a>
                                        </td>
                                    </ng-template>
                                </app-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="checkPermission('growth') && (availableFeatures$ | async)?.growths">
    <div *ngIf="(availableWidgets$ | async)?.employee?.evaluations?.growth && (assignedGrowths$ | async); let growths" class="row">
        <div class="col-12">
            <div class="card shadow">
                <app-help name="employee.detail.growth"></app-help>

                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-sm-6 text-left my-auto">
                            <p class="modal-title font-16 pt-2 pb-2 text-dark">
                                <span>{{ 'employees_kpis.employee_growth' | translate }}</span>
                            </p>
                        </div>

                        <div class="col-sm-6 text-right my-auto">
                            <button
                                (click)="openGrowthAssignModal(contentGrowth)"
                                *ngIf="canEditGrowth$ | async"
                                [createButton]="'employees_kpis.assign_growth' | translate"
                            ></button>
                        </div>
                    </div>
                </div>

                <div class="card-body px-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <app-data-table
                                    [columnsToFilterIn]="['name', 'percent_progress']"
                                    [rows]="growths"
                                    [trackByFn]="trackByGrowth"
                                >
                                    <ng-template #headerRow>
                                        <th
                                            [disableSort]="true"
                                            appDataTableHeaderCell
                                        >{{ 'employees_kpis.name' | translate }}</th>

                                        <th
                                            [disableSort]="true"
                                            appDataTableHeaderCell
                                        >{{ 'employees_kpis.progress' | translate }}</th>

                                        <th
                                            [disableSort]="true"
                                            appDataTableHeaderCell
                                            class="text-right"
                                        >{{ 'employees_kpis.table_action' | translate }}</th>
                                    </ng-template>

                                    <ng-template
                                        #dataRow
                                        let-row
                                    >
                                        <td appDataTableDataCell>{{ row.name }}</td>

                                        <td appDataTableDataCell>
                                            <div class="progress">
                                                <div
                                                    [style.width.%]="row.percent_progress"
                                                    aria-valuemax="100"
                                                    aria-valuemin="0"
                                                    aria-valuenow="progressBarWidth"
                                                    class="progress-bar"
                                                    role="progressbar"
                                                ></div>
                                            </div>
                                        </td>

                                        <td
                                            appDataTableDataCell
                                            class="text-right"
                                        >
                                            <div>
                                                <button
                                                    (click)="openEmployeeGrowthOverviewModal(row)"
                                                    class="btn btn-white btn-icon btn-sm"
                                                    title="{{ 'employees.action_view' | translate }}"
                                                >
                                                    <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </ng-template>
                                </app-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
