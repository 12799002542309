import { Component, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';

import { CustomerService } from 'src/app/crm/services/customer.service';

import { SettingGuideBasicModalComponent } from '../../partials/setting-guide-basic-modal/setting-guide-basic-modal.component';

@Component({
  selector: 'app-setting-guide',
  templateUrl: './setting-guide.component.html',
  styleUrls: ['./setting-guide.component.css']
})
export class SettingGuideComponent implements OnInit {
  public basicSettingsProgress$: Observable<{ progress: number }>;

  public constructor(
    private _customerService: CustomerService,
    private _modalService: NgbModal
  ) { }

  public ngOnInit(): void {
    this.basicSettingsProgress$ = this._customerService.getBasicOnBoardingProgress();
  }

  public openGuideModal(): void {
    this._modalService.open(SettingGuideBasicModalComponent, { centered: true });
  }
}
