<div *ngIf="import$ | async as import">
  <div class="row">
    <div class="col-lg-6 mb-3">
      <div class="card h-100 shadow">
        <app-help name="import.detail.general_info"></app-help>

        <div class="card-header py-3">
          <div class="row">
            <div class="col-6 col-sm-6 col-md-7">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'global.basic_info' | translate }}</span>
              </p>
            </div>

            <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
              <!-- <button (click)="openEditModal(import)" class="btn btn-primary d-none d-sm-inline-block">{{'products.action_edit' | translate}}</button>
                          <button (click)="openEditModal(import)" class="btn btn-primary btn-icon d-inline-block d-sm-none font-18"><i class="mdi mdi-pencil-outline"></i></button> -->
            </div>
          </div>
        </div>

        <div class="card-body px-4">
          <div class="row border-bottom text-nowrap">
            <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
              <span class="text-dark">{{ 'imports.table_filename' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span>{{ import.filename }}</span>
            </div>
          </div>

          <div class="row border-bottom text-nowrap">
            <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
              <span class="text-dark">{{ 'imports.table_entity' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span>{{ 'imports.table_entity_' + import.entity | translate }}</span>

              <span *ngIf="routeParams.length">
                <span> (</span>
                <a [routerLink]="routeParams">
                  <span>{{ import.entity_target.fullname || import.entity_target.name }}</span>
                </a>
                <span>)</span>
              </span>
            </div>
          </div>

          <div class="row border-bottom text-nowrap">
            <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
              <span class="text-dark">{{ 'imports.table_created_at' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span>{{ import.created_at | date: 'dd.MM.yyyy hh:mm' }}</span>
            </div>
          </div>

          <div class="row border-bottom text-nowrap">
            <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
              <span class="text-dark">{{ 'imports.table_user' | translate }}</span>
            </div>

            <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2">
              <span>{{ import.author?.fullname }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-lg-6 mb-3"
      *ngIf="import.permissions?.delete"
    >
      <div class="card justify-content-center shadow">
        <app-help name="import.detail.delete"></app-help>

        <div class="card-header border-bottom-0 py-3">
          <div class="row">
            <div class="col-6 col-sm-6 col-md-7 text-nowrap">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'imports.action_delete' | translate }}</span>
              </p>
            </div>

            <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
              <button
                class="btn btn-primary d-none d-sm-inline-block"
                (click)="deleteImport(import.import_ID)"
              >{{ 'imports.action_delete' | translate }}</button>

              <button
                class="btn btn-primary btn-icon d-inline-block d-sm-none font-18"
                (click)="deleteImport(import.import_ID)"
              >
                <i class="mdi mdi-delete-outline"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card shadow">
        <app-help name="import.detail.imported_item.list"></app-help>

        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-hover table-centered mb-0">
              <thead class="thead-light">
                <tr>
                  <th *ngFor="let column of import.columns">
                    <span>{{ column.label }}</span>
                  </th>

                  <th class="text-right">{{ 'imports.table_action' | translate }}</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let row of import.items">
                  <td
                    *ngFor="let column of row"
                    class="text-nowrap"
                  >{{ column }}</td>

                  <td class="text-right">
                    <button
                      class="btn btn-white btn-icon btn-sm"
                      [attr.title]="'imports.action_delete_item' | translate"
                      (click)="deleteImportItem(row.import_item_ID)"
                    >
                      <i class="mdi mdi-delete-outline mdi-24px text-muted"></i>
                    </button>
                  </td>
                </tr>

                <tr *ngIf="!import.items?.length">
                  <td
                    *ngFor="let column of import.columns; let i = index"
                    class="text-center text-muted"
                    [attr.colspan]="import.columns.length"
                    [class.d-none]="i !== 0"
                  >{{ 'imports.table_empty' | translate }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
