import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListParameters, ListResponse, transformParameters } from '../../api';
import { ActivityLog, ActivityLogFilter } from '../models/ActivityLog';

@Injectable({
    providedIn: 'root'
})
export class ActivityLogService {

    constructor(
        private _http: HttpClient
    ) { }

    public getList(params: ListParameters<ActivityLog>, filterParameters?: {}, mode = 'simple'): Observable<ListResponse<ActivityLog>> {
        const body: any = {
            ...transformParameters(params),
            filter: filterParameters,
            mode
        };

        return this._http.post<ListResponse<ActivityLog>>('/api/activity-log/list', body);
    }

    public getFilters(mode = 'simple'): Observable<ActivityLogFilter> {
        return this._http.get<any>(`/api/activity-log/filters?mode=${mode}`);
    }
}
