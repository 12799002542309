<div class="row">
  <div class="col-lg-12">
    <div class="card justify-content-center shadow">
      <div class="card-header border-bottom-0 py-3">
        <div class="row">
          <div class="col-12 col-md-3 text-center text-md-left text-nowrap">
            <p class="modal-title font-16 text-dark pt-2 pb-2">{{ 'guides.basic_settings' | translate }}</p>
          </div>

          <div class="col-9 col-md-4 align-self-center">
            <div
              *ngIf="basicSettingsProgress$ | async as p"
              class="progress"
            >
              <div
                aria-valuemax="100"
                aria-valuemin="0"
                class="progress-bar"
                role="progressbar"
                [attr.aria-valuenow]="p.progress"
                [style.width.%]="p.progress"
              ></div>
            </div>
          </div>

          <div class="col-3 col-md-5 text-right align-self-center">
            <button
              class="btn btn-primary d-none d-sm-inline-block"
              (click)="openGuideModal()"
            >{{ 'guides.action_open' | translate }}</button>

            <button
              class="btn btn-primary btn-icon d-inline-block d-sm-none font-18"
              (click)="openGuideModal()"
            >
              <i class="mdi mdi-content-copy"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <div class="card justify-content-center shadow">
      <div class="card-header border-bottom-0 py-3">
        <div class="row">
          <div class="col-12 col-md-3 text-center text-md-left text-nowrap">
            <p class="modal-title font-16 text-dark pt-2 pb-2">
              <span>{{ 'guides.shorthand_human_resources' | translate }}</span>
            </p>
          </div>

          <div class="col-9 col-md-4 align-self-center">
            <div class="progress">
              <div
                aria-valuemax="100"
                aria-valuemin="0"
                aria-valuenow="25"
                class="progress-bar"
                role="progressbar"
                style="width: 25%"
              ></div>
            </div>
          </div>

          <div class="col-3 col-md-5 text-right align-self-center">
            <button class="btn btn-primary d-none d-sm-inline-block">{{ 'guides.action_open' | translate }}</button>

            <button class="btn btn-primary btn-icon d-inline-block d-sm-none font-18">
              <i class="mdi mdi-content-copy"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <div class="card justify-content-center shadow">
      <div class="card-header border-bottom-0 py-3">
        <div class="row">
          <div class="col-12 col-md-3 text-center text-md-left text-nowrap">
            <p class="modal-title font-16 text-dark pt-2 pb-2">
              <span>{{ 'guides.shorthand_customer_relationship_management' | translate }}</span>
            </p>
          </div>

          <div class="col-9 col-md-4 align-self-center">
            <div class="progress">
              <div
                aria-valuemax="100"
                aria-valuemin="0"
                aria-valuenow="25"
                class="progress-bar"
                role="progressbar"
                style="width: 25%"
              ></div>
            </div>
          </div>

          <div class="col-3 col-md-5 text-right align-self-center">
            <button class="btn btn-primary d-none d-sm-inline-block">{{ 'guides.action_open' | translate }}</button>

            <button class="btn btn-primary btn-icon d-inline-block d-sm-none font-18">
              <i class="mdi mdi-content-copy"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
