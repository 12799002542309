import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeContactPerson } from 'src/app/employee/models/EmployeeContactPerson';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { EntityUpdateRequestModalComponent } from '../../../../entity_update_request/components/partials/modal/entity_update_request-modal.component';
import { EntityUpdateRequest } from '../../../../entity_update_request/state/entity_update_request.model';
import { WidgetTemplatesService } from '../../../../shared/services/widget-templates.service';
import { EmployeeRequiredDataUpdates } from '../../../models/EmployeeRequiredDataUpdates';
import { CreateEditEmployeeContactPersonComponent } from '../create-edit-employee-contact-person/create-edit-employee-contact-person.component';

@Component({
    selector: 'app-employee-contact-persons',
    templateUrl: './employee-contact-persons.component.html',
    styleUrls: ['./employee-contact-persons.component.css']
})
export class EmployeeContactPersonsComponent {
    @Input() employee: Employee;
    @Input() employeeContactPersons$: Observable<Array<EmployeeContactPerson>>;
    @Input() requiredUpdates: EmployeeRequiredDataUpdates;
    @Input() entityRequests: Array<EntityUpdateRequest>;

    @Output() refetchContactPersons = new EventEmitter<string>();
    @Output() refetchRequiredUpdates = new EventEmitter<string>();
    @Output() refetchActiveRequests = new EventEmitter<string>();

    public constructor(
        public widgetTemplatesService: WidgetTemplatesService,
        private _modalService: NgbModal,
        private _employeeService: EmployeeService,
    ) { }

    public openEditModal(employeeContactPerson: EmployeeContactPerson): void {
        const modalRef = this._modalService.open(CreateEditEmployeeContactPersonComponent, {centered: true});

        let updateRequested = false;

        if (employeeContactPerson) {
            modalRef.componentInstance.employeeContactPerson = employeeContactPerson;

            if (this.entityRequests) {
                for (const request of this.entityRequests) {
                    if (request.entity_type === 'App\\Models\\EmployeeContactPerson' && request.entity_ID === employeeContactPerson.employee_contact_person_ID) {
                        updateRequested = true;
                        break;
                    }
                }
            }
        }

        modalRef.componentInstance.employeeID = this.employee.employee_ID;
        modalRef.componentInstance.employee = this.employee;
        modalRef.componentInstance.updateRequested = updateRequested;

        modalRef.result
            .then(
                () => this.setUpdateData(),
                () => { }
            );
    }

    public openEntityUpdateRequestModal(entityUpdateRequest?: EntityUpdateRequest): void {
        const modalRef = this._modalService.open(EntityUpdateRequestModalComponent, {centered: true});
        if (entityUpdateRequest) {
            modalRef.componentInstance.entityUpdateRequest = entityUpdateRequest;
        }
    }

    public setUpdateData(): void {
        if (this.requiredUpdates && this.requiredUpdates.contact_person === 'REQUESTED') {
            const data: { contact_person: string } = {contact_person: 'UPDATED'};
            this._employeeService.forceEmployeeToUpdateData(data, this.employee.employee_ID)
                .then(() => {
                    this.refetchContactPersons.emit('refetch');
                    this.refetchRequiredUpdates.emit('refetch');
                    this.refetchActiveRequests.emit('refetch');
                });
        } else {
            this.refetchContactPersons.emit('refetch');
            this.refetchActiveRequests.emit('refetch');
        }
    }

    public refuseUpdateData(): void {
        const data: { contact_person: string } = {contact_person: 'REFUSED'};
        this._employeeService.forceEmployeeToUpdateData(data, this.employee.employee_ID)
            .then(() => this.refetchRequiredUpdates.emit('refetch'));
    }
}
