<div class="modal-content">
	<div class="modal-header">
			<p class="modal-title font-16 mb-0 text-dark">{{ 'auth.extra_verification' | translate }}</p>
		<button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
			<span aria-hidden="true" class="font-24 text-muted"><i class="mdi mdi-close"></i></span>
		  </button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-0 shadow-none">
					<div class="card-body p-0">
						<div *ngIf="state === 'invalid'" class="row">
							<div class="col-12">
								<div class="alert alert-danger mb-3 w-100 shadow">
									<span> {{ 'auth.verification_failed_message' | translate }} </span>
									<span *ngIf="methods.includes('verification_password')"> {{ 'auth.verification_failed_message_bad_password' | translate }} </span>
									<span *ngIf="methods.includes('verification_code')"> {{ 'auth.verification_failed_message_bad_code' | translate }} </span>
								</div>
							</div>
						</div>
						<form
								(ngSubmit)="onSubmit()"
								[formGroup]="verificationForm"
								autocomplete="off"
						>
							<div *ngIf="methods.includes('verification_password')" class="row">
								<div class="col-12">
									<div class="form-group">
										<label class="control-label" for="verification_password">
											<span>{{ labels?.verification_password }}</span>
										</label>
										<input #inputField class="form-control" formControlName="verification_password" id="verification_password" name="verification_password" type="password">
									</div>
								</div>
							</div>
							<div *ngIf="methods.includes('verification_code')" class="row mb-2">
								<div class="col-12">
									<div class="form-group">
										<label class="control-label" for="verification_code">
											<span>{{ labels?.verification_code }}</span>
										</label>
										<div class="two-fa-wrapper">
											<div class="row boxes">
												<ng-container *ngFor="let i of [0, 1, 2, 3, 4, 5]; let last = last;">
														<div [ngClass]="{'selected': inputField.value.length === i}" class="box" id="box-{{i}}">
														</div>
												</ng-container>
											</div>

											<input #inputField
												   (ngModelChange)="checkCount($event)"
												   class="form-control two-factor-partitioned"
												   formControlName="verification_code"
												   id="verification_code"
												   maxlength="6"
												   name="verification_code"
												   onlyNumber
												   type="text">
										</div>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col d-flex justify-content-end">
									<button [disabled]="loading" class="btn btn-success">
										<div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
											<span class="sr-only">Loading...</span>
										</div>
										<span *ngIf="!loading"> {{ 'auth.extra_verification_submit_action' | translate }} </span>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
