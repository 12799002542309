import { SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, Subscription } from 'rxjs';
import { EmployeeSignature } from '../../../../employee/models/EmployeeSignature';
import { EmployeeService } from '../../../../employee/services/employee.service';

@Component({
    selector: 'app-signature-pad-modal',
    templateUrl: './signature-pad-modal.component.html',
    styleUrls: ['./signature-pad-modal.component.css']
})
export class SignaturePadModalComponent implements OnInit, OnDestroy {
    @ViewChild(SignaturePadComponent, {static: true})
    public signaturePad: SignaturePadComponent;

    public employeeID: number;
    public isCollapsed = true;
    public mode: 'SIGN' | 'SETTING';
    public processFileQueue$: Observable<void>;
    public saveSignature = false;
    public showSaveSignatureControl = true;
    public signature: string;
    public signLocation: string;
    public signaturePadOptions: any = {
        minWidth: 1,
        canvasWidth: 450,
        canvasHeight: 180,
        backgroundColor: 'transparent',
        penColor: '#1f4ebf'
    };

    private _processFileQueue$ = new Subject<void>();
    private _subscription: Subscription;

    public constructor(
        public activeModal: NgbActiveModal,
        private _employeeService: EmployeeService
    ) {
        this.processFileQueue$ = this._processFileQueue$;
    }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this._subscription = this._employeeService.getSavedSignatures(this.employeeID).subscribe((response: EmployeeSignature[]) => {
            this.signature = response[0]?.signature;
            this.signLocation = response[0]?.location;
            if (this.signature) {
                this.signaturePad.fromDataURL(this.signature);
            }
        });
    }

    public clearPad(): void {
        this.signaturePad.clear();
    }

    public closeModal(useSavedSignature: boolean): void {
        this.activeModal.close({
            signature: this.signature,
            save_signature: this.saveSignature,
            use_saved_signature: useSavedSignature,
            location: this.signLocation
        });
    }

    public drawComplete(): void {
        this.signature = this.signaturePad.toDataURL();
    }

    public onAddedFile(file: any): void {
        this.signaturePad.clear();

        this.signature = file[0].dataURL;
    }

    public onRemoveFile(): void {
        this.signature = null;
    }
}
