import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { EmployeeOnboardingState, EmployeeOnboardingStore } from './employee-onboarding.store';

@Injectable({ providedIn: 'root' })
export class EmployeeOnboardingQuery extends QueryEntity<EmployeeOnboardingState> {
    constructor(protected store: EmployeeOnboardingStore) {
        super(store);
    }
}
