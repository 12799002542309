import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { UploadComponent } from './components/upload/upload.component';

@NgModule({
    imports: [
        CommonModule,
        DropzoneModule,
        NgbModule,
        TranslateModule,
        NgOptimizedImage,
    ],
    declarations: [
        UploadComponent,
    ],
    exports: [
        UploadComponent,
    ]
})
export class UIFileUploadModule {}
