import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';

export function HttpLoaderFactory(httpClient: HttpClient) {
    // when in production, load translations from json file in assets, else from backend
    if (environment.production) {
        return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
    }
    return new TranslateHttpLoader(httpClient, '/api/translations/', '');
}
