import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Employee } from 'src/app/employee/models/Employee';
import { KPI } from 'src/app/kpi/kpi/state/kpi.model';
import { KPIService } from 'src/app/kpi/kpi/state/kpi.service';

@Component({
    selector: 'app-create-edit-kpimetric-modification-modal',
    templateUrl: './create-edit-kpimetric-modification-modal.component.html',
    styleUrls: ['./create-edit-kpimetric-modification-modal.component.css']
})
export class CreateEditKPIMetricModificationModalComponent implements OnInit {

    public kpi: KPI;
    public employee: Employee;

    constructor(
        public activeModal: NgbActiveModal,
        private _kpiService: KPIService
    ) { }

    public ngOnInit(): void {

    }

    public onSubmit(form: UntypedFormGroup): void {
        if (!form.valid) {
            return;
        }
        this._kpiService.saveKPIMetricModifications(form.value, this.kpi.kpi_ID, this.employee.employee_ID)
            .then(() => this.activeModal.close());
    }

}
