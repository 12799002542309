import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CompanyGroup } from '../../../../company-group/state/company-group.model';
import { CompanyGroupQuery } from '../../../../company-group/state/company-group.query';
import { CompanyGroupService } from '../../../../company-group/state/company-group.service';
import { Employee } from '../../../../employee/models/Employee';
import { EmployeeService } from '../../../../employee/services/employee.service';
import { InternalDocument } from '../../../../internal-document/state/internal-document.model';
import { CompanyBranch } from '../../../../setting/models/CompanyBranch';
import { CompanyPosition } from '../../../../setting/models/CompanyPosition';
import { DateTimeProject } from '../../../../setting/models/DatetimeProject';
import { Role } from '../../../../setting/models/Role';
import { BranchStoreService } from '../../../../setting/services/branch-store.service';
import { CompanyPositionService } from '../../../../setting/services/company-position.service';
import { RolesService } from '../../../../setting/services/roles.service';

@Component({
    selector: 'app-targetable',
    templateUrl: './targetable.component.html',
    styleUrls: ['./targetable.component.css']
})
export class TargetableComponent implements OnInit {
    @Input() public formGroup: UntypedFormGroup;
    @Input() public entity: InternalDocument | DateTimeProject;

    public employees$: Observable<Employee[]>;
    public companyGroups$: Observable<CompanyGroup[]>;
    public companyBranches$: Observable<CompanyBranch[]>;
    public roles$: Observable<Role[]>;
    public companyPositions$: Observable<CompanyPosition[]>;

    constructor(
        private _fb: UntypedFormBuilder,
        private _employeeService: EmployeeService,
        private _companyGroupQuery: CompanyGroupQuery,
        private _branchStoreService: BranchStoreService,
        private _companyPositionService: CompanyPositionService,
        private _companyGroupService: CompanyGroupService,
        private _roleService: RolesService
    ) {}

    public ngOnInit(): void {
        this._companyGroupService.get().subscribe();

        this.employees$ = this._employeeService.getAllEmployees(false, 'select');
        this.companyBranches$ = this._branchStoreService.branches$;
        this.companyGroups$ = this._companyGroupQuery.selectAll();
        this.roles$ = this._roleService.getAllRoles();
        this.companyPositions$ = this._companyPositionService.getAllCompanyPositions();

        this.formGroup.addControl('target', this._fb.group({
            whole_company: [true],
            employee_IDs: [[]],
            company_group_IDs: [[]],
            company_branch_IDs: [[]],
            job_branch_IDs: [[]],
            company_position_IDs: [[]],
            role_IDs: [[]],
        }));

        if (this.entity) {
            this.formGroup.patchValue({target: this.entity.target});
        }
    }
}
