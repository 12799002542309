import { Component } from '@angular/core';

@Component({
  selector: 'app-screen-size',
  templateUrl: './screen-size.component.html',
  styleUrls: ['./screen-size.component.css']
})
export class ScreenSizeComponent {

}
