<div class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help name="employee.detail.scheduled_task.list"></app-help>

      <div class="card-header py-3">
        <div class="row">
          <div class="col-sm-6 text-left my-auto">
            <p class="modal-title font-16 pt-2 pb-2 text-dark">
              <span>{{ 'scheduled_task.page_title_index' | translate }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="card-body">
        <app-scheduled-task-list
          *ngIf="employeeID$ | async; let id"
          [dataOnly]="true"
          [entity]="{ id: id, type: 'Employee' }"
        ></app-scheduled-task-list>
      </div>
    </div>
  </div>
</div>
