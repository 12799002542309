  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>
            <ng-container *ngIf="('activity_log.subjects.' + selectedActivityLog.subject_type) !== (('activity_log.subjects.' + selectedActivityLog.subject_type) | translate); else noTranslationTemplate">
                                        {{ ('activity_log.subjects.' + selectedActivityLog.subject_type) | translate }}
            </ng-container>
            <ng-template #noTranslationTemplate>
                {{ selectedActivityLog.subject_type }}
            </ng-template>
            -
          {{ ('activity_log.events.' + (selectedActivityLog.event || selectedActivityLog.description)) | translate }}
        </span>
      </p>

      <button
        aria-label="Close"
        class="close"
        (click)="activeModal.dismiss()"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <form #f="ngForm">
                <div class="row mb-4">
                  <div class="col-12">
                    <label class="control-label">
                        {{ 'activity_log.object' | translate }}
                    </label><br/>
                    {{ selectedActivityLog.label }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group text-left">
                      <div class="row border-bottom mb-1">
                        <div class="col-4"></div>
                        <div class="col-4 text-dark"><label class="control-label">{{ 'activity_log.old_value' | translate }}</label></div>
                        <div class="col-4 text-dark"><label class="control-label">{{ 'activity_log.new_value' | translate}}</label></div>
                      </div>
                      <div *ngFor="let newItem of selectedActivityLog.new | keyvalue" class="row border-bottom mb-1">
                        <div class="col-4 text-dark">
                          <label *ngIf="newItem.key" class="control-label">{{ newItem.key }}</label>
                        </div>
                          <div class="col-4"><span *ngIf="selectedActivityLog.old"> {{ selectedActivityLog.old[newItem.key] }} </span></div>
                        <div class="col-4">{{ newItem.value }}</div>
                      </div>
                    </div>
                  </div>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
