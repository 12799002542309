<ng-template
  #content
  let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'roles.action_create' | translate }}</span>
      </p>

      <button
        aria-label="Close"
        class="close"
        (click)="modal.dismiss()"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <form
                [formGroup]="roleCreateForm"
                (ngSubmit)="onSubmit()"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <div class="form-group">
                        <label class="control-label">
                          <span>{{ 'tasks.name' | translate }}</span>
                        </label>

                        <input
                          class="form-control"
                          formControlName="name"
                          name="name"
                          type="text"
                        >

                        <div *ngIf="submitted && f.name.errors">
                          <p
                            *ngIf="f.name.errors.required"
                            class="text-danger validation-text"
                          >
                            <span>{{ 'angular_validation.field' | translate }} </span>
                            <span>{{ 'tasks.name' | translate }} </span>
                            <span>{{ 'angular_validation.required' | translate }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-lg-12 text-left">
                    <button class="btn btn-success">
                      <span>{{ 'roles.action_create' | translate }}</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help name="settings.users.roles.list"></app-help>

      <div class="card-header py-3">
        <div class="row">
          <div class="col-sm-6 text-left my-auto">
            <p class="modal-title font-16 text-dark pt-2 pb-2">
              <span>{{ 'roles.page_title_index' | translate }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <app-data-table
                [disableInPlaceFiltering]="true"
                [disableInPlaceSorting]="true"
                [loading]="loading"
                [rows]="rows"
                [search]="search"
                [sort]="sort"
                [trackByFn]="trackByFn"
                (searchChanged)="searchChanged($event)"
                (sortChanged)="sortChanged($event)"
              >
                <ng-template
                  #headerButton
                  *ngIf="canCreate$ | async"
                >
                  <button
                    class="ml-sm-2"
                    [createButton]="'roles.action_create' | translate"
                    (click)="openEditModal()"
                  ></button>
                </ng-template>

                <ng-template #headerRow>
                  <th appDataTableHeaderCell="name">{{ 'roles.name' | translate }}</th>
                  <th
                    appDataTableHeaderCell
                    class="text-right"
                    [disableSort]="true"
                  >{{ 'roles.table_action' | translate }}</th>
                </ng-template>

                <ng-template
                  #dataRow
                  let-row
                >
                  <td appDataTableDataCell>{{ row.name }}</td>

                  <td
                    appDataTableDataCell
                    class="text-right"
                  >
                    <a
                      class="btn btn-white btn-icon btn-sm mr-1"
                      title="{{ 'roles.action_edit' | translate }}"
                      [routerLink]="['/setting', 'roles', 'edit', row.role_ID]"
                    >
                      <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                    </a>
                  </td>
                </ng-template>
              </app-data-table>
            </div>
          </div>
        </div>

        <div class="row mt-3 flex-column-reverse flex-sm-row">
          <div class="col-12 col-sm-2">
            <app-items-per-page-select
              [itemsPerPage]="itemsPerPage"
              [total]="itemsTotal"
              (itemsPerPageChanged)="itemsPerPageChanged($event)"
            ></app-items-per-page-select>
          </div>

          <div class="col-12 col-sm-10">
            <app-pagination
              [itemsPerPage]="itemsPerPage"
              [page]="page"
              [total]="itemsTotal"
              (pageChanged)="pageChanged($event)"
            ></app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
