<div class="modal-content">
	<div class="modal-header">
			<p *ngIf="salary" class="modal-title font-16 mb-0 text-dark">{{'employees_salaries.page_title_edit' | translate}}</p>
			<p *ngIf="!salary" class="modal-title font-16 mb-0 text-dark">{{'employees_salaries.page_title_create' | translate}}</p>
		<button (click)="activeModal.dismiss()" type="button" class="close" aria-label="Close">
			<span class="font-24 text-muted" aria-hidden="true"><i class="mdi mdi-close"></i></span>
		  </button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-0 shadow-none">
					<div class="card-body p-0">
                        <app-salary-form #childRef
                                         [employee]="employee"
                                         [salary]="salary"
                                         [submitted]="submitted">
                        </app-salary-form>

                        <div class="row mt-4">
                            <div class="col-6 my-auto text-right">
                                <u *ngIf="employee?.permissions.deleteSalary && salary && !hiddenInputs" (click)="deleteSalary()" class="text-muted">{{'employees_salaries.action_delete' | translate}}</u>
                            </div>
                            <div class="col-6 text-right">
                                <button *ngIf="employee?.permissions.editSalary" [disabled]="hiddenInputs" (click)="onSubmit()" class="btn btn-success">{{ 'employees_salaries.action_save' | translate }}</button>
                            </div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

