<div class="modal-content">
	<div class="modal-header">
			<p *ngIf="kpiMetric" class="modal-title font-16 mb-0 text-dark">{{'kpis_metrics.page_title_edit' | translate}}</p>
			<p *ngIf="!kpiMetric" class="modal-title font-16 mb-0 text-dark">{{'kpis_metrics.page_title_create' | translate}}</p>
		<button (click)="activeModal.dismiss()" type="button" class="close" aria-label="Close">
			<span class="font-24 text-muted" aria-hidden="true"><i class="mdi mdi-close"></i></span>
		  </button>
	</div>
	<div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <form [formGroup]="kpiMetricEditForm" (ngSubmit)="onSubmit()" autocomplete="off">
                            <div class="row">
								<div class="col-lg-12">
									<div class="form-group">
                                        <label class="control-label">{{'kpis_metrics.name' | translate}}</label>
										<input formControlName="name" class="form-control" type="text" name="name">
										<div *ngIf="submitted && f.name.errors">
											<p *ngIf="f.name.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'kpis.name' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'kpis_metrics.description' | translate }}</label>
                                        <textarea class="form-control" formControlName="description" name="description" type="text"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'kpis_metrics.weight' | translate }}</label>
                                        <input class="form-control" name="weight" type="text" currencyMask [options]="{'suffix': ' %', 'precision': 0, 'min': 0}" formControlName="weight">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <label class="control-label mb-0">{{ 'kpis_metrics.advanced_settings' | translate }}</label>
                                </div>
                                <div class="col-6 text-right align-self-center">
                                    <div>
                                        <input formControlName="advanced" type="checkbox" id="advanced" name="advanced" data-switch="success">
                                        <label for="advanced">&nbsp;</label>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="f.advanced.value">

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{'kpis_metrics.type' | translate}}</label>
                                        <ng-select
                                            formControlName="type"
                                            [clearable]="false"
                                            class="custom"
                                            name="type"
                                        >
                                        <ng-option value="rating">{{ 'kpis_metrics.type_rating' | translate }}</ng-option>
                                        <ng-option value="number">{{ 'kpis_metrics.type_number' | translate }}</ng-option>
                                        <ng-option value="binominal">{{ 'kpis_metrics.type_binominal' | translate }}</ng-option>
                                        <ng-option value="text">{{ 'kpis_metrics.type_text' | translate }}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="f.type.value === 'rating' || f.type.value === 'number'" class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'kpis_metrics.from' | translate }}</label>
                                        <input formControlName="min" name="min" type="number" class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ 'kpis_metrics.to' | translate }}</label>
                                        <input formControlName="max" name="max" type="number" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="f.type.value === 'rating' || f.type.value === 'number'">
                                <div  class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label class="control-label">{{ 'kpis_metrics.threshold_count' | translate }}</label>
                                            <ng-select
                                                formControlName="threshold_count"
                                                name="threshold_count"
                                                class="custom"
                                                [clearable]="false"

                                            >
                                                <ng-option value="NONE">{{ 'kpis_metrics.none' | translate }}</ng-option>
                                                <ng-option value="1">1</ng-option>
                                                <ng-option value="2">2</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="f.threshold_count.value !== 'NONE'" class="row">
                                    <div *ngIf="f.threshold_count.value !== 'NONE'" class="col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label">{{ 'kpis_metrics.threshold_1' | translate }}</label>
                                                <input name="threshold_1" formControlName="threshold_1" currencyMask [options]="{'suffix': ' %', 'min': 0}" type="text" class="form-control">

                                        </div>
                                    </div>
                                    <div *ngIf="f.threshold_count.value === '2'" class="col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label">{{ 'kpis_metrics.threshold_2' | translate }}</label>
                                                <input name="threshold_2" formControlName="threshold_2" currencyMask [options]="{'suffix': ' %', 'min': 0}" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                </ng-container>
                            </ng-container>

                            <div class="row mt-2">
                                <div class="col-6 text-right my-auto">
                                    <u *ngIf="kpiMetric" (click)="deleteKPIMetric()" class="text-muted">{{'kpis.action_delete' | translate}}</u>
                                </div>
                                <div class="col-6 text-right">
                                    <button class="btn btn-success" type="submit">{{'kpis.action_save' | translate}}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
	</div>
</div>
