<div class="card shadow">
    <app-help name="employee.detail.salary"></app-help>

    <div class="card-header py-3">
        <div class="row">
            <div class="col-6 col-sm-6 col-md-7">
                <p class="modal-title font-16 text-dark pt-2 pb-2">
                    <span>{{ 'employees.salaries' | translate }}</span>
                </p>
            </div>

            <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
                <button
                    *ngIf="employee?.permissions.createSalary"
                    [createButton]="'employees_salaries.action_create' | translate"
                    (click)="openSalaryCreateModal(null)"
                ></button>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-centered mb-0">
                <tbody>
                    <ng-container *ngIf="salaries$ | async; let salaries; else loadingTemplate">
                        <ng-container *ngIf="salaries?.length; else noSalariesTemplate">
                            <tr *ngFor="let s of salaries">
                                <td>
                                    <span [ngClass]="{'hidden-sensitive-value': hiddenInputs}">{{ s.amount | localizedCurrency }} </span>
                                </td>

                                <td>{{ 'employees_salaries.frequency_adj_' + s.frequency | translate }}</td>

                                <td>{{ s.from | date:'dd.MM.yyyy' }}</td>

                                <td>{{ s.to | date:'dd.MM.yyyy' }}</td>

                                <td class="text-right">
                                    <a
                                        class="btn btn-link btn-icon p-0"
                                        title="{{ 'global.action_edit' | translate }}"
                                        (click)="openSalaryCreateModal(s)"
                                    >
                                        <i class="mdi mdi-eye-outline text-muted mdi-24px"></i>
                                    </a>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>

                    <ng-template #loadingTemplate>
                        <app-row-loader></app-row-loader>
                    </ng-template>

                    <ng-template #noSalariesTemplate>
                        <tr class="text-center">
                            <td colspan="3">{{ 'global.empty_widget_error' | translate }}</td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </div>
    </div>
</div>
