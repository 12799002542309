<div *ngIf="helpModeActive$ | async">
    <div *ngIf="help$ | async; let help; else noHelpFound">
        <div class="card-help">
            <ng-template
                #content
                let-modal
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <p class="modal-title font-16 mb-0 text-dark">
                            <span>{{ 'global.app_help_mode' | translate }}</span>
                        </p>

                        <button
                            (click)="modal.dismiss()"
                            aria-label="Close"
                            class="close"
                            type="button"
                        >
              <span
                  aria-hidden="true"
                  class="font-24 text-muted"
              >
                <i class="mdi mdi-close"></i>
              </span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card mb-0 shadow-none">
                                    <div class="card-body p-0">
                                        <h2>{{ help.name }}</h2>

                                        <div [innerHTML]="help.description | safeHtml"></div>

                                        <!-- <div
                                          *ngIf="help.url && help.url != '#'"
                                          class="text-center mt-2"
                                        >
                                          <a
                                            class="btn btn-primary"
                                            href="{{ help.url }}"
                                            target="_blank"
                                          >{{ 'help.more_info' | translate }}</a>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>

            <button
                (click)="openModal(content)"
                class="question-button btn btn-link btn-icon waves-effect pt-1 pb-0 pl-0 pr-0"
                container="body"
                ngbTooltip="{{ 'help.click_for_more_info' | translate }}"
                type="button"
            >
                <i class="mdi mdi-help-circle mdi-24px"></i>
            </button>
        </div>
    </div>

    <!-- TEMPORARY START -->
    <ng-template #noHelpFound>
        <ng-container *ngIf="canShowHelpKey">
            <span>{{ 'help.help_key_is' | translate }}</span>
            <span>„</span>
            <strong>{{ name }}</strong>
            <span>“</span>
        </ng-container>
    </ng-template>
    <!-- TEMPORARY END -->
</div>
