import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SharedModule } from '../shared/shared.module';
import { UIButtonModule, UIDataTableModule, UIFileUploadModule, UIItemsPerPageSelectModule, UIPaginationModule } from '../ui';
import { SignatureEsignModalComponent } from './components/partials/signature-esign-modal/signature-esign-modal.component';
import { SignaturePadModalComponent } from './components/partials/signature-pad-modal/signature-pad-modal.component';
import { DocumentDetailComponent } from './components/sections/document-detail/document-detail.component';
import { DocumentListComponent } from './components/sections/document-list/document-list.component';
import { DocumentTemplatesListComponent } from './components/sections/document-templates-list/document-templates-list.component';
import { HtmlDetailComponent } from './components/sections/html-detail/html-detail.component';
import { DocumentRoutingModule } from './document-routing.module';

@NgModule({
    declarations: [
        HtmlDetailComponent,
        DocumentDetailComponent,
        SignatureEsignModalComponent,
        SignaturePadModalComponent,
        DocumentListComponent,
        DocumentTemplatesListComponent
    ],
    imports: [
        SharedModule,
        CommonModule,
        PdfViewerModule,
        UIFileUploadModule,
        DocumentRoutingModule,
        AngularSignaturePadModule,
        UIDataTableModule,
        UIButtonModule,
        UIItemsPerPageSelectModule,
        UIPaginationModule
    ],
    exports: [
        DocumentListComponent
    ]
})
export class DocumentModule {}
