import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { PolicyService } from 'src/app/policy/services/policy.service';

@Component({
    selector: 'app-create-edit-employee-policy-modal',
    templateUrl: './create-edit-employee-policy-modal.component.html',
    styleUrls: ['./create-edit-employee-policy-modal.component.css']
})
export class CreateEditEmployeePolicyModalComponent implements OnInit {
    public companyEmployeePolicyID: number;

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.policyEditForm.controls;
    }

    public timeframes$: Observable<any>;
    public policyEditForm: UntypedFormGroup;

    public submitted = false;

    public constructor(
        public activeModal: NgbActiveModal,
        private _fb: UntypedFormBuilder,
        private _policyService: PolicyService
    ) { }

    public ngOnInit(): void {
        this.policyEditForm = this._fb.group({
            name: ['', Validators.required],
            holiday_hours: [20, Validators.required],
            holiday_transfer_hours: [5, Validators.required],
            sick_day_hours: [0],
            sick_day_hours_timeframe: ['YEAR'],
            home_office_hours: [0],
            home_office_hours_timeframe: ['MONTH'],
            legal_pause: [0, Validators.required],
            legal_pause_treshold: [0, Validators.required],
            // weekend_work: [false, Validators.required],
            // holiday_work: [false, Validators.required]
        });

        if (this.companyEmployeePolicyID) {
            this._policyService.getPolicyByID(this.companyEmployeePolicyID)
                .subscribe(policy => {
                    this.policyEditForm.patchValue({
                        name: policy.name,
                        holiday_hours: policy.holiday_hours,
                        holiday_transfer_hours: policy.holiday_transfer_hours,
                        sick_day_hours: policy.sick_day_hours,
                        sick_day_hours_timeframe: policy.sick_day_hours_timeframe,
                        home_office_hours: policy.home_office_hours,
                        home_office_hours_timeframe: policy.home_office_hours_timeframe,
                        legal_pause: policy.legal_pause,
                        legal_pause_treshold: policy.legal_pause_treshold,
                        // weekend_work: policy.weekend_work,
                        // holiday_work: policy.holiday_work
                    });
                });
        }

        this.timeframes$ = this._policyService.getTimeframes().pipe(
            shareReplay()
        );
    }

    public deletePolicy(): void {
        this._policyService.deletePolicy(this.companyEmployeePolicyID)
            .then(
                () => this.activeModal.close('delete'),
                () => { }
            );
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.policyEditForm.valid) {
            return;
        }

        let promise = null;

        if (this.companyEmployeePolicyID) {
            promise = this._policyService.savePolicy(this.policyEditForm.value, this.companyEmployeePolicyID);
        } else {
            promise = this._policyService.savePolicy(this.policyEditForm.value);
        }

        promise
            .then(() => this.activeModal.close());
    }
}
