<ng-container *ngIf="attendanceStats$ | async as info; else loadingTemplate">
  <div class="row">
    <div class="col-12">
      <div
        *ngIf="info.line?.series"
        class="row"
      >
        <div class="col-lg-12">
          <button
            class="export-btn btn btn-light btn-icon font-18 mr-2"
            type="button"
            (click)="exportAttendance()"
          >
            <i class="mdi mdi-export-variant mdi-24px"></i>
          </button>

          <app-line-chart
            [labels]="info.line.labels"
            [series]="info.line.series"
          ></app-line-chart>

          <!-- <app-stacked-bar-chart
          yaxisFormat="RAW"
          [labels]="info.line.labels"
          [series]="info.line.series"
          [strokeForLastOnly]="true"
          [withLine]="true"
        ></app-stacked-bar-chart> -->
        </div>
      </div>

      <div
        *ngIf="!info.line?.series"
        class="row"
      >
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h5 class="text-muted font-weight-normal pt-5 pb-5 text-center mt-0">
                <span>{{ 'global.not_enough_data' | translate }}</span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="info.radial"
    class="row"
  >
    <div class="col-12">
      <div class="card-header">
        <span>{{ 'employees_analysis.chart_attendance_hours_worked_in_selected_range' | translate }}</span>
      </div>

      <div class="row">
        <div class="col-md-4">
          <app-radial-chart
            [absoluteValue]="info.radial.worked_hours_in_selected_range"
            [labels]="[ ('employees_analysis.chart_attendance_hours_worked' | translate) ]"
            [labelFormat]="'ABSOLUTE_VALUE_MAX_VALUE'"
            [maxValue]="info.radial.ideal_working_hours_in_selected_range"
            [series]="[info.radial.worked_hours_in_percent]"
            [title]="null"
          ></app-radial-chart>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingTemplate>
          <div class="item p-3">
            <ngx-skeleton-loader count="7" appearance="line" [theme]="{
      'margin': '15px 0'
    }"></ngx-skeleton-loader>
          </div>
</ng-template>
