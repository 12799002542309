<div class="modal-content">
	<div class="modal-header">
			<p class="modal-title font-16 mb-0 text-dark">{{'employees.action_change_avatar' | translate}}</p>
		<button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
			<span aria-hidden="true" class="font-24 text-muted"><i class="mdi mdi-close"></i></span>
		  </button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-0 shadow-none">
					<div class="card-body p-0">
						<div class="row">
                            <div class="col-12 avatars-wrap">
                                <div *ngFor="let a of avatars" class="avatar-box p-1">
                                    <span (click)="saveAvatar(a)" [ngClass]="{'active' : this.selectedAvatarUrl.includes(a.name)}" class="avatar-select">
                                        <img [alt]="a.label" [ngSrc]="a.url" class="img-fluid avatar-lg rounded-circle" height="10" width="10">
                                    </span>
                                </div>
								<app-upload
									(success)="activeModal.close()"
									[allowTakingPicture]="false"
									[allowedExtensions]="'.jpg, .jpeg, .png'"
									[fileName]="employee.fullname + '_avatar_custom.png'"
									[modelName]="'employee_ID'"
									[modelValue]="employee.employee_ID"
									[model]="employee"
									[ngClass]="{'active': isCustomAvatar}"
									[secondaryModelName]="'file_type'"
									[secondaryModelValue]="'avatar'"
									[showPreviewContainer]="false"
									[style]="'avatar'"
									class="avatar-box p-1"
								>
								</app-upload>
                            </div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
