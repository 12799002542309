import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { GanttData } from 'src/app/shared/models/GanttData';
import { FileService } from 'src/app/shared/services/file.service';
import { EmployeeDatetimeType } from '../../setting/models/EmployeeDatetimeType';
import { EmployeeAttendanceOverview } from '../components/sections/employee-attendance-document-employee-detail/employee-attendance-document-employee-detail.component';
import { StatsForDatetimeExport } from '../interface/stats-for-datetime-export.interface';
import { Employee } from '../models/Employee';
import { EmployeeDatesForDateTimeExport } from '../models/EmployeeDatesForDateTimesExport';
import { EmployeeDateTime } from '../models/EmployeeDateTime';
import { EmployeeDateTimeStatus } from '../models/EmployeeDateTimeStatus';
import { File } from '../models/File';

interface DatetimeFilter {
    from: string;
    to: string;
    types: string[];
    states: string[];
    employeesIDs: number[];
    planned: boolean;
}

interface DatetimesExportEmployeeFilter {
    from: string;
    to: string;
    companyGroups: number[];
    employeePositionTypes: string[];
    positionCompanyBranches: number[];
    mainCompanyBranches: number[];
    tags: number[];
    hardSkillsTags: number[];
    softSkillsTags: number[];
}

@Injectable({
    providedIn: 'root'
})
export class AttendanceService {
    public refreshTimestamps$ = new Subject<void>();

    public constructor(
        private _apiHelper: ApiHelper,
        private _fileService: FileService,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _swalHelper: SwalHelper
    ) { }

    public approveOrDeclineEmployeeDateTime(employeeDatetimeId: number, action: 'approve' | 'deny'): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post('/api/datetimes/' + employeeDatetimeId + '/' + action, {})
                .subscribe(
                    (response: any) => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._loaderService.stop();

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public deleteEmployeeDateTimeByID(employeeId: number, employeeDatetimeId: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog('swal.cancel_datetime_request');

            if (deleteConfirmed) {
                this._http.delete('/api/employees/' + employeeId + '/datetimes/' + employeeDatetimeId)
                    .subscribe(
                        (response: any) => {
                            this._loaderService.stop();

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    async destroySummaryBatch(month: number, year: number): Promise<'done'> {
        let params = null;

        if (
            month &&
            year
        ) {
            params = new HttpParams()
                .set('month', month.toString())
                .set('year', year.toString());
        }

        const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog('employees_datetimes.try_again_text', 'employees_datetimes.try_again_title');

        if (deleteConfirmed) {
            return new Promise(resolve => {
                this._http.delete('/api/datetimes/summaries', {params})
                    .subscribe(
                        (response: any) => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');

                            this._loaderService.stop();
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);

                            this._loaderService.stop();
                        }
                    );
            });
        }
    }

    public downloadAllDatetimeExports(month: number, year: number, employeeIDs: number[], type: string = 'default'): void {
        let params = new HttpParams();
        params = params.append('month', month);
        params = params.append('year', year);
        params = params.append('employeeIDs', employeeIDs.join(','));
        params = params.append('type', type);

        this._http.get<File>(`/api/datetimes/export`, {params})
            .subscribe(file => this._fileService.checkMimeTypeAndDownloadWithAnchor(file));
    }

    public downloadDatetimeExportByEmpoyeeID(employeeId: number, month: number, year: number): void {
        let params = null;

        if (month && year) {
            params = new HttpParams()
                .set('month', month.toString())
                .set('year', year.toString());
        }

        this._http.get<File>(`/api/employees/${employeeId}/datetimes/export`, {params})
            .subscribe(file => this._fileService.checkMimeTypeAndDownloadWithAnchor(file));
    }

    public getAllDateTimes(start?: string, end?: string, limit?: number, toApprove?: number): Observable<Array<EmployeeDateTime>> {
        let params = null;

        if (
            start &&
            end
        ) {
            if (toApprove) {
                start = '';
                end = '';
            }

            params = new HttpParams()
                .set('start', start)
                .set('end', end)
                .set('to-approve', toApprove.toString())
                .set('limit', limit ? limit.toString() : '');
        }

        return this._http.get<Array<EmployeeDateTime>>('/api/datetimes', {params});
    }

    public getAllDateTimesForFullCalendar(fetchInfo, style: string) {
        let params = null;

        if (style) {
            params = new HttpParams()
                .set('style', style);
        }

        return this._http.post('/api/datetimes', fetchInfo, {params});
    }

    public getDateTimeRequestsList(params: ListParameters<EmployeeDateTime>, toApprove = false, filter?: DatetimeFilter): Observable<ListResponse<EmployeeDateTime>> {
        const p = transformParameters(params);

        p['to-approve'] = Number(toApprove);

        return this._http.post<ListResponse<EmployeeDateTime>>('/api/datetimes-requests/list', {...p, filter});
    }

    public getDateTimesListByEmployeeId(employeeId: number, params: ListParameters<EmployeeDateTime>): Observable<ListResponse<EmployeeDateTime>> {
        return this._http.post<ListResponse<EmployeeDateTime>>('/api/employees/' + employeeId + '/datetimes/list', {...transformParameters(params)});
    }

    public getDateTimesTypes(): Observable<any> {
        return this._http.get<any>('/api/datetimes-requests/types');
    }

    public getDateTimesStates(): Observable<any> {
        return this._http.get<any>('/api/datetimes-requests/states');
    }

    public getDatetimesSummaryStatus(month: number, year: number): Observable<{
        step1: boolean,
        step2: boolean,
        step3: boolean,
        step4: boolean,
        step5: boolean
    }> {
        let params = null;

        if (
            month &&
            year
        ) {
            params = new HttpParams()
                .set('month', month.toString())
                .set('year', year.toString());
        }

        return this._http.get<{
            step1: boolean,
            step2: boolean,
            step3: boolean,
            step4: boolean,
            step5: boolean
        }>(`/api/datetimes/summaries/status`, {params});
    }

    public getAllRequestsByEmployeeID(employeeId: number, limit?: number): Observable<Array<EmployeeDateTime>> {
        let params = null;

        if (limit) {
            params = new HttpParams()
                .set('limit', limit.toString());
        }

        return this._http.get<Array<EmployeeDateTime>>('/api/employees/' + employeeId + '/datetimes/requests', {params});
    }

    public getDateTimesForGanttChart(start: string, end: string, onlyShifts: boolean, companyGroupIDs?: any[], employeeIDs?: any[], categories?: boolean, groupByCompanyGroup?: boolean, expendAllCompanyGroups?: boolean, datetimeTypeIDs?: number[]) {
        let params = new HttpParams();

        if (start && end) {
            params = params
                .set('start', start)
                .set('end', end)
                .set('only_shifts', onlyShifts ? 1 : 0);
        }

        if (companyGroupIDs.length > 0) {
            params = params.set('company_group_IDs', companyGroupIDs.join(','));
        }

        if (employeeIDs?.length > 0) {
            params = params.set('employee_IDs', employeeIDs.join(','));
        }

        if (categories) {
            params = params.set('categories', categories);
        }

        if (groupByCompanyGroup === true) {
            params = params.set('group_by_company_group', 1);
        }

        if (groupByCompanyGroup === false) {
            params = params.set('group_by_company_group', 0);
        }

        if (expendAllCompanyGroups === true) {
            params = params.set('expend_all_company_groups', 1);
        }

        if (expendAllCompanyGroups === false) {
            params = params.set('expend_all_company_groups', 0);
        }

        if (datetimeTypeIDs?.length > 0) {
            params = params.set('datetime_type_IDs', datetimeTypeIDs.join(','));
        }

        return this._http.get<any>('/api/datetimes/gantt', {params})
            .pipe(shareReplay());
    }

    public getDateTimesForGanttChartByEmployeeID(employeeId: number, start: string, end: string): Observable<GanttData> {
        let params = null;

        if (
            start &&
            end
        ) {
            params = new HttpParams()
                .set('start', start)
                .set('end', end)
                .set('group-by', 'company_group');
        }

        return this._http.get<GanttData>('/api/employees/' + employeeId + '/datetimes/gantt', {params})
            .pipe(shareReplay());
    }

    public getDatesForDateTimesExport(): Observable<Array<EmployeeDatesForDateTimeExport>> {
        return this._http.get<Array<EmployeeDatesForDateTimeExport>>('/api/datetimes/export/dates');
    }

    public getEmployeeDateTimeByID(employeeId: number, employeeDatetimeId: number, style?: string): Observable<EmployeeDateTime> {
        let params = null;

        if (style) {
            params = new HttpParams()
                .set('style', style);
        }

        return this._http.get<EmployeeDateTime>('/api/employees/' + employeeId + '/datetimes/' + employeeDatetimeId, {params});
    }

    public getEmployeeDateTimeInfoBeforeSave(employeeId: number, datetime: EmployeeDateTime): Observable<any> {
        return this._http.post('/api/employees/' + employeeId + '/datetimes/info', datetime);
    }

    public getEmployeeDateTimeStatusByID(employeeId: number): Observable<EmployeeDateTimeStatus> {
        return this._http.get<EmployeeDateTimeStatus>('/api/employees/' + employeeId + '/datetimes/status');
    }

    public getEmployeesForDatetimeExport(month: number, year: number, filterForm?: DatetimesExportEmployeeFilter): Observable<Array<Employee>> {
        const filter = {
            month,
            year,
            filter: filterForm
        };

        return this._http.post<Array<Employee>>('/api/datetimes/export/employees', filter);
    }

    public getNormalizedDatetimesDataForCheck(month: number, year: number, filterForm = null): Observable<any> {
        const filter = {
            month,
            year,
            filter: filterForm ?? []
        };

        return this._http.post(`/api/datetimes/summaries/get`, filter);
    }

    public getStatsForDatetimeExport(month: number, year: number): Observable<StatsForDatetimeExport> {
        let params = null;

        if (month && year) {
            params = new HttpParams()
                .set('month', month.toString())
                .set('year', year.toString());
        }

        return this._http.get<StatsForDatetimeExport>('/api/datetimes/summaries/total', {params});
    }

    public saveEmployeeDateTime(form: EmployeeDateTime, employeeId: number, employeeDatetimeId?: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            if (employeeDatetimeId) {
                this._http.post('/api/employees/' + employeeId + '/datetimes/' + employeeDatetimeId, form)
                    .subscribe(
                        (response: any) => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');

                            this._loaderService.stop();
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);

                            this._loaderService.stop();
                        }
                    );
            } else {
                this._http.post('/api/employees/' + employeeId + '/datetimes', form)
                    .subscribe(
                        (response: any) => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');

                            this._loaderService.stop();
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);

                            this._loaderService.stop();
                        }
                    );
            }
        });
    }

    public saveEmployeeDateTimeState(form: EmployeeDateTime, employeeDatetimeId: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post('/api/datetimes/' + employeeDatetimeId + '/set-state', form)
                .subscribe(
                    (response: any) => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public saveEmployeeDateTimesState(action: 'approved' | 'denied', employeeDatetimeIds: number[], denyReason?: string): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post('/api/datetimes/multi/set-state', {state: action, employee_datetime_ids: employeeDatetimeIds, note: denyReason})
                .subscribe(
                    (response: any) => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public saveNormalizedDatetimesAfterCheck(employees: any, month: number, year: number): Promise<'done'> {
        let params = null;

        if (month && year) {
            params = new HttpParams()
                .set('month', month.toString())
                .set('year', year.toString());
        }

        return new Promise(resolve => {
            this._http.post('/api/datetimes/summaries', {employees}, {params})
                .subscribe(
                    (response: any) => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public saveNormalizedDatetimesAfterGrossSalaryCheck(employees: any, month: number, year: number): Promise<'done'> {
        let params = null;

        if (
            month &&
            year
        ) {
            params = new HttpParams()
                .set('month', month.toString())
                .set('year', year.toString());
        }

        return new Promise(resolve => {
            this._http.post('/api/datetimes/salaries', {employees}, {params})
                .subscribe(
                    (response: any) => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public setEmployeeDateTimeStatus(employeeId: number, type: string, report?: string) {
        return this._http.post('/api/employees/' + employeeId + '/datetimes/start', {type, report});
    }

    public getDatetimeTypesForEmployeeTimer(employeeId: number): Observable<Array<EmployeeDatetimeType>> {
        return this._http.get<Array<EmployeeDatetimeType>>('/api/employees/' + employeeId + '/datetimes/get-available-for-timer');
    }

    public getAbsenceList(params: ListParameters<EmployeeDateTime>, filter?: any): Observable<ListResponse<EmployeeDateTime>> {
        const p = transformParameters(params);
        return this._http.post<ListResponse<EmployeeDateTime>>('/api/employees/datetimes/absence-list', {...p, filter});
    }

    public createBulkAttendance(form: any): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post('/api/employees/datetimes', form)
                .subscribe(
                    (response: any) => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public createMultipleAttendance(form: any): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post('/api/mass-actions/create-datetimes', form)
                .subscribe(
                    (response: any) => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public deleteBulkAttendance(employeeDatetimeIDs: number[]): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post('/api/employees/datetimes/delete-bulk', {employee_datetime_IDs: employeeDatetimeIDs})
                .subscribe(
                    (response: any) => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public getAttendanceOverviewByEmployee(employeeId: number, month: number, year: number): Observable<EmployeeAttendanceOverview> {
        return this._http.get<EmployeeAttendanceOverview>(`/api/employees/${employeeId}/datetimes/attendance-overview?month=${month}&year=${year}`);
    }
}
