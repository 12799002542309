<div class="card shadow h-100">
  <app-help name="employee.detail.accesses"></app-help>

  <div class="card-header py-3">
    <div class="row">
      <div class="col-6 text-left">
        <p class="modal-title font-16 pt-2 pb-2 text-dark">
          <span>{{ 'employees_accesses.page_title_index' | translate }}</span>
        </p>
      </div>
      <div class="col-6 text-right">
          <button
            *ngIf="canEditAccess$ | async"
            [createButton]="'employees_accesses.action_create' | translate"
            (click)="openEditCreateModal()"
          ></button>
      </div>
    </div>
  </div>

  <div class="card-body px-4">
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <app-data-table
            [loading]="loading$ | async"
            [rows]="accesses$ | async"
            [showSearch]="false"
            [trackByFn]="trackByAccess"
          >
            <ng-template
              #dataRow
              let-row
            >
              <td appDataTableDataCell>{{ row.name }}</td>

              <td
                appDataTableDataCell
                class="text-right"
              >
                <button
                  *ngIf="canEditAccess$ | async"
                  class="btn btn-white btn-icon btn-sm mr-1"
                  (click)="openEditCreateModal(row)"
                >
                  <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                </button>
              </td>
            </ng-template>
          </app-data-table>
        </div>
      </div>
    </div>
  </div>
</div>
