  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>
          {{ ("entity_update_request."+(entityUpdateRequest.action)) | translate }}
          {{ ("entity_update_request."+(entityUpdateRequest.entity_type)+"_request_title") | translate}}
        </span>
      </p>

      <button
        aria-label="Close"
        class="close"
        (click)="activeModal.dismiss()"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <form #f="ngForm">
                <div class="row mb-4">
                  <div class="col-12">
                    <label class="control-label">
                        Objekt
                    </label><br/>
                    {{ entityUpdateRequest.entity_label }}
                  </div>
                </div>
                <div class="row" *ngIf="entityUpdateRequest.diff_data">
                  <div class="col-12">
                    <div class="form-group text-left">
                      <div class="row border-bottom mb-1">
                        <div class="col-4"></div>
                        <div class="col-4 text-dark"><label class="control-label">{{"entity_update_request.last_value" | translate}}</label></div>
                        <div class="col-4 text-dark"><label class="control-label">{{"entity_update_request.new_value" | translate}}</label></div>
                      </div>
                      <div *ngFor="let item of entityUpdateRequest.diff_data" class="row border-bottom mb-1">
                        <div class="col-4 text-dark">
                          <label *ngIf="item.key" class="control-label">{{("entity_update_request."+(item.key)) | translate}}</label>
                          <label *ngIf="item.label" class="control-label">{{ item.label }}</label>
                        </div>
                        <div class="col-4">{{("entity_update_request."+(item.old) | translate) !== "entity_update_request."+(item.old) ? ("entity_update_request."+(item.old) | translate) : item.old}}</div>
                        <div class="col-4">{{("entity_update_request."+(item.new) | translate) !== "entity_update_request."+(item.new) ? ("entity_update_request."+(item.new) | translate) : item.new}}</div>
                      </div>
                    </div>
                  </div>
                  </div>

                <div class="row mt-4">
                  <div class="col-12">
                    <label class="control-label">{{"entity_update_request.request_description" | translate}}</label>
                    <input type="text" class="form-control" disabled readonly [value]="entityUpdateRequest.request_description" />
                  </div>
                </div>

                <div *ngIf="canEdit" class="row mt-4">
                  <div class="col-12">
                    <label class="control-label">{{"entity_update_request.state_description" | translate}}</label>
                    <input
                    ngModel
                    type="text"
                    class="form-control"
                    name="state_description"
                    placeholder="{{ 'entity_update_request.modal-add_state_description' | translate }}"
                         >
                  </div>
                </div>

                <div *ngIf="canEdit" class="row mt-4 justify-content-between">
                  <div class="col-6">
                    <button class="btn btn-danger"
                    (click)="onSubmit(f.form, 'DENIED')"
                    >
                      <span>{{ 'entity_update_request.modal-deny' | translate }}</span>
                    </button>
                  </div>
                  <div class="col-6 text-right">
                    <button class="btn btn-primary"
                    (click)="onSubmit(f.form, 'APPROVED')"
                    >
                      <span>{{ 'entity_update_request.modal-approve' | translate }}</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
