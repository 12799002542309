import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { localStorageSafe } from '../functions';

@Injectable({
    providedIn: 'root'
})
export class InputVisibilityService {
    private storageKey = 'hide_sensitive_info';
    public hideInputsSubject: BehaviorSubject<boolean>;

    constructor() {
        const storedValue = localStorageSafe.getItem(this.storageKey);
        this.hideInputsSubject = new BehaviorSubject<boolean>(storedValue === 'true');
    }

    public get hiddenInputs(): boolean {
        return this.hideInputsSubject.value;
    }

    public get hiddenInputs$(): BehaviorSubject<boolean> {
        return this.hideInputsSubject;
    }

    public toggleInputs() {
        const newValue = !this.hideInputsSubject.value;
        this.hideInputsSubject.next(newValue);
        localStorageSafe.setItem(this.storageKey, String(newValue));
    }
}
