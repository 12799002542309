<div class="row">
    <div class="col-12">
        <div class="modal-content">
            <div class="modal-header d-block">
                <p class="modal-title font-16 mb-0 text-dark">
                    <ngx-skeleton-loader [theme]="{ width: '30%', 'margin': '0', 'display': 'block' }"
                                         appearance="line"
                                         count="1">
                    </ngx-skeleton-loader>
                </p>
            </div>

            <div class="modal-body">
                <ngx-skeleton-loader [theme]="{ width: '100%', 'margin': '25px 0', 'display': 'block' }"
                                     appearance="line"
                                     count="7">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
</div>
