type UnknownFileType = 'unknown';

type FileTypeType = UnknownFileType |
    'archive' |
    'audio' |
    'code' |
    'document' |
    'drawing' |
    'image' |
    'pdf' |
    'presentation' |
    'spreadsheet' |
    'video';

const EXTENSIONS_BY_FILE_TYPE: {
    archive: Array<string>;
    audio: Array<string>;
    code: Array<string>;
    document: Array<string>;
    drawing: Array<string>;
    image: Array<string>;
    pdf: Array<string>;
    presentation: Array<string>;
    spreadsheet: Array<string>;
    video: Array<string>;
} = {
    archive: ['7z', 'gz', 'pkg', 'rar', 'tar', 'tar.gz', 'zip'],
    audio: ['aif', 'mid', 'midi', 'mp3', 'mpa', 'ogg', 'wav', 'wma'],
    code: ['asp', 'aspx', 'c', 'css', 'dat', 'db', 'htm', 'html', 'js', 'jsp', 'log', 'py', 'rss', 'scss', 'sh', 'sql', 'ts', 'xml'],
    document: ['doc', 'docx', 'odt', 'otf', 'rtf', 'tex', 'txt'],
    drawing: ['ai', 'dwg', 'dxf', 'eps', 'ps', 'svg'],
    image: ['bmp', 'gif', 'ico', 'jpg', 'jpeg', 'png', 'tif', 'tiff', 'heic'],
    pdf: ['pdf'],
    presentation: ['odp', 'pps', 'ppt', 'pptx'],
    spreadsheet: ['csv', 'ods', 'xlr', 'xls', 'xlsx'],
    video: ['3g2', '3gp', 'avi', 'flv', 'h26', 'm4v', 'mkv', 'mov', 'mp4', 'mpe', 'mpg', 'swf', 'wmv']
};

const UNKNOWN_FILE_TYPE: UnknownFileType = 'unknown';

const getFileType = (ext: string): FileTypeType | null => {
    if (!ext) {
        return null;
    }

    let match: FileTypeType = UNKNOWN_FILE_TYPE;

    for (let fileType in EXTENSIONS_BY_FILE_TYPE) { // tslint:disable-line:prefer-const
        if (!EXTENSIONS_BY_FILE_TYPE.hasOwnProperty(fileType)) {
            continue;
        }

        if (isOfType(fileType as FileTypeType, ext)) {
            match = fileType as FileTypeType;
        }
    }

    return match;
};

const isOfType = (type: FileTypeType, ext: string): boolean => {
    const extensions = EXTENSIONS_BY_FILE_TYPE[type] || [];
    const extension = ext.toLowerCase();

    return extensions.indexOf(extension) > -1;
};

export {
    UNKNOWN_FILE_TYPE,
    FileTypeType,
    getFileType
};
