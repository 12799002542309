<ng-container *ngIf="availableFeaturesExtended?.length > 0">
    <div class="row">
        <div class="col">
            <h4 class="page-title text-dark mb-3">{{'features.settings' | translate}}</h4>
        </div>
        <div class="col-auto text-right">
            <span class="mr-3">
                <i class="mdi mdi-24px mdi-crown text-primary"></i> {{'features.is_standard_feature' | translate}}
            </span>
            <span>
                <i class="mdi mdi-24px mdi-crown text-warning"></i> {{'features.is_premium_feature' | translate}}
            </span>
        </div>
    </div>
    <form #fFeatures="ngForm">
        <div class="row">
            <div *ngFor="let feature of availableFeaturesExtended" class="col-6 col-lg-3" >
                <div (click)="setFeature(fFeatures, feature)" [ngClass]="{'not-available-feature': !feature.is_available && !(feature.is_overwritten && feature.actual_state)}" class="card shadow card-bg-icon card-height">
                    <div class="card-body card-body-icon-bg">
                        <div>
                            <span class="card-body-icon-bg-icon">
                                <i class="{{feature.icon}}"></i>
                            </span>
                            <h5 class="mt-0 text-dark">{{('features.' + feature.name) | translate}}</h5>
                            <p>{{('features.description_' + feature.name) | translate}}</p>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div>
                                    <input disabled [checked]="fFeatures.form.value[feature.name]" [ngModel]="feature.actual_state" data-switch="success" id="{{feature.name}}" name="{{feature.name}}" type="checkbox">
                                    <label for="{{feature.name}}" class="m-0">&nbsp;</label>
                                </div>
                            </div>
                            <div *ngIf="feature.actual_state && feature.available_until" class="col-auto">
                                {{'features.available_until' | translate}}: {{feature.available_until | date: 'dd.MM.yyyy hh:mm'}}
                            </div>
                        </div>
                    </div>
                </div>
                <span *ngIf="feature.min_subscription === 'STANDARD'"
                      [ngbTooltip]="'features.is_standard_feature' | translate"
                      container="body"
                      class="is-premium-feature"
                      placement="top-left">
                    <i class="mdi mdi-crown text-primary"></i>
                </span>
                <span *ngIf="feature.min_subscription === 'PREMIUM'"
                      [ngbTooltip]="'features.is_premium_feature' | translate"
                      container="body"
                      class="is-premium-feature"
                      placement="top-left">
                    <i class="mdi mdi-crown text-warning"></i>
                </span>
            </div>
        </div>
        <!--<div *ngIf="!isWelcomeModal && settingsChanged(fFeatures)" class="row">
            <div class="col-12 text-right">
                <button type="submit" class="btn btn-primary" (click)="saveFeatures(fFeatures)">{{'features.save' | translate}}</button>
            </div>
        </div>-->
    </form>
</ng-container>

