<ng-template
    #vocabularyModal
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'document_templates.help' | translate }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
            >
          <span
              aria-hidden="true"
              class="font-24 text-muted"
          >
            <i class="mdi mdi-close"></i>
          </span>
            </button>
        </div>

        <div class="modal-body">
            <div class="row mb-4">
                <div class="col-12 mb-3 text-muted">
                    {{ 'document_templates.vocabulary_description_1' | translate }}
                </div>
                <div class="col-12 mb-1 text-muted">
                    {{ 'document_templates.vocabulary_description_2' | translate }}
                </div>
                <div class="col-12 mb-1 text-muted">
                    {{ 'document_templates.vocabulary_description_3' | translate }}
                </div>
                <div class="col-12 mb-1 text-muted">
                    {{ 'document_templates.vocabulary_description_4' | translate }}
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div *ngFor="let category of $vocabulary | async" class="row mb-3">
                        <div class="col-12 mb-1 text-dark font-weight-bold"> {{('document_templates.group_'+ category.group) | translate}} </div>
                        <ng-container *ngIf="category.fields">
                            <ng-container *ngFor="let field of category.fields">
                                <ng-container *ngIf="getTypeOfVariable(field) === 'string'">
                                    <div class="col-lg-6 col-12"> {{('document_templates.field_' + field + '_title') | translate}} </div>
                                    <div class="col-lg-6 col-12 mb-1 mb-lg-0"> {{'${' + field + '}'}} </div>
                                </ng-container>
                                <ng-container *ngIf="getTypeOfVariable(field) === 'object'">
                                    <div class="col-lg-6 col-12"> {{ field.label }} </div>
                                    <div class="col-lg-6 col-12 mb-1 mb-lg-0"> {{'${' + field.key + '}'}} </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-container *ngIf="documentTemplate$ | async; let documentTemplate; else loadingTemplate">
    <div class="row mb-3">
        <div class="col-md-6 col-12 template-name">
            <div class="card h-100 mb-1 shadow">
                <app-help name="settings.documents.templates.detail.info"></app-help>

                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-6 text-left my-auto">
                            <div class="modal-title font-16 text-dark pt-2 pb-2">
                                <div class="h4"> {{ 'settings.company.documents_templates.basic_info' | translate }} </div>
                            </div>
                        </div>
                        <div *ngIf="canEditTemplate" class="col-6 text-right my-auto">
                            <button
                                (click)="openEditModal(documentTemplate)"
                                [updateButton]="'settings.company.documents_templates.edit_action' | translate"
                            ></button>
                        </div>
                    </div>
                </div>

                <div class="card-body px-4">
                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark">{{ 'settings.company.documents_templates.template_name' | translate }}</span>
                        </div>
                        <div class="col-12 col-sm-9 col-md-9 py-1 py-md-2">
                            <span *ngIf="documentTemplate">{{ documentTemplate.name }}</span>
                        </div>
                    </div>

                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark">{{ 'settings.company.documents_templates.created_at' | translate }}</span>
                        </div>
                        <div class="col-12 col-sm-9 col-md-9 py-1 py-md-2">
                            <span *ngIf="documentTemplate">{{ documentTemplate.created_at | date:'dd.MM.yyyy HH:mm'}}</span>
                        </div>
                    </div>

                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark">{{ 'settings.company.documents_templates.code' | translate }}</span>
                        </div>
                        <div class="col-12 col-sm-9 col-md-9 py-1 py-md-2">
                            <span *ngIf="documentTemplate">{{ documentTemplate.code + '-0001' }}</span>
                        </div>
                    </div>

                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark">{{ 'settings.company.documents_templates.description' | translate }}</span>
                        </div>

                        <div class="col-12 col-sm-9 col-md-9 py-1 py-md-2">
                            <span *ngIf="documentTemplate" [innerHtml]="documentTemplate.description"></span>
                        </div>
                    </div>

                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark">{{ 'settings.company.documents_templates.active' | translate }}</span>
                        </div>

                        <div class="col-12 col-sm-9 col-md-9 py-1 py-md-2">
                            <span *ngIf="documentTemplate"> {{(documentTemplate.active ? 'global.YES' : 'global.NO') | translate}} </span>
                        </div>
                    </div>

                    <div class="row border-bottom">
                        <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                            <span class="text-dark">{{ 'document_templates.signable' | translate }}</span>
                        </div>

                        <div class="col-12 col-sm-9 col-md-9 py-1 py-md-2">
                            <span *ngIf="documentTemplate">
                                <span *ngIf="!documentTemplate.signable">{{ 'global.NO' | translate }}</span>
                                <span *ngIf="documentTemplate.signable">{{ 'global.YES' | translate }}, </span>
                                <span *ngIf="documentTemplate.require_owner_signature">{{ 'document_templates.by_owner' | translate }}</span>
                                <span *ngIf="documentTemplate.require_owner_signature && documentTemplate.require_responsible_signature"> {{ 'global.and' | translate }} </span>
                                <span *ngIf="documentTemplate.require_responsible_signature">{{ 'document_templates.by_responsible' | translate }}</span>
                            </span>
                            <div class="text-muted" *ngIf="documentTemplate">
                                <small>
                                    {{'document_templates.signable_explanation' | translate}}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-12">
            <div class="row stats-row-top">
                <div class="col-md-6 col-12 stats-col">
                    <div class="card h-100 mb-1 shadow">
                        <app-help name="settings.documents.templates.detail.statistics"></app-help>
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <h5 class="text-muted font-weight-normal mt-0 text-truncate">
                                        <span>{{ 'settings.company.documents_templates.documents_generated' | translate }}</span>
                                    </h5>

                                    <h3 class="my-2 py-1 text-dark">
                                        <span *ngIf="documentTemplate"> {{ documentTemplate.generated_count }} </span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-12">
                    <div class="card h-100 mb-1 shadow">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <h5 class="text-muted font-weight-normal mt-0 text-truncate">
                                        <span>{{ 'settings.company.documents_templates.documents_signed' | translate }}</span>
                                    </h5>

                                    <h3 class="my-2 py-1 text-dark">
                                        <span *ngIf="documentTemplate"> {{ documentTemplate.signed_count }} </span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row stats-row-bottom">
                <div class="col-md-6 col-12">
                    <div class="card h-100 mb-1 shadow">
                        <app-help name="settings.documents.templates.detail.statistics"></app-help>
                        <div class="card-body">
                            <div class="row align-items-start">
                                <div class="col">
                                    <h5 class="text-muted font-weight-normal mt-0 text-truncate">
                                        <span>{{ 'settings.company.documents_templates.documents_to_be_signed' | translate }}</span>
                                    </h5>

                                    <h3 class="my-2 py-1 text-dark">
                                        <span *ngIf="documentTemplate"> {{ documentTemplate.to_be_signed_count }} </span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-12">
            <div class="card shadow">
                <app-help name="employee.detail.files"></app-help>
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-6 text-left my-auto">
                            <p class="modal-title font-16 pt-2 pb-2 text-dark">
                                <span>{{ 'employees.files' | translate }}</span>
                            </p>
                        </div>
                        <div *ngIf="showHtmlButton" class="col-6 text-right my-auto">
                            <div *ngIf="canEditTemplate">
                                <button
                                    [routerLink]="['/setting/document', documentTemplate.key, 'html']"
                                    class="btn btn-primary"
                                >
                                   <ng-container *ngIf="!documentTemplate.templateBase64"> {{ 'document_templates.create_html' | translate }} </ng-container>
                                   <ng-container *ngIf="documentTemplate.templateBase64"> {{ 'document_templates.edit_html' | translate }} </ng-container>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="showDropzone" class="card-body">
                    <div *ngIf="documentTemplate.generated_count > 0 && canEditTemplate" class="row">
                        <div class="col-12 text-right mt-2">
                            <small class="text-muted">
                                {{ 'document_templates.cannot_delete_explanation' | translate }}
                            </small>
                        </div>
                    </div>

                    <ng-container *ngIf="documentTemplate.generated_count === 0 && canEditTemplate">
                        <app-upload
                            (success)="refreshData()"
                            [allowedExtensions]="'.pdf, .docx'"
                            [allowTakingPicture]="false"
                            [modelName]="'document_template_ID'"
                            [modelValue]="documentTemplate.document_template_ID"
                        ></app-upload>
                    </ng-container>

                    <app-files-table
                        (refetch)="refreshData()"
                        *ngIf="file$"
                        [allowDelete]="canDeleteTemplate && documentTemplate.generated_count === 0"
                        [entityID]="documentTemplate.document_template_ID"
                        [entityType]="'document-template'"
                        [files$]="file$"
                        [objectType]="'DOCUMENT_TEMPLATE'"
                        [object]="documentTemplate"
                        [showFileOnNewPage]="true"
                    ></app-files-table>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-12 d-flex flex-column">
            <div class="card shadow">
                <app-help name="settings.documents.templates.detail.legend"></app-help>
                <div class="card-header border-bottom-0 py-3">
                    <div class="row">
                        <div class="col-12 col-sm-6 text-nowrap">
                            <p class="modal-title font-16 pt-2 pb-2">
                                <span>{{ 'settings.company.documents_templates.help' | translate }}</span>
                            </p>
                        </div>
                        <div class="col-12 col-sm-6 text-right align-self-center">
                            <button
                                (click)="openVocabularyModal()"
                                class="btn btn-primary"
                            >
                                {{ 'settings.company.documents_templates.action_show_legend' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card shadow flex-1">
                <app-help name="settings.documents.templates.detail.notify"></app-help>
                <div class="card-header border-bottom-0 py-3">
                    <div class="row">
                        <div class="col-12 col-sm-6 text-nowrap">
                            <p class="modal-title font-16 pt-2 pb-2">
                                <span>{{ 'settings.company.documents_templates.notify' | translate }}</span>
                            </p>
                        </div>
                        <div class="col-12 col-sm-6 text-right align-self-center">
                            <button
                                (click)="sendNotification(documentTemplate.document_template_ID)"
                                class="btn btn-primary"
                            >
                                {{ 'settings.company.documents_templates.notify' | translate }}
                            </button>
                        </div>
                        <div class="col-12 text-right mt-2">
                            <small class="text-muted">
                                {{ 'settings.company.documents_templates.notify_explanation' | translate }}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-document-list [documentTemplate]="documentTemplate"
                       [filterUnsigned]="false"
                       [allowFilterByTemplate]="false"
                       [showHeader]="true">
    </app-document-list>
</ng-container>

<ng-template #loadingTemplate>
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="card shadow p-3">
                <ngx-skeleton-loader [theme]="{ width: '100%', 'margin': '25px 0', 'display': 'block' }"
                                     appearance="line"
                                     count="8">
                </ngx-skeleton-loader>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="card shadow p-3">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'margin': '25px 0', 'display': 'block' }"
                                             appearance="line"
                                             count="3">
                        </ngx-skeleton-loader>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="card shadow p-3">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'margin': '25px 0', 'display': 'block' }"
                                             appearance="line"
                                             count="3">
                        </ngx-skeleton-loader>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="card shadow p-3">
                        <ngx-skeleton-loader [theme]="{ width: '100%', 'margin': '23px 0', 'display': 'block' }"
                                             appearance="line"
                                             count="3">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </div>


        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card shadow p-3">
                <ngx-skeleton-loader [theme]="{ width: '100%', 'margin': '25px 0', 'display': 'block' }"
                                     appearance="line"
                                     count="7">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card shadow p-3">
                <ngx-skeleton-loader [theme]="{ width: '100%', 'margin': '25px 0', 'display': 'block' }"
                                     appearance="line"
                                     count="7">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
</ng-template>
