import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CustomField } from '../../../../shared/models/CustomField';
import { CustomFieldService } from '../../../services/custom-field.service';

interface OptionInput {
    value: string;
    is_default: boolean;
}

@Component({
    selector: 'app-create-edit-custom-field-modal',
    templateUrl: './create-edit-custom-field-modal.component.html',
    styleUrls: ['./create-edit-custom-field-modal.component.css']
})

export class CreateEditCustomFieldModalComponent implements OnInit {

    public customFieldForm: UntypedFormGroup;
    public customField: CustomField;
    public submitted = false;
    public showExceedDefaultAlert: boolean;
    public customFieldWidgets = [
        {value: 'PERSONAL_DATA', label: this._translateService.instant('settings.appearance.custom_fields.widget_PERSONAL_DATA')},
        {value: 'EMPLOYEE', label: this._translateService.instant('settings.appearance.custom_fields.widget_EMPLOYEE')},
        {value: 'PROPERTY', label: this._translateService.instant('settings.appearance.custom_fields.widget_PROPERTY')},
        {value: 'EMPLOYEE_POSITION', label: this._translateService.instant('settings.appearance.custom_fields.widget_EMPLOYEE_POSITION')},
        {value: 'EMPLOYEE_SALARY', label: this._translateService.instant('settings.appearance.custom_fields.widget_EMPLOYEE_SALARY')},
    ];
    public customFieldTypes = [
        {value: 'TEXT', label: this._translateService.instant('settings.appearance.custom_fields.type_TEXT')},
        {value: 'TEXTAREA', label: this._translateService.instant('settings.appearance.custom_fields.type_TEXTAREA')},
        {value: 'RICHTEXT', label: this._translateService.instant('settings.appearance.custom_fields.type_RICHTEXT')},
        {value: 'NUMBER', label: this._translateService.instant('settings.appearance.custom_fields.type_NUMBER')},
        {value: 'PERCENT', label: this._translateService.instant('settings.appearance.custom_fields.type_PERCENT')},
        {value: 'AMOUNT', label: this._translateService.instant('settings.appearance.custom_fields.type_AMOUNT')},
        {value: 'DATE', label: this._translateService.instant('settings.appearance.custom_fields.type_DATE')},
        {value: 'DATETIME', label: this._translateService.instant('settings.appearance.custom_fields.type_DATETIME')},
        {value: 'TIME', label: this._translateService.instant('settings.appearance.custom_fields.type_TIME')},
        {value: 'TOGGLE', label: this._translateService.instant('settings.appearance.custom_fields.type_TOGGLE')},
        {value: 'SELECT', label: this._translateService.instant('settings.appearance.custom_fields.type_SELECT')},
        {value: 'MULTISELECT', label: this._translateService.instant('settings.appearance.custom_fields.type_MULTISELECT')},
    ];

    constructor(
        private _fb: UntypedFormBuilder,
        private _customFieldService: CustomFieldService,
        private _translateService: TranslateService,
        public activeModal: NgbActiveModal,
    ) { }

    public ngOnInit(): void {
        this.customFieldForm = this._fb.group({
            label: ['', Validators.required],
            type: ['', Validators.required],
            is_required: [false, Validators.required],
            is_visible: [true, Validators.required],
            widget: ['', Validators.required],
        });

        this.onTypechange({value: this.customField?.type, label: ''});
        this.customFieldForm.patchValue(this.customField);

        if (this.customField?.is_used) {
            this.customFieldForm.get('label').disable();
            this.customFieldForm.get('type').disable();
            this.customFieldForm.get('widget').disable();
        }

        const keys = Object.entries(this.customField?.options ?? []);

        if (keys?.length > 0) {
            this.options.clear();
        }

        for (const [key, value] of keys) {
            this.addOption(value);
        }
    }

    public get options(): FormArray {
        return this.customFieldForm?.get('options') as FormArray;
    }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.customFieldForm.controls;
    }

    public onSubmit(): void {
        this.submitted = true;
        if (this.customFieldForm.invalid) {
            return;
        }

        if (this.customFieldForm.get('type').value === 'SELECT') {
            const optionsDefault = this.options.value.filter((option: OptionInput) => option.is_default)?.length;
            if (optionsDefault > 1) {
                return;
            }
        }

        this._customFieldService.saveCustomField(this.customFieldForm.getRawValue(), this.customField?.custom_field_definition_ID)
            .then(() => {
                this.activeModal.close('close');
            });
    }

    public deleteCustomField(): void {
        this._customFieldService.deleteCustomField(this.customField?.custom_field_definition_ID)
            .then(() => this.activeModal.close('close')).catch(() => {});
    }

    public onTypechange($event: { value: string, label: string }): void {
        if ($event.value === 'SELECT' || $event.value === 'MULTISELECT') {
            this.customFieldForm.addControl('options', this._fb.array([
                this._fb.group({
                    value: [null, Validators.required],
                    is_default: [null]
                }),
            ]));
        } else {
            this.customFieldForm.removeControl('options');
        }
    }

    public onDefaultChange(): void {
        this.showExceedDefaultAlert = false;
        if (this.customFieldForm.get('type').value === 'SELECT') {
            const optionsDefault = this.options.value.filter((option: OptionInput) => option.is_default)?.length;
            if (optionsDefault > 1) {
                this.showExceedDefaultAlert = true;
            }
        }
    }

    public addOption(option = null): void {
        this.options.push(this._fb.group({
            value: [option?.value, Validators.required],
            is_default: [option?.is_default]
        }));
    }

    public removeOption(index: number): void {
        this.options.removeAt(index);
    }
}
