import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { EmployeeReview } from './employee-review.model';

export interface EmployeeReviewState extends EntityState<EmployeeReview> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'employee-reviews', idKey: 'employee_review_ID' })
export class EmployeeReviewStore extends EntityStore<EmployeeReviewState> {
  constructor() {
    super();
  }
}
