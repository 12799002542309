import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PropertyService } from 'src/app/property/services/property.service';

@Component({
    selector: 'app-create-edit-category',
    templateUrl: './create-edit-category.component.html',
    styleUrls: ['./create-edit-category.component.css']
})
export class CreateEditCategoryComponent implements OnInit {
    public categoryEditForm: UntypedFormGroup;
    public categoryID: number;
    public parentCategoryID: number;
    public submitted = false;
    public autocompleteItems = [
        {
            name: 'Rok 2020',
            value: '%Y%'
        },
        {
            name: 'Rok 20',
            value: '%y%'
        },
        {
            name: 'Číslo 0001',
            value: '%NMB_4%'
        },
        {
            name: 'Číslo 001',
            value: '%NMB_3%'
        },
        {
            name: 'Číslo 01',
            value: '%NMB_2%'
        }
    ];

    public constructor(
        public activeModal: NgbActiveModal,
        private _fb: UntypedFormBuilder,
        private _propertyService: PropertyService
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.categoryEditForm.controls;
    }

    public ngOnInit(): void {
        this.categoryEditForm = this._fb.group({
            name: ['', Validators.required],
            parent_id: [null]
        });

        if (this.parentCategoryID) {
            this.categoryEditForm.patchValue({
                parent_id: this.parentCategoryID
            });
        }

        if (this.categoryID) {
            this._propertyService.getPropertyCategoryByID(this.categoryID)
                .subscribe(category => {
                    this.categoryEditForm.patchValue({
                        name: category.name,
                        parent_id: category.parent_id
                    });
                });
        }
    }

    public deleteCategory(): void {
        this._propertyService.deleteCategory(this.categoryID)
            .then(
                () => this.activeModal.close('delete'),
                () => { }
            );
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.categoryEditForm.valid) {
            return;
        }

        const formValue = this.categoryEditForm.value;

        this._propertyService.saveCategory(formValue, this.categoryID)
            .then(() => this.activeModal.close('close'));
    }
}
