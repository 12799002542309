import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { ChatMessage } from './chat-message.model';
import { Chat, PromptResponse } from './chat.model';

@Injectable({
    providedIn: 'root'
})

export class ChatService {
    public constructor(
        private _http: HttpClient,
        private _apiHelper: ApiHelper,
        private _loaderService: NgxUiLoaderService,
    ) { }

    public sendMessage(chatID: number, message: string): Observable<{ chat: Chat, answer: ChatMessage }> {
        return this._http.post<{ chat: Chat, answer: ChatMessage }>(`/api/chat-messages/send`, {chatID, message});
    }

    public getChatsByUserID(): Observable<Chat[]> {
        return this._http.get<Chat[]>(`/api/chats/get`);
    }

    public getChatMessagesByChatID(chatID: number): Observable<ChatMessage[]> {
        return this._http.get<ChatMessage[]>(`/api/chat-messages/get/${chatID}`);
    }

    public newPrompt(form: any): Promise<PromptResponse> {
        return new Promise(resolve => {
            this._http.post<PromptResponse>(`/api/chats/prompt`, form)
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        resolve(response);
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public getInsight(form: any): Promise<PromptResponse> {
        return new Promise(resolve => {
            this._http.post<PromptResponse>(`/api/chats/insight`, form)
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        resolve(response);
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }
}
