import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { sendDefaultFormValue } from './filter-changed-directive';

@Directive({
    selector: 'form[formGroup]'
})
export class FormHelperDirective implements AfterViewInit, OnDestroy {
    @Input() public formGroup: UntypedFormGroup;

    public intervals: any = {};
    public showedErrorMessage = false;

    private _fromTosubscription: Subscription;
    private _resetFilterSubscription: Subscription;

    constructor(
        public el: ElementRef,
        private _translateService: TranslateService,
    ) { }

    public ngOnDestroy(): void {
        this._fromTosubscription?.unsubscribe();
        this._resetFilterSubscription?.unsubscribe();
    }

    public ngAfterViewInit(): void {
        this._setUpFromTo();
        this._markRequiredLabels(this.formGroup.controls);
        this._setUpResetFilterButton();
    }

    // check that from can't be greater than to and opposite
    private _setUpFromTo(): void {
        if (this.formGroup.controls.from && this.formGroup.controls.to) {
            this._setValidator();
            this._showErrorMessage();
            this._fromTosubscription = this.formGroup.controls.from.valueChanges.subscribe((fromValue: any) => {
                if (this.formGroup.controls.to.pristine && this._areFromToFilled()) {
                    const toValue = this.formGroup.controls.to.value;
                    const fromValueSplitted = fromValue.split(' ');
                    if (!toValue || fromValue > toValue) {
                        this.formGroup.controls.to.setValue(fromValueSplitted[0]);
                    }
                }
            });
        }
    }

    // marks required labels with class red asterisk
    private _markRequiredLabels(controls: { [p: string]: AbstractControl }): void {
        let i = 0;
        for (const key of Object.keys(controls)) {
            const control = controls[key];
            // if control is FormGroup, we have to check its controls
            if (control instanceof FormGroup) {
                this._markRequiredLabels(control.controls);
                break;
            }

            if (control.hasValidator(Validators.required)) {
                const replacedKey = key.split('.').join('\\.');
                let inputElement = this._inputSelector(replacedKey);
                // we have to check in interval, because some inputs are not rendered yet
                this.intervals[i] = setInterval(() => {
                    inputElement = this._inputSelector(replacedKey);
                    if (inputElement.length > 0) {
                        clearInterval(this.intervals[i]);
                        let siblingLabel = inputElement.siblings('label').first();
                        // if there is no sibling label, we have to check parent div
                        if (siblingLabel?.length === 0) {
                            siblingLabel = inputElement.parent('div').first().siblings('label').first();
                        }
                        if (siblingLabel && !siblingLabel.hasClass('not-required')) {
                            siblingLabel.addClass('required-label');
                        }
                    }
                }, 100);
            }
            i++;
        }
    }

    // adds reset filter button to the filter form
    private _setUpResetFilterButton(): void {
        if (this.el.nativeElement.classList.contains('filter-form')) {
            const selectButton = document.createElement('u');
            selectButton.classList.add('text-muted', 'mr-4');
            selectButton.innerHTML = this._translateService.instant('global.reset_filters');
            this.el.nativeElement.querySelector('div.row button.btn-primary').insertAdjacentElement('beforebegin', selectButton);
            this._resetFilterSubscription = sendDefaultFormValue.subscribe((value: any) => {
                const defaultValueKeys = Object.keys(value);
                const actualValueKeys = Object.keys(this.formGroup.value);

                // if there are more filter forms in DOM, we have to check, if the value emitted is from the same form
                if (defaultValueKeys.sort().toString() === actualValueKeys.sort().toString()) {
                    selectButton.onclick = () => this.formGroup.reset(value);
                }
            });
        }
    }

    private _inputSelector(key: string): JQuery<HTMLElement> {
        return $(`input[formControlName=${key}], ng-select[formControlName=${key}], textarea[formControlName=${key}]`);
    }

    // add custom validator to the from, to inputs
    private _setValidator(): void {
        this.formGroup.addValidators([
            (control) => {
                this._showErrorMessage();
                if (this._areFromToFilled() && control.value.from > control.value.to) {
                    return {fromBiggerThanTo: true};
                }
                return null;
            }]);
    }

    private _showErrorMessage(): void {
        if (this._areFromToFilled() && this.formGroup.controls.from.value > this.formGroup.controls.to.value) {
            if (!this.showedErrorMessage) {
                this._inputSelector('to').parents('div.row').first()
                    .append(`<div id="from-to-error" class="col-12 text-danger">${this._translateService.instant('global.from_to_error')}</div>`);
            }
            this.showedErrorMessage = true;
        } else {
            $('#from-to-error').remove();
            this.showedErrorMessage = false;
        }
    }

    private _areFromToFilled(): boolean {
        return (
            this.formGroup.controls.from.value && this.formGroup.controls.to.value
            && this.formGroup.controls.from.value !== '' && this.formGroup.controls.to.value !== ''
        );
    }
}
