<div *ngIf="isModal" class="modal-content">
	<div class="modal-header">
			<p class="modal-title font-16 mb-0 text-dark">{{'search.search' | translate}}</p>
		<button (click)="modalRef.dismiss()" type="button" class="close" aria-label="Close">
			<span class="font-24 text-muted" aria-hidden="true"><i class="mdi mdi-close"></i></span>
		  </button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-0 shadow-none">
					<div class="card-body p-0" [ngTemplateOutlet]="searchTemplate"></div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-container *ngIf="!isModal" [ngTemplateOutlet]="searchTemplate"></ng-container>

<ng-template #searchTemplate>
    <ng-select #searchInput
        class="custom search-select"
        [items]="results$ | async"
        bindLabel="label"
        groupBy="object_type"
        [minTermLength]="3"
        [loading]="resultsLoading"
        typeToSearchText="{{ 'search.type_in_searched_text' | translate }}"
        [typeahead]="resultsInput$"
        [placeholder]="os === 'windows' || os === 'macos' ? ('search.placeholder_' + os | translate) : ''"
        (open)="opened()"
        (close)="closed()"
        (change)="redirectToResult($event)"
    >
        <ng-template ng-optgroup-tmp let-item="item" let-index="index">
            {{ 'permissions.' + item?.object_type | lowercase | translate}}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
            <span>{{ item.label }}</span>
            <span *ngIf="item.is_archived"> 🗃 </span>
            <ng-container *ngIf="item.details">
                <div>
                    <small class="text-muted" *ngFor="let keyValue of item.details; let last = last; let first = first">
                                                <span *ngIf="keyValue.value">
                                                    {{ first ? (keyValue.key | translate) : ((keyValue.key | translate).charAt(0).toLowerCase() + (keyValue.key | translate).slice(1)) }}:
                                                    {{keyValue.value}}{{ !last ? ', ' : '' }}
                                                </span>
                    </small>
                </div>
            </ng-container>

        </ng-template>
    </ng-select>
</ng-template>
