import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { File } from 'src/app/employee/models/File';
import { DocumentTemplate } from 'src/app/setting/models/DocumentTemplate';
import { CompanyService } from 'src/app/setting/services/company.service';
import { SubscriptionService } from 'src/app/setting/services/subscription.service';
import { UserService } from 'src/app/setting/services/user.service';
import { User } from 'src/app/user/models/User';
import { AvailableFeatures } from '../../../../employee/models/AvailableFeatures';

@Component({
    selector: 'app-company-settings-other',
    templateUrl: './company-settings-other.component.html',
    styleUrls: ['./company-settings-other.component.css']
})
export class CompanySettingsOtherComponent implements OnInit {

    public availableFeatures$: Observable<AvailableFeatures>;
    public availableUsers$: Observable<Array<User>>;
    public companyDocumentLogo$: Observable<Array<File>>;
    public companySignatureLogo$: Observable<Array<File>>;
    public selectedTemplate: DocumentTemplate;
    public signatureEmployeeForm: UntypedFormGroup;

    private _companySettings$: Observable<any>;
    private _fetchCompanySettings$ = new ReplaySubject<void>(1);

    public constructor(
        private _companyService: CompanyService,
        private _formBuilder: UntypedFormBuilder,
        private _userService: UserService,
        private _subscriptionService: SubscriptionService
    ) { }

    public ngOnInit(): void {
        this.availableFeatures$ = this._subscriptionService.availableFeatures$;

        this.availableUsers$ = this._userService.getAvailableUsers();

        this._companySettings$ = this._fetchCompanySettings$
            .pipe(
                switchMap(() => this._companyService.getCompanySetting()),
                tap((settings: { company_settings?: { documents?: { signature_employee_ID: number | string; } } }) => {
                    const employeeId = Number(settings?.company_settings?.documents?.signature_employee_ID) || null;

                    this.signatureEmployeeForm.patchValue({'company.documents.signature_employee_ID': employeeId});
                }),
                shareReplay()
            );

        this.companyDocumentLogo$ = this._companySettings$
            .pipe(
                map(settings => {
                    const logos: Array<File> = [];

                    if (settings.company_documents_logo_file) {
                        logos.push(settings.company_documents_logo_file);
                    }

                    return logos;
                })
            );

        this.companySignatureLogo$ = this._companySettings$
            .pipe(
                map(settings => {
                    const logos: Array<File> = [];

                    if (settings.company_signature_file) {
                        logos.push(settings.company_signature_file);
                    }

                    return logos;
                })
            );

        this._fetchCompanySettings$.next();

        this.signatureEmployeeForm = this._formBuilder.group({
            'company.documents.signature_employee_ID': [null]
        });
    }

    public onSubmit(): void {
        const values = {
            ...this.signatureEmployeeForm.value,
            settings_type: 'document'
        };

        this._companyService.saveCompanySetting(values)
            .then(() => this._fetchCompanySettings$.next())
            .catch(() => this._fetchCompanySettings$.next());
    }

    public refresh(): void {
        this._fetchCompanySettings$.next();
    }
}
