import { Component } from '@angular/core';

@Component({
    selector: 'app-modal-loader',
    templateUrl: './modal-loader.component.html',
    styleUrls: ['./modal-loader.component.css']
})
export class ModalLoaderComponent {
    constructor() {
    }
}
