import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeAddress } from 'src/app/employee/models/EmployeeAddress';
import { EmployeeContactPerson } from 'src/app/employee/models/EmployeeContactPerson';
import { AddressService } from 'src/app/employee/services/address.service';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { CompanyService } from '../../../../setting/services/company.service';
import { EmployeeRequiredDataUpdates } from '../../../models/EmployeeRequiredDataUpdates';

@Component({
    selector: 'app-employee-contact-info',
    templateUrl: './employee-contact-info.component.html',
    styleUrls: ['./employee-contact-info.component.css']
})
export class EmployeeContactInfoComponent implements OnInit {
    @Input() requiredUpdates: EmployeeRequiredDataUpdates;
    @Input() entityRequests: Array<any>;
    @Input() employee: Employee;
    @Input() canShow: { address: boolean, contactPerson: boolean };

    @Output() getEmployeeRequiredUpdates = new EventEmitter<string>();
    @Output() getEmployeeActiveRequests = new EventEmitter<string>();

    public employeeAddresses$: Observable<EmployeeAddress[]>;
    public employeeContactPersons$: Observable<EmployeeContactPerson[]>;
    public availableWidgets$: Observable<AvailableWidgets>;
    public employeeID: number;

    constructor(
        private addrService: AddressService,
        private employeeService: EmployeeService,
        private _companyService: CompanyService,
        private route: ActivatedRoute
    ) { }

    public ngOnInit(): void {
        this.availableWidgets$ = this._companyService.availableWidgets$;

        this.employeeID = parseInt(this.route.parent.snapshot.paramMap.get('id'), 10);
        if (this.employeeID) {
            this.getAddresses();
            this.getContactPersons();
        }
    }

    public getAddresses(): void {
        this.employeeAddresses$ = this.addrService.getAllAddressesForEmployee(this.employeeID);
    }

    public getContactPersons(): void {
        this.employeeContactPersons$ = this.employeeService.getContactPersonsByEmployeeID(this.employeeID);
    }

    public getRequired(): void {
        this.getEmployeeRequiredUpdates.emit('refetch');
    }

    public getActiveRequests(): void {
        this.getEmployeeActiveRequests.emit('refetch');
    }

    public canShowBoth(availableWidgets: any): boolean {
        return availableWidgets?.contact_persons && availableWidgets?.address && this.canShow.contactPerson && this.canShow.address;
    }
}
