import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { EmployeeGrowthState, EmployeeGrowthStore } from './employee-growth.store';

@Injectable({ providedIn: 'root' })
export class EmployeeGrowthQuery extends QueryEntity<EmployeeGrowthState> {
    constructor(protected store: EmployeeGrowthStore) {
        super(store);
    }
}
