import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { MenuItem } from './menu-item.model';

export interface MenuItemState extends EntityState<MenuItem> {
    isCollapsed: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'menu-items', idKey: 'menu_item_ID', resettable: true })
export class MenuItemStore extends EntityStore<MenuItemState> {
  constructor() {
    super({isCollapsed: true});
  }
}
