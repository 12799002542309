import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ID } from '@datorama/akita';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tag } from 'src/app/tag/state/tag.model';
import { TagService } from 'src/app/tag/state/tag.service';
import { ToDo } from 'src/app/todo/state/todo.model';
import { Sort, SORT_ASC } from 'src/app/ui';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { TagModalComponent } from '../../partials/tag-modal/tag-modal.component';

@Component({
    selector: 'app-tag-list',
    templateUrl: './tag-list.component.html',
    styleUrls: ['./tag-list.component.css']
})
export class TagListComponent implements OnInit, OnDestroy {
    @Input() entityID: number;
    @Input() entityType: string;
    @Input() category: string;
    @Input() addButtonTitle: string;
    @Input() modalTitle: string;
    @Input() tagsEmptyText: string;

    public canEdit$: Observable<boolean>;
    public entityTags: Tag[];
    public refetchTags$$ = new ReplaySubject<void>();
    public modalRef: NgbModalRef;

    private _tagsSubscription: Subscription;

    public sort: Sort<ToDo> = {
        column: 'deadline',
        direction: SORT_ASC
    };

    public constructor(
        private _modalService: NgbModal,
        private _tagService: TagService,
        private _authService: AuthenticationService
    ) { }

    public ngOnDestroy(): void {
        this._tagsSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        let permissionToCheck = 'tag';
        if (this.category === 'hard_skill' || this.category === 'soft_skill') {
            permissionToCheck = 'skill';
        }

        this.canEdit$ = this._authService.hasPermissionTo([permissionToCheck + '.edit', permissionToCheck + '.editStructure'])
            .pipe(map(permission => permission.can));

        this.refetchTags();

        this.refetchTags$$.subscribe(() => {
            this.refetchTags(true);
        });
    }

    public trackByFn(index: number, tag: Tag): ID {
        return tag.tag_ID;
    }

    public openTagModal(): void {
        this.modalRef = this._modalService.open(TagModalComponent, {centered: true});

        this.modalRef.componentInstance.modalTitle = this.modalTitle;
        this.modalRef.componentInstance.entityTags = this.entityTags;
        this.modalRef.componentInstance.entityID = this.entityID;
        this.modalRef.componentInstance.entityType = this.entityType;
        this.modalRef.componentInstance.category = this.category;
        this.modalRef.componentInstance.refetchTags$$ = this.refetchTags$$;
        this.modalRef.componentInstance.parentInstance = this;

        this.modalRef.result
            .then(
                () => { },
                () => { }
            );
    }

    public refetchTags(setModal = false): void {
        this._tagsSubscription = this._tagService.getEntityTags(this.entityID, this.entityType, this.category)
            .subscribe(entityTags => {
                this.entityTags = entityTags;
                if (setModal && this.modalRef.componentInstance) {
                    this.modalRef.componentInstance.entityTags = entityTags;
                }
            });
    }
}
