<div class="">
    <span class="text-muted tags-title" *ngIf="entityType === 'Employee' && category !== 'hard_skill' && category !== 'soft_skill'"><i class="mdi mdi-tag-multiple mdi-18px"></i> {{ 'tags.tags' | translate }} </span>
  <ng-container *ngIf="entityTags">
    <ng-container *ngIf="entityTags.length > 0">
      <ng-container *ngFor="let tag of entityTags">
        <span class="tag-badge badge badge-success">
        {{ tag.label }}
        </span>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="entityTags.length < 1 && tagsEmptyText">
      <span class="mr-2 font-13">{{ tagsEmptyText }}</span>
    </ng-container>
  </ng-container>

  <button (click)="openTagModal()" *ngIf="canEdit$ | async" [title]="addButtonTitle ? addButtonTitle : ('tags.add_tag' | translate)" class="btn btn-icon btn-primary btn-sm editButton">
    <i class="mdi mdi-pencil"></i>
    <span *ngIf="addButtonTitle">{{ addButtonTitle }}</span>
  </button>

</div>

