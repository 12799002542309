import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgxSummernoteModule } from 'ngx-summernote';
import { SharedModule } from '../shared/shared.module';
import { ScheduledTaskCreateEditModalComponent } from './presentation/scheduled-task-create-edit-modal.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        FlatpickrModule,
        NgSelectModule,
        NgxSummernoteModule,
        TranslateModule,
    ],
    declarations: [
        ScheduledTaskCreateEditModalComponent,
    ],
    exports: [
        ScheduledTaskCreateEditModalComponent,
    ],
})
export class ScheduledTaskCreateEditModule {}
