import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { Employee } from 'src/app/employee/models/Employee';
import { File } from 'src/app/employee/models/File';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { FileService } from 'src/app/shared/services/file.service';
import { Property } from '../models/Property';
import { PropertyCategory } from '../models/PropertyCategory';

@Injectable({
    providedIn: 'root'
})
export class PropertyService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _fileService: FileService,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _swalHelper: SwalHelper,
    ) { }

    public addPropertyToEmployee(form: any, propertyID: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/properties/' + propertyID + '/employees/add', form)
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public deleteProperty(propertyID: number, deleteNote?: string, deleteReason?: string): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();
            let params = null;

            if (deleteConfirmed) {
                if (deleteReason) {
                    params = new HttpParams()
                        .set('delete_note', deleteNote)
                        .set('delete_reason', deleteReason);
                }

                this._loaderService.start();

                this._http.delete<ApiResponse>('/api/properties/' + propertyID, {params})
                    .subscribe(
                        response => {
                            this._loaderService.stop();

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public deleteCategory(propertyCategoryID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete<ApiResponse>('/api/properties-categories/' + propertyCategoryID)
                    .subscribe(
                        response => {
                            this._loaderService.stop();

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public downloadExportedProperties(p: ListParameters<Property>, filter?: string): void {
        const transformedParams = transformParameters(p);

        let params = null;

        const createParams = () => {
            if (!params) {
                params = {};
            }
        };

        if (transformedParams.order) {
            createParams();

            params.order = transformedParams.order;
        }

        if (transformedParams.search) {
            createParams();

            params.search = transformedParams.search;
        }

        if (filter === 'DELETED') {
            createParams();

            params.deleted = 1;
        }

        this._loaderService.start();

        this._http.post<File>('/api/properties-csv', {...params})
            .subscribe(
                file => this._fileService.checkMimeTypeAndDownloadWithAnchor(file),
                error => {
                    this._loaderService.stop();

                    this._apiHelper.handleErrorResponse(error);
                }
            );
    }

    public generateTransferProtocolByPropertyAndEmployeeID(propertyID: number, employeeID: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/properties/' + propertyID + '/generate-transfer-protocol', {employee_ID: employeeID})
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public getAllFilesByPropertyID(propertyID: number): Observable<Array<File>> {
        return this._http.get<Array<File>>('/api/properties/' + propertyID + '/files');
    }

    public getAllPropertiesByEmployeeID(params: ListParameters<Property>, employeeID: number): Observable<ListResponse<Property>> {
        return this._http.get<Array<Property>>('/api/employees/' + employeeID + '/properties')
            .pipe(
                map(response => ({
                    data: response,
                    recordsTotal: response?.length || 0
                }))
            );
    }

    public getAllPropertyCategories(style?: string): Observable<Array<PropertyCategory>> {
        let params = null;

        if (style) {
            params = new HttpParams()
                .set('style', style);
        }

        return this._http.get<Array<PropertyCategory>>('/api/properties-categories', {params});
    }

    public getAllPropertyEmployeesByPropertyID(propertyID: number): Observable<Array<Employee>> {
        return this._http.get<Array<Employee>>('/api/properties/' + propertyID + '/employees');
    }

    public getNewCodeByPropertyCategoryID(propertyCategoryID: number): Observable<{ code: string }> {
        return this._http.get<PropertyCategory>('/api/properties-categories/' + propertyCategoryID + '/new-code');
    }

    public getPropertiesList(params: ListParameters<Property>, filterParams?: {}): Observable<ListResponse<Property>> {
        let p = transformParameters(params);
        p = {
            ...p,
            ...filterParams
        };

        return this._http.post<ListResponse<Property>>('/api/properties/list', {...p});
    }

    public getPropertyByID(propertyID: number): Observable<Property> {
        return this._http.get<Property>(`/api/properties/${propertyID}?widget=PROPERTY`);
    }

    public getPropertyCategoryByID(propertyCategoryID: number): Observable<PropertyCategory> {
        return this._http.get<PropertyCategory>('/api/properties-categories/' + propertyCategoryID);
    }

    public getEmployeePropertyByID(employeePropertyID: number): Observable<number> {
        return this._http.get<number>('/api/properties/employee-property/' + employeePropertyID);
    }

    public getPropertyCategoriesList(params: ListParameters<PropertyCategory>): Observable<ListResponse<PropertyCategory>> {
        return this._http.post<ListResponse<PropertyCategory>>('/api/properties-categories/list', {...transformParameters(params)});
    }

    public removePropertyFromEmployee(
        employeeID: number,
        removalDate: string,
        note: string,
        propertyID: number
    ): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/properties/' + propertyID + '/employees/remove', {employee_ID: employeeID, note, removal_date: removalDate})
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public revertProperty(propertyID): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/properties/' + propertyID + '/revert', {})
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public saveCategory(form: PropertyCategory, propertyCategoryID?: number): Promise<'done'> {
        this._loaderService.start();

        let apiUrl = '/api/properties-categories';

        if (propertyCategoryID) {
            apiUrl += `/${propertyCategoryID}`;
        }

        return new Promise(resolve => {
            this._http.post<ApiResponse>(apiUrl, form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public saveEmployeeProperty(
        form: { handover_date: string, removal_date: string, property_month_costs: number },
        employeeID: number,
        propertyID: number
    ): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>(`/api/employees/${employeeID}/properties/${propertyID}`, form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public saveProperty(form: Property, propertyID?: number): Promise<'done'> {
        this._loaderService.start();

        let apiUrl = '/api/properties';

        if (propertyID) {
            apiUrl += `/${propertyID}`;
        }

        return new Promise(resolve => {
            this._http.post<ApiResponse>(apiUrl, form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public multiAssignUnAssignPropertiesToEmployee(form: any, propertyIDs: number[], action: 'assign' | 'unassign' = 'assign'): Promise<'done'> {
        let url = '/api/properties/attach-to-employee';
        this._loaderService.start();
        if (action === 'unassign') {
            url = '/api/properties/detach-from-employee';
        }

        const body = {
            ...form,
            property_IDs: propertyIDs
        };

        return new Promise(resolve => {
            this._http.post<ApiResponse>(url, body)
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }
}
