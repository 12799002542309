<div class="modal-content">
  <div class="modal-header">
    <p *ngIf="branch" class="modal-title font-16 mb-0 text-dark">{{'company_branches.page_title_edit' | translate}}</p>
    <p *ngIf="!branch" class="modal-title font-16 mb-0 text-dark">{{'company_branches.page_title_create' | translate}}</p>
    <button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true" class="font-24 text-muted"><i class="mdi mdi-close"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <form #branchForm (ngSubmit)="onSubmit()" [formGroup]="branchEditForm" autocomplete="off">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="control-label">{{'company_branches.name' | translate}}</label>
                    <input class="form-control"
                           formControlName="name" name="name" type="text">
                    <div *ngIf="submitted && f.name.errors">
                      <p *ngIf="f.name.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'employees.name' | translate}}
                        {{'angular_validation.required' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="control-label">{{'company_branches.addr_line_1' | translate}}</label>
                    <input class="form-control"
                           formControlName="addr_line_1"
                           name="addr_line_1" type="text">
                    <div *ngIf="submitted && f.addr_line_1.errors">
                      <p *ngIf="f.addr_line_1.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'company_branches.addr_line_1' | translate}}
                        {{'angular_validation.required' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="control-label">{{'company_branches.addr_line_2' | translate}}</label>
                    <input class="form-control"
                           formControlName="addr_line_2" name="addr_line_2" type="text">
                    <div *ngIf="submitted && f.addr_line_2.errors">
                      <p *ngIf="f.addr_line_2.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'company_branches.addr_line_2' | translate}}
                        {{'angular_validation.required' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="control-label">{{'company_branches.city' | translate}}</label>
                    <input class="form-control"
                           formControlName="city" name="city" type="text">
                    <div *ngIf="submitted && f.city.errors">
                      <p *ngIf="f.city.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'company_branches.city' | translate}}
                        {{'angular_validation.required' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="control-label">{{'company_branches.postal_code' | translate}}</label>
                    <input class="form-control"
                           formControlName="postal_code" name="postal_code" type="text">
                    <div *ngIf="submitted && f.postal_code.errors">
                      <p *ngIf="f.postal_code.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'company_branches.postal_code' | translate}}
                        {{'angular_validation.required' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="control-label required-label">{{'company_branches.country_code' | translate}}</label>
                    <ng-select [items]="countryStoreService.countries$ | async" bindLabel="name" bindValue="country_code"
                               class="custom" formControlName="country_code" name="role" placeholder="Vyberte zemi">
                    </ng-select>
                    <div *ngIf="submitted && f.country_code.errors">
                      <p *ngIf="f.country_code.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'company_branches.country_code' | translate}}
                        {{'angular_validation.required' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                    <div class="col-6">
                        <label class="control-label mr-2">{{ 'company_branches.is_company' | translate }}</label>
                    </div>
                    <div class="col-6 text-right">
                        <input
                            [checked]="isCompany"
                            aria-controls="collapse"
                            data-switch="success"
                            id="isCompany"
                            type="checkbox"
                        >
                        <label (click)="isCompany = !isCompany">&nbsp;</label>
                    </div>
              </div>

              <div class="row"
                   [(ngbCollapse)]="!isCompany">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label class="control-label">{{'company_branches.in' | translate}}</label>
                        <input class="form-control"
                               formControlName="in" name="in" type="text">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label class="control-label">{{'company_branches.tin' | translate}}</label>
                        <input class="form-control"
                               formControlName="tin" name="tin" type="text">
                    </div>
                </div>
              </div>

              <div class="row">
                    <div class="col-6">
                        <label class="control-label mr-2">{{ 'company_branches.has_signature' | translate }}</label>
                    </div>
                    <div class="col-6 text-right">
                        <input
                            [checked]="hasSignature"
                            aria-controls="collapse"
                            data-switch="success"
                            id="hasSignature"
                            type="checkbox"
                        >
                        <label (click)="hasSignature = !hasSignature">&nbsp;</label>
                    </div>
              </div>

              <div class="row"
                     [(ngbCollapse)]="!hasSignature">
                  <div class="col-12">
                      <ng-container>
                          <app-upload
                              [allowedExtensions]="'.jpg, .jpeg, .png'"
                              [allowTakingPicture]="false"
                              [process$]="processFileQueue$"
                              (addedFile)="addedFile($event)"
                          ></app-upload>
                      </ng-container>

                      <div *ngIf="branchEditForm.value.signature" class="mt-2">
                          <table class="table table-centered mb-0">
                              <tbody>
                                  <tr>
                                      <td class="pl-0 text-truncate">{{ 'company_branches.signature' | translate }}</td>

                                      <td class="pr-0 text-right text-nowrap">
                                          <button (click)="viewSignature()" class="btn btn-link btn-icon btn-sm mr-2" title="{{ 'company_branches.signature' | translate }}" type="button">
                                              <i class="mdi mdi-eye-outline text-muted mdi-24px"></i>
                                          </button>

                                          <a href="{{branch.download_url}}" target="_blank" class="btn btn-link btn-icon btn-sm mr-2">
                                              <i class="mdi mdi-tray-arrow-down text-muted mdi-24px"></i>
                                          </a>

                                          <button (click)="removeFile()" class="btn btn-link btn-icon waves-effect pt-1 pb-0 pl-0 pr-0" type="button">
                                              <i class="mdi mdi-trash-can-outline mdi-24px text-muted"></i>
                                          </button>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>

              <div class="row mt-2">
                <div class="col-12 text-right">
                  <button (click)="deleteBranch()" *ngIf="branch" class="btn btn-link text-muted pr-0 mr-4" type="button">
                    <u>{{ 'company_branches.action_delete' | translate }}</u>
                  </button>
                  <button class="btn btn-success" type="submit">
                    {{'company_branches.action_save' | translate}}
                  </button>
                </div>
              </div>
              <ng-container *ngIf="branch">
                <h5 class="text-dark mt-4">{{'company_branches.assigned_employees' | translate}}</h5>
                <div class="table-responsive">
                  <table class="table table-centered mb-0">
                    <tbody>
                      <tr *ngFor="let e of branch.employees">
                        <td class="pl-0 text-truncate">
                            <app-employee-box-avatar [employee]="e"></app-employee-box-avatar>
                        </td>
                        <td class="text-right">
                          <a *ngIf="e.permissions.view" [routerLink]="['/employee', e.employee_ID, 'detail', 'overview']" class="btn btn-white btn-icon btn-sm mr-1" title="{{ 'employees.action_view' | translate }}">
                            <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-container>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
