<ng-template
  #filterFormOptions
  let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'employees.adjust_filters' | translate }}</span>
      </p>

      <button
        (click)="modal.dismiss()"
        aria-label="Close"
        class="close"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <form
        (ngSubmit)="onSubmitFilter()"
        [formGroup]="filterForm"
        class="filter-form"
      >
          <div class="row">
              <div class="col-12">
                  <div class="form-group">
                      <label class="control-label">
                          <span>{{ 'benefits.active_only' | translate }}</span>
                      </label>

                      <div>
                          <input
                              data-switch="success"
                              formControlName="active_only"
                              id="active_only"
                              name="active_only"
                              type="checkbox"
                          >

                          <label for="active_only">&nbsp;</label>
                      </div>
                  </div>
              </div>
          </div>

        <div class="row">
          <div class="col-12 text-right">
            <button class="btn btn-primary">
              <span>{{ 'employees.action_filter' | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template
    #assignModal
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'benefits.assign_employee' | translate }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
            >
          <span
              aria-hidden="true"
              class="font-24 text-muted"
          >
            <i class="mdi mdi-close"></i>
          </span>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-0 shadow-none">
                        <div class="card-body p-0">
                            <form
                                (ngSubmit)="onSubmitAssign()"
                                [formGroup]="benefitAssignmentForm"
                            >
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label class="control-label">
                                                <span>{{ 'benefits.employee' | translate }}</span>
                                            </label>

                                            <ng-container
                                                *ngIf="selectedEmployee$ | async; let selectedEmployee; else employeeIdEnabledTemplate"
                                            >
                                                <div class="form-control">{{ selectedEmployee.fullname }}</div>
                                            </ng-container>

                                            <ng-template #employeeIdEnabledTemplate>
                                                <ng-select
                                                    [items]="allEmployees"
                                                    bindLabel="fullname"
                                                    bindValue="employee_ID"
                                                    class="custom"
                                                    formControlName="employee_ID"
                                                    name="employee_ID"
                                                ></ng-select>

                                                <div *ngIf="submitted && f.employee_ID.errors">
                                                    <p
                                                        *ngIf="f.employee_ID.errors.required"
                                                        class="text-danger validation-text"
                                                    >
                                                        <span>{{ 'angular_validation.field' | translate }} </span>
                                                        <span>{{ 'benefits.employee' | translate }} </span>
                                                        <span>{{ 'angular_validation.required' | translate }}</span>
                                                    </p>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label class="control-label">
                                                <span>{{ 'benefits.handover_date' | translate }}</span>
                                            </label>

                                            <input (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                                   [locale]="locale$ | async"
                                                   allowInput="true"
                                                   altFormat="d.m.Y"
                                                   altInput="true"
                                                   class="form-control"
                                                   dateFormat="Y-m-d"
                                                   formControlName="handover_date"
                                                   mwlFlatpickr
                                                   name="handover_date"
                                                   type="text"
                                            >

                                            <div *ngIf="submitted && f.handover_date.errors">
                                                <p
                                                    *ngIf="f.handover_date.errors.required"
                                                    class="text-danger validation-text"
                                                >
                                                    <span>{{ 'angular_validation.field' | translate }} </span>
                                                    <span>{{ 'benefits.handover_date' | translate }} </span>
                                                    <span>{{ 'angular_validation.required' | translate }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label class="control-label">
                                                <span>{{ 'benefits.removal_date' | translate }}</span>
                                            </label>

                                            <input (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                                   [locale]="locale$ | async"
                                                   allowInput="true"
                                                   altFormat="d.m.Y"
                                                   altInput="true"
                                                   class="form-control"
                                                   dateFormat="Y-m-d"
                                                   formControlName="removal_date"
                                                   mwlFlatpickr
                                                   name="removal_date"
                                                   type="text"
                                            >

                                            <div *ngIf="submitted && f.removal_date.errors">
                                                <p
                                                    *ngIf="f.removal_date.errors.required"
                                                    class="text-danger validation-text"
                                                >
                                                    <span>{{ 'angular_validation.field' | translate }} </span>
                                                    <span>{{ 'benefits.removal_date' | translate }} </span>
                                                    <span>{{ 'angular_validation.required' | translate }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label class="control-label">
                                                <span>{{ 'benefits.note' | translate }}</span>
                                            </label>

                                            <input
                                                class="form-control"
                                                formControlName="note"
                                                name="note"
                                                type="text"
                                            >
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-12 text-right">
                                        <button class="btn btn-primary" submitButton>
                                            <span>{{ 'benefits.action_save' | translate }}</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="row">
  <div class="col-12">
    <div class="card shadow">
        <app-help name="benefit.detail.employees"></app-help>

      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive overflow-visible">
              <app-data-table
                  (searchChanged)="searchChanged($event)"
                  (sortChanged)="sortChanged($event)"
                    [disableInPlaceFiltering]="true"
                    [disableInPlaceSorting]="true"
                    [loading]="loading"
                    [rows]="rows"
                    [search]="search"
                    [sort]="sort"
                    [trackByFn]="trackByFn"
              >
                <ng-template
                  #headerButton
                >
                  <button  (click)="openFilterFormOptions()"
                           class="btn btn-primary btn-icon mx-sm-2"
                           type="button"
                           [formGroup]="filterForm"
                           [defaultValue]="{ active_only: false }"
                  >
                    <i class="mdi mdi-filter-outline"></i>
                  </button>
                    <button
                        (click)="openAssignModal(assignModal, null)"
                        [createButton]="'benefits.assign_employee' | translate"
                    ></button>
                </ng-template>

                <ng-template #headerRow>
                    <th>{{ 'employees.name' | translate }}</th>

                    <th class="text-nowrap">{{ 'benefits.handover_date' | translate }}</th>

                    <th class="text-nowrap">{{ 'benefits.removal_date' | translate }}</th>

                    <th class="text-right">{{ 'benefits.table_action' | translate }}</th>
                </ng-template>

                <ng-template
                  #dataRow
                  let-row
                >
                    <td appDataTableDataCell class="">
                        <app-employee-box-avatar [employee]="row"></app-employee-box-avatar>
                    </td>

                    <td>{{ row.handover_date | date: 'dd.MM.yyyy' }}</td>

                    <td>
                        <span *ngIf="row.removal_date">{{ row.removal_date | date: 'dd.MM.yyyy' }}</span>

                        <button
                            (click)="openAssignModal(assignModal, row)"
                            *ngIf="!row.removal_date"
                            class="btn btn-link p-0"
                            type="button"
                        >{{ 'benefits.action_return' | translate }}</button>
                    </td>

                    <td class="text-right text-nowrap">
                        <button
                            (click)="openAssignModal(assignModal, row)"
                            class="btn btn-white btn-icon btn-sm mr-1"
                            title="{{ 'benefits.action_edit' | translate }}"
                        >
                            <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                        </button>

                        <button
                            (click)="unassignEmployeeFromBenefit(row)"
                            class="btn btn-white btn-icon btn-sm"
                            title="{{ 'benefits.action_unassign' | translate }}"
                        >
                            <i class="mdi mdi-close mdi-24px text-muted"></i>
                        </button>
                    </td>
                </ng-template>
              </app-data-table>
            </div>
          </div>
        </div>

        <div class="row mt-3 flex-column-reverse flex-sm-row">
          <div class="col-12 col-sm-2">
            <app-items-per-page-select
              (itemsPerPageChanged)="itemsPerPageChanged($event)"
              [itemsPerPage]="itemsPerPage"
              [total]="itemsTotal"
            ></app-items-per-page-select>
          </div>

          <div class="col-12 col-sm-10">
            <app-pagination
              (pageChanged)="pageChanged($event)"
              [itemsPerPage]="itemsPerPage"
              [page]="page"
              [total]="itemsTotal"
            ></app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
