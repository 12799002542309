<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">
      <span>{{ 'files.esignature' | translate }}</span>
    </p>

    <button aria-label="Close" class="close" (click)="activeModal.dismiss()">
      <span aria-hidden="true" class="font-24 text-muted">
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-12">
                {{ 'files.esignature_sign_warning' | translate }}
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12 d-flex justify-content-end">
                <button class="btn btn-primary" (click)="activeModal.close()">
                  <span>{{ 'files.action_sign' | translate }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
