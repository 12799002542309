import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { EntityUpdateRequest } from 'src/app/entity_update_request/state/entity_update_request.model';
import { EntityUpdateRequestService } from 'src/app/entity_update_request/state/entity_update_request.service';
import { FlatpickrHelper } from 'src/app/shared/common/FlatpickrHelper';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';

@Component({
    selector: 'app-entity-update-request-modal',
    templateUrl: './entity_update_request-modal.component.html',
    styleUrls: ['./entity_update_request-modal.component.css']
})
export class EntityUpdateRequestModalComponent implements OnInit {

    public canEdit = false;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public newValue: any;
    public entityUpdateRequestForm: UntypedFormGroup;
    public submitted = false;

    public constructor(
        public activeModal: NgbActiveModal,
        private _entityUpdateRequestService: EntityUpdateRequestService,
        private _fb: UntypedFormBuilder,
        private _authService: AuthenticationService,
        public fpHelper: FlatpickrHelper,
        private _flatpickrLocale: FlatpickrLocaleService,
    ) { }

    public _entityUpdateRequest: EntityUpdateRequest;

    public get entityUpdateRequest(): EntityUpdateRequest {
        return this._entityUpdateRequest;
    }

    public set entityUpdateRequest(entityUpdateRequest: EntityUpdateRequest) {
        this._entityUpdateRequest = entityUpdateRequest;
    }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.entityUpdateRequestForm.controls;
    }

    public ngOnInit(): void {
        this._init();
    }

    public onSubmit(f: UntypedFormGroup, state: string): void {
        this.submitted = true;

        const formValue = f.value;
        const state_description = formValue.state_description;

        this._entityUpdateRequestService.setState(state, state_description, this.entityUpdateRequest)
            .then(() => this.activeModal.close(formValue));
    }

    private _init(): void {
        this.entityUpdateRequestForm = this._fb.group({
            state_description: ['', Validators.required],
        });

        this.canEdit = this._authService.checkPermission('employee.edit');
    }
}
