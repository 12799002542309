// type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];

// type Join<K, P> = K extends string | number ? P extends string | number ? `${K}${'' extends P ? '' : '.'}${P}` : never : never;

// type NestedPaths<T, D extends number = 3> = [D] extends [never] ?
//   never :
//   T extends object ?
//   { [K in keyof T]-?: K extends string | number ?
//     `${K}` | Join<K, NestedPaths<T[K], Prev[D]>> :
//     never
//   }[keyof T] :
//   '';

type SortDirectionASCType = 'ASC';
type SortDirectionDESCType = 'DESC';

type SortDirectionType = SortDirectionASCType | SortDirectionDESCType;

const SORT_ASC: SortDirectionASCType = 'ASC';
const SORT_DESC: SortDirectionDESCType = 'DESC';

type DataType = 'localizedCurrency' | 'date' | 'datetime' | 'percent';

type TrackByFnType<T> = (index: number, item: T) => number | string;

export {
  DataType,

  // NestedPaths,

  SortDirectionType,
  SORT_ASC,
  SORT_DESC,

  TrackByFnType
};
