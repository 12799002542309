import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgForm, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ImportService } from 'src/app/import/state/import.service';
import { Employee } from '../../../../employee/models/Employee';
import { EmployeeService } from '../../../../employee/services/employee.service';
import { ApiHelper } from '../../../../shared/common/ApiHelper';
import { SwalHelper } from '../../../../shared/common/SwalHelper';
import { FilePreviewModalComponent } from '../../partials/file-preview-modal/file-preview-modal.component';

@Component({
    selector: 'app-import-overview',
    templateUrl: './import-payrolls.component.html',
    styleUrls: ['./import-payrolls.component.css']
})
export class ImportPayrollsComponent implements OnInit {

    public selectRange = {months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'], years: []};
    public today = new Date();
    public canCreate$: Observable<boolean>;
    public employees$: Observable<Array<Employee>>;
    public processFileQueue$: Observable<void>;
    public clearFileQueue$: Observable<void>;
    public employees: Array<Employee>;
    public unselectedEmployees: Array<Employee>;
    public payrollFileAdditionalData: object;
    public employeePayrolls: Array<any>;
    public payrollsFormValue: any;
    public defaultEmployees = {};
    public defaultDate: { month: string, year: string };
    public employeeDuplicates = [];
    public filesSize = 0;
    public filesCount = 0;
    public isCollapsed = true;
    public submitted = false;
    public uploadingFiles = false;

    private _clearFileQueue$ = new Subject<void>();
    private _processFileQueue$ = new Subject<void>();

    public constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _authService: AuthenticationService,
        private _employeeService: EmployeeService,
        private _importService: ImportService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _modalService: NgbModal,
        private _formBuilder: UntypedFormBuilder,
        private _apiHelper: ApiHelper,
        private _swalHelper: SwalHelper
    ) { }

    public ngOnInit(): void {
        this.today.setMonth(this.today.getMonth() - 1);
        const todayMinus5Years = this.today.getFullYear() - 5;
        for (let i = 0; i < 11; i++) {
            this.selectRange.years[i] = (todayMinus5Years + i).toString();
        }

        this.canCreate$ = this._authService.hasPermissionTo('employeePayroll.create')
            .pipe(map(perm => perm.can));

        this.employees$ = this._employeeService.getAllEmployees(false, 'minimal')
            .pipe(
                tap(employees => this.employees = employees),
                shareReplay()
            );

        this.setDefaultFormData();
        this.payrollFileAdditionalData = this._createObjectWithDateKeyValuePairAsString();

        this.clearFileQueue$ = this._clearFileQueue$;
        this.processFileQueue$ = this._processFileQueue$;
    }

    beforeUnloadFunction(event): Event {
        event.preventDefault();
        event.returnValue = 'Unsaved modifications';
        return event;
    }

    public checkDuplicates(form?: NgForm): void {
        this.employeeDuplicates = [];
        const selectedEmployees = [];

        if (form) {
            const values = Object.values(form.value.payrolls);
            values.forEach((value: { employee_ID, checked }) => {
                if (!selectedEmployees.includes(value.employee_ID) && value.checked && value.employee_ID) {
                    selectedEmployees.push(value.employee_ID);
                } else if (!this.employeeDuplicates.includes(value.employee_ID) && value.checked && value.employee_ID) {
                    this.employeeDuplicates.push(value.employee_ID);
                }
            });
        } else {
            const values = Object.values(this.defaultEmployees);
            values.forEach((value: number) => {
                if (!selectedEmployees.includes(value) && value) {
                    selectedEmployees.push(value);
                } else if (!this.employeeDuplicates.includes(value) && value) {
                    this.employeeDuplicates.push(value);
                }
            });
        }

        this.getUnselectedEmployees(selectedEmployees);
    }

    public setDefaultFormData(): void {
        this.defaultDate = {
            month: ('0' + (this.today.getMonth() + 1).toString()).slice(-2),
            year: this.today.getFullYear().toString()
        };
    }

    public async onSubmitPayrolls(form: NgForm): Promise<void> {
        this.submitted = true;
        if (!form.valid) {
            return;
        }

        this.payrollsFormValue = form.value;
        const importConfirmed = await this._swalHelper.showConfirmChangeDialog();

        if (importConfirmed) {
            this.uploadingFiles = true;
            this.payrollFileAdditionalData = this._createObjectWithDateKeyValuePairAsString();
            this._changeDetectorRef.detectChanges();
            this._processFileQueue$.next();
            window.addEventListener('beforeunload', this.beforeUnloadFunction);
            this.setDefaultFormData();
        }
    }

    public successMultiple(): void {
        this.uploadingFiles = false;
        this.employeePayrolls = null;
        const response = {message: 'imports.payrolls.action_payrolls_import_successful'};
        this._apiHelper.handleSuccessResponse(response);
        window.removeEventListener('beforeunload', this.beforeUnloadFunction);
    }

    public addedFiles(files): void {
        this.employeePayrolls = files;

        this.presetEmployees();
        this.checkDuplicates();
    }

    public getFilesStats(form: NgForm): void {
        const checkedKeys = Object.keys(form.value.payrolls);
        const checkedArray = {};
        checkedKeys.forEach(key => {
            if (form.value.payrolls[key].checked) {
                checkedArray[key] = true;
            }
        });

        this.filesCount = Object.keys(checkedArray).length;

        const total = Array.from(this.employeePayrolls).reduce((accumulator, file) => {
            if (!checkedArray[file.upload?.uuid]) {
                return accumulator;
            }
            return accumulator + file.size;
        }, 0);

        this.filesSize = total / 1000 / 1000;

        setTimeout(() => {
            this.checkDuplicates(form);
        }, 10);
    }

    public getUnselectedEmployees(selectedEmployees: Array<number>): void {
        this.unselectedEmployees = [];
        this.unselectedEmployees = this.employees.filter(e => !selectedEmployees.some((e2ID) => e.employee_ID === e2ID));
    }

    public presetEmployees(): void {
        this.defaultEmployees = {};
        this.filesSize = 0;

        this.employeePayrolls.forEach(payroll => {
            this.filesSize += payroll.size;
            // replacing special characters and putting string to lower
            const payrollName = payroll.name?.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

            for (const employee of this.employees) {
                const name = employee.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
                const surname = employee.surname.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

                if (payrollName.includes(name) && payrollName.includes(surname)) {
                    this.defaultEmployees[payroll.upload?.uuid] = employee.employee_ID;
                    break; // if employee is found, end loop
                } else {
                    this.defaultEmployees[payroll.upload?.uuid] = null;
                }
            }
        });

        this.filesCount = this.employeePayrolls.length;
        this.filesSize = this.filesSize / 1000 / 1000;
    }

    public openFilePreviewModal(file): void {
        const modalRef = this._modalService.open(FilePreviewModalComponent, {centered: true, size: 'xl'});
        modalRef.componentInstance.file = file;
    }

    private _createObjectWithDateKeyValuePairAsString(): object {
        return {
            data: {
                month: this.payrollsFormValue?.date?.month,
                year: this.payrollsFormValue?.date?.year
            },
            formValue: this.payrollsFormValue?.payrolls
        };
    }
}
