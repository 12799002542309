import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Routes } from '../shared/models/Route';
import { ActiveGrowths } from './components/sections/active-growth/active-growth.component';
import { GrowthListComponent } from './components/sections/growth-list/growth-list.component';
import { GrowthOverviewComponent } from './components/sections/growth-overview/growth-overview.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },

    {
        path: 'list',
        component: GrowthListComponent,
        data: {
            help: 'main_processes.employees_growth_management',
            title: 'growths.page_title_index'
        }
    },

    {
        path: 'overview/:growthID',
        component: GrowthOverviewComponent,
        data: {
            help: 'main_processes.employees_growth_management',
            title: 'growths.page_title_view'
        }
    },
    {
        path: 'active_growths',
        component: ActiveGrowths,
        data: {
            help: 'main_processes.employees_growth_management',
            title: 'growths.page_title_active'
        }
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [
        RouterModule,
    ]
})
export class GrowthRoutingModule {}
