export function partition<T>(array: Array<T>, isValid: (item: T) => boolean) {
    return array.reduce(([pass, fail], elem) => {
        return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    }, [[], []]);
}

export function range(size: number, startAt = 0): Array<number> {
    const arr = [];
    for (let i = startAt; i < size + startAt; i++) {
        arr.push(i);
    }
    return arr;
}

export function sortArrayOfObjectsByName<T extends { name: number | string }>(a: T, b: T): -1 | 0 | 1 {
    if (a.name > b.name) {
        return 1;
    } else if (a.name < b.name) {
        return -1;
    } else {
        return 0;
    }
}
