import { Injectable } from '@angular/core';

@Injectable()
export class FormatHelper {
    public objectIsEmpty(obj: any) {
        return Object.keys(obj).length === 0;
    }

    public jsonCopy(src: any) {
        return JSON.parse(JSON.stringify(src));
    }

    public uuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            // tslint:disable:no-bitwise
          const r = Math.random() * 16 | 0;
          const v = c === 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }
    }
