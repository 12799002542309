<ng-template
        #inputPdfPassword
        let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'files.enter_password_title' | translate }}</span>
      </p>

      <button
              aria-label="Close"
              class="close"
              type="button"
              (click)="modal.dismiss()"
      >
              <span
                      aria-hidden="true"
                      class="font-24 text-muted"
              >
              <i class="mdi mdi-close"></i>
              </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <form
                      autocomplete="off"
                      [formGroup]="pdfPasswordForm"
                      (ngSubmit)="onSubmitPassword(modal)"
              >
                <div class="row mb-2">
                  <div *ngIf="incorrectPasswordMessage" class="col-12 text-danger">
                    {{ 'files.incorrect_password_message_modal' | translate }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="password" class="control-label"> {{ 'files.password' | translate }} </label>

                      <input id="password"
                             formControlName="password"
                             class="form-control"
                             name="password"
                             type="password"
                      >

                      <div *ngIf="submitted && passwordForm.password.errors">
                        <p
                                *ngIf="passwordForm.password.errors.required"
                                class="text-danger validation-text"
                        >
                          <span> {{ 'angular_validation.field' | translate }} </span>
                          <span> {{ 'files.password' | translate }} </span>
                          <span> {{ 'angular_validation.required' | translate }} </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12 text-left d-flex justify-content-end">
                    <button class="btn btn-success">
                      <span>{{ 'files.action_enter_password' | translate }}</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!--</editor-fold desc="MODALS">-->

<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">{{file?.name}}</p>
    <button (click)="activeModal.dismiss()" type="button" class="close ml-0" aria-label="Close">
      <span class="font-24 text-muted" aria-hidden="true"><i class="mdi mdi-close"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div *ngIf="isEncrypted && !incorrectPasswordMessage && !passwordSet" class="alert alert-danger mb-3 w-100 shadow">
          {{ 'files.password_required_message' | translate }}
          <u (click)="openPdfPasswordModal()"> {{ 'files.incorrect_password_message_action' | translate }}</u>.
        </div>
        <div *ngIf="incorrectPasswordMessage" class="alert alert-danger mb-3 w-100 shadow">
          {{ 'files.incorrect_password_message' | translate }}
          <u (click)="openPdfPasswordModal()"> {{ 'files.incorrect_password_message_action' | translate }}</u>.
        </div>
        <div class="card mb-0 shadow-none">
          <div class="card-body">
            <ng-container *ngIf="file.type.includes('pdf'); else imageTemplate">
              <div class="pdfViewerWrapper" [ngClass]="{'isLoaded': filePdfIsLoaded}">
                <pdf-viewer (error)="onError($event)" [src]="pdfSrc$ | async" [original-size]="true" [render-text]="true" style="min-height: 600px;" (after-load-complete)="afterLoadComplete($event)"></pdf-viewer>
              </div>
            </ng-container>
            <ng-template #imageTemplate>
              <div class="d-flex justify-content-center" style="border: 1px #eee solid;">
                <ng-container *ngIf="file.type.includes('image'); else unknownMimeTemplate">
                  <img class="image-detail mw-100"
                       src="{{ file.dataURL }}"
                       alt="{{ file.name }}"
                  />
                </ng-container>
              </div>
            </ng-template>
            <ng-template #unknownMimeTemplate>
              <span>{{ 'files.unsupported_content' | translate }}: {{ file.type }}</span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

