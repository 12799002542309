import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Receivable } from 'src/app/receivable/state/receivable.model';
import { ReceivableService } from 'src/app/receivable/state/receivable.service';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';

@Component({
    selector: 'app-create-edit-employee-receivable-modal',
    templateUrl: './create-edit-employee-receivable-modal.component.html',
    styleUrls: ['./create-edit-employee-receivable-modal.component.css']
})
export class CreateEditEmployeeReceivableModalComponent implements OnInit {
    public submitted = false;
    public receivableEditForm: UntypedFormGroup;
    public receivable: Receivable;
    public employeeID: number;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;

    constructor(
        public activeModal: NgbActiveModal,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _receivableService: ReceivableService
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.receivableEditForm.controls;
    }

    public ngOnInit(): void {
        this.receivableEditForm = this._fb.group({
            name: ['', Validators.required],
            from: ['', Validators.required],
            to: [''],
            amount: [0, Validators.required],
            paid_amount: [0, Validators.required],
            type: ['EXECUTION_PRIORITY', Validators.required],
            state: ['RUNNING', Validators.required],
            bank_account: ['', Validators.required],
            variable_symbol: [''],
            deposit: [false],
            description: [''],
            recipient_info: [''],
        });

        if (this.receivable) {
            this.receivableEditForm.patchValue({
                name: this.receivable.name,
                from: this.receivable.from,
                to: this.receivable.to,
                amount: this.receivable.amount,
                paid_amount: this.receivable.paid_amount,
                type: this.receivable.type,
                state: this.receivable.state,
                bank_account: this.receivable.bank_account,
                variable_symbol: this.receivable.variable_symbol,
                deposit: this.receivable.deposit,
                description: this.receivable.description,
                recipient_info: this.receivable.recipient_info
            });
        }
    }

    public onSubmit(): void {
        this.submitted = true;
        if (!this.receivableEditForm.valid) {
            return;
        }

        const receivable = this.receivableEditForm.value;
        if (this.receivable) {
            this._receivableService.saveReceivable(receivable, this.employeeID, this.activeModal, this.receivable.employee_receivable_ID);
        } else {
            this._receivableService.saveReceivable(receivable, this.employeeID, this.activeModal);
        }
    }

    public deleteReceivable(): void {
        this._receivableService.deleteReceivable(this.employeeID, this.receivable.employee_receivable_ID, this.activeModal);
    }
}
