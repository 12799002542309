import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { Currency } from '../models/Currency';
import { CurrencyService } from './currency.service';

@Injectable({
    providedIn: 'root'
})
export class CurrencyStoreService {
    private readonly currenciesSubject = new BehaviorSubject<Currency[]>([]);
    public readonly currencies$ = this.currenciesSubject.asObservable();

    constructor(
        private currencyService: CurrencyService,
        private apiHelper: ApiHelper,
        private swalHelper: SwalHelper
    ) {
        void this.fetchAll();
    }

    public get currencies(): Currency[] {
        return this.currenciesSubject.getValue();
    }

    public set currencies(val: Currency[]) {
        this.currenciesSubject.next(val);
    }

    public get primaryCurrency() {
        return this.currenciesSubject.getValue().find(currency => currency.is_primary);
    }

    public async addCurrency(val: Currency) {
        const tmpBranch: Currency = {
            code: val.code,
            label: val.label,
            thousands_separator: val.thousands_separator,
            decimal_separator: val.decimal_separator,
            decimal_number: val.decimal_number,
            prefix: val.prefix,
            suffix: val.suffix,
            is_primary: val.is_primary,
            name: val.name,
            example_formatted: val.example_formatted
        };
        this.currencies = [
            ...this.currencies,
            tmpBranch
        ];

        try {
            const currency: any = await this.currencyService
                .saveCurrency(val).pipe(map((response: any) => response.currency))
                .toPromise();

            const index = this.currencies.indexOf(this.currencies.find(t => t.code === val.code));
            this.currencies[index] = {
                ...currency
            };

            this.currencies = [...this.currencies];

            return true;
        } catch (e) {
            this.removeCurrency(val.code, false);
            return false;
        }

    }

    public async updateBranch(code: string, val: Currency) {
        const currency = this.currencies.find(curr => curr.code === code);
        if (currency) {
            // optimistic update
            const index = this.currencies.indexOf(currency);

            this.currencies[index] = {
                code,
                ...val,
            };

            this.currencies = [...this.currencies];

            try {
                await this.currencyService
                    .updateCurrency(code, val)
                    .toPromise();

            } catch (e) {
                this.apiHelper.handleErrorResponse(e);
                this.currencies[index] = {
                    ...currency,
                };
            }
        }
    }

    public async removeCurrency(code: string, serverRemove = true) {

        let deleteConfirmed = false;

        if (serverRemove) {
            deleteConfirmed = await this.swalHelper.showConfirmDeleteDialog();
        }

        if (deleteConfirmed || !serverRemove) {
            const branch = this.currencies.find(t => t.code === code);
            this.currencies = this.currencies.filter(currency => currency.code !== code);
            if (serverRemove) {
                try {
                    await this.currencyService.deleteCurrency(code).toPromise();
                } catch (e) {
                    this.apiHelper.handleErrorResponse(e);
                    this.currencies = [...this.currencies, branch];
                }

            }
        }

    }

    public async fetchAll() {
        this.currencies = await this.currencyService.getAllCurrencies().toPromise();
    }
}
