import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FileService } from 'src/app/shared/services/file.service';
import { Location } from '@angular/common';
import { File } from 'src/app/employee/models/File';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignaturePadModalComponent } from '../../partials/signature-pad-modal/signature-pad-modal.component';
import {CompanyService} from '../../../../setting/services/company.service';
import {SignatureEsignModalComponent} from '../../partials/signature-esign-modal/signature-esign-modal.component';

@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.css']
})
export class DocumentDetailComponent implements OnInit {
  @Input() public allowedDocumentSignature: boolean;
  @Output() public refetch = new EventEmitter<string>();

  public fileHash: string;
  public encodedFile: ArrayBuffer;
  public file: File;

  constructor(
    private fileService: FileService,
    private location: Location,
    private loaderService: NgxUiLoaderService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private _companyService: CompanyService
  ) { }

  ngOnInit(): void {
    if (this.fileHash) {
      this.getFile();
    }
  }

  getFile() {
    this.fileService.getFileByHash(this.fileHash).subscribe((file: File) => {
      this.file = file;
      if (this.file.mime_type.includes('pdf')) {
        this.encodedFile = this.fileService.base64ToArrayBuffer(file.fileBase64);
      }
    });
  }

  download() {
    this.loaderService.start();
    this.fileService.downloadFile(this.fileHash);
  }

  openSignModal() {
    const modalRef = this.modalService.open(SignaturePadModalComponent, {centered: true});
    modalRef.componentInstance.mode = 'SIGN';
    if (this.file.signature_saved) {
      modalRef.componentInstance.showSaveSignatureControl = false;
    }
    modalRef.result.then(
      (result) => {
        const promise = this.fileService.signDocument(
          this.file.document_ID,
          result.signature,
          result.save_signature,
          result.use_saved_signature,
          result.location
          );
        promise.then(() => {
          this.getFile();
          this.refetch.emit('refetch');
        });
      });
  }

  openEsignModal() {
    const modalRef = this.modalService.open(SignatureEsignModalComponent, {centered: true});
    if (this.file.signature_saved) {
      modalRef.componentInstance.showSaveSignatureControl = false;
    }
    modalRef.result.then(() => {
        const promise = this.fileService.signFile(this.file.file_ID, false);
        promise.then(() => {
          this.getFile();
          this.refetch.emit('refetch');
        });
      });
  }

  deleteFileSignature() {
    const promise = this.fileService.removeFileSignature(this.file.file_ID);
    promise.then(() => {
      this.getFile();
      this.refetch.emit('refetch');
    });
  }
}
