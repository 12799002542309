import { Injectable } from '@angular/core';
import { SummernoteOptions } from 'ngx-summernote/lib/summernote-options';

@Injectable()
export class SummernoteHelper {
    public getSummernoteConfig(heightInPx = null): SummernoteOptions {
        return {
            height: (heightInPx ? heightInPx : 200),
            toolbar: [
                ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
                ['para', ['ul', 'ol']],
                ['insert', ['link']]
            ],
        };
    }
}

