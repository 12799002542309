import { Component, Input, OnInit } from '@angular/core';
import { Employee } from '../../../../employee/models/Employee';

@Component({
    selector: 'app-employee-box-avatar',
    templateUrl: './employee-box-avatar.component.html',
    styleUrls: ['./employee-box-avatar.component.css']
})
export class EmployeeBoxAvatarComponent implements OnInit {

    @Input() public employee: Employee;
    @Input() public employeeID: number;
    @Input() public avatar: string;
    @Input() public fullname: string;
    @Input() public abbreviation: string;
    @Input() public position: string;
    @Input() public isArchived: boolean;
    @Input() public canView: boolean;
    @Input() public employeeType: string;
    @Input() public avatarWidth: number;
    @Input() public sizeRatio = 1;
    @Input() public states: {
        joining: boolean;
        checklist: boolean;
        change_request: boolean;
        position_end: boolean;
        testing_end: boolean;
        termination_period: boolean;
    };

    constructor() { }

    public ngOnInit(): void {
        if (this.employee && (!this.avatar && !this.fullname && !this.abbreviation && !this.position && !this.employeeID && !this.canView && !this.employeeType && !this.states)) {
            this.avatar = this.employee.avatar;
            this.fullname = this.employee.fullname;
            this.abbreviation = this.employee.abbreviation;
            this.position = this.employee.position;
            this.isArchived = this.employee.is_archived;
            this.employeeID = this.employee.employee_ID;
            this.canView = this.employee.permissions?.view;
            this.employeeType = this.employee.employee_type;
            this.states = this.employee.states;
        }
    }
}
