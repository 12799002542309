import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SubmitButtonInterceptor implements HttpInterceptor {

    public event: CustomEvent;
    public button: HTMLButtonElement;
    public previousText: string;
    public url: string;

    public constructor() {
        document.addEventListener('submitButtonClicked', (event: CustomEvent) => {
            this.event = event;
            // check if the request is call immediately after the button click event
            setTimeout(() => {
                this.event = null;
            });
        });
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.event && (req.method === 'POST' || req.method === 'DELETE')) {
            this.button = this.event.detail.button as HTMLButtonElement;
            this.previousText = $(this.button).text();
            this.url = req.url;
            $(this.button).prop('disabled', true);
            $(this.button).text('');
            $(this.button).append('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>');
            this.event = null;
        }

        return next.handle(req)
            .pipe(
                map((response): any => {
                    if (response instanceof HttpResponse) {
                        this.resetButton(response);
                    }

                    return response;
                }),
                catchError(error => {
                    if (error instanceof HttpErrorResponse) {
                        this.resetButton(error);
                    }
                    return throwError(error);
                })
            );
    }

    public resetButton(ressponseObj: HttpErrorResponse | HttpResponse<any>): void {
        if (this.button && this.url === ressponseObj.url) {
            $(this.button).prop('disabled', false);
            $(this.button).text(this.previousText);
            this.button = null;
            this.previousText = null;
        }
    }
}
