import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { EmployeeDatesForDateTimeExport } from 'src/app/employee/models/EmployeeDatesForDateTimesExport';
import { AttendanceService } from 'src/app/employee/services/attendance.service';
import { FatherOfListComponent, Sort, SORT_DESC } from 'src/app/ui';

@Component({
    selector: 'app-employee-attendance-documents',
    templateUrl: './employee-attendance-documents.component.html',
    styleUrls: ['./employee-attendance-documents.component.css']
})
export class EmployeeAttendanceDocumentsComponent extends FatherOfListComponent<EmployeeDatesForDateTimeExport> implements OnInit {
    public sort: Sort<EmployeeDatesForDateTimeExport> = {
        column: 'date',
        direction: SORT_DESC
    };

    private _fetchExportDates$ = new ReplaySubject<void>(1);

    public constructor(
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _ngZone: NgZone,
        private _attendanceService: AttendanceService
    ) {
        super(_ngZone, _changeDetectorRef);
    }

    public ngOnInit(): void {
        this._rows$ = this._fetchExportDates$
            .pipe(
                tap(() => this._loading$.next(true)),
                switchMap(() => this._attendanceService.getDatesForDateTimesExport()),
                tap(() => this._loading$.next(false)),
                shareReplay()
            );

        this._init();

        this._fetchListData();
    }

    public searchChanged(search: string): void {
        this.search = search;
    }

    public sortChanged(sort: Sort<EmployeeDatesForDateTimeExport>): void {
        this.sort = sort;
    }

    public trackByFn(index: number, dates: EmployeeDatesForDateTimeExport): string {
        return dates.name;
    }

    protected _fetchListData(): void {
        this._fetchExportDates$.next();
    }
}
