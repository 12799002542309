<div *ngIf="checkPermission('employeeSalary') && (availableWidgets$ | async)?.employee?.['salaries-payrolls']?.salary" class="row">
  <div class="col-lg-12">
    <app-employee-salaries
      (refetchSalaries)="refreshEmployeeSalaries()"
      *ngIf="employeeSalaries$"
      [employeeID]="employeeID"
      [employee]="employee$ | async"
      [salaries$]="employeeSalaries$"
    ></app-employee-salaries>
  </div>
</div>

<div *ngIf="checkPermission('employeeBonus') && (availableWidgets$ | async)?.employee?.['salaries-payrolls']?.bonus" class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help name="employee.detail.bonus"></app-help>

      <div class="card-header py-3">
        <div class="row">
          <div class="col-sm-6 text-left my-auto">
            <p class="modal-title font-16 pt-2 pb-2 text-dark">
              <span>{{ 'employees_bonuses.page_title_index' | translate }}</span>
            </p>
          </div>

          <div
            *ngIf="canEditBonus$ | async"
            class="col-sm-6 text-right"
          >
            <button
              (click)="openBonusEditModal(null)"
              [createButton]="'employees_bonuses.action_add' | translate"
            ></button>
          </div>
        </div>
      </div>

      <div class="card-body px-4">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <app-data-table
                [columnsToFilterIn]="['amount', 'date', 'name']"
                [rows]="bonuses$ | async"
                [trackByFn]="trackByBonus"
              >
                <ng-template #headerRow>
                  <th
                    [disableSort]="true"
                    appDataTableHeaderCell
                  >{{ 'employees_bonuses.name' | translate }}</th>

                  <th
                    [disableSort]="true"
                    appDataTableHeaderCell
                  >{{ 'employees_bonuses.amount' | translate }}</th>

                  <th
                    [disableSort]="true"
                    appDataTableHeaderCell
                  >{{ 'employees_bonuses.date' | translate }}</th>

                  <th
                    [disableSort]="true"
                    appDataTableHeaderCell
                    class="text-right"
                  >{{ 'employees_bonuses.table_action' | translate }}</th>
                </ng-template>

                <ng-template
                  #dataRow
                  let-row
                >
                  <td appDataTableDataCell>{{ row.name }}</td>

                  <td appDataTableDataCell>
                    <span [ngClass]="{'hidden-sensitive-value': hiddenInputs}">{{ row.amount | localizedCurrency }}</span>
                  </td>

                  <td appDataTableDataCell>
                    <span>{{ row.date | date: 'dd.MM.yyyy' }}</span>
                  </td>

                  <td
                    appDataTableDataCell
                    class="text-right"
                  >
                    <a
                      (click)="openBonusEditModal(row)"
                      class="btn btn-white btn-icon btn-sm mr-1"
                      title="{{ 'employees.action_view' | translate }}"
                    >
                      <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                    </a>
                  </td>
                </ng-template>
              </app-data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help name="employee.detail.receivable.list"></app-help>

      <div class="card-header py-3">
        <div class="row">
          <div class="col-6 col-sm-6 col-md-7">
            <p class="modal-title font-16 text-dark pt-2 pb-2">
              <span>{{ 'employees_receivables.page_title_index' | translate }}</span>
            </p>
          </div>

          <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
            <button
              *ngIf="canCreateOrEditReceivable$ | async"
              [createButton]="'employees_receivables.action_create' | translate"
              (click)="openReceivableEditModal(null)"
            ></button>
          </div>
        </div>
      </div>

      <div class="card-body px-4">
        <div class="table-responsive">
          <table class="table table-centered table-hover">
            <tbody>
              <ng-container *ngIf="employeeReceivables$ | async; let receivables; else loadingTemplate">
                <ng-container *ngIf="receivables?.length; else noDataTemplate">
                  <tr *ngFor="let r of receivables">
                    <td>{{ r.name }}</td>
                    <td>{{ r.amount | localizedCurrency }}</td>
                    <td>{{ r.paid_amount | localizedCurrency }}</td>
                    <td>{{ 'employees_receivables.type_' + r.type | translate }}</td>
                    <td>{{ 'employees_receivables.state_' + r.state | translate }}</td>
                    <td class="text-right">
                      <button
                        *ngIf="canCreateOrEditReceivable$ | async"
                        class="btn btn-white btn-icon btn-sm mr-1"
                        (click)="openReceivableEditModal(r)"
                      >
                        <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>

              <ng-template #loadingTemplate>
                <app-row-loader></app-row-loader>
              </ng-template>

              <ng-template #noDataTemplate>
                <tr class="text-center">
                  <td colspan="2">{{ 'global.empty_widget_error' | translate }}</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
 -->
