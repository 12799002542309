<div class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help name="growth.list"></app-help>

      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <app-data-table
                [disableInPlaceFiltering]="true"
                [disableInPlaceSorting]="true"
                [loading]="loading"
                [rows]="rows"
                [search]="search"
                [sort]="sort"
                [trackByFn]="trackByFn"
                (searchChanged)="searchChanged($event)"
                (sortChanged)="sortChanged($event)"
              >
                <ng-template
                  #headerButton
                  *ngIf="canCreate$ | async"
                >
                  <button
                    class="ml-sm-2"
                    [createButton]="'growths.action_create' | translate"
                    (click)="openEditModal()"
                  ></button>
                </ng-template>

                <ng-template #headerRow>
                  <th appDataTableHeaderCell="name">{{ 'growths.name' | translate }}</th>

                  <th appDataTableHeaderCell="created_at">{{ 'growths.created_at' | translate }}</th>

                  <th appDataTableHeaderCell="active_count">{{ 'growths.active_count' | translate }}</th>

                  <th
                    appDataTableHeaderCell
                    class="text-right"
                    [disableSort]="true"
                  >{{ 'growths.table_action' | translate }}</th>
                </ng-template>

                <ng-template
                  #dataRow
                  let-row
                >
                  <td appDataTableDataCell>{{ row.name }}</td>

                  <td appDataTableDataCell>{{ row.created_at | date: 'dd.MM.yyyy' }}</td>

                  <td appDataTableDataCell>{{ row.active_count }}</td>

                  <td
                    appDataTableDataCell
                    class="text-right"
                  >
                    <a
                      class="btn btn-white btn-icon btn-sm"
                      title="{{ 'growths.action_view' | translate }}"
                      [routerLink]="['/growth', 'overview', row.growth_ID]"
                    >
                      <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                    </a>
                  </td>
                </ng-template>
              </app-data-table>
            </div>
          </div>
        </div>

        <div class="row mt-3 flex-column-reverse flex-sm-row">
          <div class="col-12 col-sm-2">
            <app-items-per-page-select
              [itemsPerPage]="itemsPerPage"
              [total]="itemsTotal"
              (itemsPerPageChanged)="itemsPerPageChanged($event)"
            ></app-items-per-page-select>
          </div>

          <div class="col-12 col-sm-10">
            <app-pagination
              [itemsPerPage]="itemsPerPage"
              [page]="page"
              [total]="itemsTotal"
              (pageChanged)="pageChanged($event)"
            ></app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
