import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { FlatpickrHelper } from 'src/app/shared/common/FlatpickrHelper';
import { SummernoteHelper } from 'src/app/shared/common/SummernoteHelper';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';
import { InternalEntity } from '../interface/internal-entity.interface';
import { ScheduledTaskEntity } from '../interface/scheduled-task-entity.interface';
import { ScheduledTask } from '../interface/scheduled-task.interface';
import { ScheduledTaskService } from '../service/scheduled-task.service';
import { ScheduledTaskSchedulableTypeType } from '../types';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-scheduled-task-create-edit-modal',
    templateUrl: './scheduled-task-create-edit-modal.component.html',
    styleUrls: ['./scheduled-task-create-edit-modal.component.css']
})
export class ScheduledTaskCreateEditModalComponent implements OnInit, OnDestroy {
    @Output() public deleted = new EventEmitter<void>();

    public entities$: Observable<Array<ScheduledTaskEntity>>;
    public entitiesLoading$: Observable<boolean>;
    public form: UntypedFormGroup;
    public hideEntitySelection = false;
    public hideType = false;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public submitted = false;

    private _entity$ = new ReplaySubject<InternalEntity | null>(1);
    private _entitiesLoading$ = new ReplaySubject<boolean>(1);
    private _subscriptions: Array<Subscription> = [];
    private _scheduledTask: ScheduledTask | undefined;

    public constructor(
        public activeModal: NgbActiveModal,
        public fpHelper: FlatpickrHelper,
        public summernoteHelper: SummernoteHelper,
        private _flatpickrLocale: FlatpickrLocaleService,
        private _formBuilder: UntypedFormBuilder,
        private _scheduledTaskService: ScheduledTaskService
    ) { }

    public set entity(entity: InternalEntity | null) {
        this._entity$.next(entity);
    }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.form.controls;
    }

    public get scheduledTask(): ScheduledTask {
        return this._scheduledTask;
    }

    public set scheduledTask(scheduledTask: ScheduledTask) {
        this._scheduledTask = scheduledTask;
    }

    public ngOnInit(): void {
        this._init();
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach(subscription => subscription?.unsubscribe());
    }

    public deleteScheduledTask(): void {
        this.deleted.emit();
    }

    public onSubmit(): void {
        this.submitted = true;

        if (this.form.valid) {
            const {
                ended_at,
                frequency,
                interval,
                schedulable_type,
                schedulable_id,
                started_at,
                type
            } = this.form.value;

            this.activeModal.close({
                data: {
                    description: this.form.value.description,
                    target: this.form.value.target,
                    title: this.form.value.name,
                },
                ended_at,
                frequency,
                interval,
                schedulable_id,
                schedulable_type,
                started_at,
                type
            });
        }
    }

    private _init(): void {
        this.entitiesLoading$ = this._entitiesLoading$;

        this.form = this._formBuilder.group({
            description: [null],
            ended_at: [null],
            frequency: ['YEARLY', Validators.required],
            interval: [1, [Validators.required, Validators.min(1)]],
            name: [null, Validators.required],
            schedulable_id: [null],
            schedulable_type: [null],
            started_at: [null, Validators.required],
            target: ['both', Validators.required],
            type: ['NOTIFICATION', Validators.required]
        });

        this._prefillForm(this.scheduledTask);

        this.form.controls.schedulable_id.disable();

        this._subscriptions.push(
            this._entity$
                .subscribe(entity => {
                    if (!entity) {
                        this.hideEntitySelection = false;
                        this.hideType = false;

                        return;
                    }

                    this.hideEntitySelection = true;

                    this.form.patchValue({
                        schedulable_id: entity.id,
                        schedulable_type: entity.type
                    });

                    if (
                        entity.type === 'Document' ||
                        entity.type === 'Employee' ||
                        entity.type === 'Property'
                    ) {
                        this.hideType = true;
                    }
                }),

            this.form.controls.frequency.valueChanges
                .subscribe(frequency => {
                    const control = this.form.controls.interval;

                    if (frequency === 'ONE_TIME') {
                        control.patchValue(null);
                        control.setValidators([]);
                        control.disable();
                    } else {
                        control.setValidators([Validators.required]);
                        control.enable();
                    }
                }),

            this.form.controls.schedulable_type.valueChanges
                .subscribe(schedulableType => {
                    const control = this.form.controls.schedulable_id;

                    if (schedulableType) {
                        this._fetchEntities(schedulableType);
                        control.setValidators([Validators.required]);
                        control.enable();
                    } else {
                        control.patchValue(null);
                        control.setValidators([]);
                        control.disable();
                    }
                }),

            this.form.controls.type.valueChanges
                .subscribe(type => {
                    const control = this.form.controls.target;

                    if (type === 'DUPLICATION') {
                        control.patchValue(null);
                        control.setValidators([]);
                        control.disable();
                    } else {
                        control.setValidators([Validators.required]);
                        control.enable();
                    }
                })
        );
    }

    private _fetchEntities(schedulableType: ScheduledTaskSchedulableTypeType): void {
        this.entities$ = of(null)
            .pipe(
                tap(() => this._entitiesLoading$.next(true)),
                switchMap(() => {
                    if (schedulableType === 'Document') {
                        return this._scheduledTaskService.getEntitiesDocument();
                    } else if (schedulableType === 'Employee') {
                        return this._scheduledTaskService.getEntitiesEmployee();
                    } else if (schedulableType === 'Order') {
                        return this._scheduledTaskService.getEntitiesOrder();
                    } else if (schedulableType === 'Property') {
                        return this._scheduledTaskService.getEntitiesProperty();
                    } else {
                        return of(null);
                    }
                }),
                tap(() => this._entitiesLoading$.next(false)),
            );
    }

    private _prefillForm(scheduledTask: ScheduledTask | undefined) {
        if (scheduledTask) {
            this.form.patchValue({
                description: scheduledTask.data.description,
                ended_at: scheduledTask.ended_at,
                frequency: scheduledTask.frequency,
                interval: scheduledTask.interval,
                name: scheduledTask.data.title,
                schedulable_id: scheduledTask.schedulable_id,
                schedulable_type: scheduledTask.schedulable_type,
                started_at: scheduledTask.started_at,
                target: scheduledTask.data.target,
                type: scheduledTask.type
            });
        }
    }
}
