import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { localStorageSafe } from '../../../functions';

@Component({
    selector: 'app-export',
    templateUrl: './export.component.html',
    styleUrls: ['./export.component.css']
})
export class ExportComponent implements OnInit, OnDestroy {
    @Input() public stream$: Observable<any>;

    private _streamSubscription: Subscription;

    public constructor() { }

    public ngOnInit(): void {

    }

    public ngOnDestroy(): void {
        this._streamSubscription?.unsubscribe();
    }

    public exportData(extension = 'csv'): void {
        localStorageSafe.setItem('export_extension', extension);
        this._streamSubscription = this.stream$.subscribe();
    }
}
