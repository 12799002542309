import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { InternalEntity } from '../interface/internal-entity.interface';
import { ScheduledTaskEntity } from '../interface/scheduled-task-entity.interface';
import { ScheduledTask } from '../interface/scheduled-task.interface';
import { ScheduledTaskSchedulableTypeType } from '../types';

const API_URI = '/api';
const BASE_URI = `${API_URI}/scheduled-task`;
const TASKS_URI_PART = 'scheduled-tasks';
const DOCUMENTS_URI_PART = 'documents';
const EMPLOYEES_URI_PART = 'employees';
const ORDERS_URI_PART = 'orders';
const PROPERTIES_URI_PART = 'properties';
const ENTITY_URI = 'list-for-scheduled-task';
const DOCUMENTS_URI = `${API_URI}/${DOCUMENTS_URI_PART}/${ENTITY_URI}`;
const EMPLOYEES_URI = `${API_URI}/${EMPLOYEES_URI_PART}/${ENTITY_URI}`;
const ORDERS_URI = `${API_URI}/${ORDERS_URI_PART}/${ENTITY_URI}`;
const PROPERTIES_URI = `${API_URI}/${PROPERTIES_URI_PART}/${ENTITY_URI}`;

@Injectable({
    providedIn: 'root'
})
export class ScheduledTaskService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _http: HttpClient,
        private _swalHelper: SwalHelper
    ) {
    }

    public create(task: ScheduledTask): Observable<null> {
        return this._http.post<ApiResponse>(`${BASE_URI}/create`, task)
            .pipe(
                map(response => {
                    if (response.message === 'scheduled_task.created') {
                        this._apiHelper.handleSuccessResponse(response);
                    } else {
                        this._apiHelper.handleErrorResponse(response);
                    }

                    return null;
                }),
                catchError(error => {
                    console.error('E:', error);

                    this._apiHelper.handleErrorResponse(error);

                    return of(null);
                })
            );
    }

    public delete(task: ScheduledTask): Observable<boolean> {
        return from(this._swalHelper.showConfirmDeleteDialog())
            .pipe(
                switchMap((deleteConfirmed) => {
                    if (deleteConfirmed) {
                        return this._http.get<ApiResponse>(`${BASE_URI}/${task.scheduled_task_ID}/delete`)
                            .pipe(
                                map(response => {
                                    if (response.message === 'scheduled_task.deleted') {
                                        this._apiHelper.handleSuccessResponse(response);

                                        return true;
                                    } else {
                                        this._apiHelper.handleErrorResponse(response);

                                        return false;
                                    }
                                }),
                                catchError(error => {
                                    console.error('E:', error);

                                    this._apiHelper.handleErrorResponse(error);

                                    return of(false);
                                })
                            );
                    } else {
                        return of(false);
                    }
                })
            );
    }

    public getById(taskId: ScheduledTask['scheduled_task_ID']): Observable<ScheduledTask> {
        return this._http.get<ScheduledTask>(`${BASE_URI}/${taskId}`)
            .pipe(
                map(scheduledTask => this._transformSchedulableType(scheduledTask))
            );
    }

    public getEntitiesDocument(): Observable<Array<ScheduledTaskEntity>> {
        return this._http.get<Array<{ document_ID: number; name: string; }>>(DOCUMENTS_URI)
            .pipe(
                map(entities => entities.map(entity => {
                    return {
                        name: entity.name,
                        value: entity.document_ID
                    };
                }))
            );
    }

    public getEntitiesEmployee(): Observable<Array<ScheduledTaskEntity>> {
        return this._http.get<Array<{ employee_ID: number; fullname: string; }>>(EMPLOYEES_URI)
            .pipe(
                map(entities => entities.map(entity => {
                    return {
                        name: entity.fullname,
                        value: entity.employee_ID
                    };
                }))
            );
    }

    public getEntitiesOrder(): Observable<Array<ScheduledTaskEntity>> {
        return this._http.get<Array<{ fullname: string; order_ID: number; subject: string; }>>(ORDERS_URI)
            .pipe(
                map(entities => entities.map(entity => {
                    return {
                        name: `${entity.fullname} – ${entity.subject}`,
                        value: entity.order_ID
                    };
                }))
            );
    }

    public getEntitiesProperty(): Observable<Array<ScheduledTaskEntity>> {
        return this._http.get<Array<{ name: string; property_ID: number; serial_number: string; }>>(PROPERTIES_URI)
            .pipe(
                map(entities => entities.map(entity => {
                    let name = entity.name;

                    if (entity.serial_number) {
                        name = `${name} (${entity.serial_number})`;
                    }

                    return {
                        name,
                        value: entity.property_ID
                    };
                }))
            );
    }

    public getList(params: ListParameters<ScheduledTask>, internalEntity: InternalEntity | null): Observable<ListResponse<ScheduledTask> | null> {
        const p = transformParameters(params);
        const uri = this._getListUriByInternalEntity(internalEntity);

        return this._http.post<ListResponse<ScheduledTask>>(uri, p)
            .pipe(
                map(response => {
                    if (response?.data) {
                        response = {
                            ...response,
                            data: response.data.map(scheduledTask => this._transformSchedulableType(scheduledTask))
                        };
                    }

                    return response;
                }),
                catchError(error => {
                    console.error('E:', error);

                    return of(null);
                })
            );
    }

    public update(taskId: ScheduledTask['scheduled_task_ID'], changes: Partial<ScheduledTask>): Observable<ScheduledTask | null> {
        return this._http.post<{ message: string; scheduled_task: ScheduledTask }>(`${BASE_URI}/${taskId}/update`, changes)
            .pipe(
                map(response => {
                    if (response.message === 'scheduled_task.updated') {
                        this._apiHelper.handleSuccessResponse(response);

                        return response.scheduled_task;
                    } else {
                        this._apiHelper.handleErrorResponse(response);

                        return null;
                    }
                }),
                catchError(error => {
                    console.error('E:', error);

                    this._apiHelper.handleErrorResponse(error);

                    return of(null);
                })
            );
    }

    private _getListUriByInternalEntity(internalEntity?: InternalEntity | null): string {
        let uri = BASE_URI;

        if (!internalEntity) {
            return uri;
        }

        switch (internalEntity.type) {
            case 'Document': {
                uri = `${API_URI}/${DOCUMENTS_URI_PART}/${internalEntity.id}/${TASKS_URI_PART}`;

                break;
            }

            case 'Employee': {
                uri = `${API_URI}/${EMPLOYEES_URI_PART}/${internalEntity.id}/${TASKS_URI_PART}`;

                break;
            }

            case 'Order': {
                uri = `${API_URI}/${ORDERS_URI_PART}/${internalEntity.id}/${TASKS_URI_PART}`;

                break;
            }

            case 'Property': {
                uri = `${API_URI}/${PROPERTIES_URI_PART}/${internalEntity.id}/${TASKS_URI_PART}`;

                break;
            }

            default: {
                uri = BASE_URI;

                break;
            }
        }

        return uri;
    }

    private _transformSchedulableType(scheduledTask: ScheduledTask): ScheduledTask {
        if (scheduledTask?.schedulable_type) {
            scheduledTask = {
                ...scheduledTask,
                schedulable_type: scheduledTask.schedulable_type.replace('App\\Models\\', '') as ScheduledTaskSchedulableTypeType
            };
        }

        return scheduledTask;
    }
}
