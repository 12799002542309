import { NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedModule } from '../shared/shared.module';
import { UIButtonModule, UIDataTableModule, UIFormModule, UIItemsPerPageSelectModule, UIPaginationModule } from '../ui';
import { CreateEditOnboardingModalComponent } from './components/partials/create-edit-onboarding-modal/create-edit-onboarding-modal.component';
import { CreateEditOnboardingTaskModalComponent } from './components/partials/create-edit-onboarding-task-modal/create-edit-onboarding-task-modal.component';
import { EmployeeOnboardingOverviewModalComponent } from './components/partials/employee-onboarding-overview-modal/employee-onboarding-overview-modal.component';
import { ActiveOnboardings } from './components/sections/active-onboardings/active-onboardings.component';
import { OnboardingListComponent } from './components/sections/onboarding-list/onboarding-list.component';
import { OnboardingOverviewComponent } from './components/sections/onboarding-overview/onboarding-overview.component';
import { OnboardingRoutingModule } from './onboarding-routing.module';

@NgModule({
    declarations: [
        CreateEditOnboardingModalComponent,
        CreateEditOnboardingTaskModalComponent,
        OnboardingListComponent,
        OnboardingOverviewComponent,
        EmployeeOnboardingOverviewModalComponent,
        ActiveOnboardings
    ],
    imports: [
        OnboardingRoutingModule,
        SharedModule,
        UIButtonModule,
        UIDataTableModule,
        UIFormModule,
        UIItemsPerPageSelectModule,
        UIPaginationModule,
        NgxSkeletonLoaderModule
    ],
    exports: [
        ActiveOnboardings
    ]
})
export class OnboardingModule {}
