import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { ApexAxisChartSeries, ApexChart, ApexFill, ApexMarkers, ApexPlotOptions, ApexStroke, ApexTitleSubtitle, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    title: ApexTitleSubtitle;
    stroke: ApexStroke;
    fill: ApexFill;
    markers: ApexMarkers;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis;
    plotOptions: ApexPlotOptions;
};

@Component({
    selector: 'app-radar-chart',
    templateUrl: './radar-chart.component.html',
    styleUrls: ['./radar-chart.component.css']
})
export class RadarChartComponent implements OnInit {
    @ViewChild('chart') chart: ChartComponent;

    @Input() public categories: Array<string>;
    @Input() public series: ApexAxisChartSeries;
    @Input() public title: string;
    @Input() public height = 250;

    public chartOptions: Partial<ChartOptions>;
    public canOpen = false;

    public ngOnInit() {
        this.chartOptions = {
            series: this.series,
            chart: {
                height: 350,
                type: 'radar',
                dropShadow: {
                    enabled: true,
                    blur: 1,
                    left: 1,
                    top: 1
                },
                toolbar: {
                    show: false,
                }
            },
            title: {
                text: this.title
            },
            stroke: {
                width: 0
            },
            fill: {
                opacity: 0.4
            },
            markers: {
                size: 0
            },
            xaxis: {
                categories: this.categories,
                labels: {
                    trim: true,
                    formatter(value: string, timestamp?: number, opts?: any): string | string[] {
                        const length = 10;
                        return (value.length > length) ? value.slice(0, length - 1) + '...' : value;
                    }
                }
            },
            yaxis: {
                min: 0,
                max: 100,
            },
            plotOptions: {
                radar: {
                    polygons: {
                        strokeColors: '#e8e8e8',
                        fill: {
                            colors: ['#f8f8f8', '#fff']
                        }
                    }
                }
            }
        };

        if (this.series?.length > 0 && this.categories.length > 0) {
            this.canOpen = true;
        }
    }
}
