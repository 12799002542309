import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { CompanyGroup } from '../../../../company-group/state/company-group.model';
import { Employee } from '../../../models/Employee';

@Component({
    selector: 'app-change-employee-active-access-modal',
    templateUrl: './change-employee-active-access-modal.component.html',
    styleUrls: ['./change-employee-active-access-modal.component.css']
})

export class ChangeEmployeeActiveAccessModalComponent implements OnInit {
    public employee: Employee;
    public form: UntypedFormGroup;
    public companyGroups$: Observable<CompanyGroup[]>;

    constructor(
        public activeModal: NgbActiveModal,
        private _employeeService: EmployeeService,
        private _fb: UntypedFormBuilder,
    ) { }

    public ngOnInit(): void {
        this.form = this._fb.group({
            activate: [false, Validators.required]
        });

        if (!this.employee.has_active_access) {
            this.form.addControl('send_invite', this._fb.control(true));
            this.form.patchValue({activate: true});
        }
    }

    public onSubmit(): void {
        this._employeeService.toggleEmployeeActiveAccess(this.employee.employee_ID, this.form.value)
            .then(() => {
                this.activeModal.close();
            }, () => { });
    }
}
