import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../../core/services/authentication.service';

@Component({
    selector: 'app-gantt-chart',
    templateUrl: './one-time.component.html',
    styleUrls: ['./one-time.component.css'],
    imports: [
        NgIf,
        RouterLink,
        TranslateModule
    ],
    standalone: true
})
export class OneTimeComponent implements OnInit, OnDestroy {
    public title: string;
    public description: string;
    public urlToRedirect: string;
    public interval: any;
    public progressBarWidth = 0;
    public remainingSeconds = 5;

    private _subscription: Subscription;

    public constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _authService: AuthenticationService
    ) { }

    public ngOnInit(): void {
        this._subscription = this._route.queryParams.subscribe(params => {
            this.title = params.title;
            this.description = params.description;
            this.urlToRedirect = params.next;

            if (!this.title) {
                this._redirect();
            }
        });

        this._setUpTimer();

    }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    private _setUpTimer(): void {
        const start = new Date().getTime();
        const end = start + 5000;

        this.interval = setInterval(() => {
            const now = new Date().getTime();
            if (now >= end) {
                clearInterval(this.interval);
                this._redirect();
            } else {
                this.progressBarWidth = (now - start) / 5000 * 100;
                this.remainingSeconds = Math.ceil((end - now) / 1000);
            }
        }, 100);
    }

    private _redirect(): void {
        if (this._authService.loggedUser?.user_ID) {
            if (this.urlToRedirect) {
                void this._router.navigate([this.urlToRedirect]);
                return;
            }
            void this._router.navigate(['/dashboard']);
            return;
        }
        void this._router.navigate(['/auth/login']);
    }
}
