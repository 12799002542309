import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { EntityUpdateRequestModalComponent } from '../../../../entity_update_request/components/partials/modal/entity_update_request-modal.component';
import { EntityUpdateRequest } from '../../../../entity_update_request/state/entity_update_request.model';
import { InputVisibilityService } from '../../../../shared/services/InputVisibilityService';
import { WidgetTemplatesService } from '../../../../shared/services/widget-templates.service';
import { EmployeePersonalData } from '../../../models/EmployeePersonalData';
import { EmployeeRequiredDataUpdates } from '../../../models/EmployeeRequiredDataUpdates';
import { CreateEditEmployeePersonalDataModalComponent } from '../../sections/create-edit-employee-personal-data-modal/create-edit-employee-personal-data-modal.component';

@Component({
    selector: 'app-employee-personal-data',
    templateUrl: './employee-personal-data-component.html',
    styleUrls: ['./employee-personal-data-component.css']
})
export class EmployeePersonalDataComponent implements OnInit {
    @Input() employee: Employee;
    @Input() requiredUpdates: EmployeeRequiredDataUpdates;
    @Input() entityRequests: Array<any>;

    @Output() refetchRequiredUpdates = new EventEmitter<string>();
    @Output() refetchActiveRequests = new EventEmitter<string>();

    public employeePersonalData$: Observable<EmployeePersonalData>;
    public isCollapsed = true;

    public get hiddenInputs(): boolean {
        return this._inputVisibilityService.hiddenInputs;
    }

    public constructor(
        public widgetTemplatesService: WidgetTemplatesService,
        private _modalService: NgbModal,
        private _employeeService: EmployeeService,
        private _inputVisibilityService: InputVisibilityService,
    ) { }

    public ngOnInit() {
        this.refetchPersonalData();
    }

    public refetchPersonalData(): void {
        this.employeePersonalData$ = this._employeeService.getPersonalData(this.employee.employee_ID);
    }

    public openEditModal(personalData: EmployeePersonalData): void {
        const modalRef = this._modalService.open(CreateEditEmployeePersonalDataModalComponent, {centered: true, size: 'lg'});

        let updateRequested = false;
        if (this.entityRequests) {
            for (const request of this.entityRequests) {
                if (request.entity_type === 'App\\Models\\EmployeePersonalData') {
                    updateRequested = true;
                    break;
                }
            }
        }

        modalRef.componentInstance.employee = this.employee;
        modalRef.componentInstance.employeePersonalData = personalData;
        modalRef.componentInstance.updateRequested = updateRequested;

        modalRef.result
            .then(
                () => {
                    this.refetchPersonalData();
                    this.setUpdateData();
                },
                () => { }
            );
    }

    public openEntityUpdateRequestModal(entityUpdateRequest?: EntityUpdateRequest): void {
        const modalRef = this._modalService.open(EntityUpdateRequestModalComponent, {centered: true});
        if (entityUpdateRequest) {
            modalRef.componentInstance.entityUpdateRequest = entityUpdateRequest;
        }
    }

    public setUpdateData(): void {
        if (this.requiredUpdates && this.requiredUpdates.personal_info === 'REQUESTED') {
            const data: { personal_info: string } = {personal_info: 'UPDATED'};
            this._employeeService.forceEmployeeToUpdateData(data, this.employee.employee_ID)
                .then(() => {
                    this.refetchRequiredUpdates.emit('refetch');
                    this.refetchActiveRequests.emit('refetch');
                });
        } else {
            this.refetchActiveRequests.emit('refetch');
        }
    }

    public refuseUpdateData(): void {
        const data: { personal_info: string } = {personal_info: 'REFUSED'};
        this._employeeService.forceEmployeeToUpdateData(data, this.employee.employee_ID)
            .then(() => this.refetchRequiredUpdates.emit('refetch'));
    }
}
