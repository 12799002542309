<section id="logo">
<div *ngIf="(availableFeatures$ | async)?.document_generations" class="card shadow">
  <app-help name="settings.documents.logo"></app-help>

  <div class="card-header py-3">
    <div class="row">
      <div class="col-sm-6 text-left my-auto">
        <p class="modal-title font-16 text-dark pt-2 pb-2">
          <span>{{ 'settings.company.info.documents_logo_file_ID' | translate }}</span>
        </p>
      </div>
    </div>
  </div>

  <div class="card-body">
    <app-upload
      (success)="refresh()"
      [allowTakingPicture]="false"
      [modelName]="'file_type'"
      [modelValue]="'settings_company_info_documents_logo_file_ID'"
    ></app-upload>

    <app-files-table
      (refetch)="refresh()"
      [allowDelete]="true"
      [files$]="companyDocumentLogo$"
      [objectType]="'DOCUMENTLOGO'"
      [object]="null"
      [loadSettings]="false"
    ></app-files-table>
  </div>
</div>
</section>

<section id="signature">
<div *ngIf="(availableFeatures$ | async)?.document_generations && (availableFeatures$ | async)?.signatures" class="card shadow">
  <app-help name="settings.documents.signature"></app-help>

  <div class="card-header py-3">
    <div class="row">
      <div class="col-sm-6 text-left my-auto">
        <p class="modal-title font-16 text-dark pt-2 pb-2">
          <span>{{ 'settings.company.info.signature_file_ID' | translate }}</span>
        </p>
      </div>
    </div>
  </div>

  <div class="card-body">
    <app-upload
      (success)="refresh()"
      [allowTakingPicture]="false"
      [modelName]="'file_type'"
      [modelValue]="'settings_company_info_signature_file_ID'"
    ></app-upload>

    <app-files-table
      (refetch)="refresh()"
      [allowDelete]="true"
      [files$]="companySignatureLogo$"
      [objectType]="'SIGNATURE'"
      [object]="null"
      [loadSettings]="false"
    ></app-files-table>
  </div>
</div>
</section>

<section id="responsible_person">
<div *ngIf="(availableFeatures$ | async)?.document_generations" class="card shadow">
  <app-help name="settings.documents.responsible_person"></app-help>

  <div class="card-header py-3">
    <div class="row">
      <div class="col-sm-6 text-left my-auto">
        <p class="modal-title font-16 text-dark pt-2 pb-2">
          <span>{{ 'settings.company.info.signature_employee_ID' | translate }}</span>
        </p>
      </div>
    </div>
  </div>

  <div class="card-body">
    <form
      (ngSubmit)="onSubmit()"
      [formGroup]="signatureEmployeeForm"
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <ng-select
              [clearable]="false"
              [items]="availableUsers$ | async"
              bindLabel="fullname"
              bindValue="user_ID"
              class="custom"
              formControlName="company.documents.signature_employee_ID"
              name="company.documents.signature_employee_ID"
            ></ng-select>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col d-flex justify-content-end">
          <button class="btn btn-success">
            <span>{{ 'global.action_save' | translate }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
</section>
