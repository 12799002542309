import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PropertyService } from 'src/app/property/services/property.service';

@Component({
    selector: 'app-delete-property-modal',
    templateUrl: './delete-property-modal.component.html',
    styleUrls: ['./delete-property-modal.component.css']
})
export class DeletePropertyModalComponent implements OnInit {

    public propertyID: number;
    public propertyDeleteForm: UntypedFormGroup;
    public deleteSubmitted = false;

    constructor(
        public activeModal: NgbActiveModal,
        private _fb: UntypedFormBuilder,
        private _propertyService: PropertyService,
        private _router: Router,
    ) { }

    public ngOnInit(): void {
        this.propertyDeleteForm = this._fb.group({
            delete_reason: ['', Validators.required],
            delete_note: [''],
        });
    }

    public onSubmitPropertyDelete(): void {
        this.deleteSubmitted = true;
        if (!this.propertyDeleteForm.valid) {
            return;
        }
        const promise = this._propertyService.deleteProperty(this.propertyID, this.propertyDeleteForm.value.delete_note, this.propertyDeleteForm.value.delete_reason);
        promise.then(result => this._router.navigateByUrl('/property/list')).catch(() => {});
    }
}
