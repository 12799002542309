<div class="row">
    <div class="col-12">
        <div class="card shadow">
            <app-help name="settings.users.list"></app-help>

            <div class="card-header py-3">
                <div class="row">
                    <div class="col-sm-6 text-left my-auto">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">
                            <span>{{ 'users.page_title_index' | translate }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive">
                            <app-data-table
                                [disableInPlaceFiltering]="true"
                                [disableInPlaceSorting]="true"
                                [loading]="loading"
                                [rows]="rows"
                                [search]="search"
                                [sort]="sort"
                                [trackByFn]="trackByFn"
                                (searchChanged)="searchChanged($event)"
                                (sortChanged)="sortChanged($event)"
                            >
                                <ng-template #headerButton *ngIf="canCreate$ | async">
                                    <button
                                        class="ml-sm-2"
                                        [createButton]="'users.action_create' | translate"
                                        (click)="openEditModal()"
                                    ></button>
                                </ng-template>

                                <ng-template #headerRow>
                                    <th appDataTableHeaderCell="fullname">{{ 'users.full_name' | translate }}</th>
                                    <th appDataTableHeaderCell="email">{{ 'users.email' | translate }}</th>
                                    <th appDataTableHeaderCell="email">{{ 'users.phone' | translate }}</th>
                                    <th appDataTableHeaderCell [disableSort]="true">{{ 'users.role' | translate }}</th>
                                    <th appDataTableHeaderCell="created_at" name="created_at_formatted">{{ 'users.created_at' | translate }}</th>
                                    <th appDataTableHeaderCell class="text-right" [disableSort]="true">{{ 'users.table_action' | translate }}</th>
                                </ng-template>

                                <ng-template #dataRow let-row>
                                    <td appDataTableDataCell>{{ row.fullname }}</td>
                                    <td appDataTableDataCell>{{ row.email }}</td>
                                    <td appDataTableDataCell>{{ row.phone }}</td>
                                    <td appDataTableDataCell>{{ row.role}}</td>
                                    <td appDataTableDataCell>{{ row.created_at_formatted }}</td>
                                    <td appDataTableDataCell class="text-right">
                                        <button
                                            class="btn btn-white btn-icon btn-sm mr-1"
                                            title="{{ 'users.action_edit' | translate }}"
                                            (click)="openEditModal(row.user_ID)">
                                            <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                        </button>
                                    </td>
                                </ng-template>
                            </app-data-table>
                        </div>
                    </div>
                </div>

                <div class="row mt-3 flex-column-reverse flex-sm-row">
                    <div class="col-12 col-sm-2">
                        <app-items-per-page-select
                            [itemsPerPage]="itemsPerPage"
                            [total]="itemsTotal"
                            (itemsPerPageChanged)="itemsPerPageChanged($event)"
                        ></app-items-per-page-select>
                    </div>

                    <div class="col-12 col-sm-10">
                        <app-pagination
                            [itemsPerPage]="itemsPerPage"
                            [page]="page"
                            [total]="itemsTotal"
                            (pageChanged)="pageChanged($event)"
                        ></app-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
