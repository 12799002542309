import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { WebcamImage, WebcamInitError } from 'ngx-webcam';

import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-camera-modal',
  templateUrl: './camera-modal.component.html',
  styleUrls: ['./camera-modal.component.css']
})
export class CameraModalComponent {
  public height = 500;

  public trigger: Observable<void>;

  public webcamImage: WebcamImage = null;

  public width = 500;

  private _trigger = new Subject<void>();

  public constructor(public activeModal: NgbActiveModal) {
    this.trigger = this._trigger;
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
  }

  public handleInitError(error: WebcamInitError): void {

    console.log(error);
  }

  public triggerSnapshot(): void {
    this._trigger.next();
  }
}
