<div *ngIf="kpiRadarChart$ | async; let chart" class="modal-content">
  <div class="modal-header">
    <p
      class="modal-title font-16 mb-0 text-dark"
    >{{ 'kpis.review_chart' | translate }}</p>

    <button
      (click)="activeModal.dismiss()"
      aria-label="Close"
      class="close"
    >
      <span
        aria-hidden="true"
        class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <app-radar-chart *ngIf="chart[0][kpiID] || chart[1][kpiID]; else noDataTemplate"
                             [categories]="scoreType === 'both' ? chart[0][kpiID]['categories'] : chart[0][kpiID]?.categories"
                             [height]="250"
                             [series]="scoreType === 'both' ? [chart[0][kpiID]['series'][0], chart[1][kpiID]['series'][0]] : chart[0][kpiID]?.series">
            </app-radar-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noDataTemplate>
  <div class="row">
    <div class="col-12 text-center">
      {{ 'global.empty_widget_error' | translate }}
    </div>
  </div>
</ng-template>
