import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DocumentTemplate } from 'src/app/setting/models/DocumentTemplate';
import { DocumentTemplateService } from 'src/app/setting/services/document-template.service';
import { SummernoteHelper } from '../../../../shared/common/SummernoteHelper';

@Component({
    selector: 'app-create-edit-document-template-html',
    templateUrl: './create-edit=document-template-html.component.html',
    styleUrls: ['./create-edit=document-template-html.component.css']
})
export class CreateEditDocumentTemplateHtmlComponent implements OnInit, OnDestroy {
    public key: string;
    public documentTemplate$: Observable<DocumentTemplate>;
    public form: FormGroup;

    private _routeSubscription: Subscription;

    public constructor(
        public summernoteHelper: SummernoteHelper,
        private _documentTeplateService: DocumentTemplateService,
        private _route: ActivatedRoute,
        private _fb: FormBuilder,
        private _router: Router,
    ) { }

    public ngOnInit(): void {
        this.form = this._fb.group({
            content: ['', Validators.required],
            name: ['', Validators.required],
        });

        this._routeSubscription = this._route.params.subscribe(params => {
            this.key = params.key;
            this.refreshData();
        });
    }

    public ngOnDestroy(): void {
        this._routeSubscription?.unsubscribe();
    }

    public refreshData(): void {
        this.documentTemplate$ = this._documentTeplateService.getTemplateByKey(this.key)
            .pipe(tap(documentTemplate => {
                this.form.patchValue({name: documentTemplate.name});
                if (documentTemplate.templateBase64) {
                    const utf8String = new TextDecoder().decode(new Uint8Array([...atob(documentTemplate.templateBase64)].map(char => char.charCodeAt(0))));
                    this.form.patchValue({
                        content: utf8String
                    });
                }
            }));
    }

    public saveTemplate(template: DocumentTemplate): void {
        this._documentTeplateService.setHTMLcontent(this.form.value, template.document_template_ID)
            .then(() => {
                void this._router.navigateByUrl(`/setting/document/${this.key}/detail`);
            });
    }
}
