import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { EmployeeActivity } from 'src/app/employee/models/EmployeeActivity';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { AvailableWidgets } from '../../../../setting/models/AvailableWidgets';
import { CompanyService } from '../../../../setting/services/company.service';
import { CreateEditEmployeeActivityModalComponent } from '../create-edit-employee-activity-modal/create-edit-employee-activity-modal.component';

@Component({
    selector: 'app-employee-activity-list',
    templateUrl: './employee-activity-list.component.html',
    styleUrls: ['./employee-activity-list.component.css']
})
export class EmployeeActivityListComponent implements OnInit, OnDestroy {
    public activities$: Observable<Array<EmployeeActivity>>;
    public canEditActivity$: Observable<boolean>;
    public loading$: Observable<boolean>;
    public availableWidgets$: Observable<AvailableWidgets>;
    public employeeID: number;
    public permissions: Array<string>;
    public watchingMyself = false;

    private _fetchEmployeeActivities$ = new ReplaySubject<void>(1);
    private _loading$ = new BehaviorSubject<boolean>(true);
    private _subscription: Subscription;

    public constructor(
        private _authService: AuthenticationService,
        private _employeeService: EmployeeService,
        private _companyService: CompanyService,
        private _modalService: NgbModal,
        private _route: ActivatedRoute,
        private _cdr: ChangeDetectorRef
    ) { }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.availableWidgets$ = this._companyService.availableWidgets$;

        this.canEditActivity$ = this._authService.hasPermissionTo('employeeActivity.edit').pipe(map(permission => permission.can));

        this.loading$ = this._loading$;

        this.permissions = this._authService?.permissions?.map(perm => perm.name);

        this._subscription = this._route.parent.params.subscribe(params => {
            this.employeeID = parseInt(params.id, 10);

            const loggedEmployeeID = this._authService?.employee?.employee_ID;
            this.watchingMyself = loggedEmployeeID === this.employeeID;

            this.activities$ = this._fetchEmployeeActivities$
                .pipe(
                    tap(() => this._loading$.next(true)),
                    switchMap(noop => this._employeeService.getActivitiesByEmployeeID(this.employeeID)),
                    tap(() => this._loading$.next(false)),
                );

            this._cdr.detectChanges();
        });

        this._fetchEmployeeActivities$.next();
    }

    public openCreateEditModal(activity?: EmployeeActivity): void {
        const modalRef = this._modalService.open(CreateEditEmployeeActivityModalComponent, {centered: true});

        modalRef.componentInstance.activity = activity;
        modalRef.componentInstance.employeeID = this.employeeID;

        modalRef.closed
            .subscribe(() => this._fetchEmployeeActivities$.next());
    }

    public checkPermission(name: string): boolean {
        return (this.watchingMyself || this.permissions.includes(name + '.view') || this.permissions.includes(name + '.viewStructure'));
    }

    public trackByActivity(index: number, employeeActivity: EmployeeActivity): number {
        return employeeActivity.employee_activity_ID;
    }
}
