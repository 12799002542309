import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanyService } from 'src/app/setting/services/company.service';

@Component({
    selector: 'app-company-settings-facturation',
    templateUrl: './company-settings-facturation.component.html',
    styleUrls: ['./company-settings-facturation.component.css']
})
export class CompanySettingsFacturationComponent implements OnInit {
    public companyFacturationNotificationsSettingEditForm: UntypedFormGroup;
    public companyFacturationSettings$: Observable<any>;
    public companyFacturationSettingEditForm: UntypedFormGroup;
    public submitted = false;
    public submittedNoti = false;

    public constructor(
        private _companyService: CompanyService,
        private _fb: UntypedFormBuilder,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.companyFacturationSettingEditForm.controls;
    }

    public get fNoti(): { [formControlName: string]: AbstractControl; } {
        return this.companyFacturationNotificationsSettingEditForm.controls;
    }

    public ngOnInit(): void {
        this.companyFacturationSettingEditForm = this._fb.group({
            'company.facturation.due_date_days': [30, Validators.min(0)],
            'company.facturation.vat': [0, Validators.min(0)],
        });

        this.companyFacturationNotificationsSettingEditForm = this._fb.group({
            'company.facturation.invoice_internal_email': ['', [Validators.required, Validators.email]],
            'company.facturation.due_date_notification_before': [0, Validators.min(0)],
            'company.facturation.due_date_notification_today': [false],
            'company.facturation.due_date_notification_after': [0, Validators.min(0)],
            'company.facturation.due_date_notification_after_repeat': [false],
            'company.facturation.due_date_notification_before_switch': [false],
            'company.facturation.due_date_notification_after_switch': [false],
        });

        this.companyFacturationSettings$ = this._companyService.getCompanySetting()
            .pipe(
                tap(settings => {
                    this.companyFacturationSettingEditForm.patchValue({
                        'company.facturation.due_date_days': settings.company_settings.facturation?.due_date_days,
                        'company.facturation.vat': settings.company_settings.facturation?.vat ? settings.company_settings.facturation?.vat : 21,
                    });

                    this.companyFacturationNotificationsSettingEditForm.patchValue({
                        'company.facturation.invoice_internal_email': settings.company_settings.facturation?.invoice_internal_email,
                        'company.facturation.due_date_notification_before': settings.company_settings.facturation?.due_date_notification_before,
                        'company.facturation.due_date_notification_today': settings.company_settings.facturation?.due_date_notification_today === '1' ? true : false,
                        'company.facturation.due_date_notification_after': settings.company_settings.facturation?.due_date_notification_after,
                        'company.facturation.due_date_notification_after_repeat': settings.company_settings.facturation?.due_date_notification_after_repeat,
                        'company.facturation.due_date_notification_before_switch': settings.company_settings.facturation?.due_date_notification_before_switch === '1' ? true : false,
                        'company.facturation.due_date_notification_after_switch': settings.company_settings.facturation?.due_date_notification_after_switch === '1' ? true : false,
                    });
                })
            );
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.companyFacturationSettingEditForm.valid) {
            return;
        }

        const value = this.companyFacturationSettingEditForm.value;
        value.settings_type = 'facturation';
        this._companyService.saveCompanySetting(value);
    }

    public onSubmitNoti(): void {
        this.submitted = true;

        if (!this.companyFacturationNotificationsSettingEditForm.valid) {
            return;
        }

        const value = this.companyFacturationNotificationsSettingEditForm.value;
        value.settings_type = 'facturation_notifications';
        this._companyService.saveCompanySetting(value);
    }
}
