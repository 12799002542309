<div class="modal-content">
  <div class="modal-header">
    <p class="modal-title font-16 mb-0 text-dark">
      <span *ngIf="onboardingTask">{{ 'onboardings_tasks.action_edit' | translate }}</span>
      <span *ngIf="!onboardingTask">{{ 'onboardings_tasks.action_create' | translate }}</span>
    </p>

    <button
      (click)="activeModal.dismiss()"
      aria-label="Close"
      class="close"
    >
      <span
        aria-hidden="true"
        class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <form
              (ngSubmit)="onSubmit()"
              [formGroup]="onboardingTaskEditForm"
              autocomplete="off"
            >
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'onboardings_tasks.name' | translate }}</span>
                    </label>

                    <div class="input-group border-2px">
                      <input
                        class="form-control border-0"
                        formControlName="name"
                        name="name"
                        type="text"
                      >

                      <app-form-max-length-hint
                        [maxLength]="maxTaskNameLength"
                        [value]="f.name.value"
                      ></app-form-max-length-hint>
                    </div>

                    <div *ngIf="submitted && f.name.errors">
                      <p
                        *ngIf="f.name.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'onboardings_tasks.name' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>

                      <p
                        *ngIf="f.name.errors.maxlength"
                        class="ml-1 text-danger"
                      >
                        <span>{{ 'angular_validation.max_length_prefix' | translate }} </span>
                        <span>{{ f.name.errors.maxlength.requiredLength }} </span>
                        <span>{{ 'angular_validation.max_length_suffix' | translate }}</span>
                      </p>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'onboardings_tasks.default_deadline' | translate }}</span>
                    </label>
                     <ng-select [items]="deadlineItems"
                                bindLabel="label"
                                bindValue="value"
                                class="custom"
                                [clearable]="false"
                                formControlName="deadline"
                                (change)="onDeadlineChange($event)"
                                name="deadline">
                      </ng-select>
                  </div>

                    <div class="row" #settingsCollapsed="ngbCollapse" [(ngbCollapse)]="customSettingsCollapsed">
                        <div class="col-lg-4">
                        <div class="form-group">
                            <label class="control-label">
                                <span>{{ 'onboardings_tasks.interval' | translate }}</span>
                            </label>

                            <input
                                class="form-control"
                                formControlName="interval"
                                min="1"
                                name="interval"
                                type="number"
                                (change)="onCustomChange()"
                            >

                            <div *ngIf="submitted && f.interval.errors">
                                <p
                                    *ngIf="f.interval.errors.min"
                                    class="text-danger validation-text"
                                >
                                    <span>{{ 'angular_validation.field' | translate }} </span>
                                    <span>{{ 'onboardings_tasks.interval' | translate }} </span>
                                    <span>{{ 'angular_validation.cannot_be_lower' | translate }}</span>
                                    <span> 1 </span>
                                </p>
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="form-group">
                              <label class="control-label">
                                  <span>{{ 'onboardings_tasks.period' | translate }}</span>
                              </label>

                              <ng-select
                                  [clearable]="false"
                                  [placeholder]="'onboardings_tasks.period_select' | translate"
                                  class="custom"
                                  formControlName="period"
                                  name="period"
                                  (change)="onCustomChange()"
                              >
                                  <ng-option value="DAY">
                                      <span>{{ 'onboardings_tasks.period_DAY' | translate }}</span>
                                  </ng-option>

                                  <ng-option value="WEEK">
                                      <span>{{ 'onboardings_tasks.period_WEEK' | translate }}</span>
                                  </ng-option>

                                  <ng-option value="MONTH">
                                      <span>{{ 'onboardings_tasks.period_MONTH' | translate }}</span>
                                  </ng-option>

                                  <ng-option value="END_MONTH">
                                      <span>{{ 'onboardings_tasks.period_END_MONTH' | translate }}</span>
                                  </ng-option>
                              </ng-select>

                              <div *ngIf="submitted && f.period.errors">
                                  <p
                                      *ngIf="f.period.errors.required"
                                      class="text-danger validation-text"
                                  >
                                      <span>{{ 'angular_validation.field' | translate }} </span>
                                      <span>{{ 'onboardings_tasks.period' | translate }} </span>
                                      <span>{{ 'angular_validation.required' | translate }}</span>
                                  </p>
                              </div>
                          </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label class="control-label">
                                    <span>{{ 'onboardings_tasks.when' | translate }}</span>
                                </label>

                                <ng-select
                                    [clearable]="false"
                                    [placeholder]="'onboardings_tasks.select_when' | translate"
                                    class="custom"
                                    formControlName="period_type"
                                    name="period_type"
                                    (change)="onCustomChange()"
                                >
                                    <ng-option value="BEFORE">
                                        <span>{{ 'onboardings_tasks.BEFORE' | translate }}</span>
                                    </ng-option>

                                    <ng-option value="AFTER">
                                        <span>{{ 'onboardings_tasks.AFTER' | translate }}</span>
                                    </ng-option>
                                </ng-select>

                                <div *ngIf="submitted && f.period_type.errors">
                                    <p
                                        *ngIf="f.period_type.errors.required"
                                        class="text-danger validation-text"
                                    >
                                        <span>{{ 'angular_validation.field' | translate }} </span>
                                        <span>{{ 'onboardings_tasks.when' | translate }} </span>
                                        <span>{{ 'angular_validation.required' | translate }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-right">
                            <small class="text-muted">
                                <span *ngIf="f.deadline.value === 'NONE'"> {{ 'onboardings_tasks.deadline_NONE_description' | translate }}.</span>
                                <span *ngIf="f.deadline.value !== 'NONE' && selectedDeadlineDate"> {{ 'onboardings_tasks.deadline_description' | translate }} {{selectedDeadlineDate | date : 'dd.MM.yyyy'}}.</span>
                            </small>
                        </div>
                    </div>

                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'onboardings_tasks.default_assignee' | translate }}</span>
                    </label>
                      <ng-select [items]="assigneeItems"
                                 bindLabel="label"
                                 bindValue="value"
                                 class="custom"
                                 formControlName="assignee"
                                 groupBy="group"
                                 (change)="onAsigneeChange($event)"
                                 [clearable]="false"
                                 name="assignee">
                      </ng-select>
                  </div>

                    <div *ngIf="assigneeName" class="row">
                        <div class="col-12 text-right">
                            <small class="text-muted">
                                <ng-container *ngIf="assigneeName !== 'none'">
                                    <span *ngIf="assigneeName !== 'not_found'"> {{ 'onboardings_tasks.assignee_description' | translate }}</span>
                                    <span *ngIf="assigneeName !== 'not_found'"> {{ assigneeName }}.</span>
                                    <span *ngIf="assigneeName === 'not_found'"> {{ 'onboardings_tasks.assignee_no_first_employee' | translate }}.</span>
                                </ng-container>
                                <span *ngIf="assigneeName === 'none'"> {{ 'onboardings_tasks.assignee_none_description' | translate }}.</span>
                            </small>
                        </div>
                    </div>

                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'onboardings_tasks.description' | translate }}</span>
                    </label>

                    <textarea
                      [ngxSummernote]="summernoteHelper.getSummernoteConfig()"
                      class="form-control"
                      formControlName="description"
                      name="description"
                      rows="5"
                    ></textarea>

                    <app-form-max-length-hint
                      [maxLength]="maxTaskDescriptionLength"
                      [value]="f.description.value"
                      class="d-block ml-1"
                    ></app-form-max-length-hint>

                    <span *ngIf="submitted && f.description.errors">
                      <span
                        *ngIf="f.description.errors.maxlength"
                        class="ml-1 text-danger"
                      >
                        <span>{{ 'angular_validation.max_length_prefix' | translate }} </span>
                        <span>{{ f.description.errors.maxlength.requiredLength }} </span>
                        <span>{{ 'angular_validation.max_length_suffix' | translate }}</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col text-right my-auto">
                  <u
                      (click)="deleteOnboardingTask()"
                      *ngIf="onboardingTask"
                      class="text-muted"
                  >{{ 'onboardings_tasks.action_delete' | translate }}</u>
                </div>
                <div class="col-auto text-right">
                  <button class="btn btn-success">
                    <span>{{ 'onboardings_tasks.action_save' | translate }}</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
