import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { EmployeeGrowth } from './employee-growth.model';

export interface EmployeeGrowthState extends EntityState<EmployeeGrowth> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'employee-growths', idKey: 'employee_growth_ID' })
export class EmployeeGrowthStore extends EntityStore<EmployeeGrowthState> {
  constructor() {
    super();
  }
}
