import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { IntegrationService } from 'src/app/core/services/integration.service';
import { CompanyService } from 'src/app/setting/services/company.service';
import { UserService } from 'src/app/setting/services/user.service';
import { FormHelper } from 'src/app/shared/common/FormHelper';
import { SecuritySetting } from '../../../models/SecuritySetting';

@Component({
    selector: 'app-setting-security',
    templateUrl: './setting-security.component.html',
    styleUrls: ['./setting-security.component.css']
})
export class SettingSecurityComponent implements OnInit {
    public canViewSecurity$: Observable<boolean>;
    public canEditSecurity$: Observable<boolean>;
    public twoFactorForm: UntypedFormGroup;
    public ipWhitelistForm: UntypedFormGroup;
    public autoLogoutForm: UntypedFormGroup;
    public backofficeAccessForm: UntypedFormGroup;
    public securitySetting$: Observable<SecuritySetting>;
    public isCollapsed: { twoFactor: boolean, ipWhitelist: boolean };
    public submitted = false;

    public constructor(
        private _authService: AuthenticationService,
        private _companyService: CompanyService,
        private _formBuilder: UntypedFormBuilder,
        private _formHelper: FormHelper,
        private _modalService: NgbModal,
        private _integrationService: IntegrationService,
        private _userService: UserService
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.autoLogoutForm.controls;
    }

    public ngOnInit(): void {
        this.twoFactorForm = this._formBuilder.group({
            enabled: [null, Validators.required],
            force: [null],
            force_admin: [null],
        });

        this.ipWhitelistForm = this._formBuilder.group({
            enabled: [null, Validators.required],
            whitelist: [null],
        });

        this.autoLogoutForm = this._formBuilder.group({
            timeout: [null, Validators.required],
        });

        this.backofficeAccessForm = this._formBuilder.group({
            enabled: [null, Validators.required],
        });

        this.securitySetting$ = this._companyService.getSecuritySetting()
            .pipe(map(setting => {
                this.twoFactorForm.patchValue(setting.twoFactor);
                this.ipWhitelistForm.patchValue(setting.ipWhitelist);
                this.autoLogoutForm.patchValue(setting.autoLogout);
                this.backofficeAccessForm.patchValue(setting.backofficeAccess);

                this.isCollapsed = {
                    twoFactor: setting.twoFactor.enabled,
                    ipWhitelist: setting.ipWhitelist.enabled
                };

                return setting;
            }));

        this.canEditSecurity$ = this._authService.hasPermissionTo('security.edit')
            .pipe(map(permission => permission.can));

        this.canViewSecurity$ = this._authService.hasPermissionTo('security.view')
            .pipe(map(permission => permission.can));

    }

    public setCollapse(form: string): void {
        this.isCollapsed[form] = !this.isCollapsed[form];
    }

    public addCurrentIpAddress(ipAddress: string): void {
        const value = this.ipWhitelistForm.get('whitelist').getRawValue();

        if (value.length > 0) {
            this.ipWhitelistForm.get('whitelist').setValue(value + ',' + ipAddress);
        } else {
            this.ipWhitelistForm.get('whitelist').setValue(ipAddress);
        }
    }

    public onSubmitTwoFactor(): void {
        this._companyService.updateSecuritySetting(this.twoFactorForm.value, 'two_factor');
    }

    public onSubmitIpWhitelist(): void {
        this._companyService.updateSecuritySetting(this.ipWhitelistForm.value, 'ip_whitelist');
    }

    public onSubmitAutoLogout(): void {
        this.submitted = true;
        if (this.autoLogoutForm.invalid) {
            return;
        }

        this._companyService.updateSecuritySetting(this.autoLogoutForm.value, 'auto_logout');
    }

    public onSubmitBackofficeAccess(): void {
        this._companyService.updateSecuritySetting(this.backofficeAccessForm.value, 'backoffice_access');
    }
}
