<div class="row justify-content-center mt-5">
    <div class="col-lg-4">
        <div class="text-center">
            <img alt="File not found Image" height="350" src="assets/images/404-title-image.png">

            <h1 class="mt-3 text-dark"> {{ 'global.smth_went_wrong' | translate }} </h1>
            <p class="text-muted font-18 mt-3"> {{ 'global.site_does_not_exist' | translate }} </p>

            <a class="btn btn-primary mt-3" routerLink="/dashboard"> {{ 'global.back_to_dashboard' | translate }} </a>
        </div> <!-- end /.text-center-->
    </div> <!-- end col-->
</div>
<!-- end row -->
