<div class="modal-content">
	<div class="modal-header">
			<p *ngIf="widgetTemplate" class="modal-title font-16 mb-0 text-dark">{{'settings.appearance.widget_templates.page_title_edit' | translate}}</p>
			<p *ngIf="!widgetTemplate" class="modal-title font-16 mb-0 text-dark">{{'settings.appearance.widget_templates.page_title_create' | translate}}</p>
		<button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
			<span aria-hidden="true" class="font-24 text-muted"><i class="mdi mdi-close"></i></span>
		  </button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-0 shadow-none">
					<div class="card-body p-0">
                        <!-- form components to get all inputs in formGroups -->
                        <app-personal-data-form #personalDataFormComponent
                                                [hidden]="true">
                        </app-personal-data-form>
                        <app-address-form #addressFormComponent
                                          [hidden]="true">
                        </app-address-form>
                        <app-contact-person-form #contactPersonFormComponent
                                                 [hidden]="true">
                        </app-contact-person-form>

						<form #form="ngForm" (ngSubmit)="onSubmit(form)" autocomplete="off">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label class="control-label required-label">{{'settings.appearance.widget_templates.name' | translate}}</label>
										<input class="form-control"
                                               required
                                               [ngModel]="widgetTemplate?.name"
                                               name="name"
                                               type="text">
										<div *ngIf="submitted && form.form.controls.name.errors">
											<p *ngIf="form.form.controls.name.errors.required" class="text-danger validation-text">
												{{ 'angular_validation.field' | translate }} {{ 'settings.appearance.widget_templates.name' | translate }}
												{{ 'angular_validation.required' | translate }}
											</p>
										</div>
									</div>
								</div>
							</div>

                            <hr>
                            <ng-container [ngModelGroup]="entity.key" *ngFor="let entity of controls | keyvalue">
                                <div class="row mb-2">
                                    <div class="col-6">
                                        <h4>{{ ('settings.appearance.widget_templates.' + entity.key) | translate }}</h4>
                                    </div>
                                    <div class="col-6 text-right align-self-center">
                                        <div>
                                            <input [checked]="!collapsedForms[entity.key]" data-switch="success" id="collapse_{{entity.key}}" type="checkbox">
                                            <label class="m-0" (click)="collapsedForms[entity.key] = !collapsedForms[entity.key]">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>

                                <div [ngbCollapse]="collapsedForms[entity.key]" class="row mb-2">
                                    <ng-container *ngFor="let control of entity.value | keyvalue">
                                        <ng-container *ngIf="control.key === 'custom_fields'" [ngModelGroup]="'custom_fields'">
                                            <ng-container *ngFor="let customField of control.value">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label class="control-label">{{customField.label}}</label>
                                                        <ng-select [clearable]="false"
                                                                   [disabled]="customField.disabled"
                                                                   closeOnSelect="true"
                                                                   class="custom"
                                                                   [items]="controlOptions"
                                                                   [bindLabel]="'label'"
                                                                   [bindValue]="'value'"
                                                                   ngModel="{{customField.value}}"
                                                                   name="{{customField.name}}">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <div *ngIf="control.key !== 'custom_fields'" class="col-6">
                                            <div class="form-group">
                                                <label class="control-label">{{control.value.label}}</label>
                                                <ng-select [clearable]="false"
                                                           [disabled]="control.value.disabled"
                                                           closeOnSelect="true"
                                                           class="custom"
                                                           [items]="controlOptions"
                                                           [bindLabel]="'label'"
                                                           [bindValue]="'value'"
                                                           ngModel="{{control.value.value}}"
                                                           name="{{control.value.name}}">
                                                </ng-select>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>

                                <hr>
                            </ng-container>

							<div class="row justify-content-end mt-2">
                                <div class="col-6 text-right my-auto">
                                    <u (click)="deleteWidgetTemplate()" *ngIf="widgetTemplate" class="text-muted">{{'global.action_delete' | translate}}</u>
                                </div>
								<div class="col-6 text-right">
									<button class="btn btn-success"
										    type="submit">{{'global.action_save' | translate}}</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>









