import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { arrayAdd, arrayRemove, arrayUpdate, cacheable } from '@datorama/akita';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { Employee } from 'src/app/employee/models/Employee';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { EmployeeOnboarding } from '../../employee-onboarding/state/employee-onboarding.model';
import { EmployeeOnboardingQuery } from '../../employee-onboarding/state/employee-onboarding.query';
import { EmployeeOnboardingStore } from '../../employee-onboarding/state/employee-onboarding.store';
import { OnboardingTask } from './onboarding-task.model';
import { Onboarding } from './onboarding.model';
import { OnboardingStore } from './onboarding.store';

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _employeeOnboardingQuery: EmployeeOnboardingQuery,
        private _employeeOnboardingStore: EmployeeOnboardingStore,
        private _http: HttpClient,
        private _modalService: NgbModal,
        private _onboardingStore: OnboardingStore,
        private _router: Router,
        private _swalHelper: SwalHelper
    ) { }

    public assignEmployeesToOnboarding(onboarding: Onboarding, employeesIDs: Array<number>, startDate: string): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            this._http.post<ApiResponse>(`/api/onboardings/${onboarding.onboarding_ID}/employees/assign`, {employeesIDs, startDate})
                .subscribe(
                    response => {

                        this._onboardingStore.update(
                            onboarding.onboarding_ID,
                            onboarding
                        );

                        this._modalService.dismissAll();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve(true);
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        reject('error');
                    }
                );
        });
    }

    public assignMultipleOnboardingsToMultipleEmployees(onboardingsIDs: Array<number>, employeesIDs: Array<number>, startDate: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._http.post<ApiResponse>(`/api/onboardings/assign-multiple`, {employeesIDs, onboardingsIDs, startDate})
                .subscribe(
                    response => {

                        this._modalService.dismissAll();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve(true);
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        reject('error');
                    }
                );
        });
    }

    public assignOnboardingsToEmployee(form: { start_date: string, onboardingIDs: Array<number> }, employeeID: number): Promise<'done'> {
        const body = {
            startDate: form.start_date,
            onboardingIDs: form.onboardingIDs
        };

        return new Promise(resolve => {
            this._http.post<ApiResponse & { employeeOnboardings: Array<EmployeeOnboarding>; }>('/api/employees/' + employeeID + '/onboardings/assign', body)
                .subscribe(
                    response => {
                        this._employeeOnboardingStore.add(response.employeeOnboardings);

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => this._apiHelper.handleErrorResponse(error));
        });
    }

    public createAndAssignOnboardingToEmployee(form: { name: string, type: string, description: string, start_date: string }, employeeID: number): Promise<'done'> {
        const body = {
            name: form.name,
            type: form.type,
            description: form.description,
            startDate: form.start_date,
        };

        return new Promise(resolve => {
            this._http.post<ApiResponse & { employee_onboarding: EmployeeOnboarding; }>('/api/employees/' + employeeID + '/onboardings', body)
                .subscribe(
                    response => {
                        this._employeeOnboardingStore.add(response.employee_onboarding);

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => this._apiHelper.handleErrorResponse(error));
        });
    }

    public async deleteOnboarding(onboardingID: number | string): Promise<void> {
        const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

        if (deleteConfirmed) {
            this._http.delete<ApiResponse>(`/api/onboardings/${onboardingID}`, {})
                .subscribe(
                    response => {
                        this._onboardingStore.remove(onboardingID);

                        this._apiHelper.handleSuccessResponse(response);

                        this._router.navigate(['/onboarding', 'list']);
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        }
    }

    public async deleteOnboardingTask(
        onboardingID: number | string,
        onboardingTaskID: number | string,
        modalRef: NgbActiveModal
    ): Promise<void> {
        const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

        if (deleteConfirmed) {
            this._http.delete<ApiResponse>(`/api/onboardings/${onboardingID}/tasks/${onboardingTaskID}`, {})
                .subscribe(
                    response => {
                        this._onboardingStore.update(
                            onboardingID,
                            originalOnboarding => ({
                                ...originalOnboarding,
                                tasks: arrayRemove(originalOnboarding.tasks, onboardingTaskID, 'onboarding_task_ID')
                            })
                        );

                        modalRef.close();

                        this._apiHelper.handleSuccessResponse(response);
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        }
    }

    public get(): Observable<void> {
        const request = this._http.get<Array<Onboarding>>('/api/onboardings')
            .pipe(map(response => this._onboardingStore.set(response)));

        return cacheable(this._onboardingStore, request);
    }

    public getAllOnboardings(): Observable<Array<Onboarding>> {
        return this._http.get<Array<Onboarding>>('/api/onboardings');
    }

    public getEmployeesForOnboarding(onboardingID: number | string): Observable<Array<Employee>> {
        return this._http.get<Array<Employee>>(`/api/onboardings/${onboardingID}/employees`);
    }

    public getOnboardingByID(onboardingID: number | string): Observable<Onboarding> {
        return this._http.get<Onboarding>(`/api/onboardings/${onboardingID}`);
    }

    public getOnboardingsList(params: ListParameters<Onboarding>): Observable<ListResponse<Onboarding>> {
        return this._http.post<ListResponse<Onboarding>>('/api/onboardings/list', {...transformParameters(params)});
    }

    public saveOnboarding(form: Onboarding, modalRef: NgbActiveModal, onboardingID?: number | string): void {
        let apiUrl = '/api/onboardings';

        if (onboardingID) {
            apiUrl += `/${onboardingID}`;
        }

        this._http.post<ApiResponse & { onboarding: Onboarding; }>(apiUrl, form)
            .subscribe(
                response => {
                    this._apiHelper.handleSuccessResponse(response);

                    if (!onboardingID) {
                        this._onboardingStore.add(response.onboarding);
                    } else {
                        this._onboardingStore.update(onboardingID, response.onboarding);
                    }

                    modalRef.close();
                },
                error => this._apiHelper.handleErrorResponse(error)
            );
    }

    public saveOnboardingTask(
        form: OnboardingTask,
        onboardingID: string | number,
        modalRef: NgbActiveModal,
        onboardingTaskID?: number | string
    ): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let apiUrl = `/api/onboardings/${onboardingID}/tasks`;

            if (onboardingTaskID) {
                apiUrl += `/${onboardingTaskID}`;
            }

            this._http.post<ApiResponse & { onboarding_task: OnboardingTask; }>(apiUrl, form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        if (onboardingTaskID) {
                            this._onboardingStore.update(
                                onboardingID,
                                originalOnboarding => ({
                                    tasks: arrayUpdate(
                                        originalOnboarding.tasks,
                                        onboardingTaskID,
                                        response.onboarding_task,
                                        'onboarding_task_ID'
                                    )
                                })
                            );
                        } else {
                            this._onboardingStore.update(
                                onboardingID,
                                originalOnboarding => ({
                                    tasks: arrayAdd(originalOnboarding.tasks, response.onboarding_task)
                                })
                            );
                        }

                        if (modalRef) {
                            modalRef.close();
                        }

                        resolve(true);
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        reject('error');
                    }
                );
        });
    }

    public unAssignEmployeeFromOnboarding(onboardingID: number | string, employeeID: number): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.post<ApiResponse>(`/api/onboardings/${onboardingID}/employees/unassign`, {employee_ID: employeeID})
                    .subscribe(
                        response => {
                            this._onboardingStore.update(
                                onboardingID,
                                onboarding => ({
                                    employees: arrayRemove(onboarding.employees, employeeID, 'employee_ID')
                                })
                            );

                            this._apiHelper.handleSuccessResponse(response);

                            resolve(true);
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);

                            reject('error');
                        }
                    );
            } else {
                reject('cancelled');
            }
        });
    }

    public async unassignOnboardingFromEmployee(employeeOnboarding: EmployeeOnboarding, employeeID: number): Promise<void> {
        const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

        if (deleteConfirmed) {
            let stream$: Observable<ApiResponse>;

            if (employeeOnboarding.onboarding_ID) {
                stream$ = this._http.post<ApiResponse>(`/api/onboardings/${employeeOnboarding.onboarding_ID}/employees/unassign`, {employee_ID: employeeID});
            } else {
                stream$ = this._http.delete<ApiResponse>(`/api/employees-onboardings/${employeeOnboarding.employee_onboarding_ID}`);
            }

            stream$
                .subscribe(
                    response => {
                        this._onboardingStore.update(
                            employeeOnboarding.onboarding_ID,
                            onboarding => ({
                                employees: arrayRemove(onboarding.employees, employeeID, 'employee_ID')
                            })
                        );

                        if (this._employeeOnboardingQuery.hasEntity(employeeOnboarding.employee_onboarding_ID)) {
                            this._employeeOnboardingStore.remove(employeeOnboarding.employee_onboarding_ID);
                        }

                        this._apiHelper.handleSuccessResponse(response);
                        this._modalService.dismissAll();
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        }
    }
}
