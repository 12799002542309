import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-items-per-page-select',
    templateUrl: './items-per-page-select.component.html',
    styleUrls: ['./items-per-page-select.component.css']
})
export class ItemsPerPageSelectComponent implements OnChanges {
    @Input() public itemsPerPage = 10;
    @Input() public total: number;
    @Input() public steps = [
        10,
        20,
        50
    ];

    @Output() public itemsPerPageChanged = new EventEmitter<number>();

    public shown: boolean;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.total) {
            this.shown = changes.total.currentValue > this.steps[0];
        }
    }

    public itemsPerPageChange(itemsPerPage: number): void {
        this.itemsPerPageChanged.emit(itemsPerPage);
    }
}
