import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { DomainService } from 'src/app/core/services/domain.service';
import { CheckExtraVerificationModalComponent } from '../components/partials/check-extra-verification-modal/check-extra-verification-modal.component';

@Injectable()
export class ExtraVerificationInterceptor implements HttpInterceptor {

    public constructor(
        private _authService: AuthenticationService,
        private _domainService: DomainService,
        private _loaderService: NgxUiLoaderService,
        private _router: Router,
        private _modalService: NgbModal,
        private _http: HttpClient,
    ) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                catchError(error => {
                    if (this.isErrorResponse(error, 428)) {
                        this._loaderService.stop();
                        return this.loop(req, next, error);
                    }
                    return throwError(error);
                })
            );
    }

    private loop(req: HttpRequest<any>, next: HttpHandler, error: any): Observable<any> {
        return from(this.openModal(error)
            .then(result => {
                req = req.clone({
                    body: {...req.body, ...result}
                });
            }))
            .pipe(
                mergeMap(() => {
                    return next.handle(req).pipe(catchError(err => {
                        // check if verification successed, else loop again
                        if (this.isErrorResponse(err, 428)) {
                            return this.loop(req, next, err);
                        }
                        return of(err);
                    }));
                }));
    }

    private isErrorResponse(error: any, status: number): boolean {
        if (error instanceof HttpErrorResponse) {
            if (error.status === status) {
                return true;
            }
        }

        return false;
    }

    private openModal(error: any): Promise<any> {
        const modalRef = this._modalService.open(CheckExtraVerificationModalComponent, {centered: true});
        modalRef.componentInstance.methods = error.error.methods;
        modalRef.componentInstance.state = error.error.state;
        modalRef.componentInstance.labels = error.error.labels;

        return modalRef.result;
    }
}
