<div
    *ngIf="data$ | async as data; else loadingTemplate"
    class="row"
>
    <div class="col-xl-9 mb-3">
        <div class="card h-100 shadow">
            <app-help name="attendance.documents.detail.employee.info_table"></app-help>
            <div class="card-header py-3">
                <div class="row">
                    <div class="col-12">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">
                            <span>{{ data.employee.fullname }} </span>
                            <span>{{ year }}</span>
                            <span>/</span>
                            <span>{{ month }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="card-body px-4">
                <div class="table-responsive">
                    <app-data-table
                        [customNoDataMessage]="'global.empty_widget_error' | translate"
                        [disableInPlaceSorting]="true"
                        [rows]="data.dates"
                        [showSearch]="false"
                    >
                        <ng-template #headerRow>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.day' | translate }}</th>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.work_start' | translate }}</th>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.work_end' | translate }}</th>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.Total' | translate }}</th>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.absence_start' | translate }}</th>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.absence_end' | translate }}</th>
                            <th appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.Total' | translate }}</th>
                            <th *ngIf="!data.is_uneven" appDataTableHeaderCell [disableSort]="true">{{ 'employees_datetimes.saldo' | translate }}</th>
                        </ng-template>

                        <ng-template
                            #dataRow
                            let-row
                        >
                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                {{ row.date | date: 'dd.MM.yyyy' }}
                            </td>

                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                {{ row.work.from }}
                            </td>

                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                {{ row.work.to }}
                            </td>

                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                {{ row.work.total }}
                            </td>

                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                {{ row.absence.from }}
                            </td>

                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                {{ row.absence.to }}
                            </td>

                            <td [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                {{ row.absence.total }}
                            </td>

                            <td *ngIf="!data.is_uneven" [ngClass]="{'bg-light': row.is_holiday || row.is_weekend}" appDataTableDataCell>
                                {{ row.saldo }}
                            </td>
                        </ng-template>
                    </app-data-table>
                </div> <!-- end table-responsive-->
            </div>
        </div>
    </div>

    <div class="col-xl-3 mb-3">
        <div class="card h-100 shadow">
            <app-help name="attendance.documents.detail.employee.info_summary"></app-help>
            <div class="card-header py-3">
                <div class="row">
                    <div class="col-12">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">
                            <span>{{ 'employees_datetimes.summary' | translate }} </span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="card-body px-4">
                <div class="table-responsive">
                    <table class="table table-centered mb-0">
                        <tbody>
                            <tr> <strong><td colspan="2"> {{ 'employees_datetimes.whole_month' | translate }} </td> </strong></tr>
                            <tr> <td>{{ 'employees_datetimes.expected' | translate }}</td> <td class="text-right">{{ data.summary.whole_month.expected }} </td></tr>
                            <tr> <td>{{ 'employees_datetimes.real' | translate }}</td> <td class="text-right">{{ data.summary.whole_month.real }}</td> </tr>
                            <tr> <td>{{ 'employees_datetimes.saldo' | translate }}</td> <td class="text-right">{{ data.summary.whole_month.saldo }}</td> </tr>

                            <tr> <strong><td colspan="2"> {{ 'employees_datetimes.until_now' | translate }} </td> </strong></tr>
                            <tr> <td>{{ 'employees_datetimes.expected' | translate }}</td> <td class="text-right">{{ data.summary.until_now.expected }} </td></tr>
                            <tr> <td>{{ 'employees_datetimes.real' | translate }}</td> <td class="text-right">{{ data.summary.until_now.real }}</td> </tr>
                            <tr> <td>{{ 'employees_datetimes.saldo' | translate }}</td> <td class="text-right">{{ data.summary.until_now.saldo }}</td> </tr>
                        </tbody>
                    </table>
                </div> <!-- end table-responsive-->
            </div>
        </div>
    </div>

</div>


<ng-template #loadingTemplate>
    <div class="row">
        <div class="col-12 col-lg-8">
            <div class="card shadow p-3">
                <div class="item">
                    <ngx-skeleton-loader [theme]="{
                        'margin': '13px 0'
                      }" appearance="line" count="10"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div class="card shadow p-3">
                <div class="item">
                    <ngx-skeleton-loader [theme]="{
                        'margin': '13px 0'
                      }" appearance="line" count="10"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</ng-template>
