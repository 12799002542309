<ng-container *ngIf="(canViewSecurity$ | async) && (securitySetting$ | async) as settings; else loadingTemplate">
    <app-help name="settings.security"></app-help>
    <div class="row">
        <div class="col-12">
            <!-- 2FA -->
            <div class="card shadow">
                <app-help name="settings.security.2fa"></app-help>
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-12 text-left my-auto">
                            <p class="modal-title font-16 text-dark pt-2 pb-2">
                                <span>{{ 'settings.security.2fa_title' | translate }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <form (ngSubmit)="onSubmitTwoFactor()"
                          [formGroup]="twoFactorForm"
                          autocomplete="off">
                        <div class="row">
                            <div class="col-6">
                                <label class="control-label">
                                    <span>{{ 'settings.security.enable_2fa' | translate }}</span>
                                </label>
                            </div>
                            <div class="col-6 text-right">
                                <input (click)="setCollapse('twoFactor')"
                                       [attr.aria-expanded]="!isCollapsed.twoFactor"
                                       aria-controls="collapse"
                                       data-switch="success"
                                       formControlName="enabled"
                                       id="enabled"
                                       type="checkbox"
                                >
                                <label for="enabled">&nbsp;</label>
                            </div>
                        </div>
                        <!--<div #collapseTenantID="ngbCollapse"
                             [(ngbCollapse)]="!isCollapsed.twoFactor">
                          <div class="row">
                            <div class="col-6">
                              <label class="control-label">
                                <span>{{ 'settings.security.force_2fa' | translate }}</span>
                              </label>
                            </div>
                            <div class="col-6 text-right">
                              <input data-switch="success"
                                     formControlName="force"
                                     id="force"
                                     type="checkbox"
                              >
                              <label for="force">&nbsp;</label>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <label class="control-label">
                                <span>{{ 'settings.security.force_admin_2fa' | translate }}</span>
                              </label>
                            </div>
                            <div class="col-6 text-right">
                              <input data-switch="success"
                                     formControlName="force_admin"
                                     id="force_admin"
                                     type="checkbox"
                              >
                              <label for="force_admin">&nbsp;</label>
                            </div>
                          </div>
                        </div>-->
                        <div class="row">
                            <div class="col-12 text-right">
                                <small class="text-muted">
                                    {{ 'settings.security.2fa_hint' | translate }}
                                </small>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-success">
                                    <span>{{ 'settings.security.2fa_action_save' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <!-- IP whitelist-->
            <div class="card shadow">
                <app-help name="settings.security.ip_address"></app-help>
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-12 text-left my-auto">
                            <p class="modal-title font-16 text-dark pt-2 pb-2">
                                <span>{{ 'settings.security.ip_whitelist_title' | translate }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <form (ngSubmit)="onSubmitIpWhitelist()"
                          [formGroup]="ipWhitelistForm"
                          autocomplete="off">
                        <div class="row">
                            <div class="col-6">
                                <label class="control-label">
                                    <span>{{ 'settings.security.enable_ip_whitelist' | translate }}</span>
                                </label>
                            </div>
                            <div class="col-6 text-right">
                                <input (click)="setCollapse('ipWhitelist')"
                                       [attr.aria-expanded]="!isCollapsed.ipWhitelist"
                                       aria-controls="collapse"
                                       data-switch="success"
                                       formControlName="enabled"
                                       id="enabledWhitelist"
                                       type="checkbox"
                                >
                                <label for="enabledWhitelist">&nbsp;</label>
                            </div>
                        </div>
                        <div #haha="ngbCollapse"
                             [(ngbCollapse)]="!isCollapsed.ipWhitelist">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <div class="control-label d-sm-flex justify-content-between mb-2">
                                            <div class="mb-2 mb-sm-0">
                                                <span>{{ 'settings.security.whitelist' | translate }} </span>
                                                <span class="text-muted">({{ 'settings.security.whitelist_hint' | translate }})</span>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <button (click)="addCurrentIpAddress(settings.clientIPAddress)"
                                                        [disabled]="ipWhitelistForm.get('whitelist').getRawValue().includes(settings.clientIPAddress)"
                                                        class="btn btn-link p-0 font-12 btn-whitelist"
                                                        type="button">
                            <span>{{ 'settings.security.add_this_ip_address' | translate }}:
                                {{ settings.clientIPAddress }}</span>
                                                </button>
                                            </div>

                                        </div>
                                        <div>
                                            <input class="form-control"
                                                   formControlName="whitelist"
                                                   type="text"
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-right">
                                <small class="text-muted">
                                    {{ 'settings.security.ip_whitelist_hint' | translate }}
                                </small>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-success"
                                        type="submit">
                                    <span>{{ 'settings.security.ip_whitelist_action_save' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <!-- Auto logout -->
            <div class="card shadow">
                <app-help name="settings.security.auto_logout"></app-help>
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-12 text-left my-auto">
                            <p class="modal-title font-16 text-dark pt-2 pb-2">
                                <span>{{ 'settings.security.auto_logout_title' | translate }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <form (ngSubmit)="onSubmitAutoLogout()"
                          [formGroup]="autoLogoutForm"
                          autocomplete="off">
                        <div class="row">
                            <div class="col-12 d-flex align-items-center">
                                <label class="control-label mb-0 mr-1 not-required">
                                    <span>{{ 'settings.security.auto_logout' | translate }}</span>
                                </label>
                                <input class="form-control auto-logout-input"
                                       formControlName="timeout"
                                       type="number"
                                       min="5"
                                       max="240"
                                >
                                <label class="control-label mb-0 ml-1">
                                    <span>{{ 'settings.security.auto_logout_2' | translate }}</span>
                                </label>
                            </div>
                            <div class="col-12">
                                <div *ngIf="submitted && autoLogoutForm.controls.timeout.errors">
                                    <p *ngIf="f.timeout.errors.min" class="text-danger">
                                        <span>{{ 'angular_validation.field' | translate }} </span>
                                        <span>{{ 'settings.security.auto_logout_title' | translate }} </span>
                                        <span>{{ 'angular_validation.cannot_be_lower' | translate }} 5</span>
                                    </p>
                                    <p *ngIf="f.timeout.errors.max" class="text-danger">
                                        <span>{{ 'angular_validation.field' | translate }} </span>
                                        <span>{{ 'settings.security.auto_logout_title' | translate }} </span>
                                        <span>{{ 'angular_validation.cannot_be_greater' | translate }} 240</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-right">
                                <small class="text-muted">
                                    {{ 'settings.security.auto_logout_hint' | translate }}
                                </small>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-success">
                                    <span>{{ 'settings.security.2fa_action_save' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <!-- App lock -->
            <div class="card shadow">
                <app-help name="settings.security.lock"></app-help>
                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-12 text-left my-auto">
                            <p class="modal-title font-16 text-dark pt-2 pb-2">
                                <span>{{ 'settings.security.backoffice_access_title' | translate }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <form (ngSubmit)="onSubmitBackofficeAccess()"
                          [formGroup]="backofficeAccessForm"
                          autocomplete="off">
                        <div class="row">
                            <div class="col-6">
                                <label class="control-label">
                                    <span>{{ 'settings.security.backoffice_access_enable' | translate }}</span>
                                </label>
                            </div>
                            <div class="col-6 text-right">
                                <input data-switch="success"
                                       formControlName="enabled"
                                       id="backoffice_access_enabled"
                                       type="checkbox"
                                >
                                <label for="backoffice_access_enabled">&nbsp;</label>
                            </div>
                            <div class="col-12 text-right">
                                <small class="text-muted">
                                    {{ 'settings.security.backoffice_access_enable_hint' | translate }}
                                </small>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-success">
                                    <span>{{ 'settings.security.2fa_action_save' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loadingTemplate>
    <div class="card shadow p-3 mb-3">
        <div class="item">
            <ngx-skeleton-loader [theme]="{
      'margin': '15px 0'
    }" appearance="line" count="4"></ngx-skeleton-loader>
        </div>
    </div>

    <div class="card shadow p-3">
        <div class="item">
            <ngx-skeleton-loader [theme]="{
      'margin': '15px 0'
    }" appearance="line" count="4"></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
