<form [formGroup]="form"
      autocomplete="off"
>
    <fieldset *ngIf="f.born_date || f.born_place || f.personal_in || f.gender || f.health_insurer || f.insurance_number || f.health_condition || f.nationality || f.id_card_number || f.passport_number || f.driving_licence_number" class="fieldset-bordered">
        <legend>{{ 'employees.personal_data.section_personal_info' | translate }}</legend>
        <div class="row">
            <div *ngIf="f.born_date" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.born_date' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                            [class.is-invalid]="submitted && f.born_date.errors"
                            [locale]="locale$ | async"
                            allowInput="true"
                            altFormat="d.m.Y"
                            altInput="true"
                            class="form-control"
                            dateFormat="Y-m-d"
                            formControlName="born_date"
                            mwlFlatpickr
                            name="born_date"
                            type="text"
                        >
                        <div *ngIf="submitted && f.born_date.errors">
                            <p *ngIf="f.born_date.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.born_date' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="f.born_place" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.born_place' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            class="form-control"
                            formControlName="born_place"
                            name="born_place"
                            type="text"
                        >
                        <div *ngIf="submitted && f.born_place.errors">
                            <p *ngIf="f.born_place.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.born_place' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="f.personal_in" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.personal_in' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            (keyup)="inputChanged($event)"
                            [class.is-invalid]="submitted && f.personal_in.errors"
                            class="form-control"
                            formControlName="personal_in"
                            name="personal_in"
                            type="text"
                        >
                        <div *ngIf="submitted && f.personal_in.errors">
                            <p *ngIf="f.personal_in.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.personal_in' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                    <div *ngIf="personalInTaken === true">
                        <p class="text-warning">
                            <span>{{ 'users.personal_in_taken' | translate }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div *ngIf="f.gender" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label" for="employees_gender">
                        <span>{{ 'employees.gender' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <ng-select
                            [class.is-invalid]="submitted && f.gender.errors"
                            [clearable]="false"
                            class="custom"
                            formControlName="gender"
                            id="employees_gender">
                            <ng-option *ngFor="let gender of employeesGenders" [value]="gender">
                                {{ ('employees.gender_option_' + gender) | translate }}
                            </ng-option>
                        </ng-select>
                        <div *ngIf="submitted && f.gender.errors">
                            <p *ngIf="f.gender.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.gender' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="f.health_insurer" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.health_insurer' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            class="form-control"
                            formControlName="health_insurer"
                            name="health_insurer"
                            type="text"
                        >
                        <div *ngIf="submitted && f.health_insurer.errors">
                            <p *ngIf="f.health_insurer.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.health_insurer' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="f.insurance_number" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.insurance_number' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            class="form-control"
                            formControlName="insurance_number"
                            name="insurance_number"
                            type="text"
                        >
                        <div *ngIf="submitted && f.insurance_number.errors">
                            <p *ngIf="f.insurance_number.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.insurance_number' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="f.health_condition" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.health_condition' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            class="form-control"
                            formControlName="health_condition"
                            name="health_condition"
                            type="text"
                        >
                        <div *ngIf="submitted && f.health_condition.errors">
                            <p *ngIf="f.health_condition.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.health_condition' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="f.nationality" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.nationality' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            class="form-control"
                            formControlName="nationality"
                            name="nationality"
                            type="text"
                        >
                        <div *ngIf="submitted && f.nationality.errors">
                            <p *ngIf="f.nationality.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.nationality' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="f.id_card_number" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.id_card_number' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            class="form-control"
                            formControlName="id_card_number"
                            name="id_card_number"
                            type="text"
                        >
                        <div *ngIf="submitted && f.id_card_number.errors">
                            <p *ngIf="f.id_card_number.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.id_card_number' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="f.passport_number" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.passport_number' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            class="form-control"
                            formControlName="passport_number"
                            name="passport_number"
                            type="text"
                        >
                        <div *ngIf="submitted && f.passport_number.errors">
                            <p *ngIf="f.passport_number.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.passport_number' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="f.driving_licence_number" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.driving_licence_number' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            class="form-control"
                            formControlName="driving_licence_number"
                            name="driving_licence_number"
                            type="text"
                        >
                        <div *ngIf="submitted && f.driving_licence_number.errors">
                            <p *ngIf="f.driving_licence_number.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.driving_licence_number' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>

    <fieldset *ngIf="f.personal_email || f.personal_phone" class="fieldset-bordered">
        <legend>{{ 'employees.personal_data.section_contact_info' | translate }}</legend>
        <div class="row">
            <div *ngIf="f.personal_email" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.personal_email' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            [class.is-invalid]="submitted && f.personal_email.errors"
                            class="form-control"
                            formControlName="personal_email"
                            name="personal_email"
                            type="text"
                        >
                    </div>
                    <div *ngIf="submitted && f.personal_email.errors">
                        <p *ngIf="f.personal_email.errors.email" class="text-danger">
                            <span>{{ 'angular_validation.valid_email' | translate }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div *ngIf="f.personal_phone" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.personal_phone' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            [class.is-invalid]="submitted && f.personal_phone.errors"
                            class="form-control"
                            formControlName="personal_phone"
                            name="personal_phone"
                            type="text"
                        >
                        <div *ngIf="submitted && f.personal_phone.errors">
                            <p *ngIf="f.personal_phone.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.personal_phone' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>

    <fieldset *ngIf="f.bank_account_number || f.bank_account_bank_code || f.bank_account_iban" class="fieldset-bordered">
        <legend>{{ 'employees.personal_data.section_bank_info' | translate }}</legend>
        <div class="row">
            <div *ngIf="f.bank_account_number" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.bank_account_number' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            [class.is-invalid]="submitted && f.bank_account_number.errors"
                            class="form-control"
                            formControlName="bank_account_number"
                            name="bank_account_number"
                            type="text"
                        >
                        <div *ngIf="submitted && f.bank_account_number.errors">
                            <p *ngIf="f.bank_account_number.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.bank_account_number' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="f.bank_account_bank_code" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.bank_account_bank_code' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            [class.is-invalid]="submitted && f.bank_account_bank_code.errors"
                            class="form-control"
                            formControlName="bank_account_bank_code"
                            name="bank_account_bank_code"
                            type="text"
                        >
                        <div *ngIf="submitted && f.bank_account_bank_code.errors">
                            <p *ngIf="f.bank_account_bank_code.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.bank_account_bank_code' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="f.bank_account_iban" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.bank_account_iban' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            class="form-control"
                            formControlName="bank_account_iban"
                            name="bank_account_iban"
                            type="text"
                        >
                        <div *ngIf="submitted && f.bank_account_iban.errors">
                            <p *ngIf="f.bank_account_iban.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.bank_account_iban' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>

    <fieldset *ngIf="f.has_pension || f.has_salary_deduction || f.has_employment_agency || f.has_second_pension_pillar || f.is_student || f.has_student_confirmation || f.number_of_children || f.number_of_dependents" class="fieldset-bordered">
        <legend>{{ 'employees.personal_data.section_tax_info' | translate }}</legend>
        <div *ngIf="f.has_pension" class="row">
            <div class="col-6 col-lg-3">
                <label class="control-label">{{ 'employees.has_pension' | translate }}</label>
            </div>
            <div class="col-6 col-lg-3 text-right align-self-center">
                <div>
                    <div [ngClass]="{'hidden-sensitive-value-checkbox': hiddenInputs}">
                        <input data-switch="success" formControlName="has_pension" id="has_pension" name="has_pension" type="checkbox">
                        <label for="has_pension">&nbsp;</label>
                    </div>
                </div>
            </div>
        </div>
        <div
            #collapseTenantID="ngbCollapse"
            [(ngbCollapse)]="!form.value.has_pension"
            *ngIf="!hiddenInputs && f.has_pension"
        >
            <div class="row">
                <div *ngIf="f.pension_type" class="col-12 col-lg-6">
                    <div class="form-group">
                        <label class="control-label">{{ 'employees.pension_type' | translate }}</label>
                        <input class="form-control" formControlName="pension_type" id="pension_type" name="pension_type" type="text">
                        <div *ngIf="submitted && f.pension_type.errors">
                            <p *ngIf="f.pension_type.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.pension_type' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>

                <div *ngIf="f.pension_amount" class="col-12 col-lg-6">
                    <div class="form-group">
                        <label class="control-label">{{ 'employees.pension_amount' | translate }}</label>
                        <input class="form-control" currencyMask formControlName="pension_amount" id="pension_amount" name="pension_amount" type="text">
                        <div *ngIf="submitted && f.pension_amount.errors">
                            <p *ngIf="f.pension_amount.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.pension_amount' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div *ngIf="f.has_salary_deduction" class="col-6 col-lg-3">
                <label class="control-label">{{ 'employees.has_salary_deduction' | translate }}</label>
            </div>
            <div class="col-6 col-lg-3 text-right align-self-center">
                <div>
                    <div [ngClass]="{'hidden-sensitive-value-checkbox': hiddenInputs}">
                        <input data-switch="success" formControlName="has_salary_deduction" id="has_salary_deduction" name="has_salary_deduction" type="checkbox">
                        <label for="has_salary_deduction">&nbsp;</label>
                    </div>
                </div>
            </div>
        </div>

        <div
            #collapseTenantID="ngbCollapse"
            [(ngbCollapse)]="!form.value.has_salary_deduction"
            *ngIf="!hiddenInputs && f.has_salary_deduction"
        >
            <div class="row">
                <div *ngIf="f.salary_deduction_type" class="col-12 col-lg-6">
                    <div class="form-group">
                        <label class="contro-label">{{ 'employees.salary_deduction_type' | translate }}</label>
                        <input class="form-control" formControlName="salary_deduction_type" id="salary_deduction_type" name="salary_deduction_type" type="text">
                        <div *ngIf="submitted && f.salary_deduction_type.errors">
                            <p *ngIf="f.salary_deduction_type.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.salary_deduction_type' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>

                <div *ngIf="f.salary_deduction_amount" class="col-12 col-lg-6">
                    <div class="form-group">
                        <label class="contro-label">{{ 'employees.salary_deduction_amount' | translate }}</label>
                        <input class="form-control" currencyMask formControlName="salary_deduction_amount" id="salary_deduction_amount" name="salary_deduction_amount" type="text">
                        <div *ngIf="submitted && f.salary_deduction_amount.errors">
                            <p *ngIf="f.salary_deduction_amount.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.salary_deduction_amount' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div *ngIf="f.has_employment_agency" class="col-6 col-lg-3">
                <label class="control-label">{{ 'employees.has_employment_agency' | translate }}</label>
            </div>
            <div *ngIf="f.has_employment_agency" class="col-6 col-lg-3 text-right align-self-center">
                <div>
                    <div [ngClass]="{'hidden-sensitive-value-checkbox': hiddenInputs}">
                        <input data-switch="success" formControlName="has_employment_agency" id="has_employment_agency" name="has_employment_agency" type="checkbox">
                        <label for="has_employment_agency">&nbsp;</label>
                    </div>
                </div>
            </div>
            <div *ngIf="f.has_second_pension_pillar" class="col-6 col-lg-3">
                <label class="control-label">{{ 'employees.has_second_pension_pillar' | translate }}</label>
            </div>
            <div *ngIf="f.has_second_pension_pillar" class="col-6 col-lg-3 text-right align-self-center">
                <div>
                    <div [ngClass]="{'hidden-sensitive-value-checkbox': hiddenInputs}">
                        <input data-switch="success" formControlName="has_second_pension_pillar" id="has_second_pension_pillar" name="has_second_pension_pillar" type="checkbox">
                        <label for="has_second_pension_pillar">&nbsp;</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div *ngIf="f.is_student" class="col-6 col-lg-3">
                <label class="control-label mb-0">{{ 'employees.is_student' | translate }}</label>
            </div>
            <div *ngIf="f.is_student" class="col-6 col-lg-3 text-right align-self-center">
                <div>
                    <div [ngClass]="{'hidden-sensitive-value-checkbox': hiddenInputs}">
                        <input data-switch="success" formControlName="is_student" id="is_student" name="is_student" type="checkbox">
                        <label for="is_student">&nbsp;</label>
                    </div>
                </div>
            </div>

            <div *ngIf="f.has_student_confirmation" class="col-6 col-lg-3">
                <label class="control-label">{{ 'employees.has_student_confirmation' | translate }}</label>
            </div>
            <div *ngIf="f.has_student_confirmation" class="col-6 col-lg-3 text-right align-self-center">
                <div>
                    <div [ngClass]="{'hidden-sensitive-value-checkbox': hiddenInputs}">
                        <input data-switch="success" formControlName="has_student_confirmation" id="has_student_confirmation" name="has_student_confirmation" type="checkbox">
                        <label for="has_student_confirmation">&nbsp;</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div *ngIf="f.number_of_children" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">{{ 'employees.number_of_children' | translate }}</label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <ng-select
                            [clearable]="false"
                            class="custom"
                            formControlName="number_of_children"
                        >
                            <ng-container *ngFor="let i of numberPicker">
                                <ng-option value="{{ i }}"> {{ i }}</ng-option>
                            </ng-container>
                        </ng-select>
                        <div *ngIf="submitted && f.number_of_children.errors">
                            <p *ngIf="f.number_of_children.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.number_of_children' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="f.number_of_dependents" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">{{ 'employees.number_of_dependents' | translate }}</label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <ng-select
                            [clearable]="false"
                            class="custom"
                            formControlName="number_of_dependents"
                        >
                            <ng-container *ngFor="let i of numberPicker">
                                <ng-option value="{{ i }}"> {{ i }}</ng-option>
                            </ng-container>
                        </ng-select>
                        <div *ngIf="submitted && f.number_of_dependents.errors">
                            <p *ngIf="f.number_of_dependents.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.number_of_dependents' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>

    <fieldset *ngIf="f.in || f.tin || f.has_food_license" class="mb-3 fieldset-bordered">
        <legend>{{ 'employees.personal_data.section_other_info' | translate }}</legend>
        <div class="row">
            <div *ngIf="f.in" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.in' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            (keyup)="inputChanged($event)"
                            class="form-control"
                            formControlName="in"
                            name="in"
                            type="text"
                        >
                        <div *ngIf="submitted && f.in.errors">
                            <p *ngIf="f.in.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.in' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                    <div *ngIf="inTaken === true">
                        <p class="text-warning">
                            <span>{{ 'users.in_taken' | translate }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div *ngIf="f.tin" class="col-12 col-lg-6">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.tin' | translate }}</span>
                    </label>
                    <div [ngClass]="{'hidden-sensitive-value-input': hiddenInputs}">
                        <input
                            class="form-control"
                            formControlName="tin"
                            name="tin"
                            type="text"
                        >
                        <div *ngIf="submitted && f.tin.errors">
                            <p *ngIf="f.tin.errors.required" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }} {{'employees.tin' | translate}}
                                {{'angular_validation.required' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="f.has_food_license" class="col-6 col-lg-3">
                <label class="control-label">{{ 'employees.has_food_license' | translate }}</label>
            </div>
            <div *ngIf="f.has_food_license" class="col-6 col-lg-3 text-right align-self-center">
                <div [ngClass]="{'hidden-sensitive-value-checkbox': hiddenInputs}">
                    <input data-switch="success" formControlName="has_food_license" id="has_food_license" name="has_food_license" type="checkbox">
                    <label for="has_food_license">&nbsp;</label>
                </div>
            </div>
        </div>
    </fieldset>

    <app-custom-fields *ngIf="employeePersonalData?.custom_fields"
                       [customFields]="employeePersonalData.custom_fields"
                       [sensitive]="true"
                       [submitted]="submitted"
                       [fieldset]="true"
                       [columns]="2"
                       [formConfig]="formConfig"
                       [form]="form">
    </app-custom-fields>

    <div *ngIf="emailTaken || personalInTaken || inTaken" class="row">
        <div class="col">
            <div *ngIf="emailTaken" class="text-danger">{{ 'users.email_taken' | translate }}</div>
            <div *ngIf="personalInTaken" class="text-warning">{{ 'users.personal_in_taken' | translate }}</div>
            <div *ngIf="inTaken" class="text-warning">{{ 'users.in_taken' | translate }}</div>
        </div>
    </div>
</form>
