import { Component } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { Note } from 'src/app/note/state/note.model';
import { SummernoteHelper } from 'src/app/shared/common/SummernoteHelper';

@Component({
    selector: 'app-note-modal',
    templateUrl: './note-modal.component.html',
    styleUrls: ['./note-modal.component.css']
})
export class NoteModalComponent {

    public canEdit$: Observable<boolean>;
    public note$: Observable<Note>;

    private _note: Note;

    public set note(note: Note) {
        this._note = note;
        this.editNoteForm.patchValue({
            text: this.note.text,
        });
    }

    public get note(): Note {
        return this._note;
    }

    public editNoteForm: UntypedFormGroup;

    public submitted = false;

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.editNoteForm.controls;
    }

    public constructor(
        public activeModal: NgbActiveModal,
        public summernoteHelper: SummernoteHelper,
        private _fb: UntypedFormBuilder,
    ) {
        this._init();
    }

    private _init(): void {
        this.editNoteForm = this._fb.group({
            text: '',
        });

        this.canEdit$ = of(true);
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.editNoteForm.valid) {
            return;
        }

        const formValue = this.editNoteForm.value;

        this.activeModal.close(formValue);
    }
}
