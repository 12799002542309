import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cacheable } from '@datorama/akita';
import { combineLatest, merge, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, map, shareReplay, skip, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Help } from 'src/app/help/state/help.model';
import { HelpStore } from 'src/app/help/state/help.store';
import { ApiResponse } from '../models/ApiResponse';
import { HelpKeys } from '../models/HelpKeys';

interface ModalHelp extends Partial<Record<keyof HelpKeys, boolean>> {}

export interface WidgetHelp {
    content: string;
    description: string;
    url: string;
}

@Injectable({
    providedIn: 'root'
})
export class HelpService {
    public helpKey$: Observable<keyof HelpKeys>;
    public helpData$: Observable<Array<Help>>;

    private _helpData$ = new ReplaySubject<Array<Help>>(1);
    private _helpKey$ = new ReplaySubject<keyof HelpKeys | null>(1);
    private _helpUpdate$ = new ReplaySubject<ModalHelp>(1);
    private _modalHelpForCurrentUser$: Observable<ModalHelp | null>;

    public constructor(
        private _authService: AuthenticationService,
        private _helpStore: HelpStore,
        private _http: HttpClient
    ) {
        this.helpData$ = this._helpData$;
        this._init();
    }

    public get(): Observable<void> {
        const request = this._http.get<Array<Help>>('/api/help')
            .pipe(map(response => this._helpStore.set(response)), shareReplay());

        return cacheable(this._helpStore, request);
    }

    public getHelpData(): Observable<Array<Help>> {
        return this._http.get<Array<Help>>('/api/help')
            .pipe(
                tap(data => this._helpData$.next(data)),
                shareReplay()
            );
    }

    public getWidgetHelpByName(name: string): Observable<WidgetHelp> {
        let params = null;

        if (name) {
            params = new HttpParams()
                .set('name', name);
        }

        return this._http.get<WidgetHelp>('/api/help', {params});
    }

    public markModalHelpAsSeen(helpKey: keyof HelpKeys): Observable<ApiResponse | null> {
        return this._authService.loggedUser$
            .pipe(
                withLatestFrom(this._modalHelpForCurrentUser$),
                switchMap(([user, modalHelp]) => {
                    this._helpUpdate$.next({
                        ...modalHelp,
                        [helpKey]: true
                    });

                    let url: string | null;

                    if (user?.user_ID) {
                        url = `/api/users/${user.user_ID}/help-view`;
                    } else {
                        url = null;
                    }

                    if (url) {
                        return this._http.post<ApiResponse>(url, {help_view: helpKey})
                            .pipe(catchError(() => of(null)));
                    } else {
                        return of(null);
                    }
                })
            );
    }

    public setKey(key: keyof HelpKeys): void {
        this._helpKey$.next(key);
    }

    public getModalHelpForCurrentUser(): Observable<ModalHelp | null> {
        return this._authService.loggedUser$
            .pipe(
                switchMap(user => {
                    if (user?.user_ID) {
                        return this._http.get<ModalHelp>(`/api/users/${user.user_ID}/help-view`);
                    } else {
                        return of(null);
                    }
                })
            );
    }

    private _init(): void {
        this._modalHelpForCurrentUser$ = merge(
            this.getModalHelpForCurrentUser(),
            this._helpUpdate$
        )
            .pipe(shareReplay());

        // We are using combineLatest’s feature
        // of waiting for all observables to emit
        // at least once,
        // then, after emitting first value,
        // we are switching to withLatestFrom
        // as we don’t need to update on every user
        // profile refresh
        this.helpKey$ = merge(
            combineLatest([
                this._helpKey$,
                this._modalHelpForCurrentUser$
            ])
                .pipe(
                    take(1)
                ),

            this._helpKey$
                .pipe(
                    skip(1),
                    withLatestFrom(this._modalHelpForCurrentUser$)
                )
        )
            .pipe(
                filter(([helpKey, noop]) => typeof helpKey !== 'undefined'),
                map(([helpKey, noop]) => helpKey)
            );
    }
}
