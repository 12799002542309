import { Injectable } from '@angular/core';

import { WindowRef } from 'src/app/ui/window';

export interface IScriptStatus {
    loaded: boolean;
    value?: any;
}

@Injectable({
    providedIn: 'root'
})
export class ScriptLoader {
    private _checkIntervals: {
        [url: string]: NodeJS.Timer;
    } = {};

    public constructor(private _windowRef: WindowRef) { }

    public exists(url: string): boolean {
        return this._getScriptElement(url) && true || false;
    }

    /**
     * Appends an external script
     *
     * @param url URL of a script to be loaded
     * @param propertyToLookFor If provided, continuously checks for its existence – does NOT resolve until found,
     * nesting of maximum 3 allowed
     */
    public load(url: string, propertyToLookFor?: string): Promise<IScriptStatus> {
        return new Promise((resolve, reject) => {
            if (!url) {
                reject(new Error('No script URL provided'));
            }

            if (propertyToLookFor) {
                if (typeof propertyToLookFor !== 'string') {
                    reject(new Error('Property to look for is NOT of type string'));
                } else if ((propertyToLookFor.match(/\./g) || []).length > 3) {
                    reject(new Error('Maximum nesting (3) breached'));
                }
            }

            const scriptElement = this._getScriptElement(url);

            if (!scriptElement) {
                this._appendScript(url);
            }

            if (!propertyToLookFor) {
                resolve({
                    loaded: true
                });
            } else {
                this._checkIntervalCreate(
                    url,
                    propertyToLookFor,
                    value => resolve({
                        loaded: true,
                        value
                    })
                );
            }
        });
    }

    private _appendScript(url: string): void {
        const nativeWindow = this._getWindow();
        const windowDocument = this._getWindowDocument(nativeWindow);

        const scriptElement = windowDocument.createElement('script');
        scriptElement.src = url;

        windowDocument.body.appendChild(scriptElement);
    }

    private _checkIntervalClear(url: string): void {
        if (this._checkIntervals[url]) {
            clearInterval(this._checkIntervals[url]);
        }
    }

    private _checkIntervalCreate(url: string, propertyToLookFor: string, callback: (value: any) => void): void {
        this._checkIntervalClear(url);

        const nativeWindow = this._getWindow();

        const [part1, part2, part3] = propertyToLookFor.split('.');

        this._checkIntervals[url] = setInterval(() => {
            let found = false;
            let value;

            if (
                part1 &&
                nativeWindow[part1]
            ) {
                if (part2) {
                    if (nativeWindow[part1][part2]) {
                        if (part3) {
                            if (nativeWindow[part1][part2][part3]) {
                                found = true;

                                value = nativeWindow[part1][part2][part3];
                            }
                        } else {
                            found = true;

                            value = nativeWindow[part1][part2];
                        }
                    }
                } else {
                    found = true;

                    value = nativeWindow[part1];
                }
            }

            if (found === true) {
                callback(value);

                this._checkIntervalClear(url);
            }
        }, 50);
    }

    private _getScriptElement(url: string): HTMLScriptElement {
        const scripts = this._getScriptElements();

        return scripts.find(script => script && script.src === url) || null;
    }

    private _getScriptElements(): Array<HTMLScriptElement> {
        const nativeWindow = this._getWindow();
        const windowDocument = this._getWindowDocument(nativeWindow);

        return Array.from(windowDocument.getElementsByTagName('script'));
    }

    private _getWindow(): any {
        if (
            !this._windowRef ||
            !this._windowRef.nativeWindow
        ) {
            throw new Error('Window unavailable');
        }

        return this._windowRef.nativeWindow;
    }

    private _getWindowDocument(nativeWindow: any): any {
        if (
            !nativeWindow ||
            !nativeWindow.document
        ) {
            throw new Error('Document unavailable');
        }

        if (typeof nativeWindow.document.createElement !== 'function') {
            throw new Error('Function createElement unavailable');
        }

        if (typeof nativeWindow.document.getElementsByTagName !== 'function') {
            throw new Error('Function getElementsByTagName unavailable');
        }

        return nativeWindow.document;
    }
}
