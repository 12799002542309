import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchResult } from '../models/SearchResult';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    constructor(
        private http: HttpClient
    ) { }

    public search(q: string, objectType = null, uniqueColumn = null): Observable<SearchResult[]> {
        let params = new HttpParams();
        if (q) {
            params = params.set('q', q);
        }
        if (objectType) {
            params = params.set('object_type', objectType);
        }
        if (uniqueColumn) {
            params = params.set('unique_column', uniqueColumn);
        }
        return this.http.get<SearchResult[]>('/api/search', {params}).pipe(map((results: any) => results.result));
    }
}
