import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import {
  UIButtonModule,
  UIDataTableModule,
  UIItemsPerPageSelectModule,
  UIPaginationModule
} from '../ui';

import { PolicyRoutingModule } from './policy-routing.module';

import { CompanyPolicyListComponent } from './components/sections/company-policy-list/company-policy-list.component';

import { CreateEditEmployeePolicyModalComponent } from './components/partials/create-edit-employee-policy-modal/create-edit-employee-policy-modal.component';


@NgModule({
  declarations: [
    CompanyPolicyListComponent,
    CreateEditEmployeePolicyModalComponent
  ],
  imports: [
    PolicyRoutingModule,

    SharedModule,

    UIButtonModule,
    UIDataTableModule,
    UIItemsPerPageSelectModule,
    UIPaginationModule
  ],
  exports: [
    CompanyPolicyListComponent
  ]
})
export class PolicyModule { }
