<ng-template
    #filterFormModal
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'term_watch.adjust_filters' | translate }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <form
                (ngSubmit)="onSubmit()"
                [formGroup]="filterForm"
                class="filter-form"
            >
                <fieldset>
                    <legend>{{ 'documents.filter_document' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'documents.filter_date_from' | translate }}</label>

                                <input
                                    [locale]="locale$ | async"
                                    allowInput="true"
                                    altFormat="d.m.Y"
                                    altInput="true"
                                    class="form-control"
                                    dateFormat="Y-m-d"
                                    formControlName="from"
                                    mwlFlatpickr
                                    type="text"
                                    (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                >
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'documents.filter_date_to' | translate }}</label>

                                <input
                                    [locale]="locale$ | async"
                                    allowInput="true"
                                    altFormat="d.m.Y"
                                    altInput="true"
                                    class="form-control"
                                    dateFormat="Y-m-d"
                                    formControlName="to"
                                    mwlFlatpickr
                                    type="text"
                                    (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                >
                            </div>
                        </div>
                    </div>

                    <div *ngIf="allowFilterByTemplate" class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'documents.filter_template' | translate }}</label>

                                <ng-select
                                    [bindValue]="'key'"
                                    [bindLabel]="'name'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [multiple]="true"
                                    [items]="documentTemplates$ | async"
                                    class="custom"
                                    formControlName="document_types"
                                >
                                    <ng-template ng-multi-label-tmp>
                                        <span
                                            class="ng-placeholder"
                                            style="display: block;"
                                        >
                                          <span>{{ 'documents.filter_templates_count' | translate }}</span>
                                          <span> (</span>
                                          <span>{{ getCount('document_types') }}</span>
                                          <span>)</span>
                                        </span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>{{ 'documents.filter_employee' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'documents.filter_employee' | translate }}</label>

                                <ng-select
                                    [bindValue]="'employee_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="employees$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="employee_IDs"
                                >
                                    <ng-template ng-multi-label-tmp>
                                        <span
                                            class="ng-placeholder"
                                            style="display: block;"
                                        >
                                          <span>{{ 'documents.filter_employees_count' | translate }}</span>
                                          <span> (</span>
                                          <span>{{ getCount('employee_IDs') }}</span>
                                          <span>)</span>
                                        </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        />

                                        <span>&nbsp;</span>
                                        <span>{{ item.fullname }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'documents.filter_sign' | translate }}</label>

                                <ng-select
                                    [bindValue]="'value'"
                                    [bindLabel]="'label'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="signatureTypes"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="employee_signature_types"
                                ></ng-select>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend>{{ 'documents.filter_responsible' | translate }}</legend>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'documents.filter_employee' | translate }}</label>

                                <ng-select
                                    [bindValue]="'employee_ID'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="employees$ | async"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="responsible_employee_IDs"
                                >
                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'documents.filter_employees_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('responsible_employee_IDs') }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template
                                        let-index="index"
                                        let-item="item"
                                        let-item$="item$"
                                        ng-option-tmp
                                    >
                                        <input
                                            [checked]="item$.selected"
                                            id="item-{{index}}"
                                            type="checkbox"
                                        />

                                        <span>&nbsp;</span>
                                        <span>{{ item.fullname }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{ 'documents.filter_sign' | translate }}</label>

                                <ng-select
                                    [bindValue]="'value'"
                                    [bindLabel]="'label'"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="signatureTypes"
                                    [multiple]="true"
                                    class="custom"
                                    formControlName="responsible_employee_signature_types"
                                ></ng-select>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div class="row">
                    <div class="col-12 text-right">
                        <button class="btn btn-primary">
                            <span>{{ 'documents.action_filter' | translate }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<div class="row">
    <div class="col-12">
    <div class="card shadow">
      <app-help name="documents.list"></app-help>
        <div *ngIf="showHeader" class="card-header py-3">
            <div class="row">
                <div class="col-6 text-left my-auto">
                    <p class="modal-title font-16 text-dark pt-2 pb-2">
                        <span>{{ 'settings.company.documents_templates.documents' | translate }}</span>
                    </p>
                </div>
                <div class="col-6 text-right align-self-center d-flex justify-content-end">
                    <app-export [stream$]="dataToExport$"></app-export>
                    <button
                        (click)="refreshData()"
                        class="btn btn-icon btn-primary mdi mdi-refresh ml-sm-2"
                    ></button>
                    <button (click)="openFilterFormModal()"
                            [formGroup]="filterForm"
                            class="btn btn-primary btn-icon ml-sm-2"
                            type="button"
                    >
                        <i class="mdi mdi-filter-outline"></i>
                    </button>
                    <span *ngIf="documentTemplate" [ngbTooltip]="!documentTemplate.can_bulk_generate ? ('document_templates.cannot_bulk_generate' | translate) : ''"
                          container="body"
                          placement="top-end">
                                <button [disabled]="!documentTemplate.can_bulk_generate"
                                        (click)="openTemplateGenerationModal(documentTemplate)"
                                        [createButton]="'settings.company.documents_templates.action_create_generation' | translate"
                                        class="ml-sm-2"
                                ></button>
                            </span>
                </div>
            </div>
        </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <app-data-table
              [customNoDataMessage]="'documents.empty_documents_widget_error' | translate"
              [disableInPlaceSorting]="true"
              [loading]="loading"
              [rows]="rows"
              [search]="search"
              [sort]="sort"
              [trackByFn]="trackByFn"
              (searchChanged)="searchChanged($event)"
              (sortChanged)="sortChanged($event)"
            >
                  <ng-template *ngIf="!showHeader"
                      #headerButton
                  >
                      <app-export [stream$]="dataToExport$"></app-export>
                      <button (click)="openFilterFormModal()"
                              [formGroup]="filterForm"
                              class="btn btn-primary btn-icon ml-sm-2"
                              type="button"
                      >
                          <i class="mdi mdi-filter-outline"></i>
                      </button>
                  </ng-template>
              <ng-template #headerRow>
                <th [disableSort]="true"
                  appDataTableHeaderCell="employee"
                  >{{ 'documents.employee' | translate }}</th>

                <th [disableSort]="true"
                  appDataTableHeaderCell="name"
                  >{{ 'documents.document' | translate }}</th>

                <th
                appDataTableHeaderCell="exposed_at"
                >{{ 'settings.company.documents_templates.generated_at' | translate }}</th>

                  <th *ngIf="(availableFeatures$ | async)?.signatures" [disableSort]="true" appDataTableHeaderCell="name">
                      {{ 'settings.company.documents_templates.owner_signature' | translate }}
                  </th>
                  <th *ngIf="(availableFeatures$ | async)?.signatures" [disableSort]="true" appDataTableHeaderCell="name">
                      {{ 'settings.company.documents_templates.responsible_signature' | translate }}
                  </th>

                <th
                [disableSort]="true"
                appDataTableHeaderCell
                class="text-right"
                >{{ 'settings.company.documents_templates.action' | translate }}</th>
              </ng-template>

              <ng-template
                #dataRow
                let-row
              >
              <td appDataTableDataCell class="w-sm-15">
                <app-employee-box-avatar [employee]="row.owner"></app-employee-box-avatar>
              </td>

                <td appDataTableDataCell>
                  <div>
                  {{ row.name }}
                  </div>
                </td>

                <td appDataTableDataCell>
                  <div>
                  {{ row.created_at | date: 'dd.MM.yyyy' }}
                  </div>
                </td>

                  <td *ngIf="(availableFeatures$ | async)?.signatures" appDataTableDataCell>
                      <div *ngIf="!row.signatures.is_signed_by_owner && !row.signatures.has_to_be_signed_by_owner">
                          {{ 'settings.company.documents_templates.sign_not_required' | translate }}
                      </div>
                      <div *ngIf="!row.signatures.is_signed_by_owner && row.signatures.has_to_be_signed_by_owner" class="text-danger">
                          {{ 'settings.company.documents_templates.sign_required' | translate }}
                      </div>
                      <div *ngIf="row.signatures.is_signed_by_owner" class="text-success">
                          {{ 'settings.company.documents_templates.signed' | translate }}
                      </div>
                  </td>

                  <td *ngIf="(availableFeatures$ | async)?.signatures" appDataTableDataCell>
                      <div *ngIf="!row.signatures.is_signed_by_responsible && !row.signatures.has_to_be_signed_by_responsible">
                          {{ 'settings.company.documents_templates.sign_not_required' | translate }}
                      </div>
                      <div *ngIf="!row.signatures.is_signed_by_responsible && row.signatures.has_to_be_signed_by_responsible" class="text-danger">
                          {{ 'settings.company.documents_templates.sign_required' | translate }}
                      </div>
                      <div *ngIf="row.signatures.is_signed_by_responsible" class="text-success">
                          {{ 'settings.company.documents_templates.signed' | translate }}
                      </div>
                  </td>

                <td
                  appDataTableDataCell
                  class="text-right"
                >
                    <a [routerLink]="['/', 'file-detail', 'document-template-document', row.type ,'file', row.file?.hash, 'detail']" class="btn btn-white btn-icon btn-sm mr-1" title="{{ 'settings.company.documents_templates.action_view_document' | translate }}">
                        <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                  </a>
                    <a (click)="deleteDocument(row.document_ID)" class="btn btn-white btn-icon btn-sm" title="{{ 'settings.company.documents_templates.action_delete_document' | translate }}">
                        <i class="mdi mdi-trash-can-outline mdi-24px text-muted"></i>
                    </a>
                </td>
              </ng-template>
            </app-data-table>
            </div>
          </div>
        </div>

        <div class="row mt-2 flex-column-reverse flex-sm-row">
          <div class="col-12 col-sm-2">
            <app-items-per-page-select
                (itemsPerPageChanged)="itemsPerPageChanged($event)"
                [itemsPerPage]="itemsPerPage"
                [total]="itemsTotal"
            ></app-items-per-page-select>
          </div>

          <div class="col-12 col-sm-10">
            <app-pagination
                (pageChanged)="pageChanged($event)"
                [itemsPerPage]="itemsPerPage"
                [page]="page"
                [total]="itemsTotal"
            ></app-pagination>
          </div>
        </div>
      </div>
    </div>
    </div>
</div>
