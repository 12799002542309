import { AfterViewInit, Directive, ElementRef, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';

@Directive({
    selector: 'ng-select[multiple]'
})
export class SelectAllDirective implements AfterViewInit {

    @Input() public multiple = false;
    public allOptions: any = [];

    constructor(
        @Optional() public control: NgControl,
        @Optional() public select: NgSelectComponent,
        public el: ElementRef,
        private _translateService: TranslateService,
    ) { }

    public ngAfterViewInit(): void {
        if (this.multiple && this.control.status !== 'DISABLED') {
            this.initButtons();
        }
    }

    public initButtons(): void {
        const div = document.createElement('div');
        const selectButton = document.createElement('button');
        const unselectButton = document.createElement('button');
        this.select.clearSearchOnAdd = true;
        div.classList.add('text-right', 'mt-1');

        selectButton.setAttribute('type', 'button');
        selectButton.classList.add('btn', 'btn-link', 'p-0', 'mr-1');
        selectButton.innerHTML = this._translateService.instant('global.select_all');
        selectButton.onclick = () => this.onClickSelect();

        unselectButton.setAttribute('type', 'button');
        unselectButton.classList.add('btn', 'btn-link', 'p-0');
        unselectButton.innerHTML = this._translateService.instant('global.unselect_all');
        unselectButton.onclick = () => this.onClickUnselect();

        this.el.nativeElement.insertAdjacentElement('afterend', div);
        div.insertAdjacentElement('beforeend', selectButton);
        div.insertAdjacentElement('beforeend', unselectButton);
    }

    public onClickSelect(): void {
        this.allOptions = this.select.bindValue ? this.select.items.map((item: any) => item[this.select.bindValue]) : this.select.items;
        this.control.control.patchValue(this.allOptions);
    }

    public onClickUnselect(): void {
        this.control.control.patchValue([]);
    }
}
