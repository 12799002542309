<form [formGroup]="form">
    <div *ngIf="f.addr_line_1" class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">{{'employees_addresses.addr_line_1' | translate}}</label>
                <input [ngClass]="{ 'is-invalid': submitted && f.addr_line_1.errors }" class="form-control" formControlName="addr_line_1" name="addr_line_1" type="text">
                <div *ngIf="submitted && f.addr_line_1.errors">
                    <p *ngIf="f.addr_line_1.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'employees_addresses.addr_line_1' | translate}}
                        {{'angular_validation.required' | translate }}
                    </p>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="f.addr_line_2" class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">{{'employees_addresses.addr_line_2' | translate}}</label>
                <input class="form-control" formControlName="addr_line_2" name="addr_line_2" type="text">
                <div *ngIf="submitted && f.addr_line_2.errors">
                    <p *ngIf="f.addr_line_2.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'employees_addresses.addr_line_2' | translate}}
                        {{'angular_validation.required' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="f.city" class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">{{'employees_addresses.city' | translate}}</label>
                <input [ngClass]="{ 'is-invalid': submitted && f.city.errors }" class="form-control" formControlName="city" name="city" type="text">
                <div *ngIf="submitted && f.city.errors">
                    <p *ngIf="f.city.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'employees_addresses.city' | translate}}
                        {{'angular_validation.required' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="f.postal_code" class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">{{'employees_addresses.postal_code' | translate}}</label>
                <input [ngClass]="{ 'is-invalid': submitted && f.postal_code.errors }" class="form-control" formControlName="postal_code" name="postal_code" type="text">
                <div *ngIf="submitted && f.postal_code.errors">
                    <p *ngIf="f.postal_code.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'employees_addresses.postal_code' | translate}}
                        {{'angular_validation.required' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="f.country_code" class="row">
        <div [ngClass]="{'col-lg-12': isQuestionnaire, 'col-lg-6': !isQuestionnaire}">
            <div class="form-group">
                <label class="control-label">{{'employees_addresses.country_code' | translate}}</label>
                <ng-select
                    [clearable]="false"
                    [items]="countryStoreService.countries$ | async"
                    bindLabel="name"
                    bindValue="country_code"
                    class="custom"
                    formControlName="country_code"
                    name="country_code"
                >
                </ng-select>
                <div *ngIf="submitted && f.country_code.errors">
                    <p *ngIf="f.country_code.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'employees_addresses.country_code' | translate}}
                        {{'angular_validation.required' | translate }}
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="f.type && !isQuestionnaire" class="col-lg-6">
            <div class="form-group">
                <label class="control-label">{{ 'employees_addresses.address_type.label' | translate }}</label>
                <ng-select
                    formControlName="type"
                    class="custom"
                    [items]="[
                        {value: 'DOMICILE', label: ('employees_addresses.address_type.DOMICILE' | translate)},
                        {value: 'RESIDENCE', label: ('employees_addresses.address_type.RESIDENCE' | translate)},
                        {value: 'OTHER', label: ('employees_addresses.address_type.OTHER' | translate)}
                    ]"
                    bindValue="value"
                    bindLabel="label"
                    [clearable]="false"
                ></ng-select>
                <div *ngIf="submitted && f.type.errors">
                    <p *ngIf="f.type.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'employees_addresses.country_code' | translate}}
                        {{'angular_validation.required' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="f.description" class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">{{'accounts_moves.description' | translate}}</label>
                <input [ngClass]="{ 'is-invalid': submitted && f.description.errors }" class="form-control" formControlName="description" name="description" type="text">
                <div *ngIf="submitted && f.description.errors">
                    <p *ngIf="f.description.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{'accounts_moves.description' | translate}}
                        {{'angular_validation.required' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- second residence address ONLY FOR QUESTIONNAIRE -->
    <ng-container *ngIf="isQuestionnaire">
        <hr>
        <div class="row">
            <div class="col-6">
                <label class="control-label mr-2" for="editEnabled">{{ 'employees_addresses.show_residence' | translate }}</label>
            </div>
            <div class="col-6 text-right">
                <input
                    [checked]="showResidenceAddress"
                    (change)="toggleResidenceAddress()"
                    data-switch="success"
                    id="editEnabled"
                    name="editEnabled"
                    type="checkbox"
                >
                <label for="editEnabled">&nbsp;</label>
            </div>
        </div>
    </ng-container>

    <ng-container [formGroupName]="'employee_address_residence'" *ngIf="showResidenceAddress">
        <div *ngIf="f.addr_line_1" class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <label [ngClass]="{'required-label': f.addr_line_1.hasValidator(Validators.required)}" class="control-label">{{'employees_addresses.addr_line_1' | translate}}</label>
                    <input [ngClass]="{ 'is-invalid': submitted && f.addr_line_1.errors }" class="form-control" formControlName="addr_line_1" name="addr_line_1" type="text">
                    <div *ngIf="submitted && f.addr_line_1.errors">
                        <p *ngIf="f.addr_line_1.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{'employees_addresses.addr_line_1' | translate}}
                            {{'angular_validation.required' | translate }}
                        </p>
                    </div>
                </div>
            </div>

        </div>
        <div *ngIf="f.addr_line_2" class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <label [ngClass]="{'required-label': f.addr_line_2.hasValidator(Validators.required)}" class="control-label">{{'employees_addresses.addr_line_2' | translate}}</label>
                    <input class="form-control" formControlName="addr_line_2" name="addr_line_2" type="text">
                    <div *ngIf="submitted && f.addr_line_2.errors">
                        <p *ngIf="f.addr_line_2.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{'employees_addresses.addr_line_2' | translate}}
                            {{'angular_validation.required' | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="f.city" class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <label [ngClass]="{'required-label': f.city.hasValidator(Validators.required)}" class="control-label">{{'employees_addresses.city' | translate}}</label>
                    <input [ngClass]="{ 'is-invalid': submitted && f.city.errors }" class="form-control" formControlName="city" name="city" type="text">
                    <div *ngIf="submitted && f.city.errors">
                        <p *ngIf="f.city.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{'employees_addresses.city' | translate}}
                            {{'angular_validation.required' | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="f.postal_code" class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <label [ngClass]="{'required-label': f.postal_code.hasValidator(Validators.required)}" class="control-label">{{'employees_addresses.postal_code' | translate}}</label>
                    <input [ngClass]="{ 'is-invalid': submitted && f.postal_code.errors }" class="form-control" formControlName="postal_code" name="postal_code" type="text">
                    <div *ngIf="submitted && f.postal_code.errors">
                        <p *ngIf="f.postal_code.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{'employees_addresses.postal_code' | translate}}
                            {{'angular_validation.required' | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="f.country_code" class="row">
            <div [ngClass]="{'col-lg-12': isQuestionnaire, 'col-lg-6': !isQuestionnaire}">
                <div class="form-group">
                    <label [ngClass]="{'required-label': f.country_code.hasValidator(Validators.required)}" class="control-label">{{'employees_addresses.country_code' | translate}}</label>
                    <ng-select
                        [clearable]="false"
                        [items]="countryStoreService.countries$ | async"
                        bindLabel="name"
                        bindValue="country_code"
                        class="custom"
                        formControlName="country_code"
                        name="country_code"
                    >
                    </ng-select>
                    <div *ngIf="submitted && f.country_code.errors">
                        <p *ngIf="f.country_code.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{'employees_addresses.country_code' | translate}}
                            {{'angular_validation.required' | translate }}
                        </p>
                    </div>
                </div>
            </div>
            <div *ngIf="f.type && !isQuestionnaire" class="col-lg-6">
                <div class="form-group">
                    <label class="control-label">{{ 'employees_addresses.address_type.label' | translate }}</label>
                    <ng-select
                        formControlName="type"
                        class="custom"
                        [items]="[
                        {value: 'DOMICILE', label: ('employees_addresses.address_type.DOMICILE' | translate)},
                        {value: 'RESIDENCE', label: ('employees_addresses.address_type.RESIDENCE' | translate)},
                        {value: 'OTHER', label: ('employees_addresses.address_type.OTHER' | translate)}
                    ]"
                        bindValue="value"
                        bindLabel="label"
                        [clearable]="false"
                    ></ng-select>
                    <div *ngIf="submitted && f.type.errors">
                        <p *ngIf="f.type.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{'employees_addresses.country_code' | translate}}
                            {{'angular_validation.required' | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="f.description" class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <label [ngClass]="{'required-label': f.description.hasValidator(Validators.required)}" class="control-label">{{'accounts_moves.description' | translate}}</label>
                    <input [ngClass]="{ 'is-invalid': submitted && f.description.errors }" class="form-control" formControlName="description" name="description" type="text">
                    <div *ngIf="submitted && f.description.errors">
                        <p *ngIf="f.description.errors.required" class="text-danger validation-text">
                            {{ 'angular_validation.field' | translate }} {{'accounts_moves.description' | translate}}
                            {{'angular_validation.required' | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</form>
