import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ImportDataWithMappings } from 'src/app/import/interface/import-data-with-mappings.interface';
import { ImportPreview } from 'src/app/import/interface/import-preview.interface';
import { ImportService } from 'src/app/import/state/import.service';
import { sortArrayOfObjectsByName } from 'src/app/utils/array.functions';
import { ImportTemplate } from '../../../interface/import-template.interface';

interface SelectItem {
    name: string;
    value: string;
    required?: boolean;
    entity?: string;
}

interface ImportFile {
    name: string;
    download_name: string;
    locale: string;
    entity?: 'EMPLOYEE';
}

interface SelectedColumn extends SelectItem {
    index: number;
}

@Component({
    selector: 'app-import-preview',
    templateUrl: './import-preview.component.html',
    styleUrls: ['./import-preview.component.css']
})
export class ImportPreviewComponent implements OnInit, OnDestroy {
    public columnIndices$: Observable<Array<number>>;
    public emptyImportableColumn: SelectItem = {
        name: this.translateService.instant('imports.column_do_not_import'),
        value: 'do_not_import'
    };
    public isImportTemplateEmpty = true;
    public loading = false;
    public headerRowsCountMissing = false;
    public submitted = false;
    public saveAsTemplate = false;
    public templateNameMissing = false;
    public showErrorMessage = false;
    public headerRowsCount = 0;
    public templateName: string;
    public selectedColumns: Array<SelectedColumn> = [];
    public importableColumns$: Observable<Array<SelectItem>>;
    public preview$: Observable<ImportPreview>;
    public importTemplates$: Observable<Array<ImportTemplate>>;
    public emptyImportTemplate: ImportTemplate = {
        created_at: null,
        entity: null,
        import_template_ID: 0,
        mappings: {},
        name: this.translateService.instant('imports.from_template_no_template'),
        parent_entity: null
    };
    public selectedImportTemplate: ImportTemplate;
    public defaultColumns: Array<SelectedColumn>;
    public processFileQueue$: Observable<void>;
    public clearFileQueue$: Observable<void>;
    public importFiles: Array<ImportFile> = [
        {name: 'Speybl - employee import.cs.xlsx', download_name: 'Speybl - import zaměstnanců.xlsx', locale: 'cs', entity: 'EMPLOYEE'},
        {name: 'Speybl - employee import.en.xlsx', download_name: 'Speybl - employee import.xlsx', locale: 'en', entity: 'EMPLOYEE'},
    ];

    private _clearFileQueue$ = new Subject<void>();
    private _processFileQueue$ = new Subject<void>();
    private _columnCount: number;
    private _columnIndices$ = new ReplaySubject<Array<number>>(1);
    private _importableColumns$ = new ReplaySubject<Array<SelectItem>>(1);
    private _selectionChanged$ = new BehaviorSubject<void>(null);
    private _importDataSubscription: Subscription;
    private _importPreviewSubscription: Subscription;

    public constructor(
        public translateService: TranslateService,
        private _importService: ImportService,
        private _router: Router,
    ) { }

    public ngOnDestroy(): void {
        window.removeEventListener('beforeunload', this.beforeUnloadFunction);
        this._importDataSubscription?.unsubscribe();
        this._importPreviewSubscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.importableColumns$ = combineLatest([
            this._importableColumns$,
            this._selectionChanged$
        ])
            .pipe(
                map(([importableColumns]) => {
                    return importableColumns
                        .filter(importableColumn => this.selectedColumns
                                .map(selectedColumn => selectedColumn.value)
                                .indexOf(importableColumn.value) === -1 ||
                            importableColumn.value === this.emptyImportableColumn.value
                        );
                }),
                shareReplay()
            );

        this.columnIndices$ = this._columnIndices$;

        this.importTemplates$ = this._importService.getAllImportTemplates()
            .pipe(
                map(importTemplates => {
                    const importTemplatesTemp = [...importTemplates];

                    importTemplatesTemp.sort(sortArrayOfObjectsByName);

                    importTemplatesTemp.unshift(this.emptyImportTemplate);

                    return [...importTemplatesTemp];
                })
            );

        this.selectedImportTemplate = this.emptyImportTemplate;

        this.clearFileQueue$ = this._clearFileQueue$;
        this.processFileQueue$ = this._processFileQueue$;
    }

    public onImport(): void {
        this.submitted = true;
        if (!this._validate()) {
            return;
        }

        const mappings: {
            [key: string]: number;
        } = {};

        let index: number;
        let value: string;

        for (let i = 0, max = this.selectedColumns.length; i < max; i++) {
            index = this.selectedColumns[i].index;
            value = this.selectedColumns[i].value;

            if (value !== this.emptyImportableColumn.value) {
                mappings[value] = index;
            }
        }

        const importData: ImportDataWithMappings = {
            mappings,
            saveAsTemplate: this.saveAsTemplate,
        };

        if (this.saveAsTemplate) {
            importData.name = this.templateName;
        }

        importData.headerRowsCount = this.headerRowsCount;

        this._importDataSubscription = this._importService.importData(importData)
            .subscribe(importedData => {
                window.removeEventListener('beforeunload', this.beforeUnloadFunction);
                if (importedData) {
                    this._router.navigate(['/import', 'overview', importedData.import_ID]);
                }
            });
    }

    public onSelect(index: number, column: SelectItem): void {
        const selectedColumns = [...this.selectedColumns];

        selectedColumns[index] = {
            ...column,
            index
        };

        this.selectedColumns = selectedColumns;

        this._validate();

        this._selectionChanged$.next();
    }

    public onSelectImportTemplate(importTemplate: ImportTemplate): void {
        if (importTemplate.import_template_ID === this.emptyImportTemplate.import_template_ID) {
            this.isImportTemplateEmpty = true;
            this.selectedColumns = [...this.defaultColumns];
        } else {
            this.isImportTemplateEmpty = false;
        }

        this.selectedImportTemplate = importTemplate;

        this._presetColumnsForTemplate();
    }

    public addedFile($event: File): void {
        this.loading = true;
        this.preview$ = null;
        window.addEventListener('beforeunload', this.beforeUnloadFunction);

        const importData = {
            file: $event,
            entityType: 'EMPLOYEE'
        };

        this._importPreviewSubscription = this._importService.importPreview(importData)
            .subscribe(preview => {
                this._columnIndices$.next(this._createColumns(preview));
                this._presetColumns(preview);
                this._importableColumns$.next(this._createImportableColumns(preview));
                this.preview$ = of(preview);
                this.loading = false;
            });
    }

    public beforeUnloadFunction(event: any): Event {
        event.preventDefault();
        event.returnValue = 'Unsaved modifications';
        return event;
    }

    private _createColumns(preview: ImportPreview): Array<number> {
        this._columnCount = preview?.rows[0].length;

        return Array.from({length: this._columnCount}, (_value, key) => key + 1);
    }

    private _createImportableColumns(preview: ImportPreview): Array<SelectItem> {
        const importableColumnsRaw = preview.importable_columns;
        const importableColumnsForSelect: Array<SelectItem> = [];

        for (const key of Object.keys(importableColumnsRaw)) {
            for (const [subKey, subValue] of Object.entries(importableColumnsRaw[key])) {

                importableColumnsForSelect.push({
                    name: this.translateService.instant(`imports.column_${key}_${subKey}`),
                    value: key + '.' + subKey,
                    entity: this.translateService.instant(`imports.category_${key}`),
                    required: subValue === 'required'
                });
            }
        }

        importableColumnsForSelect.sort(sortArrayOfObjectsByName);

        importableColumnsForSelect.unshift(this.emptyImportableColumn);

        return importableColumnsForSelect;
    }

    private _flipMappings(mappings: { [key: string]: number; }): { [index: number]: string; } {
        const flippedMappings: { [index: number]: string; } = {};

        for (const key in mappings) {
            if (!mappings.hasOwnProperty(key)) {
                continue;
            }

            flippedMappings[mappings[key]] = key;
        }

        return flippedMappings;
    }

    private _presetColumns(preview: ImportPreview): void {
        const mappings = preview.template?.mappings;
        const flippedMappings = this._flipMappings(mappings);

        const selectedColumns: Array<SelectedColumn> = [];

        let columnName: string;

        for (let i = 0; i < this._columnCount; i++) {
            columnName = flippedMappings[i] || this.emptyImportableColumn.value;

            selectedColumns[i] = {
                index: i,
                name: this.translateService.instant(`imports.column_${columnName}`),
                value: columnName
            };
        }

        this.defaultColumns = [...selectedColumns];

        this.selectedColumns = selectedColumns;

        this._selectionChanged$.next();
    }

    private _validate(): boolean {
        this.showErrorMessage = false;
        if (this.selectedColumns.find(column => column.value === 'employee.email') === undefined) {
            this.showErrorMessage = true;
            return false;
        }

        return true;
    }

    private _presetColumnsForTemplate(): void {
        for (const key of Object.keys(this.selectedImportTemplate.mappings)) {
            const subKeys = key.split('.');
            const name = this.translateService.instant(`imports.column_${subKeys[0]}_${subKeys[1]}`);
            this.selectedColumns[this.selectedImportTemplate.mappings[key]] = {
                ...this.selectedColumns[this.selectedImportTemplate.mappings[key]],
                name,
                value: key
            };
        }

        this._selectionChanged$.next();
    }
}
