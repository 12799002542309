import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { FileService } from '../../../../shared/services/file.service';
import { Employee } from '../../../models/Employee';

interface Avatar {
    name: string;
    label: string;
    url: string;
}

@Component({
    selector: 'app-change-avatar-modal',
    templateUrl: './change-avatar-modal.component.html',
    styleUrls: ['./change-avatar-modal.component.css']
})

export class ChangeAvatarModalComponent implements OnInit {
    public employee: Employee;
    public selectedAvatarUrl: string;
    public isCustomAvatar = false;
    public customAvatarLoading = false;
    public avatars: Avatar[] = [
        {name: 'Avatar_female_01.png', label: this._translateService.instant('employees.avatar_female_01'), url: 'assets/images/avatars/Avatar_female_01.png'},
        {name: 'Avatar_female_02.png', label: this._translateService.instant('employees.avatar_female_02'), url: 'assets/images/avatars/Avatar_female_02.png'},
        {name: 'Avatar_female_03.png', label: this._translateService.instant('employees.avatar_female_03'), url: 'assets/images/avatars/Avatar_female_03.png'},
        {name: 'Avatar_female_04.png', label: this._translateService.instant('employees.avatar_female_04'), url: 'assets/images/avatars/Avatar_female_04.png'},
        {name: 'Avatar_female_05.png', label: this._translateService.instant('employees.avatar_female_05'), url: 'assets/images/avatars/Avatar_female_05.png'},
        {name: 'Avatar_female_06.png', label: this._translateService.instant('employees.avatar_female_06'), url: 'assets/images/avatars/Avatar_female_06.png'},
        {name: 'Avatar_male_01.png', label: this._translateService.instant('employees.avatar_female_01'), url: 'assets/images/avatars/Avatar_male_01.png'},
        {name: 'Avatar_male_02.png', label: this._translateService.instant('employees.avatar_female_02'), url: 'assets/images/avatars/Avatar_male_02.png'},
        {name: 'Avatar_male_03.png', label: this._translateService.instant('employees.avatar_female_03'), url: 'assets/images/avatars/Avatar_male_03.png'},
        {name: 'Avatar_male_04.png', label: this._translateService.instant('employees.avatar_female_04'), url: 'assets/images/avatars/Avatar_male_04.png'},
        {name: 'Avatar_male_05.png', label: this._translateService.instant('employees.avatar_female_05'), url: 'assets/images/avatars/Avatar_male_05.png'},
        {name: 'Avatar_male_06.png', label: this._translateService.instant('employees.avatar_female_06'), url: 'assets/images/avatars/Avatar_male_06.png'},
    ];

    constructor(
        private _employeeService: EmployeeService,
        private _translateService: TranslateService,
        private _fileService: FileService,
        public activeModal: NgbActiveModal,
    ) { }

    public ngOnInit(): void {
        if (!this.selectedAvatarUrl.includes('assets/images/avatars/')) {
            this.isCustomAvatar = true;
        }
    }

    public saveAvatar(avatar: Avatar): void {
        this._employeeService.saveAvatar(avatar.name, this.employee.employee_ID)
            .then(() => this.activeModal.close('close'), () => {});
    }
}
