import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subscription } from 'rxjs';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { EmployeeDateTimeStatus } from '../../employee/models/EmployeeDateTimeStatus';
import { SwalHelper } from '../../shared/common/SwalHelper';
import { getCookie, localStorageSafe } from '../../shared/functions';
import { ApiResponse } from '../../shared/models/ApiResponse';
import { Workspace } from '../../shared/models/Workspace';
import { KioskLogin } from './KioskLogin';
import { KioskWorkspace } from './KioskWorkspace';

interface EmployeeDateTimeStatusWithWorkspace extends EmployeeDateTimeStatus {
    workspace: Workspace;
}

@Injectable({
    providedIn: 'root'
})

export class KioskService {

    public expiry: number;

    private readonly TOKEN_EXPIRY_CHECK_INTERVAL_MS = 1000;
    private _tokenExpiryInterval: any;
    private _logoutSubscription: Subscription;

    public constructor(
        private _http: HttpClient,
        private _apiHelper: ApiHelper,
        private _swalHelper: SwalHelper,
        private _loaderService: NgxUiLoaderService,
        private _router: Router,
    ) { }

    public setKioskMode(value: boolean, tenantName?: string): void {
        if (value) {
            localStorageSafe.setItem('kiosk_tenant_name', tenantName);
            localStorageSafe.setItem('kiosk_mode', 'true');
            localStorageSafe.setItem('cc_tenant', tenantName);
        } else {
            localStorageSafe.removeItem('kiosk_tenant_name');
            localStorageSafe.removeItem('kiosk_mode');
        }
    }

    public activateKiosk(tenantName: string): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/kiosk/activate', {tenant_name: tenantName})
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public deactivateKiosk(tenantName: string): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/kiosk/deactivate', {tenant_name: tenantName})
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public loginKiosk(formValue: { tenant_name: string, login_code: string }): Promise<KioskLogin> {
        return new Promise(resolve => {
            this._http.post<KioskLogin>('/api/kiosk/login', formValue)
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        resolve(response);
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public checkTokenExpiry(token?: string): void {
        token = token ?? getCookie('token');
        this.clearData();

        if (token) {
            this.expiry = new Date((JSON.parse(atob((token.split('.')[1]))).exp * 1000)).getTime();
            this._tokenExpiryInterval = setInterval(() => {
                const now = new Date().getTime();
                if (now > this.expiry) {
                    this.clearData();
                    this._router.navigateByUrl('/kiosk/login');
                }
            }, this.TOKEN_EXPIRY_CHECK_INTERVAL_MS);
        } else {
            this.clearData();
            this._logoutSubscription = this.logoutKiosk()
                .subscribe(() => this._router.navigate(['/kiosk/login']));
        }
    }

    public clearData(): void {
        clearInterval(this._tokenExpiryInterval);
    }

    public logoutKiosk(): Observable<any> {
        return this._http.get('/api/kiosk/logout');
    }

    public getWorkSpaceInfo(tenantName: string): Observable<KioskWorkspace> {
        return this._http.post<KioskWorkspace>('/api/kiosk/workspace', {tenant_name: tenantName});
    }

    public getDatetimeStatus(): Observable<EmployeeDateTimeStatusWithWorkspace> {
        return this._http.get<EmployeeDateTimeStatusWithWorkspace>('/api/kiosk/datetimes/status?kiosk');
    }

    public setDatetimeState(type: string): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post('/api/kiosk/datetimes/start', {type})
                .subscribe(
                    (response: any) => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public setDatetimeStateSimple(code: string, tenantName: string, type?: string, action = 'start'): Promise<'done'> {
        const body = {
            tenant_name: tenantName,
            login_code: code,
            employee_datetime_type_ID: type
        };
        return new Promise((resolve, reject) => {
            this._http.post(`/api/kiosk/datetimes/${action}`, body)
                .subscribe(
                    (response: any) => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        reject();

                        this._loaderService.stop();
                    }
                );
        });
    }

    public generateKioskLoginCode(employeeID: number): Promise<{ login_code: string }> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.get<{ login_code: string }>(`/api/employees/${employeeID}/generate-kiosk-login-code`, {})
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        resolve(response);
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }
}
