import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import type { GenericObject } from '../../interface/generic-object.interface';
import type { DataType, SortDirectionType } from '../../types';
import { SORT_ASC, SORT_DESC } from '../../types';

@Directive({
  selector: '[appDataTableHeaderCell]'
})
export class DataTableHeaderCellDirective<T extends GenericObject> {
    @Input('appDataTableHeaderCell') public column: string;
    @Input() public disableSort = false;
    @Input()  public name: string;
    @Input() public sortDirection: SortDirectionType | null;
    @Input() public type: DataType | null;
    @Output()  public sortDirectionChanged = new EventEmitter<SortDirectionType>();

  @HostBinding('class.sortable')
  public get classSortable(): boolean {
    return !this.disableSort;
  }

  @HostBinding('class.asc')
  public get classASC(): boolean {
    return this.sortDirection === SORT_ASC;
  }

  @HostBinding('class.desc')
  public get classDESC(): boolean {
    return this.sortDirection === SORT_DESC;
  }

  @HostListener('click')
  public click(): void {
    this._toggleSort();
  }

  public get content(): string | null {
    return (this._elementRef.nativeElement as HTMLElement).textContent;
  }

  public constructor(private _elementRef: ElementRef) { }

  public ASC(): void {
    if (this.sortDirection !== SORT_ASC) {
      this.sortDirectionChanged.emit(SORT_ASC);
    }
  }

  public DESC(): void {
    if (this.sortDirection !== SORT_DESC) {
      this.sortDirectionChanged.emit(SORT_DESC);
    }
  }

  private _toggleSort(): void {
    if (this.disableSort) {
      return;
    }

    if (this.sortDirection === SORT_ASC) {
      this.DESC();
    } else {
      this.ASC();
    }
  }
}
