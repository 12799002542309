<ng-template
    #filterFormModal
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'company_positions.adjust_filters' | translate }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <form
                (ngSubmit)="onSubmitFilter()"
                [formGroup]="filterForm"
                class="filter-form"
            >
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ 'company_positions.filter.type' | translate }}</label>

                            <ng-select
                                [clearable]="true"
                                [closeOnSelect]="false"
                                [items]="positionsTypes"
                                [multiple]="true"
                                bindLabel="label"
                                bindValue="value"
                                class="custom"
                                formControlName="type"
                            >
                                <ng-template ng-multi-label-tmp>
                                    <span
                                        class="ng-placeholder"
                                        style="display: block;"
                                    >
                                      <span>{{ 'company_positions.filter.types_count' | translate }}</span>
                                      <span> (</span>
                                      <span>{{ getCount('type') }}</span>
                                      <span>)</span>
                                    </span>
                                </ng-template>

                                <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                                    <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/>
                                    <span>&nbsp;</span>
                                    <span>{{ item.label }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-right">
                        <button class="btn btn-primary">
                            <span>{{ 'activity_log.action_filter' | translate }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<div class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help name="settings.company.positions.list"></app-help>

      <div class="card-header py-3">
        <div class="row">
          <div class="col-sm-6 text-left my-auto">
            <p class="modal-title font-16 text-dark pt-2 pb-2">
              <span>{{ 'company_positions.page_title_index' | translate }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <app-data-table
                (searchChanged)="searchChanged($event)"
                (sortChanged)="sortChanged($event)"
                [disableInPlaceFiltering]="false"
                [disableInPlaceSorting]="false"
                [loading]="loading"
                [rows]="rows"
                [search]="search"
                [showSearch]="true"
                [sort]="sort"
                [trackByFn]="trackByFn"
              >
                <ng-template
                  #headerButton
                >
                    <button  (click)="openFilterModal()"
                             [formGroup]="filterForm"
                             class="btn btn-primary btn-icon ml-sm-2"
                             type="button"
                    >
                        <i class="mdi mdi-filter-outline"></i>
                    </button>
                  <button *ngIf="canCreate"
                    (click)="openEditModal()"
                    [createButton]="'company_positions.action_create' | translate"
                    class="ml-sm-2"
                  ></button>
                </ng-template>

                <ng-template #headerRow>
                  <th appDataTableHeaderCell="name">{{ 'company_positions.name' | translate }}</th>

                  <th appDataTableHeaderCell="type">{{ 'company_positions.type' | translate }}</th>

                  <th appDataTableHeaderCell="type">{{ 'company_positions.number_of_employees' | translate }}</th>

                  <th
                    [disableSort]="true"
                    appDataTableHeaderCell
                    class="text-right"
                  >{{ 'company_positions.table_action' | translate }}</th>
                </ng-template>

                <ng-template
                  #dataRow
                  let-row
                >
                  <td appDataTableDataCell>{{ row.name }}</td>

                  <td appDataTableDataCell>{{ ('employees_positions.' + row.type) | translate }}</td>

                  <td appDataTableDataCell>{{ row.employees_count }}</td>

                  <td
                    appDataTableDataCell
                    class="text-right"
                  >
                    <button *ngIf="canEdit"
                      (click)="openEditModal(row)"
                      class="btn btn-white btn-icon btn-sm mr-1"
                    >
                      <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                    </button>
                  </td>
                </ng-template>
              </app-data-table>
            </div>
          </div>
        </div>

        <div class="row mt-3 flex-column-reverse flex-sm-row">
          <div class="col-12 col-sm-2">
            <app-items-per-page-select
              (itemsPerPageChanged)="itemsPerPageChanged($event)"
              [itemsPerPage]="itemsPerPage"
              [total]="itemsTotal"
            ></app-items-per-page-select>
          </div>

          <div class="col-12 col-sm-10">
            <app-pagination
              (pageChanged)="pageChanged($event)"
              [itemsPerPage]="itemsPerPage"
              [page]="page"
              [total]="itemsTotal"
            ></app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
