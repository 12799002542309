import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Injectable()
export class SwalHelper {

    constructor(
        private translateService: TranslateService
    ) {}

    public async showConfirmDeleteDialog(text?: string, title?: string) {
        let deleteDialogText = '';
        let deleteDialogTitle = '';
        if (text) {
            deleteDialogText = this.translateService.instant(text);
        } else {
            deleteDialogText = this.translateService.instant('swal.delete_text');
        }
        if (title) {
            deleteDialogTitle = this.translateService.instant(title);
        } else {
            deleteDialogTitle = this.translateService.instant('swal.delete_title');

        }
        const deleteDialogConfirmButton = this.translateService.instant('swal.action_confirm_delete');
        const deleteDialogCancelButton = this.translateService.instant('swal.action_cancel_delete');
        const result = await Swal.fire({
            title: deleteDialogTitle,
            text: deleteDialogText,
            showCancelButton: true,
            cancelButtonText: deleteDialogCancelButton,
            confirmButtonText: deleteDialogConfirmButton,
            confirmButtonColor: '#6979ED'
        });

        if (result.value) {
            return true;
        }
        return false;
    }

    public async showConfirmDeleteDialogWithCheckbox(text?: string, title?: string, checkboxText?: string): Promise<{ isConfirmed: boolean, checkedBox: boolean }> {
        let deleteDialogText = '';
        let deleteDialogTitle = '';
        let deleteCheckboxText = '';
        if (text) {
            deleteDialogText = this.translateService.instant(text);
        } else {
            deleteDialogText = this.translateService.instant('swal.delete_text');
        }
        if (title) {
            deleteDialogTitle = this.translateService.instant(title);
        } else {
            deleteDialogTitle = this.translateService.instant('swal.delete_title');
        }
        if (checkboxText) {
            deleteCheckboxText = this.translateService.instant(checkboxText);
        } else {
            deleteCheckboxText = this.translateService.instant('swal.delete_text_team');
        }

        const deleteDialogConfirmButton = this.translateService.instant('swal.action_confirm_delete');
        const deleteDialogCancelButton = this.translateService.instant('swal.action_cancel_delete');

        const {value: checked, isConfirmed, isDismissed} = await Swal.fire({
            title: deleteDialogTitle,
            text: deleteDialogText,
            input: 'checkbox',
            inputPlaceholder: deleteCheckboxText,
            showCancelButton: true,
            cancelButtonText: deleteDialogCancelButton,
            confirmButtonText: deleteDialogConfirmButton,
            confirmButtonColor: '#6979ED',
        });

        if (isConfirmed) {
            const checkedBox = checked === 1;
            return {
                isConfirmed,
                checkedBox
            };
        }

        return {
            isConfirmed: false,
            checkedBox: false
        };
    }

    public async showConfirmChangeDialog(text?: string, title?: string) {
        let changeDialogText = '';
        let changeDialogTitle = '';
        if (text) {
            changeDialogText = this.translateService.instant(text);
        } else {
            changeDialogText = this.translateService.instant('swal.change_text');
        }
        if (title) {
            changeDialogTitle = this.translateService.instant(title);
        } else {
            changeDialogTitle = this.translateService.instant('swal.change_title');

        }
        const changeDialogConfirmButton = this.translateService.instant('swal.action_confirm_change');
        const changeDialogCancelButton = this.translateService.instant('swal.action_cancel_change');
        const result = await Swal.fire({
            title: changeDialogTitle,
            text: changeDialogText,
            showCancelButton: true,
            cancelButtonText: changeDialogCancelButton,
            confirmButtonText: changeDialogConfirmButton,
            confirmButtonColor: '#6979ED'
        });

        if (result.value) {
            return true;
        }
        return false;
    }
}
