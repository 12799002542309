<ng-template
    #filterFormModal
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'activity_log.adjust_filters' | translate }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <form
                (ngSubmit)="onSubmitFilter()"
                [formGroup]="filterForm"
                class="filter-form"
            >
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ 'activity_log.filter.event' | translate }}</label>

                            <ng-select
                                [clearable]="true"
                                [closeOnSelect]="false"
                                [items]="availableFilters.events"
                                [multiple]="true"
                                class="custom"
                                formControlName="events"
                            >
                                <ng-template ng-multi-label-tmp>
                                    <span
                                        class="ng-placeholder"
                                        style="display: block;"
                                    >
                                      <span>{{ 'activity_log.filter.events_count' | translate }}</span>
                                      <span> (</span>
                                      <span>{{ getCount('events') }}</span>
                                      <span>)</span>
                                    </span>
                                </ng-template>

                                <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                                    <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/>
                                    <span>&nbsp;</span>
                                    <ng-container *ngIf="('activity_log.events.' + item) !== (('activity_log.events.' + item) | translate); else noTranslationTemplate">
                                        <span>{{ ('activity_log.events.' + item) | translate }}</span>
                                    </ng-container>
                                    <ng-template #noTranslationTemplate>
                                        <span>{{ item }}</span>
                                    </ng-template>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ 'activity_log.filter.subject' | translate }}</label>

                            <ng-select
                                [clearable]="true"
                                [closeOnSelect]="false"
                                [items]="availableFilters.subjects"
                                [multiple]="true"
                                class="custom"
                                formControlName="subjects"
                            >
                                <ng-template ng-multi-label-tmp>
                                    <span
                                        class="ng-placeholder"
                                        style="display: block;"
                                    >
                                      <span>{{ 'activity_log.filter.subjects_count' | translate }}</span>
                                      <span> (</span>
                                      <span>{{ getCount('subjects') }}</span>
                                      <span>)</span>
                                    </span>
                                </ng-template>

                                <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                                    <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/>
                                    <span>&nbsp;</span>
                                    <ng-container *ngIf="('activity_log.subjects.' + item) !== (('activity_log.subjects.' + item) | translate); else noTranslationTemplate">
                                        <span>{{ ('activity_log.subjects.' + item) | translate }}</span>
                                    </ng-container>
                                    <ng-template #noTranslationTemplate>
                                        <span>{{ item }}</span>
                                    </ng-template>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div *ngIf="isAdmin && mode === 'full'" class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ 'activity_log.filter.causer' | translate }}</label>

                            <ng-select
                                [clearable]="true"
                                [closeOnSelect]="false"
                                [items]="availableFilters.causers"
                                [multiple]="true"
                                bindLabel="fullname"
                                bindValue="user_ID"
                                class="custom"
                                formControlName="causers"
                            >
                                <ng-template ng-multi-label-tmp>
                                    <span
                                        class="ng-placeholder"
                                        style="display: block;"
                                    >
                                      <span>{{ 'activity_log.filter.causers_count' | translate }}</span>
                                      <span> (</span>
                                      <span>{{ getCount('causers') }}</span>
                                      <span>)</span>
                                    </span>
                                </ng-template>

                                <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                                    <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/>
                                    <span>&nbsp;</span>
                                    <span>{{ item.fullname }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ 'activity_log.filter.employee' | translate }}</label>

                            <ng-select
                                [clearable]="true"
                                [closeOnSelect]="false"
                                [items]="availableFilters.employees"
                                [multiple]="true"
                                bindLabel="fullname"
                                bindValue="employee_ID"
                                class="custom"
                                formControlName="employees"
                            >
                                <ng-template ng-multi-label-tmp>
                                    <span
                                        class="ng-placeholder"
                                        style="display: block;"
                                    >
                                      <span>{{ 'activity_log.filter.employees_count' | translate }}</span>
                                      <span> (</span>
                                      <span>{{ getCount('employees') }}</span>
                                      <span>)</span>
                                    </span>
                                </ng-template>

                                <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                                    <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/>
                                    <span>&nbsp;</span>
                                    <span>{{ item.fullname }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ 'activity_log.filter.from' | translate }}</label>

                            <input
                                (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                [locale]="locale$ | async"
                                allowInput="true"
                                altFormat="d.m.Y"
                                altInput="true"
                                class="form-control"
                                dateFormat="Y-m-d"
                                formControlName="from"
                                mwlFlatpickr
                                type="text"
                                [minDate]="availableFilters.start_date"
                                [maxDate]="maxDate"
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ 'activity_log.filter.to' | translate }}</label>

                            <input
                                (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                                [locale]="locale$ | async"
                                allowInput="true"
                                altFormat="d.m.Y"
                                altInput="true"
                                class="form-control"
                                dateFormat="Y-m-d"
                                formControlName="to"
                                mwlFlatpickr
                                type="text"
                                [minDate]="availableFilters.start_date"
                                [maxDate]="maxDate"
                            >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-right">
                        <button class="btn btn-primary">
                            <span>{{ 'activity_log.action_filter' | translate }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<section id="activity-logs">
    <div class="card shadow">
        <app-help name="settings.activity_log"></app-help>

        <div class="card-header py-3">
            <div class="row">
                <div class="col-6 text-left my-auto">
                    <p class="modal-title font-16 text-dark pt-2 pb-2">
                        <span>{{ 'activity_log.title_header' | translate }}</span>
                    </p>
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <app-export [stream$]="activityLogs$"></app-export>
                    <button  (click)="openFilterModal()"
                             [formGroup]="filterForm"
                             class="btn btn-primary btn-icon ml-sm-2"
                             type="button"
                             [disabled]="!availableFilters"
                    >
                        <i class="mdi mdi-filter-outline"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                        <app-data-table
                            (searchChanged)="searchChanged($event)"
                            (sortChanged)="sortChanged($event)"
                            [disableInPlaceFiltering]="true"
                            [disableInPlaceSorting]="true"
                            [loading]="loading"
                            [rows]="rows"
                            [search]="search"
                            [showSearch]="true"
                            [sort]="sort"
                            [trackByFn]="trackByFn"
                        >
                            <ng-template #headerRow>
                                <th appDataTableHeaderCell="name">{{ 'activity_log.name' | translate }}</th>

                                <th appDataTableHeaderCell="model">{{ 'activity_log.model' | translate }}</th>

                                <th appDataTableHeaderCell="event">{{ 'activity_log.event.title' | translate }}</th>

                                <th appDataTableHeaderCell="created_at">{{ 'activity_log.created_at' | translate }}</th>

                                <th *ngIf="isAdmin && mode === 'full'" appDataTableHeaderCell="causer">{{ 'activity_log.causer.title' | translate }}</th>

                                <th *ngIf="isAdmin && mode === 'full'" appDataTableHeaderCell="ip">{{ 'activity_log.ip_address' | translate }}</th>

                                <th
                                    [disableSort]="true"
                                    appDataTableHeaderCell
                                    class="text-right"
                                >{{ 'employees.table_action' | translate }}</th>

                            </ng-template>

                            <ng-template
                                #dataRow
                                let-isFirst="isFirst"
                                let-isLast="isLast"
                                let-row
                            >
                                <td appDataTableDataCell>
                                    <ng-container *ngIf="row.employee; else noEmployeeTemplate">
                                        <app-employee-box-avatar [employee]="row.employee"></app-employee-box-avatar>
                                    </ng-container>
                                    <ng-template #noEmployeeTemplate>
                                        <span>{{ row.label }}</span>
                                    </ng-template>
                                </td>

                                <td appDataTableDataCell>
                                    <ng-container *ngIf="('activity_log.subjects.' + row.subject_type) !== (('activity_log.subjects.' + row.subject_type) | translate); else noTranslationTemplate">
                                        {{ ('activity_log.subjects.' + row.subject_type) | translate }}
                                    </ng-container>
                                    <ng-template #noTranslationTemplate>
                                        <span>{{ row.subject_type }}</span>
                                    </ng-template>
                                </td>

                                <td appDataTableDataCell> {{ ('activity_log.events.' + (row.event || row.description)) | translate }} </td>

                                <td appDataTableDataCell> {{ row.created_at | date: 'dd.MM.yyyy'}} </td>

                                <td *ngIf="isAdmin && mode === 'full'" appDataTableDataCell> {{ row.causer?.fullname }} </td>

                                <td *ngIf="isAdmin && mode === 'full'" appDataTableDataCell> {{ row.ip }} </td>

                                <td style="height: 76px" class="text-right" appDataTableDataCell>
                                    <button *ngIf="row.new"
                                            (click)="openDetailModal(row)"
                                            class="btn btn-white btn-icon btn-sm mr-1"
                                    >
                                        <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                    </button>
                                </td>
                            </ng-template>
                        </app-data-table>
                    </div>
                </div>
            </div>
            <div class="row mt-3 flex-column-reverse flex-sm-row">
                <div class="col-12 col-sm-2">
                    <app-items-per-page-select
                        (itemsPerPageChanged)="itemsPerPageChanged($event)"
                        [itemsPerPage]="itemsPerPage"
                        [total]="itemsTotal"
                    ></app-items-per-page-select>
                </div>

                <div class="col-12 col-sm-10">
                    <app-pagination
                        (pageChanged)="pageChanged($event)"
                        [itemsPerPage]="itemsPerPage"
                        [page]="page"
                        [total]="itemsTotal"
                    ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</section>
