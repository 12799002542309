<ng-template
    #filterFormOptions
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'employees_datetimes.adjust_filters' | translate }}</span>
            </p>

            <button
                (click)="modal.dismiss()"
                aria-label="Close"
                class="close"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>
        <div class="modal-body">
            <form
                (ngSubmit)="onSubmit()"
                [formGroup]="filterForm"
                class="filter-form"
            >
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ 'entity_update_request.filter_states' | translate }}</label>
                            <ng-select
                                [bindLabel]="'label'"
                                [bindValue]="'value'"
                                [clearable]="false"
                                [closeOnSelect]="false"
                                [items]="states$ | async"
                                [multiple]="true"
                                class="custom"
                                formControlName="states"
                            >
                                <ng-template ng-multi-label-tmp>
                  <span
                      class="ng-placeholder"
                      style="display: block;"
                  >
                    <span>{{ 'todo.filter_types_count' | translate }}</span>
                    <span> (</span>
                    <span>{{ statesCount }}</span>
                    <span>)</span>
                  </span>
                                </ng-template>

                                <ng-template
                                    let-index="index"
                                    let-item="item"
                                    let-item$="item$"
                                    ng-option-tmp
                                >
                                    <input
                                        [checked]="item$.selected"
                                        id="item-{{index}}"
                                        type="checkbox"
                                    />

                                    <span>&nbsp;</span>
                                    <span>{{ item.label }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div *ngIf="!employeeIds" class="row mt-2">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{ 'entity_update_request.filter_employees' | translate }}</label>
                            <ng-select
                                [bindLabel]="'fullname'"
                                [bindValue]="'employee_ID'"
                                [clearable]="false"
                                [closeOnSelect]="false"
                                [items]="employees$ | async"
                                [multiple]="true"
                                class="custom"
                                formControlName="employeesIDs"
                            >
                                <ng-template ng-multi-label-tmp>
                  <span
                      class="ng-placeholder"
                      style="display: block;"
                  >
                    <span>{{ 'todo.filter_types_count' | translate }}</span>
                    <span> (</span>
                    <span>{{ employeesIDsCount }}</span>
                    <span>)</span>
                  </span>
                                </ng-template>

                                <ng-template
                                    let-index="index"
                                    let-item="item"
                                    let-item$="item$"
                                    ng-option-tmp
                                >
                                    <input
                                        [checked]="item$.selected"
                                        id="item-{{index}}"
                                        type="checkbox"
                                    />

                                    <span>&nbsp;</span>
                                    <span>{{ item.fullname }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12 text-right">
                        <button class="btn btn-primary">
                            <span>{{ 'analysis.action_filter' | translate }}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-container>
    <div class="card shadow mb-0">
        <app-help name="employee.detail.requests"></app-help>

        <div *ngIf="!isDashboard" class="card-header py-3">
            <div class="row">
                <div class="col-6 text-left my-auto">
                    <p class="modal-title font-16 pt-2 pb-2 text-dark">
                        <span>{{ 'entity_update_request.title' | translate }}</span>
                    </p>
                </div>
                <div *ngIf="canEdit" class="col-6 text-right">
                    <button
                        (click)="openFilterFormOptions()"
                        [formGroup]="filterForm"
                        class="btn btn-primary btn-icon"
                        type="button"
                    >
                        <i class="mdi mdi-filter-outline"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="card-body px-4">
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                        <app-data-table
                            [columnsToFilterIn]="['name', 'percent_progress']"
                            [rows]="entityRequests"
                            [showSearch]="false"
                        >
                            <ng-template #headerRow *ngIf="mode != 'compact' && mode != 'noHeader'">
                                <th *ngIf="!employeeIds" [disableSort]="true" appDataTableHeaderCell>
                                    {{ 'entity_update_request.employee_name' | translate }}
                                </th>

                                <th [disableSort]="true" appDataTableHeaderCell>
                                    {{ 'entity_update_request.entity_name' | translate }}
                                </th>

                                <th [disableSort]="true" appDataTableHeaderCell>
                                    {{ 'entity_update_request.state' | translate }}
                                </th>

                                <th [disableSort]="true" appDataTableHeaderCell>
                                    {{ 'entity_update_request.state_set_at' | translate }}
                                </th>

                                <th [disableSort]="true" appDataTableHeaderCell class="text-right">
                                    {{ 'entity_update_request.action' | translate }}
                                </th>
                            </ng-template>

                            <ng-template #dataRow let-row>
                                <td *ngIf="!employeeIds" appDataTableDataCell class="w-sm-15">
                                    <app-employee-box-avatar [employee]="row.employee"></app-employee-box-avatar>
                                </td>

                                <td appDataTableDataCell>
                                    <h5 class="mt-0 mb-1">{{ ("entity_update_request." + (row.entity_type)) | translate }}</h5>
                                    <span class="font-13 text-muted">{{ ("entity_update_request." + (row.action)) | translate }}</span>
                                </td>

                                <td appDataTableDataCell> {{ ("entity_update_request." + (row.state)) | translate }} </td>

                                <td appDataTableDataCell> {{ row.state_set_at | date:'dd.MM.yyyy HH:mm' }} </td>

                                <td
                                    appDataTableDataCell
                                    class="text-right"
                                >
                                    <div>
                                        <button
                                            (click)="openEntityUpdateRequestModal(row)"
                                            class="btn btn-white btn-icon btn-sm mr-1"
                                            title="{{ 'employees_onboardings.action_view' | translate }}"
                                        >
                                            <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                        </button>
                                    </div>
                                </td>
                            </ng-template>
                        </app-data-table>
                    </div>
                </div>
            </div>
            <div *ngIf="mode && canEdit" class="text-right mt-2">
                <a
                    class="text-muted"
                    routerLink="/entity-update-requests/list"
                >
                    <u>{{ 'entity_update_request.all_requests' | translate }}</u>
                </a>
            </div>
        </div>
    </div>
</ng-container>
