import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EmployeeAttendancePlanChartComponent } from '../components/sections/employee-attendance-plan-chart/employee-attendance-plan-chart.component';
import { ActivityLogListComponent } from '../shared/components/partials/activity-log-list/activity-log-list.component';
import { Routes } from '../shared/models/Route';
import { CreateEditEmployeeAddressModalComponent } from './components/sections/create-edit-employee-address-modal/create-edit-employee-address-modal.component';
import { CreateEmployeeSalaryComponent } from './components/sections/create-employee-salary/create-employee-salary.component';
import { EmployeeAbsenceListComponent } from './components/sections/employee-absence-list/employee-absence-list.component';
import { EmployeeAccessComponent } from './components/sections/employee-access/employee-access.component';
import { EmployeeActivityListComponent } from './components/sections/employee-activity-list/employee-activity-list.component';
import { EmployeeAnalysisComponent } from './components/sections/employee-analysis/employee-analysis.component';
import { EmployeeAttendanceChartComponent } from './components/sections/employee-attendance-chart/employee-attendance-chart.component';
import { EmployeeAttendanceDetailComponent } from './components/sections/employee-attendance-detail/employee-attendance-detail.component';
import { EmployeeAttendanceDocumentDetailComponent } from './components/sections/employee-attendance-document-detail/employee-attendance-document-detail.component';
import { EmployeeAttendanceDocumentEmployeeDetailComponent } from './components/sections/employee-attendance-document-employee-detail/employee-attendance-document-employee-detail.component';
import { EmployeeAttendanceDocumentsComponent } from './components/sections/employee-attendance-documents/employee-attendance-documents.component';
import { EmployeeAttendanceGrossSalaryCountComponent } from './components/sections/employee-attendance-gross-salary-count/employee-attendance-gross-salary-count.component';
import { EmployeeAttendanceHourGrossSalaryCountComponent } from './components/sections/employee-attendance-hour-gross-salary-count/employee-attendance-hour-gross-salary-count.component';
import { EmployeeAttendanceListComponent } from './components/sections/employee-attendance-list/employee-attendance-list.component';
import { EmployeeAttendanceNetSalaryCountComponent } from './components/sections/employee-attendance-net-salary-count/employee-attendance-net-salary-count.component';
import { EmployeeAttendanceNormalizeCheckComponent } from './components/sections/employee-attendance-normalize-check/employee-attendance-normalize-check.component';
import { EmployeeAttendanceRealNetSalaryCountComponent } from './components/sections/employee-attendance-real-net-salary-count/employee-attendance-real-net-salary-count.component';
import { EmployeeAttendanceComponent } from './components/sections/employee-attendance/employee-attendance.component';
import { EmployeeBenefitBonusComponent } from './components/sections/employee-benefit-bonus/employee-benefit-bonus.component';
import { EmployeeContactInfoComponent } from './components/sections/employee-contact-info/employee-contact-info.component';
import { EmployeeContactListComponent } from './components/sections/employee-contact-list/employee-contact-list.component';
import { EmployeeDatetimesComponent } from './components/sections/employee-datetimes/employee-datetimes.component';
import { EmployeeDetailComponent } from './components/sections/employee-detail/employee-detail.component';
import { EmployeeDocumentsComponent } from './components/sections/employee-documents/employee-documents.component';
import { EmployeeKpisComponent } from './components/sections/employee-kpis/employee-kpis.component';
import { EmployeeListByTypeComponent } from './components/sections/employee-list-by-type/employee-list-by-type.component';
import { EmployeeListComponent } from './components/sections/employee-list/employee-list.component';
import { EmployeeOnboardingsComponent } from './components/sections/employee-onboardings/employee-onboardings.component';
import { EmployeeOverviewComponent } from './components/sections/employee-overview/employee-overview.component';
import { EmployeePositionsFilesComponent } from './components/sections/employee-positions-files/employee-positions-files.component';
import { EmployeePropertiesComponent } from './components/sections/employee-properties/employee-properties.component';
import { EmployeeScheduledTasksComponent } from './components/sections/employee-scheduled-tasks/employee-scheduled-tasks.component';
import { EmployeeSettingComponent } from './components/sections/employee-setting/employee-setting.component';
import { EmployeeShiftsChartComponent } from './components/sections/employee-shifts-chart/employee-shifts-chart.component';
import { EmployeeStructureTreeComponent } from './components/sections/employee-structure-tree/employee-structure-tree.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },

    {
        path: 'archive',
        redirectTo: 'list-by-type/archive',
        pathMatch: 'full'
    },

    {
        path: 'attendance',
        redirectTo: 'attendance/to-approve',
        pathMatch: 'full'
    },

    {
        path: 'list-by-type',
        redirectTo: 'list-by-type/archive',
        pathMatch: 'full'
    },

    {
        path: 'contact-list',
        component: EmployeeContactListComponent,
        data: {
            title: 'employees.contact_list_page_title_view'
        },
    },

    {
        path: 'activity-report',
        component: ActivityLogListComponent,
        data: {
            title: 'activity_log.report_title_header'
        },
    },

    {
        path: ':id/detail', component: EmployeeDetailComponent,
        data: {
            title: 'employees.page_title_view'
        },
        children: [
            {path: 'accesses', component: EmployeeAccessComponent, data: {title: 'employees.page_title_view_access'}},
            {
                path: '',
                redirectTo: 'overview',
                pathMatch: 'full',
            },

            {
                path: 'analysis',
                component: EmployeeAnalysisComponent,
                data: {
                    help: 'employees.employee.detail.analysis',
                    title: 'employees.page_title_view_analysis'
                }
            },

            {
                path: 'benefit-bonus',
                component: EmployeeBenefitBonusComponent,
                data: {
                    help: 'employees.employee.detail.benefit_bonus',
                    title: 'employees.page_title_view_benefit_bonus'
                }
            },

            {
                path: 'contact-info',
                component: EmployeeContactInfoComponent,
                data: {
                    help: 'employees.employee.detail.contactInfo',
                    title: 'employees.page_title_view_contact_info'
                }
            },

            {
                path: 'datetimes',
                component: EmployeeDatetimesComponent,
                data: {
                    help: 'employees.employee.detail.attendance',
                    title: 'employees.page_title_view_datetimes'
                }
            },

            {
                path: 'documents',
                component: EmployeeDocumentsComponent,
                data: {
                    help: 'employees.employee.detail.document',
                    title: 'employees.page_title_view_files'
                }
            },

            {
                path: 'evaluations',
                component: EmployeeKpisComponent,
                data: {
                    help: 'employees.employee.detail.kpi',
                    title: 'employees.page_title_view_kpis'
                }
            },

            {
                path: 'notes',
                component: EmployeeActivityListComponent,
                data: {
                    help: 'employees.employee.detail.activity',
                    title: 'employees.page_title_view_activity'
                }
            },

            {
                path: 'onboardings',
                component: EmployeeOnboardingsComponent,
                data: {
                    help: 'employees.employee.detail.onboarding',
                    title: 'employees.page_title_view_onboardings'
                }
            },

            {
                path: 'overview',
                component: EmployeeOverviewComponent,
                data: {
                    help: 'main_processes.employee_detail',
                    title: 'employees.page_title_view_overview'
                }
            },

            {
                path: 'positions-files',
                component: EmployeePositionsFilesComponent,
                data: {
                    help: 'employees.employee.detail.position',
                    title: 'employees.page_title_view_positions_files'
                }
            },

            {
                path: 'properties',
                component: EmployeePropertiesComponent,
                data: {
                    help: 'employees.employee.detail.property',
                    title: 'employees.page_title_view_properties'
                }
            },

            {
                path: 'setting',
                component: EmployeeSettingComponent,
                data: {
                    help: 'employees.employee.detail.settings',
                    title: 'employees.page_title_view_setting'
                }
            },
            {path: 'scheduled-tasks', component: EmployeeScheduledTasksComponent, data: {title: 'employees.page_title_view_scheduled_tasks'}},
        ]
    },

    {
        path: 'attendance',
        component: EmployeeAttendanceComponent,
        children: [
            {
                path: '',
                redirectTo: 'to-approve',
                pathMatch: 'full'
            },

            {
                path: 'chart',
                component: EmployeeAttendanceChartComponent,
                data: {
                    help: 'employees.employee.attendance.chart',
                    title: 'employees_datetimes.page_title_index_attendance'
                }
            },

            {
                path: 'plan',
                component: EmployeeAttendancePlanChartComponent,
                data: {
                    help: 'employees.employee.attendance.chart',
                    title: 'employees_datetimes.page_title_index_plan'
                }
            },

            {
                path: 'shifts',
                children: [
                    {
                        path: 'chart',
                        component: EmployeeShiftsChartComponent,
                        data: {
                            help: 'employees.employee.attendance.shifts.chart',
                            title: 'employees_datetimes.page_title_index_shifts'
                        }
                    },
                    {
                        path: 'history',
                        component: EmployeeAttendanceListComponent,
                        data: {
                            help: 'employees.employee.attendance.shifts.history',
                            title: 'employees_datetimes.page_title_index_shifts'
                        }
                    },
                ],
            },

            {
                path: 'documents',
                component: EmployeeAttendanceDocumentsComponent,
                data: {
                    help: 'employees.employee.attendance.document',
                    title: 'employees_datetimes.page_title_index_documents'
                }
            },

            {
                path: 'history',
                component: EmployeeAttendanceListComponent,
                data: {
                    help: 'employees.employee.attendance.history',
                    title: 'employees_datetimes.page_title_index_attendance'
                }
            },

            {
                path: 'to-approve',
                component: EmployeeAttendanceListComponent,
                data: {
                    help: 'employees.employee.attendance.toApprove',
                    title: 'employees_datetimes.page_title_index_to_approve'
                }
            },

            {
                path: 'absence-list',
                component: EmployeeAbsenceListComponent,
                data: {
                    help: 'employees.employee.attendance.absence_list',
                    title: 'employees_datetimes.absence_list_page_title_view'
                }
            },
        ]
    },

    {
        path: ':id/addresses/create',
        component: CreateEditEmployeeAddressModalComponent,
        data: {
            help: 'employees.employee.address.create',
            title: 'employees_salaries.page_title_edit'
        }
    },

    {
        path: ':id/addresses/edit/:addrID',
        component: CreateEditEmployeeAddressModalComponent,
        data: {
            help: 'employees.employee.address.edit',
            title: 'employees_salaries.page_title_edit'
        }
    },

    {
        path: ':id/attendance/detail/:dateTimeID',
        component: EmployeeAttendanceDetailComponent,
        data: {
            help: 'employees.employee.attendance.edit',
            title: 'employees_datetimes.page_title_view'
        }
    },

    {
        path: ':id/salaries/create',
        component: CreateEmployeeSalaryComponent,
        data: {
            help: 'employees.employee.salary.create',
            title: 'employees_salaries.page_title_edit'
        }
    },

    {
        path: 'attendance/documents/detail',
        component: EmployeeAttendanceDocumentDetailComponent,
        data: {
            help: 'employees.attendance.document.detail',
            title: 'employees_datetimes.page_title_index_document_detail'
        }
    },

    {
        path: 'attendance/documents/detail/employee/:id',
        component: EmployeeAttendanceDocumentEmployeeDetailComponent,
        data: {
            help: 'employees.attendance.document.detail',
            title: 'employees_datetimes.page_title_index_document_detail'
        }
    },

    {
        path: 'attendance/gross-salary-count',
        component: EmployeeAttendanceGrossSalaryCountComponent,
        data: {
            help: 'employees.attendance.grossSalaryCount',
            title: 'employees_datetimes.page_title_index_gross_salary_count'
        }
    },

    {
        path: 'attendance/hour-gross-count',
        component: EmployeeAttendanceHourGrossSalaryCountComponent,
        data: {
            help: 'employees.attendance.hourGrossCount',
            title: 'employees_datetimes.page_title_index_hour_gross_count'
        }
    },

    {
        path: 'attendance/net-salary-count',
        component: EmployeeAttendanceNetSalaryCountComponent,
        data: {
            help: 'employees.attendance.netSalaryCount',
            title: 'employees_datetimes.page_title_index_net_salary_count'
        }
    },

    {
        path: 'attendance/normalized-check',
        component: EmployeeAttendanceNormalizeCheckComponent,
        data: {
            help: 'employees.attendance.normalizedCheck',
            title: 'employees_datetimes.page_title_index_normalized_check'
        }
    },

    {
        path: 'attendance/real-net-salary-count',
        component: EmployeeAttendanceRealNetSalaryCountComponent,
        data: {
            help: 'employees.attendance.realNetSalaryCount',
            title: 'employees_datetimes.page_title_index_real_net_salary_count'
        }
    },

    {
        path: 'structure-tree',
        component: EmployeeStructureTreeComponent,
        data: {
            help: 'employees.employee.structureTree',
            title: 'employees.page_title_structure_tree'
        }
    },
    {
        path: 'list',
        component: EmployeeListComponent,
        data: {
            help: 'main_processes.employee_creation',
            title: 'employees.page_title_index'
        }
    },
    {
        path: 'list-by-type',
        component: EmployeeListByTypeComponent,
        children: [
            {
                path: 'archive',
                component: EmployeeListComponent,
                data: {
                    help: 'main_processes.employees_types',
                    title: 'employees.page_title_index_archived',
                    type: 'archived'
                }
            },
            {
                path: 'contractor',
                component: EmployeeListComponent,
                data: {
                    help: 'main_processes.employees_types',
                    title: 'employees.page_title_index_contractor',
                    type: 'CONTRACTOR'
                }
            },
            {
                path: 'potential',
                component: EmployeeListComponent,
                data: {
                    help: 'main_processes.employees_types',
                    type: 'POTENTIAL',
                    title: 'employees.page_title_index_potential',
                }
            },
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [
        RouterModule,
    ]
})
export class EmployeeRoutingModule {}
