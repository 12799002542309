import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeePosition } from 'src/app/employee/models/EmployeePosition';
import { CreateEmployeePositionModalComponent } from '../../sections/create-employee-position-modal/create-employee-position-modal.component';

@Component({
    selector: 'app-employee-positions',
    templateUrl: './employee-positions.component.html',
    styleUrls: ['./employee-positions.component.css']
})
export class EmployeePositionsComponent implements OnInit {

    @Input() employee: Employee;
    @Output() refetchPositions = new EventEmitter<string>();
    public loading$: Observable<boolean>;
    private _loading$ = new BehaviorSubject<boolean>(true);

    public constructor(
        private _modalService: NgbModal,
        private _route: ActivatedRoute
    ) { }

    private _positions$: Observable<Array<EmployeePosition>>;

    public get positions$(): Observable<Array<EmployeePosition>> {
        return this._positions$;
    }

    @Input()
    set positions$(positions$: Observable<Array<EmployeePosition>>) {
        this._positions$ = positions$
            .pipe(
                shareReplay(),
                tap(() => this._loading$.next(false))
            );
    }

    public ngOnInit(): void {
        this.loading$ = this._loading$;
    }

    public openPositionCreateModal(position: EmployeePosition): void {
        const modalRef = this._modalService.open(CreateEmployeePositionModalComponent, {centered: true});

        modalRef.componentInstance.employeeID = this.employee.employee_ID;
        modalRef.componentInstance.employee = this.employee;

        if (position) {
            modalRef.componentInstance.position = position;
        }

        modalRef.result
            .then(
                () => this.refetchPositions.emit('refetch'),
                () => { }
            );
    }
}
