import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Injectable()
export class FormHelper {
    public getFilledRequiredControlsCount(controls: object): number {
        const filled = [];
        for (const name of Object.keys(controls)) {
            const control = controls[name];
            const {validator} = control;
            if (validator) {
                const validation = validator(new UntypedFormControl());
                if (controls[name].value && controls[name].value !== '' && validation !== null && validation.required === true) {
                    filled.push(name);
                }
            }
        }

        return filled.length;
    }

    public getRequiredControlsCount(controls: object): number {
        const required = [];
        for (const name of Object.keys(controls)) {
            const control = controls[name];
            const {validator} = control;
            if (validator) {
                const validation = validator(new UntypedFormControl());
                if (validation !== null && validation.required === true) {
                    required.push(control);
                }
            }
        }

        return required.length;
    }
}
