import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {FileService} from '../../../../shared/services/file.service';
import {DocumentService} from '../../../../employee/services/document.service';
import {PropertyService} from '../../../../property/services/property.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {SubscriptionService} from '../../../../setting/services/subscription.service';
import {FormHelper} from '../../../../shared/common/FormHelper';

interface FileWithDataUrl extends File {
  dataURL: string;
}

@Component({
  selector: 'app-file-preview-modal',
  templateUrl: './file-preview-modal.component.html',
  styleUrls: ['./file-preview-modal.component.css']
})
export class FilePreviewModalComponent implements OnInit {
  @ViewChild('inputPdfPassword', { static: true })
  public inputPdfPassword: TemplateRef<ElementRef>;

  public get passwordForm(): { [formControlName: string]: AbstractControl; } {
    return this.pdfPasswordForm.controls;
  }

  public pdfSrc$: Observable<any>;
  public file: FileWithDataUrl;
  public pdfPasswordForm: UntypedFormGroup;
  public submitted = false;
  public filePdfIsLoaded = false;
  public incorrectPasswordMessage = false;
  public passwordSet = false;
  public isEncrypted = false;

  private _pdfSrc$: Observable<any>;

  public constructor(
      public activeModal: NgbActiveModal,
      private _fileService: FileService,
      private _documentService: DocumentService,
      private _modalService: NgbModal,
      private _fb: UntypedFormBuilder,
      private _router: Router,
      private _propertyService: PropertyService,
      private _authService: AuthenticationService,
      private _subscriptionService: SubscriptionService,
      private _formHelper: FormHelper
  ) { }

  public ngOnInit(): void {
    this.getFile();

    this.pdfPasswordForm = this._fb.group({
      password: ['', Validators.required]
    });
  }

  onError(error: any): void {
    if (error?.message === 'Incorrect Password') {
      this.incorrectPasswordMessage = true;
    }
  }

  afterLoadComplete(pdfData: any) {
    this.filePdfIsLoaded = true;
  }


  public getFile(): void {
    const reader = new FileReader();
    reader.readAsArrayBuffer(this.file);
    reader.onload = () => {
      if (this.file.type.includes('pdf')) {
        this._pdfSrc$ = of({})
            .pipe(
                map(pdf => ({...pdf, data: reader.result})
                ));
        this.pdfSrc$ = this._pdfSrc$;

        this.file.text().then(text => {
          if (text.includes('Encrypt')) { // is Encrypted
            this.isEncrypted = true;
            this.openPdfPasswordModal();
          }
        });
      }
    };
  }

  public onSubmitPassword(modal): void {
    this.submitted = true;
    if (!this.pdfPasswordForm.valid) {
      return;
    }

    this.passwordSet = true;

    const value = this.pdfPasswordForm.value.password;

    this.pdfSrc$ = this._pdfSrc$.pipe(
        map(pdf => ({ ...pdf, password: value})));

    this.incorrectPasswordMessage = false;
    modal.dismiss();
  }

  public openPdfPasswordModal(): void {
    this._modalService.open(this.inputPdfPassword, { centered: true });
  }
}
