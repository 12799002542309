import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'localizedCurrency'
})
export class LocalizedCurrencyPipe implements PipeTransform {

    private _currencySettings: any;

    public constructor(
        private _ts: TranslateService,
    ) { }

    public transform(value: number | string): string {
        if (value === undefined || value === null) {
            return '';
        }
        const label = this._ts.instant('formatters.currency.label');
        const thousandsDelimiter = this._ts.instant('formatters.currency.thousands_delimiter');
        const decimalDelimiter = this._ts.instant('formatters.currency.decimal_delimiter');
        const decimalCount = parseInt(this._ts.instant('formatters.currency.round'), 10);
        const prefix = this._ts.instant('formatters.currency.prefix') === '1';

        const parts = value.toString().split('.');
        const numericPart = this.delimitedString(parts[0], thousandsDelimiter);
        const decimalPart = parts.length > 1 ? parts[1] : '0';
        const decimalRounded = Math.round(parseInt(decimalPart.substr(0, decimalCount + 1), 10) / 10).toString().padEnd(decimalCount, '0');

        let fullNumber: string;
        if (decimalCount > 0 && decimalPart) {
            fullNumber = numericPart + decimalDelimiter + decimalRounded;
        } else {
            fullNumber = numericPart;
        }

        if (label) {
            if (prefix) {
                return label + ' ' + fullNumber;
            } else {
                return fullNumber + ' ' + label;
            }
        }

        return fullNumber;

    }

    public delimitedString(str: string, dlm: string) {
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(str)) {
            str = str.replace(pattern, '$1' + dlm + '$2');
        }
        return str;
    }
}
