import { Component } from '@angular/core';

@Component({
    selector: 'app-setting-positions-branches',
    templateUrl: './setting-positions-branches.component.html',
    styleUrls: ['./setting-positions-branches.component.css']
})
export class SettingPositionsBranchesComponent {
    public constructor() { }
}
