import { Component, OnDestroy, OnInit } from '@angular/core';
import { NumberSeriesService } from 'src/app/setting/services/number-series.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NumberSeries } from 'src/app/setting/models/NumberSeries';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-create-edit-number-series',
    templateUrl: './create-edit-number-series-modal.component.html',
    styleUrls: ['./create-edit-number-series-modal.component.css']
})
export class CreateEditNumberSeriesModalComponent implements OnInit, OnDestroy {
    public seriesEditForm: UntypedFormGroup;
    public submitted = false;
    public numberSerieID: number;
    public formChangeSubscription: Subscription;
    public numberSeriePreview: string;
    public showNumberSerieChangeWarning = false;
    public numberSeriesTokens = {
        '%Y%': '2021',
        '%y%': '21',
        '%NMB_5%': '00001',
        '%NMB_4%': '0001',
        '%NMB_3%': '001',
        '%NMB_2%': '01'
    };

    constructor(
        private nsService: NumberSeriesService,
        private fb: UntypedFormBuilder,
        public activeModal: NgbActiveModal,
    ) { }

    public ngOnInit(): void {
        this.seriesEditForm = this.fb.group({
            name: ['', Validators.required],
            default_for: ['', Validators.required],
            prefix: ['F', Validators.required],
            year_format: ['%Y%', Validators.required],
            count: ['%NMB_3%', Validators.required]
        });

        if (this.numberSerieID) {
            this.nsService.getNumberSeriesByID(this.numberSerieID).subscribe((numberSerie: NumberSeries) => {
                const codeFormatArray = numberSerie.code_format.split(',');
                if (codeFormatArray.length === 2) {
                    codeFormatArray.splice(1, 0, 'EMPTY');
                }
                this.seriesEditForm.patchValue({
                    name: numberSerie.name,
                    default_for: numberSerie.default_for,
                    prefix: codeFormatArray[0],
                    year_format: codeFormatArray[1],
                    count: codeFormatArray[2]
                });
            });
        }
        this.buildNumberSeriePreview(this.seriesEditForm.value);
        this.formChangeSubscription = this.seriesEditForm.valueChanges.subscribe(val => {
            this.buildNumberSeriePreview(val);
            if (this.seriesEditForm.dirty && this.numberSerieID) {
                this.showNumberSerieChangeWarning = true;
            }
        });

    }

    public get f() {
        return this.seriesEditForm.controls;
    }

    public onSubmit() {
        this.submitted = true;
        if (!this.seriesEditForm.valid) {
            return;
        }
        let splittedString = '';
        if (this.f.year_format.value === 'EMPTY') {
            splittedString = [this.f.prefix.value, this.f.count.value].join(',');
        } else {
            splittedString = [this.f.prefix.value, this.f.year_format.value, this.f.count.value].join(',');
        }

        let promise = null;

        if (this.numberSerieID) {
            promise = this.nsService.saveNumberSeries(this.f.name.value, this.f.default_for.value, splittedString, this.numberSerieID);
        } else {
            promise = this.nsService.saveNumberSeries(this.f.name.value, this.f.default_for.value, splittedString);
        }
        promise.then(() => this.activeModal.close('close'));
    }

    public buildNumberSeriePreview(formValue: any) {
        const yearFormat = this.numberSeriesTokens[formValue.year_format] ? this.numberSeriesTokens[formValue.year_format] : 'EMPTY';
        const count = this.numberSeriesTokens[formValue.count];
        if (yearFormat === 'EMPTY') {
            this.numberSeriePreview = formValue.prefix + count;
        } else {
            this.numberSeriePreview = formValue.prefix + yearFormat + count;
        }
    }

    public deleteNumberSeries(numberSeriesID: number) {
        const promise = this.nsService.deleteNumberSeries(numberSeriesID);
        promise.then(() => this.activeModal.close('close')).catch(() => {});
    }

    public ngOnDestroy(): void {
        this.formChangeSubscription?.unsubscribe();
    }
}
