import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-employee-scheduled-tasks',
  templateUrl: './employee-scheduled-tasks.component.html',
  styleUrls: ['./employee-scheduled-tasks.component.css']
})
export class EmployeeScheduledTasksComponent implements OnInit {
  public employeeID$: Observable<number>;

  public constructor(private _route: ActivatedRoute) { }

  public ngOnInit(): void {
    this.employeeID$ = this._route.parent.params
      .pipe(
        map(params => parseInt(params.id, 10)),
        shareReplay()
      );
  }
}
