import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { localStorageSafe } from '../../../../shared/functions';

@Component({
    selector: 'app-employee-attendance-chart',
    templateUrl: './employee-attendance-chart.component.html',
    styleUrls: ['./employee-attendance-chart.component.css']
})
export class EmployeeAttendanceChartComponent implements OnInit {
    public from: string;
    public to: string;
    public chartType: Observable<string>;

    public constructor(
        private _route: ActivatedRoute,
        private _authService: AuthenticationService
    ) { }

    public ngOnInit(): void {
        this.chartType = this._route.queryParams.pipe(
            map(params => {
                const chartType = localStorageSafe.getItem('attendance_chart_type');
                if (chartType) {
                    return chartType;
                }
                if (!params.type) {
                    return 'gannt';
                }
                return params.type;
            })
        );

        const defaultRange = this._authService.loggedUser?.default_filters?.gantt_range?.detail;
        this.from = defaultRange === 'DEFAULT' ? moment().subtract(7, 'days').format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD');
        this.to = defaultRange === 'DEFAULT' ? moment().add(30, 'days').format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD');
    }
}
