import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Routes } from '../shared/models/Route';
import { ActiveOnboardings } from './components/sections/active-onboardings/active-onboardings.component';
import { OnboardingListComponent } from './components/sections/onboarding-list/onboarding-list.component';
import { OnboardingOverviewComponent } from './components/sections/onboarding-overview/onboarding-overview.component';

const routes: Routes = [
    {
        path: 'active_onboardings',
        component: ActiveOnboardings,
        data: {
            help: 'main_processes.onboardings_offboardings_management',
            title: 'onboardings.page_title_active'
        }
    },
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },

    {
        path: 'list',
        component: OnboardingListComponent,
        data: {
            help: 'main_processes.onboardings_offboardings_management',
            title: 'onboardings.page_title_index'
        }
    },

    {
        path: 'overview/:onboardingID',
        component: OnboardingOverviewComponent,
        data: {
            help: 'main_processes.onboardings_offboardings_management',
            title: 'onboardings.page_title_view'
        }
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [
        RouterModule,
    ]
})
export class OnboardingRoutingModule {}
