import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeeReviewService } from 'src/app/kpi/employee-review/employee-review.service';
import { SummernoteHelper } from 'src/app/shared/common/SummernoteHelper';

@Component({
    selector: 'app-kpi-radar-chart-modal-component',
    templateUrl: './kpi-radar-chart-modal.component.html',
    styleUrls: ['./kpi-radar-chart-modal.component.css']
})
export class KpiRadarChartModalComponent implements OnInit {
    public scoreType: string;
    public kpiID: number;
    public employeeID: number;
    public kpiRadarChart$: Observable<any>;

    public constructor(
        public activeModal: NgbActiveModal,
        public summernoteHelper: SummernoteHelper,
        private _employeeReviewService: EmployeeReviewService,
    ) { }

    public ngOnInit(): void {
        if (this.scoreType === 'latest') {
            this.kpiRadarChart$ = this._employeeReviewService.getRadarForEmployeeKPI([this.employeeID], [this.kpiID], null, true)
                .pipe(
                    map((data: any) => [data])
                );
        }

        if (this.scoreType === 'avg') {
            this.kpiRadarChart$ = this._employeeReviewService.getRadarForEmployeeKPI([this.employeeID], [this.kpiID])
                .pipe(
                    map((data: any) => [data])
                );
        }

        if (this.scoreType === 'both') {
            this.kpiRadarChart$ = combineLatest([
                this._employeeReviewService.getRadarForEmployeeKPI([this.employeeID], [this.kpiID], null, true),
                this._employeeReviewService.getRadarForEmployeeKPI([this.employeeID], [this.kpiID])
            ]);
        }

        this.kpiRadarChart$.subscribe((data: any) => {
            console.log(data);
        });
    }
}
