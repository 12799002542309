import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TagListComponent } from './components/sections/tag-list/tag-list.component';

const routes: Routes = [
  { path: 'list', component: TagListComponent, data: { title: 'tag.list_title' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TagRoutingModule { }
