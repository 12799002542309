<apx-chart
  [chart]="chart"
  [dataLabels]="dataLabels"
  [labels]="labels"
  [series]="series"
  [yaxis]="yaxis"
  [xaxis]="xaxis"
  [stroke]="stroke"
  [colors]="colors"
  [plotOptions]="plotOptions"
  [grid]="grid"
  [tooltip]="tooltip"
  [legend]="{show: false}"
></apx-chart>
