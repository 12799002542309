<ng-container>
      <div class="card shadow h-100">
        <app-help *ngIf="isDashboard" name="dashboard.note"></app-help>
        <app-help *ngIf="!isDashboard" name="employee.detail.note"></app-help>
        <div *ngIf="!isDashboard" class="card-header py-3">
          <div class="row">
            <div class="col-6 col-sm-6 col-md-7">
              <p class="modal-title font-16 text-dark pt-2 pb-2">
                <span>{{ 'notes.note' | translate }}</span>
              </p>
            </div>

            <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
              <button
                class="ml-sm-2"
                [updateButton]="'notes.action_edit' | translate"
                (click)="openNoteModal()"
              ></button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12">
                <div
                  *ngIf="note$ | async; let note;"
                >
                  <div *ngIf="note.text" [innerHtml]="note.text | safeHtml"></div>
                  <div *ngIf="!note.text" class="mt-3 text-center text-muted">
                    <p class="mx-auto my-0 add-note" (click)="openNoteModal()">
                      {{ 'notes.empty_widget' | translate }}
                    </p>
                  </div>
                </div>
            </div>
          </div>
          <div *ngIf="isDashboard" class="text-right mt-2">
            <a
              class="text-muted"
              (click)="openNoteModal()"
            >
              <u>{{ 'notes.edit' | translate }}</u>
            </a>
          </div>
        </div>
      </div>
</ng-container>
