<ng-template
    #content
    let-modal
>
    <div class="modal-content">
        <div class="modal-header">
            <p class="modal-title font-16 mb-0 text-dark">
                <span>{{ 'company_employee_policies.modal_title_add_policy_to_employee' | translate }}</span>
            </p>

            <button
                aria-label="Close"
                class="close"
                type="button"
                (click)="modal.dismiss()"
            >
        <span
            aria-hidden="true"
            class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
            </button>
        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-0 shadow-none">
                        <div class="card-body p-0">
                            <form
                                autocomplete="off"
                                [formGroup]="addPolicyToEmployeeForm"
                                (ngSubmit)="onSubmit()"
                            >
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label class="control-label">
                                                <span>{{ 'company_employee_policies.policies' | translate }}</span>
                                            </label>

                                            <ng-select
                                                bindLabel="name"
                                                bindValue="company_employee_policy_ID"
                                                class="custom"
                                                formControlName="company_employee_policy_ID"
                                                name="company_employee_policy_ID"
                                                [placeholder]="'company_employee_policies.select_policy' | translate"
                                                [clearable]="false"
                                                [items]="policyService.policies$ | async"
                                            ></ng-select>

                                            <div *ngIf="submitted && f.company_employee_policy_ID.errors">
                                                <p
                                                    *ngIf="f.company_employee_policy_ID.errors.required"
                                                    class="text-danger validation-text"
                                                >
                                                    <span>{{ 'angular_validation.field' | translate }}</span>
                                                    <span>{{ 'company_employee_policies.policies' | translate }}</span>
                                                    <span>{{ 'angular_validation.required' | translate }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-12 text-left">
                                        <button
                                            class="btn btn-success"
                                            type="submit"
                                        >{{ 'employees.action_save' | translate }}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="card shadow">
    <app-help name="employee.detail.attendance.policy"></app-help>

    <div class="card-header py-3">
        <div class="row">
            <div class="col-sm-6 text-left my-auto">
                <p class="modal-title font-16 pt-2 pb-2 text-dark">
                    <span>{{ 'company_employee_policies.policies' | translate }}</span>
                </p>
            </div>

            <div class="col-sm-6 text-right my-auto">
                <button
                    *ngIf="employee?.permissions?.createPolicy"
                    [updateButton]="'company_employee_policies.action_add_policy_to_employee' | translate"
                    (click)="openPolicyAssignModal(content)"
                ></button>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="row">
            <div class="col-12">
                <p *ngIf="employeePolicy$ | async; let policy; else noAssignedPolicyTemplate">
                    <span>{{ 'company_employee_policies.assigned_policy' | translate }}</span>
                    <span>:&nbsp;</span>
                    <span>{{ policy.label }}</span>
                </p>

                <ng-template #noAssignedPolicyTemplate>
                    <span>{{ 'company_employee_policies.no_assigned_policy' | translate }}</span>
                </ng-template>
            </div>
        </div>
    </div>

    <ng-container *ngIf="employeeAdjustments$ | async; let adjustments">
        <div class="card-header py-3">
            <div class="row">
                <div class="col-sm-6 text-left my-auto">
                    <p class="modal-title font-16 pt-2 pb-2 text-dark">
                        <span>{{ 'employees_policies_adjustements.adjustements' | translate}}</span>
                    </p>
                </div>

                <div class="col-sm-6 text-right my-auto">
                    <button
                        *ngIf="employee?.permissions?.createAdjustement"
                        [createButton]="'employees_policies_adjustements.action_create' | translate"
                        (click)="openAdjustmentEditModal(null)"
                    ></button>
                </div>
            </div>
        </div>

        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-hover table-centered mb-0">
                    <thead>
                        <tr>
                            <td>{{ 'employees_policies_adjustements.effective_from' | translate }}</td>
                            <td>{{ 'company_employee_policies.holiday_days' | translate }}</td>
                            <td>{{ 'company_employee_policies.holiday_transfer_days' | translate }}</td>
                            <td>{{ 'company_employee_policies.home_office_days' | translate }}</td>
                            <td *ngIf="employee?.total_sick_days > 0">{{ 'company_employee_policies.sick_days' | translate }}</td>
                            <td></td>
                        </tr>
                    </thead>

                    <tbody>
                        <ng-container *ngIf="adjustments?.length else noAdjustmentsTemplate">
                            <tr *ngFor="let a of adjustments">
                                <td>{{ a.effective_from | date:'dd.MM.yyyy' }}</td>
                                <td>{{ a.holiday_hours }}</td>
                                <td>{{ a.holiday_transfer_hours }}</td>
                                <td>{{ a.home_office_hours }}</td>
                                <td *ngIf="employee?.total_sick_days > 0">{{ a.sick_day_hours }}</td>
                                <td class="text-right">
                                    <a
                                        *ngIf="employee?.permissions?.createAdjustement"
                                        class="btn btn-white btn-icon btn-sm mr-1"
                                        title="{{ 'employees_positions.action_edit' | translate }}"
                                        (click)="openAdjustmentEditModal(a)"
                                    >
                                        <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                    </a>
                                </td>
                            </tr>
                        </ng-container>

                        <ng-template #noAdjustmentsTemplate>
                            <tr class="text-center">
                                <td colspan="6">{{ 'global.empty_widget_error' | translate }}</td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>
</div>
