import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'body', // tslint:disable-line:component-selector
    templateUrl: './body.component.html'
})
export class BodyComponent {
    @HostBinding('class.app-loading')
    public appLoading = true;
}
