  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">{{ 'demo.turn_off_data_demo_data_modal_title' | translate }}</p>

      <button
        aria-label="Close"
        class="close"
        type="button"
        (click)="close()"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-lg-12">
                  <div *ngIf="showExplanation" class="mb-2 text-dark">
                    {{ 'demo.explanation' | translate }}
                  </div>
                  <div class="form-group">
                    <p class="text-dark">{{ 'demo.turn_off_data_demo_data_modal_text' | translate }}</p>
                    <div class="mt-2 text-dark">
                      <span>
                        {{ 'global.welcome_modal.welcome_text_3' | translate}}
                        <a href="mailto:info@speybl.com"> support@speybl.com </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-link text-muted pr-0 mr-4"
                  >
                    <u (click)="close()" >{{ 'demo.action_cancel' | translate }} </u>
                  </button>
                  <button
                    class="btn btn-success"
                    type="button"
                    (click)="disable()"
                  >{{ 'demo.action_confirm' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
