import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AnalysisResponse } from 'src/app/shared/common/AnalysisResponse';
import { AxisChart } from 'src/app/shared/common/AxisChart';

interface KpiAnalysisExport {
    extension: string;
    fileBase64: string;
    name: string;
}

interface AttendanceAnalysisExport {
    extension: string;
    fileBase64: string;
    name: string;
    message: string;
}

interface SalaryAnalysisExport {
    extension: string;
    fileBase64: string;
    name: string;
}

interface KpiAnalysisExportResponse extends KpiAnalysisExport {
    message: string;
}

interface SalaryAnalysisExportResponse extends SalaryAnalysisExport {
    message: string;
}

interface KpiAnalysisFilterForm {
    employeesIDs: number[];
    category: string;
    from: string;
    to: string;
    categoryValues: number[];
}

export interface AttendanceAnalysisFilterForm {
    employeeDatetimeTypeIDs?: number[];
    employeesIDs: number[];
    export?: boolean;
    from: string;
    groupByCategory?: boolean;
    groupByEmployee?: boolean;
    periodType?: 'week' | 'month' | 'quarter' | 'year';
    to: string;
}

interface SalaryAnalysisFilterForm {
    category: string;
    categoryValues: string[];
    categoryKpisValues: number[];
    employeesIDs: number[];
    from: string;
    groupByCategory: boolean;
    groupByEmployee: boolean;
    periodType: string;
    to: string;
    prediction_periods: number;
}

@Injectable({
    providedIn: 'root',
})
export class AnalysisService {
    public constructor(private _http: HttpClient) {}

    public exportKpiAnalysis(filterForm: KpiAnalysisFilterForm): Observable<KpiAnalysisExportResponse | null> {
        let params = this._getKpiAnalysisParams(filterForm);

        if (!params) {
            params = new HttpParams();
        }

        params = params.set('export', '1');

        return this._http
            .get<KpiAnalysisExportResponse>('/api/evaluations/reviews/stats', {
                params,
            })
            .pipe(
                map((response) => {
                    if (response.message === 'documents.action_generate_successful') {
                        return {
                            extension: response.extension,
                            fileBase64: response.fileBase64,
                            name: response.name,
                            message: response.message,
                        };
                    } else {
                        return null;
                    }
                }),
                catchError(() => of(null))
            );
    }

    public getGeneralStats(parameters: Array<string>): Observable<any> {
        let params = new HttpParams();
        parameters.forEach((key) => {
            params = params.set(key, '1');
        });
        return this._http.get<any>('/api/stats', {params});
    }

    public exportAttendanceAnalysisToExcel(filterForm: AttendanceAnalysisFilterForm): Observable<AttendanceAnalysisExport | null> {
        let params = this._getAttendanceAnalysisParams({
            ...filterForm,
            export: true,
        });

        if (!params) {
            params = new HttpParams();
        }

        return this._getAttendanceAnalysis<AttendanceAnalysisExport>(params).pipe(
            map((response) => {
                if (response.message === 'documents.action_generate_successful') {
                    return {
                        extension: response.extension,
                        fileBase64: response.fileBase64,
                        name: response.name,
                        message: response.message,
                    };
                } else {
                    return null;
                }
            }),
            catchError(() => of(null))
        );
    }

    public exportSalaryAnalysis(filterForm: SalaryAnalysisFilterForm): Observable<SalaryAnalysisExportResponse | null> {
        let params = this._getSalaryAnalysisParams(filterForm);

        if (!params) {
            params = new HttpParams();
        }

        params = params.set('export', '1');

        return this._http
            .get<SalaryAnalysisExportResponse>('/api/employees/salaries/analysis', {
                params,
            })
            .pipe(
                map((response) => {
                    if (response.message === 'documents.action_generate_successful') {
                        return {
                            extension: response.extension,
                            fileBase64: response.fileBase64,
                            name: response.name,
                            message: response.message,
                        };
                    } else {
                        return null;
                    }
                }),
                catchError(() => of(null))
            );
    }

    public getAttendanceAnalysis(filterForm: AttendanceAnalysisFilterForm): Observable<AxisChart> {
        let params = this._getAttendanceAnalysisParams(filterForm);

        if (!params) {
            params = new HttpParams();
        }

        return this._getAttendanceAnalysis<AxisChart>(params);
    }

    public getAttendanceAnalysisInfoByEmployeeID(employeeID: number, filterForm?: { from: string; to: string; categoryValues: string[] }): Observable<AxisChart> {
        let params = null;

        if (filterForm) {
            params = new HttpParams()
                .set('from', filterForm.from)
                .set('to', filterForm.to)
                .set('categoryValues', filterForm.categoryValues.join(','));
        }

        return this._http.get<AxisChart>(
            '/api/employees/' + employeeID + '/datetimes/stats',
            {params}
        );
    }

    public getEmployeeStats(filterForm: any): Observable<AxisChart> {
        let params = null;
        if (filterForm) {
            params = new HttpParams()
                .set('prediction_periods', filterForm.prediction_periods);
        }

        return this._http.get<AxisChart>('/api/employees/stats', {params});
    }

    public getEmployeeSalaryStats(): Observable<any> {
        return this._http.get('/api/employees/salaries/stats');
    }

    public getCRMAnalysis(filterForm: {
        from: string;
        to: string;
        periodType: string;
        categoryValues: string[];
    }) {
        let params = null;

        if (filterForm) {
            params = new HttpParams()
                .set('from', filterForm.from)
                .set('to', filterForm.to)
                .set('periodType', filterForm.periodType)
                .set('categoryValues', filterForm.categoryValues.join(','));
        }

        return this._http.get<AnalysisResponse>('/api/crm/analysis', {params});
    }

    public getCRMAnalysisCategories() {
        return this._http.get<Array<{ label: string; value: string }>>(
            '/api/crm/analysis/categories'
        );
    }

    public getCompanyGroupStats(): Observable<any> {
        return this._http.get('/api/company-groups/stats');
    }

    public getReviewStats(filterForm?: { employeesIDs: number[]; category: string; from: string; to: string; categoryValues: string[]; }) {
        let params = null;

        if (filterForm) {
            params = new HttpParams()
                .set('employeesIDs', (filterForm.employeesIDs || []).join(','))
                .set('from', filterForm.from)
                .set('to', filterForm.to)
                .set('categoryValues', filterForm.categoryValues.join(','));
        }

        return this._http
            .get('/api/evaluations/reviews/stats', {params})
            .pipe(catchError(() => of([])));
    }

    public getSalaryAnalysis(filterForm?: SalaryAnalysisFilterForm, showKpis = false) {
        let params = this._getSalaryAnalysisParams(filterForm, showKpis);

        if (!params) {
            params = new HttpParams();
        }

        params = params.set('chart', '1');

        return this._http
            .get('/api/employees/salaries/analysis', {params})
            .pipe(catchError(() => of([])));
    }

    public getSalaryAnalysisCategories() {
        return this._http
            .get('/api/employees/salaries/analysis/categories')
            .pipe(catchError(() => of([])));
    }

    public getSalaryAnalysisForGrid(filterForm?: SalaryAnalysisFilterForm) {
        const params = this._getSalaryAnalysisParams(filterForm, true);

        return this._http
            .get('/api/employees/salaries/analysis', {params})
            .pipe(catchError(() => of(null)));
    }

    public getSalaryAnalysisInfoByEmployeeID(employeeID: number, filterForm?: { from: string; to: string; categoryValues: string[]; groupByCategory: boolean; }): Observable<AxisChart> {
        let params = null;

        if (filterForm) {
            params = new HttpParams()
                .set('categoryValues', filterForm.categoryValues.join(','))
                .set('from', filterForm.from)
                .set('groupByCategory', String(Number(filterForm.groupByCategory)))
                .set('to', filterForm.to)
                .set('chart', '1');
        }

        return this._http.get<AxisChart>(
            '/api/employees/' + employeeID + '/salaries/info',
            {params}
        );
    }

    public getKPIAnalysisInfoByEmployeeID(employeeID: number, filterForm?: { from: string; to: string; categoryValues: string[] }): Observable<any> {
        let params = null;

        if (filterForm) {
            params = new HttpParams()
                .set('from', filterForm.from)
                .set('to', filterForm.to)
                .set('categoryValues', filterForm.categoryValues?.join(','));
        }

        return this._http.get<AxisChart>(
            '/api/employees/' + employeeID + '/evaluations/stats',
            {params}
        );
    }

    private _getKpiAnalysisParams(filterForm: KpiAnalysisFilterForm): HttpParams | null {
        let params = null;

        if (filterForm) {
            params = new HttpParams()
                .set('employeesIDs', (filterForm.employeesIDs || []).join(','))
                .set('category', filterForm.category)
                .set('from', filterForm.from)
                .set('to', filterForm.to)
                .set('categoryValues', filterForm.categoryValues.join(','));
        }

        return params;
    }

    private _getSalaryAnalysisParams(filterForm: SalaryAnalysisFilterForm, showKpis = false): HttpParams | null {
        let params = null;

        if (filterForm) {
            params = new HttpParams()
                .set('categoryValues', filterForm.categoryValues.join(','))
                .set('employeesIDs', (filterForm.employeesIDs || []).join(','))
                .set('from', filterForm.from)
                .set('groupByEmployee', String(Number(filterForm.groupByEmployee)))
                .set('groupByCategory', String(Number(filterForm.groupByCategory)))
                .set('periodType', filterForm.periodType)
                .set('to', filterForm.to)
                .set('prediction_periods', filterForm.prediction_periods);

            if (filterForm.categoryKpisValues && showKpis) {
                params = params.append(
                    'categoryKpisValues',
                    filterForm.categoryKpisValues.join(',')
                );
            }
        }

        return params;
    }

    private _getAttendanceAnalysis<T>(params: HttpParams): Observable<T> {
        return this._http.get<T>('/api/employees/datetimes/analysis', {params});
    }

    private _getAttendanceAnalysisParams(filterForm: AttendanceAnalysisFilterForm): HttpParams | null {
        let params: HttpParams = null;

        if (filterForm) {
            params = new HttpParams()
                .set('employeesIDs', (filterForm.employeesIDs || []).join(','))
                .set('from', filterForm.from)
                .set('to', filterForm.to);

            if (filterForm.employeeDatetimeTypeIDs) {
                params = params.append(
                    'employeeDatetimeTypeIDs',
                    filterForm.employeeDatetimeTypeIDs.join(',')
                );
            }

            if (filterForm.export) {
                params = params.append('export', String(Number(filterForm.export)));
            }

            if (filterForm.groupByCategory) {
                params = params.append(
                    'groupByCategory',
                    String(Number(filterForm.groupByCategory))
                );
            }

            if (filterForm.groupByEmployee) {
                params = params.append(
                    'groupByEmployee',
                    String(Number(filterForm.groupByEmployee))
                );
            }

            if (filterForm.periodType) {
                params = params.append('periodType', filterForm.periodType);
            }
        }

        return params;
    }
}
