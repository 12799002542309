import { NgModule } from '@angular/core';

import { LocalizedCurrencyPipe } from './pipes/localized-currency.pipe';

@NgModule({
  declarations: [
    LocalizedCurrencyPipe
  ],
  exports: [
    LocalizedCurrencyPipe
  ],
  providers: [
    LocalizedCurrencyPipe
  ]
})
export class LocalizedCurrencyPipeModule { }
