import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeAddress } from 'src/app/employee/models/EmployeeAddress';
import { AddressService } from 'src/app/employee/services/address.service';
import { CountryStoreService } from 'src/app/setting/services/country-store.service';
import { FormHelper } from 'src/app/shared/common/FormHelper';
import { AddressFormComponent } from '../../embedded-forms/address/address-form.component';

@Component({
    selector: 'app-create-edit-employee-address',
    templateUrl: './create-edit-employee-address-modal.component.html',
    styleUrls: ['./create-edit-employee-address-modal.component.css']
})
export class CreateEditEmployeeAddressModalComponent implements OnInit {
    @ViewChild('childRef', {static: false}) formComponent: AddressFormComponent;

    public submitted = false;
    public presetPrimaryAddress = false;
    public employeeAddress: EmployeeAddress;
    public employeeID: number;
    public employee: Employee;
    public hasRequestDeletePermission = false;
    public hasRequestEditPermission = false;
    public canEdit: boolean;
    public updateRequested = false;

    constructor(
        private addrService: AddressService,
        private fb: UntypedFormBuilder,
        public activeModal: NgbActiveModal,
        private formHelper: FormHelper,
        public countryStoreService: CountryStoreService,
        public authService: AuthenticationService
    ) { }

    public ngOnInit(): void {
        if (this.employee.permissions.requestDeleteAddress) {
            this.hasRequestDeletePermission = true;
        }

        if (this.employee.permissions.requestEditAddress) {
            this.hasRequestEditPermission = true;
        }

        this.canEdit = this.authService.checkPermission(['employeeAddress.edit', 'employeeAddress.editStructure']);
    }

    public onSubmit() {
        this.submitted = true;
        let promise: Promise<any>;
        const form = this.formComponent.form;

        if (form.invalid) {
            return;
        }

        if (this.employeeAddress) {
            promise = this.addrService.saveAddress(form.value, this.employeeID, this.employeeAddress.employee_address_ID);
        } else {
            promise = this.addrService.saveAddress(form.value, this.employeeID);
        }

        promise.then(() => {
            this.activeModal.close('close');
        });
    }

    public deleteAddress() {
        this.addrService.deleteAddress(this.employeeID, this.employeeAddress.employee_address_ID)
            .then(() => this.activeModal.close('close')).catch(() => {});
    }

}
