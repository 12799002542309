import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Bonus } from './bonus.model';

export interface BonusState extends EntityState<Bonus> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'bonuses', idKey: 'employee_bonus_ID' })
export class BonusStore extends EntityStore<BonusState> {
  constructor() {
    super();
  }
}
