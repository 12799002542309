<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark d-none d-lg-block">{{ 'settings.basic_settings_modal.heading' | translate }}</p>
        <p class="modal-title font-16 mb-0 text-dark d-block d-lg-none">{{ 'settings.basic_settings_modal.basic_data' | translate }}</p>
        <button *ngIf="closeable" (click)="activeModal.dismiss()" type="button" class="close" aria-label="Close">
            <span class="font-24 text-muted" aria-hidden="true"><i class="mdi mdi-close"></i></span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <form [formGroup]="basicSettingForm" autocomplete="off" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-12">
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" [style.width.%]="progressBarWidth" aria-valuenow="progressBarWidth" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center mt-3 mt-lg-5">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-3">
                                                <label class="control-label">{{ 'employees.in' | translate }}</label>
                                            </div>
                                            <div class="col-9 text-right">
                                                <span *ngIf="!customerInfoLoading" class="text-muted">{{ 'settings.basic_settings_modal.data_autoload' | translate }}</span>
                                                <div *ngIf="customerInfoLoading" class="spinner-border spinner-border-sm" role="status">
                                                    <span class="sr-only">{{ 'settings.basic_settings_modal.loading' | translate }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <input formControlName="company.info.in" type="text" name="company.info.in" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                  <div class="form-group">
                                      <label class="control-label">{{ 'employees.company_name' | translate }} </label>
                                      <input formControlName="company.info.name" name="company.info.name" type="text" class="form-control">
                                      <div *ngIf="submitted && f['company.info.name'].errors">
                                          <!--<p *ngIf="f['company.info.name'].errors.required" class="text-danger validation-text">
                                              {{ 'angular_validation.field' | translate }} {{ 'employees.company_name' | translate }}
                                              {{ 'angular_validation.required' | translate }}
                                          </p>-->
                                      </div>
                                  </div>
                              </div>
                          </div>
                            <div class="row">
                              <div class="col-6">
                                <label for="showForm" class="control-label mr-2">{{ 'settings.company.info.add_more_info' | translate }}</label>
                              </div>
                              <div class="col-6 text-right">
                                <input
                                  aria-controls="collapse"
                                  [attr.aria-expanded]="showOtherFormFields"
                                  type="checkbox"
                                  [checked]="showOtherFormFields"
                                  id="showForm"
                                  name="showForm"
                                  data-switch="success"
                                  (click)="setFormCollapse()"
                                >
                                <label for="showForm">&nbsp;</label>
                              </div>
                            </div>
                            <div
                              #collapse="ngbCollapse"
                              [(ngbCollapse)]="!showOtherFormFields"
                            >
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label">{{ 'employees.tin' | translate }}</label>
                                            <input formControlName="company.info.tin" name="company.info.tin" type="text" class="form-control">
                                            <div *ngIf="submitted && f['company.info.tin'].errors">
                                                <p *ngIf="f['company.info.tin'].errors.required" class="text-danger validation-text">
                                                    {{ 'angular_validation.field' | translate }} {{ 'employees.tin' | translate }}
                                                    {{ 'angular_validation.required' | translate }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label">{{ 'settings.company.info.registered_for_vat-1' | translate }}</label>
                                            <div>
                                                <input formControlName="company.info.registered_for_vat" type="checkbox" id="company.info.registered_for_vat" name="company.info.registered_for_vat" data-switch="success">
                                                <label for="company.info.registered_for_vat">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label">{{ 'settings.company.info.registration_text' | translate }}</label>
                                            <input formControlName="company.info.registration_text" name="company.info.registration_text" type="text" class="form-control">
                                            <div *ngIf="submitted && f['company.info.registration_text'].errors">
                                                <p *ngIf="f['company.info.registration_text'].errors.required" class="text-danger validation-text">
                                                    {{ 'angular_validation.field' | translate }} {{ 'settings.company.info.registration_text' | translate }}
                                                    {{ 'angular_validation.required' | translate }}
                                                </p>
                                            </div>
                                            <!-- <small class="text-muted">{{ 'settings.basic_settings_modal.auto_footer' | translate }}</small> -->
                                        </div>
                                    </div>
                                </div>
                                <hr *ngIf="accounts?.length"/>
                                <div *ngFor="let a of accounts; let i = index" class="row">
                                    <div class="col-8">
                                        <div class="form-group">
                                            <label class="control-label">{{ 'accounts.bank_account' | translate }}</label>
                                            <input formControlName="account_number-{{i}}" name="account_number-{{i}}" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <label class="control-label">{{ 'settings.company.bank_account.bank_code' | translate }}</label>
                                        <input formControlName="account_code-{{i}}" name="account_code-{{i}}" type="text" class="form-control">
                                    </div>
                                    <div class="col-12 mb-2">
                                        <label class="control-label">{{ 'employees.bank_account_iban' | translate }}]</label>
                                        <input formControlName="account_iban-{{i}}" name="account_iban-{{i}}" type="text" class="form-control">
                                        <small class="text-muted">{{ 'settings.basic_settings_modal.auto_iban' | translate }}</small>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label">{{ 'accounts.currency' | translate }}</label>
                                            <ng-select
                                                formControlName="account_currency-{{i}}"
                                                class="custom"
                                                [clearable]="false"
                                                [items]="currencyStoreService.currencies$ | async"
                                                bindValue="code"
                                                bindLabel="code"
                                            >
                                                <ng-template ng-option-tmp let-item="item">
                                                    <span>{{ 'currencies.' + item.code | translate }}</span>
                                                </ng-template>
                                                <ng-template ng-label-tmp let-item="item">
                                                    <span>{{ 'currencies.' + item.code | translate }}</span>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label">{{ 'employees_addresses.addr_line_1' | translate }}</label>
                                            <input formControlName="company.address.addr_line_1" type="text" class="form-control" name="company.address.addr_line_1">
                                            <div *ngIf="submitted && f['company.address.addr_line_1'].errors">
                                                <p *ngIf="f['company.address.addr_line_1'].errors.required" class="text-danger validation-text">
                                                    {{ 'angular_validation.field' | translate }} {{ 'employees_addresses.addr_line_1' | translate }}
                                                    {{ 'angular_validation.required' | translate }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label">{{ 'employees_addresses.addr_line_2' | translate }}</label>
                                            <input formControlName="company.address.addr_line_2" type="text" class="form-control" name="company.address.addr_line_2">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label">{{ 'employees_addresses.city' | translate }}</label>
                                            <input formControlName="company.address.city" type="text" class="form-control" name="company.address.city">
                                            <div *ngIf="submitted && f['company.address.city'].errors">
                                                <p *ngIf="f['company.address.city'].errors.required" class="text-danger validation-text">
                                                    {{ 'angular_validation.field' | translate }} {{ 'employees_addresses.city' | translate }}
                                                    {{ 'angular_validation.required' | translate }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label class="control-label">{{ 'employees_addresses.postal_code' | translate }}</label>
                                            <input formControlName="company.address.postal_code" type="text" class="form-control" name="company.address.postal_code">
                                            <div *ngIf="submitted && f['company.address.postal_code'].errors">
                                                <p *ngIf="f['company.address.postal_code'].errors.required" class="text-danger validation-text">
                                                    {{ 'angular_validation.field' | translate }} {{ 'employees_addresses.postal_code' | translate }}
                                                    {{ 'angular_validation.required' | translate }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label class="control-label">{{ 'employees_addresses.country_code' | translate }}</label>
                                            <ng-select
                                                formControlName="company.address.country_code"
                                                class="custom"
                                                [items]="countryStoreService.countries$ | async"
                                                bindValue="country_code"
                                                bindLabel="name"
                                                [clearable]="false"
                                            >
                                            </ng-select>
                                            <div *ngIf="submitted && f['company.address.country_code'].errors">
                                                <p *ngIf="f['company.address.country_code'].errors.required" class="text-danger validation-text">
                                                    {{ 'angular_validation.field' | translate }} {{ 'employees_addresses.country_code' | translate }}
                                                    {{ 'angular_validation.required' | translate }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                              </div>
                            <div class="row mt-2">
                                <div class="col d-flex justify-content-end">
                                    <button [disabled]="loading" class="btn btn-success" type="submit">
                                      <span *ngIf="!loading; else loadingTemplate">
                                        {{ 'employees.action_save' | translate }}
                                      </span>
                                      <ng-template #loadingTemplate>
                                        <span
                                          aria-hidden="true"
                                          class="spinner-border spinner-border-sm mr-3"
                                          role="status"
                                        >  </span>
                                        <span> {{ 'settings.basic_settings_modal.loading' | translate }} </span>
                                      </ng-template>
                                   </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
