<div *ngIf="datetimeDocumentStatus$ | async as status" class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header py-3">
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-7">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">{{year}} / {{month}}</p>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <form #f="ngForm" (ngSubmit)="onSubmit(f.value)">

                    <table class="table table-centered table-striped mb-0">
                        <thead class="thead-light">
                            <tr>
                                <th> {{ 'employees.full_name' | translate }} </th>
                                <th> {{ 'employees.worked_hours_real_normalized' | translate }} </th>
                                <th> {{ 'employees.hours_vacation' | translate }} </th>
                                <th> {{ 'employees.hours_infirmary' | translate }} </th>
                                <th> {{ 'employees.hours_sick_days' | translate }} </th>
                                <th> {{ 'employees.gross_salary_hourly' | translate }}</th>
                                <th> {{ 'employees.gross_salary_hourly_avg' | translate }}</th>
                                <th> {{ 'employees.one_time_bonus' | translate }}</th>
                                <th> {{ 'employees.counted_gross_salary' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                                <tr *ngFor="let e of employees$ | async">
                                    <ng-container [ngModelGroup]="e.employee_ID">
                                    <td [attr.data-order]="e.surname_for_order + ' ' +  e.name_for_order" class="table-user">
                                        <div class="media text-nowrap">
                                            <img *ngIf="e.avatar" [src]="e.avatar" class="mr-2 rounded-circle">
                                            <div class="media-body my-auto">
                                                {{ e.fullname }}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {{e.real_work_hours}} / {{e.normalized_work_hours}}
                                    </td>
                                    <td>
                                        {{e.holiday_hours}}
                                    </td>
                                    <td>
                                        {{e.sickness_hours}}
                                    </td>
                                    <td>
                                        {{e.sick_day_hours}}
                                    </td>
                                    <td>
                                        {{e.hourly_gross_salary | localizedCurrency }}
                                    </td>
                                    <td>
                                        {{e.hourly_gross_salary_previous_quarter | localizedCurrency }}
                                    </td>
                                    <td>
                                        {{e?.bonus | localizedCurrency }}
                                    </td>
                                    <td style="width: 160px;">
                                        <input [(ngModel)]="e.gross_salary" [disabled]="status.step3" class="form-control" currencyMask name="gross_salary" type="text">
                                    </td>
                                </ng-container>
                                </tr>
                        </tbody>
                    </table>
                    <div class="row mt-4">
                        <div *ngIf="!status.step3" class="col-6 text-left">
                            <button class="btn btn-success mr-2"
                                type="submit">{{'numbers_series.action_save' | translate}}</button>
                        </div>
                    </div>
                </form>

                </div>
            </div>
        </div>
    </div>
</div>
