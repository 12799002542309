<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">
            <span *ngIf="datetimeProject">{{ 'settings.attendance.projects.page_title_edit' | translate }}</span>
            <span *ngIf="!datetimeProject">{{ 'settings.attendance.projects.page_title_create' | translate }}</span>
        </p>

        <button
            aria-label="Close"
            class="close"
            (click)="activeModal.dismiss()"
        >
      <span
          aria-hidden="true"
          class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <form
                            autocomplete="off"
                            [formGroup]="datetimeProjectForm"
                            (ngSubmit)="onSubmit()"
                        >
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">
                                            <span>{{ 'settings.attendance.projects.name' | translate }}</span>
                                        </label>

                                        <input
                                            class="form-control"
                                            formControlName="name"
                                            name="name"
                                            type="text"
                                        >

                                        <div *ngIf="submitted && f.name.errors">
                                            <p
                                                *ngIf="f.name.errors.required"
                                                class="text-danger validation-text"
                                            >
                                                <span>{{ 'angular_validation.field' | translate }} </span>
                                                <span>{{ 'settings.attendance.projects.name' | translate }} </span>
                                                <span>{{ 'angular_validation.required' | translate }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="control-label">{{'settings.attendance.projects.description' | translate }}</label>
                                        <textarea class="form-control" formControlName="description" name="description" type="text"></textarea>
                                    </div>
                                </div>
                            </div>

                            <app-targetable [formGroup]="datetimeProjectForm" [entity]="datetimeProject"></app-targetable>

                            <div class="row mt-2">
                                <div class="col d-flex justify-content-end">
                                    <button *ngIf="datetimeProject" class="btn btn-link text-muted pr-0 mr-4" type="button" (click)="deleteProject()">
                                        <u>{{ 'settings.attendance.projects.action_delete' | translate }}</u>
                                    </button>
                                    <button class="btn btn-success">
                                        <span>{{ 'settings.attendance.projects.action_save' | translate }}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
