import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SubscriptionService } from 'src/app/setting/services/subscription.service';

interface Alert {
  type: string;
  message: string;
  closeable: boolean;
}

@Component({
  selector: 'app-disable-demo-mode-modal',
  templateUrl: './disable-demo-mode-modal.component.html',
  styleUrls: ['./disable-demo-mode-modal.component.css']
})
export class DisableDemoModeModalComponent implements OnInit {
  public alerts: Array<Alert> = [];
  public demoModeActive = false;
  public showExplanation = false;
  public modalContent: any;

  public constructor(
    private _activeModal: NgbActiveModal,
    private _subscriptionService: SubscriptionService,
  ) { }

  public ngOnInit(): void {
    this._subscriptionService.subscription$
      .subscribe(
        data => {
          this.demoModeActive = data.demo_mode;

          if (this.demoModeActive) {
            this.alerts.push({
              type: 'primary',
              message: 'demo.alert_text',
              closeable: true
            });
          }

        });
  }

  public close(): void {
    this._activeModal.close();
  }

  public disable(): void {
    this._activeModal.close(true);
  }
}
