import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent } from 'rxjs';

@Component({
    selector: 'user-inactivity-modal',
    templateUrl: './user-inactivity-modal.component.html',
    styleUrls: ['./user-inactivity-modal.component.css']
})
export class UserInactivityModalComponent implements OnInit {
    public expiry: number;
    public expiryPercent: number;
    public interval: any;
    private events: string[] = ['keydown', 'click', 'wheel', 'mousemove'];

    constructor(
        public activeModal: NgbActiveModal
    ) {
    }

    public ngOnInit(): void {
        this.events.forEach(event =>
            fromEvent(document, event).subscribe(_ => this._closeModal())
        );
        const start = new Date().getTime();
        const diff = this.expiry - start;

        this.interval = setInterval(() => {
            const now = new Date().getTime();
            this.expiryPercent = (now - start) / diff * 100;
            if (this.expiryPercent >= 100 || now >= this.expiry) {
                clearInterval(this.interval);
                this.activeModal.dismiss();
            }
        }, 100);

    }

    private _closeModal(): void {
        clearInterval(this.interval);
        this.activeModal.close();
    }
}
