import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Employee } from 'src/app/employee/models/Employee';
import { Salary } from 'src/app/employee/models/Salary';
import { InputVisibilityService } from '../../../../shared/services/InputVisibilityService';
import { CreateEmployeeSalaryComponent } from '../../sections/create-employee-salary/create-employee-salary.component';

@Component({
    selector: 'app-employee-salaries',
    templateUrl: './employee-salaries.component.html',
    styleUrls: ['./employee-salaries.component.css']
})
export class EmployeeSalariesComponent {
    @Input() public employee: Employee;
    @Input() public employeeID: number;
    @Input() public salaries$: Observable<Array<Salary>>;

    @Output() public refetchSalaries = new EventEmitter<string>();

    public get hiddenInputs(): boolean {
        return this._inputVisibilityService.hiddenInputs;
    }

    public constructor(
        private _modalService: NgbModal,
        private _inputVisibilityService: InputVisibilityService,
    ) { }

    public openSalaryCreateModal(salary: Salary): void {
        const modalRef = this._modalService.open(CreateEmployeeSalaryComponent, {centered: true});

        modalRef.componentInstance.employee = this.employee;

        modalRef.componentInstance.salary = salary;

        modalRef.result
            .then(
                () => this.refetchSalaries.emit('refetch'),
                () => { }
            );
    }
}
