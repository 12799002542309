import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { EmployeePosition } from '../models/EmployeePosition';

@Injectable({
    providedIn: 'root'
})
export class EmployeePositionService {

    constructor(
        private http: HttpClient,
        private loaderService: NgxUiLoaderService,
        private apiHelper: ApiHelper,
        private swalHelper: SwalHelper
    ) { }

    public getAllPositionsByEmployeeID(employeeID: number, withDocumentTemplateData = false): Observable<EmployeePosition[]> {
        let params = new HttpParams();
        if (withDocumentTemplateData) {
            params = params.set('scope', 'document_template');
        }
        return this.http.get<EmployeePosition[]>('/api/employees/' + employeeID + '/positions?widget=EMPLOYEE_POSITION', {params});
    }

    public getEmployeePositionByID(employeeID: number, employeepositionID: number) {
        return this.http.get<EmployeePosition>('/api/employees/' + employeeID + '/positions/' + employeepositionID);
    }

    public savePosition(form: EmployeePosition, employeepositionID: number, employeeID: number) {
        this.loaderService.start();
        return new Promise(resolve => {
            if (employeepositionID) {
                this.http.post('/api/employees/' + employeeID + '/positions/' + employeepositionID, form).subscribe((response: any) => {
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                    this.loaderService.stop();
                }, error => {
                    this.apiHelper.handleErrorResponse(error);
                    this.loaderService.stop();
                });
            } else {
                this.http.post('/api/employees/' + employeeID + '/positions', form).subscribe((response: any) => {
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                    this.loaderService.stop();
                }, error => {
                    this.apiHelper.handleErrorResponse(error);
                    this.loaderService.stop();
                });
            }
        });
    }

    public deletePosition(employeeID: number, employeepositionID): Promise<string> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this.swalHelper.showConfirmDeleteDialog();
            if (deleteConfirmed) {
                this.loaderService.start();
                this.http.delete('/api/employees/' + employeeID + '/positions/' + employeepositionID).subscribe((response: ApiResponse) => {
                    this.loaderService.stop();
                    this.apiHelper.handleSuccessResponse(response);
                    resolve('done');
                }, error => {
                    this.loaderService.stop();
                    this.apiHelper.handleErrorResponse(error);
                });
            } else {
                reject('error');
            }
        });

    }
}
