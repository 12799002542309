<div class="card shadow">
    <app-help name="attendance.detail.parts"></app-help>
    <div class="card-header py-3">
        <div class="row">
            <div class="col-sm-6 text-left my-auto">
                <p class="modal-title font-16 text-dark pt-2 pb-2">
                    <span>{{ 'employees_datetimes.parts' | translate }}</span>
                </p>
            </div>
            <!-- <div class="col-sm-6 text-right my-auto">
                      <button (click)="openEditModal(null)" class="btn btn-success">{{ 'employees_datetimes.action_create_part' | translate }}</button>
                  </div> -->
        </div>
    </div>

    <div class="card-body">
        <ng-container *ngIf="employeeDateTime.parts; let parts; else loadingTemplate">
            <ng-container *ngIf="parts?.length; else noDataTemplate">
                <div class="table-responsive">
                    <table class="table table-hover table-centered">
                        <tbody>
                            <tr *ngFor="let employeeDateTime of parts">
                                <td>
                                    <span>{{ employeeDateTime.from | date:'dd.MM.yyyy HH:mm' }}</span>
                                    <span> → </span>
                                    <span>{{ employeeDateTime.to | date:'dd.MM.yyyy HH:mm' }}</span>
                                </td>

                                <td>{{ employeeDateTime.duration_hours }}</td>

                                <td class="text-right">
                                    <a
                                        class="btn btn-white btn-icon btn-sm mr-1"
                                        title="{{ 'employees_datetimes.action_view' | translate }}"
                                        (click)="openEditModal(employeeDateTime)"
                                    >
                                        <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #loadingTemplate>
            <app-row-loader></app-row-loader>
        </ng-template>

        <ng-template #noDataTemplate>
            <div class="row">
                <div class="col-12 text-center">
                    <span>{{ 'global.empty_widget_error' | translate }}</span>
                </div>
            </div>
        </ng-template>
    </div>
</div>
