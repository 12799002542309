<ng-container *ngIf="(availableWidgets$ | async)?.employee?.overview as availableWidgets">
    <div *ngIf="canShow.address && availableWidgets?.address"
         [ngClass]="{'col-lg-6': canShowBoth(availableWidgets)}"
         class="col-12 col-lg-6 mb-3"
         id="address">
        <app-employee-addresses
            (refetchActiveRequests)="getActiveRequests()"
            (refetchAddresses)="getAddresses()"
            (refetchRequiredUpdates)="getRequired()"
            *ngIf="employeeAddresses$"
            [employeeAddresses$]="employeeAddresses$"
            [employee]="employee"
            [entityRequests]="entityRequests"
            [requiredUpdates]="requiredUpdates"
        ></app-employee-addresses>
    </div>

    <div *ngIf="canShow.contactPerson && availableWidgets?.contact_persons"
         [ngClass]="{'col-lg-6': canShowBoth(availableWidgets)}"
         class="col-12 mb-3"
         id="contact_person">
        <app-employee-contact-persons
            (refetchActiveRequests)="getActiveRequests()"
            (refetchContactPersons)="getContactPersons()"
            (refetchRequiredUpdates)="getRequired()"
            *ngIf="employeeContactPersons$"
            [employeeContactPersons$]="employeeContactPersons$"
            [employee]="employee"
            [entityRequests]="entityRequests"
            [requiredUpdates]="requiredUpdates"
        ></app-employee-contact-persons>
    </div>
</ng-container>


