import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { ApiHelper } from '../../shared/common/ApiHelper';
import { SwalHelper } from '../../shared/common/SwalHelper';
import { CompanyBranch } from '../models/CompanyBranch';
import { CompanyPosition } from '../models/CompanyPosition';

@Injectable({
    providedIn: 'root'
})
export class CompanyPositionService {
    public constructor(
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _apiHelper: ApiHelper,
        private _swalHelper: SwalHelper,
    ) { }

    public getPositionsList(params: ListParameters<CompanyBranch>, filterParameters?: {}): Observable<ListResponse<CompanyPosition>> {
        const body: any = {
            ...transformParameters(params),
            filter: filterParameters,
        };

        return this._http.post<ListResponse<CompanyPosition>>('/api/company-positions/list', body);
    }

    public getAllCompanyPositions(): Observable<CompanyPosition[]> {
        return this._http.get<CompanyPosition[]>('/api/company-positions');
    }

    public saveCompanyPosition(companyPositionID: number, form: CompanyPosition): Promise<'done'> {
        return new Promise(resolve => {
            this._loaderService.start();

            if (companyPositionID) {
                this._http.post<ApiResponse>('/api/company-positions/' + companyPositionID, form)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');

                            this._loaderService.stop();
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);

                            this._loaderService.stop();
                        }
                    );
            } else {
                this._http.post<ApiResponse>('/api/company-positions', form)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');

                            this._loaderService.stop();
                        },
                        error => {

                            this._apiHelper.handleErrorResponse(error);

                            this._loaderService.stop();
                        }
                    );
            }
        });
    }

    public deleteCompanyPosition(companyPositionID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete(`/api/company-positions/${companyPositionID}`)
                    .subscribe(
                        (response: any) => {
                            this._loaderService.stop();

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }
}
