import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';

import { ContentLoaderModule } from '@ngneat/content-loader';

import { TranslateModule } from '@ngx-translate/core';

import { LocalizedCurrencyPipeModule } from 'src/app/shared/localized-currency-pipe.module';

import { DataTableComponent } from './components/data-table/data-table.component';

import { DataTableDataCellDirective } from './directives/data-table-data-cell/data-table-data-cell.directive';
import { DataTableHeaderCellDirective } from './directives/data-table-header-cell/data-table-header-cell.directive';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ScrollingModule,

    ContentLoaderModule,
    NgSelectModule,
    TranslateModule,

    LocalizedCurrencyPipeModule
  ],
  declarations: [
    DataTableComponent,
    DataTableDataCellDirective,
    DataTableHeaderCellDirective
  ],
  exports: [
    DataTableComponent,
    DataTableDataCellDirective,
    DataTableHeaderCellDirective
  ]
})
export class UIDataTableModule { }
