<ng-template
  #content
  let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'properties.page_title_edit' | translate }}</span>
      </p>

      <button
        (click)="modal.dismiss()"
        aria-label="Close"
        class="close"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <form
                #form="ngForm"
                (ngSubmit)="onSubmitEmployeeProperty(form)"
                autocomplete="off"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'properties.handover_date' | translate }}</span>
                      </label>

                      <input
                        [ngModel]="selectedEmployeeProperty?.handover_date"
                        allowInput="true"
                        altFormat="d.m.Y"
                        altInput="true"
                        class="form-control"
                        dateFormat="Y-m-d"
                        mwlFlatpickr
                        name="handover_date"
                        type="text"
                      >
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'properties.removal_date' | translate }}</span>
                      </label>

                      <input
                        [ngModel]="selectedEmployeeProperty?.removal_date"
                        allowInput="true"
                        altFormat="d.m.Y"
                        altInput="true"
                        class="form-control"
                        dateFormat="Y-m-d"
                        mwlFlatpickr
                        name="removal_date"
                        type="text"
                      >
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'properties.condition' | translate }}</span>
                      </label>

                      <input
                        [ngModel]="selectedEmployeeProperty?.condition"
                        class="form-control"
                        name="condition"
                        type="text"
                      >
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'properties.condition_handover' | translate }}</span>
                      </label>

                      <input
                        [ngModel]="selectedEmployeeProperty?.condition_handover"
                        class="form-control"
                        name="condition_handover"
                        type="text"
                      >
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'properties.condition_takeover' | translate }}</span>
                      </label>

                      <input
                        [ngModel]="selectedEmployeeProperty?.condition_takeover"
                        class="form-control"
                        name="condition_takeover"
                        type="text"
                      >
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'properties.property_month_costs' | translate }}</span>
                      </label>
                      <input [ngModel]="selectedEmployeeProperty?.property_month_costs" class="form-control" currencyMask name="property_month_costs" type="text">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="control-label">
                        <span>{{ 'properties.note' | translate }}</span>
                      </label>

                      <input
                        [ngModel]="selectedEmployeeProperty?.note"
                        class="form-control"
                        name="note"
                        type="text"
                      >
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col d-flex justify-content-end">
                    <button class="btn btn-primary">
                      <span>{{ 'properties.action_save' | translate }}</span>
                    </button>
                  </div>
                </div>
              </form>

              <app-upload
                (success)="loadEmployees()"
                [fileName]="selectedEmployeeProperty?.name + '.jpeg'"
                [modelName]="'employee_property_ID'"
                [modelValue]="employeePropertyId"
                class="mt-3"
              ></app-upload>

              <app-files-table
                (refetch)="loadEmployees()"
                [allowDelete]="canDelete$ | async"
                [entityID]="employeePropertyId"
                [entityType]="'employee-property'"
                [files$]="employeePropertyFiles$"
                [objectType]="'PROPERTY_FILE'"
                [object]="selectedEmployeeProperty"
                [showFileOnNewPage]="true"
              ></app-files-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #contentUpload
  let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'files.upload_modal_title_basic' | translate }}</span>
      </p>

      <button
        (click)="modal.dismiss()"
        aria-label="Close"
        class="close"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <app-upload
                (success)="loadPropertyFiles()"
                [fileName]="(property$ | async)?.name + '.jpeg'"
                [modelName]="'property_ID'"
                [modelValue]="(property$ | async)?.property_ID"
              ></app-upload>

              <app-files-table
                (refetch)="loadPropertyFiles()"
                *ngIf="propertyFiles$"
                [allowDelete]="true"
                [files$]="propertyFiles$"
                [objectType]="'PROPERTY'"
                [object]="property$ | async"
              ></app-files-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #contentUploadThumbnail
  let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'properties.thumbnail_upload' | translate }}</span>
      </p>

      <button
        (click)="modal.dismiss()"
        aria-label="Close"
        class="close"
        type="button"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <app-upload
                (success)="loadProperty()"
                [allowTakingPicture]="false"
                [fileName]="(property$ | async)?.name + '.jpeg'"
                [modelName]="'property_ID'"
                [modelValue]="(property$ | async)?.property_ID"
                [secondaryModelName]="'file_type'"
                [secondaryModelValue]="'THUMBNAIL'"
              ></app-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #contentThumbnail
  let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'properties.thumbnail' | translate }}</span>
      </p>

      <button
        (click)="modal.dismiss()"
        aria-label="Close"
        class="close"
        type="button"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0 text-center">
              <img
                [attr.src]="thumbnail.url"
                alt="image"
                class="img-fluid"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div
  *ngIf="property$ | async; let property"
  class="row"
>
  <div class="col-lg-6 mb-3">
    <div class="card h-100 shadow">
      <app-help name="property.detail.info"></app-help>

      <div class="card-header py-3">
        <div class="row">
          <div class="col-6 col-sm-6 col-md-7">
            <p class="modal-title font-16 text-dark pt-2 pb-2">
              <span>{{ 'global.basic_info' | translate }}</span>
            </p>
          </div>

          <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
            <button
              (click)="openPropertyEditModal(property)"
              [updateButton]="'properties.action_edit' | translate"
            ></button>
          </div>
        </div>
      </div>

      <div class="card-body px-4">
        <div
          *ngIf="property.is_deleted"
          class="row border-bottom text-nowrap"
        >
          <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
            <span class="text-danger">{{ 'properties.is_deleted' | translate }}</span>
          </div>
        </div>

        <div class="row border-bottom">
          <div class="col-12 col-md-3 py-1 py-md-2">
            <span class="text-dark">{{ 'properties.name' | translate }}</span>
          </div>

          <div class="col-12 col-md-9 py-1 py-md-2">
            <span [innerHtml]="property.name"></span>
          </div>
        </div>

          <div class="row border-bottom">
              <div class="col-12 col-md-3 py-1 py-md-2">
                  <span class="text-dark">{{ 'properties.state' | translate }}</span>
              </div>

              <div class="col-12 col-md-9 py-1 py-md-2">
                  <span>{{ (property?.state ? 'properties.states.'+property?.state : 'properties.states.UNKNOWN') | translate}}</span>
              </div>
          </div>

        <div class="row border-bottom">
          <div class="col-12 col-md-3 py-1 py-md-2">
            <span class="text-dark">{{ 'properties.code' | translate }}</span>
          </div>

          <div class="col-12 col-md-9 py-1 py-md-2">
            <span>{{ property?.code }}</span>
          </div>
        </div>

        <div class="row border-bottom">
          <div class="col-12 col-md-3 py-1 py-md-2">
            <span class="text-dark">{{ 'properties.serial_number' | translate }}</span>
          </div>

          <div class="col-12 col-md-9 py-1 py-md-2">
            <span>{{ property?.serial_number }}</span>
          </div>
        </div>

        <div class="row border-bottom">
          <div class="col-12 col-md-3 py-1 py-md-2">
            <span class="text-dark">{{ 'properties.lost_fee' | translate }}</span>
          </div>

          <div class="col-12 col-md-9 py-1 py-md-2">
            <span>{{ property?.lost_fee | localizedCurrency }}</span>
          </div>
        </div>

        <div class="row border-bottom">
          <div class="col-12 col-md-3 py-1 py-md-2">
            <span class="text-dark">{{ 'properties.property_category_ID' | translate }}</span>
          </div>

          <div class="col-12 col-md-9 py-1 py-md-2">
            <span *ngIf="!property?.category">---</span>
            <span *ngIf="property?.category">{{ property?.category?.name }}</span>
          </div>
        </div>

        <div class="row border-bottom">
          <div class="col-12 col-md-3 py-1 py-md-2">
            <span class="text-dark">{{ 'properties.date_of_registration' | translate }}</span>
          </div>

          <div class="col-12 col-md-9 py-1 py-md-2">
            <span>{{ property?.date_of_registration | date: 'dd.MM.yyyy' }}</span>
          </div>
        </div>

        <div class="row border-bottom">
          <div class="col-12 col-md-3 py-1 py-md-2">
            <span class="text-dark">{{ 'properties.date_of_decommissioning' | translate }}</span>
          </div>

          <div class="col-12 col-md-9 py-1 py-md-2">
            <span>{{ property?.date_of_decommissioning | date: 'dd.MM.yyyy' }}</span>
          </div>
        </div>

        <div class="row border-bottom">
          <div class="col-12 col-md-3 py-1 py-md-2">
            <span class="text-dark">{{ 'properties.end_of_warranty' | translate }}</span>
          </div>

          <div class="col-12 col-md-9 py-1 py-md-2">
            <span>{{ property?.end_of_warranty | date: 'dd.MM.yyyy' }}</span>
          </div>
        </div>

        <div class="row border-bottom">
          <div class="col-12 col-md-3 py-1 py-md-2">
            <span class="text-dark">{{ 'properties.description' | translate }}</span>
          </div>

          <div class="col-12 col-md-9 py-1 py-md-2">
            <span>{{ property?.description }}</span>
          </div>
        </div>

          <ng-container *ngIf="property.custom_fields.length > 0">
              <div *ngFor="let field of property.custom_fields" class="row border-bottom">
                  <div class="col-12 col-sm-12 col-md-3 py-1 py-md-2">
                      <span class="text-dark"> {{ field.label }} </span>
                  </div>
                  <div class="col-12 col-sm-9 col-md-6 py-1 py-md-2 sensitive text-cropped">
                      <ng-container [ngSwitch]="field.type">
                          <span *ngSwitchCase="'RICHTEXT'" [innerHTML]="field.value"></span>
                          <span *ngSwitchCase="'PERCENT'"> {{ field.value }} % </span>
                          <span *ngSwitchCase="'AMOUNT'"> {{ field.value | localizedCurrency }} </span>
                          <span *ngSwitchCase="'DATE'"> {{ field.value | date: 'dd.MM.yyyy' }} </span>
                          <span *ngSwitchCase="'DATETIME'"> {{ field.value | date: 'dd.MM.yyyy HH:mm' }} </span>
                          <span *ngSwitchCase="'TOGGLE'">  {{ (field.value ? 'global.YES' : 'global.NO') | translate }} </span>
                          <span *ngSwitchDefault> {{ field.value }} </span>
                      </ng-container>
                  </div>
              </div>
          </ng-container>

      </div>
    </div>
  </div>

  <div class="col-lg-6 mb-3">
    <div class="row mb-3">
      <div class="col-12">
          <app-note
              [entity]="{ id: property.property_ID, type: 'Property' }"
          ></app-note>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <div class="card h-100 shadow">
          <app-help name="property.detail.thumbnail"></app-help>

          <div class="card-header py-3">
            <div class="row">
              <div class="col-6 col-sm-6 col-md-7">
                <p class="modal-title font-16 text-dark pt-2 pb-2">
                  <span>{{ 'properties.thumbnail' | translate }}</span>
                </p>
              </div>

              <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
                <button
                  (click)="openUploadModal(contentUploadThumbnail)"
                  [uploadButton]="'files.action_upload' | translate"
                ></button>
              </div>
            </div>
          </div>

          <div class="card-body px-4">
            <div
              *ngIf="thumbnail"
              class="row border-bottom text-nowrap"
            >
              <div class="col-6 py-3">
                <div class="row">
                  <div class="col-6 text-truncate">
                    <img
                      (click)="openThumbnailModal(contentThumbnail)"
                      *ngIf="thumbnail"
                      [attr.src]="thumbnail.url"
                      alt="image"
                      class="img-fluid avatar-xs pointer"
                    >
                  </div>

                  <div class="col-6">
                    <span>{{ thumbnail.created_at | date: 'dd.MM.yyyy' }}</span>
                  </div>
                </div>
              </div>

              <div class="col-6 py-2 text-right">
                <button
                  (click)="deleteFile(thumbnail.file_ID, null)"
                  class="btn btn-link btn-icon waves-effect pt-1 pb-0 pl-0 pr-0"
                >
                  <i class="mdi mdi-trash-can-outline mdi-24px text-muted"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-3">
        <div class="card h-100 shadow">
          <app-help name="property.detail.files"></app-help>

          <div class="card-header py-3">
            <div class="row">
              <div class="col-6 col-sm-6 col-md-7">
                <p class="modal-title font-16 text-dark pt-2 pb-2">
                  <span>{{ 'properties.files' | translate }}</span>
                </p>
              </div>

              <div class="col-6 col-sm-6 col-md-5 text-right align-self-center">
                <button
                  (click)="openUploadModal(contentUpload)"
                  [uploadButton]="'files.action_upload' | translate"
                ></button>
              </div>
            </div>
          </div>

          <div class="card-body px-4">
            <div
              *ngFor="let file of propertyFiles$ | async"
              class="row border-bottom text-nowrap"
            >
              <div class="col-6 py-3">
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-12 mb-1 text-truncate">
                    <app-file-type [ext]="file.extension" [oneDriveID]="file.one_drive_ID" class="force-left"></app-file-type>
                    <span class="text-dark">{{ file.name }}</span>
                  </div>

                  <div class="col-12 col-md-6 col-lg-12">
                    <span>{{ file.created_at | date: 'dd.MM.yyyy' }}</span>
                  </div>
                </div>
              </div>

              <div class="col-6 py-2 text-right">
                <button
                  (click)="documentService.openDocumentDetailModal(file?.hash)"
                  class="btn btn-link btn-icon waves-effect p-0 mr-2"
                >
                  <i class="mdi mdi-tray-arrow-down mdi-24px text-muted"></i>
                </button>

                <button
                  (click)="deleteFile(file.file_ID, file.document_ID)"
                  class="btn btn-link btn-icon waves-effect pt-1 pb-0 pl-0 pr-0"
                >
                  <i class="mdi mdi-trash-can-outline mdi-24px text-muted"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="property$ | async; let property" class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help name="property.detail.employees"></app-help>

      <div class="card-header py-3">
        <div class="row">
          <div class="col-sm-6 text-left my-auto">
            <p class="modal-title font-16 text-dark pt-2 pb-2">
              <span>{{ 'properties.employees_history' | translate }}</span>
            </p>
          </div>
          <div class="col-sm-6 text-right align-self-center">
            <button
              (click)="openAssignPropertyModal(property)"
              [createButton]="'employees_properties.action_create' | translate"
              class="ml-sm-2"
            ></button>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="table-responsive">
          <app-data-table
            (searchChanged)="searchChanged($event)"
            (sortChanged)="sortChanged($event)"
            [columnsToFilterIn]="['handover_date_formatted', 'name', 'removal_date_formatted']"
            [disableInPlaceFiltering]="false"
            [disableInPlaceSorting]="false"
            [loading]="loading"
            [rows]="rows"
            [search]="search"
            [showSearch]="true"
            [sort]="sort"
            [trackByFn]="trackByFn"
          >
            <ng-template #headerRow>
              <th appDataTableHeaderCell="name">
                <span>{{ 'employees_properties.name' | translate }}</span>
              </th>

              <th
                appDataTableHeaderCell="handover_date"
                name="handover_date_formatted"
              >
                <span>{{ 'employees_properties.date_of_registration' | translate }}</span>
              </th>

              <th
                appDataTableHeaderCell="removal_date"
                name="removal_date_formatted"
              >
                <span>{{ 'employees_properties.date_of_decommissioning' | translate }}</span>
              </th>

              <th
                [disableSort]="true"
                appDataTableHeaderCell
              >{{ 'documents.document' | translate }}</th>

              <th
                [disableSort]="true"
                appDataTableHeaderCell
                class="text-right"
              >{{ 'employees_properties.table_action' | translate }}</th>
            </ng-template>

            <ng-template
              #dataRow
              let-row
            >
              <td appDataTableDataCell> <app-employee-box-avatar [avatar]="row.avatar" [canView]="row.permissions.view" [employeeID]="row.employee_ID" [fullname]="row.fullname" [position]="row.position"></app-employee-box-avatar> </td>

              <td appDataTableDataCell>{{ row.handover_date_formatted }}</td>

              <td appDataTableDataCell>
                <span *ngIf="row.property_is_removed">{{ row.removal_date_formatted }}</span>

                <button
                  (click)="removePropertyFromEmployee(row, content)"
                  *ngIf="!row.property_is_removed"
                  class="btn btn-link p-0"
                  type="button">
                  {{ 'employees_properties.action_return' | translate }}
                </button>
              </td>

              <td appDataTableDataCell>
                  <ng-container *ngIf="row.files">
                      <button
                          *ngIf="row.files.length === 1"
                          [ngbTooltip]="row.files[0].name"
                          [routerLink]="['/', 'file-detail', 'employee-property', row.employee_property_ID ,'file', row.files[0].hash, 'detail']"
                          class="btn btn-link btn-icon btn-sm"
                          title="{{ 'employees.action_view' | translate }}">
                          <i class="mdi mdi-file mdi-24px"></i>
                      </button>
                      <button
                          *ngIf="row.files.length > 1"
                          (click)="openFilesModal(row.files)"
                          class="btn btn-white btn-icon btn-sm"
                      >
                          <i class="mdi mdi-folder-outline mdi-24px"></i>
                      </button>
                  </ng-container>

                  <button (click)="openCreateDocumentByTemplateModal(row)" *ngIf="(availableFeatures$ | async)?.document_generations"
                    class="btn btn-link p-0">
                    <span>{{ 'employees_properties.action_generate' | translate }}</span>
                  </button>

              </td>

              <td appDataTableDataCell class="text-right">
                <div>
                  <button (click)="openEmployeePropertyDetailModal(content, row)"
                    class="btn btn-white btn-icon btn-nplr btn-sm mr-1"
                    title="{{ 'employees.action_view' | translate }}">
                    <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                  </button>
                </div>
              </td>
            </ng-template>
          </app-data-table>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <app-pagination
          (pageChanged)="pageChanged($event)"
          [itemsPerPage]="itemsPerPage"
          [page]="page"
          [total]="itemsTotal"
        ></app-pagination>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="property$ | async; let property"
  class="row"
>
  <div class="col-12">
    <div class="card shadow">
      <app-help name="property.detail.notifications"></app-help>

      <div class="card-header py-3">
        <div class="row">
          <div class="col-6 col-sm-6 col-md-7">
            <p class="modal-title font-16 text-dark pt-2 pb-2">
              <span>{{ 'properties.scheduled_tasks' | translate }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="card-body">
        <app-scheduled-task-list
          *ngIf="property"
          [dataOnly]="true"
          [entity]="{ id: property.property_ID, type: 'Property' }"
        ></app-scheduled-task-list>
      </div>
    </div>
  </div>
</div>
