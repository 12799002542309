<div *ngIf="datetimeDocumentStatus$ | async as status" class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header py-3">
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-7">
                        <p class="modal-title font-16 text-dark pt-2 pb-2">{{year}} / {{month}}</p>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <form #f="ngForm" (ngSubmit)="onSubmit(f.value)">

                    <table class="table table-centered table-striped mb-0">
                        <thead class="thead-light">
                            <tr>
                                <th class="text-nowrap">{{ 'employees.full_name' | translate }}</th>
                                <th class="text-nowrap">{{ 'employees_salaries.gross_salary' | translate }}</th>
                                <th class="text-nowrap">{{ 'employees_salaries.net_salary' | translate }}</th>
                                <th class="text-nowrap">{{ 'employees_salaries.priority_claims' | translate }}</th>
                                <th class="text-nowrap">{{ 'employees_salaries.not_priority_claims' | translate }}</th>
                                <th class="text-nowrap">{{ 'employees_salaries.real_net_salary' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                                <tr *ngFor="let e of employees$ | async">
                                    <ng-container [ngModelGroup]="e.employee_ID">
                                    <td [attr.data-order]="e.surname_for_order + ' ' +  e.name_for_order" class="table-user">
                                        <div class="media text-nowrap">
                                            <img *ngIf="e.avatar" [src]="e.avatar" class="mr-2 rounded-circle">
                                            <div class="media-body my-auto">
                                                {{ e.fullname }}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-nowrap">
                                        {{e.gross_salary | localizedCurrency }}
                                    </td>
                                    <td class="text-nowrap">
                                        {{e.net_salary | localizedCurrency }}
                                    </td>
                                    <td class="text-nowrap">
                                        {{e.priority_receivables | localizedCurrency }}
                                    </td>
                                    <td class="text-nowrap">
                                        {{e.non_priority_receivables | localizedCurrency }}
                                    </td>
                                    <td class="text-nowrap">
                                        <input [disabled]="status.step5" [ngModel]="e.real_net_salary" class="form-control" currencyMask name="real_net_salary" type="text">
                                    </td>
                                </ng-container>
                                </tr>
                        </tbody>
                    </table>
                    <div class="row mt-4">
                        <div *ngIf="!status.step5" class="col-6 text-left">
                            <button class="btn btn-success mr-2"
                                type="submit">{{'numbers_series.action_save' | translate}}</button>
                        </div>
                    </div>
                </form>

                </div>
            </div>
        </div>
    </div>
</div>
