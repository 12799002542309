<div class="modal-content">
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-0 shadow-none">
					<div class="card-body p-0">
						<div class="row">
							<div class="col-12">
								<div class="progress mb-2">
									<div [style.width.%]="expiryPercent" aria-valuemax="100" aria-valuemin="0" aria-valuenow="progressBarWidth" class="progress-bar" role="progressbar"></div>

								</div>
								<p class="mb-0"> {{ 'global.auto_logout_in_1_minute' | translate }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
