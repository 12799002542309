<ng-container *ngIf="canCreate$ | async; else loadingTemplate"></ng-container>
    <div *ngIf="employees$ | async as employee; else loadingTemplate" class="card shadow">
      <div class="card-body">
        <ng-container>
          <app-upload
                  (addedFiles)="addedFiles($event)"
                  (successMultiple)="successMultiple()"
                  [additionalParams]="payrollFileAdditionalData"
                  [allowTakingPicture]="false"
                  [clear$]="clearFileQueue$"
                  [modelName]="'file_type'"
                  [modelValue]="'payroll'"
                  [process$]="processFileQueue$"
                  [showPreviewContainer]="false"
                  [uploadMultiple]="true"
                  class="mb-4"
          ></app-upload>
        </ng-container>

        <form #fPayrollAssign="ngForm" (ngSubmit)="onSubmitPayrolls(fPayrollAssign)" autocomplete="off">
          <div class="row mb-3">
            <div class="col-12">
              <div [ngModelGroup]="'payrolls'"
                   class="table-responsive overflow-visible">
                <app-data-table
                    [colsConfig]="{width: ['25%', '15%', '30%', '10%', '10%', '10%']}"
                    [customNoDataMessage]="'global.empty_widget_error' | translate"
                    [disableInPlaceSorting]="true"
                    [rows]="employeePayrolls"
                    [showSearch]="false"
                >
                  <ng-template
                          #headerRow
                  >
                    <th [disableSort]="true" appDataTableHeaderCell="fileName">
                      {{ 'imports.payrolls.file_name' | translate }}
                    </th>

                    <th [disableSort]="true" appDataTableHeaderCell="fileSize">
                      {{ 'imports.payrolls.file_size' | translate }}
                    </th>

                    <th [disableSort]="true" appDataTableHeaderCell="employee">
                      {{ 'imports.payrolls.employee' | translate }}
                    </th>

                    <th [disableSort]="true" appDataTableHeaderCell="loading">
                    </th>

                    <th [disableSort]="true" appDataTableHeaderCell="actions" class="text-right pr-4">
                      {{ 'imports.payrolls.process' | translate }}
                    </th>

                    <th [disableSort]="true" appDataTableHeaderCell="actions" class="text-right pr-4">
                      {{ 'imports.payrolls.actions' | translate }}
                    </th>

                  </ng-template>

                  <ng-template
                      #dataRow
                      let-row
                  >
                    <ng-container
                      [ngModelGroup]="row.upload?.uuid"
                    >
                      <td
                        [ngClass]="{'disabled-row': !fPayrollAssign.value['payrolls'][row.upload?.uuid]?.checked}"
                        appDataTableDataCell
                      >
                        {{ row.name }}
                      </td>

                      <td
                        [ngClass]="{'disabled-row': !fPayrollAssign.value['payrolls'][row.upload?.uuid]?.checked}"
                        appDataTableDataCell
                      >
                        {{ (row.size / 1000 / 1000) | number:'1.1-2' }} MB
                      </td>

                      <td
                        [ngClass]="{'disabled-row': !fPayrollAssign.value['payrolls'][row.upload?.uuid]?.checked}"
                        appDataTableDataCell
                        class="ng-select-td overflow-visible"
                      >
                        <ng-select
                          (change)="checkDuplicates(fPayrollAssign)"
                          [clearable]="false"
                          [disabled]="!fPayrollAssign.value['payrolls'][row.upload?.uuid]?.checked"
                          [items]="employees"
                          [ngModel]="defaultEmployees[row.upload?.uuid]"
                          bindLabel="fullname"
                          bindValue="employee_ID"
                          class="custom payroll-employees-select"
                          name="employee_ID"
                          required
                        >
                        </ng-select>

                        <div *ngIf="employeeDuplicates.includes(fPayrollAssign.value['payrolls'][row.upload?.uuid]?.employee_ID)" class="text-danger"> {{ 'imports.payrolls.employee_already_selected' | translate }}</div>

                      </td>

                      <td appDataTableDataCell class="text-center">
                        <div *ngIf="uploadingFiles && fPayrollAssign.value['payrolls'][row.upload?.uuid]?.checked" class="spinner-border spinner-border-sm" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </td>

                      <td appDataTableDataCell
                          class="text-center">
                        <input (ngModelChange)="getFilesStats(fPayrollAssign)"
                               [ngModel]="true"
                               data-switch="success"
                               id="checked-{{row.upload?.uuid}}"
                               name="checked"
                               type="checkbox"
                        />

                        <label for="checked-{{row.upload?.uuid}}">&nbsp;</label>
                      </td>

                      <td appDataTableDataCell
                          class="text-right"
                      >
                        <div>
                          <a
                            (click)="openFilePreviewModal(row)"
                            [ngClass]="{'disabled-row': !fPayrollAssign.value['payrolls'][row.upload?.uuid]?.checked}"
                            class="btn btn-white btn-icon btn-sm mr-1"
                            title="{{ 'todo.action_view' | translate }}"
                          >
                            <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                          </a>
                        </div>
                      </td>
                    </ng-container>
                  </ng-template>
                </app-data-table>
              </div>
            </div>
          </div>
          <div *ngIf="employeePayrolls?.length > 0"
               [ngModelGroup]="'date'"
               class="row mb-3 d-flex justify-content-end">
            <div class="col-6 col-md-3">
              <label class="control-label">
                <span>{{ 'employees.payroll_month' | translate }}</span>
                <span class="text-danger"> *</span>
              </label>
              <ng-select
                      [clearable]="false"
                      [ngModel]="defaultDate.month"
                      class="custom"
                      name="month"
                      required
              >
                <ng-container *ngFor="let i of selectRange.months">
                  <ng-option value="{{ i }}"> {{ 'global.month_' + i | translate }}</ng-option>
                </ng-container>
              </ng-select>
            </div>
            <div class="col-6 col-md-3">
              <label class="control-label">
                <span>{{ 'employees.payroll_year' | translate }}</span>
                <span class="text-danger"> *</span>
              </label>
              <ng-select
                      [clearable]="false"
                      [ngModel]="defaultDate.year"
                      class="custom"
                      name="year"
                      required
              >
                <ng-container *ngFor="let i of selectRange.years">
                  <ng-option value="{{ i }}"> {{ i }}</ng-option>
                </ng-container>
              </ng-select>
            </div>
          </div>
          <div *ngIf="unselectedEmployees?.length > 0 && employeePayrolls?.length > 0" class="row">
            <div (click)="collapse.toggle()"
                 [attr.aria-expanded]="!isCollapsed"
                 class="col-12"
            >
              <div class="cursor-pointer d-flex align-items-center mb-2">
                <i *ngIf="isCollapsed"
                   class="mdi mdi-menu-right mr-1 mdi-24px d-inline-block menu-arrow-collapse"
                ></i>
                <i *ngIf="!isCollapsed"
                   class="mdi mdi-menu-down mr-1 mdi-24px d-inline-block menu-arrow-collapse"
                ></i>
                <span class="text-danger mr-1">
                  {{ 'imports.payrolls.employee_not_selected' | translate }}
                </span>
                <span class="text-dark">
                  ({{ unselectedEmployees.length }})
                </span>
              </div>
            </div>

            <div #collapse="ngbCollapse"
                 [(ngbCollapse)]="isCollapsed"
                 class="col-12"
            >
              <p *ngFor="let employee of unselectedEmployees" class="font-weight-bold m-0 pl-3">
                {{ employee.name }} {{ employee.surname }}
              </p>
            </div>
          </div>
          <div class="row d-flex justify-content-end align-items-center mt-2">
            <div *ngIf="employeePayrolls?.length > 0"
                 class="col-6">
              <span>
                {{ 'imports.payrolls.file_count' | translate }}:
              </span>
              <span [ngClass]="{'text-danger': filesCount > 50}"
                    class="font-weight-bold mr-3">
                {{ filesCount }} / 50
              </span>
              <span>
                {{ 'imports.payrolls.files_size' | translate }}:
              </span>
              <span [ngClass]="{'text-danger': filesSize > 32}"
                    class="font-weight-bold mr-3">
                {{ filesSize | number:'1.1-2' }} / 32 MB
              </span>
            </div>
            <div class="col-6 text-right">
              <button [disabled]="fPayrollAssign.invalid || filesCount > 50 || filesCount === 0 || filesSize > 32"
                      class="btn btn-primary"> {{ 'imports.payrolls.action_import' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

<ng-template #loadingTemplate>
    <div class="row">
        <div class="col-12">
            <div class="card shadow p-3">
                <ngx-skeleton-loader [theme]="{ width: '100%', 'margin': '25px 0', 'display': 'block' }"
                                     appearance="line"
                                     count="8">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
</ng-template>
