<div class="modal-content">
    <form
        autocomplete="off"
        #fMultiGenerate="ngForm"
        (ngSubmit)="onSubmit(fMultiGenerate)"
    >
        <div class="row">
            <div class="col-md-12">
                <div class="modal-header">
                    <p class="modal-title font-16 text-dark mb-0">
            <span>{{
                "settings.company.documents_templates.action_create_generation"
                    | translate
                }}</span>
                    </p>
                    <button
                        aria-label="Close"
                        class="close"
                        type="button"
                        (click)="activeModal.dismiss()"
                    >
            <span aria-hidden="true" class="font-24 text-muted">
              <i class="mdi mdi-close"></i>
            </span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>{{
                                    "settings.company.documents_templates.employees"
                                        | translate
                                    }}</label>

                                <ng-select
                                    bindLabel="fullname"
                                    bindValue="employee_ID"
                                    class="custom"
                                    ngModel="employeesIDs"
                                    id="employeesIDs"
                                    name="employeesIDs"
                                    groupBy="company_group_name"
                                    [clearable]="false"
                                    [closeOnSelect]="false"
                                    [items]="employees$ | async"
                                    [multiple]="true"
                                    [selectableGroup]="true"
                                    [selectableGroupAsModel]="false"
                                >

                                    <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'settings.company.documents_templates.employees_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ employeesIDsCount(fMultiGenerate) }}</span>
                      <span>)</span>
                    </span>
                                    </ng-template>

                                    <ng-template
                                        ng-option-tmp
                                        let-item="item"
                                        let-item$="item$"
                                        let-index="index"
                                    >
                                        <input
                                            id="item-{{ index }}"
                                            type="checkbox"
                                            [checked]="item$.selected"
                                        />

                                        <span>&nbsp;</span>
                                        <span>{{ item.fullname }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col d-flex justify-content-end">
                            <button
                                type="button"
                                class="btn btn-success"
                                [disabled]="employeesIDsCount(fMultiGenerate) === 0"
                                (click)="checkEmployees(fMultiGenerate)"
                            >
                <span>{{
                    "settings.company.documents_templates.action_check"
                        | translate
                    }}</span>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="employeesErrors" class="row mt-1">
                        <div class="col-12">
                            <div class="form-group m-0">
                                <hr class="m-0"/>
                                <div
                                    class="row text-danger m-0 py-2 cursor-pointer font-weight-bold"
                                    [attr.aria-expanded]="!isCollapsed['errorsSection']"
                                    (click)="isCollapsed['errorsSection'] = !isCollapsed['errorsSection']"
                                >
                                    <i
                                        *ngIf="!isCollapsed['errorsSection']"
                                        class="mdi mdi-menu-right mr-1 mdi-24px d-inline-block menu-arrow-collapse"
                                    ></i>
                                    <i
                                        *ngIf="isCollapsed['errorsSection']"
                                        class="mdi mdi-menu-down mr-1 mdi-24px d-inline-block menu-arrow-collapse"
                                    ></i>
                                    {{
                                    "settings.company.documents_templates.errors" | translate
                                    }}
                                    {{ "(" + errorsCount + ")" }}
                                </div>

                                <div
                                    #collapse="ngbCollapse"
                                    [(ngbCollapse)]="!isCollapsed['errorsSection']"
                                >
                                    <div *ngFor="let employee of employeesErrors | keyvalue" class="pl-3">
                                        <hr class="m-0"/>
                                        <div
                                            class="row m-0 cursor-pointer py-2"
                                            aria-controls="collapse"
                                            [attr.aria-expanded]="!isCollapsed['errors'][employee.key]"
                                            (click)="isCollapsed['errors'][employee.key] = !isCollapsed['errors'][employee.key] "
                                        >
                                            <i
                                                *ngIf="!isCollapsed['errors'][employee.key]"
                                                class="mdi mdi-menu-right mr-1 mdi-24px d-inline-block menu-arrow-collapse"
                                            ></i>
                                            <i
                                                *ngIf="isCollapsed['errors'][employee.key]"
                                                class="mdi mdi-menu-down mr-1 mdi-24px d-inline-block menu-arrow-collapse"
                                            ></i>
                                            <span class="mr-1 font-weight-bold"> {{ employee?.value?.employee?.fullname ? employee?.value?.employee?.fullname : employee?.key }} </span>
                                            <span class="mr-1" *ngIf="employee?.value?.employee?.position"> {{ " (" + employee?.value?.employee?.position + ") "}} </span>
                                            <span class="font-weight-bold"> {{ "(" + employee?.value?.fields?.length + ")" }} </span>
                                        </div>
                                        <div #collapse="ngbCollapse"
                                             [(ngbCollapse)]="!isCollapsed['errors'][employee.key]">
                                            <hr class="m-0"/>
                                            <div class="py-2">
                                                <div
                                                    *ngFor="let column of employee.value.fields"
                                                >
                                                    <p class="row m-0 py-1 pl-3">
                                                        {{
                                                        "document_templates.field_" + column + "_error_note"
                                                            | translate
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="employeesWarnings" class="row">
                        <div class="col-12">
                            <div class="form-group m-0">
                                <hr class="m-0"/>
                                <div
                                    [attr.aria-expanded]="!isCollapsed['warningsSection']"
                                    (click)="isCollapsed['warningsSection'] = !isCollapsed['warningsSection']"
                                    class="row text-warning m-0 py-2 cursor-pointer font-weight-bold"
                                >
                                    <i
                                        *ngIf="!isCollapsed['warningsSection']"
                                        class="mdi mdi-menu-right mr-1 mdi-24px d-inline-block menu-arrow-collapse"
                                    ></i>
                                    <i
                                        *ngIf="isCollapsed['warningsSection']"
                                        class="mdi mdi-menu-down mr-1 mdi-24px d-inline-block menu-arrow-collapse"
                                    ></i>
                                    {{
                                    "settings.company.documents_templates.warnings" | translate
                                    }}
                                    {{ "(" + warningsCount + ")" }}
                                </div>
                                <div
                                    #collapse="ngbCollapse"
                                    [(ngbCollapse)]="!isCollapsed['warningsSection']"
                                >
                                    <div *ngFor="let employee of employeesWarnings | keyvalue" class="pl-3">
                                        <hr class="m-0"/>
                                        <div
                                            class="row m-0 py-2 cursor-pointer"
                                            aria-controls="collapse"
                                            [attr.aria-expanded]="!isCollapsed['warnings'][employee.key]"
                                            (click)="
                        isCollapsed['warnings'][employee.key] =
                          !isCollapsed['warnings'][employee.key]
                      "
                                        >
                                            <i
                                                *ngIf="!isCollapsed['warnings'][employee.key]"
                                                class="mdi mdi-menu-right mr-1 mdi-24px d-inline-block menu-arrow-collapse"
                                            ></i>
                                            <i
                                                *ngIf="isCollapsed['warnings'][employee.key]"
                                                class="mdi mdi-menu-down mr-1 mdi-24px d-inline-block menu-arrow-collapse"
                                            ></i>
                                            <span class="font-weight-bold mr-1"> {{ employee?.value?.fullname }} </span>
                                            <span *ngIf="employee?.value?.position"> {{ " (" + employee?.value?.position + ") "}} </span>
                                        </div>
                                        <div
                                            #collapse="ngbCollapse"
                                            [(ngbCollapse)]="!isCollapsed['warnings'][employee.key]"
                                        >
                                            <hr class="m-0"/>
                                            <p class="row m-0 py-2 pl-3">
                                                {{ "document_templates.warning_note" | translate }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="employeesSuccess" class="row">
                        <div class="col-12">
                            <div class="form-group m-0">
                                <hr class="m-0"/>
                                <div
                                    [attr.aria-expanded]="!isCollapsed['successSection']"
                                    (click)="isCollapsed['successSection'] = !isCollapsed['successSection']"
                                    class="row text-green m-0 py-2 cursor-pointer"
                                >
                                    <i
                                        *ngIf="!isCollapsed['successSection']"
                                        class="mdi mdi-menu-right mr-1 mdi-24px d-inline-block menu-arrow-collapse"
                                    ></i>
                                    <i
                                        *ngIf="isCollapsed['successSection']"
                                        class="mdi mdi-menu-down mr-1 mdi-24px d-inline-block menu-arrow-collapse"
                                    ></i>
                                    {{
                                    "settings.company.documents_templates.success" | translate
                                    }}
                                    {{ "(" + successCount + ")" }}
                                </div>
                                <div
                                    #collapse="ngbCollapse"
                                    [(ngbCollapse)]="!isCollapsed['successSection']"
                                >
                                    <div *ngFor="let employee of employeesSuccess | keyvalue"
                                         class="pl-3">
                                        <hr class="my-0"/>
                                        <div class="row py-2 m-0">
                                            <span class="font-weight-bold mr-1"> {{ employee?.value?.fullname }} </span>
                                            <span *ngIf="employee?.value?.position"> {{ " (" + employee?.value?.position + ") "}} </span>
                                        </div>
                                    </div>
                                </div>
                                <hr class="m-0"/>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="selectedTemplate?.signable && allowedDocumentSignature" class="row">
                        <div class="col">
                            <div class="d-flex mb-2">
                                <div class="alert alert-warning mb-2 w-100 shadow">
                                    <span> {{ 'document_templates.signable' | translate }} </span>
                                    <span *ngIf="selectedTemplate.require_owner_signature">{{ 'document_templates.by_owner' | translate }}</span>
                                    <span *ngIf="selectedTemplate.require_owner_signature && selectedTemplate.require_responsible_signature"> {{ 'global.and' | translate }} </span>
                                    <span *ngIf="selectedTemplate.require_responsible_signature">{{ 'document_templates.by_responsible' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="!submitDisabled">
                        <ng-container *ngFor="let group of fields">
                            <ng-container *ngIf="group?.group !== 'SIDE2'">
                                <ng-container *ngFor="let field of group?.data">
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <ng-container *ngIf="field.type === 'custom'; else autoField">
                                                    <label class="control-label" [class.required]="field.required" [hidden]="group?.group === 'SIDE1'">
                                                        {{field.name}}
                                                    </label>
                                                    <input ngModel="{{ field.value }}" type="text" class="form-control" name="{{ field.name }}" value="{{ field.value }}" [hidden]="group?.group === 'SIDE1'" [required]="field.required" [readOnly]="field.disabled"/>
                                                </ng-container>
                                                <ng-template #autoField>
                                                    <label class="control-label" [class.required]="field.required" [hidden]="group?.group === 'SIDE1'">
                                                        {{"document_templates.field_" + field.name + "_title" | translate}}
                                                    </label>
                                                    <input ngModel="{{ field.value }}" type="text" class="form-control" name="{{ field.name }}" value="{{ field.value }}" [hidden]="group?.group === 'SIDE1'" [required]="field.required" [readOnly]="field.disabled"/>
                                                    <small *ngIf="!field.value && field.required && field.disabled" class="text-danger mr-1" [hidden]="group?.group === 'SIDE1'">
                                                        {{"document_templates.field_" + field.name + "_error_note" | translate}}
                                                    </small>
                                                    <small *ngIf="(('document_templates.field_' + field.name + '_note') | translate) !== ''" class="text-muted" [hidden]="group?.group === 'SIDE1'">
                                                        {{ "document_templates.field_" + field.name + "_note" | translate}}
                                                    </small>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <div *ngIf="message == 'message'" class="row">
                        <div class="col-12">
                            <p class="my-1 text-muted">
                                {{
                                "settings.company.documents_templates.message"
                                    | translate
                                }}
                            </p>
                        </div>
                    </div>
                    <div *ngIf="message == 'errors'" class="row">
                        <div class="col-12">
                            <p class="my-1 text-muted">
                                {{
                                "settings.company.documents_templates.errors_message"
                                    | translate
                                }}
                            </p>
                        </div>
                    </div>

                    <div class="row mb-0 mt-3">
                        <div class="col d-flex justify-content-end">
                            <button
                                type="submit"
                                class="btn btn-success"
                                [disabled]="fMultiGenerate.form.invalid || submitDisabled"
                            >
                <span>{{
                    "settings.company.documents_templates.action_generate"
                        | translate
                    }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
