import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeAddress } from 'src/app/employee/models/EmployeeAddress';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { EntityUpdateRequestModalComponent } from '../../../../entity_update_request/components/partials/modal/entity_update_request-modal.component';
import { EntityUpdateRequest } from '../../../../entity_update_request/state/entity_update_request.model';
import { WidgetTemplatesService } from '../../../../shared/services/widget-templates.service';
import { EmployeeRequiredDataUpdates } from '../../../models/EmployeeRequiredDataUpdates';
import { CreateEditEmployeeAddressModalComponent } from '../../sections/create-edit-employee-address-modal/create-edit-employee-address-modal.component';

@Component({
    selector: 'app-employee-addresses',
    templateUrl: './employee-addresses.component.html',
    styleUrls: ['./employee-addresses.component.css']
})
export class EmployeeAddressesComponent {
    @Input() employee: Employee;
    @Input() employeeAddresses$: Observable<Array<EmployeeAddress>>;
    @Input() requiredUpdates: EmployeeRequiredDataUpdates;
    @Input() entityRequests: Array<EntityUpdateRequest>;

    @Output() refetchAddresses = new EventEmitter<string>();
    @Output() refetchRequiredUpdates = new EventEmitter<string>();
    @Output() refetchActiveRequests = new EventEmitter<string>();

    public constructor(
        public widgetTemplatesService: WidgetTemplatesService,
        private _modalService: NgbModal,
        private _employeeService: EmployeeService
    ) { }

    public openEditModal(selectedAddress: EmployeeAddress = null, allAddresses: EmployeeAddress[] = null): void {
        const modalRef = this._modalService.open(CreateEditEmployeeAddressModalComponent, {centered: true});

        let updateRequested = false;

        if (selectedAddress) {
            modalRef.componentInstance.employeeAddress = selectedAddress;

            if (this.entityRequests) {
                for (const request of this.entityRequests) {
                    if (request.entity_type === 'App\\Models\\EmployeeAddress' && request.entity_ID === selectedAddress.employee_address_ID) {
                        updateRequested = true;
                        break;
                    }
                }
            }
        }

        if (allAddresses) {
            const primaryAddress = allAddresses.find(address => address.type === 'DOMICILE');
            modalRef.componentInstance.presetPrimaryAddress = !primaryAddress;
        }

        modalRef.componentInstance.employeeID = this.employee.employee_ID;
        modalRef.componentInstance.employee = this.employee;
        modalRef.componentInstance.updateRequested = updateRequested;

        modalRef.result
            .then(
                () => this.setUpdateData(),
                () => { }
            );
    }

    public openEntityUpdateRequestModal(entityUpdateRequest?: EntityUpdateRequest): void {
        const modalRef = this._modalService.open(EntityUpdateRequestModalComponent, {centered: true});
        if (entityUpdateRequest) {
            modalRef.componentInstance.entityUpdateRequest = entityUpdateRequest;
        }
    }

    public setUpdateData(): void {
        if (this.requiredUpdates && this.requiredUpdates.address === 'REQUESTED') {
            const data: { address: string } = {address: 'UPDATED'};
            this._employeeService.forceEmployeeToUpdateData(data, this.employee.employee_ID)
                .then(() => {
                    this.refetchAddresses.emit('refetch');
                    this.refetchRequiredUpdates.emit('refetch');
                    this.refetchActiveRequests.emit('refetch');
                });
        } else {
            this.refetchAddresses.emit('refetch');
            this.refetchActiveRequests.emit('refetch');
        }
    }

    public refuseUpdateData(): void {
        const data: { address: string } = {address: 'REFUSED'};
        this._employeeService.forceEmployeeToUpdateData(data, this.employee.employee_ID)
            .then(() => this.refetchRequiredUpdates.emit('refetch'));
    }
}
