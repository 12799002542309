import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { EmployeeOnboardingService } from 'src/app/onboarding/employee-onboarding/state/employee-onboarding.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { Employee } from '../../../../employee/models/Employee';
import { EmployeeService } from '../../../../employee/services/employee.service';
import { FlatpickrLocaleService } from '../../../../shared/services/flatpickr-locale.service';
import { Sort, SORT_ASC } from '../../../../ui';
import { EmployeeOnboarding } from '../../../employee-onboarding/state/employee-onboarding.model';
import { Onboarding } from '../../../onboarding/state/onboarding.model';
import { OnboardingService } from '../../../onboarding/state/onboarding.service';
import { EmployeeOnboardingOverviewModalComponent } from '../../partials/employee-onboarding-overview-modal/employee-onboarding-overview-modal.component';

@Component({
    selector: 'app-active-onboardings',
    templateUrl: './active-onboardings.component.html',
    styleUrls: ['./active-onboardings.component.css']
})
export class ActiveOnboardings implements OnInit, OnDestroy {
    public canCreate$: Observable<boolean>;
    public activeOnboardings: EmployeeOnboarding[];
    public data$: Observable<{ employees: Employee[], onboardings: Onboarding[] }>;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public sort: Sort<Onboarding> = {
        column: 'created_at',
        direction: SORT_ASC
    };

    private _subscription: Subscription;

    public constructor(
        private _employeeOnboardingService: EmployeeOnboardingService,
        private _modalService: NgbModal,
        private _authService: AuthenticationService,
        private _employeeService: EmployeeService,
        private _onboardingService: OnboardingService,
        private _flatpickrLocale: FlatpickrLocaleService,
    ) { }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.fetchOnboardings();

        this.data$ = combineLatest([
            this._employeeService.getAllEmployees(false, 'select'),
            this._onboardingService.getOnboardingsList({
                sort: this.sort
            })
                .pipe(map(response => response.data))
        ]).pipe(
            map(([employees, onboardings]) => ({employees, onboardings})
            ),
            shareReplay());

        this.canCreate$ = this._authService.hasPermissionTo('onboarding.create')
            .pipe(map(permission => permission.can));
    }

    public fetchOnboardings(): void {
        this._subscription = this._employeeOnboardingService.getActiveEmployeeOnboardings()
            .subscribe((data) => this.activeOnboardings = data);
    }

    public openEmployeeOnboardingOverviewModal(employeeOnboardingID: number, employeeID: number): void {
        const modalRef = this._modalService.open(EmployeeOnboardingOverviewModalComponent, {size: 'lg', centered: true});
        modalRef.componentInstance.employeeOnboardingID = employeeOnboardingID;
        modalRef.result
            .then(
                () => {},
                () => this.fetchOnboardings()
            );
        this._employeeOnboardingService.get(employeeID).subscribe();
    }

    public openAssignModal(content: any): void {
        this._modalService.open(content, {centered: true});
    }

    public onSubmit(f: UntypedFormGroup): void {
        if (!f.valid) {
            return;
        }

        const value = f.value;

        this._onboardingService.assignMultipleOnboardingsToMultipleEmployees(value.onboardingsIDs, value.employeesIDs, value.start_date)
            .then(() => this.fetchOnboardings());
    }
}
