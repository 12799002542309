import { Component } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeAccess } from 'src/app/employee/models/EmployeeAccess';
import { AccessService } from 'src/app/employee/services/access.service';
import { FlatpickrHelper } from 'src/app/shared/common/FlatpickrHelper';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';

@Component({
    selector: 'app-create-employee-access-modal',
    templateUrl: './create-employee-access-modal.component.html',
    styleUrls: ['./create-employee-access-modal.component.css']
})
export class CreateEmployeeAccessModalComponent {
    public employeeID: number;
    public form: UntypedFormGroup;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public submitted = false;

    public constructor(
        public activeModal: NgbActiveModal,
        public fpHelper: FlatpickrHelper,
        private _accessService: AccessService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
    ) {
        this._init();
    }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.form.controls;
    }

    private _access: EmployeeAccess | undefined;

    public get access(): EmployeeAccess | undefined {
        return this._access;
    }

    public set access(access: EmployeeAccess) {
        this._prefillForm(access);

        this._access = access;
    }

    public deleteAccess() {
        this._accessService.deleteAccess(this.employeeID, this.access.employee_access_ID)
            .then(() => this.activeModal.close('close'))
            .catch(() => { });
    }

    public onSubmit(): void {
        this.submitted = true;

        if (this.form.valid) {
            const values = {
                ...this.form.value,
                employee_ID: this.employeeID
            } as EmployeeAccess;

            this._accessService.saveAccess(values, this.access?.employee_access_ID)
                .then(() => this.activeModal.close('close'));
        }
    }

    private _init(): void {
        this.form = this._fb.group({
            name: ['', Validators.required],
            login: [''],
            description: [''],
            from: [''],
            to: [''],
        });
    }

    private _prefillForm(access: EmployeeAccess): void {
        if (access) {
            this.form.patchValue({
                description: access.description,
                from: access.from,
                login: access.login,
                name: access.name,
                to: access.to,
            });
        }
    }
}
