<div class="modal-content">
	<div class="modal-header">
			<p class="modal-title font-16 mb-0 text-dark">{{ 'kpis.modification' | translate }}</p>
		<button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
			<span aria-hidden="true" class="font-24 text-muted"><i class="mdi mdi-close"></i></span>
		  </button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">
				<div class="card mb-0 shadow-none">
					<div class="card-body p-0">
						<form #f="ngForm" (ngSubmit)="onSubmit(f.form)">
                            <div [ngModelGroup]="'metrics'">
                                    <div *ngFor="let metric of employee.metrics" [ngModelGroup]="metric.kpi_metric_ID">
                                        <p class="modal-title font-16 mb-1 text-dark">{{metric.name}}</p>
                                        <div class="mb-2">{{ metric.description }}</div>
                                        <div class="row">
                                            <div class="col-6">
                                                <label class="control-label">{{ 'kpis.edit_metric' | translate }}</label>
                                            </div>
                                            <div class="col-6 text-right align-self-center">
                                            <div>
                                                <input #modify="ngModel" [ngModel]="metric.modified" data-switch="success" id="modify-{{metric.kpi_metric_ID}}" name="modify" type="checkbox">
                                                <label for="modify-{{metric.kpi_metric_ID}}">&nbsp;</label>
                                            </div>
                                            </div>
                                        </div>
                                            <div class="row">

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label class="control-label">{{ 'kpis.from' | translate }}</label>
                                                        <input [disabled]="!modify.value" [ngModel]="metric.min" class="form-control" name="min" type="number"/>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label class="control-label">{{ 'kpis.to' | translate }}</label>
                                                        <input [disabled]="!modify.value" [ngModel]="metric.max" class="form-control" name="max" type="number"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="metric.threshold_1" class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label class="control-label">{{ 'kpis.threshold_1' | translate }}</label>
                                                        <input [disabled]="!modify.value" [ngModel]="metric.threshold_1" class="form-control" currencyMask [options]="{'suffix': ' %', 'min': 0}" name="threshold_1" type="text"/>
                                                    </div>
                                                </div>
                                                <div *ngIf="metric.threshold_2" class="col-lg-6">
                                                    <div class="form-group">
                                                        <label class="control-label">{{ 'kpis.threshold_2' | translate }}</label>
                                                        <input [disabled]="!modify.value" [ngModel]="metric.threshold_2" class="form-control" currencyMask [options]="{'suffix': ' %', 'min': 0}" name="threshold_2" type="text"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr class="mb-3"/>
                                    </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-6">
                                    <button class="btn btn-primary">{{ 'kpis.action_save' | translate }}</button>
                                </div>
                            </div>
                        </form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
