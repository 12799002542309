<div *ngIf="(availableWidgets$ | async)?.employee_contact_list?.columns as availableWidgets" class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help name="employee.contact_list"></app-help>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive overflow-visible">
              <app-data-table
                (searchChanged)="searchChanged($event)"
                (sortChanged)="sortChanged($event)"
                [disableInPlaceFiltering]="true"
                [disableInPlaceSorting]="true"
                [loading]="loading"
                [rows]="rows"
                [search]="search"
                [sort]="sort"
                [trackByFn]="trackByFn"
              >
                <ng-template #headerRow>
                  <th appDataTableHeaderCell="fullname">{{ 'employees.name' | translate }}</th>

                  <th *ngIf="availableWidgets?.contact" appDataTableHeaderCell="contact">{{ 'employees.contact' | translate }}</th>

                  <th *ngIf="availableWidgets?.main_branch" appDataTableHeaderCell="company_branch">{{ 'employees.main_company_branch' | translate }}</th>

                  <th *ngIf="availableWidgets?.teams"
                      [disableSort]="true"
                      appDataTableHeaderCell
                  >{{ 'employees.company_groups' | translate }}</th>
                </ng-template>

                <ng-template
                  #dataRow
                  let-row
                >
                  <td
                    appDataTableDataCell
                    class=""
                  >
                      <app-employee-box-avatar [avatar]="row.avatar" [canView]="false" [employeeID]="row.employee_ID" [fullname]="row.fullname" [position]="row.position" [states]="null"></app-employee-box-avatar>
                  </td>

                  <td *ngIf="availableWidgets?.contact" appDataTableDataCell>{{ row.email }}<br>{{ row.phone }}</td>

                  <td *ngIf="availableWidgets?.main_branch" appDataTableDataCell>{{ row.company_branch_name }}</td>

                  <td *ngIf="availableWidgets?.teams" appDataTableDataCell>{{ row.companyGroupNames }}</td>
                </ng-template>
              </app-data-table>
            </div>
          </div>
        </div>

        <div class="row mt-3 flex-column-reverse flex-sm-row">
          <div class="col-12 col-sm-2">
            <app-items-per-page-select
              (itemsPerPageChanged)="itemsPerPageChanged($event)"
              [itemsPerPage]="itemsPerPage"
              [total]="itemsTotal"
            ></app-items-per-page-select>
          </div>

          <div class="col-12 col-sm-10">
            <app-pagination
              (pageChanged)="pageChanged($event)"
              [itemsPerPage]="itemsPerPage"
              [page]="page"
              [total]="itemsTotal"
            ></app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
