import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmployeeContactPerson } from 'src/app/employee/models/EmployeeContactPerson';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { WidgetTemplate } from '../../../../shared/models/WidgetTemplate';
import { WidgetTemplatesService } from '../../../../shared/services/widget-templates.service';

@Component({
    selector: 'app-contact-person-form',
    templateUrl: './contact-person-form.component.html',
    styleUrls: ['./contact-person-form.component.css']
})
export class ContactPersonFormComponent implements OnInit {

    @Input() public submitted = false;
    @Input() public employeeContactPerson: EmployeeContactPerson;

    public form: UntypedFormGroup;
    public formConfig: WidgetTemplate['contact_person'];

    constructor(
        private _widgetTemplatesService: WidgetTemplatesService,
        private _fb: UntypedFormBuilder,
        private _authService: AuthenticationService
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.form.controls;
    }

    public ngOnInit(): void {
        this.formConfig = this._widgetTemplatesService.defaultWidgetTemplate.contact_person;

        this.form = this._fb.group({
            fullname: ['', Validators.required],
            email: ['', Validators.email],
            phone: [''],
            description: [''],
        });

        if (this.formConfig && !this._authService.checkPermission(['employeeContactPerson.edit', 'employeeContactPerson.editStructure'])) {
            this._widgetTemplatesService.presetFormByConfig(this.form, this.formConfig);
        }

        if (this.employeeContactPerson) {
            this.form.patchValue(this.employeeContactPerson);
        }
    }
}
