<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">
            <span *ngIf="employee">{{ 'employees.page_title_edit' | translate }}</span>
            <span *ngIf="!employee">{{ 'employees.page_title_create' | translate }}</span>
        </p>

        <button
            (click)="activeModal.dismiss()"
            aria-label="Close"
            class="close"
        >
      <span
          aria-hidden="true"
          class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <div class="row">
                            <div *ngIf="message !== ''" class="row px-2">
                                <div class="col-12">
                                    <p class="pt-1 text-danger">
                                        <span> {{ message | translate }} </span>
                                        <a routerLink="/setting/subscription-settings">
                                            <u>{{ 'subscription.message_button' | translate }}</u>
                                        </a>.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="!employee">
                            <div class="row mb-2">
                                <div class="col-12 text-right">
                                    <label class="control-label mr-2">{{ 'employees.simple_create' | translate }}</label>
                                    <input [checked]="complexCreate" data-switch="success" id="complex_create" name="complex_create" type="checkbox">
                                    <label (click)="setComplexCreate()">&nbsp;</label>
                                </div>

                            </div>

                            <hr>

                            <div *ngIf="complexCreate">
                                <div class="row mb-2">
                                    <div class="col-6">
                                        <h4>{{ 'employees.employee_info' | translate }}</h4>
                                    </div>
                                    <div class="col-6 text-right align-self-center">
                                        <div>
                                            <input [checked]="!collapsedForms.employee" data-switch="success" id="collapse_employee" name="collapse_employee" type="checkbox">
                                            <label class="m-0" (click)="collapseForm('employee')">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                                <div [(ngbCollapse)]="collapsedForms.employee">
                                    <fieldset class="fieldset-bordered py-2">
                                        <app-employee-form #employeeFormComponent
                                                           [employee]="null"
                                                           [submitted]="submitted"
                                                           [employeeType]="employeeType">
                                        </app-employee-form>
                                    </fieldset>

                                </div>

                                <hr>

                                <div class="row mb-2">
                                    <div class="col-6">
                                        <h4>{{ 'employees.personal_info' | translate }}</h4>
                                    </div>
                                    <div class="col-6 text-right align-self-center">
                                        <div>
                                            <input [checked]="!collapsedForms.employee_personal_data" data-switch="success" id="collapse_personal_data" name="collapse_personal_data" type="checkbox">
                                            <label class="m-0" (click)="collapseForm('employee_personal_data')">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                                <div [(ngbCollapse)]="collapsedForms.employee_personal_data">
                                    <app-personal-data-form #personalDataFormComponent
                                                            [employee]="null"
                                                            [employeePersonalData]="null"
                                                            [submitted]="submitted">
                                    </app-personal-data-form>
                                </div>

                                <hr>

                                <div class="row mb-2">
                                    <div class="col-6">
                                        <h4>{{ 'employees.address' | translate }}</h4>
                                    </div>
                                    <div class="col-6 text-right align-self-center">
                                        <div>
                                            <input [checked]="!collapsedForms.employee_address" data-switch="success" id="collapse_address" name="collapse_address" type="checkbox">
                                            <label class="m-0" (click)="collapseForm('employee_address')">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                                <div [(ngbCollapse)]="collapsedForms.employee_address">
                                    <fieldset class="fieldset-bordered pt-2">
                                        <app-address-form #addressFormComponent
                                                          [presetPrimaryAddress]="true"
                                                          [employeeAddress]="null"
                                                          [submitted]="submitted">
                                        </app-address-form>
                                    </fieldset>
                                </div>

                                <hr>

                                <div class="row mb-2">
                                    <div class="col-6">
                                        <h4>{{ 'employees.contact_person' | translate }}</h4>
                                    </div>
                                    <div class="col-6 text-right align-self-center">
                                        <div>
                                            <input [checked]="!collapsedForms.employee_contact_person" data-switch="success" id="collapse_contact_person" name="collapse_contact_person" type="checkbox">
                                            <label class="m-0" (click)="collapseForm('employee_contact_person')">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                                <div [(ngbCollapse)]="collapsedForms.employee_contact_person">
                                    <fieldset class="fieldset-bordered pt-2">
                                        <app-contact-person-form #contactPersonFormComponent
                                                                 [employeeContactPerson]="null"
                                                                 [submitted]="submitted">
                                        </app-contact-person-form>
                                    </fieldset>
                                </div>

                                <hr>

                                <div class="row mb-2">
                                    <div class="col-6">
                                        <h4>{{ 'employees.position' | translate }}</h4>
                                    </div>
                                    <div class="col-6 text-right align-self-center">
                                        <div>
                                            <input [checked]="!collapsedForms.employee_position" data-switch="success" id="collapse_position" name="collapse_position" type="checkbox">
                                            <label class="m-0" (click)="collapseForm('employee_position')">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                                <div [(ngbCollapse)]="collapsedForms.employee_position">
                                    <fieldset class="fieldset-bordered pt-2">
                                        <app-position-form #positionFormComponent
                                                           [employeePosition]="null"
                                                           [employee]="null"
                                                           [employeeID]="null"
                                                           [submitted]="submitted">
                                        </app-position-form>
                                    </fieldset>
                                </div>

                                <hr>

                                <div class="row mb-2">
                                    <div class="col-6">
                                        <h4>{{ 'employees.salary' | translate }}</h4>
                                    </div>
                                    <div class="col-6 text-right align-self-center">
                                        <div>
                                            <input [checked]="!collapsedForms.employee_salary" data-switch="success" id="collapse_salary" name="collapse_salary" type="checkbox">
                                            <label class="m-0" (click)="collapseForm('employee_salary')">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                                <div [(ngbCollapse)]="collapsedForms.employee_salary">
                                    <fieldset class="fieldset-bordered pt-2">
                                        <app-salary-form #salaryFormComponent
                                                         [employee]="employee"
                                                         [salary]="null"
                                                         [submitted]="submitted">
                                        </app-salary-form>
                                    </fieldset>
                                </div>

                                <hr>

                                <div class="row mb-2">
                                    <div class="col-6">
                                        <h4>{{ 'employees.main_company_branch' | translate }}</h4>
                                    </div>
                                    <div class="col-6 text-right align-self-center">
                                        <div>
                                            <input [checked]="!collapsedForms.employee_branch" data-switch="success" id="collapse_branch" name="collapse_branch" type="checkbox">
                                            <label class="m-0" (click)="collapseForm('employee_branch')">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-2" [(ngbCollapse)]="collapsedForms.employee_branch">
                                    <fieldset class="fieldset-bordered pt-2">
                                        <form [formGroup]="branchForm" autocomplete="off">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label class="control-label mr-2">{{ 'employees.main_company_branch' | translate }}</label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <ng-select [clearable]="false"
                                                                   class="custom"
                                                                   formControlName="branch"
                                                                   id="branch"
                                                        >
                                                            <ng-option [value]="0">{{ 'company_branches.no_branch' | translate }}</ng-option>
                                                            <ng-option *ngFor="let companyBranch of branchStoreService.branches$ | async" [value]="companyBranch.company_branch_ID">
                                                                {{companyBranch.name }}
                                                            </ng-option>
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </fieldset>
                                </div>

                                <hr>
                            </div>
                        </ng-container>


                        <!--Default form-->
                        <app-employee-form #employeeFormComponentSimple
                                           *ngIf="!complexCreate"
                                           [employee]="employee"
                                           [submitted]="submitted"
                                           [employeeType]="employeeType">
                        </app-employee-form>

                        <div *ngIf="hasEditEmployeePermission && !(canEdit$ | async)" class="row">
                            <div class="col-12 d-flex justify-content-end">
                                <p class="text-muted mb-1"><small> {{'employees_addresses.request_will_be_sent' | translate}} </small></p>
                            </div>
                        </div>

                        <div *ngIf="message !== ''" class="row">
                            <div class="col-12">
                                <p class="pt-1 text-danger">
                                    <span> {{ message | translate }} </span>
                                    <a routerLink="/setting/subscription-settings">
                                        <u>{{ 'subscription.message_button' | translate }}</u>
                                    </a>.
                                </p>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col d-flex justify-content-end">
                                <button (click)="onSubmit()" [disabled]="personalCodetaken || emailTaken || !showButton || (updateRequested && !(canEdit$ | async))" class="btn btn-success">
                                    <span *ngIf="employee">{{ 'employees.action_save' | translate }}</span>
                                    <span *ngIf="!employee">{{ 'employees.action_create' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
