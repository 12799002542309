import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanyService } from 'src/app/setting/services/company.service';
import { CountryStoreService } from 'src/app/setting/services/country-store.service';

@Component({
    selector: 'app-company-settings-address',
    templateUrl: './company-settings-address.component.html',
    styleUrls: ['./company-settings-address.component.css']
})
export class CompanySettingsAddressComponent implements OnInit {

    @Input() companySettings$: Observable<any>;

    public companyAddressSettingEditForm: UntypedFormGroup;
    public submitted = false;

    public constructor(
        public countryStoreService: CountryStoreService,
        private _companyService: CompanyService,
        private _fb: UntypedFormBuilder,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.companyAddressSettingEditForm.controls;
    }

    public ngOnInit(): void {
        this.companyAddressSettingEditForm = this._fb.group({
            'company.address.addr_line_1': [''],
            'company.address.addr_line_2': [''],
            'company.address.city': [''],
            'company.address.postal_code': [''],
            'company.address.country_code': [null],
        });

        this.companySettings$ = this.companySettings$
            .pipe(
                tap(settings => {
                    this.companyAddressSettingEditForm.patchValue({
                        'company.address.addr_line_1': settings.company_settings.address?.addr_line_1,
                        'company.address.addr_line_2': settings.company_settings.address?.addr_line_2,
                        'company.address.city': settings.company_settings.address?.city,
                        'company.address.postal_code': settings.company_settings.address?.postal_code,
                        'company.address.country_code': settings.company_settings.address?.country_code,
                    });
                })
            );
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.companyAddressSettingEditForm.valid) {
            return;
        }

        const value = this.companyAddressSettingEditForm.value;

        value.settings_type = 'address';

        this._companyService.saveCompanySetting(value);
    }
}
