<ng-template #noIntegrationTemplate>
  <span>{{ 'integrations.integrations_none' | translate }}</span>
</ng-template>

<ng-container *ngIf="activeUserIntegrations$ | async; let activeUserIntegrations; else noIntegrationTemplate">
  <div class="d-flex flex-column flex-md-row align-items-md-center align-items-start justify-content-between">
    <span class="mb-2 mb-md-0"></span>
    <div *ngIf="activeUserIntegrations['azure']" class="mb-2 mb-md-0">
      <span>{{ activeUserIntegrations['azure'].email }}</span>
      <span *ngIf="activeUserIntegrations['azure'].name; let name">{{ ' (' + name + ')' }}</span>
    </div>

    <ng-container *ngIf="watchingMyself">
      <button  (click)="deactivateIntegration('azure')"
               *ngIf="activeUserIntegrations['azure']; else connectAzureTemplate"
               class="btn btn-danger">
        {{ 'integrations.action_disconnect' | translate }}
      </button>

      <ng-template #connectAzureTemplate>
        <button (click)="activateIntegration('azure')"
                class="btn btn-primary">
          {{ 'integrations.action_connect' | translate }}
        </button>
      </ng-template>
    </ng-container>
  </div>
    <div class="row mt-2">
        <div class="col-12 text-right">
            <small class="text-muted">
                <span>{{'integrations.explanation_start' | translate}}</span>
                <span *ngIf="activeIntegrations.includes('microsoft-teams')"> {{'integrations.microsoft-teams.explanation' | translate}} </span>
                <span *ngIf="activeIntegrations.includes('microsoft-teams') && activeIntegrations.includes('microsoft-0365-login')"> {{'global.and' | translate}} </span>
                <span *ngIf="activeIntegrations.includes('microsoft-0365-login')"> {{'integrations.microsoft-0365-login.explanation' | translate}}</span>.
            </small>
        </div>
    </div>
    <div *ngIf="activeIntegrations.includes('microsoft-0365-login')" class="row">
        <div class="col-12 text-right">
            <small class="text-muted"></small>
        </div>
    </div>
</ng-container>
