import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GrowthTask } from 'src/app/growth/growth/state/growth-task.model';
import { Growth } from 'src/app/growth/growth/state/growth.model';
import { GrowthService } from 'src/app/growth/growth/state/growth.service';

@Component({
  selector: 'app-create-edit-growth-task-modal',
  templateUrl: './create-edit-growth-task-modal.component.html',
  styleUrls: ['./create-edit-growth-task-modal.component.css']
})
export class CreateEditGrowthTaskModalComponent implements OnInit {
  submitted = false;
  growthTaskEditForm: UntypedFormGroup;
  growthTask: GrowthTask;
  growth: Growth;

  constructor(
    private growthService: GrowthService,
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.growthTaskEditForm = this.fb.group({
      name: ['', Validators.required],
    });

    if (this.growthTask) {
      this.growthTaskEditForm.patchValue({
        name: this.growthTask.name,
      });
    }
  }

  get f() {
    return this.growthTaskEditForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (!this.growthTaskEditForm.valid) {
      return;
    }
    this.growthService.saveGrowthTask(
      this.growthTaskEditForm.value,
      this.growth.growth_ID,
      this.activeModal,
      this.growthTask?.growth_task_ID,
    );
  }

  deleteGrowthTask() {
    this.growthService.deleteGrowthTask(this.growth.growth_ID, this.growthTask.growth_task_ID, this.activeModal);
  }

}
