<div id="loader-wrap" class="app-loader-wrap d-none">
    <div class="d-block">
        <img alt="loading" height="55" src="assets/images/logo_speybl-logo-black.svg">
        <div class="text-center">
            <svg height="35" viewBox="0 0 85 35" width="85" xmlns="http://www.w3.org/2000/svg">
                <path class="dash-path" d="M83 30 H1" stroke="#3d3f49" stroke-width="2"/>
            </svg>
        </div>
    </div>
</div>

<router-outlet></router-outlet>

<ngx-ui-loader
  [bgsOpacity]="1"
  [bgsSize]="0"
  [fgsSize]="0"
  [overlayColor]="'rgba(230,21,21,0)'"
  [pbColor]="'#0acf97'"
></ngx-ui-loader>

<div
  class="d-none"
  id="uploadPreviewTemplate"
>
  <div class="col-md-12 mt-3">
    <div class="card mt-0 mb-0 shadow-none border border-light">
      <div class="p-2">
        <div class="row align-items-center">
          <div class="col-auto">
            <img
              alt=""
              class="avatar-sm rounded bg-light"
              data-dz-thumbnail=""
            >
          </div>

          <div class="col pl-0">
            <a
              class="text-muted font-weight-bold"
              data-dz-name=""
              href="javascript:void(0);"
            ></a>

            <p
              class="mb-0"
              data-dz-size=""
            ></p>
          </div>

          <div class="col-auto">
            <!-- Button -->
            <a
              class="btn btn-link btn-icon btn-lg text-muted"
              data-dz-remove=""
              href=""
            >
              <i class="mdi mdi-close"></i>
            </a>
          </div>
        </div>
          <div class="row mt-2 justify-content-center text-danger">
              <div class="dz-error-message px-2"><span data-dz-errormessage></span></div>
          </div>
      </div>
    </div>
  </div>
</div>

<div
  class="d-none"
  id="uploadPreviewTemplateSmall"
>
  <div class="col-xl-4">
    <div class="card mt-0 mb-0 shadow-none border border-light">
      <div class="p-0 pl-1">
        <div class="row align-items-center">
          <div class="col-auto">
            <img
              alt=""
              class="avatar-xs rounded bg-light"
              data-dz-thumbnail=""
            >
          </div>

          <div class="col pl-0">
            <a
              class="text-muted font-weight-bold"
              data-dz-name=""
              href="javascript:void(0);"
            ></a>
          </div>

          <div class="col-auto">
            <!-- Button -->
            <a
              class="btn btn-link btn-icon btn-xs text-mute"
              data-dz-remove=""
              href=""
            >
              <i class="mdi mdi-close"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-new-version *ngIf="appService.notifyNewVersion"></app-new-version>
