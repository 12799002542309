import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { UIRowLoaderModule } from '../ui';

import { EmployeeAnalysisAttendanceComponent } from './components/sections/employee-analysis-attendance/employee-analysis-attendance.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';

@NgModule({
  declarations: [
    EmployeeAnalysisAttendanceComponent
  ],
    imports: [
        SharedModule,

        UIRowLoaderModule,
        NgxSkeletonLoaderModule,
    ],
  exports: [
    EmployeeAnalysisAttendanceComponent
  ]
})
export class EmployeeAnalysisAttendanceModule { }
