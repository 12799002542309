import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Receivable } from './receivable.model';

export interface ReceivableState extends EntityState<Receivable> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'receivables', idKey: 'employee_receivable_ID' })
export class ReceivableStore extends EntityStore<ReceivableState> {
  constructor() {
    super();
  }
}
