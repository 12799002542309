import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormatHelper } from 'src/app/shared/common/FormatHelper';
import { HelpService } from 'src/app/shared/services/help.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {
    public constructor(
        private _authService: AuthenticationService,
        private _formatHelper: FormatHelper,
        private _helpService: HelpService,
        private _router: Router
    ) { }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (this._authService.getAuthStatus()) {
            this._setHelpKey(next);
            return true;
        } else {
            return this._authService.loggedUser$
                .pipe(
                    map(loggedInUser => {
                        if (!loggedInUser || this._formatHelper.objectIsEmpty(loggedInUser)) {
                            void this._router.navigateByUrl('auth/login');
                            return false;
                        } else {
                            this._authService.changeAuthStatus(true);
                            this._setHelpKey(next);
                            return true;
                        }
                    })
                );
        }
    }

    public canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (this._authService.getAuthStatus()) {
            this._setHelpKey(next);
            return true;
        } else {
            return this._authService.loggedUser$
                .pipe(
                    map(loggedInUser => {
                        if (this._formatHelper.objectIsEmpty(loggedInUser)) {
                            void this._router.navigateByUrl('auth/login');
                            return false;
                        } else {
                            this._authService.changeAuthStatus(true);
                            this._setHelpKey(next);
                            return true;
                        }
                    })
                );
        }
    }

    private _setHelpKey(next: ActivatedRouteSnapshot): void {
        this._helpService.setKey(next.data.help);
    }
}
