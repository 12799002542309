import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { combineLatest, Subscription } from 'rxjs';
import { OnboardingTask } from 'src/app/onboarding/onboarding/state/onboarding-task.model';
import { Onboarding } from 'src/app/onboarding/onboarding/state/onboarding.model';
import { OnboardingService } from 'src/app/onboarding/onboarding/state/onboarding.service';
import { SummernoteHelper } from 'src/app/shared/common/SummernoteHelper';
import { BLOB_MAX_LENGTH, TEXT_MAX_LENGTH } from 'src/app/ui/form/constants';
import { EmployeeService } from '../../../../employee/services/employee.service';
import { Role } from '../../../../setting/models/Role';
import { RolesService } from '../../../../setting/services/roles.service';

@Component({
    selector: 'app-create-edit-onboarding-task-modal',
    templateUrl: './create-edit-onboarding-task-modal.component.html',
    styleUrls: ['./create-edit-onboarding-task-modal.component.css']
})
export class CreateEditOnboardingTaskModalComponent implements OnInit, OnDestroy {
    public maxTaskDescriptionLength = BLOB_MAX_LENGTH;
    public maxTaskNameLength = TEXT_MAX_LENGTH;
    public onboarding: Onboarding;
    public onboardingTask: OnboardingTask;
    public onboardingTaskEditForm: UntypedFormGroup;
    public submitted = false;
    public subscription: Subscription;
    public customSettingsCollapsed = true;
    public selectedDeadlineDate: string;
    public assigneeName: string;
    public roles: Role[];
    public deadlineItems = [
        {label: this._translateService.instant('onboardings_tasks.deadline_none'), value: 'NONE'},
        {label: this._translateService.instant('onboardings_tasks.deadline_at_start'), value: 'AT_START'},
        {label: this._translateService.instant('onboardings_tasks.deadline_end_of_week'), value: 'END_OF_WEEK'},
        {label: this._translateService.instant('onboardings_tasks.deadline_end_of_month'), value: 'END_OF_MONTH'},
        {label: this._translateService.instant('onboardings_tasks.deadline_end_of_next_month'), value: 'END_OF_NEXT_MONTH'},
        {label: this._translateService.instant('onboardings_tasks.deadline_custom'), value: 'CUSTOM'},
    ];
    public assigneeItems = [];
    public selectedOption = null;

    public constructor(
        public activeModal: NgbActiveModal,
        public summernoteHelper: SummernoteHelper,
        private _fb: UntypedFormBuilder,
        private _onboardingService: OnboardingService,
        private _translateService: TranslateService,
        private _employeeService: EmployeeService,
        private _roleService: RolesService,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.onboardingTaskEditForm.controls;
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    public ngOnInit(): void {
        this.onboardingTaskEditForm = this._fb.group({
            description: ['', Validators.maxLength(this.maxTaskDescriptionLength)],
            name: ['', [Validators.required, Validators.maxLength(this.maxTaskNameLength)]],
            assignee: ['NONE'],
            assignee_entity: [''],
            deadline: ['NONE'],
            interval: [1],
            period: ['WEEK'],
            period_type: ['AFTER'],
        });

        this.subscription = combineLatest([
            this._employeeService.getAllEmployees(false, 'selectWithRole'),
            this._roleService.getAllRoles()
        ])
            .subscribe(([employees, roles]) => {
                this.roles = roles;
                const assigneeRoles = roles.map(role => ({label: role.name, value: 'ROLE_' + role.role_ID.toString(), group: this._translateService.instant('onboardings_tasks.roles'), group_entity: 'ROLE'})) as any;
                const assigneeStructure = [{label: this._translateService.instant('onboardings_tasks.assignee_nearest_team_leader'), value: 'STRUCTURE_NEAREST_TEAM_LEADER', group: this._translateService.instant('onboardings_tasks.structure'), group_entity: 'STRUCTURE'}];
                const assigneeEmployees = employees.map(employee => ({label: employee.fullname, value: 'EMPLOYEE_' + employee.employee_ID.toString(), group: this._translateService.instant('onboardings_tasks.employees'), group_entity: 'EMPLOYEE'}));
                this.assigneeItems = [
                    {label: this._translateService.instant('onboardings_tasks.assignee_none'), value: 'NONE', group: null, group_entity: null},
                    ...assigneeRoles,
                    ...assigneeStructure,
                    ...assigneeEmployees
                ];
                this.selectedOption = this.assigneeItems.find(item => item.value === this.onboardingTask?.assignee_entity + '_' + this.onboardingTask?.assignee);
                this.onAsigneeChange(this.selectedOption);
            });

        if (this.onboardingTask) {
            this.onboardingTaskEditForm.patchValue({
                ...this.onboardingTask,
                assignee: this.onboardingTask.assignee ? this.onboardingTask.assignee_entity + '_' + this.onboardingTask.assignee : 'NONE',
            });

            if (this.onboardingTask.deadline === 'CUSTOM') {
                this.customSettingsCollapsed = false;
            }
        }
    }

    public deleteOnboardingTask(): void {
        this._onboardingService.deleteOnboardingTask(this.onboarding.onboarding_ID, this.onboardingTask.onboarding_task_ID, this.activeModal)
            .then(() => { },
                () => { });
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.onboardingTaskEditForm.valid) {
            return;
        }

        const value = this.onboardingTaskEditForm.value;

        if (value.deadline !== 'CUSTOM') {
            delete value.interval;
            delete value.period;
            delete value.period_type;
        }

        value.assignee = value.assignee?.replace('ROLE_', '');
        value.assignee = value.assignee?.replace('EMPLOYEE_', '');
        value.assignee = value.assignee?.replace('STRUCTURE_', '');

        if (value.assignee === 'NONE') {
            value.assignee = null;
        }

        this._onboardingService.saveOnboardingTask(value, this.onboarding.onboarding_ID, this.activeModal, this.onboardingTask?.onboarding_task_ID)
            .then(() => { },
                () => { });
    }

    public getDeadlineDate(type: string): string {
        let date = moment();
        if (type === 'AT_START') {
            date = date.endOf('day');
        }
        if (type === 'END_OF_WEEK') {
            date = date.endOf('week');
        }
        if (type === 'END_OF_MONTH') {
            date = date.endOf('month');
        }
        if (type === 'END_OF_NEXT_MONTH') {
            date = date.add(1, 'month').endOf('month');
        }
        if (type === 'CUSTOM' && this.f.interval.value && this.f.period.value && this.f.period_type.value) {
            const usedPeriod = this.f.period.value === 'END_MONTH' ? 'month' : this.f.period.value.toLowerCase();
            if (this.f.period_type.value === 'BEFORE') {
                date = moment().subtract(this.f.interval.value, usedPeriod);
            }
            if (this.f.period_type.value === 'AFTER') {
                date = moment().add(this.f.interval.value, usedPeriod);
            }
            if (this.f.period.value === 'END_MONTH') {
                date = date.endOf('month');
            }
        }

        return date.toISOString();
    }

    public onDeadlineChange($event: any): void {
        this.customSettingsCollapsed = $event.value !== 'CUSTOM';
        this.selectedDeadlineDate = this.getDeadlineDate($event.value);
    }

    public onCustomChange(): void {
        this.selectedDeadlineDate = this.getDeadlineDate('CUSTOM');
    }

    public onAsigneeChange($event: any): void {
        this.onboardingTaskEditForm.patchValue({assignee_entity: $event?.group_entity ?? null});

        if (!$event || $event.value === 'NONE') {
            this.assigneeName = 'none';
        }
        if ($event?.group_entity === 'EMPLOYEE') {
            this.assigneeName = $event.label;
        }
        if ($event?.group_entity === 'ROLE') {
            const roleID = $event.value.replace('ROLE_', '');
            this.assigneeName = this.roles.find(item => item.role_ID.toString() === roleID)?.first_employee?.fullname ?? 'not_found';
        }
        if ($event?.group_entity === 'STRUCTURE') {
            this.assigneeName = this._translateService.instant('onboardings_tasks.assignee_nearest_team_leader_small');
        }
    }
}
